import { View, Image, StyleSheet, Pressable, Text } from 'react-native'
import React from 'react'
import image from '../../../../assets/icons'
import { Theme, useTheme } from '../../../../styles/theme'
import { useNavigation } from '@react-navigation/native'
import { observer } from 'mobx-react-lite'
import { FormPageStore } from '@appformula/app-studio-core/src'
import { ARNFormPageStore } from '../../ARNFormPageStore'
import { COLORS } from '../../../../assets/colors'
import { useBinding } from '../../../collection/components/hooks/useBinding'

const FormHeader = observer(
    (props: {
        pageStore: FormPageStore
        arnFormPageStore: ARNFormPageStore
        onSaveData: () => void
    }) => {
        const { arnFormPageStore, onSaveData, pageStore } = props
        const theme = useTheme()
        const styles = React.useMemo(() => createStyles(theme), [theme])
        const navigation = useNavigation()
        const formScreenError = arnFormPageStore.formScreenError
        const title = useBinding(pageStore.title)
        const handleClose = () => {
            navigation.goBack()
        }

        const handleSave = async () => {
            onSaveData()
        }

        return (
            <View>
                <View style={styles.header}>
                    <View style={styles.headerLeft}>
                        <Pressable onPress={handleClose} hitSlop={10}>
                            <Image
                                source={image.close}
                                style={styles.imgClose}
                            />
                        </Pressable>
                        <Text style={styles.txtTitle}>{title}</Text>
                    </View>
                    <View style={styles.headerRight}>
                        <Pressable onPress={handleSave} style={styles.btnSave}>
                            <Text style={styles.txtSave}>{'Save'}</Text>
                        </Pressable>
                    </View>
                </View>
                {!!formScreenError && (
                    <View style={styles.wrapError}>
                        <Text style={styles.textError}>
                            {formScreenError.message}
                        </Text>
                    </View>
                )}
            </View>
        )
    }
)

export default FormHeader

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        textError: {
            color: COLORS.WHITE,
            fontSize: 13,
            lineHeight: 17.55,
            fontWeight: '400',
        },
        wrapError: {
            backgroundColor: colorScheme.error,
            justifyContent: 'center',
            alignItems: 'center',
            padding: 10,
        },
        header: {
            padding: 10,
            flexDirection: 'row',
            justifyContent: 'space-between',
            backgroundColor: colorScheme.backgroundL1,
            alignItems: 'center',
            borderBottomColor: colorScheme.separator,
            borderBottomWidth: 1,
        },
        imgClose: {
            height: 28,
            width: 28,
        },
        headerLeft: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        headerRight: {},
        btnSave: {
            backgroundColor: colorScheme.accentColor,
            borderRadius: roundness,
        },
        txtSave: {
            color: COLORS.WHITE,
            paddingVertical: 7,
            paddingHorizontal: 16.5,
            fontSize: 14,
            lineHeight: 16.5,
            fontWeight: '500',
        },
        txtTitle: {
            fontWeight: '600',
            fontSize: 18,
            lineHeight: 29.7,
            color: colorScheme.labelPrimary,
            marginLeft: 8,
        },
    })
