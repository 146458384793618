//
//  FilterOptionStore.ts
//
//  Created by thaitd96 on 2022-07-28 10:24.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { action, makeObservable, observable } from 'mobx'
import { ColumnFilter, FilterOptionData } from '../CollectionFilterStore'
import appConstants from '../../../../../constants/const'

export class FilterOptionStore {
    readonly collectionPageId: string
    readonly columnFilter: ColumnFilter
    availableOptions: (string | number)[]
    selectedOptions: string[]
    type: number

    constructor(collectionPageId: string, filterOptionData: FilterOptionData) {
        this.collectionPageId = collectionPageId
        this.columnFilter = filterOptionData.columnFilter
        this.availableOptions = filterOptionData.availableOptions ?? []
        this.selectedOptions = filterOptionData.selectedOptions ?? []
        this.type =
            filterOptionData.type ?? appConstants.OPTIONS_COMPARE_TYPE.EQUALS

        makeObservable(this, {
            availableOptions: observable,
            selectedOptions: observable,
            addAvailableOption: action,
            changeSelectedOption: action,
            type: observable,
            setType: action,
        })
    }

    setAvailableOptions(availableOptions: string[]) {
        this.availableOptions = availableOptions
    }

    addAvailableOption(option: string) {
        this.availableOptions.push(option)
    }

    changeSelectedOption(option: string) {
        const optionIndex = this.selectedOptions.indexOf(option)
        if (optionIndex >= 0) {
            this.selectedOptions.splice(optionIndex, 1)
        } else {
            this.selectedOptions.push(option)
        }
    }

    setType(type: number) {
        this.type = type
    }
}
