import { View, Text, Image, StyleSheet } from 'react-native'
import React, { useMemo } from 'react'
import image from '../../assets/icons'
import { Theme, useTheme } from '../../styles/theme'

const NotPublishScreen = () => {
    const theme = useTheme()
    const styles = useMemo(() => createStyles(theme), [theme])
    return (
        <View style={styles.container}>
            <Image source={image.infoNotPublish} style={styles.imgNotPublish} />
            <Text style={styles.txtTitle}>App has not Published</Text>
            <Text style={styles.txtContent}>
                Please contact the app owner to Publish it on the App Editor.
            </Text>
        </View>
    )
}

export default NotPublishScreen

const createStyles = ({ colorScheme, roundness }: Theme) =>
    StyleSheet.create({
        imgNotPublish: {
            width: 40.35,
            height: 40.35,
            marginBottom: 8,
            tintColor: colorScheme.labelSecondary,
        },
        txtContent: {
            fontSize: 15,
            fontWeight: '400',
            lineHeight: 22.5,
            textAlign: 'center',
            color: colorScheme.labelSecondary,
        },
        txtTitle: {
            fontSize: 22,
            fontWeight: '500',
            lineHeight: 29.7,
            color: colorScheme.labelSecondary,
            marginBottom: 8,
        },
        container: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            paddingHorizontal: 40,
            backgroundColor: colorScheme.backgroundL1,
        },
    })
