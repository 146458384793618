export const actionIcon = {
    add: require('./add.png'),
    archive: require('./archive.png'),
    'delete-symbol': require('./delete-symbol.png'),
    delete: require('./delete.png'),
    info: require('./info.png'),
    preview: require('./preview.png'),
    notification: require('./notification.png'),
    search: require('./search.png'),
    cursor: require('./cursor.png'),
    download: require('./download.png'),
    share: require('./share.png'),
    send: require('./send.png'),
    message: require('./message.png'),
    mail: require('./mail.png'),
    phone: require('./phone.png'),
    'shopping-cart': require('./shopping-cart.png'),
    'shopping-bag': require('./shopping-bag.png'),
    'thumb-up': require('./thumb-up.png'),
    'thumb-down': require('./thumb-down.png'),
    pen: require('./pen.png'),
    gear: require('./gear.png'),
    glob: require('./glob.png'),
    'location-pin': require('./location-pin.png'),
    compose: require('./compose.png'),
    copy: require('./copy.png'),
    link: require('./link.png'),
    // End action icon
    moreActionList: require('./more-action-list.png'),
}
