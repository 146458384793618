import { apiClient } from '../index'
import appConstants from '../../../constants/const'
import { NOTIFICATION_URL } from '../apis/NotificationUrl'

interface IDataPushToken {
    deviceID: string
    deviceType: string
    pushToken: string
}

export interface IDataPush {
    title: string
    body: string
    recipients: string // String(emails, separated by comma)
}

interface IOptionUrl {
    teamId: string
    appId: string
}

export default {
    push: (data: IDataPush, optionUrl: IOptionUrl) => {
        const { teamId, appId } = optionUrl
        return apiClient.post(
            `pushNotification/${teamId}/${appId}/${NOTIFICATION_URL.push}`,
            {
                data,
            }
        )
    },

    pushToken: async (
        data: IDataPushToken,
        optionUrl: IOptionUrl
    ): Promise<void> => {
        try {
            const { teamId, appId } = optionUrl
            await apiClient.post(
                `pushNotification/${teamId}/${appId}/${NOTIFICATION_URL.pushToken}`,
                {
                    data,
                    isHandleError: false,
                }
            )
        } catch (error) {
            // console.info('pushToken error', error);
        }
    },
}
