import { useCallback, useMemo } from 'react'
import React, {
    Image,
    ImageStyle,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
} from 'react-native'
import { COLORS } from '../../assets/colors'
import { Theme, useTheme } from '../../styles/theme'

const ContextMenuOptions = (props: {
    actions: Array<Record<string, unknown>>
    getIcon: (item: Record<string, unknown>) => unknown
    getText: (item: Record<string, unknown>) => string
    onSelect: (item: Record<string, unknown>) => void
    iconStyle?: ImageStyle
}) => {
    const theme = useTheme()
    const styles = useMemo(() => createStyles(theme), [theme])
    const { onSelect, getIcon, getText, actions, iconStyle } = props

    const onPress = useCallback(
        (item) => () => {
            onSelect(item)
        },
        [onSelect]
    )

    return (
        <View style={styles.viewMenus}>
            {actions.map((item, index) => (
                <TouchableOpacity
                    key={index}
                    style={[
                        styles.viewMenu,
                        {
                            borderBottomWidth:
                                index === actions.length - 1 ? 0 : 0.5,
                        },
                    ]}
                    onPress={onPress(item)}
                >
                    <Text style={styles.textMenu}>{getText(item)}</Text>
                    <Image
                        source={getIcon(item)}
                        style={[styles.iconStyle, iconStyle]}
                    />
                </TouchableOpacity>
            ))}
        </View>
    )
}

export default ContextMenuOptions

const createStyles = ({ colorScheme }: Theme) => {
    return StyleSheet.create({
        iconStyle: {
            height: 18,
            width: 18,
            resizeMode: 'contain',
            tintColor: COLORS.BLACK,
        },
        textMenu: { fontSize: 17, fontWeight: '400', lineHeight: 22 },
        viewMenus: { backgroundColor: COLORS.BG_MENU_FILTER, borderRadius: 12 },
        viewMenu: {
            width: 254,
            height: 44,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingHorizontal: 15,
            borderColor: COLORS.SEPARATOR_MENU_FILTER,
        },
    })
}
