//
//  Schema.tsx
//
//  Created by thaitd96 on 2022-11-16 11:58.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import getFireStore from '../utils/firebase/firestore/getFireStore'
import {
    appFormulaId,
    ColumnNames,
    Columns,
    createdAt,
    DataType,
    DocumentData,
    DocumentSnapshot,
    FIRST_TIME_SYNC,
    FIRST_VERSION_WMDB,
    isDeleted,
    Item,
    OLD_SCHEMA,
    OLD_WMDB_VERSION,
    QuerySnapshot,
    ReactNativeTableSchema,
    SchemaChanges,
    TableChanges,
    TIME_LATEST_SYNC_UNIX,
    updatedAt,
} from './type'
import { Table } from '@appformula/app-descriptor/src'
import { getData, storeData } from '../utils/persistent-storage/AsyncStorage'
import differenceWith from 'lodash/differenceWith'
import isEqual from 'lodash/isEqual'
import { appSchema, Database, tableSchema } from '@nozbe/watermelondb'
import { convertFirestoreToWMDBDataType } from './utils'
import { getDataBase } from '.'
import { ConvertDateToISOString } from '../utils/date-time/DateTime'
import { onSyncSuccess } from '../utils/sync/onSyncSuccess'
import dayjs from 'dayjs'
import Model from '@nozbe/watermelondb/Model'
import {
    addColumns,
    createTable,
    schemaMigrations,
} from '@nozbe/watermelondb/Schema/migrations'
// @ts-ignore
// import { pullSyncChanges } from 'firestore-watermelon-sync-data'
import { action, makeObservable, observable } from 'mobx'
import cache from '../utils/cache'
import { Nullable } from '@appformula/shared-foundation-x'

export class Schema {
    teamId: string
    appId: string
    database: Database

    constructor(teamId: string, appId: string) {
        this.teamId = teamId
        this.appId = appId

        makeObservable(this, {
            database: observable,
            setDatabase: action,
        })
    }

    setDatabase(database: Database) {
        this.database = database
        cache.database = database
    }

    async refreshSchema(): Promise<ReactNativeTableSchema[]> {
        try {
            const [oldTables, currentTables] = await Promise.all([
                this.getOldSchema(),
                this.getCurrentSchema(),
            ])
            const oldVersion = await this.getWMDBVersion()

            console.info('schema oldTables', oldTables)
            console.info('schema currentTables', currentTables)

            if (!oldTables) {
                // First time
                const schema = this.defineSchema(
                    currentTables,
                    FIRST_VERSION_WMDB
                )
                const database = getDataBase(
                    schema,
                    currentTables,
                    this.teamId,
                    this.appId
                )
                this.setDatabase(database)
            } else if (isEqual(oldTables, currentTables)) {
                // Keep version
                const schema = this.defineSchema(currentTables, oldVersion)
                const database = getDataBase(
                    schema,
                    currentTables,
                    this.teamId,
                    this.appId
                )
                this.setDatabase(database)
            } else {
                const {
                    isHaveDeleteOrChangeTableColumns,
                    addedTables,
                    addedColumns,
                } = this.calculateSchemaChange(oldTables, currentTables)

                console.info(
                    'schema isHaveDeleteOrChangeTableColumns',
                    isHaveDeleteOrChangeTableColumns
                )
                if (isHaveDeleteOrChangeTableColumns) {
                    // Reset DB
                    const schema = this.defineSchema(currentTables, oldVersion)
                    const database = getDataBase(
                        schema,
                        currentTables,
                        this.teamId,
                        this.appId
                    )
                    this.setDatabase(database)
                } else {
                    // Migrate to bigger version
                    const newVersion = oldVersion + 1
                    this.storeWMDBVersion(newVersion)

                    const migrations = this.defineMigrations(
                        addedTables,
                        addedColumns,
                        newVersion
                    )
                    const schema = this.defineSchema(currentTables, newVersion)
                    const database = getDataBase(
                        schema,
                        currentTables,
                        this.teamId,
                        this.appId,
                        migrations
                    )

                    this.setDatabase(database)
                }
            }

            this.saveSchema(currentTables)
            return currentTables
        } catch (error) {
            console.info('Error refreshSchema', error)
            return Promise.reject(error)
        }
    }

    // In this function, we use tableId as table name
    protected async getCurrentSchema(): Promise<ReactNativeTableSchema[]> {
        try {
            const schema: QuerySnapshot<DocumentData> = (await (
                await getFireStore()
            )
                .collection(`teams/${this.teamId}/tables`)
                // .collection(`teams/${teamId}/apps/${appId}/tables`)
                .get()) as unknown as QuerySnapshot<DocumentData>

            return schema.docs.map((querySnapshot) => {
                const table = querySnapshot.data() as Table
                const columns: Columns = {}
                const columnNames: ColumnNames = {}

                Object.entries(table?.schema?.fieldType || {}).forEach(
                    ([columnName, tableFieldType]) => {
                        columns[columnName] = tableFieldType.type
                        columnNames[columnName] = {
                            name: tableFieldType.name,
                            type: tableFieldType.type,
                        }
                    }
                )
                const name = querySnapshot.id

                return {
                    name,
                    columns,
                    columnNames,
                }
            })
        } catch (error) {
            return Promise.reject(error)
        }
    }

    protected async getSchemaByTableId(
        tableId: string
    ): Promise<Nullable<ReactNativeTableSchema>> {
        try {
            const tableData: DocumentSnapshot<DocumentData> = (await (
                await getFireStore()
            )
                .collection(`teams/${this.teamId}/tables`)
                .doc(`${tableId}`)
                .get()) as unknown as DocumentSnapshot<DocumentData>

            const table = tableData.data() as Table
            const columns: Columns = {}

            Object.entries(table?.schema?.fieldType || {}).forEach(
                ([columnName, tableFieldType]) => {
                    columns[columnName] = tableFieldType.type
                }
            )

            return {
                name: tableData.id,
                columns,
            }
        } catch (error) {
            console.error('Error getCurrentSchemaByTableName', error)
            return undefined
        }
    }

    private calculateSchemaChange(
        oldTables: ReactNativeTableSchema[],
        currentTables: ReactNativeTableSchema[]
    ): SchemaChanges {
        const schemaChanges: SchemaChanges = {
            isHaveDeleteOrChangeTableColumns: false,
        }

        const addedTables = differenceWith(
            currentTables,
            oldTables,
            (firstArr, secondArr) => {
                return firstArr.name === secondArr.name
            }
        )

        const removedTables = differenceWith(
            oldTables,
            currentTables,
            (firstArr, secondArr) => {
                return firstArr.name === secondArr.name
            }
        )

        console.info('schema removedTables', removedTables)
        console.info('schema addedTables', addedTables)

        if (removedTables.length) {
            schemaChanges.isHaveDeleteOrChangeTableColumns = true
            return schemaChanges
        } else {
            const currentPersistentTables = differenceWith(
                currentTables,
                addedTables,
                (firstArr, secondArr) => {
                    return firstArr.name === secondArr.name
                }
            )

            for (const persistentCurrentTable of currentPersistentTables) {
                const tableName = persistentCurrentTable.name
                console.info('schema name', tableName, addedTables)
                const { isHaveDeleteOrChangeColumn, addedColumns } =
                    this.calculateTableChange(
                        oldTables.find((table) => table.name === tableName)
                            .columns,
                        persistentCurrentTable.columns
                    )
                if (isHaveDeleteOrChangeColumn) {
                    schemaChanges.isHaveDeleteOrChangeTableColumns = true
                    return schemaChanges
                } else {
                    schemaChanges.addedColumns = {
                        ...schemaChanges.addedColumns,
                        [tableName]: addedColumns,
                    }
                }
            }

            schemaChanges.addedTables = addedTables
            return schemaChanges
        }
    }

    private async saveSchema(schema: DocumentData[]) {
        return storeData(`${OLD_SCHEMA}${this.teamId}${this.appId}`, schema)
    }

    private async getOldSchema() {
        return getData(`${OLD_SCHEMA}${this.teamId}${this.appId}`)
    }

    private calculateTableChange(
        oldColumns: Columns,
        currentColumns: Columns
    ): TableChanges {
        const oldColumnNames = Object.keys(oldColumns)
        const currentColumnNames = Object.keys(currentColumns)

        // Added columns
        const addedColumns: Columns = {}
        const addedColumnNames = differenceWith(
            currentColumnNames,
            oldColumnNames,
            (firstArr, secondArr) => {
                return firstArr === secondArr
            }
        )
        addedColumnNames.forEach((name) => {
            addedColumns[name] = currentColumns[name]
        })

        // Check if exclude added columns, the rest of current columns are equal with old columns
        const restOfCurrentColumns = { ...currentColumns }
        addedColumnNames.forEach((name) => {
            delete restOfCurrentColumns[name]
        })
        const isHaveDeleteOrChangeColumn = !isEqual(
            restOfCurrentColumns,
            oldColumns
        )

        return {
            isHaveDeleteOrChangeColumn,
            addedColumns,
        }
    }

    private defineSchema(tables: ReactNativeTableSchema[], version: number) {
        console.info('schema tables', tables)
        return appSchema({
            version: version,
            tables: tables.map((table) =>
                tableSchema({
                    name: table.name,
                    columns: Object.keys(table.columns).map((name) => ({
                        name,
                        type: convertFirestoreToWMDBDataType(
                            table.columns[name] as DataType
                        ),
                    })),
                })
            ),
        })
    }

    private defineMigrations(
        addedTables: ReactNativeTableSchema[],
        addedColumns: Record<string, Columns>,
        newVersion: number
    ) {
        const createdTables = addedTables.map((table) =>
            createTable({
                name: table.name,
                columns: Object.keys(table.columns).map((name) => ({
                    name,
                    type: convertFirestoreToWMDBDataType(
                        table.columns[name] as DataType
                    ),
                })),
            })
        )

        console.info('schema addedColumns', addedColumns)

        const createdColumns = Object.keys(addedColumns).map((tableName) => {
            return addColumns({
                table: tableName,
                columns: Object.keys(addedColumns[tableName]).map((name) => ({
                    name,
                    type: convertFirestoreToWMDBDataType(
                        addedColumns[tableName][name] as DataType
                    ),
                })),
            })
        })
        return schemaMigrations({
            migrations: [
                {
                    toVersion: newVersion,
                    steps: [...createdTables, ...createdColumns],
                },
            ],
        })
    }

    // async turboLogin(currentTables: ReactNativeTableSchema[]) {
    //     const database = this.database
    //     const teamId = this.teamId
    //     await synchronize({
    //         database,
    //         pullChanges: async () => {
    //             const syncId = Math.floor(Math.random() * 1000000000)
    //             await pullSyncChanges(syncId, teamId)
    //             return { syncJsonId: syncId }
    //         },
    //         unsafeTurbo: true,
    //     })
    //     const test = await this.database
    //         .get(currentTables[0].name)
    //         .query()
    //         .fetch()
    //     console.info('turboLogin', test)
    // }

    async getDataChangeFromLatestSync(currentTables: ReactNativeTableSchema[]) {
        try {
            const timeLatestSync = await this.getTimeLatestSync()
            this.storeTimeLatestSync()
            const firestore = await getFireStore()

            for (const table of currentTables) {
                const changeQuerySnapshot = await firestore
                    .collection(`tableData/${this.teamId}/${table.name}`)
                    .where(updatedAt, '>=', timeLatestSync)
                    .get()

                console.info(
                    'schema changeQuerySnapshot',
                    changeQuerySnapshot.docs.length
                )

                const newDocumentSnapshots = changeQuerySnapshot.docs.filter(
                    (item) => item.data()[createdAt] >= timeLatestSync
                )

                const updatedDocumentSnapshots =
                    changeQuerySnapshot.docs.filter(
                        (item) =>
                            item.data()[createdAt] < timeLatestSync &&
                            !item.data()[isDeleted]
                    )

                const deletedDocumentSnapshots =
                    changeQuerySnapshot.docs.filter(
                        (item) =>
                            item.data()[createdAt] < timeLatestSync &&
                            item.data()[isDeleted]
                    )

                console.info(
                    'schema newDocumentSnapshots',
                    newDocumentSnapshots.length,
                    updatedDocumentSnapshots.length,
                    deletedDocumentSnapshots.length
                )

                this.database &&
                    this.syncData(
                        newDocumentSnapshots,
                        updatedDocumentSnapshots,
                        deletedDocumentSnapshots,
                        table
                    )
            }
        } catch (error) {
            console.info('getDataChangeFromLatestSync error', error)
        }
    }

    async syncData(
        newDocumentSnapshots: DocumentSnapshot<DocumentData>[],
        updatedDocumentSnapshots: DocumentSnapshot<DocumentData>[],
        deletedDocumentSnapshots: DocumentSnapshot<DocumentData>[],
        currentTable: ReactNativeTableSchema
    ) {
        try {
            // Create
            const batchCreate: Model[] = this.calculateBatchCreate(
                newDocumentSnapshots,
                currentTable
            )

            // Update
            const batchUpdate: Model[] = await this.calculateBatchUpdate(
                updatedDocumentSnapshots,
                currentTable
            )

            // Delete
            const batchDestroyPermanently: Model[] =
                await this.calculateBatchDestroyPermanently(
                    deletedDocumentSnapshots,
                    currentTable
                )

            await this.database.write(async () => {
                this.database.batch([
                    ...batchCreate,
                    ...batchUpdate,
                    ...batchDestroyPermanently,
                ])
            })

            onSyncSuccess(currentTable.name)
        } catch (error) {
            console.info('syncData error', error)
        }
    }

    private async calculateBatchUpdate(
        updatedDocumentSnapshots: DocumentSnapshot<DocumentData>[],
        currentTable: ReactNativeTableSchema
    ) {
        return Promise.all(
            updatedDocumentSnapshots.map(async (documentSnapshot) => {
                const tempItem: Item = documentSnapshot.data()

                Object.keys(currentTable.columns).map((column) => {
                    if (
                        (currentTable.columns[column] as DataType) ===
                        'DateTime'
                    ) {
                        return ConvertDateToISOString(
                            tempItem[column] as string
                        )
                    } else {
                        return tempItem[column] ?? ''
                    }
                })

                const record = await this.database.collections
                    .get(currentTable.name)
                    .find(documentSnapshot.id)
                // @ts-ignore
                return record.prepareUpdate((oldItem) => {
                    Object.keys(currentTable.columns).forEach((colName) => {
                        oldItem._raw[colName] = tempItem[colName]
                    })
                })
            })
        )
    }

    private calculateBatchCreate(
        newDocumentSnapshots: DocumentSnapshot<DocumentData>[],
        currentTable: ReactNativeTableSchema
    ) {
        return newDocumentSnapshots.map((documentSnapshot) => {
            const tempItem: Item = documentSnapshot.data()

            Object.keys(currentTable.columns).map((column) => {
                if ((currentTable.columns[column] as DataType) === 'DateTime') {
                    return ConvertDateToISOString(tempItem[column] as string)
                } else {
                    return tempItem[column] ?? ''
                }
            })

            return this.database.collections
                .get(currentTable.name)
                .prepareCreate((item) => {
                    Object.keys(currentTable.columns).forEach((colName) => {
                        item._raw.id = tempItem[appFormulaId]
                        item._raw[colName] = tempItem[colName]
                    })
                })
        })
    }

    private async calculateBatchDestroyPermanently(
        destroyDocumentSnapshots: DocumentSnapshot<DocumentData>[],
        currentTable: ReactNativeTableSchema
    ) {
        return Promise.all(
            destroyDocumentSnapshots.map(async (documentSnapshot) => {
                const record = await this.database.collections
                    .get(currentTable.name)
                    .find(documentSnapshot.id)
                return record.prepareDestroyPermanently()
            })
        )
    }

    private async storeTimeLatestSync() {
        try {
            const timeUnix = dayjs().valueOf()
            await storeData(
                `${TIME_LATEST_SYNC_UNIX}${this.teamId}${this.appId}`,
                timeUnix
            )
        } catch (error) {
            //
        }
    }

    private async getTimeLatestSync(): Promise<number> {
        try {
            return (
                (await getData(
                    `${TIME_LATEST_SYNC_UNIX}${this.teamId}${this.appId}`
                )) ?? FIRST_TIME_SYNC
            )
        } catch (error) {
            return FIRST_TIME_SYNC
        }
    }

    private async storeWMDBVersion(version: number) {
        try {
            await storeData(
                `${OLD_WMDB_VERSION}${this.teamId}${this.appId}`,
                version
            )
        } catch (error) {
            //
        }
    }

    private async getWMDBVersion(): Promise<number> {
        try {
            return (
                (await getData(
                    `${OLD_WMDB_VERSION}${this.teamId}${this.appId}`
                )) ?? FIRST_VERSION_WMDB
            )
        } catch (error) {
            return FIRST_VERSION_WMDB
        }
    }
}
