//
//  RefreshSchema.tsx
//
//  Created by thaitd96 on 2022-06-29 11:00.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import getFireStore from '../firebase/firestore/getFireStore'
import { Table } from '@appformula/app-descriptor/src'
import { Nullable } from '@appformula/shared-foundation-x/src'
import {
    DocumentData,
    DocumentSnapshot,
    QuerySnapshot,
    ReactNativeTableSchema,
} from '../../database/type'

// In this function, we use tableId as table name
export async function getCurrentSchema(
    teamId: string,
    appId: string
): Promise<{
    currentTableNames: string[]
    currentTables: ReactNativeTableSchema[]
}> {
    try {
        const schema: QuerySnapshot<DocumentData> = (await (
            await getFireStore()
        )
            // Temp while wait for Binh update
            .collection(`teams/${teamId}/tables`)
            // .collection(`teams/${teamId}/apps/${appId}/tables`)
            .get()) as unknown as QuerySnapshot<DocumentData>

        const currentTableNames = schema.docs.map(
            (querySnapshot) => querySnapshot.id
        )

        const currentTables = schema.docs.map((querySnapshot) => {
            const table = querySnapshot.data() as Table
            const columns: Record<string, unknown> = {}

            Object.entries(table?.schema?.fieldType || {}).forEach(
                ([columnName, tableFieldType]) => {
                    columns[columnName] = tableFieldType.type
                }
            )

            return {
                name: querySnapshot.id,
                // Add key if schema not have (this field use id of document)
                columns: { ...columns, key: 'TEXT' },
            }
        })

        return { currentTableNames, currentTables }
    } catch (error) {
        return Promise.reject(error)
    }
}

export async function getSchemaByTableId(
    teamId: string,
    tableId: string
): Promise<Nullable<ReactNativeTableSchema>> {
    try {
        const tableData: DocumentSnapshot<DocumentData> = (await (
            await getFireStore()
        )
            .collection(`teams/${teamId}/tables`)
            .doc(`${tableId}`)
            .get()) as unknown as DocumentSnapshot<DocumentData>

        const table = tableData.data() as Table
        const columns: Record<string, unknown> = {}

        Object.entries(table?.schema?.fieldType || {}).forEach(
            ([columnName, tableFieldType]) => {
                columns[columnName] = tableFieldType.type
            }
        )

        const tableSchema: ReactNativeTableSchema = {
            name: tableData.id,
            // Add key if schema not have (this field use id of document)
            columns: { ...columns, key: 'TEXT' },
        }

        return tableSchema
    } catch (error) {
        console.log('Error getCurrentSchemaByTableName', error)
        return undefined
    }
}
