//
//  OptionsFormInput.tsx
//
//  Created by thaitd96 on 2022-07-11 15:55.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { OptionsFormInputStore } from '@appformula/app-studio-core/src'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { randomTrueFalse } from '../../../../utils'
import { useBinding } from '../../../collection/components/hooks/useBinding'
import { ARNFormPageStore } from '../../ARNFormPageStore'
import { ARNOptionFormInputStore } from './ARNOptionFormInputStore'
import { OptionsFormInputPage } from './option-form-input-page'
import { OptionsFormInputInline } from './OptionFormInputInline'
const allowsMultipleSelection = randomTrueFalse

const OptionFormInput = observer(
    (props: {
        optionsFormInput: OptionsFormInputStore
        arnOptionFormInputStore: ARNOptionFormInputStore
        arnFormPageStore: ARNFormPageStore
    }) => {
        const { optionsFormInput, arnOptionFormInputStore } = props
        const defaultValue = useBinding(optionsFormInput?.defaultValue)
        useEffect(() => {
            const subscriptions = arnOptionFormInputStore.startListeningEvents()

            return () => {
                subscriptions.forEach((s) => s.unsubscribe())
            }
        }, [arnOptionFormInputStore])

        useEffect(() => {
            const options = arnOptionFormInputStore.computeOptions(
                allowsMultipleSelection,
                defaultValue as string[]
            )
            arnOptionFormInputStore.setOptions(options)
        }, [arnOptionFormInputStore, defaultValue, optionsFormInput.defaultValue])

        if (optionsFormInput.optionsInputType === 'inline') {
            return (
                <OptionsFormInputInline
                    optionsFormInput={optionsFormInput}
                    arnOptionFormInputStore={arnOptionFormInputStore}
                />
            )
        } else {
            return (
                <OptionsFormInputPage
                    optionsFormInput={optionsFormInput}
                    arnOptionFormInputStore={arnOptionFormInputStore}
                />
            )
        }
    }
)

export default OptionFormInput
