//
//  PeopleLookupPage.tsx
//
//  Created by thaitd96 on 2022-07-06 11:27.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import {
    FormPageStore,
    PeopleLookupFormInputStore,
} from '@appformula/app-studio-core'
import { useRoute } from '@react-navigation/native'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import React, { StyleSheet, View } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { MenuProvider } from 'react-native-popup-menu'
import { Theme, useTheme } from '../../../../styles/theme'
import { ARNFormPageStore } from '../../ARNFormPageStore'
import { SearchFormPage } from '../SearchFormPage'
import AddPeopleLookupEmail from './AddPeopleLookupEmail'
import { ARNPeopleLookupFormStore } from './ARNPeopleLookupFormStore'
import PeopleItemFormInput from './PeopleItemFormInput'
import { People } from './PeopleLookupFormInput'

const isMultipleChoice = true

type Params = {
    componentId: string
}

const PeopleLookupPage = observer(
    (props: {
        pageStore: FormPageStore
        arnFormPageStore: ARNFormPageStore
    }) => {
        const { arnFormPageStore, pageStore } = props
        const route = useRoute()
        const { componentId } = route.params as Params

        const peopleLookupFormInput = pageStore.componentStore(
            componentId
        ) as PeopleLookupFormInputStore

        const arnPeopleLookupFormStore =
            arnFormPageStore.childComponentFormStore(
                componentId,
                peopleLookupFormInput.inputType
            ) as ARNPeopleLookupFormStore

        return (
            <PeopleLookupPageContent
                arnPeopleLookupFormStore={arnPeopleLookupFormStore}
                peopleLookupFormInput={peopleLookupFormInput}
                isMultipleChoice={isMultipleChoice}
            />
        )
    }
)

type PeopleLookupPageContentProps = {
    arnPeopleLookupFormStore: ARNPeopleLookupFormStore
    peopleLookupFormInput: PeopleLookupFormInputStore
    isMultipleChoice: boolean
}

const PeopleLookupPageContent = observer(
    (props: PeopleLookupPageContentProps) => {
        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])
        const { arnPeopleLookupFormStore } = props
        const peoples = arnPeopleLookupFormStore.peoples || []

        const renderOptionItem = (item: People, index: number) => {
            return (
                <PeopleItemFormInput
                    key={index}
                    people={item}
                    index={index}
                    arnPeopleLookupFormStore={arnPeopleLookupFormStore}
                />
            )
        }

        return (
            <MenuProvider>
                <View style={styles.wrap}>
                    <SearchFormPage
                        inputType="peopleLookup"
                        placeholder="People Lookup"
                    />
                    <KeyboardAwareScrollView
                        style={styles.wrapList}
                        extraScrollHeight={150}
                    >
                        <ScrollView
                            style={styles.viewOptionsFormInput}
                            showsVerticalScrollIndicator={false}
                        >
                            {peoples && peoples.length > 0 ? (
                                <View>
                                    {peoples.map((item, index) =>
                                        renderOptionItem(item, index)
                                    )}
                                </View>
                            ) : null}
                             {props.peopleLookupFormInput
                                ?.allowsMentioningOutsider && (
                                <AddPeopleLookupEmail
                                    arnPeopleLookupFormStore={
                                        arnPeopleLookupFormStore
                                    }
                                />
                            )}
                        </ScrollView>
                    </KeyboardAwareScrollView>
                </View>
            </MenuProvider>
        )
    }
)

export default PeopleLookupPage

const createStyles = ({ colorScheme }: Theme) =>
    StyleSheet.create({
        wrap: { flex: 1, backgroundColor: colorScheme.backgroundL1 },
        otherEmail: { backgroundColor: 'red', height: 80, width: '100%' },
        wrapList: { flex: 1 },
        imageClose: { width: 24, height: 24, resizeMode: 'contain' },
        buttonClose: { position: 'absolute', top: 22, right: 10 },
        viewTitle: { flexDirection: 'row', alignItems: 'center' },
        requireDot: {
            height: 6,
            width: 6,
            backgroundColor: 'red',
            borderRadius: 6,
            marginLeft: 6,
        },
        viewOptionsFormInput: {
            backgroundColor: colorScheme.backgroundL1,
        },
        title: {
            fontSize: 16,
            lineHeight: 19,
            fontWeight: '500',
        },
    })
