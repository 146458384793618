import { FirebaseFirestoreTypes } from '@react-native-firebase/firestore'

export type Item = {
    [key: string]: string | number
}

export type Columns = {
    [key: string]: string
}

export type ColumnNames = {
    [key: string]: {
        name: string
        type: DataType
    }
}

export type ReactNativeTableSchema = {
    name: string
    columns: Columns
}

export type DocumentData = FirebaseFirestoreTypes.DocumentData

export type QuerySnapshot<T extends DocumentData> =
    FirebaseFirestoreTypes.QuerySnapshot<T>

export type DocumentSnapshot<T extends DocumentData> =
    FirebaseFirestoreTypes.DocumentSnapshot<T>

export interface SchemaChanges {
    isHaveDeleteOrChangeTableColumns: boolean
    addedTables?: ReactNativeTableSchema[]
    addedColumns?: Record<string, Columns>
}

export interface TableChanges {
    isHaveDeleteOrChangeColumn: boolean
    addedColumns: Columns
}

export const OLD_SCHEMA = 'OLD_SCHEMA'

export const updatedAt = '__updatedAt'

export const createdAt = '__createdAt'

export const isDeleted = '__isDeleted'

export const appFormulaId = 'f_85552c19f43203aa31af883abc56837d'

export const TIME_LATEST_SYNC_UNIX = 'TIME_LATEST_SYNC_UNIX'

export const OLD_WMDB_VERSION = 'OLD_WMDB_VERSION'

export type DataType = 'Number' | 'String' | 'Boolean' | 'DateTime' | 'List'

export const FIRST_VERSION_WMDB = 1

export const FIRST_TIME_SYNC = 0
