//
//  CustomPage.ts
//
//  Created by thaitd96 on 2022-08-10 14:24
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import {
    Barcode,
    BooleanInput,
    Button,
    Carousel,
    Chart,
    ComponentType,
    CustomPage,
    DateRangeInput,
    DateTimeInput,
    Divider,
    FilePreview,
    Image,
    InlineCollection,
    Map,
    Markdown,
    NumberScaleInput,
    NumberSliderInput,
    OptionsInput,
    PageComponent,
    PeopleInput,
    ProgressBar,
    Repeater,
    TextComponent,
    TextInput,
    Video,
    WebLink,
} from '@appformula/app-descriptor'
import {
    ComponentRepository,
    CustomPageRepository,
} from '@appformula/app-studio-core'
import { LocalBasePageRepository } from '../BaseRepository'
import { LocalPageComponentRepository } from './components/Base'
import { Audio } from '@appformula/app-descriptor'
import { LocalCollectionComponentRepository } from './components/CollectionComponent'
import { LocalChartRepository } from './components/Chart'
import { of } from 'rxjs'

export class LocalCustomPageRepository
    extends LocalBasePageRepository<CustomPage>
    implements CustomPageRepository
{
    componentRepository(
        componentId: string,
        componentType: ComponentType
    ): ComponentRepository {
        const component = this.objectSnapshot?.components?.[componentId]

        switch (componentType) {
            case 'audio':
                return new LocalPageComponentRepository<Audio>(
                    of(component as unknown as Audio),
                    component as unknown as Audio,
                    componentId
                )
            case 'button':
                return new LocalPageComponentRepository<Button>(
                    of(component as unknown as Button),
                    component as unknown as Button,
                    componentId
                )
            case 'carousel':
                return new LocalPageComponentRepository<Carousel>(
                    of(component as unknown as Carousel),
                    component as unknown as Carousel,
                    componentId
                )
            case 'chart':
                return new LocalChartRepository(
                    of(component as unknown as Chart),
                    component as unknown as Chart,
                    componentId
                )
            case 'filePreview':
                return new LocalPageComponentRepository<FilePreview>(
                    of(component as unknown as FilePreview),
                    component as unknown as FilePreview,
                    componentId
                )
            case 'image':
                return new LocalPageComponentRepository<Image>(
                    of(component as unknown as Image),
                    component as unknown as Image,
                    componentId
                )
            case 'inlineCollection':
                return new LocalCollectionComponentRepository<InlineCollection>(
                    of(component as unknown as InlineCollection),
                    component as unknown as InlineCollection,
                    componentId
                )
            case 'map':
                return new LocalCollectionComponentRepository<Map>(
                    of(component as unknown as Map),
                    component as unknown as Map,
                    componentId
                )
            case 'markdown':
                return new LocalPageComponentRepository<Markdown>(
                    of(component as unknown as Markdown),
                    component as unknown as Markdown,
                    componentId
                )
            case 'repeater':
                return new LocalPageComponentRepository<Repeater>(
                    of(component as unknown as Repeater),
                    component as unknown as Repeater,
                    componentId
                )
            case 'text':
                return new LocalPageComponentRepository<TextComponent>(
                    of(component as unknown as TextComponent),
                    component as unknown as TextComponent,
                    componentId
                )
            case 'video':
                return new LocalPageComponentRepository<Video>(
                    of(component as unknown as Video),
                    component as unknown as Video,
                    componentId
                )
            case 'webLink':
                return new LocalPageComponentRepository<WebLink>(
                    of(component as unknown as WebLink),
                    component as unknown as WebLink,
                    componentId
                )
            case 'barcode':
                return new LocalPageComponentRepository<Barcode>(
                    of(component as unknown as Barcode),
                    component as unknown as Barcode,
                    componentId
                )
            case 'booleanInput':
                return new LocalPageComponentRepository<BooleanInput>(
                    of(component as unknown as BooleanInput),
                    component as unknown as BooleanInput,
                    componentId
                )
            case 'dateRangeInput':
                return new LocalPageComponentRepository<DateRangeInput>(
                    of(component as unknown as DateRangeInput),
                    component as unknown as DateRangeInput,
                    componentId
                )
            case 'dateTimeInput':
                return new LocalPageComponentRepository<DateTimeInput>(
                    of(component as unknown as DateTimeInput),
                    component as unknown as DateTimeInput,
                    componentId
                )
            case 'divider':
                return new LocalPageComponentRepository<Divider>(
                    of(component as unknown as Divider),
                    component as unknown as Divider,
                    componentId
                )
            case 'numberScaleInput':
                return new LocalPageComponentRepository<NumberScaleInput>(
                    of(component as unknown as NumberScaleInput),
                    component as unknown as NumberScaleInput,
                    componentId
                )
            case 'numberSliderInput':
                return new LocalPageComponentRepository<NumberSliderInput>(
                    of(component as unknown as NumberSliderInput),
                    component as unknown as NumberSliderInput,
                    componentId
                )
            case 'optionsInput':
                return new LocalPageComponentRepository<OptionsInput>(
                    of(component as unknown as OptionsInput),
                    component as unknown as OptionsInput,
                    componentId
                )
            case 'peopleInput':
                return new LocalPageComponentRepository<PeopleInput>(
                    of(component as unknown as PeopleInput),
                    component as unknown as PeopleInput,
                    componentId
                )
            case 'progressBar':
                return new LocalPageComponentRepository<ProgressBar>(
                    of(component as unknown as ProgressBar),
                    component as unknown as ProgressBar,
                    componentId
                )
            case 'textInput':
                return new LocalPageComponentRepository<TextInput>(
                    of(component as unknown as TextInput),
                    component as unknown as TextInput,
                    componentId
                )
            default:
                throw Error('')
        }
    }
    insertComponent(
        componentId: string,
        component: PageComponent,
        position: string
    ): Promise<void> {
        throw new Error('Method not implemented.')
    }
    setComponentPosition(componentId: string, position: string): Promise<void> {
        throw new Error('Method not implemented.')
    }
    removeComponent(componentId: string): Promise<void> {
        throw new Error('Method not implemented.')
    }
}
