//
//  AppStore.ts
//
//  Created by Peter Vu on 2022-03-28 11:01:51.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { action, computed, makeObservable, observable } from 'mobx'
import auth, { FirebaseAuthTypes } from '@react-native-firebase/auth'
import { AppResourcesStore } from './AppResourceStore'
import { apiClient } from '../utils/network'
import { Platform } from 'react-native'
import firebase from 'firebase'
import { getBaseOs } from 'react-native-device-info'
import { DesktopOS } from './type'
import { generateRandomId } from '../utils'
import getFireStore from '../utils/firebase/firestore/getFireStore'
import {
    AppConfig,
    AuthProviderConfig,
    WelcomePageStore,
} from '../pages/authentication/WelcomePageStore'
import { Nullable } from '@appformula/shared-foundation-x'
import { FirebaseFirestoreTypes } from '@react-native-firebase/firestore'
import appConstants from '../constants/const'
import cache from '../utils/cache'

interface IInfoUpdate {
    displayName?: string | null
    photoURL?: string | null
}

export enum UserAuthenticationState {
    IsNotVerify,
    IsExternal,
    IsTeamMember,
    IsNotTeamMember,
}

export class AppStore {
    readonly appResourcesStore = new AppResourcesStore()
    private _auth: FirebaseAuthTypes.Module | firebase.auth.Auth
    authenticatedUser: FirebaseAuthTypes.User | firebase.User
    private _authSubscriptionCancel
    private _userSubscriptionCancel
    authProviderConfig: AuthProviderConfig = {}
    unsubscribes: (() => void)[] = []
    isCustomizedProfile: boolean = true
    private cachedWelcomePageStore: Nullable<WelcomePageStore>
    teamInfoUnsubscribe: Nullable<() => void>
    isTeamMember: UserAuthenticationState = UserAuthenticationState.IsNotVerify

    // Can not observer user change on WEB
    changeProfile: string = ''

    constructor(options: {
        auth: FirebaseAuthTypes.Module | firebase.auth.Auth
    }) {
        this._auth = options.auth
        this.authenticatedUser = options.auth.currentUser

        makeObservable(this, {
            changeProfile: observable,
            authenticatedUser: observable,
            setAuthenticatedUser: action,

            authProviderConfig: observable.struct,
            setAuthProviderConfig: action,
            unsubscribes: observable,
            setUnsubscribe: action,
            observeConfig: action,
            unobserveConfig: action,

            isCustomizedProfile: observable,
            setIsCustomizedProfile: action,
            getCurrentUser: computed,
            isTeamMember: observable,
            setTeamMember: action,
        })

        if (Platform.OS !== 'web') {
            this._userSubscriptionCancel = (
                options.auth as FirebaseAuthTypes.Module
            ).onUserChanged((user) => {
                this.setAuthenticatedUser(user)
                this.getAndSetBaseUrl()
                if (user) {
                    this.setAuthenticationHeader()
                }
            })
        } else {
            this._authSubscriptionCancel = (
                options.auth as firebase.auth.Auth
            ).onIdTokenChanged(
                (user: FirebaseAuthTypes.User | firebase.User) => {
                    this.setAuthenticatedUser(user)
                    this.getAndSetBaseUrl()
                    this.setAuthenticationHeader()
                }
            )
        }
    }

    onClean() {
        this._authSubscriptionCancel()
        this._userSubscriptionCancel()
    }

    clearWelcomePageStore = () => {
        this.cachedWelcomePageStore = undefined
    }

    welcomePageStore(): WelcomePageStore {
        if (this.cachedWelcomePageStore) {
            return this.cachedWelcomePageStore
        } else {
            const newWelcomePageStore = new WelcomePageStore({
                auth: Platform.OS === 'web' ? firebase.auth() : auth(),
                appStore: this,
            })
            this.cachedWelcomePageStore = newWelcomePageStore
            return newWelcomePageStore
        }
    }

    setAuthProviderConfig(authProviderConfig: AuthProviderConfig) {
        this.authProviderConfig = authProviderConfig
    }

    setUnsubscribe(unsubscribes: (() => void)[]) {
        this.unsubscribes = unsubscribes
    }

    setTeamMember(isTeamMember: UserAuthenticationState) {
        this.isTeamMember = isTeamMember
    }

    async listenTeamInfo() {
        try {
            const firestore = await getFireStore()
            if (this.teamInfoUnsubscribe) {
                this.teamInfoUnsubscribe()
            }
            // @ts-ignore
            const teamId = global.teamId
            // @ts-ignore
            const appId = global.appId
            this.teamInfoUnsubscribe = firestore
                .collection(`teams/${teamId}/apps`)
                .doc(appId)
                .onSnapshot({
                    next: (
                        querySnapshot: FirebaseFirestoreTypes.DocumentSnapshot<FirebaseFirestoreTypes.DocumentData>
                    ) => {
                        const appConfig = querySnapshot?.data() as AppConfig
                        if (appConfig?.type === 'external') {
                            this.setTeamMember(
                                UserAuthenticationState.IsExternal
                            )
                        } else if (appConfig?.type === 'internal') {
                            firestore
                                .collection(`teams/${teamId}/members`)
                                .onSnapshot({
                                    next: (querySnapshot) => {
                                        const listTeamMember: Record<
                                            string,
                                            string
                                        > = {}
                                        querySnapshot?.docs.forEach((item) => {
                                            const uid = item.id
                                            listTeamMember[uid] = uid
                                        })
                                        if (
                                            this.authenticatedUser?.uid in
                                            listTeamMember
                                        ) {
                                            this.setTeamMember(
                                                UserAuthenticationState.IsTeamMember
                                            )
                                        } else {
                                            this.setTeamMember(
                                                UserAuthenticationState.IsNotTeamMember
                                            )
                                        }
                                    },
                                    error: (e) =>
                                        console.error(
                                            'Check Team member error:',
                                            e
                                        ),
                                })
                        }
                    },
                    error: (e) => console.error('Check App type error:', e),
                })
        } catch (error) {
            console.info(error)
            return
        }
    }

    async observeConfig() {
        try {
            // @ts-ignore
            const teamId = global.teamId
            const unsubscribe = (await getFireStore())
                .collection(`teams`)
                .doc(teamId)
                .onSnapshot({
                    next: (querySnapshot) => {
                        if (querySnapshot.data()) {
                            const appConfig = querySnapshot?.data() as AppConfig
                            if (appConfig?.authProviderConfig) {
                                this.setAuthProviderConfig(
                                    appConfig.authProviderConfig as AuthProviderConfig
                                )
                            }
                        }
                    },
                })

            this.setUnsubscribe([...this.unsubscribes, unsubscribe])
        } catch (error) {
            console.warn('Error get AuthProviderConfig:', error)
        }
    }

    async getAndSetBaseUrl() {
        try {
            const unsubscribe = (await getFireStore())
                .collection(`teams`)
                // @ts-ignore: global variable
                .doc(global.teamId)
                .collection(`apps`)
                // @ts-ignore: global variable
                .doc(global.appId)
                .onSnapshot({
                    next: (querySnapshot) => {
                        if (querySnapshot.data()) {
                            const appInfo = querySnapshot?.data()
                            const appDomainPrefix = appInfo?.['appDomainPrefix']
                            const baseUrl = `https://${appDomainPrefix}${appConstants.API.SUF_DOMAIN}`

                            cache.baseUrl = baseUrl
                        }
                    },
                    error: (error) => {
                        console.info('error=====', error)
                    },
                })

            this.setUnsubscribe([...this.unsubscribes, unsubscribe])
        } catch (error) {
            console.warn('Error get AuthProviderConfig:', error)
        }
    }

    unobserveConfig() {
        this.unsubscribes.forEach((unsubscribe) => {
            unsubscribe()
        })
    }

    setIsCustomizedProfile(isCustomizedProfile: boolean) {
        this.isCustomizedProfile = isCustomizedProfile
    }

    setAuthenticatedUser(newUser: FirebaseAuthTypes.User | firebase.User) {
        this.authenticatedUser = newUser
    }

    async setAuthenticationHeader() {
        if (this.authenticatedUser) {
            const token = await (
                this.authenticatedUser as FirebaseAuthTypes.User
            )?.getIdToken(true)
            apiClient.setHeader(token)
        }
    }

    async logout() {
        this.setTeamMember(UserAuthenticationState.IsNotVerify)
        return this._auth.signOut()
    }

    async isDesktop() {
        const baseOs = await getBaseOs()
        return baseOs === DesktopOS.macOs || baseOs === DesktopOS.windows
    }

    async update(info: IInfoUpdate) {
        try {
            if (Platform.OS === 'web') {
                await (
                    this._auth as firebase.auth.Auth
                ).currentUser?.updateProfile(info)

                const user = await (this._auth as firebase.auth.Auth)
                    .currentUser
                if (user) {
                    this.setAuthenticatedUser(user)
                }
                this.changeProfile = generateRandomId()
                return Promise.resolve(true)
            } else {
                await (
                    this.authenticatedUser as FirebaseAuthTypes.User
                ).updateProfile(info)
                await (
                    this.authenticatedUser as FirebaseAuthTypes.User
                ).reload()
                return Promise.resolve(true)
            }
        } catch (error) {
            return Promise.reject(error)
        }
    }

    get getCurrentUser() {
        return this._auth.currentUser
    }
}
