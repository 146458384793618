//
//  ChooseColumnToAddCondition.web.tsx
//
//  Created by thaitd96 on 2022-09-30 14:01.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React from 'react'
import {
    Menu,
    MenuOptions,
    MenuTrigger,
    renderers,
} from 'react-native-popup-menu'
import { Image, Pressable, StyleSheet, Text } from 'react-native'
import image from '../../assets/icons'
import { TouchableOpacity } from '@gorhom/bottom-sheet'
import { Theme, useTheme } from '../../styles/theme'
import MenuOptionItems from './MenuOptionItems'
import { COLORS } from '../../assets/colors'
const { Popover } = renderers

export type ActionType = {
    title: string
    key: number
    systemIcon?: string
}

export type ContextMenuCustomProps = {
    dataActions: ActionType[]
    customSelector?: React.ReactNode
    dropdownMenuMode: boolean
    onSelect: (value: number) => void
    currentValue: number
    initialValue: number
}

const ContextMenuCustom = (props: ContextMenuCustomProps) => {
    const theme = useTheme()
    const styles = React.useMemo(() => createStyles(theme), [theme])

    const { dataActions, onSelect, initialValue, currentValue } = props

    const ref = React.useRef<Menu>(null)

    const handleSelect = React.useCallback(
        async (value: number) => {
            if (typeof onSelect === 'function') {
                onSelect(value)
            }
            await ref?.current?.close()
        },
        [onSelect]
    )

    return (
        <Menu
            ref={ref}
            renderer={Popover}
            rendererProps={{
                placement: 'bottom',
                anchorStyle: { backgroundColor: COLORS.TRANSPARENT },
            }}
        >
            <MenuTrigger
                customStyles={{
                    TriggerTouchableComponent: Pressable,
                }}
            >
                <TouchableOpacity style={styles.touchSelector}>
                    <Text style={styles.txtSelector}>
                        {
                            dataActions.find(
                                (item) =>
                                    item.key === (currentValue || initialValue)
                            )?.title
                        }
                    </Text>
                    <Image
                        source={image.dropdownFill}
                        style={styles.iconDropDown}
                    />
                </TouchableOpacity>
            </MenuTrigger>
            <MenuOptions
                renderOptionsContainer={() => (
                    <MenuOptionItems
                        actions={dataActions}
                        onSelect={handleSelect}
                    />
                )}
            />
        </Menu>
    )
}

export default ContextMenuCustom

const createStyles = ({ colorScheme, roundness }: Theme) => {
    return StyleSheet.create({
        touchSelector: {
            alignSelf: 'baseline',
            flexDirection: 'row',
            paddingLeft: 12,
            paddingRight: 8,
            marginTop: 8,
            backgroundColor: colorScheme.backgroundL2,
            borderRadius: roundness,
            height: 40,
            alignItems: 'center',
        },

        txtSelector: {
            color: colorScheme.accentColor,
            fontWeight: '500',
            fontSize: 15,
            lineHeight: 18.15,
        },

        iconDropDown: {
            height: 24,
            width: 24,
            resizeMode: 'contain',
            tintColor: colorScheme.accentColor,
        },
    })
}
