import { GeofencingEventType, LocationRegion } from 'expo-location'

export const GEOFENCING_TASK = 'GEOFENCING_TASK'

export type Data = {
    eventType: GeofencingEventType
    region: LocationRegion
}

export type Identifier = {
    id: string
    notifyOnEnter: boolean
    notifyOnExit: boolean
}
