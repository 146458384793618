//
//  ARNSignatureDrawingFormStore.tsx
//
//  Created by thaitd96 on 2022-07-13 14:25.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import {
    BehaviorSubject,
    Observable,
    debounceTime,
    merge,
    map,
    retry,
    timeout,
    Subject,
    distinctUntilChanged,
    Subscription,
    switchMap,
    from,
    filter,
} from 'rxjs'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import appConstants from '../../../../constants/const'
import { ARNFormPageStore } from '../../ARNFormPageStore'
import file from '../../../../utils/file/index.web'
import { FORM_URL } from '../../../../utils/network/apis/FormUrl'
import { BaseARNFormStore } from '../BaseARNFormStore'
import { ResponseUpload, ResponseUploadData } from '../../../../utils'
import { FormulaManager } from '../../../../utils/integrate-formula/FormulaManager'
import { SaveEventParams } from 'react-native-signature-capture'

export class ARNSignatureDrawingFormStore implements BaseARNFormStore {
    readonly formPageId: string
    readonly componentId: string
    arnFormPageStore: ARNFormPageStore
    get base64String(): Observable<string | undefined> {
        return this.base64StringSubject.asObservable()
    }

    get uploadedURL(): URL | undefined {
        return this.uploadedURLSubject.value
    }

    private base64StringSubject: BehaviorSubject<string | undefined> =
        new BehaviorSubject<string>(undefined)
    private uploadedURLSubject: BehaviorSubject<URL | undefined> =
        new BehaviorSubject(undefined)

    touchStartEvent: Subject<void>
    dragEvent: Subject<void>
    saveEvent: Observable<void>
    clearEvent: Subject<void>
    isPlaceholderVisibleValue: boolean
    isHavePubsubSignal: boolean
    isDragged: boolean
    private isPlaceholderVisible: Observable<boolean>
    base64StringValue: string | undefined
    formulaManager?: FormulaManager
    // pathNameValue unused in this (web) platform
    pathNameValue: string

    constructor(
        arnFormPageStore: ARNFormPageStore,
        formPageId: string,
        componentId: string,
        formulaManager?: FormulaManager
    ) {
        this.arnFormPageStore = arnFormPageStore
        this.formPageId = formPageId
        this.componentId = componentId
        this.touchStartEvent = new Subject()
        this.dragEvent = new Subject()
        this.clearEvent = new Subject()
        this.isPlaceholderVisibleValue = true
        this.isHavePubsubSignal = false
        this.isDragged = false
        this.base64StringValue = ''
        this.formulaManager = formulaManager

        const pubsubSignal = new Observable<void>((subscriber) => {
            const token = PubSub.subscribe(appConstants.TOPIC_SIGN, () => {
                subscriber.next()
                this.isHavePubsubSignal = true
            })

            return () => {
                PubSub.unsubscribe(token)
            }
        })

        const debouncedDrag = this.dragEvent.pipe(debounceTime(3000))
        this.saveEvent = merge(debouncedDrag, pubsubSignal)

        this.isPlaceholderVisible = merge(
            this.clearEvent.pipe(map(() => true)),
            this.touchStartEvent.pipe(map(() => false))
        ).pipe(distinctUntilChanged())

        makeObservable(this, {
            uploadedURL: computed,
            isPlaceholderVisibleValue: observable,
            base64StringValue: observable,
            isDragged: observable,
            isHavePubsubSignal: observable,
            resetIsHavePubsubSignal: action,
        })
    }

    resetIsHavePubsubSignal = () => {
        this.isHavePubsubSignal = false
    }

    startListeningDataChange(): Subscription[] {
        const placeholderSubscription = this.isPlaceholderVisible.subscribe({
            next: (newValue) => {
                runInAction(() => {
                    this.isPlaceholderVisibleValue = newValue
                })
            },
        })

        const base64StringSubscription = this.base64String.subscribe({
            next: (newValue) => {
                runInAction(() => {
                    this.base64StringValue = newValue
                })
            },
        })

        const clearEventSubscription = this.clearEvent.subscribe({
            next: () => {
                this.base64StringSubject.next(undefined)
                this.isDragged = false
            },
        })

        const dragEventSubscription = this.dragEvent.subscribe({
            next: () => {
                this.isDragged = true
                this.arnFormPageStore.clearFormScreenError(this.componentId)
            },
        })

        const uploadSubscription = this.base64String
            .pipe(
                filter((value) => !!value),
                debounceTime(0), // Prevent switchMap run twice for each base64String change
                switchMap((base64String) => {
                    return this.uploadToStorage(base64String).pipe(
                        timeout(10000),
                        retry(5)
                    )
                })
            )
            .subscribe({
                next: (uploadedURL) => {
                    this.uploadedURLSubject.next(uploadedURL)
                },
            })

        return [
            placeholderSubscription,
            clearEventSubscription,
            dragEventSubscription,
            uploadSubscription,
            base64StringSubscription,
        ]
    }

    private uploadToStorage(base64String: string): Observable<URL | undefined> {
        return from(
            (async () => {
                try {
                    const blob = await (await fetch(base64String)).blob()

                    const response = await file.uploadFileBlob({
                        data: blob,
                        url: FORM_URL.uploadFile,
                        options: { isHandleError: false },
                    })

                    const url =
                        (
                            JSON.parse(
                                (response as ResponseUpload).data
                            ) as ResponseUploadData
                        ).assetUrl ?? ''

                    console.info('url', url)
                    return new URL(url)
                } catch (error) {
                    console.info('error', error)
                    return undefined
                } finally {
                    PubSub.publish(appConstants.TOPIC_SAVE_SIGN_DONE)
                }
            })()
        )
    }

    // SaveEventParams unused in this (web) platform
    onSignatureSave(base64String: string | SaveEventParams) {
        this.base64StringSubject.next(base64String as string)
    }

    async validate(): Promise<void> {
        const componentStore = this.arnFormPageStore.pageStore.componentStore(
            this.componentId
        )

        if (componentStore.required && !this.isDragged) {
            return Promise.reject(`${componentStore.titleName} is required!`)
        }
    }
}
