//
//  FilePreview.tsx
//
//  Created by thaitd96 on 2022-20-04 11:45.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React, { useMemo, useState } from 'react'
import { Image, Pressable, StyleSheet, Text, View } from 'react-native'
import { FilePreviewStore } from '@appformula/app-studio-core'
import appConstants from '../../../../constants/const'
import { Theme, useTheme } from '../../../../styles/theme'
import { observer } from 'mobx-react-lite'
import { fileNameFromUrl, getExtension } from '../../../../utils'
import image from '../../../../assets/icons'
import * as Progress from 'react-native-progress'
import { apiClient, IProgressEvent } from '../../../../utils/network'
import fileDownload from 'js-file-download'
import { useBindingCommon } from '../../../collection/components/hooks/useBindingCommon'
import { useWidthHeightMainLayout } from '../../../collection/components/hooks/useSpecifyPlatformWindowDimensions'
import IconFile, { IconFileType } from './IconFile'

export const FilePreview = observer(
    (props: {
        filePreview: FilePreviewStore
        item: Record<string, unknown>
        isViewHorizontal?: boolean
    }) => {
        const { filePreview, item, isViewHorizontal } = props
        const filePreviewData = filePreview.object || filePreview
        const { width } = useWidthHeightMainLayout()

        const theme = useTheme()
        const styles = useMemo(
            () => createStyles(theme, width, isViewHorizontal),
            [isViewHorizontal, theme, width]
        )

        const [isDownloading, setIsDownloading] = useState(false)
        const [progress, setProgress] = useState(0)

        const fileUrl = useBindingCommon(filePreviewData.fileUrl, item)
        const isHidden = useBindingCommon(filePreviewData.isHidden, item)

        const fileName = fileNameFromUrl(fileUrl as string)

        const onHandleView = () => {
            const url = fileUrl?.toString() || ''
            setIsDownloading(true)
            apiClient
                .get(url, {
                    responseType: 'blob',
                    handleProgressDownload,
                })
                .then((response) => {
                    fileDownload(response.data, fileName)
                })
                .finally(() => {
                    setIsDownloading(false)
                })
        }

        const handleProgressDownload = (progressEvent: IProgressEvent) => {
            const loaded = progressEvent?.loaded || 1
            const total = progressEvent?.total || 1
            setProgress(loaded / total)
        }

        return (
            !isHidden && (
                <View style={styles.viewFilePreview}>
                    <View style={styles.fileDetail}>
                        {
                            <IconFile
                                type={getExtension(fileName) as IconFileType}
                            />
                        }
                        <View style={styles.fileWrapTxt}>
                            <Text style={styles.fileName}>{fileName}</Text>
                            <Text style={styles.fileUrlTxt} numberOfLines={1}>
                                {fileUrl}
                            </Text>
                        </View>
                    </View>
                    {isDownloading ? (
                        <Progress.Circle
                            size={30}
                            indeterminate={false}
                            progress={progress}
                        />
                    ) : (
                        <Pressable onPress={onHandleView} hitSlop={20}>
                            <Text style={styles.txtViewFile}>View</Text>
                        </Pressable>
                    )}
                </View>
            )
        )
    }
)

const createStyles = (
    { colorScheme, roundness, typography }: Theme,
    width: number,
    isViewHorizontal?: boolean
) =>
    StyleSheet.create({
        viewFilePreview: {
            marginVertical: appConstants.PADDING_COMMON / 2,
            borderWidth: 1,
            padding: 16,
            borderRadius: roundness,
            borderColor: colorScheme.separator,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: isViewHorizontal ? width - 100 : null,
        },

        fileDetail: {
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            marginRight: 8,
        },

        fileName: {
            fontWeight: '500',
            fontSize: 15,
            lineHeight: 18,
            color: colorScheme.labelPrimary,
            marginBottom: 6,
        },

        image: {
            height: 28,
            width: 28,
            marginRight: 8,
        },

        fileWrapTxt: { flexShrink: 1, marginLeft: 11 },

        fileUrlTxt: {
            fontWeight: '500',
            fontSize: 13,
            lineHeight: 15.73,
            color: colorScheme.labelSecondary,
            maxWidth: '90%',
        },

        txtViewFile: {
            fontWeight: '500',
            fontSize: 15,
            color: colorScheme.accentColor,
            paddingVertical: 10,
        },
    })
