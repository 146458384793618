import { View, StyleSheet, Platform } from 'react-native'
import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { VideoStore } from '@appformula/app-studio-core/src'
import appConstants from '../../../../../constants/const'
import Player from './Player'
import { COLORS } from '../../../../../assets/colors'
import { useWidthHeightMainLayout } from '../../../../collection/components/hooks/useSpecifyPlatformWindowDimensions'
import { Theme, useTheme } from '../../../../../styles/theme'

const Vimeo = observer(
    (props: {
        video: VideoStore
        item: Record<string, unknown>
        videoURL: string
        title: string
        thumbnailURL: string
    }) => {
        const { videoURL } = props
        const { width, height } = useWidthHeightMainLayout()
        const theme = useTheme()
        const styles = useMemo(
            () => createStyles(theme, width, height),
            [height, theme, width]
        )

        return (
            <View style={styles.container} removeClippedSubviews>
                <Player
                    videoUrl={videoURL as string}
                    {...(Platform.OS !== 'web' && {
                        height: 211,
                        width: width - 32,
                    })}
                />
            </View>
        )
    }
)

export default Vimeo

const createStyles = (
    { colorScheme, roundness, typography }: Theme,
    width: number,
    height: number
) =>
    StyleSheet.create({
        container: {
            marginVertical: appConstants.PADDING_COMMON / 2,
            borderRadius: roundness,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: COLORS.BLACK,

            width: width - 32,
            height: 211,
            ...Platform.select({
                web: {
                    width: null,
                    height: null,
                    aspectRatio: (width - 32) / 211,
                },
            }),
            overflow: 'hidden',
        },
    })
