//
//  FilterDateStore.ts
//
//  Created by thaitd96 on 2022-07-28 11:28.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { Nullable } from '@appformula/shared-foundation-x/src'
import { action, makeObservable, observable } from 'mobx'
import { ColumnFilter, FilterDateData } from '../CollectionFilterStore'
import appConstants from '../../../../../constants/const'

export class FilterDateStore {
    readonly collectionPageId: string
    readonly columnFilter: ColumnFilter
    startDate: Nullable<Date>
    endDate: Nullable<Date>
    type: number

    constructor(collectionPageId: string, filterDateData: FilterDateData) {
        this.collectionPageId = collectionPageId
        this.columnFilter = filterDateData.columnFilter
        this.startDate = filterDateData.startDate
        this.endDate = filterDateData.endDate
        this.type =
            filterDateData.type ?? appConstants.DATE_COMPARE_TYPE.BETWEEN

        makeObservable(this, {
            startDate: observable,
            endDate: observable,
            setStartDate: action,
            setEndDate: action,
            type: observable,
            setType: action,
        })
    }

    setStartDate(startDate: Date) {
        this.startDate = startDate
    }

    setEndDate(endDate: Date) {
        this.endDate = endDate
    }

    setType(type: number) {
        this.type = type
    }
}
