import { View, Text, Pressable, StyleSheet, Image } from 'react-native'
import React, { forwardRef, useEffect, useImperativeHandle, useMemo } from 'react'
import { ILookup } from '.'
import { Theme, useTheme } from '../../../../../styles/theme'
import image from '../../../../../assets/icons'
export interface ISingleChoiceItem {
    checked: boolean
    unCheck: () => void
    item: ILookup
}

const SingleChoiceItem = forwardRef(
    (
        props: {
            item: ILookup
            changeSelected?: (id: string) => void
            isActive: boolean
        },
        ref
    ) => {
        const { item, changeSelected, isActive } = props
        const [checked, setChecked] = React.useState(isActive)

        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])

        useImperativeHandle(ref, () => ({
            unCheck: () => {
                return unCheck()
            },
            checked,
            item
        }))

        const unCheck = () => {
            setChecked(false)
        }

        useEffect(() => {
            if (checked) {
                changeSelected && changeSelected(item.id)
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [checked])

        const handleCheck = () => {
            // Only change checked to true here. If checked is true, nothing change
            setChecked(true)
        }

        return (
            <Pressable onPress={handleCheck}>
                <View style={styles.viewBooleanFieldCheckBox}>
                    <Text style={styles.title}>{item.content}</Text>
                    {checked && <Image source={image.checked} style={styles.imgChecked} />}
                </View>
            </Pressable>
        )
    }
)

SingleChoiceItem.displayName = 'SingleChoiceItem'

export default SingleChoiceItem

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        viewBooleanFieldCheckBox: {
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderBottomWidth: 1,
            borderBottomColor: colorScheme.separator,
        },

        title: {
            fontWeight: '400',
            fontSize: 15,
            lineHeight: 18,
            paddingVertical: 18,
            color: colorScheme.labelPrimary
        },

        imgChecked: { width: 24, height: 24, tintColor: colorScheme.accentColor, marginHorizontal: 12 },
    })
