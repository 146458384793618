//
//  Photos.ts
//
//  Created by thaitd96 on 2022-08-10 14:24
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { PhotosFormInput } from '@appformula/app-descriptor'
import { PhotosFormInputRepository } from '@appformula/app-studio-core'
import { LocalFormInputRepository } from '../FormInputRepository'

export class LocalPhotosFormInputRepository
    extends LocalFormInputRepository<PhotosFormInput>
    implements PhotosFormInputRepository
{
    removeExcludeSource(source: 'photoLibrary' | 'camera'): Promise<void> {
        throw new Error('Method not implemented.')
    }
    addExcludeSource(source: 'photoLibrary' | 'camera'): Promise<void> {
        throw new Error('Method not implemented.')
    }
}
