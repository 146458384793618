//
//  ButtonMore.web.tsx
//
//  Created by thaitd96 on 2022-09-08 10:40.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React, { Image, StyleSheet, Text, View } from 'react-native'
import { ItemAction } from '@appformula/app-descriptor/src'
import { observer } from 'mobx-react-lite'
import { actionIcon } from '../../../../../../assets/icons/icon-actions'
import { Theme, useTheme } from '../../../../../../styles/theme'
import { useMemo } from 'react'
import { ButtonMoreActions } from '../../../../../../components/button-more-actions/ButtonMoreActions'
import { hexToRGBA } from '../../../../../../utils'

export const ButtonMore = observer(
    (props: {
        itemActions: ItemAction[]
        itemData: Record<string, unknown>
    }) => {
        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])
        return (
            <ButtonMoreActions
                itemActions={props.itemActions}
                triggerComponent={
                    <View style={styles.hiddenItemAction}>
                        <Image
                            source={actionIcon.moreActionList}
                            style={styles.more}
                        />
                        <Text style={styles.itemActionTitle}>{'More'}</Text>
                    </View>
                }
                itemData={props.itemData}
            />
        )
    }
)
const createStyles = ({ colorScheme }: Theme) =>
    StyleSheet.create({
        hiddenItemAction: {
            borderRightWidth: 2,
            borderRightColor: hexToRGBA(colorScheme.accentColor as string, 0.1),
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: 120,
        },
        more: {
            height: 30,
            width: 30,
            resizeMode: 'contain',
            tintColor: colorScheme.accentColor,
        },
        itemActionTitle: {
            marginTop: 6,
            color: colorScheme.accentColor,
            fontSize: 14,
            fontWeight: '500',
        },
    })
