import { ColorValue, useColorScheme } from 'react-native'
import { FontFamily, TextStyles, TypographySettings } from './typography'
import { ColorScheme } from './colors'
import { InterFontFamily } from '../assets/fonts/InterFontFamily'
import { useContext } from 'react'
import { AppDescriptorContext } from '../context/AppDescriptorContext'
import { AppDescriptorStore } from '@appformula/app-studio-core'

export interface Theme {
    readonly colorScheme: ColorScheme
    readonly typography: TypographySettings
    readonly roundness: number
}

export const useTheme = () => {
    const colorSchemeName = useColorScheme()
    const appDescriptorStore: AppDescriptorStore =
        useContext(AppDescriptorContext)
    const accentColor: string = appDescriptorStore.accentColor || '#1F63E7' // TODO: Inject brand color here

    switch (colorSchemeName) {
        case 'dark':
            return new DarkTheme({ accentColor: accentColor })
        case 'light':
            return new LightTheme({ accentColor: accentColor })
        default:
            return new LightTheme({ accentColor: accentColor })
    }
}

class DefaultTypographySettings implements TypographySettings {
    readonly fontFamily: FontFamily
    readonly textStyles: TextStyles

    constructor() {
        this.fontFamily = new InterFontFamily()
    }
}

class DarkTheme implements Theme {
    readonly colorScheme: ColorScheme
    readonly typography: TypographySettings
    readonly roundness: number

    constructor(options: { accentColor: ColorValue }) {
        this.roundness = 8
        this.typography = new DefaultTypographySettings()
        this.colorScheme = {
            accentColor: options.accentColor,
            backgroundL1: '#191A1C',
            backgroundL2: '#292B2E',
            backgroundSidebar: '#191A1C',
            groupedBackground: '#0B0C0D',
            labelPrimary: '#EBEBEB',
            labelSecondary: '#858585',
            separator: '#403E3E',
            error: '#B91615',
            // Added by thaitd96 from figma
            starRating: '#E1861B',
            black: '#000000',
            sectionHeader: '#E1861B',
            starInactive: '#403E3E',
        }
    }
}

class LightTheme implements Theme {
    readonly colorScheme: ColorScheme
    readonly typography: TypographySettings
    readonly roundness: number

    constructor(options: { accentColor: ColorValue }) {
        this.roundness = 8
        this.typography = new DefaultTypographySettings()
        this.colorScheme = {
            accentColor: options.accentColor,
            backgroundL1: '#FFFFFF',
            backgroundL2: '#F6F6F6',
            backgroundSidebar: '#F1F1F1',
            groupedBackground: '#F2F2F2',
            labelPrimary: '#0B0B0B',
            labelSecondary: '#808080',
            separator: '#EBEBEB',
            error: '#D92323',
            // Added by thaitd96 from figma
            starRating: '#E1861B',
            black: '#000000',
            sectionHeader: '#E1861B',
            starInactive: '#EBEBEB',
        }
    }
}
