//
//  PeopleItemFormInput.tsx
//
//  Created by thaitd96 on 2022-07-06 11:30.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { observer } from 'mobx-react-lite'
import React, { useEffect, useMemo } from 'react'
import { StyleSheet, Text, TouchableOpacity, View, Image } from 'react-native'
import appConstants from '../../../../constants/const'
import { Theme, useTheme } from '../../../../styles/theme'
import { People } from './PeopleLookupFormInput'
import image from '../../../../assets/icons'
import AvatarByName from '../../../../components/avatar-by-name/AvatarByName'
import { ARNPeopleLookupFormStore } from './ARNPeopleLookupFormStore'
import FastImage from '../../../../components/fast-image/FastImage'

type PeopleItemFormInputProps = {
    people: People
    index: number
    arnPeopleLookupFormStore: ARNPeopleLookupFormStore
}

const PeopleItemFormInput = observer((props: PeopleItemFormInputProps) => {
    const { people, index, arnPeopleLookupFormStore } = props
    const theme = useTheme()

    console.info('people====', people)

    const styles = useMemo(
        () => createStyles(theme, people?.name || ''),
        [theme, people.name]
    )
    const textForAvatar = people.name || people.email || ''

    useEffect(() => {
        const subscriptions = arnPeopleLookupFormStore.startListeningEvents()

        return () => {
            subscriptions.forEach((s) => s.unsubscribe())
        }
    }, [arnPeopleLookupFormStore])

    const onPressItem = () => {
        arnPeopleLookupFormStore.setChangeSelectedPeopleLookup(index)
    }

    return (
        <TouchableOpacity style={styles.item} onPress={onPressItem}>
            <View style={styles.avatarAndImage}>
                {people.avatarImage ? (
                    <FastImage
                        source={{ uri: people.avatarImage }}
                        style={styles.avatar}
                    />
                ) : (
                    <AvatarByName name={textForAvatar} size={40} />
                )}
            </View>

            <View style={styles.right}>
                <View style={styles.content}>
                    {!!people.name && (
                        <Text
                            style={styles.mainText}
                            numberOfLines={1}
                            ellipsizeMode={'tail'}
                        >
                            {people.name}
                        </Text>
                    )}
                    <Text
                        style={styles.mail}
                        numberOfLines={1}
                        ellipsizeMode={'tail'}
                    >
                        {people.email}
                    </Text>
                </View>
                {!!people.selected && (
                    <Image
                        source={image.check}
                        style={styles.iconCheck}
                    ></Image>
                )}
            </View>
        </TouchableOpacity>
    )
})

export default PeopleItemFormInput

const createStyles = ({ colorScheme }: Theme, name: string) =>
    StyleSheet.create({
        avatarAndImage: { marginHorizontal: appConstants.PADDING_COMMON },
        content: { flex: 1 },
        mail: name
            ? {
                  color: colorScheme.labelSecondary,
                  fontSize: 13,
                  fontWeight: '400',
                  lineHeight: 21.45,
              }
            : {
                  color: colorScheme.labelPrimary,
                  fontSize: 15,
                  fontWeight: '500',
                  lineHeight: 24.75,
              },
        iconCheck: {
            height: 24,
            width: 24,
            resizeMode: 'contain',
            tintColor: colorScheme.accentColor,
            marginHorizontal: 9,
        },
        mainText: {
            color: colorScheme.labelPrimary,
            fontSize: 15,
            fontWeight: '500',
            lineHeight: 24.75,
        },
        right: {
            flex: 1,
            borderBottomColor: colorScheme.separator,
            borderBottomWidth: 1,
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '100%',
            flexDirection: 'row',
        },
        avatar: {
            height: 40,
            width: 40,
            resizeMode: 'contain',
            borderRadius: 20,
        },
        item: {
            height: name ? 67 : 61,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
        },
    })
