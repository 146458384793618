import { View, StyleSheet, Image, Platform, Text } from 'react-native'
import React, { useEffect, useMemo, useState } from 'react'
import { Video as VideoExpo, Audio as AudioExpo, ResizeMode } from 'expo-av'
import { VideoStore } from '@appformula/app-studio-core/src'
import { observer } from 'mobx-react-lite'
import appConstants from '../../../../constants/const'
import { Theme, useTheme } from '../../../../styles/theme'
import image from '../../../../assets/icons'
import { COLORS } from '../../../../assets/colors'
import { useWidthHeightMainLayout } from '../../../collection/components/hooks/useSpecifyPlatformWindowDimensions'
import cache from '../../../../utils/cache'

const HotLink = observer(
    (props: {
        video: VideoStore
        item: Record<string, unknown>
        videoURL: string
        title: string
        thumbnailURL: string
    }) => {
        const { thumbnailURL, videoURL, title } = props
        const videoRef = React.useRef<VideoExpo>()
        const { width } = useWidthHeightMainLayout()

        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme, width), [theme, width])

        const [hasError, setHasError] = useState<boolean>(false)
        const [keyReset, setKeyReset] = useState<number>(0)

        useEffect(() => {
            const enableAudio = async () => {
                await AudioExpo.setAudioModeAsync({
                    allowsRecordingIOS: false,
                    // playsInSilentModeIOS: false,
                    staysActiveInBackground: false,
                    shouldDuckAndroid: false,
                })
            }
            if (videoURL) {
                enableAudio()
                    .then(() => {
                        //
                    })
                    .catch((error) => {
                        console.log('enableAudio', error)
                    })
            }
        }, [videoURL])

        const onError = (error: string) => {
            setHasError(true)
        }

        // Reset error when change data firestore
        useEffect(() => {
            setKeyReset((prevKey) => prevKey + 1)
        }, [videoURL])

        const onReadyForDisplay = () => {
            setHasError(false)
        }


        return (
            <View
                key={keyReset}
                style={[styles.container, hasError && styles.containerWebErr]}
                removeClippedSubviews
            >
                {videoURL && (
                    <VideoExpo
                        ref={videoRef}
                        style={styles.video}
                        source={{
                            uri: videoURL as string,
                        }}
                        useNativeControls
                        isLooping
                        shouldPlay={true}
                        posterSource={
                            thumbnailURL
                                ? {
                                      uri: thumbnailURL as string,
                                  }
                                : null
                        }
                        posterStyle={styles.video}
                        usePoster
                        onError={onError}
                        resizeMode={ResizeMode.CONTAIN}
                        onReadyForDisplay={onReadyForDisplay}
                    />
                )}

                {hasError && (
                    <View style={styles.wrapPlaybackIC}>
                        <Image
                            source={image.playback}
                            style={styles.imgPlayback}
                        />
                    </View>
                )}
                {hasError && <Text style={styles.titleVideo}>{title}</Text>}
            </View>
        )
    }
)

export default HotLink

const createStyles = (
    { colorScheme, roundness, typography }: Theme,
    width: number
) =>
    StyleSheet.create({
        titleVideo: {
            fontWeight: '400',
            fontSize: 18,
            lineHeight: 21.78,
            position: 'absolute',
            color: COLORS.WHITE,
            top: 16,
            left: 16,
        },
        container: {
            marginVertical: appConstants.PADDING_COMMON / 2,
            width: width - 32,
            height: 211,
            borderRadius: roundness,
            backgroundColor: COLORS.BLACK,
            ...Platform.select({
                web: {
                    width:
                        cache.sourceStartUp === 'APP_EDITOR'
                            ? width - 40
                            : width - 48,
                    height: null,
                },
            }),
            overflow: 'hidden',
            justifyContent: 'center',
            alignItems: 'center',
        },

        containerWebErr: {
            ...Platform.select({
                web: {
                    width:
                        cache.sourceStartUp === 'APP_EDITOR'
                            ? width - 40
                            : width - 48,
                    height: 211,
                },
            }),
        },

        video: {
            width: width - 32,
            height: 211,
            ...Platform.select({
                web: {
                    width: '100%',
                    height: '100%',
                },
            }),
        },
        buttons: {
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
        },
        imgPlayback: {
            width: 34,
            height: 34,
            marginHorizontal: 17,
            marginVertical: 4,
        },
        wrapPlaybackIC: {
            backgroundColor: colorScheme.accentColor,
            borderRadius: roundness,
        },
        containerErr: {
            width: width - 32,
            height: 211,
            backgroundColor: COLORS.GRAY,
            position: 'absolute',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: roundness,
            ...Platform.select({
                web: {
                    width: width - 32,
                },
            }),
        },
    })
