//
//  ARNBooleanFormStore.ts
//
//  Created by thaitd on 2022-08-12 14:08.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { action, makeObservable, observable } from 'mobx'
import { FormulaManager } from '../../../../utils/integrate-formula/FormulaManager'
import { ARNFormPageStore, BooleanFormData } from '../../ARNFormPageStore'
import { BaseARNFormStore } from '../BaseARNFormStore'

export class ARNBooleanFormStore implements BaseARNFormStore {
    readonly formPageId: string
    readonly componentId: string
    value: boolean
    arnFormPageStore: ARNFormPageStore
    formulaManager?: FormulaManager

    constructor(
        arnFormPageStore: ARNFormPageStore,
        formPageId: string,
        componentId: string,
        initData?: BooleanFormData,
        formulaManager?: FormulaManager
    ) {
        this.arnFormPageStore = arnFormPageStore
        this.formPageId = formPageId
        this.componentId = componentId
        this.value = initData?.value ?? false
        this.formulaManager = formulaManager

        makeObservable(this, {
            value: observable,
            setValue: action,
        })
    }

    setValue(value: boolean) {
        this.value = value
    }

    async validate(): Promise<void> {
        //
    }
}
