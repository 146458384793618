//
//  CardLayoutSection.tsx
//
//  Created by thaitd96 on 2022-27-04 17:20.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React, { useMemo } from 'react'
import {
    FlatList,
    Keyboard,
    ScrollView,
    StyleSheet,
    Text,
    View,
} from 'react-native'
import { Search } from '../Search'
import { Theme, useTheme } from '../../../../styles/theme'
import { Loading } from '../../../../components'
import { CardItemLargeOverlay } from '../card-item/CardItemLargeOverlay'
import appConstants from '../../../../constants/const'
import { CardItemBlock } from '../card-item/CardItemBlock'
import { getCardItemHeight } from '../../../../utils'
import { useNavigation } from '@react-navigation/native'
import screenNames from '../../../../navigations/screenNames'
import { observer } from 'mobx-react-lite'
import { CollectionPageStore } from '@appformula/app-studio-core'
import { CardLayout } from '@appformula/app-descriptor'
import { ARNCollectionPageStore } from '../../ARNCollectionPageStore'
import { useWidthHeightMainLayout } from '../hooks/useSpecifyPlatformWindowDimensions'

export const CardLayoutSection = observer(
    (props: {
        pageStore: CollectionPageStore
        arnCollectionPageStore: ARNCollectionPageStore
    }) => {
        const { pageStore, arnCollectionPageStore } = props
        const navigation = useNavigation()
        const theme = useTheme()
        const { width } = useWidthHeightMainLayout()

        const { accessory, itemSubtitle, imageSize, image, contentPosition } =
            pageStore.activeLayout as CardLayout

        const height = getCardItemHeight(
            !!accessory,
            !!itemSubtitle,
            imageSize,
            width,
            contentPosition,
            !!image
        )

        const styles = useMemo(
            () => createStyles(theme, height),
            [theme, height]
        )

        const renderItem =
            (title: string) =>
            // eslint-disable-next-line react/display-name
            ({ item }: { item: number }) => {
                // Switch case to detect item type in here
                if (image && contentPosition === 'overlay') {
                    return (
                        <CardItemLargeOverlay
                            item={item}
                            pageStore={pageStore}
                            arnCollectionPageStore={arnCollectionPageStore}
                            valueGrouping={title}
                        />
                    )
                } else {
                    return (
                        <CardItemBlock
                            item={item}
                            imageSize={imageSize}
                            itemHeight={height}
                            image={image}
                            pageStore={pageStore}
                            arnCollectionPageStore={arnCollectionPageStore}
                            valueGrouping={title}
                        />
                    )
                }
            }

        const goToSectionDetails = React.useCallback(
            (section) => () => {
                navigation.navigate(screenNames.SECTION_DETAIL as never)
                props.arnCollectionPageStore.setCurrentCardSection(section)
            },
            [navigation, props.arnCollectionPageStore]
        )

        const keyExtractor = (item: number) =>
            `${item}_${props.arnCollectionPageStore.queryString}`

        return (
            <View style={styles.viewCardLayoutSection}>
                <Search
                    searchCollectionStore={props.arnCollectionPageStore.getSearchStore()}
                    searchBarStore={pageStore.searchBarStore()}
                />
                {props.arnCollectionPageStore.isLoading ? (
                    <Loading />
                ) : (
                    <ScrollView
                        contentContainerStyle={styles.contentContainer}
                        style={styles.listCard}
                        nestedScrollEnabled={true}
                        showsVerticalScrollIndicator={false}
                    >
                        {props.arnCollectionPageStore.listVirtualDataSection.map(
                            (virtualDataSection) => {
                                return (
                                    <View
                                        key={virtualDataSection.title as string}
                                    >
                                        <View style={styles.sectionHeader}>
                                            <Text style={styles.sectionTitle}>
                                                {
                                                    virtualDataSection.title as string
                                                }
                                            </Text>
                                            <Text
                                                style={styles.textViewAll}
                                                onPress={goToSectionDetails(
                                                    virtualDataSection.title as string
                                                )}
                                            >
                                                {'View All'}
                                            </Text>
                                        </View>
                                        <View style={styles.viewWrapList}>
                                            <FlatList
                                                renderItem={renderItem(
                                                    virtualDataSection.title as string
                                                )}
                                                data={virtualDataSection.data}
                                                keyExtractor={keyExtractor}
                                                horizontal={true}
                                                showsHorizontalScrollIndicator={
                                                    false
                                                }
                                                onScroll={() => {
                                                    Keyboard.dismiss()
                                                }}
                                            />
                                        </View>
                                    </View>
                                )
                            }
                        )}
                    </ScrollView>
                )}
            </View>
        )
    }
)

const createStyles = ({ colorScheme }: Theme, height: number) =>
    StyleSheet.create({
        contentContainer: { paddingBottom: 10 },
        textViewAll: {
            fontSize: 16,
            fontWeight: '500',
            color: colorScheme.accentColor,
        },
        sectionTitle: {
            fontSize: 18,
            fontWeight: '600',
            color: colorScheme.labelPrimary,
        },
        sectionHeader: {
            flexDirection: 'row',
            paddingTop: 24,
            paddingHorizontal: appConstants.PADDING_COMMON,
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        viewWrapList: {
            height: height + appConstants.PADDING_COMMON + 1, // Item margin top PADDING_COMMON & borderTopWidth of list card
            paddingRight: appConstants.PADDING_COMMON,
        },
        listCard: {
            width: '100%',
            borderColor: colorScheme.separator,
            borderTopWidth: 1,
            backgroundColor: colorScheme.groupedBackground,
        },
        viewCardLayoutSection: {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
        },
    })
