import Model from '@nozbe/watermelondb/Model'
import { ReactNativeTableSchema } from '../type'

const getModelClasses = (tables: ReactNativeTableSchema[]) => {
    const dynamicModelClasses: Model[] = tables.map((table) => {
        class ModelClass extends Model {
            static override table = table.name
        }
        Object.defineProperty(ModelClass, 'name', { value: table.name })
        return ModelClass as unknown as Model
    })

    return dynamicModelClasses
}

export default getModelClasses
