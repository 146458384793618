export const pagesIcon: {
    [key in string]: unknown
} = {
    bicycle: require('./bicycle.png'),
    pound: require('./pound.png'),
    rocket: require('./rocket.png'),
    euro: require('./euro.png'),
    'take-off': require('./take-off.png'),
    dollar: require('./dollar.png'),
    car: require('./car.png'),
    'cash-and-coins': require('./cash-and-coins.png'),
    truck: require('./truck.png'),
    wallet: require('./wallet.png'),
    cloud: require('./cloud.png'),
    piggy: require('./piggy.png'),
    database: require('./database.png'),
    stack: require('./stack.png'),
    verified: require('./verified.png'),
    documents: require('./documents.png'),
    unlocked: require('./unlocked.png'),
    folders: require('./folders.png'),
    shield: require('./shield.png'),
    tags: require('./tags.png'),
    locked: require('./locked.png'),
    folder: require('./folder.png'),
    key: require('./key.png'),
    document: require('./document.png'),
    'heart-rate': require('./heart-rate.png'),
    'app-stack': require('./app-stack.png'),
    'pill-bottle': require('./pill-bottle.png'),
    'shopping-cart': require('./shopping-cart.png'),
    pill: require('./pill.png'),
    'shopping-bag': require('./shopping-bag.png'),
    scale: require('./scale.png'),
    calendar: require('./calendar.png'),
    'health-data': require('./health-data.png'),
    alarm: require('./alarm.png'),
    photos: require('./photos.png'),
    phone: require('./phone.png'),
    photo: require('./photo.png'),
    message: require('./message.png'),
    reel: require('./reel.png'),
    mail: require('./mail.png'),
    camera: require('./camera.png'),
    inbox: require('./inbox.png'),
    wheelchair: require('./wheelchair.png'),
    flag: require('./flag.png'),
    footstep: require('./footstep.png'),
    wishlist: require('./wishlist.png'),
    archive: require('./archive.png'),
    'contact-card': require('./contact-card.png'),
    news: require('./news.png'),
    target: require('./target.png'),
    'people-circle': require('./people-circle.png'),
    megaphone: require('./megaphone.png'),
    'presentation-image': require('./presentation-image.png'),
    people: require('./people.png'),
    bed: require('./bed.png'),
    'bar-chart': require('./bar-chart.png'),
    planet: require('./planet.png'),
    package: require('./package.png'),
    briefcase: require('./briefcase.png'),
    gift: require('./gift.png'),
    badge: require('./badge.png'),
    storefront: require('./storefront.png'),
    flower: require('./flower.png'),
    tropy: require('./tropy.png'),
    'office-building': require('./office-building.png'),
    flame: require('./flame.png'),
    library: require('./library.png'),
    more: require('./more.png'),
    'addmission-ticket': require('./addmission-ticket.png'),
    yen: require('./yen.png'),
    star: require('./star.png'),
    book: require('./book.png'),
    'dollar-stack': require('./dollar-stack.png'),
    home: require('./home.png'),
}
