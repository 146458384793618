//
//  CardItemBlock.tsx
//
//  Created by thaitd96 on 2022-26-04 18:00.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { Binding, CardLayout, ItemAction } from '@appformula/app-descriptor/src'
import {
    CollectionPageStore,
    DefaultPageSelection,
} from '@appformula/app-studio-core/src'
import appConstants from '../../../../constants/const'
import React, { useCallback, useEffect, useState } from 'react'
import { StyleSheet, ImageBackground, Pressable } from 'react-native'
import { Theme, useTheme } from '../../../../styles/theme'
import { CardItemContent } from './CardItemContent'
import { observer } from 'mobx-react-lite'
import { useGetItemFromIndex } from '../hooks/useGetItemFromIndex'
import { ARNCollectionPageStore } from '../../ARNCollectionPageStore'
import { ButtonMore } from './ButtonMore'
import { useNavigation } from '@react-navigation/native'
import screenNames from '../../../../navigations/screenNames'
import { useBindingHaveItemData } from '../hooks/useBindingHaveItemData'
import { useWidthHeightMainLayout } from '../hooks/useSpecifyPlatformWindowDimensions'
import { DesignPageContext } from '../../../../context/DesignPageContext'

type Props = {
    item: number
    imageSize: string
    itemHeight?: number
    image?: Binding<string>
    pageStore: CollectionPageStore
    arnCollectionPageStore: ARNCollectionPageStore
    valueGrouping?: unknown
}

export const CardItemBlock = observer((props: Props) => {
    const { item, imageSize, itemHeight, pageStore } = props
    const { width } = useWidthHeightMainLayout()
    const theme = useTheme()
    const styles = React.useMemo(
        () => createStyles(theme, imageSize, width, itemHeight),
        [theme, imageSize, width, itemHeight]
    )
    const designPageStore = React.useContext(DesignPageContext)
    const [itemActions, setItemActions] = useState<ItemAction[]>([])

    const filterShownActions = useCallback(async () => {
        const res = await props.arnCollectionPageStore.filterShownActions(
            pageStore.itemActions
        )
        setItemActions(res as unknown as ItemAction[])
    }, [pageStore.itemActions, props.arnCollectionPageStore])

    useEffect(() => {
        filterShownActions()
    }, [filterShownActions])

    const navigation = useNavigation()

    const itemData = useGetItemFromIndex(
        props.pageStore,
        item,
        props.arnCollectionPageStore.queryString,
        props.valueGrouping
    )

    const image = useBindingHaveItemData(
        (props.pageStore.activeLayout as CardLayout).image,
        itemData
    )

    const onPressItem = useCallback(() => {
        try {
            const tableId = props.pageStore.dataSource?.id
            if (tableId) {
                if (designPageStore) {
                    designPageStore.pageSelection = new DefaultPageSelection(
                        tableId,
                        'details'
                    )
                }
                navigation.navigate(
                    screenNames.DETAIL_SCREEN as never,
                    {
                        itemKey: itemData?.['key'] ?? '',
                        pageId: '',
                        tableId,
                    } as never
                )
            }
        } catch (error) {
            console.info('Error press card item: ', error)
        }
    }, [designPageStore, itemData, navigation, props.pageStore.dataSource?.id])

    const MainImage = useCallback(() => {
        return (
            <ImageBackground
                style={styles.image}
                source={{
                    uri: (image as string) ?? '',
                }}
                resizeMode={'stretch'}
            >
                {itemActions?.length > 0 && (
                    <ButtonMore itemActions={itemActions} itemData={itemData} />
                )}
            </ImageBackground>
        )
    }, [image, itemData, itemActions, styles.image])

    return (
        <Pressable style={styles.item} onPress={onPressItem}>
            {!!image && <MainImage />}
            <CardItemContent item={itemData} pageStore={pageStore} />
        </Pressable>
    )
})

const createStyles = (
    { colorScheme, roundness }: Theme,
    imageSize: string,
    width: number,
    itemHeight?: number
) =>
    StyleSheet.create({
        item: {
            borderColor: colorScheme.separator,
            borderWidth: 1,
            borderRadius: roundness,
            overflow: 'hidden',
            marginLeft: appConstants.PADDING_COMMON,
            marginTop: appConstants.PADDING_COMMON,
            backgroundColor: colorScheme.backgroundL1,
            width:
                imageSize === 'large'
                    ? width - 2 * appConstants.PADDING_COMMON
                    : (width - 3 * appConstants.PADDING_COMMON) / 2,
            ...(itemHeight && { height: itemHeight }),
        },
        image: { width: '100%', aspectRatio: 4 / 3 },
    })
