import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { observer } from 'mobx-react-lite'
import { Theme, useTheme } from '../../../../../styles/theme'
import { ARNChartStore, AxisDataTypeWeb } from '../ARNChartStore'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import appConstants from '../../../../../constants/const'

const commonOption: Highcharts.Options = {
    chart: {
        type: 'line',
        panning: {
            enabled: true,
            type: 'x',
        },
        height: 325,
        backgroundColor: 'transparent',
    },
    title: {
        text: '',
    },
    credits: {
        enabled: false,
    },

    plotOptions: {
        column: {
            pointPadding: 0,
            borderWidth: 0,
        },
        series: {
            enableMouseTracking: false,
            states: {
                hover: {
                    enabled: false,
                },
            },
        },
        line: {
            marker: {
                enabled: false,
            },
        },
    },
    tooltip: {
        enabled: false,
    },
}

const LineChart = observer((props: { arnChartStore: ARNChartStore }) => {
    const { arnChartStore } = props

    const theme = useTheme()
    const styles = React.useMemo(() => createStyles(theme), [theme])

    const renderLegends = () => {
        return (
            <View style={styles.wrapLegends}>
                {arnChartStore.getAxisChartLayoutWeb?.data?.map(
                    (item: AxisDataTypeWeb) => renderItemLegend(item)
                )}
            </View>
        )
    }

    const renderItemLegend = (item: AxisDataTypeWeb) => {
        return (
            <View style={styles.containerLegendItem} key={item.name}>
                <View
                    style={[styles.tagLegend, { backgroundColor: item.color }]}
                />
                <Text style={styles.txtLegend}>{item.name}</Text>
            </View>
        )
    }

    return (
        <View style={styles.container}>
            <HighchartsReact
                highcharts={Highcharts}
                options={{
                    ...commonOption,
                    xAxis: {
                        max:
                            arnChartStore.getAxisChartLayoutWeb.labels?.length >
                            4
                                ? 4
                                : arnChartStore.getAxisChartLayoutWeb.labels
                                      .length - 1,
                        categories: arnChartStore.getAxisChartLayoutWeb.labels,
                        crosshair: false,
                        labels: {
                            format: '{value}',
                            allowOverlap: true,
                            autoRotation: 0,
                            style: {
                                color: theme.colorScheme.labelSecondary,
                                fontSize: 10,
                            },
                        },
                        lineColor: theme.colorScheme.separator,
                        lineWidth: 1,
                    },
                    yAxis: {
                        min: 0,
                        tickAmount: 6,
                        startOnTick: true,
                        endOnTick: false,
                        maxPadding: 0,
                        title: {
                            text: '',
                        },
                        labels: {
                            // format: '{value}',
                            // formatter: function () {
                            //     // return Intl.NumberFormat('en-US', {
                            //     //     notation: 'compact',
                            //     //     maximumFractionDigits: 1,
                            //     // }).format(this.value)
                            // },
                            style: {
                                color: theme.colorScheme.labelSecondary,
                                fontSize: 10,
                            },
                        },
                        gridLineColor: theme.colorScheme.separator,
                        gridLineWidth: 1,
                    },
                    legend: {
                        enabled: false,
                        align: 'center',
                        verticalAlign: 'bottom',
                        layout: 'horizontal',
                        itemStyle: {
                            color: theme.colorScheme.labelPrimary,
                            cursor: 'auto',
                            fontSize: '12px',
                            fontWeight: '400',
                            textOverflow: 'ellipsis',
                        },
                        symbolHeight: 7,
                        symbolWidth: 7,
                        symbolRadius: 6,
                        symbolPadding: 6,
                        itemHoverStyle: {
                            color: theme.colorScheme.labelPrimary,
                        },
                    },
                    colors: arnChartStore.chartStore.colors,
                    series: arnChartStore.getAxisChartLayoutWeb.data,
                }}
            />
            {renderLegends()}
        </View>
    )
})

export default LineChart

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        container: {
            marginVertical: appConstants.PADDING_COMMON / 2,
        },
        wrapLegends: {
            flexDirection: 'row',
            alignSelf: 'center',
            // marginTop: 12,
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
        },
        containerLegendItem: {
            flexDirection: 'row',
            alignItems: 'center',
            marginHorizontal: 19 / 2,
        },
        tagLegend: { width: 7, height: 7, borderRadius: 3.5, marginRight: 6 },
        txtLegend: {
            fontWeight: '400',
            fontSize: 12,
            lineHeight: 14.52,
            color: colorScheme.labelPrimary,
        },
    })
