import { AppDescriptor } from '@appformula/app-descriptor/src'
import { AppDescriptorStore } from '@appformula/app-studio-core/src'
import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import { NativeEventEmitter, NativeModules, Platform } from 'react-native'
import { LocalAppDescriptorRepository } from '../../app-studio-core-local'
import { AppDescriptorContext } from '../../context/AppDescriptorContext'

interface IProps {
    children: ReactNode
    initDescriptor: string
}

const WrapContextAppGo = (props: IProps) => {
    const { initDescriptor } = props
    const [descriptorStore, setDescriptorStore] = useState<AppDescriptorStore>()

    const handleSetDescriptor = useCallback((data: string) => {
        const descriptorContentLocal = new LocalAppDescriptorRepository(
            JSON.parse(data) as unknown as AppDescriptor
        )

        const appDescriptorRepository = new AppDescriptorStore(
            descriptorContentLocal
        )
        setDescriptorStore(appDescriptorRepository)
    }, [])

    useEffect(() => {
        handleSetDescriptor(initDescriptor)
    }, [handleSetDescriptor, initDescriptor])

    const handleEventEmitter = React.useCallback(() => {
        try {
            if (Platform.OS !== 'web') {
                const { EventEmitter } = NativeModules
                const eventEmitterModule = new NativeEventEmitter(EventEmitter)
                eventEmitterModule.addListener('onDescriptorUpdate', (data) => {
                    handleSetDescriptor(data)
                })
            }
        } catch (error) {
            //
        }
    }, [handleSetDescriptor])

    useEffect(() => {
        handleEventEmitter()
    }, [handleEventEmitter])

    return descriptorStore ? (
        <AppDescriptorContext.Provider value={descriptorStore}>
            {props.children}
        </AppDescriptorContext.Provider>
    ) : null
}

export default WrapContextAppGo
