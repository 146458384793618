//
//  GoogleSignInButton.tsx
//
//  Created by thaitd96 on 2022-05-30 17:06.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React, { useContext } from 'react'
import {
    StyleSheet,
    Text,
    TouchableOpacity,
    TouchableOpacityProps,
} from 'react-native'
import { Theme, useTheme } from '../../../styles/theme'
import FastImage from '../../../components/fast-image/FastImage'
import image from '../../../assets/icons'
import firebase from 'firebase'
import buildConfig from '../../../../buildConfig.json'
import { AppType } from '../WelcomePageStore'
import cache from '../../../utils/cache'
import { Alert } from '../../../components/alert'
import { WelcomePageDataStoreContext } from '../context/WelcomePageDataStoreContext'

type ButtonProps = {
    loading?: boolean
    appType?: AppType
}

const GoogleSignInButton = (props: ButtonProps & TouchableOpacityProps) => {
    const theme = useTheme()
    const styles = React.useMemo(() => createStyles(theme), [theme])
    const store = useContext(WelcomePageDataStoreContext)

    const onGoogleButtonPress = React.useCallback(async () => {
        if (cache.sourceStartUp !== 'APP_EDITOR') {
            try {
                const provider = new firebase.auth.GoogleAuthProvider()

                provider.addScope('email')
                provider.addScope('profile')

                if (
                    props.appType === AppType.EXTERNAL &&
                    buildConfig.tenantId !== ''
                ) {
                    firebase.auth().tenantId = buildConfig.tenantId
                }
                await firebase
                    .auth()
                    .signInWithPopup(provider)
                    .then(async (userInfo) => {
                        if (userInfo.user) {
                            await store.createAppUser(userInfo.user.uid)
                        }
                    })
                console.info('Signed in with Google!')
            } catch (error) {
                console.info(`Signed in with Google error ${error}!`)
            }
        } else {
            Alert.alert(
                'Notification',
                `You can't use this feature in Web Editor!`
            )
        }
    }, [props.appType])

    return (
        <TouchableOpacity
            style={styles.ggButton}
            onPress={onGoogleButtonPress}
            disabled={props.loading}
        >
            <FastImage
                style={styles.ggImg}
                source={image.google}
                resizeMode={'contain'}
            />
            <Text style={styles.txtGGSignIn}>Continue with Google</Text>
        </TouchableOpacity>
    )
}

export default GoogleSignInButton

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        ggButton: {
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            borderWidth: 1,
            borderRadius: roundness,
            borderColor: colorScheme.separator,
            width: '100%',
            height: 50,
        },

        ggImg: {
            width: 18,
            height: 18,
            marginRight: 10,
        },

        txtGGSignIn: {
            fontWeight: '500',
            fontSize: 16,
            lineHeight: 19.36,
            color: colorScheme.labelPrimary,
        },
    })
