//
//  MonthCalendar.tsx
//
//  Created by thaitd96 on 2022-06-17 09:40.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { Theme, useTheme } from '../../../../../styles/theme'
import MonthViewCalendar from 'react-native-month-view-calendar'
import { observer } from 'mobx-react-lite'
import { CalendarLayoutStore } from '../CalendarLayoutStore'
import { CollectionPageStore } from '@appformula/app-studio-core/src'
import { Event } from 'react-native-month-view-calendar/lib/contracts/event'
import dayjs from 'dayjs'
import ItemMonthCalendar from './ItemMonthCalendar'
import { MonthCalendarStore } from './MonthCalendarStore'
import { appFormulaId } from '../../../../../database/type'

export const MonthCalendar = observer(
    (props: {
        calendarLayoutStore: CalendarLayoutStore
        pageStore: CollectionPageStore
        monthCalendarStore: MonthCalendarStore
    }) => {
        console.info('re-render MonthCalendar')
        const { pageStore, monthCalendarStore, calendarLayoutStore } = props

        const dataCalendar = monthCalendarStore.listCalenderView
        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])
        const [events, setEvents] = useState([])
        const monthCalendarRef = useRef<MonthViewCalendar>()
        const [keyReset, setKeyReset] = useState(0)
        const [isLoading, setLoading] = useState(true)
        const ref = useRef({
            isSwipe: false,
        })

        const onScrollToIndexFailed = () => {
            setKeyReset((prevKey) => prevKey + 1)
        }

        useEffect(() => {
            setTimeout(() => {
                setLoading(false)
            }, 0)
        }, [])

        useEffect(() => {
            const timeout = setTimeout(() => {
                const newData = dataCalendar
                    .map((item) => item.data)
                    .flat()
                    .map((item) => ({
                        ...item,
                        id: item?.[appFormulaId],
                        date: dayjs(item?.['startDate'] as string).toDate(),
                    }))
                setEvents(newData)
            }, 0)
            return () => {
                clearTimeout(timeout)
            }
        }, [dataCalendar])

        const renderEvent = (event: Event, i: number) => {
            return (
                <ItemMonthCalendar
                    key={event?.[appFormulaId]}
                    item={event}
                    pageStore={pageStore}
                />
            )
        }

        useEffect(() => {
            if (!ref.current.isSwipe) {
                setKeyReset((prevKey) => prevKey + 1)
            }
            ref.current.isSwipe = false
        }, [calendarLayoutStore.monthYear])

        const onSwipe = useCallback(
            (date: Date) => {
                ref.current.isSwipe = true
                calendarLayoutStore.setMonthYear(date)
            },
            [calendarLayoutStore]
        )

        return (
            <View style={styles.wrap}>
                {!isLoading && (
                    <MonthViewCalendar
                        key={keyReset}
                        ref={monthCalendarRef}
                        date={calendarLayoutStore.monthYear}
                        events={events}
                        renderEvent={renderEvent}
                        headerTextStyles={styles.headerTextStyles}
                        dayTextStyles={styles.dayTextStyles}
                        onScrollToIndexFailed={onScrollToIndexFailed}
                        backgroundColorContent={theme.colorScheme.backgroundL1}
                        backgroundColorHeader={theme.colorScheme.backgroundL2}
                        borderCellColor={theme.colorScheme.separator}
                        onSwipe={onSwipe}
                    />
                )}
            </View>
        )
    }
)

const createStyles = ({ colorScheme }: Theme) =>
    StyleSheet.create({
        dayTextStyles: {
            fontSize: 13,
            color: colorScheme.labelPrimary,
            marginTop: 8,
        },
        headerTextStyles: {
            fontSize: 11,
            color: colorScheme.labelSecondary,
            fontWeight: '500',
        },
        wrap: { flex: 1 },
        monthView: {
            backgroundColor: colorScheme.backgroundL1,
        },
    })
