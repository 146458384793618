//
//  CollectionFilterStore.ts
//
//  Created by thaitd96 on 2022-07-27 15:24.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { Nullable } from '@appformula/shared-foundation-x/src'
import { action, makeObservable, observable } from 'mobx'
import { FilterBooleanStore } from './filter-boolean/FilterBooleanStore'
import { FilterDateStore } from './filter-date/FilteDateStore'
import { FilterOptionStore } from './filter-option/FilterOptionStore'
import { FilterSliderStore } from './filter-slider/FilterSliderStore'
import { FilterStringStore } from './filter-string/FilterStringStore'

export interface BaseFilterData {
    columnFilter: ColumnFilter
}

export interface FilterStringData extends BaseFilterData {
    type?: number
    value?: string
}

export interface FilterSliderData extends BaseFilterData {
    type?: number
    low?: number
    high?: number
    value?: string
}

export interface FilterBooleanData extends BaseFilterData {
    value: Nullable<boolean>
}
export interface FilterDateData extends BaseFilterData {
    startDate: Nullable<Date>
    endDate: Nullable<Date>
    type: number
}
export interface FilterOptionData extends BaseFilterData {
    availableOptions?: (string | number)[]
    selectedOptions?: string[]
    type: number
}

export type FilterData =
    | FilterStringData
    | FilterSliderData
    | FilterBooleanData
    | FilterDateData
    | FilterOptionData

export type ChildComponentStore =
    | FilterStringStore
    | FilterSliderStore
    | FilterBooleanStore
    | FilterDateStore
    | FilterOptionStore

export type DataType = 'Number' | 'String' | 'Boolean' | 'DateTime' | 'List'

export type ColumnName = string

export type Columns = Record<ColumnName, DataType>

export type ColumnFilter = {
    key: string
    columnName: ColumnName
    dataType: DataType
    columnNameRaw: string
}

export interface TableSchemaType {
    type: DataType
    rawType: string
    enum: (string | number)[]
}

export class CollectionFilterStore {
    collectionPageId: string
    dataSourceId: string
    private cachedChildComponentStores: Map<string, ChildComponentStore> =
        new Map()

    filterData: FilterData[] = []

    constructor(
        collectionPageId: string,
        dataSourceId: string,
        filterData?: FilterData[]
    ) {
        this.collectionPageId = collectionPageId
        this.filterData = filterData ?? []
        this.dataSourceId = dataSourceId

        makeObservable(this, {
            addFilteredColumn: action,
            removeFilteredColumn: action,
            clearChildComponentStores: action,

            filterData: observable,
        })
    }

    addFilteredColumn(column: ColumnFilter) {
        this.filterData.push({ columnFilter: column })
    }

    removeFilteredColumn(key: string) {
        const index = this.filterData.findIndex(
            (item) => item.columnFilter.key === key
        )
        index >= 0 && this.filterData.splice(index, 1)
        this.removeChildComponentStores(key)
    }

    allChildComponentStores() {
        return this.cachedChildComponentStores
    }

    private removeChildComponentStores(key: string) {
        this.cachedChildComponentStores.delete(key)
    }

    clearChildComponentStores() {
        this.filterData = []
        this.cachedChildComponentStores.clear()
    }

    childComponentStore(filterData: FilterData): Nullable<ChildComponentStore> {
        const cachedChildComponentStores = this.cachedChildComponentStores.get(
            filterData.columnFilter.key
        )
        if (cachedChildComponentStores) {
            return cachedChildComponentStores
        } else {
            const newChildComponentStore =
                this.makeChildComponentStore(filterData)
            this.cachedChildComponentStores.set(
                filterData.columnFilter.key,
                newChildComponentStore
            )
            return newChildComponentStore
        }
    }

    private makeChildComponentStore(
        filterData: FilterData
    ): ChildComponentStore {
        const collectionPageId = this.collectionPageId
        if ('availableOptions' in filterData && filterData.availableOptions) {
            return new FilterOptionStore(
                collectionPageId,
                filterData as FilterOptionData
            )
        }
        switch (filterData.columnFilter.dataType) {
            case 'String':
                return new FilterStringStore(
                    collectionPageId,
                    filterData as FilterStringData
                )

            case 'Number':
                return new FilterSliderStore(
                    collectionPageId,
                    filterData as FilterSliderData
                )
            case 'Boolean':
                return new FilterBooleanStore(
                    collectionPageId,
                    filterData as FilterBooleanData
                )
            case 'DateTime':
                return new FilterDateStore(
                    collectionPageId,
                    filterData as FilterDateData
                )
            case 'List':
                return new FilterOptionStore(
                    collectionPageId,
                    filterData as FilterOptionData
                )
        }
    }
}
