//
//  CollectionPage.tsx
//
//  Created by thaitd96 on 2022-25-07 14:11.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import {
    CollectionPageStore,
    DesignPageStore,
} from '@appformula/app-studio-core/src'
import { BottomTabScreenProps } from '@react-navigation/bottom-tabs'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useMemo } from 'react'
import { AppDescriptorContext } from '../../context/AppDescriptorContext'
import { MainPageDataStoreContext } from '../../context/MainPageDataStoreContext'
import { CollectionScreen } from './components/collection-screen/CollectionScreen'
import { SectionDetails } from './components/card-layout/SectionDetails'
import { Filter } from './components/filter/Filter'
import SortCollectionScreen from './components/SortScreen/SortCollectionScreen'
import { Theme, useTheme } from '../../styles/theme'
import screenNames from '../../navigations/screenNames'
import { ARNCollectionPageStore } from './ARNCollectionPageStore'
import BarcodeScannerScreen from './components/BarcodeScanner/BarcodeScannerScreen'
import { DesignPageContext } from '../../context/DesignPageContext'
import { Image, Pressable, StyleSheet, Text, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import image from '../../assets/icons'
import { useNavigation } from '@react-navigation/native'
import { TabParamList } from '../main/tab-view/TabView'
import { useDatabaseAction } from '../../context/AppDatabaseContext'
import { AppAuthenticationContext } from '../../context/AppAuthenticationContext'

interface IParams {
    pageId: string
    fromMorePage?: boolean
    showBackButton?: boolean
}

type Props = BottomTabScreenProps<TabParamList>

const Stack = createNativeStackNavigator()

export const CollectionPage = ({ route }: Props) => {
    const store = React.useContext(AppDescriptorContext)
    const authStore = React.useContext(AppAuthenticationContext)
    const mainPageDataStore = React.useContext(MainPageDataStoreContext)
    const { pageId, fromMorePage, showBackButton } = route.params as IParams
    const pageStore = store.pageStore(pageId)
    const designPageStore = React.useContext(DesignPageContext)
    const theme = useTheme()
    const styles = useMemo(() => createStyles(theme), [theme])
    const navigation = useNavigation()
    const databaseAction = useDatabaseAction()

    const onPress = () => {
        navigation.goBack()
    }

    return showBackButton ? (
        <SafeAreaView style={styles.container}>
            <View>
                <Pressable onPress={onPress}>
                    <View style={styles.wrapContentHeader}>
                        <Image source={image.back} style={styles.icBack} />
                        {fromMorePage && (
                            <Text style={styles.txtBack}>More</Text>
                        )}
                    </View>
                </Pressable>
            </View>
            <CollectionPageContent
                pageStore={pageStore as CollectionPageStore}
                arnCollectionPageStore={mainPageDataStore.arnCollectionPageStore(
                    pageId,
                    databaseAction,
                    authStore?.authenticatedUser
                )}
                designPageStore={designPageStore}
            />
        </SafeAreaView>
    ) : (
        <CollectionPageContent
            pageStore={pageStore as CollectionPageStore}
            arnCollectionPageStore={mainPageDataStore.arnCollectionPageStore(
                pageId,
                databaseAction,
                authStore?.authenticatedUser
            )}
            designPageStore={designPageStore}
        />
    )
}

const CollectionPageContent = observer(
    (props: {
        pageStore: CollectionPageStore
        arnCollectionPageStore: ARNCollectionPageStore
        designPageStore: DesignPageStore
    }) => {
        const theme = useTheme()

        React.useEffect(() => {
            props.arnCollectionPageStore.getPageDataStored()
        }, [props.arnCollectionPageStore])

        React.useEffect(() => {
            let unsubscribes: (() => void)[] = []

            const handleGetData = async () => {
                unsubscribes =
                    await props.arnCollectionPageStore.getTableDataType()
            }

            handleGetData()

            return () => {
                unsubscribes.forEach((unsubscribe) => {
                    unsubscribe()
                })
            }
        }, [props.arnCollectionPageStore])

        const CollectionScreenCallback = useCallback(() => {
            return (
                <CollectionScreen
                    pageStore={props.pageStore}
                    arnCollectionPageStore={props.arnCollectionPageStore}
                    designPageStore={props.designPageStore}
                />
            )
        }, [
            props.arnCollectionPageStore,
            props.designPageStore,
            props.pageStore,
        ])

        const SectionDetailsScreen = useCallback(
            () => (
                <SectionDetails
                    pageStore={props.pageStore}
                    arnCollectionPageStore={props.arnCollectionPageStore}
                />
            ),
            [props.arnCollectionPageStore, props.pageStore]
        )

        const FilterScreen = useCallback(() => {
            return (
                <Filter
                    arnCollectionPageStore={props.arnCollectionPageStore}
                    collectionFilterStore={props.arnCollectionPageStore.collectionFilterStore()}
                />
            )
        }, [props.arnCollectionPageStore])

        const SortScreen = useCallback(() => {
            return (
                <SortCollectionScreen
                    arnCollectionPageStore={props.arnCollectionPageStore}
                    collectionSortStore={props.arnCollectionPageStore.collectionSortStore()}
                />
            )
        }, [props.arnCollectionPageStore])

        return (
            <Stack.Navigator
                screenOptions={{
                    headerTintColor: theme.colorScheme.labelPrimary as string,
                }}
            >
                <Stack.Screen
                    name={screenNames.COLLECTION_SCREEN}
                    component={CollectionScreenCallback}
                    options={{
                        headerShown: false,
                    }}
                />
                <Stack.Screen
                    name={screenNames.SECTION_DETAIL}
                    component={SectionDetailsScreen}
                    options={{
                        headerShadowVisible: false,
                        headerStyle: {
                            backgroundColor: theme.colorScheme
                                .backgroundL1 as string,
                        },
                        title: '',
                    }}
                />
                <Stack.Screen
                    name={screenNames.COLLECTION_FILTER_SCREEN}
                    component={FilterScreen}
                    options={{
                        presentation: 'modal',
                        headerShown: false,
                    }}
                />
                <Stack.Screen
                    name={screenNames.SORT_COLLECTION}
                    component={SortScreen}
                    options={{
                        presentation: 'modal',
                        headerShown: false,
                    }}
                />
                <Stack.Screen
                    name={screenNames.BARCODE_SCANNER_SCREEN}
                    component={BarcodeScannerScreen}
                    options={{
                        presentation: 'fullScreenModal',
                        headerStyle: {
                            backgroundColor: 'rgba(0, 0, 0, 0.2)' as string,
                        },
                        headerTransparent: true,
                    }}
                />
            </Stack.Navigator>
        )
    }
)
const createStyles = ({ colorScheme }: Theme) =>
    StyleSheet.create({
        txtBack: {
            color: colorScheme.accentColor,
            fontSize: 17,
            lineHeight: 20.57,
            fontWeight: '500',
        },
        icBack: { width: 28, height: 28, tintColor: colorScheme.accentColor },
        wrapContentHeader: {
            flexDirection: 'row',
            alignItems: 'center',
            marginLeft: 8,
            height: 49,
        },
        container: { flex: 1, backgroundColor: colorScheme.backgroundL1 },
    })
