import { StyleSheet, View } from 'react-native'
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { InlineCollectionStore } from '@appformula/app-studio-core'
import Layout from './Layout'
import appConstants from '../../../../constants/const'
import { useBindingHaveItemData } from '../../../collection/components/hooks/useBindingHaveItemData'
import { useBindingCommon } from '../../../collection/components/hooks/useBindingCommon'

const FakeData = [
    {
        id: '1',
        title: 'Samsung Electronics Galaxy Buds Live',
        subTitle: '$89.99',
        imageUrl:
            'https://storage.googleapis.com/pik-buk/inspitrip/2019_1dae4334-8ae5-4f4f-bcbb-ae9c9a836706.jpg?w=800',
        avatar: 'https://storage.googleapis.com/pik-buk/inspitrip/2019_1dae4334-8ae5-4f4f-bcbb-ae9c9a836706.jpg?w=800',
    },
    {
        id: '2',
        title: 'Samsung Electronics Galaxy Buds Live',
        subTitle: '$89.99',
        imageUrl:
            'https://storage.googleapis.com/pik-buk/inspitrip/2019_1dae4334-8ae5-4f4f-bcbb-ae9c9a836706.jpg?w=800',
        avatar: 'https://storage.googleapis.com/pik-buk/inspitrip/2019_1dae4334-8ae5-4f4f-bcbb-ae9c9a836706.jpg?w=800',
    },
    {
        id: '3',
        title: 'Samsung Electronics Galaxy Buds Live',
        subTitle: '$89.99',
        imageUrl:
            'https://storage.googleapis.com/pik-buk/inspitrip/2019_1dae4334-8ae5-4f4f-bcbb-ae9c9a836706.jpg?w=800',
        avatar: 'https://storage.googleapis.com/pik-buk/inspitrip/2019_1dae4334-8ae5-4f4f-bcbb-ae9c9a836706.jpg?w=800',
    },
    {
        id: '4',
        title: 'Samsung Electronics Galaxy Buds Live',
        subTitle: '$89.99',
        imageUrl:
            'https://storage.googleapis.com/pik-buk/inspitrip/2019_1dae4334-8ae5-4f4f-bcbb-ae9c9a836706.jpg?w=800',
        avatar: 'https://storage.googleapis.com/pik-buk/inspitrip/2019_1dae4334-8ae5-4f4f-bcbb-ae9c9a836706.jpg?w=800',
    },
    {
        id: '5',
        title: 'Samsung Electronics Galaxy Buds Live',
        subTitle: '$89.99',
        imageUrl:
            'https://storage.googleapis.com/pik-buk/inspitrip/2019_1dae4334-8ae5-4f4f-bcbb-ae9c9a836706.jpg?w=800',
        avatar: 'https://storage.googleapis.com/pik-buk/inspitrip/2019_1dae4334-8ae5-4f4f-bcbb-ae9c9a836706.jpg?w=800',
    },
    {
        id: '6',
        title: 'Samsung Electronics Galaxy',
        subTitle: '$89.99',
        imageUrl:
            'https://storage.googleapis.com/pik-buk/inspitrip/2019_1dae4334-8ae5-4f4f-bcbb-ae9c9a836706.jpg?w=800',
        avatar: 'https://storage.googleapis.com/pik-buk/inspitrip/2019_1dae4334-8ae5-4f4f-bcbb-ae9c9a836706.jpg?w=800',
    },
]

export type IData = {
    id: string
}

const InlineCollection = observer(
    (props: {
        inlineCollection: InlineCollectionStore
        item: Record<string, unknown>
    }) => {
        const { inlineCollection, item } = props
        const [data, setData] = useState<Array<IData>>([])
        const isHidden = useBindingCommon(inlineCollection.isHidden, item)
        useEffect(() => {
            setData(data)
        }, [])
        return (
            !isHidden && (
                <View style={styles.container}>
                    <Layout
                        inlineCollection={inlineCollection}
                        data={FakeData}
                    />
                </View>
            )
        )
    }
)

export default InlineCollection

const styles = StyleSheet.create({
    container: {
        marginVertical: appConstants.PADDING_COMMON / 2,
    },
})
