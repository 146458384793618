import { Binding } from '@appformula/app-descriptor/src'
import { Nullable } from '@appformula/shared-foundation-x/src'
import { Alert } from '../components/alert'
import appConstants from '../constants/const'
import 'react-native-get-random-values'
import { v4 as uuidv4 } from 'uuid'
import * as Location from 'expo-location'
import { LocationGeocodedAddress } from 'expo-location'
import { ICoords } from '../pages/form/components/Location/locationSearchingPage/index.ios'
import { Platform } from 'react-native'
import { PromiseResolution } from 'promise.allsettled'

const WIDTH_LARGE_DEVICE = 700

export type ResponseUploadData = {
    success: boolean
    assetUrl: string
}

export type ResponseUpload = {
    data: string
}

export const demo = (key: string): string => {
    return key
}

export const getCardItemHeight = (
    isHaveAccessory: boolean,
    isHaveItemSubtitle: boolean,
    imageSize: string,
    width: number,
    contentPosition: string,
    isHaveImage: boolean
): number => {
    let contentHeight: number
    if (isHaveAccessory && isHaveItemSubtitle) {
        contentHeight = appConstants.MAX_CONTENT_HEIGHT
    } else if (isHaveAccessory && !isHaveItemSubtitle) {
        contentHeight = appConstants.MAX_CONTENT_HEIGHT_WITHOUT_SUBTITLE
    } else if (!isHaveAccessory && isHaveItemSubtitle) {
        contentHeight = appConstants.MAX_CONTENT_HEIGHT_WITHOUT_ACCESSORY
    } else {
        contentHeight = appConstants.MAX_CONTENT_HEIGHT_ONLY_TITLE
    }

    if (!isHaveImage) {
        if (imageSize === 'large') {
            return contentHeight
        } else {
            // When image size is small, limit number lines added 2
            return contentHeight + 36
        }
    } else {
        if (contentPosition !== 'block') {
            // Overlay only have size large
            return ((width - 2 * appConstants.PADDING_COMMON) * 3) / 4
        } else {
            if (imageSize === 'large') {
                return (
                    contentHeight +
                    ((width - 2 * appConstants.PADDING_COMMON) * 3) / 4
                )
            } else {
                // When image size is small, limit number lines added 2
                contentHeight = contentHeight + 36
                return (
                    contentHeight +
                    (((width - 3 * appConstants.PADDING_COMMON) / 2) * 3) / 4
                )
            }
        }
    }
}

const scaleWidth = appConstants.WIDTH_SCREEN / appConstants.BASE_WIDTH
const scaleHeight = appConstants.HEIGHT_SCREEN / appConstants.BASE_HEIGHT
const scale = Math.min(scaleWidth, scaleHeight)

export const scaledSize = (size: number) => Math.ceil(size * scale)

export const hexToRGBA = (hex: string, opacity: number) => {
    return (
        'rgba(' +
        (hex = hex.replace('#', ''))
            .match(new RegExp('(.{' + hex.length / 3 + '})', 'g'))
            ?.map(function (l) {
                return parseInt(hex.length % 2 ? l + l : l, 16)
            })
            .concat(isFinite(opacity) ? opacity : 1)
            .join(',') +
        ')'
    )
}

export const fileNameFromUrl = (url: Nullable<Binding<string>> | string) => {
    if (url) {
        const regex = /\/([^\\/?#]+)[^\\/]*$/
        const matches = url.toString().match(regex)
        if (matches && matches.length > 1) {
            return matches[1]
        }
        return ''
    }
    return ''
}

export const getExtension = (filename: string): string => {
    const extension = filename.split('.').pop()
    return extension
}

export const generateRandomId = () => {
    return '_' + uuidv4()
}

export const padTo2Digits = (num: number) => {
    return num.toString().padStart(2, '0')
}

export const millisToMinutesAndSeconds = (milliseconds: number) => {
    let seconds = Math.floor(milliseconds / 1000)
    let minutes = Math.floor(seconds / 60)
    let hours = Math.floor(minutes / 60)

    seconds = seconds % 60
    minutes = minutes % 60
    hours = hours % 24

    if (hours > 0) {
        return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(
            seconds
        )}`
    } else {
        return `${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`
    }
}

export const validateVimeoURL = (url: string) => {
    if (url) {
        const regex = /^(http:\/\/|https:\/\/)?(www\.)?(vimeo\.com\/)([0-9]+)$/
        return regex.test(url)
    } else {
        return false
    }
}

export const validateYouTubeURL = (url: string) => {
    if (url) {
        const regExp =
            /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/
        const match = url.match(regExp)
        if (match && (match?.[2]?.length || 0) === 11) {
            return true
        } else {
            return false
        }
    }
    return false
}

export const convertDayOfWeek = (numberWeekDay: number) => {
    switch (numberWeekDay) {
        case 0:
            return 'S'
        case 1:
            return 'M'
        case 2:
            return 'T'
        case 3:
            return 'W'
        case 4:
            return 'T'
        case 5:
            return 'F'
        case 6:
            return 'S'
        default:
            return ''
    }
}

export const getStanForName = (name: string) => {
    if (!name) return ''
    return name
        .split(' ')
        .map((str) => str[0])
        .slice(0, 2)
        .join('')
}

export const getPermissionLocation = async () => {
    const { status } = await Location.requestForegroundPermissionsAsync()
    if (status !== 'granted') {
        Alert.alert(
            'Notification',
            'Permission to access location was denied, Please re-open app setting.',
            [
                {
                    text: 'OK',
                    onPress: () => {
                        if (Platform.OS !== 'web') {
                            const {
                                openSettings,
                            } = require('react-native-permissions')
                            openSettings().catch(() =>
                                console.warn('cannot open settings')
                            )
                        }
                    },
                },
            ]
        )
        return false
    }
    return true
}

export const getCurrentLocation = async () => {
    try {
        const hasPermission = await getPermissionLocation()
        if (!hasPermission) {
            throw new Error('Permission to access location was denied')
        }

        const location = await Location.getCurrentPositionAsync({
            accuracy:
                Platform.OS === 'android'
                    ? Location.Accuracy.Low
                    : Location.Accuracy.Lowest,
        })

        let detailLocaton

        if (Platform.OS === 'web') {
            detailLocaton = {}
        } else {
            detailLocaton = await getDetailPlace(location.coords)
        }
        const description = `${detailLocaton?.streetNumber || ''} ${
            detailLocaton?.street || ''
        } ${detailLocaton?.city || ''} ${detailLocaton?.country || ''}`
        const mainText = `${detailLocaton?.streetNumber || ''} ${
            detailLocaton?.street || ''
        }`
        return Promise.resolve({
            ...location.coords,
            description,
            mainText,
            placeId: appConstants.PLACE_ID_CURRENT,
        })
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getDetailPlace = (
    coords: ICoords
): Promise<LocationGeocodedAddress> => {
    return new Promise((resolve, reject) => {
        Location.reverseGeocodeAsync(coords)
            .then((response) => {
                resolve(response[0])
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const randomTrueFalse: boolean = !!(Math.random() < 0.5)

export const removeAscent = (str: string): string => {
    if (str === null || str === undefined) return str
    str = str.toLowerCase()
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i')
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
    str = str.replace(/đ/g, 'd')
    return str
}
export const convertResultSuccessToUrl = (
    resultSuccess: PromiseResolution<Record<string, unknown>>
): string =>
    (
        JSON.parse(
            (resultSuccess?.value as ResponseUpload)?.data
        ) as ResponseUploadData
    )?.assetUrl ?? ''

export const convertResponseUploadToUrl = (
    resultSuccess: Record<string, unknown>
): string =>
    (JSON.parse((resultSuccess as ResponseUpload)?.data) as ResponseUploadData)
        ?.assetUrl ?? ''

export const isLargeDevice = (width: number): boolean => {
    return width >= WIDTH_LARGE_DEVICE
}

export const typeCheck = (value: any) => {
    const return_value = Object.prototype.toString.call(value)
    // we can also use regex to do this...
    const type = return_value.substring(
        return_value.indexOf(' ') + 1,
        return_value.indexOf(']')
    )

    return type
}

export const asyncFilter = async (
    arr: Record<string, unknown>[],
    predicate: (item: Record<string, unknown>) => unknown
): Promise<Record<string, unknown>[]> =>
    Promise.all(arr.map(predicate)).then((results) =>
        arr.filter((_v, index) => results[index])
    )
