//
//  CollectionComponent.ts
//
//  Created by thaitd96 on 2022-08-10 14:24
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck

import {
    CollectionComponent,
    SortColumn,
    SortOrder,
} from '@appformula/app-descriptor'
import { CollectionComponentRepository } from '@appformula/app-studio-core'
import { LocalPageComponentRepository } from './Base'

export class LocalCollectionComponentRepository<C extends CollectionComponent>
    extends LocalPageComponentRepository<C>
    implements CollectionComponentRepository<C>
{
    insertSortColumn(
        columnId: string,
        columnName: string,
        sortOrder: SortOrder,
        rank: string
    ): Promise<void> {
        return this.ref.runTransaction((data) => {
            let sort = data.sort
            if (!sort) {
                sort = {}
            }

            sort[columnId] = {
                columnName: columnName,
                order: sortOrder,
            }

            let positions = data.sortColumnsPosition
            if (!positions) {
                positions = {}
            }
            positions[columnId] = rank

            return data
        })
    }

    setColumnPosition(columnId: string, rank: string): Promise<void> {
        return this.ref.child('sortColumnsPosition').child(columnId).set(rank)
    }

    setColumnProp<K extends keyof SortColumn>(
        key: K,
        value: SortColumn[K],
        columnId: string
    ): Promise<void> {
        throw new Error('Method not implemented.')
    }

    removeSortColumnId(columnId: string): Promise<void> {
        return this.ref.runTransaction((data) => {
            delete data.sort?.[columnId]
            delete data.sortColumnsPosition?.[columnId]

            return data
        })
    }
}
