//
//  FilterBooleanItem.tsx
//
//  Created by thaitd96 on 2022-07-26 14:15.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { View, StyleSheet, Text, Pressable, Image } from 'react-native'
import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { Theme, useTheme } from '../../../../../styles/theme'
import image from '../../../../../assets/icons'
import appConstants from '../../../../../constants/const'
import { ItemFilterBoolean } from './FilterBoolean'

const FilterBooleanItem = observer(
    (props: {
        isSelected: boolean
        item: ItemFilterBoolean
        onChangeSelected: (item: ItemFilterBoolean) => unknown
    }) => {
        const { isSelected, item, onChangeSelected } = props

        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])

        const onPressItem = () => {
            onChangeSelected(props.item)
        }

        return (
            <Pressable onPress={onPressItem}>
                <View style={styles.viewCheckBox}>
                    <View style={styles.wrapImg}>
                        {isSelected ? (
                            <View>
                                <Image
                                    source={image.radioChecked}
                                    style={styles.radioChecked}
                                    resizeMode={'contain'}
                                ></Image>
                            </View>
                        ) : (
                            <Image
                                style={styles.radioUncheck}
                                source={image.radioUncheck}
                                resizeMode={'contain'}
                            />
                        )}
                    </View>
                    <Text
                        style={styles.title}
                        numberOfLines={1}
                        ellipsizeMode={'tail'}
                    >
                        {item.label}
                    </Text>
                </View>
            </Pressable>
        )
    }
)

export default FilterBooleanItem

const createStyles = ({ colorScheme }: Theme) =>
    StyleSheet.create({
        viewCheckBox: {
            flexDirection: 'row',
            alignItems: 'center',
            height: 46,
        },
        title: {
            fontWeight: '500',
            fontSize: 15,
            lineHeight: 18,
            marginRight: appConstants.PADDING_COMMON,
            color: colorScheme.labelPrimary,
        },
        wrapImg: {
            marginRight: 12,
        },
        radioChecked: {
            height: 20,
            width: 20,
            tintColor: colorScheme.accentColor,
        },
        imgCheck: { height: 20, width: 20, position: 'absolute' },
        radioUncheck: { height: 20, width: 20 },
    })
