//
//  ARNBarCodescannerFormStore.ts
//
//  Created by thaitd on 2022-08-12 14:08.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { action, makeObservable, observable } from 'mobx'
import { Subject, Subscription } from 'rxjs'
import { FormulaManager } from '../../../../utils/integrate-formula/FormulaManager'
import {
    ARNFormPageStore,
    BarcodeScannerFormData,
} from '../../ARNFormPageStore'
import { BaseARNFormStore } from '../BaseARNFormStore'
export class ARNBarCodescannerFormStore implements BaseARNFormStore {
    readonly formPageId: string
    readonly componentId: string
    value: string
    arnFormPageStore: ARNFormPageStore
    valueChange: Subject<void>
    formulaManager?: FormulaManager

    constructor(
        arnFormPageStore: ARNFormPageStore,
        formPageId: string,
        componentId: string,
        initData?: BarcodeScannerFormData,
        formulaManager?: FormulaManager
    ) {
        this.arnFormPageStore = arnFormPageStore
        this.formPageId = formPageId
        this.componentId = componentId
        this.value = initData?.value ?? ''
        this.valueChange = new Subject()
        this.formulaManager = formulaManager

        makeObservable(this, {
            value: observable,
            setValue: action,
        })
    }

    startListeningEvents(): Subscription[] {
        const valueChangeSubscription = this.valueChange.subscribe({
            next: () => {
                this.arnFormPageStore.clearFormScreenError(this.componentId)
            },
        })

        return [valueChangeSubscription]
    }

    setValue(value: string) {
        this.value = value
        this.valueChange.next()
    }

    async validate(): Promise<void> {
        const componentStore = this.arnFormPageStore.pageStore.componentStore(
            this.componentId
        )

        if (componentStore.required && !this.value?.trim()) {
            Promise.reject(`${componentStore.titleName} is required!`)
        }
    }
}
