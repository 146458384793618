//
//  LeftViewBelowInternal.tsx
//
//  Created by thaitd96 on 2022-12-05 13:57.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import React, {
    Image,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
} from 'react-native'
import { Theme, useTheme } from '../../../../../styles/theme'
import image from '../../../../../assets/icons/index'
import { WrapContextAppDesktopStore } from '../../WrapContextAppDesktopStore'
const LINK_IOS = 'https://starion.io/starion-go'
const LINK_ANDROID = 'https://starion.io/starion-go'

type Props = {
    wrapContextAppDesktopStore: WrapContextAppDesktopStore
}

const LeftViewBelowInternal = observer((props: Props) => {
    const { wrapContextAppDesktopStore } = props
    const theme = useTheme()
    const styles = useMemo(() => createStyles(theme), [theme])
    return (
        <View style={styles.viewBelow}>
            <Text style={styles.download}>{'Download Starion Go'}</Text>
            <Text style={styles.descriptorDownload}>
                {
                    'Download the Starion Go app to use native features like\ncamera, barcode scanning, location updates and more.'
                }
            </Text>
            <Image source={image.qrCode} style={styles.qrCode}></Image>
            <View style={styles.viewButton}>
                <TouchableOpacity
                    onPress={() =>
                        wrapContextAppDesktopStore.openLink(LINK_IOS)
                    }
                >
                    <Image
                        source={image.appStore}
                        style={styles.appStore}
                    ></Image>
                </TouchableOpacity>
                <TouchableOpacity
                    style={styles.buttonGooglePlay}
                    onPress={() =>
                        wrapContextAppDesktopStore.openLink(LINK_ANDROID)
                    }
                >
                    <Image
                        source={image.googlePlay}
                        style={styles.googlePlay}
                    ></Image>
                </TouchableOpacity>
            </View>
        </View>
    )
})

export default LeftViewBelowInternal

const createStyles = ({ colorScheme }: Theme) =>
    StyleSheet.create({
        buttonGooglePlay: { marginLeft: 3 },
        googlePlay: { height: 67, width: 176, resizeMode: 'contain' },
        appStore: { height: 43.03, width: 132.66, resizeMode: 'contain' },
        viewButton: {
            flexDirection: 'row',
            marginTop: 14,
            alignItems: 'center',
        },
        qrCode: { height: 220, width: 220, marginTop: 14 },
        descriptorDownload: {
            marginTop: 8,
            fontWeight: '400',
            fontSize: 14,
            lineHeight: 24,
            color: colorScheme.labelSecondary,
        },
        download: {
            color: colorScheme.labelPrimary,
            fontWeight: '600',
            fontSize: 18,
            lineHeight: 21.78,
        },
        viewBelow: {
            marginTop: 50,
            flex: 1,
            backgroundColor: colorScheme.backgroundL2,
            borderRadius: 12,
            paddingVertical: 24,
            paddingHorizontal: 32,
        },
    })
