import { ColorValue, StyleSheet, View } from 'react-native'
import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { CollectionPageStore } from '@appformula/app-studio-core/src'
import { CalendarLayout as CalendarLayoutDescriptor } from '@appformula/app-descriptor/src'
import { GroupDisplay } from '../ItemGroupCalendar'
import { Theme, useTheme } from '../../../../../styles/theme'
import { hexToRGBA } from '../../../../../utils'

const ItemWeekCalendar = observer(
    (props: {
        item: Record<string, unknown>
        pageStore: CollectionPageStore
    }) => {
        const { item } = props
        const theme = useTheme()

        const activeLayout = props.pageStore
            .activeLayout as CalendarLayoutDescriptor
        const groupsDisplay = Object.values(
            activeLayout.groupsDisplay || {}
        ) as GroupDisplay[]

        const groupingColumn = activeLayout.groupingColumn
        const colorLine = groupsDisplay?.find(
            (e) => e.columnValue === item?.[groupingColumn] || ''
        )?.color

        const styles = useMemo(
            () => createStyles(theme, colorLine),
            [theme, colorLine]
        )

        return (
            <View style={[styles.myEventComponentStyle]}>
                <View style={styles.line} />
            </View>
        )
    }
)

export default ItemWeekCalendar

const createStyles = ({ colorScheme }: Theme, colorLine: ColorValue | string) =>
    StyleSheet.create({
        line: {
            position: 'absolute',
            height: '100%',
            backgroundColor: colorLine || '#cccccc',
            width: 3,
            borderRadius: 1,
        },
        myEventComponentStyle: {
            backgroundColor: colorLine
                ? hexToRGBA(colorLine as string, 0.2)
                : hexToRGBA('#cccccc' as string, 0.2),
            height: '100%',
        },
    })
