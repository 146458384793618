import { Database } from '@nozbe/watermelondb'
import { SourceStartUp } from '../app/App'

let sourceStartUp = 'APP_NORMAL' as SourceStartUp
let baseUrl = ''
let database: Database
export default {
    sourceStartUp,
    baseUrl,
    database,
    resetCache: () => {
        baseUrl = ''
        sourceStartUp = 'APP_NORMAL'
    },
}
