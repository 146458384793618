//
//  Base.ts
//
//  Created by thaitd96 on 2022-08-10 14:24
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { Component } from '@appformula/app-descriptor'
import { PageComponentRepository } from '@appformula/app-studio-core'
import { Observable } from 'rxjs'
import { AnyObservableObjectRepository } from '../../../ObjectRepository'

export class LocalPageComponentRepository<C extends Component>
    extends AnyObservableObjectRepository<C>
    implements PageComponentRepository<C>
{
    get componentId(): string {
        return this.objectId
    }
    get componentSnapshot(): C {
        return this.objectSnapshot
    }
    get component(): Observable<C> {
        return this.object
    }
    set<K extends keyof C>(key: K, value: C[K]): Promise<void> {
        throw new Error('Method not implemented.')
    }
    update<K extends keyof C>(key: K, value: Partial<C[K]>): Promise<void> {
        throw new Error('Method not implemented.')
    }
}
