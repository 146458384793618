//
//  Map.tsx
//
//  Created by thaitd96 on 2022-20-04 11:49.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { MapStore } from '@appformula/app-studio-core'
import React, { useEffect, useMemo } from 'react'
import { StyleSheet, View } from 'react-native'
import GoogleMapReact from 'google-map-react'
import appConstants from '../../../../constants/const'
import { Theme, useTheme } from '../../../../styles/theme'
import { ARNMapStore } from './ARNMapStore'
import { useWidthHeightMainLayout } from '../../../collection/components/hooks/useSpecifyPlatformWindowDimensions'
import { Nullable } from '@appformula/shared-foundation-x/src'
import { Binding } from '@appformula/app-descriptor/src'
import { observer } from 'mobx-react-lite'
import { IReverseGeocodeItem } from '../../../../utils/network/extensions/MapApi'
import { IReverseExtend } from '../../../collection/components/map-layout/MapLayoutStore'
import { FormulaManagerContext } from '../../../../context/FormulaManagerContext'
import cache from '../../../../utils/cache'

const defaultProps = {
    center: {
        lat: 10.99835602,
        lng: 77.01502627,
    },
    zoom: 11,
}

const Map = observer(
    (props: {
        mapStore: MapStore
        arnMapStore: ARNMapStore
        address: Nullable<Binding<string>>
    }) => {
        const { mapStore, arnMapStore, address } = props
        const { width } = useWidthHeightMainLayout()
        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme, width), [theme, width])
        const formulaManager = React.useContext(FormulaManagerContext)

        useEffect(() => {
            if (arnMapStore) {
                arnMapStore.getAndSetMapData(formulaManager, address)
            }
        }, [address])

        const Marker = ({
            text,
            item,
            lat,
            lng,
        }: {
            text?: string
            item: IReverseGeocodeItem & IReverseExtend
            lat: number
            lng: number
        }) => {
            return <View />
        }

        console.info(
            'arnMapStore.listMarkerData====',
            arnMapStore.listMarkerData
        )

        return (
            <View style={styles.viewMap} removeClippedSubviews>
                <GoogleMapReact
                    bootstrapURLKeys={{
                        key: 'AIzaSyCOMyzp0azA3gDL1Nk31OiiGUvCFyMKU7k',
                    }}
                    defaultCenter={{
                        lat: appConstants.REGION_DEFAULT.latitude,
                        lng: appConstants.REGION_DEFAULT.longitude,
                    }}
                    center={
                        arnMapStore.mapRegion
                            ? {
                                  lat: arnMapStore.mapRegion?.latitude,
                                  lng: arnMapStore.mapRegion?.longitude,
                              }
                            : null
                    }
                    // defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                    yesIWantToUseGoogleMapApiInternals
                >
                    {/* {arnMapStore.listMarkerData.length > 0 &&
                        arnMapStore.listMarkerData?.map(
                            (
                                item: IReverseGeocodeItem & IReverseExtend,
                                index
                            ) => (
                                <Marker
                                    key={item.query}
                                    lat={item.data.coordinate.latitude}
                                    lng={item.data.coordinate.longitude}
                                    item={item}
                                />
                            )
                        )} */}
                </GoogleMapReact>
            </View>
        )
    }
)

export default Map

const createStyles = (
    { colorScheme, roundness, typography }: Theme,
    width: number
) =>
    StyleSheet.create({
        viewMap: {
            width:
                cache.sourceStartUp === 'APP_EDITOR'
                    ? width - 40
                    : width - 2 * appConstants.PADDING_COMMON,
            height: 294,
            borderRadius: roundness,
            marginVertical: appConstants.PADDING_COMMON / 2,
            overflow: 'hidden',
        },
    })
