//
//  TextComponent.tsx
//
//  Created by thaitd96 on 2022-20-04 11:38.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React, { useMemo } from 'react'
import { StyleSheet, Text, TextStyle, View } from 'react-native'
import { Theme, useTheme } from '../../../styles/theme'
import { TextComponentStore } from '@appformula/app-studio-core'
import { observer } from 'mobx-react-lite'
import { useBindingCommon } from '../../collection/components/hooks/useBindingCommon'

export const TextComponent = observer(
    (props: {
        textComponent: TextComponentStore
        item: Record<string, unknown>
    }) => {
        const { textComponent, item } = props
        const textData = textComponent.object
        const text = useBindingCommon(textData?.text, item)
        const isHidden = useBindingCommon(textData?.isHidden, item)

        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])

        const styleTxt: TextStyle = useMemo(() => {
            let textAlign = null
            if (textData?.textAlignment === 'center') {
                textAlign = 'center'
            }
            if (textData?.textAlignment === 'leading') {
                textAlign = 'left'
            }
            if (textData?.textAlignment === 'trailing') {
                textAlign = 'right'
            }
            return {
                ...(textAlign && { textAlign }),
            } as TextStyle
        }, [textData])

        return (
            !isHidden && (
                <View style={styles.container}>
                    <Text style={[styleTxt, styles[textData?.style]]}>
                        {(text || '') as string}
                    </Text>
                </View>
            )
        )
    }
)

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        container: {},
        h1: {
            fontWeight: '700',
            fontSize: 28,
            lineHeight: 33.89,
            color: colorScheme.labelPrimary,
        },
        h2: {
            fontWeight: '700',
            fontSize: 26,
            lineHeight: 31.47,
            color: colorScheme.labelPrimary,
        },
        h3: {
            fontWeight: '600',
            fontSize: 20,
            lineHeight: 24.2,
            color: colorScheme.labelPrimary,
        },
        h4: {
            fontWeight: '600',
            fontSize: 18,
            lineHeight: 21.78,
            color: colorScheme.labelPrimary,
        },
        h5: {
            fontWeight: '600',
            fontSize: 16,
            lineHeight: 21.6,
            color: colorScheme.labelPrimary,
        },
        h6: {
            fontWeight: '500',
            fontSize: 14,
            lineHeight: 16.94,
            color: colorScheme.labelPrimary,
        },
        paragraph: {
            fontWeight: '400',
            fontSize: 15,
            lineHeight: 24.75,
            color: colorScheme.labelPrimary,
        },
    })
