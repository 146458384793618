//
//  CollectionPageRepository.ts
//
//  Created by thaitd96 on 2022-08-10 14:24
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import {
    CalendarLayout,
    CardLayout,
    CollectionPage,
    ColumnBinding,
    ColumnBindingClause,
    Filter,
    FilterClause,
    FilterType,
    FormulaFilterBinding,
    ItemAction,
    LayoutType,
    ListLayout,
    MapLayout,
    SearchConfig,
    SortColumn,
    SortOrder,
} from '@appformula/app-descriptor'
import {
    CollectionPageItemActionRepository,
    CollectionPageRepository,
    CollectionPageSearchBarRepository,
} from '@appformula/app-studio-core'
import { of } from 'rxjs'
import { AnyObservableObjectRepository } from '../../ObjectRepository'
import { LocalBasePageRepository } from '../BaseRepository'

export class LocalCollectionPageRepository
    extends LocalBasePageRepository<CollectionPage>
    implements CollectionPageRepository
{
    removeFilterGroup(preFilterId: string, type: FilterType): Promise<void> {
        throw new Error('Method not implemented.')
    }
    setActiveFilterType(filterType: FilterType): Promise<void> {
        throw new Error('Method not implemented.')
    }
    insertPreFilter(
        preFilterId: string,
        type: FilterType,
        filter: Filter | FormulaFilterBinding,
        priority: string
    ): Promise<void> {
        throw new Error('Method not implemented.')
    }
    addColumnFilter(
        columnFilterKey: string,
        bindingKey: string,
        value: ColumnBinding
    ): Promise<void> {
        throw new Error('Method not implemented.')
    }
    changeColumnFilterClause(
        columnFilterId: string,
        clause: FilterClause
    ): Promise<void> {
        throw new Error('Method not implemented.')
    }
    changeColumnFilterBindingClause(
        columnFilterId: string,
        preFilterId: string,
        clause: ColumnBindingClause
    ): Promise<void> {
        throw new Error('Method not implemented.')
    }
    removeAllFilter(): Promise<void> {
        throw new Error('Method not implemented.')
    }
    removePreFilter(
        preFilterId: string,
        type: FilterType,
        columnFilterId?: string
    ): Promise<void> {
        throw new Error('Method not implemented.')
    }
    addItemAction(itemActionId: string, action: ItemAction): Promise<void> {
        throw new Error('Method not implemented.')
    }
    removeItemAction(itemActionId: string): Promise<void> {
        throw new Error('Method not implemented.')
    }
    setItemActionTitle(itemActionId: string, title: string): Promise<void> {
        throw new Error('Method not implemented.')
    }
    setActiveLayoutType(layoutType: LayoutType): Promise<void> {
        throw new Error('Method not implemented.')
    }
    setCardLayout(layout: CardLayout): Promise<void> {
        throw new Error('Method not implemented.')
    }
    setCardLayoutProp<K extends keyof CardLayout>(
        key: K,
        value: CardLayout[K]
    ): Promise<void> {
        throw new Error('Method not implemented.')
    }
    setMapLayout(layout: MapLayout): Promise<void> {
        throw new Error('Method not implemented.')
    }
    setMapLayoutProp<K extends keyof MapLayout>(
        key: K,
        value: MapLayout[K]
    ): Promise<void> {
        throw new Error('Method not implemented.')
    }
    setCalendarLayout(layout: CalendarLayout): Promise<void> {
        throw new Error('Method not implemented.')
    }
    setCalendarLayoutProp<K extends keyof CalendarLayout>(
        key: K,
        value: CalendarLayout[K]
    ): Promise<void> {
        throw new Error('Method not implemented.')
    }
    setListLayout(layout: ListLayout): Promise<void> {
        throw new Error('Method not implemented.')
    }
    setListLayoutProp<K extends keyof ListLayout>(
        key: K,
        value: ListLayout[K]
    ): Promise<void> {
        throw new Error('Method not implemented.')
    }
    insertSortColumn(
        columnId: string,
        sortOrder: SortOrder,
        rank: string
    ): Promise<void> {
        throw new Error('Method not implemented.')
    }
    removeSortColumnId(columnId: string): Promise<void> {
        throw new Error('Method not implemented.')
    }
    setSortColumnProp<K extends keyof SortColumn>(
        key: K,
        value: SortColumn[K],
        columnId: string
    ): Promise<void> {
        throw new Error('Method not implemented.')
    }
    searchBarRepository(): CollectionPageSearchBarRepository {
        const searchBarId = `__${this.pageId}_searchBar`
        return new LocalCollectionPageSearchBarRepository(
            of(this.objectSnapshot?.search),
            this.objectSnapshot?.search,
            searchBarId
        )
    }
    itemActionRepository(
        itemActionId: string
    ): CollectionPageItemActionRepository {
        return new LocalCollectionPageItemActionRepository(
            of(this.objectSnapshot?.itemActions?.[itemActionId]),
            this.objectSnapshot?.itemActions?.[itemActionId],
            itemActionId
        )
    }
}

class LocalCollectionPageSearchBarRepository
    extends AnyObservableObjectRepository<SearchConfig>
    implements CollectionPageSearchBarRepository
{
    set<K extends keyof SearchConfig>(
        key: K,
        value: SearchConfig[K]
    ): Promise<void> {
        throw new Error('Method not implemented.')
    }
}

class LocalCollectionPageItemActionRepository
    extends AnyObservableObjectRepository<ItemAction>
    implements CollectionPageItemActionRepository
{
    set<K extends keyof ItemAction>(
        key: K,
        value: ItemAction[K]
    ): Promise<void> {
        throw new Error('Method not implemented.')
    }
}
