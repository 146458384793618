import {
    StyleSheet,
    Text,
    TextInput,
    TouchableOpacity,
    View,
} from 'react-native'
import React from 'react'
import GoogleSignInButton from './components/GoogleSignInButton'
import AppleSignInButton from './components/AppleSignInButton'
import { observer } from 'mobx-react-lite'
import { Theme, useTheme } from '../../styles/theme'
import { WelcomePageStore } from './WelcomePageStore'
import AppButton from '../../components/AppButton'
import appConstants from '../../constants/const'
import { useNavigation } from '@react-navigation/native'
import screenNames from '../../navigations/screenNames'
import { AppDescriptorStore } from '@appformula/app-studio-core'
import { AppDescriptorContext } from '../../context/AppDescriptorContext'
import cache from '../../utils/cache'
import { Alert } from '../../components/alert'
import AuthenticationLayout from './AuthenticationLayout'

const SignInScreen = observer((props: { store: WelcomePageStore }) => {
    const appDescriptorStore: AppDescriptorStore =
        React.useContext(AppDescriptorContext)
    const theme = useTheme()
    const styles = React.useMemo(() => createStyles(theme), [theme])
    const navigation = useNavigation()

    const { store } = props

    React.useEffect(() => {
        store.observeConfig(appDescriptorStore.authenticationPage)

        return () => {
            store.unobserveConfig()
        }
    }, [appDescriptorStore.authenticationPage, store])

    const hasSeparateOR = React.useMemo(() => {
        return (
            (store.isShowAppleAuth || store.isShowGoogleAuth) &&
            store.isShowEmailAuth
        )
    }, [store.isShowAppleAuth, store.isShowEmailAuth, store.isShowGoogleAuth])

    const hasPrivacyContent = React.useMemo(() => {
        return (
            (store.isShowAppleAuth || store.isShowGoogleAuth) &&
            !store.isShowEmailAuth
        )
    }, [store.isShowAppleAuth, store.isShowEmailAuth, store.isShowGoogleAuth])

    const [email, setEmail] = React.useState<string>('')
    const [password, setPassword] = React.useState<string>('')

    const handleSetEmail = React.useCallback((value: string) => {
        setEmail(value)
    }, [])

    const handleSetPassword = React.useCallback((value: string) => {
        setPassword(value)
    }, [])

    const login = React.useCallback(async () => {
        if (cache.sourceStartUp !== 'APP_EDITOR') {
            await store.signIn(email.trim(), password.trim())
        } else {
            Alert.alert(
                'Notification',
                `You can't use this feature in Web Editor!`
            )
        }
    }, [email, password, store])

    const moveToReset = React.useCallback(() => {
        if (cache.sourceStartUp !== 'APP_EDITOR') {
            if (!store.loadingState) {
                navigation.navigate(screenNames.RESET_PASSWORD as never)
            }
        } else {
            Alert.alert(
                'Notification',
                `You can't use this feature in Web Editor!`
            )
        }
    }, [navigation, store.loadingState])

    const moveToSignUp = React.useCallback(() => {
        if (cache.sourceStartUp !== 'APP_EDITOR') {
            if (!store.loadingState) {
                navigation.navigate(screenNames.SIGN_UP as never)
            }
        } else {
            Alert.alert(
                'Notification',
                `You can't use this feature in Web Editor!`
            )
        }
    }, [navigation, store.loadingState])

    const onLoginAsAnonymous = React.useCallback(async () => {
        if (cache.sourceStartUp !== 'APP_EDITOR') {
            await store.signInAnonymously()
        } else {
            Alert.alert(
                'Notification',
                `You can't use this feature in Web Editor!`
            )
        }
    }, [store])

    return (
        <>
            <AuthenticationLayout
                title={store.welcomeTitle || ''}
                subTitle={store.welcomeSubtitle || ''}
                headerRightTitle={'Continue as guest'}
                onHeaderRight={onLoginAsAnonymous}
                isHideLeftHeader={true}
                isHideRightHeader={!store.isShowGuestAuth}
                icon={
                    store.brandLogoURL
                        ? {
                              uri: store.brandLogoURL,
                          }
                        : undefined
                }
            >
                {store.isShowGoogleAuth && (
                    <GoogleSignInButton
                        loading={store.loadingState}
                        appType={store.appType}
                    />
                )}
                {store.isShowAppleAuth && (
                    <AppleSignInButton
                        loading={store.loadingState}
                        appType={store.appType}
                    />
                )}
                {hasPrivacyContent && (
                    <Text style={styles.txtPrivacy}>
                        {`By tapping Continue with Google, Apple button, you’ve agree with ${store.appName} app `}
                        <Text style={styles.txtDetailPrivacy}>
                            Privacy Policy
                        </Text>{' '}
                        &{' '}
                        <Text style={styles.txtDetailPrivacy}>
                            Terms of Use
                        </Text>
                    </Text>
                )}
                {hasSeparateOR && (
                    <View style={styles.containerSeparator}>
                        <View style={styles.lineSeparator} />
                        <Text style={styles.txtSeparator}>OR</Text>
                        <View style={styles.lineSeparator} />
                    </View>
                )}
                {store.isShowEmailAuth && (
                    <>
                        <TextInput
                            style={styles.input}
                            onChangeText={handleSetEmail}
                            value={email}
                            placeholder={'name@work-email.com'}
                            autoCapitalize="none"
                            placeholderTextColor={
                                theme.colorScheme.labelSecondary
                            }
                        />
                        <TextInput
                            style={styles.input}
                            onChangeText={handleSetPassword}
                            value={password}
                            placeholder="Password"
                            secureTextEntry={true}
                            placeholderTextColor={
                                theme.colorScheme.labelSecondary
                            }
                        />
                        <AppButton
                            title="Sign In"
                            onPress={login}
                            loading={store.loadingState}
                        />
                        <TouchableOpacity
                            onPress={moveToReset}
                            style={styles.btnForgot}
                        >
                            <Text style={styles.txtForgot}>
                                Forgot your password?{' '}
                                <Text style={styles.txtReset}>Reset</Text>
                            </Text>
                        </TouchableOpacity>
                    </>
                )}
            </AuthenticationLayout>
            {store.isShowEmailAuth && (
                <View style={styles.btnSignUp}>
                    <TouchableOpacity onPress={moveToSignUp}>
                        <Text style={styles.txtHaveAcc}>
                            Don’t have an account?{' '}
                            <Text style={styles.txtSignUp}>Sign Up</Text>
                        </Text>
                    </TouchableOpacity>
                </View>
            )}
        </>
    )
})

export default SignInScreen

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        viewWelcome: {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            paddingHorizontal: 24,
        },
        input: {
            width: '100%',
            height: 50,
            marginBottom: 12,
            borderWidth: 1,
            padding: 10,
            borderRadius: roundness,
            borderColor: colorScheme.separator,
            color: colorScheme.labelPrimary,
        },
        container: {
            flex: 1,
            backgroundColor: colorScheme.backgroundL1,
        },
        headerContainer: {
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: colorScheme.backgroundL1,
            // marginBottom: 40,
        },
        containerHeaderRight: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            flex: 1,
        },
        containerScroll: {
            // flex: 1,
            justifyContent: 'space-between',
        },
        txtBtnGuess: {
            textAlign: 'center',
            margin: appConstants.PADDING_COMMON,
            color: colorScheme.labelSecondary,
        },
        logoImg: {
            width: 100,
            height: 100,
            marginBottom: 9,
        },
        txtTitle: {
            fontWeight: '700',
            fontSize: 30,
            lineHeight: 36.31,
            color: colorScheme.labelPrimary,
            marginBottom: 9,
        },
        txtSubTitle: {
            fontWeight: '400',
            fontSize: 15,
            lineHeight: 22.5,
            color: colorScheme.labelSecondary,
            textAlign: 'center',
            marginBottom: 20,
        },
        containerSeparator: {
            flexDirection: 'row',
            alignItems: 'center',
            marginVertical: 20,
        },
        lineSeparator: {
            flex: 1,
            height: 1,
            backgroundColor: colorScheme.separator,
        },
        txtSeparator: {
            marginHorizontal: 14,
            fontSize: 14,
            lineHeight: 16.94,
            fontWeight: '600',
            color: colorScheme.labelSecondary,
        },
        txtForgot: {
            fontSize: 13,
            lineHeight: 16,
            fontWeight: '500',
            color: colorScheme.labelSecondary,
        },
        txtReset: {
            fontSize: 13,
            lineHeight: 16,
            fontWeight: '500',
            color: colorScheme.accentColor,
        },
        btnForgot: {
            marginVertical: 14,
        },
        txtHaveAcc: {
            fontSize: 14,
            lineHeight: 17,
            fontWeight: '500',
            color: colorScheme.labelSecondary,
            marginVertical: 18,
        },
        txtSignUp: {
            fontSize: 14,
            lineHeight: 17,
            fontWeight: '500',
            color: colorScheme.accentColor,
            textDecorationLine: 'underline',
        },
        btnSignUp: {
            borderTopWidth: 1,
            borderTopColor: colorScheme.separator,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: colorScheme.backgroundL1,
        },
        txtPrivacy: {
            textAlign: 'center',
            color: colorScheme.labelSecondary,
            marginTop: 11,
            fontSize: 13,
            lineHeight: 19.5,
            fontWeight: '400',
        },
        txtDetailPrivacy: {
            color: colorScheme.accentColor,
            fontSize: 13,
            lineHeight: 19.5,
            fontWeight: '400',
            textDecorationLine: 'underline',
        },
    })
