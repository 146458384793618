import { View, Text, ColorValue, StyleSheet } from 'react-native'
import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { Theme, useTheme } from '../../../../../styles/theme'
import { GroupDisplay } from '../ItemGroupCalendar'
import { CalendarLayout as CalendarLayoutDescriptor } from '@appformula/app-descriptor/src'
import { CollectionPageStore } from '@appformula/app-studio-core/src'
import { Event } from 'react-native-month-view-calendar/lib/contracts/event'
import { appFormulaId } from '../../../../../database/type'

const ItemMonthCalendar = observer(
    (props: { item: Event; pageStore: CollectionPageStore }) => {
        const { item } = props
        const theme = useTheme()
        const activeLayout = props.pageStore
            .activeLayout as CalendarLayoutDescriptor
        const groupsDisplay = Object.values(
            activeLayout.groupsDisplay || {}
        ) as GroupDisplay[]
        const groupingColumn = activeLayout.groupingColumn
        const colorLine = groupsDisplay?.find(
            (e) => e.columnValue === item?.[groupingColumn] || ''
        )?.color

        const styles = useMemo(
            () => createStyles(theme, colorLine),
            [theme, colorLine]
        )

        return <View key={item?.[appFormulaId]} style={[styles.event]}></View>
    }
)

export default ItemMonthCalendar

const createStyles = ({ colorScheme }: Theme, colorLine: ColorValue | string) =>
    StyleSheet.create({
        event: {
            height: 5,
            width: 5,
            borderRadius: 5,
            margin: 2,
            backgroundColor: colorLine || 'red',
        },
    })
