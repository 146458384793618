import { View } from 'react-native'
import React from 'react'
import { observer } from 'mobx-react-lite'
import ResultItem from './ResultItem'
import { ILookup } from '..'
import { ARNLookupFormStore } from '../../ARNLookupFormStore'

const LookupsResult = observer(
    (props: { arnLookupFormStore: ARNLookupFormStore }) => {
        const { arnLookupFormStore } = props
        const lookupsSelected = arnLookupFormStore.data || {}

        const renderItem = (item: ILookup) => {
            return (
                <ResultItem key={item.id} item={item} removeItem={onRemove} />
            )
        }

        const onRemove = (item: ILookup) => {
            const newListSelected = { ...lookupsSelected }
            delete newListSelected[item.id]
            arnLookupFormStore.setData(newListSelected)
        }

        return (
            <View>
                {Object.values(lookupsSelected).length > 0 &&
                    Object.values(lookupsSelected).map((item) =>
                        renderItem(item)
                    )}
            </View>
        )
    }
)

export default LookupsResult
