import { TextInput, StyleSheet, TextInputProps } from 'react-native'
import React, {
    forwardRef,
    useImperativeHandle,
    useMemo,
    useState,
} from 'react'
import { Theme, useTheme } from '../styles/theme'

export interface IAppTextInput {
    setText?: (text: string) => void
    getValue?: () => string
}

const AppTextInput = forwardRef(
    (props: IAppTextInput & TextInputProps, ref) => {
        const { placeholder, defaultValue } = props
        const [value, setValue] = useState<string>(defaultValue || '')
        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])

        useImperativeHandle(ref, () => ({
            setText: (text: string) => {
                setValue(text)
            },
            getValue: () => {
                return value
            },
        }))

        const onChangeText = (text: string) => {
            setValue(text)
        }

        return (
            <TextInput
                style={styles.textInput}
                placeholder={placeholder}
                value={value}
                onChangeText={onChangeText}
                placeholderTextColor={theme.colorScheme.labelSecondary}
                selectTextOnFocus={true}
                {...props}
            />
        )
    }
)

AppTextInput.displayName = 'AppTextInput'

AppTextInput.defaultProps = {
    placeholder: 'Search',
}

export default AppTextInput

const createStyles = ({ colorScheme, roundness }: Theme) =>
    StyleSheet.create({
        textInput: {
            flex: 1,
            marginHorizontal: 3,
            height: '100%',
            color: colorScheme.labelPrimary,
        },
    })
