//
//  PeopleInput.tsx
//
//  Created by thaitd96 on 2022-20-04 11:55.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { PeopleInputStore } from '@appformula/app-studio-core'
import React, { useEffect, useMemo, useState } from 'react'
import { Image, Pressable, StyleSheet, Text, View } from 'react-native'
import { Theme, useTheme } from '../../../../styles/theme'
import PeopleChip from './PeopleChip'
import image from '../../../../assets/icons'
import { hexToRGBA } from '../../../../utils'
import TextInputEmailSearch, {
    IInfo,
} from '../../../../components/TextInputEmailSearch'
import { Binding, BindingValue } from '@appformula/app-descriptor/src'
import { useBindingCommon } from '../../../collection/components/hooks/useBindingCommon'

export const PeopleInput = (props: {
    peopleInput: PeopleInputStore
    item: Record<string, unknown>
}) => {
    const { peopleInput, item } = props

    const isHidden = useBindingCommon(peopleInput.isHidden, item)
    const title = useBindingCommon(
        peopleInput?.title as Binding<BindingValue>,
        item
    )
    const initialEmailAddresses = useBindingCommon(
        peopleInput?.initialEmailAddresses,
        item
    )
    const [people, setPeople] = useState<string[]>([])

    const theme = useTheme()
    const styles = useMemo(() => createStyles(theme), [theme])

    useEffect(() => {
        initialEmailAddresses && setPeople(initialEmailAddresses as string[])
    }, [initialEmailAddresses])

    const onSelectItem = (item: IInfo) => {
        const newPeople = [...people, item?.emailAddress || '']
        setPeople([...new Set(newPeople)])
    }

    const onRemove = (item: string) => {
        const newPeople = [...people]
        const index = newPeople.findIndex((i) => i === item)
        if (index > -1) {
            newPeople.splice(index, 1)
            setPeople(newPeople)
        }
    }

    return (
        !isHidden && (
            <View style={styles.viewPeopleInput}>
                <Text style={styles.txtTitle}>{title as string}</Text>
                {people.map((item) => (
                    <PeopleChip key={item} item={item} onRemove={onRemove} />
                ))}
                <View style={styles.wrapViewAdd}>
                    <Pressable>
                        <View style={styles.wrapImgAdd}>
                            <Image source={image.add} style={styles.imgAdd} />
                        </View>
                    </Pressable>
                    <TextInputEmailSearch
                        onSelectItem={onSelectItem}
                        menuOptionsProps={{
                            optionsContainerStyle: styles.popup,
                        }}
                    />
                </View>
            </View>
        )
    )
}

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        txtInput: {
            flex: 1,
            fontSize: 15,
            fontWeight: '500',
            lineHeight: 18.15,
            color: colorScheme.labelPrimary,
        },
        wrapViewAdd: { flexDirection: 'row', alignItems: 'center' },
        wrapImgAdd: {
            width: 32,
            height: 32,
            borderRadius: 16,
            backgroundColor: hexToRGBA(colorScheme.accentColor as string, 0.2),
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: 12,
        },
        imgAdd: { width: 24, height: 24, tintColor: colorScheme.accentColor },
        txtTitle: {
            fontSize: 16,
            fontWeight: '500',
            lineHeight: 19.36,
            color: colorScheme.labelPrimary,
        },
        viewPeopleInput: {
            marginVertical: 6,
        },

        popup: {
            width: 300,
            backgroundColor: colorScheme.backgroundL2,
            paddingHorizontal: 8,
            borderRadius: 5,
            marginLeft: 64,
        },
    })
