//
//  usePressItemAction.ts
//
//  Created by thaitd96 on 2022-09-06 11:12.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { useCallback, useContext } from 'react'
import { Linking } from 'react-native'
import { Alert } from '../../../../components/alert'
import * as Clipboard from 'expo-clipboard'
import {
    Action,
    CopyToClipboardAction,
    CreateRowAction,
    ModifyControlAction,
    NotifyPeopleAction,
    OpenPageAction,
    OpenURLAction,
    UpdateRowsAction,
} from '@appformula/app-descriptor/src'
import { useNavigation } from '@react-navigation/native'
import { AppDescriptorContext } from '../../../../context/AppDescriptorContext'
import { TransformBinding } from '../../../../utils/transform-binding/TransformBinding'
import screenNames from '../../../../navigations/screenNames'
import { FormulaManagerContext } from '../../../../context/FormulaManagerContext'
import { FormulaManager } from '../../../../utils/integrate-formula/FormulaManager'
import getFireStore from '../../../../utils/firebase/firestore/getFireStore'
import { nanoid } from 'nanoid'
import { ARNCustomDetailPageStore } from '../../../custom/ARNCustomDetailPageStore'
import { TransformConditionBindingToSql } from '../../../../utils/database/TransformConditionBindingToSql'
import { GetItemsByQueryString } from '../../../../utils/database/GetSqlData'
import { AppMetadataContext } from '../../../../context/AppMetadataContext'
import NotificationApi, {
    IDataPush,
} from '../../../../utils/network/extensions/NotificationApi'
import { DesignPageContext } from '../../../../context/DesignPageContext'
import { NormalPageSelection } from '@appformula/app-studio-core'
import { appFormulaId } from '../../../../database/type'

export function usePressItemAction(
    itemData?: Record<string, unknown>,
    arnDetailPageStore?: ARNCustomDetailPageStore
) {
    const navigation = useNavigation()
    const store = useContext(AppDescriptorContext)
    const designPageStore = useContext(DesignPageContext)
    const formulaManager: FormulaManager = useContext(
        FormulaManagerContext
    ) as FormulaManager
    const { teamId, appId } = useContext(AppMetadataContext)

    const handleOpenURL = useCallback(
        async (action: OpenURLAction) => {
            const url = (await TransformBinding(
                action.url,
                formulaManager,
                itemData
            )) as string

            const supported = await Linking.canOpenURL(url)
            if (supported) {
                await Linking.openURL(url)
            } else {
                Alert.alert(`Can not open this URL: ${url}`)
            }
        },
        [formulaManager, itemData]
    )

    const openPage = useCallback(
        (action: OpenPageAction) => {
            if (designPageStore && action.pageId) {
                designPageStore.pageSelection = new NormalPageSelection(
                    action.pageId
                )
            }
            switch (store.pageStore(action.pageId)?.pageType) {
                case 'form':
                    navigation.navigate(
                        screenNames.FORM_PAGE as never,
                        { pageId: action.pageId, showBackButton: true } as never
                    )
                    break
                case 'collection':
                    navigation.navigate(
                        screenNames.COLLECTION_PAGE as never,
                        { pageId: action.pageId, showBackButton: true } as never
                    )
                    break
                case 'custom':
                    navigation.navigate(
                        screenNames.CUSTOM_SCREEN as never,
                        { pageId: action.pageId, showBackButton: true } as never
                    )
                    break
                case 'details':
                    navigation.navigate(
                        screenNames.DETAIL_SCREEN as never,
                        { pageId: action.pageId, showBackButton: true } as never
                    )
                    break
                default:
                    break
            }
        },
        [designPageStore, navigation, store]
    )

    const copyToClipboard = useCallback(
        async (action: CopyToClipboardAction) => {
            const content = (await TransformBinding(
                action?.content,
                formulaManager,
                itemData
            )) as string
            await Clipboard.setStringAsync(content)
        },
        [formulaManager, itemData]
    )

    const createRow = useCallback(
        async (action: CreateRowAction) => {
            const dataToSend: Record<string, unknown> = {}
            for (const [columnName, binding] of Object.entries(
                action?.values ?? {}
            )) {
                dataToSend[columnName] = await TransformBinding(
                    binding,
                    formulaManager,
                    itemData
                )
            }

            const firestore = await getFireStore()
            await firestore
                .collection('ProductsSortFilterSaved')
                .doc(nanoid())
                .set(dataToSend)
        },
        [formulaManager, itemData]
    )

    const updateRows = useCallback(
        async (action: UpdateRowsAction) => {
            const queryStrings: string[] = []
            for (const [columnName, binding] of Object.entries(
                action?.conditions ?? {}
            )) {
                const conditionBindingValue = await TransformBinding(
                    binding,
                    formulaManager,
                    itemData
                )

                if (conditionBindingValue) {
                    const queryString = TransformConditionBindingToSql(
                        columnName,
                        conditionBindingValue,
                        binding.valueTypeName
                    )

                    queryStrings.push(queryString)
                }
            }

            const compoundedQueryString = queryStrings.join(' AND ')

            const items = await GetItemsByQueryString(
                action?.targetTableId,
                compoundedQueryString
            )

            await Promise.all(
                items.map(async (item) =>
                    updateToFirestore(
                        action,
                        formulaManager,
                        item,
                        teamId,
                        itemData
                    )
                )
            )
        },
        [formulaManager, itemData, teamId]
    )

    const modifyControl = useCallback(
        async (action: ModifyControlAction) => {
            const modifyComponentStore =
                arnDetailPageStore?.childComponentStore(action?.controlId)

            for (const [propName, binding] of Object.entries(
                action?.values ?? {}
            )) {
                const bindingValue = await TransformBinding(
                    binding,
                    formulaManager,
                    itemData
                )

                modifyComponentStore?.setData({
                    ...modifyComponentStore?.data,
                    [propName]: bindingValue,
                })
            }
        },
        [arnDetailPageStore, formulaManager, itemData]
    )

    const notifyPeople = useCallback(
        async (action: NotifyPeopleAction) => {
            try {
                const [message, people] = await Promise.all([
                    TransformBinding(action.message, formulaManager, itemData),
                    TransformBinding(action.people, formulaManager, itemData),
                ])

                const data: IDataPush = {
                    title: 'TITLE',
                    body: message?.toString() || '',
                    recipients: (people as string[])?.join(','),
                }

                const response = await NotificationApi.push(data, {
                    teamId,
                    appId,
                })

                console.info('response------', response)
            } catch (error) {
                console.info('error------', error)
            }
        },
        [appId, formulaManager, itemData, teamId]
    )

    const onAfterSuccess = useCallback(
        async (action: Action) => {
            if (action?.showsSuccessNotification) {
                const successMessage = await TransformBinding(
                    action.successMessage,
                    formulaManager
                )
                Alert.alert('Notification', successMessage as string)
            }
        },
        [formulaManager]
    )

    const switchAction = useCallback(
        async (action: Action) => {
            try {
                switch (action?.actionType) {
                    case 'openPage': {
                        openPage(action as OpenPageAction)
                        break
                    }
                    case 'openURL': {
                        await handleOpenURL(action as OpenURLAction)
                        break
                    }
                    case 'copyToClipboard': {
                        copyToClipboard(action as CopyToClipboardAction)
                        break
                    }
                    case 'createRow': {
                        createRow(action as CreateRowAction)
                        break
                    }
                    case 'updateRows': {
                        updateRows(action as UpdateRowsAction)
                        break
                    }
                    case 'modifyControl':
                        modifyControl(action as ModifyControlAction)
                        break
                    case 'notifyPeople':
                        notifyPeople(action as NotifyPeopleAction)
                        break
                    default:
                        break
                }
                onAfterSuccess(action)
            } catch (error) {
                console.error(error)
            }
        },
        [
            copyToClipboard,
            createRow,
            handleOpenURL,
            modifyControl,
            notifyPeople,
            onAfterSuccess,
            openPage,
            updateRows,
        ]
    )

    const onPressItemAction = useCallback(
        async (action: Action) => {
            if (action?.confirmationRequired) {
                const confirmationMessage = await TransformBinding(
                    action.confirmationMessage,
                    formulaManager
                )
                const confirmationActionTitle = await TransformBinding(
                    action.confirmationActionTitle,
                    formulaManager
                )
                Alert.alert('Notification', confirmationMessage as string, [
                    {
                        text: 'Cancel',
                        onPress: () => {
                            //
                        },
                        style: 'cancel',
                    },
                    {
                        text: confirmationActionTitle as string,
                        onPress: () => switchAction(action),
                    },
                ])
            } else {
                switchAction(action)
            }
        },
        [formulaManager, switchAction]
    )

    return onPressItemAction
}

async function updateToFirestore(
    action: UpdateRowsAction,
    formulaManager: FormulaManager,
    itemToUpdate: Record<string, unknown>,
    teamId: string,
    itemData?: Record<string, unknown>
): Promise<void> {
    try {
        const dataToSend: Record<string, unknown> = {}
        for (const [columnName, binding] of Object.entries(
            action?.values ?? {}
        )) {
            dataToSend[columnName] = await TransformBinding(
                binding,
                formulaManager,
                itemData
            )
        }

        const firestore = await getFireStore()
        await firestore
            .collection(`tableData/${teamId}/${action?.targetTableId}`)
            .doc(itemToUpdate[appFormulaId] as string)
            .update(dataToSend)
    } catch (error) {
        Promise.reject()
    }
}
