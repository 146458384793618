//
//  Repeater.tsx
//
//  Created by thaitd96 on 2022-20-04 11:47.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import {
    AudioStore,
    FilePreviewStore,
    RepeaterStore,
    VideoStore,
    WebLinkStore,
} from '@appformula/app-studio-core'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import appConstants from '../../../../constants/const'
import { useDatabaseAction } from '../../../../context/AppDatabaseContext'
import { Theme, useTheme } from '../../../../styles/theme'
import { useBindingCommon } from '../../../collection/components/hooks/useBindingCommon'
import { Audio } from '../Audio'
import { FilePreview } from '../FilePreview'
import Video from '../Video'
import { WebLink } from '../WebLink'

export const Repeater = observer(
    (props: { repeater: RepeaterStore; item: Record<string, unknown> }) => {
        const { repeater, item } = props
        const [data, setData] = useState<Array<Record<string, unknown>>>([])
        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])
        const isHidden = useBindingCommon(repeater.isHidden, item)
        const repeaterData = repeater.object
        const element = repeaterData.element
        const direction = repeaterData.direction
        const tableName = repeaterData.dataSource.id
        const { getAll } = useDatabaseAction()

        const getAndSetData = useCallback(async () => {
            try {
                const result = await getAll(tableName)
                setData(result)
            } catch (error) {
                //
            }
        }, [getAll, tableName])

        useEffect(() => {
            getAndSetData()
        }, [getAndSetData])

        const renderItem = (item: Record<string, unknown>) => {
            if (element.componentType === 'webLink') {
                return (
                    <WebLink
                        webLink={element as WebLinkStore}
                        item={item}
                        isViewHorizontal={direction === 'horizontal'}
                    />
                )
            } else if (element.componentType === 'video') {
                return <Video video={element as VideoStore} item={item} />
            } else if (element.componentType === 'audio') {
                return (
                    <Audio
                        audio={element as AudioStore}
                        item={item}
                        isViewHorizontal={direction === 'horizontal'}
                    />
                )
            } else if (element.componentType === 'filePreview') {
                return (
                    <FilePreview
                        filePreview={element as FilePreviewStore}
                        item={item}
                        isViewHorizontal={direction === 'horizontal'}
                    />
                )
            }
            return null
        }

        return (
            !isHidden && (
                <ScrollView horizontal={direction === 'horizontal'}>
                    {data.map((item: Record<string, unknown>, index) => {
                        return (
                            <View
                                key={`${index}`}
                                style={
                                    direction === 'horizontal' &&
                                    styles.horizontalViewItem
                                }
                            >
                                {renderItem(item)}
                            </View>
                        )
                    })}
                </ScrollView>
            )
        )
    }
)

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        viewRepeater: {},

        horizontalView: {
            flexDirection: 'row',
        },

        horizontalViewItem: {
            marginRight: appConstants.PADDING_COMMON,
        },
    })
