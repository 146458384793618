import appConstants from "../../../../../constants/const";
import { StyleSheet } from "react-native";
import { Theme } from "../../../../../styles/theme";

export const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        wrapTxtTitleAddr: { flex: 1 },
        wrapTitleAddrSelected: { flexDirection: 'row', alignItems: 'center', flex: 1 },
        imgClose: { width: 24, height: 24 },
        imgPin3: { width: 34, height: 34, marginRight: 6 },
        viewAddressSelected: {
            flex: 1,
            position: 'absolute',
            alignSelf: 'center',
            width: appConstants.WIDTH_SCREEN - 32,
            backgroundColor: colorScheme.backgroundL1,
            bottom: 100,
            borderRadius: roundness,
            paddingVertical: 16,
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingHorizontal: 10,
        },
        wrapMapLocation: { flex: 1 },
        inputSeach: {
            flex: 1,
            fontWeight: '500',
            fontSize: 16,
            color: colorScheme.labelPrimary,
            paddingVertical: 18,
            
        },
        wrapSeach: { flex: 1, flexDirection: 'row', alignItems: 'center' },
        header: {
            flexDirection: 'row',
            paddingHorizontal: 12,
            alignItems: 'center',
            borderBottomColor: colorScheme.separator,
            borderBottomWidth: 1,
            backgroundColor: colorScheme.backgroundL1 
        },
        container: { flex: 1 },
        txtDone: {
            color: colorScheme.accentColor,
            fontWeight: '500',
            fontSize: 17,
        },
        imgSearch: {
            height: 24,
            width: 24,
            marginRight: 4,
        },
        map: {
            flex: 1,
        },
        txtMain: {
            fontSize: 15,
            fontWeight: '500',
            lineHeight: 18,
            marginBottom: 4,
            color: colorScheme.labelPrimary,
        },
        txtDescription: {
            fontSize: 13,
            fontWeight: '400',
            lineHeight: 18.2,
            color: colorScheme.labelSecondary,
        },
    })