import { observer } from 'mobx-react-lite'
import { WelcomePageStore } from './WelcomePageStore'
import { Theme, useTheme } from '../../styles/theme'
import React, { useMemo } from 'react'
import {
    Image,
    ImageSourcePropType,
    Keyboard,
    Platform,
    SafeAreaView,
    ScrollView,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
    ViewProps,
} from 'react-native'
import image from '../../assets/icons'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import appConstants from '../../constants/const'

type AuthenticationLayoutProps = {
    store?: WelcomePageStore
    title?: string
    subTitle?: string
    icon?: ImageSourcePropType
    loading?: boolean
    isHideLeftHeader?: boolean
    isHideRightHeader?: boolean
    headerLeftTitle?: string
    onHeaderLeft?: () => void
    headerRightTitle?: string
    onHeaderRight?: () => void
    bottom?: React.ReactNode
}

const AuthenticationLayout: React.FC<AuthenticationLayoutProps & ViewProps> =
    observer((props: AuthenticationLayoutProps & ViewProps) => {
        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])
        const {
            children,
            title,
            subTitle,
            icon,
            headerLeftTitle,
            onHeaderLeft,
            headerRightTitle,
            onHeaderRight,
            isHideLeftHeader,
            isHideRightHeader,
        } = props

        return (
            <SafeAreaView style={styles.container}>
                <ScrollView
                    style={styles.containerScroll}
                    onScroll={() => {
                        Platform.OS !== 'android' && Keyboard.dismiss()
                    }}
                    showsVerticalScrollIndicator={false}
                >
                    <View style={styles.headerContainer}>
                        {!isHideLeftHeader && (
                            <TouchableOpacity
                                style={styles.containerHeaderLeft}
                                onPress={onHeaderLeft}
                            >
                                <Image
                                    style={styles.backImg}
                                    source={image.back}
                                    resizeMode={'contain'}
                                />
                                {!!headerLeftTitle && (
                                    <Text style={styles.txtHeaderLeft}>
                                        {headerLeftTitle}
                                    </Text>
                                )}
                            </TouchableOpacity>
                        )}
                        {!isHideRightHeader && (
                            <TouchableOpacity
                                style={styles.containerHeaderRight}
                                onPress={onHeaderRight}
                            >
                                {!!headerRightTitle && (
                                    <Text style={styles.txtHeaderRight}>
                                        {headerRightTitle}
                                    </Text>
                                )}
                            </TouchableOpacity>
                        )}
                    </View>
                    <KeyboardAwareScrollView
                        contentContainerStyle={styles.contentContainer}
                    >
                        {icon && (
                            <Image
                                style={styles.icon}
                                source={icon}
                                resizeMode={'center'}
                            />
                        )}
                        {!!title && (
                            <Text style={styles.txtTitle}>{title}</Text>
                        )}
                        {!!subTitle && (
                            <Text style={styles.txtSubTitle}>{subTitle}</Text>
                        )}
                        {children && children}
                    </KeyboardAwareScrollView>
                </ScrollView>
            </SafeAreaView>
        )
    })

export default AuthenticationLayout

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        backImg: {
            width: 28,
            height: 28,
            tintColor: colorScheme.accentColor,
            marginLeft: 8,
        },
        icon: {
            width: 100,
            height: 100,
        },
        containerHeaderLeft: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            flex: 1,
        },
        containerHeaderRight: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            flex: 1,
        },
        txtHeaderLeft: {
            color: colorScheme.accentColor,
            fontWeight: '500',
            fontSize: 17,
            lineHeight: 21,
        },
        txtHeaderRight: {
            textAlign: 'right',
            marginHorizontal: appConstants.PADDING_COMMON,
            color: colorScheme.labelSecondary,
        },
        container: {
            flex: 1,
            backgroundColor: colorScheme.backgroundL1,
        },
        containerScroll: {
            flex: 1,
        },
        headerContainer: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 20,
            flexGrow: 0,
        },
        contentContainer: {
            paddingHorizontal: 24,
            paddingTop: 45,
            alignItems: 'center',
            justifyContent: 'center',
            // height: appConstants.HEIGHT_SCREEN - 200,
        },
        txtTitle: {
            fontWeight: '700',
            fontSize: 30,
            lineHeight: 36.31,
            color: colorScheme.labelPrimary,
            marginTop: 9,
            textAlign: 'center',
        },
        txtSubTitle: {
            fontWeight: '400',
            fontSize: 15,
            lineHeight: 22.5,
            color: colorScheme.labelSecondary,
            textAlign: 'center',
            marginTop: 9,
            marginBottom: 20,
        },
    })
