//
//  PeopleLookupFormInput.tsx
//
//  Created by thaitd96 on 2022-07-06 11:28.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { PeopleLookupFormInputStore } from '@appformula/app-studio-core'
import appConstants from '../../../../constants/const'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useMemo, useState } from 'react'
import {
    Image,
    LayoutChangeEvent,
    Pressable,
    StyleSheet,
    Text,
    View,
} from 'react-native'
import image from '../../../../assets/icons'
import { Theme, useTheme } from '../../../../styles/theme'
import { ARNFormPageStore } from '../../ARNFormPageStore'
import { useNavigation } from '@react-navigation/native'
import screenNames from '../../../../navigations/screenNames'
import AvatarByName from '../../../../components/avatar-by-name/AvatarByName'
import { ARNPeopleLookupFormStore } from './ARNPeopleLookupFormStore'
import { useBinding } from '../../../collection/components/hooks/useBinding'
import FastImage from '../../../../components/fast-image/FastImage'

export type People = {
    email: string
    selected: boolean
    name?: string
    avatarImage?: string
}

export const defaultPeoples = [
    {
        name: 'David Patterson',
        avatarImage:
            'https://i.picsum.photos/id/991/200/200.jpg?hmac=zdrg88cOolAGaFqNdqLZG2ECaXB0AjpYxU8PNyq3dBw',
        email: 'david_patterson@apple1.com',
        selected: true,
    },
    {
        name: 'David Patterson',
        email: 'david_patterson@apple2.com',
        selected: false,
    },
    {
        email: 'david_patterson@apple3.com',
        selected: false,
    },
    {
        avatarImage:
            'https://i.picsum.photos/id/991/200/200.jpg?hmac=zdrg88cOolAGaFqNdqLZG2ECaXB0AjpYxU8PNyq3dBw',
        email: 'david_patterson@apple4.com',
        selected: true,
    },
    {
        avatarImage:
            'https://i.picsum.photos/id/991/200/200.jpg?hmac=zdrg88cOolAGaFqNdqLZG2ECaXB0AjpYxU8PNyq3dBw',
        email: 'david_patterson@apple5.com',
        selected: true,
    },
    {
        avatarImage:
            'https://i.picsum.photos/id/991/200/200.jpg?hmac=zdrg88cOolAGaFqNdqLZG2ECaXB0AjpYxU8PNyq3dBw',
        email: 'david_patterson@apple6.com',
        selected: true,
    },
    {
        avatarImage:
            'https://i.picsum.photos/id/991/200/200.jpg?hmac=zdrg88cOolAGaFqNdqLZG2ECaXB0AjpYxU8PNyq3dBw',
        email: 'david_patterson@apple7.com',
        selected: true,
    },
    {
        avatarImage:
            'https://i.picsum.photos/id/991/200/200.jpg?hmac=zdrg88cOolAGaFqNdqLZG2ECaXB0AjpYxU8PNyq3dBw',
        email: 'david_patterson@apple8.com',
        selected: true,
    },
]

const PeopleLookupFormInput = observer(
    (props: {
        peopleLookupFormInput: PeopleLookupFormInputStore
        arnPeopleLookupFormStore: ARNPeopleLookupFormStore
        arnFormPageStore: ARNFormPageStore
    }) => {
        const { peopleLookupFormInput, arnPeopleLookupFormStore } = props
        const navigation = useNavigation()
        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])
        const [numberMore, setNumberMore] = useState(0)
        const [numberShow, setNumberShow] = useState(0)
        const [maxOfAvatar, setMaxOfAvatar] = useState(0)
        const isHidden = useBinding(peopleLookupFormInput?.isHidden)

        const goToPageOptions = () => {
            navigation &&
                navigation.navigate(
                    screenNames.PEOPLE_LOOKUP as never,
                    { componentId: peopleLookupFormInput.componentId } as never
                )
        }

        const onLayout = (event: LayoutChangeEvent) => {
            setMaxOfAvatar(Math.round(event.nativeEvent.layout.width / 41))
        }

        useEffect(() => {
            if (arnPeopleLookupFormStore.selectedPeoples.length > maxOfAvatar) {
                setNumberMore(
                    arnPeopleLookupFormStore.selectedPeoples.length -
                        maxOfAvatar +
                        1
                )
                setNumberShow(maxOfAvatar - 1)
            } else {
                setNumberMore(0)
                setNumberShow(maxOfAvatar)
            }
        }, [arnPeopleLookupFormStore.selectedPeoples.length, maxOfAvatar])

        return isHidden ? null : (
            <Pressable style={styles.wrap} onPress={goToPageOptions}>
                <View style={styles.viewTitle}>
                    <Text style={styles.dateTimeTitle} numberOfLines={2}>
                        {peopleLookupFormInput.titleName}
                    </Text>
                    {peopleLookupFormInput.required && (
                        <View style={styles.requireDot}></View>
                    )}
                </View>
                <View style={styles.viewSelected}>
                    <View style={styles.wrapListAvatar} onLayout={onLayout}>
                        {arnPeopleLookupFormStore.selectedPeoples
                            .slice(0, numberShow)
                            .map((people, index) => (
                                <View
                                    key={people.email}
                                    style={styles.wrapAvatar}
                                >
                                    {people.avatarImage ? (
                                        <FastImage
                                            source={{ uri: people.avatarImage }}
                                            style={styles.avatar}
                                        />
                                    ) : (
                                        <AvatarByName
                                            name={people.name || people.email}
                                            size={37}
                                        />
                                    )}
                                </View>
                            ))}
                        {!!numberMore && (
                            <AvatarByName
                                name={`+${numberMore}`}
                                size={37}
                                backgroundColor={
                                    theme.colorScheme
                                        .backgroundSidebar as string
                                }
                                textColor={
                                    theme.colorScheme.labelSecondary as string
                                }
                                nonChangeName={true}
                            />
                        )}
                    </View>
                    <Image
                        source={image.arrowRight}
                        style={styles.image}
                    ></Image>
                </View>
            </Pressable>
        )
    }
)

export default PeopleLookupFormInput

const createStyles = ({ colorScheme }: Theme) =>
    StyleSheet.create({
        wrapAvatar: { marginHorizontal: 2 },
        wrapListAvatar: {
            flexDirection: 'row',
            flex: 1,
            justifyContent: 'flex-end',
            marginRight: 4,
        },
        selected: {
            fontSize: 15,
            color: colorScheme.labelSecondary,
            fontWeight: '500',
        },
        image: {
            height: 20,
            width: 20,
            resizeMode: 'contain',
            tintColor: colorScheme.labelSecondary,
        },
        viewSelected: {
            flexDirection: 'row',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'flex-end',
            paddingLeft: 30,
        },
        wrap: {
            paddingLeft: appConstants.PADDING_COMMON,
            paddingRight: 8,
            height: 68,
            borderBottomWidth: 1,
            borderBottomColor: colorScheme.separator,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        viewTitle: { flexDirection: 'row', alignItems: 'center' },
        requireDot: {
            height: 6,
            width: 6,
            backgroundColor: 'red',
            borderRadius: 6,
            marginLeft: 6,
        },
        viewDateTimeFormInput: {
            flex: 1,
            paddingVertical: 15,
            paddingHorizontal: appConstants.PADDING_COMMON,
            borderBottomColor: colorScheme.separator,
            borderBottomWidth: 1,
        },
        dateTimeContent: {
            color: colorScheme.accentColor,
            fontWeight: '500',
            fontSize: 15,
            lineHeight: 18,
        },
        dateTimeTitle: {
            fontWeight: '500',
            fontSize: 16,
            lineHeight: 19,
            color: colorScheme.labelPrimary,
        },
        avatar: {
            height: 40,
            width: 40,
            resizeMode: 'contain',
            borderRadius: 20,
        },
    })
