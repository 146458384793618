//
//  BarcodeScanner.ts
//
//  Created by thaitd96 on 2022-08-10 14:24
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { BarcodeScannerFormInput } from '@appformula/app-descriptor'
import { BarcodeScannerFormInputRepository } from '@appformula/app-studio-core'
import { LocalFormInputRepository } from '../FormInputRepository'

export class LocalBarcodeScannerFormInputRepository
    extends LocalFormInputRepository<BarcodeScannerFormInput>
    implements BarcodeScannerFormInputRepository
{
    addExcludeDetectedCodeType(
        codeType:
            | 'qr'
            | 'code11'
            | 'code39'
            | 'code93'
            | 'code128'
            | 'ean-13'
            | 'ean-8'
            | 'jan'
            | 'upc-e'
            | 'upc-a'
    ): Promise<void> {
        throw new Error('Method not implemented.')
    }
    removeExcludeDetectedCodeType(
        codeType:
            | 'qr'
            | 'code11'
            | 'code39'
            | 'code93'
            | 'code128'
            | 'ean-13'
            | 'ean-8'
            | 'jan'
            | 'upc-e'
            | 'upc-a'
    ): Promise<void> {
        throw new Error('Method not implemented.')
    }
}
