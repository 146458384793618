import {
    combineLatest,
    map,
    skipWhile,
    take,
    switchMap,
    from,
    Subscription,
} from 'rxjs'
import {
    getData,
    getItemsWithPrefix,
    removeItem,
    storeData,
} from '../../persistent-storage/AsyncStorage'
import { CompoundedOperation } from '../operation/CompoundedOperation'
import {
    Operation,
    OperationRawData,
    OperationType,
} from '../operation/Operation'
import { CompoundedOperationBuilder } from '../operation/operation-builder/CompoundedOperationBuilder'
import { OperationBuilder } from '../operation/operation-builder/OperationBuilder'
import { SaveDataToStorageOperationBuilder } from '../operation/operation-builder/SaveDataToStorageOperationBuilder'
import { UploadOperationBuilder } from '../operation/operation-builder/UploadOperationBuilder'
import { SaveDataToStorageOperation } from '../operation/SaveDataToStorageOperation'
import { UploadOperation } from '../operation/UploadOperation'
import { OperationQueue } from './OperationQueue'

export const MAX_RETRIES = 19
const PREFIX_EXECUTION_COUNT_NOTE = 'PREFIX_EXECUTION_COUNT_NOTE'
const PREFIX_QUEUE = 'PREFIX_QUEUE'

export class CommonOperationQueue implements OperationQueue {
    private unsubscribeMap: Record<string, Subscription> = {}

    constructor() {
        this.handleOldOperations()
        this.register(new UploadOperationBuilder(), 'uploadFile')
        this.register(new CompoundedOperationBuilder(), 'compounded')
        this.register(
            new SaveDataToStorageOperationBuilder(),
            'saveDataToStorage'
        )
    }

    private async handleOldOperations(): Promise<void> {
        try {
            const operations = await this.allOperations()
            operations.map((operation) => this.addOperation(operation))
        } catch (error) {
            //
        }
    }

    async addOperation(operation: Operation): Promise<void> {
        try {
            this.saveOperationPersistent(operation)
            const executionCountNote = await this.getExecutionCountNote(
                operation.id
            )
            let executionCount = executionCountNote?.executionCount ?? 0

            const unsubscribe = combineLatest(
                operation.constraints.map((constraint) => constraint.isSatisfy)
            )
                .pipe(
                    map((satisfies) => {
                        return !satisfies.includes(false)
                    }),
                    skipWhile((isAllSatisfy) => !isAllSatisfy),
                    take(1),
                    switchMap((e) => {
                        console.info('operation.run()')
                        return from(operation.run())
                    })
                )
                .subscribe({
                    next: (value) => {
                        console.info('success operation run', value)
                        this.unsubscribeMap[operation.id]?.unsubscribe()
                        this.removeOperationPersistent(operation)
                        this.removeExecutionNote(operation.id)
                    },
                    error: (err) => {
                        console.info('err operation run', err)
                        this.unsubscribeMap[operation.id]?.unsubscribe()

                        if (executionCount < 19) {
                            executionCount += 1
                            this.saveExecutionCountNote({
                                operationId: operation.id,
                                executionCount,
                            })
                        }
                        const waitingTimeBackOff =
                            Math.pow(2, executionCount) * 100
                        setTimeout(() => {
                            this.addOperation(operation)
                        }, waitingTimeBackOff)
                    },
                })

            this.unsubscribeMap[operation.id] = unsubscribe
        } catch (error) {
            //
        }
    }

    async saveExecutionCountNote(
        executionCountNote: ExecutionCountNote
    ): Promise<void> {
        return storeData(
            `${PREFIX_EXECUTION_COUNT_NOTE}${executionCountNote.operationId}`,
            executionCountNote as unknown as Record<string, unknown>
        )
    }

    async getExecutionCountNote(
        operationId: string
    ): Promise<ExecutionCountNote> {
        return getData(`${PREFIX_EXECUTION_COUNT_NOTE}${operationId}`)
    }

    async removeExecutionNote(operationId: string): Promise<void> {
        return removeItem(`${PREFIX_EXECUTION_COUNT_NOTE}${operationId}`)
    }

    async saveOperationPersistent(operation: Operation): Promise<void> {
        const operationType = this.operationTypeFromOperation(operation)
        return storeData(
            `${PREFIX_QUEUE}${operationType}${operation.id}`,
            operation.toRawData() as unknown as Record<string, unknown>
        )
    }

    async allOperations(): Promise<Operation[]> {
        try {
            const listOperationRawData: OperationRawData[] =
                await getItemsWithPrefix(PREFIX_QUEUE)

            return listOperationRawData.map((operationRawData) => {
                return this.builderMap[
                    operationRawData.type
                ].operationFromRawData(operationRawData)
            })
        } catch (error) {
            throw new Error('allOperations')
        }
    }

    async removeOperationPersistent(operation: Operation): Promise<void> {
        const operationType = this.operationTypeFromOperation(operation)
        return removeItem(`${PREFIX_QUEUE}${operationType}${operation.id}`)
    }

    operationTypeFromOperation(
        operation: Operation
    ): OperationType | undefined {
        if (operation instanceof UploadOperation) {
            return 'uploadFile'
        } else if (operation instanceof SaveDataToStorageOperation) {
            return 'saveDataToStorage'
        } else if (operation instanceof CompoundedOperation) {
            return 'compounded'
        }
        return undefined
    }

    private builderMap: Record<string, OperationBuilder> = {}

    register(
        operationBuilder: OperationBuilder,
        operationType: OperationType
    ): void {
        this.builderMap[operationType] = operationBuilder
    }
}

export const commonOperationQueue = new CommonOperationQueue()

export interface ExecutionCountNote {
    operationId: string
    executionCount: number
}
