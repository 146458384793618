import { useCallback, useMemo } from 'react'
import React, {
    Image,
    ImageSourcePropType,
    ImageStyle,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
} from 'react-native'
import { COLORS } from '../../assets/colors'
import { iconDataTypes } from '../../assets/icons/icon-data-types'
import { Theme, useTheme } from '../../styles/theme'
import { ActionType } from './ContextMenuCustom.web'

type MenuOptionProps = {
    actions: ActionType[]
    onSelect: (value: number) => void
    iconStyle?: ImageStyle
}

const getIconFromType = (item: string): ImageSourcePropType => {
    return iconDataTypes.string
}

const MenuOptionItems = (props: MenuOptionProps) => {
    const theme = useTheme()
    const styles = useMemo(() => createStyles(theme), [theme])
    const { onSelect, actions, iconStyle } = props

    const onPress = useCallback(
        (item: ActionType) => {
            onSelect(item.key)
        },
        [onSelect]
    )

    return (
        <View style={styles.viewMenus}>
            {actions.map((item) => (
                <TouchableOpacity
                    key={item.key}
                    style={[
                        styles.viewMenu,
                        {
                            borderBottomWidth:
                                item.key === actions.length - 1 ? 0 : 0.5,
                        },
                    ]}
                    onPress={() => onPress(item)}
                >
                    <Text style={styles.textMenu}>{item.title}</Text>
                    {item.systemIcon && (
                        <Image
                            source={getIconFromType(item.systemIcon)}
                            style={[styles.iconStyle, iconStyle]}
                        />
                    )}
                </TouchableOpacity>
            ))}
        </View>
    )
}

export default MenuOptionItems

const createStyles = ({ colorScheme }: Theme) => {
    return StyleSheet.create({
        iconStyle: {
            height: 18,
            width: 18,
            resizeMode: 'contain',
            tintColor: COLORS.BLACK,
        },
        textMenu: { fontSize: 17, fontWeight: '400', lineHeight: 22 },
        viewMenus: { backgroundColor: COLORS.BG_MENU_FILTER, borderRadius: 5 },
        viewMenu: {
            width: 254,
            height: 44,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingHorizontal: 15,
            borderColor: COLORS.SEPARATOR_MENU_FILTER,
        },
    })
}
