import { isEmpty } from 'lodash'
import CustomPageApi from '../network/extensions/CustomPageApi'
import { getData, storeData } from '../persistent-storage/AsyncStorage'

interface Metadata {
    description: string
    image: string
    title: string
    url: string
}

class WebLinkHelper {
    async callApiInfo(url: string): Promise<Metadata> {
        try {
            if (url) {
                const result = await CustomPageApi.getWeblinkInfo({
                    url: url as string,
                })
                const metadata: Metadata = result.data?.metadata
                return Promise.resolve(metadata)
            } else {
                return Promise.reject(null)
            }
        } catch (error) {
            return Promise.reject(null)
        }
    }

    async getInfo(url: string): Promise<Metadata> {
        try {
            const info = await getData(url)
            console.info("info=====", info);
            
            if (isEmpty(info)) {
                const result = await this.callApiInfo(url)
                storeData(url, result as unknown as Record<string, unknown>)
                return Promise.resolve(result)
            }
            return Promise.resolve(info)
        } catch (error) {
            return Promise.reject(null)
        }
    }
}

const webLinkHelper = new WebLinkHelper()

export default webLinkHelper
