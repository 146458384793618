//
//  CollectionScreen.tsx
//
//  Created by thaitd96 on 2022-18-04 17:20.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React, { useCallback, useContext, useEffect, useState } from 'react'
import { StyleSheet, View, Pressable, Image, Platform } from 'react-native'
import { observer } from 'mobx-react-lite'
import {
    CollectionPageStore,
    DefaultPageSelection,
    DesignPageStore,
} from '@appformula/app-studio-core'
import { Theme, useTheme } from '../../../../styles/theme'
import image from '../../../../assets/icons'
import { useNavigation } from '@react-navigation/native'
import screenNames from '../../../../navigations/screenNames'
import { FormulaManagerContext } from '../../../../context/FormulaManagerContext'
import { FormulaManager } from '../../../../utils/integrate-formula/FormulaManager'
import { COLORS } from '../../../../assets/colors'
import { ARNCollectionPageStore } from '../../ARNCollectionPageStore'
import { Layout } from '../layouts/Layout'
import { Loading } from '../../../../components'
import { Nullable } from '@appformula/shared-foundation-x/src'
import appConstants from '../../../../constants/const'

export const CollectionScreen = observer(
    (props: {
        pageStore: CollectionPageStore
        arnCollectionPageStore: ARNCollectionPageStore
        designPageStore: DesignPageStore
    }) => {
        const formulaManager: Nullable<FormulaManager> = useContext(
            FormulaManagerContext
        )
        const [isRegistContext, setIsRegistContext] = useState<boolean>(false)
        const [isStartedListen, setIsStartedListen] = useState<boolean>(false)

        const theme = useTheme()
        const styles = React.useMemo(() => createStyles(theme), [theme])

        const navigation = useNavigation()

        useEffect(() => {
            isStartedListen &&
                props.arnCollectionPageStore.collectionScreenDidMount.next()
        }, [
            isStartedListen,
            props.arnCollectionPageStore.collectionScreenDidMount,
        ])

        useEffect(() => {
            props.arnCollectionPageStore.setPageStore(props.pageStore)
        }, [
            props.arnCollectionPageStore,
            props.pageStore,
            props.pageStore.object,
        ])

        useEffect(() => {
            props.arnCollectionPageStore.pageStoreChange.next()
        }, [props.arnCollectionPageStore, props.pageStore.object])

        useEffect(() => {
            const saveToken =
                props.arnCollectionPageStore.getAndSetDataEvent.subscribe({
                    next: () => {
                        props.arnCollectionPageStore.getAndSetData(
                            formulaManager
                        )
                    },
                })
            setIsStartedListen(true)
            return () => {
                saveToken.unsubscribe()
            }
        }, [
            formulaManager,
            props.arnCollectionPageStore,
            props.arnCollectionPageStore.getAndSetDataEvent,
        ])

        const handleAdd = useCallback(() => {
            if (Platform.OS !== 'web') {
                const Haptics = require('expo-haptics')
                Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium)
            }

            const tableId = props.pageStore.dataSource?.id

            if (props.designPageStore && tableId) {
                props.designPageStore.pageSelection = new DefaultPageSelection(
                    tableId,
                    'form'
                )
            }

            navigation.navigate(
                screenNames.FORM_PAGE as never,
                { pageId: '', tableId } as never
            )
        }, [
            navigation,
            props?.designPageStore,
            props?.pageStore?.dataSource?.id,
        ])

        useEffect(() => {
            const sub = PubSub.subscribe(appConstants.OPEN_FORM_PAGE, handleAdd)

            return function clean() {
                PubSub.unsubscribe(sub)
            }
        }, [handleAdd])

        const goToTestFormula = useCallback(() => {
            navigation && navigation.navigate(screenNames.TEST_FORMULA as never)
        }, [navigation])

        const registContextVariableWithTableId = useCallback(async () => {
            if (formulaManager) {
                await formulaManager.registContextVariableWithTableId(
                    appConstants.CURRENT_ROW,
                    props?.pageStore?.dataSource?.id || ''
                )
                setIsRegistContext(true)
            }
        }, [formulaManager, props?.pageStore?.dataSource?.id])

        useEffect(() => {
            registContextVariableWithTableId()
        }, [registContextVariableWithTableId])

        if (!isRegistContext) {
            return <Loading />
        } else {
            return (
                <View style={styles.viewCollection}>
                    <Layout
                        pageStore={props.pageStore}
                        arnCollectionPageStore={props.arnCollectionPageStore}
                    />
                    {props.pageStore.activeLayoutType !== 'map' && (
                        <Pressable
                            onLongPress={goToTestFormula}
                            onPress={handleAdd}
                            style={styles.btnFloat}
                            hitSlop={10}
                        >
                            <Image source={image.add} style={styles.imgAdd} />
                        </Pressable>
                    )}
                </View>
            )
        }
    }
)

const createStyles = ({ colorScheme }: Theme) =>
    StyleSheet.create({
        viewCollection: { flex: 1, backgroundColor: colorScheme.backgroundL1 },
        btnFloat: {
            height: 46,
            width: 46,
            backgroundColor: colorScheme.accentColor,
            position: 'absolute',
            bottom: 16,
            right: 15.43,
            borderRadius: 23,
            justifyContent: 'center',
            alignItems: 'center',
        },
        imgAdd: { width: 31.92, height: 31.92, tintColor: COLORS.WHITE },
    })
