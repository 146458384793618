import React from 'react'
import { VideoStore } from '@appformula/app-studio-core/src'
import { useMemo } from 'react'
import { validateVimeoURL, validateYouTubeURL } from '../../../../utils'
import { observer } from 'mobx-react-lite'
import Youtube from './Youtube'
import HotLink from './HotLink'
import Vimeo from './Vimeo'
import { useBindingCommon } from '../../../collection/components/hooks/useBindingCommon'

const Video = observer(
    (props: {
        video: VideoStore
        item: Record<string, unknown>
    }) => {
        const { video, item } = props
        const videoData = video.object || video
        const isHidden = useBindingCommon(videoData.isHidden, item) || ''
        const videoURL = useBindingCommon(videoData?.videoURL, item) || ''
        const title = useBindingCommon(videoData?.title, item)
        const thumbnailURL = useBindingCommon(videoData?.thumbnailURL, item)

        const type = useMemo(() => {
            const url = (videoURL || '') as string
            if (validateVimeoURL(url)) {
                return 'vimeo'
            } else if (validateYouTubeURL(url)) {
                return 'yotube'
            } else {
                return 'hotlink'
            }
        }, [videoURL])

        if (isHidden) {
            return null
        }

        const VideoPlayer = () => {
            switch (type) {
                case 'yotube':
                    return (
                        <Youtube
                            {...props}
                            videoURL={videoURL as string}
                            title={title as string}
                            thumbnailURL={thumbnailURL as string}
                        />
                    )
                case 'vimeo':
                    return (
                        <Vimeo
                            {...props}
                            videoURL={videoURL as string}
                            title={title as string}
                            thumbnailURL={thumbnailURL as string}
                        />
                    )
                default:
                    return (
                        <HotLink
                            {...props}
                            videoURL={videoURL as string}
                            title={title as string}
                            thumbnailURL={thumbnailURL as string}
                        />
                    )
            }
        }

        return !!videoURL && <VideoPlayer />
    }
)

export default Video
