import { useMemo } from 'react'
import React, { ImageStyle, Platform, StyleSheet } from 'react-native'
import {
    Menu,
    MenuOptions,
    MenuTrigger,
    renderers,
} from 'react-native-popup-menu'
import { COLORS } from '../../assets/colors'
import { Theme, useTheme } from '../../styles/theme'
import ContextMenuOptions from './ContextMenuOptions'

const { Popover } = renderers

const ContextMenu = (props: {
    triggerComponent: JSX.Element
    actions: Array<Record<string, unknown>>
    getIcon: (item: Record<string, unknown>) => unknown
    getText: (item: Record<string, unknown>) => string
    onSelect: (item: Record<string, unknown>) => void
    iconStyle?: ImageStyle
}) => {
    const theme = useTheme()
    const styles = useMemo(() => createStyles(theme), [theme])
    const { onSelect, getIcon, getText, actions, iconStyle, triggerComponent } =
        props

    return (
        <Menu
            renderer={Popover}
            rendererProps={{
                anchorStyle: { backgroundColor: COLORS.TRANSPARENT },
            }}
        >
            <MenuTrigger>{triggerComponent}</MenuTrigger>
            <MenuOptions
                renderOptionsContainer={() => (
                    <ContextMenuOptions
                        actions={actions}
                        getIcon={getIcon}
                        getText={getText}
                        onSelect={onSelect}
                        iconStyle={iconStyle}
                    />
                )}
                customStyles={{
                    optionsContainer: styles.optionsContainer,
                }}
            />
        </Menu>
    )
}

export default ContextMenu

const createStyles = ({ colorScheme }: Theme) => {
    return StyleSheet.create({
        optionsContainer: {
            borderRadius: 12,
            backgroundColor:
                Platform.OS === 'web' ? COLORS.WHITE : COLORS.TRANSPARENT,
            // shadow -----------
            shadowColor: COLORS.BLACK,
            shadowOpacity: 0.1,
            shadowOffset: { width: 0, height: 0.5 }, // rem
            shadowRadius: 4, // rem
            // ------------------
        },
    })
}
