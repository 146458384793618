//
//  BaseRepository.ts
//
//  Created by thaitd96 on 2022-08-10 14:24
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { BasePage } from '@appformula/app-descriptor'
import { BasePageRepository } from '@appformula/app-studio-core'
import { Observable } from 'rxjs'
import { AnyObservableObjectRepository } from '../ObjectRepository'

export class LocalBasePageRepository<P extends BasePage>
    extends AnyObservableObjectRepository<P>
    implements BasePageRepository<P>
{
    get pageId(): string {
        return this.objectId
    }
    get pageSnapshot(): P {
        return this.objectSnapshot
    }
    get page(): Observable<P> {
        return this.object
    }
    set<K extends Exclude<keyof P, 'type' | 'layout' | 'components'>>(
        key: K,
        value: P[K]
    ): Promise<void> {
        throw new Error('Method not implemented.')
    }
}
