import { View, Text, Pressable, Image, StyleSheet } from 'react-native'
import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { DetailsPageStore } from '@appformula/app-studio-core/src'
import { ARNCustomDetailPageStore } from '../custom/ARNCustomDetailPageStore'
import { useBindingHaveItemData } from '../collection/components/hooks/useBindingHaveItemData'
import image from '../../assets/icons'
import { Theme, useTheme } from '../../styles/theme'
import { useNavigation } from '@react-navigation/native'
const DetailHeader = observer(
    (props: {
        pageStore: DetailsPageStore
        arnDetailPageStore: ARNCustomDetailPageStore
        item?: Record<string, unknown>
    }) => {
        const { pageStore, item } = props
        const theme = useTheme()
        const navigation = useNavigation()
        const styles = useMemo(() => createStyles(theme), [theme])
        const isHidden = useBindingHaveItemData(pageStore.isHidden, item)
        const title = useBindingHaveItemData(pageStore.title, item)

        const goBack = () => {
            navigation.goBack()
        }
        return !isHidden ? (
            <View style={styles.container}>
                <Pressable onPress={goBack}>
                    <Image source={image.back} style={styles.icBack} />
                </Pressable>
                <Text style={styles.txtTitle}>{title}</Text>
                <Pressable>
                    <Text style={styles.txtEdit}>Edit</Text>
                </Pressable>
            </View>
        ) : null
    }
)

export default DetailHeader

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        container: {
            backgroundColor: colorScheme.backgroundL1,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingHorizontal: 14,
            borderBottomColor: colorScheme.separator,
            borderBottomWidth: 1,
        },
        icBack: { width: 28, height: 28, tintColor: colorScheme.accentColor },
        txtEdit: {
            color: colorScheme.accentColor,
            fontWeight: '500',
            fontSize: 17,
            lineHeight: 20.57,
        },
        txtTitle: {
            color: colorScheme.labelPrimary,
            fontWeight: '500',
            fontSize: 17,
            lineHeight: 20.57,
            paddingVertical: 14,
        },
    })
