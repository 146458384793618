import { Pressable, View } from 'react-native'
import React from 'react'

import {
    Menu,
    MenuOptions,
    MenuOptionsProps,
    MenuProps,
    MenuTrigger,
    MenuTriggerProps,
    renderers,
} from 'react-native-popup-menu'
import { COLORS } from '../../../../../assets/colors'

const { Popover } = renderers

interface IContextMenuView {
    onSelectCommon?: (optionValue: any) => void
    MenuTriggerCustomize: React.ReactNode
    MenuOptionsCustomize: React.ReactNode
    menuProps?: MenuProps
    menuTriggerProps?: MenuTriggerProps
    menuOptionsProps?: MenuOptionsProps
}

const ContextMenuView = (props: IContextMenuView) => {
    return (
        <Menu
            renderer={Popover}
            onSelect={props.onSelectCommon}
            rendererProps={{
                placement: 'bottom',
                anchorStyle: { backgroundColor: COLORS.TRANSPARENT },
            }}
            {...props.menuProps}
        >
            <MenuTrigger
                customStyles={{
                    TriggerTouchableComponent: Pressable,
                }}
                {...props.menuTriggerProps}
            >
                {props.MenuTriggerCustomize}
            </MenuTrigger>
            <MenuOptions {...props.menuOptionsProps}>
                {props.MenuOptionsCustomize}
            </MenuOptions>
        </Menu>
    )
}

export default ContextMenuView
