//
//  form/ComponentSwitcher.tsx
//
//  Created by thaitd96 on 2022-06-21 10:12.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React, { useContext } from 'react'
import {
    BooleanFormInputStore,
    FilesFormInputStore,
    FormInputStore,
    MultiLineTextFormInputStore,
    PhotosFormInputStore,
    OptionsFormInputStore,
    SingleLineTextFormInputStore,
    DateTimeFormInputStore,
    LocationFormInputStore,
    LookupFormInputStore,
    SignatureDrawingFormInputStore,
    PeopleLookupFormInputStore,
    BarcodeScannerFormInputStore,
    NumberFormInputStore,
} from '@appformula/app-studio-core'
import { Nullable } from '@appformula/shared-foundation-x'
import { observer } from 'mobx-react-lite'
import { MultiLineTextFormInput } from './components/multiline-text/MultiLineText'
import { SingleLineTextFormInput } from './components/single-line-text/SingleLineText'
import { BooleanFormInput } from './components/boolean/Boolean'
import { DateTimeFormInput } from './components/date-time/DateTime'
import { BarcodeScannerFormInput } from './components/barcode-scanner/BarcodeScanner'
import LocationFormInput from './components/Location'
import LookupFormInput from './components/Lookup'
import { SignatureDrawingFormInput } from './components/signature-drawing/SignatureDrawing'
import PeopleLookupFormInput from './components/people-lookup/PeopleLookupFormInput'
import OptionFormInput from './components/option-form-input'
import {
    ARNFormPageStore,
    BooleanFormData,
    MultiLineFormData,
    OptionFormInputData,
    SingleLineFormData,
} from './ARNFormPageStore'
import { NumberFormInput } from './components/number/NumberFormInput'
import { ARNMultiLineTextFormStore } from './components/multiline-text/ARNMultiLineTextFormStore'
import { ARNSingleLineTextFormStore } from './components/single-line-text/ARNSingleLineTextFormStore'
import { ARNNumberFormStore } from './components/number/ARNNumberFormStore'
import { ARNBooleanFormStore } from './components/boolean/ARNBooleanFormStore'
import { ARNDateTimeFormStore } from './components/date-time/ARNDateTimeFormStore'
import { ARNBarCodescannerFormStore } from './components/barcode-scanner/ARNBarCodescannerFormStore'
import { ARNOptionFormInputStore } from './components/option-form-input/ARNOptionFormInputStore'
import { ARNPeopleLookupFormStore } from './components/people-lookup/ARNPeopleLookupFormStore'
import { ARNLookupFormStore } from './components/Lookup/ARNLookupFormStore'
import { ARNLocationFormStore } from './components/Location/ARNLocationFormStore'
import { ARNSignatureDrawingFormStore } from './components/signature-drawing/ARNSignatureDrawingFormStore'
import Files from './components/files/file'
// import Photos from './components/Photos/photo/index'
import { ARNFileFormStore } from './components/files/ARNFileFormStore'
import { ARNPhotoFormStore } from './components/photos/ARNPhotoFormStore'
import Photos from './components/photos/photo'
import { useBinding } from '../collection/components/hooks/useBinding'
import { FormulaManagerContext } from '../../context/FormulaManagerContext'
import { FormulaManager } from '../../utils/integrate-formula/FormulaManager'
import dayjs from 'dayjs'

export const ComponentSwitcher = observer(
    (props: {
        componentStore: Nullable<FormInputStore>
        arnFormPageStore: ARNFormPageStore
    }) => {
        const { componentStore, arnFormPageStore } = props
        if (!componentStore) {
            return null
        }
        const { componentId, inputType } = componentStore
        const formulaManager: FormulaManager | undefined = useContext(
            FormulaManagerContext
        )

        switch (componentStore?.inputType) {
            case 'multiLineText': {
                const multiLineTextFormInput =
                    componentStore as MultiLineTextFormInputStore
                const defaultValue = useBinding(
                    multiLineTextFormInput?.defaultValue
                )
                return (
                    <MultiLineTextFormInput
                        multiLineTextFormInput={multiLineTextFormInput}
                        arnMultiLineTextFormStore={
                            arnFormPageStore.childComponentFormStore(
                                componentId,
                                inputType,
                                defaultValue as unknown as MultiLineFormData,
                                formulaManager
                            ) as ARNMultiLineTextFormStore
                        }
                        arnFormPageStore={arnFormPageStore}
                    />
                )
            }

            case 'singleLineText': {
                const singleLineTextFormInput =
                    componentStore as SingleLineTextFormInputStore
                const defaultValue = useBinding(
                    singleLineTextFormInput?.defaultValue
                )
                return (
                    <SingleLineTextFormInput
                        singleLineTextFormInput={singleLineTextFormInput}
                        arnSingleLineTextFormStore={
                            arnFormPageStore.childComponentFormStore(
                                componentId,
                                inputType,
                                defaultValue as unknown as SingleLineFormData,
                                formulaManager
                            ) as ARNSingleLineTextFormStore
                        }
                        arnFormPageStore={arnFormPageStore}
                    />
                )
            }
            case 'options': {
                const optionsFormInput = componentStore as OptionsFormInputStore
                const defaultValue = useBinding(optionsFormInput?.defaultValue)
                return (
                    <OptionFormInput
                        optionsFormInput={optionsFormInput}
                        arnOptionFormInputStore={
                            arnFormPageStore.childComponentFormStore(
                                componentId,
                                inputType,
                                defaultValue as unknown as OptionFormInputData
                            ) as ARNOptionFormInputStore
                        }
                        arnFormPageStore={arnFormPageStore}
                    />
                )
            }

            case 'boolean': {
                const booleanFormInput = componentStore as BooleanFormInputStore
                const defaultValue = useBinding(booleanFormInput?.defaultValue)
                return (
                    <BooleanFormInput
                        booleanFormInput={booleanFormInput}
                        arnBooleanFormStore={
                            arnFormPageStore.childComponentFormStore(
                                componentId,
                                inputType,
                                defaultValue as unknown as BooleanFormData
                            ) as ARNBooleanFormStore
                        }
                        arnFormPageStore={arnFormPageStore}
                    />
                )
            }

            case 'files': {
                const filesFormInput = componentStore as FilesFormInputStore
                return (
                    <Files
                        filesFormInput={filesFormInput}
                        arnFileFormStore={
                            arnFormPageStore.childComponentFormStore(
                                componentId,
                                inputType
                            ) as ARNFileFormStore
                        }
                        arnFormPageStore={arnFormPageStore}
                    />
                )
            }

            case 'photos':
                return (
                    <Photos
                        photosFormInput={componentStore as PhotosFormInputStore}
                        arnPhotoFormStore={
                            arnFormPageStore.childComponentFormStore(
                                componentId,
                                inputType
                            ) as ARNPhotoFormStore
                        }
                        arnFormPageStore={arnFormPageStore}
                    />
                )
            case 'dateTime': {
                const dateTimeFormInput =
                    componentStore as DateTimeFormInputStore
                const defaultValue = useBinding(
                    dateTimeFormInput.defaultValue
                ) as Date

                const initData = defaultValue
                    ? { value: dayjs(defaultValue).toDate() }
                    : undefined

                return (
                    <DateTimeFormInput
                        dateTimeFormInput={dateTimeFormInput}
                        arnDateTimeFormStore={
                            arnFormPageStore.childComponentFormStore(
                                componentId,
                                inputType,
                                initData
                            ) as ARNDateTimeFormStore
                        }
                        arnFormPageStore={arnFormPageStore}
                    />
                )
            }

            case 'barcodeScanner': {
                return (
                    <BarcodeScannerFormInput
                        barcodeScannerFormInput={
                            componentStore as BarcodeScannerFormInputStore
                        }
                        arnBarCodescannerFormStore={
                            arnFormPageStore.childComponentFormStore(
                                componentId,
                                inputType
                            ) as ARNBarCodescannerFormStore
                        }
                        arnFormPageStore={arnFormPageStore}
                    />
                )
            }

            case 'location':
                return (
                    <LocationFormInput
                        locationFormInput={
                            componentStore as LocationFormInputStore
                        }
                        arnLocationFormStore={
                            arnFormPageStore.childComponentFormStore(
                                componentId,
                                inputType
                            ) as ARNLocationFormStore
                        }
                        arnFormPageStore={arnFormPageStore}
                    />
                )
            case 'lookup':
                return (
                    <LookupFormInput
                        lookupFormInput={componentStore as LookupFormInputStore}
                        arnLookupFormStore={
                            arnFormPageStore.childComponentFormStore(
                                componentId,
                                inputType
                            ) as ARNLookupFormStore
                        }
                    />
                )
            case 'signatureDrawing':
                return (
                    <SignatureDrawingFormInput
                        signatureDrawingFormInput={
                            componentStore as SignatureDrawingFormInputStore
                        }
                        arnSignatureDrawingFormStore={
                            arnFormPageStore.childComponentFormStore(
                                componentId,
                                inputType
                            ) as ARNSignatureDrawingFormStore
                        }
                        arnFormPageStore={arnFormPageStore}
                    />
                )
            case 'peopleLookup': {
                return (
                    <PeopleLookupFormInput
                        peopleLookupFormInput={
                            componentStore as PeopleLookupFormInputStore
                        }
                        arnPeopleLookupFormStore={
                            arnFormPageStore.childComponentFormStore(
                                componentId,
                                inputType,
                            ) as ARNPeopleLookupFormStore
                        }
                        arnFormPageStore={arnFormPageStore}
                    />
                )
            }

            case 'number': {
                const numberFormInputStore =
                    componentStore as NumberFormInputStore
                const initData = {
                    value: (
                        useBinding(numberFormInputStore.defaultValue) as number
                    )?.toString(),
                }
                return (
                    <NumberFormInput
                        numberFormInputStore={numberFormInputStore}
                        arnNumberFormStore={
                            arnFormPageStore.childComponentFormStore(
                                componentId,
                                inputType,
                                initData
                            ) as ARNNumberFormStore
                        }
                        arnFormPageStore={arnFormPageStore}
                    />
                )
            }

            default:
                return null
        }
    }
)
