import dayjs from 'dayjs'
import {
    CompareableValue,
    RangeUnit,
} from 'packages/app-descriptor/src/lib/prefilter/datetimeExt/DateTimeExtCondition'

export const subtractDays = (numOfDays: number, date = new Date()): Date => {
    date.setDate(date.getDate() - numOfDays)

    return date
}

export const subtractDaysWithUnit = (
    numUnit: number = 0,
    unit: RangeUnit = 'day',
    date = new Date()
): Date => {
    switch (unit) {
        case 'day':
            date.setDate(date.getDate() - numUnit)
            break
        case 'week':
            date.setDate(date.getDate() - numUnit * 7)
            break
        case 'month':
            date.setMonth(date.getMonth() - numUnit)
            break
        case 'year':
            date.setFullYear(date.getFullYear() - numUnit)
            break
    }

    return date
}

export const addDays = (numOfDays: number, date = new Date()): Date => {
    date.setDate(date.getDate() + numOfDays)

    return date
}

export const addDaysWithUnit = (
    numUnit: number,
    unit: RangeUnit = 'day',
    date = new Date()
): Date => {
    switch (unit) {
        case 'day':
            date.setDate(date.getDate() + numUnit)
            break
        case 'week':
            date.setDate(date.getDate() + numUnit * 7)
            break
        case 'month':
            date.setMonth(date.getMonth() + numUnit)
            break
        case 'year':
            date.setFullYear(date.getFullYear() + numUnit)
            break
    }

    return date
}

export type FirstLastDay = Record<'First' | 'Last', Date>

export const getFirstLastDayOfUnit = (
    comparableValue: CompareableValue,
    unit: RangeUnit = 'day'
): FirstLastDay => {
    const compareDate = new Date()

    switch (comparableValue) {
        case 'tomorrow':
            compareDate.setDate(compareDate.getDate() + 1)
            break
        case 'yesterday':
            compareDate.setDate(compareDate.getDate() - 1)
            break
        case 'current_day':
        default:
            break
    }

    switch (unit) {
        case 'day': {
            const date = new Date(compareDate)
            return {
                First: date,
                Last: date,
            }
        }
        case 'week': {
            const date = new Date(compareDate)
            const day = date.getDay()
            const diff = date.getDate() - day + (day === 0 ? -6 : 1)
            const firstDay = new Date(date.setDate(diff))
            const lastDay = new Date(firstDay)
            lastDay.setDate(lastDay.getDate() + 6)

            return {
                First: firstDay,
                Last: lastDay,
            }
        }
        case 'month': {
            const now = new Date(compareDate)
            const firstDay = new Date(now.getFullYear(), now.getMonth(), 1)
            const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0)

            return {
                First: firstDay,
                Last: lastDay,
            }
        }
        case 'year': {
            const currentYear = new Date(compareDate).getFullYear()
            const firstDay = new Date(currentYear, 0, 1)
            const lastDay = new Date(currentYear, 11, 31)

            return {
                First: firstDay,
                Last: lastDay,
            }
        }
    }
}

export const ConvertDateToDateQuery = (date: string | Date) => {
    if (typeof date === 'string') {
        return new Date(date).toISOString().slice(0, 10)
    } else if (date instanceof Date) {
        return date.toISOString().slice(0, 10)
    } else {
        return date
    }
}

export const ConvertDateToISOString = (date: string | Date) => {
    if (typeof date === 'string') {
        return new Date(date).toISOString().slice(0, -1)
    } else if (date instanceof Date) {
        return date.toISOString().slice(0, -1)
    } else {
        return date
    }
}

export const ConvertDateFormat = (
    date: string | Date,
    format: string = 'DD/MM/YYYY'
) => {
    return dayjs(date).format(format)
}

export const isToday = (date: string | Date) => {
    //
}

export const generateYear = (
    numberOfYear: number = 1500,
    startYear: number = 1900
) => {
    const listYear = Array.from(Array(numberOfYear).keys()).map(
        (item) => item + startYear
    )
    return listYear
}

export const getRangDateFromWeekNum = (weekNum: number, year: number) => {
    const endDate = new Date(year, 0, 1 + (weekNum + 1) * 7)
    while (endDate.getDay() !== 0) {
        endDate.setDate(endDate.getDate() - 1)
    }

    const startDate = dayjs(endDate).startOf('isoWeek').toDate()
    return {
        startDate,
        endDate,
    }
}
