import { View, Text, StyleSheet, Pressable, Image } from 'react-native'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Theme, useTheme } from '../../../../styles/theme'
import { useNavigation, useRoute } from '@react-navigation/native'
// import Torch from 'react-native-torch';
import {
    AutoFocus,
    BarCodeScanningResult,
    Camera,
    FlashMode,
} from 'expo-camera'
import image from '../../../../assets/icons'
import { SearchCollectionStore } from '../SearchCollectionStore'
import { COLORS } from '../../../../assets/colors'
import { ARNCollectionPageStore } from '../../ARNCollectionPageStore'
import { MainPageDataStoreContext } from '../../../../context/MainPageDataStoreContext'
import { DesignPageContext } from '../../../../context/DesignPageContext'
import { DefaultPageSelection } from '@appformula/app-studio-core'
import screenNames from '../../../../navigations/screenNames'
import PubSub from 'pubsub-js'
import appConstants from '../../../../constants/const'
import { useDatabaseAction } from '../../../../context/AppDatabaseContext'
import { AppAuthenticationContext } from '../../../../context/AppAuthenticationContext'

type Params = {
    searchCollectionStore: SearchCollectionStore
    arnCollectionPageStore?: ARNCollectionPageStore
}

const BarcodeScannerScreen = () => {
    const theme = useTheme()
    const styles = useMemo(() => createStyles(theme), [theme])
    const navigation = useNavigation()

    const mainPageDataStore = React.useContext(MainPageDataStoreContext)
    const designPageStore = React.useContext(DesignPageContext)
    const authStore = React.useContext(AppAuthenticationContext)

    const databaseAction = useDatabaseAction()

    const [hasPermission, setHasPermission] = useState<boolean | null>(null)

    const [flashMode, setFlashMode] = useState(FlashMode.off)
    const route = useRoute()

    const { searchCollectionStore } = route.params as Params

    useEffect(() => {
        checkPermission()
    }, [])

    const checkPermission = async () => {
        const { status } = await Camera.requestCameraPermissionsAsync()
        setHasPermission(status === 'granted')
    }

    const onCancel = useCallback(() => {
        navigation.goBack()
    }, [navigation])

    useEffect(() => {
        navigation.setOptions({
            headerLeft: () => (
                <Pressable onPress={onCancel} hitSlop={10}>
                    <Text
                        style={styles.textButtonHeader}
                        numberOfLines={1}
                        ellipsizeMode={'tail'}
                    >
                        {'Cancel'}
                    </Text>
                </Pressable>
            ),
            title: 'Barcode Search',
            headerTitleStyle: styles.headerTitleStyle,
        })
    }, [
        navigation,
        styles.headerTitleStyle,
        styles.textButtonHeader,
        theme.colorScheme.accentColor,
        theme.colorScheme.backgroundL1,
        onCancel,
    ])

    const handleBarCodeScanned = React.useCallback(
        async (scanningResult: BarCodeScanningResult) => {
            searchCollectionStore.setValueSearch(scanningResult.data)
            setTimeout(() => {
                PubSub.publish(appConstants.SET_DATA_SORT_FILTER)
            }, 0)

            const arnCollectionPageStore =
                mainPageDataStore.arnCollectionPageStore(
                    searchCollectionStore.collectionPageId,
                    databaseAction,
                    authStore?.authenticatedUser
                )

            if (arnCollectionPageStore) {
                const querySearch = arnCollectionPageStore.getQuerySearch()
                const query =
                    await arnCollectionPageStore.mapSearchFilterAndSort(
                        querySearch,
                        [],
                        []
                    )
                databaseAction
                    .getAllVirtualSqlDataWithQueryString(
                        arnCollectionPageStore.pageStore?.dataSource?.id ?? '',
                        query
                    )
                    .then(async (data) => {
                        if (data?.length > 0) {
                            const tableId =
                                arnCollectionPageStore.pageStore?.dataSource?.id
                            if (designPageStore && tableId) {
                                designPageStore.pageSelection =
                                    new DefaultPageSelection(tableId, 'details')
                            }

                            const itemData =
                                await databaseAction.getItemByIndex(
                                    arnCollectionPageStore.pageStore?.dataSource
                                        ?.id ?? '',
                                    query,
                                    data[0] as number
                                )

                            navigation.goBack()
                            navigation.navigate(
                                screenNames.DETAIL_SCREEN as never,
                                {
                                    pageId: '',
                                    tableId,
                                    itemKey: itemData.id ?? '',
                                } as never
                            )
                        } else {
                            navigation.goBack()
                        }
                    })
            }

            // navigation.goBack()
        },
        [
            authStore?.authenticatedUser,
            databaseAction,
            designPageStore,
            mainPageDataStore,
            navigation,
            searchCollectionStore,
        ]
    )

    const handleFlash = () => {
        if (flashMode === FlashMode.off) {
            setFlashMode(FlashMode.torch)
        } else {
            setFlashMode(FlashMode.off)
        }
    }

    if (hasPermission === null) {
        return <View />
    }
    if (hasPermission === false) {
        return <Text>No access to camera</Text>
    }

    return (
        <View style={styles.container}>
            <Camera
                style={styles.camera}
                flashMode={flashMode}
                onBarCodeScanned={handleBarCodeScanned}
                autoFocus={AutoFocus.on}
                useCamera2Api
                focusDepth={1}
            >
                <Pressable onPress={handleFlash}>
                    {flashMode === FlashMode.torch ? (
                        <View style={styles.wrapFlashOn}>
                            <Image source={image.flashOn} />
                        </View>
                    ) : (
                        <View style={styles.wrapFlashOff}>
                            <Image source={image.flashOff} />
                        </View>
                    )}
                </Pressable>
            </Camera>
        </View>
    )
}

export default BarcodeScannerScreen

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
            backgroundColor: 'red',
        },
        barcodeScanner: { flex: 1 },
        headerTitleStyle: {
            color: COLORS.WHITE,
            fontSize: 17,
            fontWeight: '600',
        },
        textButtonHeader: {
            color: COLORS.WHITE,
            fontWeight: '500',
            fontSize: 17,
        },
        text: {
            fontSize: 18,
            color: 'white',
        },
        camera: {
            flex: 1,
        },
        wrapFlashOn: {
            width: 56,
            height: 56,
            borderRadius: 56 / 2,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: colorScheme.accentColor,
            position: 'absolute',
            top: 705,
            right: 20,
        },
        wrapFlashOff: {
            width: 56,
            height: 56,
            borderRadius: 56 / 2,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.3)',
            position: 'absolute',
            top: 705,
            right: 20,
        },
    })
