//
//  ButtonMore.tsx
//
//  Created by thaitd96 on 2022-26-04 18:00.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React, {
    Image,
    StyleSheet,
    TouchableOpacity,
    Platform,
} from 'react-native'
import image from '../../../../assets/icons'
import { ItemAction } from '@appformula/app-descriptor/src'
import { observer } from 'mobx-react-lite'
import { ButtonMoreActions } from '../../../../components/button-more-actions/ButtonMoreActions'

export const ButtonMore = observer(
    (props: {
        itemActions: ItemAction[]
        itemData: Record<string, unknown>
    }) => {
        return (
            <ButtonMoreActions
                itemActions={props.itemActions}
                triggerComponent={
                    <TouchableOpacity
                        style={styles.touchMore}
                        disabled={Platform.OS === 'web'}
                    >
                        <Image source={image.more} style={styles.imageMore} />
                    </TouchableOpacity>
                }
                itemData={props.itemData}
            />
        )
    }
)

const styles = StyleSheet.create({
    imageMore: { height: 29, width: 29, resizeMode: 'stretch' },
    touchMore: {
        height: 45,
        width: 45,
        alignSelf: 'flex-end',
        marginTop: 4,
        marginRight: 4,
        justifyContent: 'center',
        alignItems: 'center',
    },
})
