//
//  NumberFormInput.tsx
//
//  Created by thaitd on 2022-08-15 10:29.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React, { useCallback, useEffect, useRef } from 'react'
import { NumberFormInputStore } from '@appformula/app-studio-core/src'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { StyleSheet, TextInput, View } from 'react-native'
import { Theme, useTheme } from '../../../../styles/theme'
import appConstants from '../../../../constants/const'
import Animated, {
    useAnimatedStyle,
    useSharedValue,
    withTiming,
} from 'react-native-reanimated'
import { ARNFormPageStore } from '../../ARNFormPageStore'
import { ARNNumberFormStore } from './ARNNumberFormStore'
import { useBinding } from '../../../collection/components/hooks/useBinding'

const styleFocus = {
    fontSize: 12,
    top: 13,
    marginTop: 36,
    paddingBottom: 13,
}

const styleBlur = {
    fontSize: 15,
    top: 25,
    marginTop: 20,
    paddingBottom: 25,
}

export const NumberFormInput = observer(
    (props: {
        numberFormInputStore: NumberFormInputStore
        arnNumberFormStore: ARNNumberFormStore
        arnFormPageStore: ARNFormPageStore
    }) => {
        const { numberFormInputStore, arnNumberFormStore } = props
        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])
        const defaultValue = useBinding(numberFormInputStore?.defaultValue)
        const isFocus: boolean = !!defaultValue?.toString().length
        const textInputRef = useRef(null)
        const isHidden = useBinding(numberFormInputStore.isHidden)

        useEffect(() => {
            const subscriptions = arnNumberFormStore.startListeningEvents()

            return () => {
                subscriptions.forEach((s) => s.unsubscribe())
            }
        }, [arnNumberFormStore])

        const onChangeText = React.useCallback(
            (text: string) => {
                arnNumberFormStore.setValue(text)
            },
            [arnNumberFormStore]
        )

        const fontSize = useSharedValue(
            isFocus ? styleFocus.fontSize : styleBlur.fontSize
        )
        const top = useSharedValue(isFocus ? styleFocus.top : styleBlur.top)
        const color = useSharedValue(
            (isFocus
                ? theme.colorScheme.labelPrimary
                : theme.colorScheme.labelSecondary) as string
        )
        const marginTop = useSharedValue(
            isFocus ? styleFocus.marginTop : styleBlur.marginTop
        )
        const paddingBottom = useSharedValue(
            isFocus ? styleFocus.paddingBottom : styleBlur.paddingBottom
        )

        const styleView = useAnimatedStyle(() => {
            return {
                top: withTiming(top.value),
            }
        })

        const viewWrap = useAnimatedStyle(() => {
            return {
                paddingBottom: withTiming(paddingBottom.value),
            }
        })
        const styleText = useAnimatedStyle(() => {
            return {
                fontSize: withTiming(fontSize.value),
                color: withTiming(color.value),
            }
        })

        const styleTextInput = useAnimatedStyle(() => {
            return {
                marginTop: withTiming(marginTop.value),
            }
        })

        const onFocus = useCallback(() => {
            fontSize.value = styleFocus.fontSize
            color.value = theme.colorScheme.labelPrimary as string
            top.value = styleFocus.top
            marginTop.value = styleFocus.marginTop
            paddingBottom.value = styleFocus.paddingBottom
        }, [
            color,
            fontSize,
            marginTop,
            paddingBottom,
            theme.colorScheme.labelPrimary,
            top,
        ])

        const onBlur = useCallback(() => {
            if (!arnNumberFormStore.valueString) {
                fontSize.value = styleBlur.fontSize
                color.value = theme.colorScheme.labelSecondary as string
                top.value = styleBlur.top
                marginTop.value = styleBlur.marginTop
                paddingBottom.value = styleBlur.paddingBottom
            }
        }, [
            color,
            fontSize,
            marginTop,
            paddingBottom,
            arnNumberFormStore.valueString,
            theme.colorScheme.labelSecondary,
            top,
        ])

        useEffect(() => {
            if (isFocus) {
                onFocus()
            } else {
                !textInputRef.current.isFocused() && onBlur()
            }
        }, [isFocus, onBlur, onFocus])

        useEffect(() => {
            arnNumberFormStore.setValue(defaultValue?.toString())
        }, [
            numberFormInputStore.defaultValue,
            arnNumberFormStore,
            defaultValue,
        ])

        return isHidden ? null : (
            <Animated.View style={[styles.viewNumberFormInput, viewWrap]}>
                <Animated.View style={[styles.title, styleView]}>
                    <Animated.Text style={[styles.textTitle, styleText]}>
                        {numberFormInputStore.titleName}
                    </Animated.Text>
                    {numberFormInputStore.required && (
                        <View style={styles.requireDot}></View>
                    )}
                </Animated.View>
                <Animated.View
                    style={[
                        { marginLeft: appConstants.PADDING_COMMON },
                        styleTextInput,
                    ]}
                >
                    <TextInput
                        keyboardType={
                            numberFormInputStore?.allowsFloatingNumber
                                ? 'decimal-pad'
                                : 'number-pad'
                        }
                        ref={textInputRef}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        placeholderTextColor={theme.colorScheme.labelPrimary}
                        style={styles.txtInput}
                        onChangeText={onChangeText}
                        value={arnNumberFormStore.valueString}
                    />
                </Animated.View>
            </Animated.View>
        )
    }
)

const createStyles = ({ colorScheme }: Theme) =>
    StyleSheet.create({
        textTitle: { color: colorScheme.black, fontWeight: '500' },
        title: {
            flexDirection: 'row',
            alignItems: 'center',
            position: 'absolute',
            left: appConstants.PADDING_COMMON,
        },
        requireDot: {
            height: 6,
            width: 6,
            backgroundColor: 'red',
            borderRadius: 6,
            marginLeft: 6,
        },
        viewNumberFormInput: {
            paddingRight: appConstants.PADDING_COMMON,
            borderBottomWidth: 1,
            borderColor: colorScheme.separator,
        },
        txtInput: {
            fontSize: 15,
            lineHeight: 21.75,
            flex: 1,
            fontWeight: '400',
            color: colorScheme.labelPrimary,
        },
    })
