import { View, Image as ImageRN, StyleSheet, Pressable } from 'react-native'
import React, { useCallback, useEffect, useState } from 'react'
import { Theme, useTheme } from '../../styles/theme'
import image from '../../assets/icons'
import FastImage from '../../components/fast-image/FastImage'
import { Loading } from '../../components'
import { AppStore } from '../../app/AppStore'
import { useDropzone } from 'react-dropzone'
import {
    generateRandomId,
    ResponseUpload,
    ResponseUploadData,
} from '../../utils'
import { FORM_URL } from '../../utils/network/apis/FormUrl'
import file from '../../utils/file/index.web'
import appConstants from '../../constants/const'
import { Alert } from '../../components/alert'

const AvatarPicker = ({
    appAuthenticationStore,
}: {
    appAuthenticationStore: AppStore
}) => {
    const theme = useTheme()
    const styles = React.useMemo(() => createStyles(theme), [theme])
    const [isLoading, setLoading] = useState<boolean>(false)
    const userInfo = appAuthenticationStore?.authenticatedUser || null
    const [avatarURL, setAvatarURL] = useState<string>('')

    useEffect(() => {
        setAvatarURL(userInfo?.photoURL || '')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const { open, acceptedFiles } = useDropzone({
        accept: {
            'image/*': [],
        },
        maxFiles: 1,
    })

    const handleUpload = useCallback(async () => {
        try {
            if (acceptedFiles && acceptedFiles.length > 0) {
                const newAcceptedFiles = acceptedFiles.map((item) => ({
                    id: generateRandomId(),
                    name: item.name,
                    type: item.type,
                    size: item.size,
                    preview: URL.createObjectURL(item),
                }))

                setLoading(true)
                setAvatarURL(URL.createObjectURL(acceptedFiles[0]))

                const resultSuccess = await file.uploadProfile({
                    data: newAcceptedFiles[0],
                    url: `${FORM_URL.uploadFile}`,
                    email: userInfo.email,
                })
                const url =
                (
                    JSON.parse(
                        (resultSuccess as ResponseUpload).data
                    ) as ResponseUploadData
                ).assetUrl ?? ''


                PubSub.publish(appConstants.RECEIVE_AVATAR_URL, url)
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            Alert.alert('Error', 'Something went wrong!!!', [
                {
                    text: 'OK',
                    onPress: () => {
                        //
                    },
                },
            ])
        }
    }, [acceptedFiles])

    useEffect(() => {
        handleUpload()
    }, [handleUpload])

    const renderImage = () => {
        return !avatarURL ? (
            <View style={styles.wrapNoAva}>
                <ImageRN source={image.avatar} style={styles.imgNoAva} />
            </View>
        ) : (
            <FastImage
                source={{ uri: avatarURL }}
                style={styles.imgAva}
            />
        )
    }

    return (
        <Pressable onPress={open}>
            {isLoading ? (
                <View style={styles.wrapLoading}>
                    <Loading />
                </View>
            ) : (
                renderImage()
            )}
        </Pressable>
    )
}

export default AvatarPicker

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        imgNoAva: {
            width: 53.93,
            height: 53.93,
            tintColor: colorScheme.separator,
        },

        imgAva: {
            height: 101,
            width: 101,
            borderRadius: 101 / 2,
            marginTop: 24,
            alignSelf: 'center',
        },

        wrapNoAva: {
            height: 101,
            width: 101,
            borderRadius: 101 / 2,
            borderColor: colorScheme.separator,
            backgroundColor: colorScheme.backgroundL2,
            borderStyle: 'dashed',
            borderWidth: 3,
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf: 'center',
            marginTop: 24,
        },

        wrapLoading: {
            height: 101,
            width: 101,
            alignItems: 'center',
            alignSelf: 'center',
            marginTop: 24,
            justifyContent: 'center',
            borderRadius: 101 / 2,
            backgroundColor: colorScheme.backgroundL2,
        },
    })
