//
//  useSpecifyPlatformWindowDimensions.ts
//
//  Created by thaitd96 on 2022-09-13 13:57.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { DesignPageStore } from '@appformula/app-studio-core/src'
import { Nullable } from '@appformula/shared-foundation-x/src'
import { useContext, useEffect, useState } from 'react'
import { Platform, useWindowDimensions } from 'react-native'
import { getBaseOs } from 'react-native-device-info'
import { DesktopOS } from '../../../../app/type'
import { DesignPageContext } from '../../../../context/DesignPageContext'
import { isLargeDevice } from '../../../../utils'
import cache from '../../../../utils/cache'

export enum DeviceOnDeskTop {
    width = 336,
    height = 686, // 726 - 40 (padding vertical)
}

export enum EditorTablet {
    width = 1133,
    height = 744,
}

export enum EditorPhone {
    width = 390,
    height = 844,
}

const getIsDesktop = async () => {
    const baseOs = await getBaseOs()
    return baseOs === DesktopOS.macOs || baseOs === DesktopOS.windows
}

export function useWidthHeightFull() {
    const { width, height } = useWindowDimensions()
    const designPageStore = useContext(DesignPageContext)
    const [isDesktop, setIsDeskTop] = useState<Nullable<boolean>>(undefined)

    useEffect(() => {
        const asyncFunc = async () => {
            setIsDeskTop(await getIsDesktop())
        }
        asyncFunc()
    })

    if (
        Platform.OS === 'web' &&
        cache.sourceStartUp === 'APP_NORMAL' &&
        isDesktop
    ) {
        return { width: DeviceOnDeskTop.width, height: DeviceOnDeskTop.height }
    } else if (
        Platform.OS === 'web' &&
        cache.sourceStartUp === 'APP_EDITOR' &&
        (designPageStore as DesignPageStore).previewMode === 'tablet'
    ) {
        return { width: EditorTablet.width, height: EditorTablet.height }
    } else if (
        Platform.OS === 'web' &&
        cache.sourceStartUp === 'APP_EDITOR' &&
        (designPageStore as DesignPageStore).previewMode === 'phone'
    ) {
        return { width: EditorPhone.width, height: EditorPhone.height }
    } else {
        return { width, height }
    }
}

export function useWidthHeightMainLayout() {
    const { width, height } = useWidthHeightFull()
    if (isLargeDevice(width)) {
        return { width: width - 344, height }
    } else {
        return { width, height }
    }
}
