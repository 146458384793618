import { TouchableOpacity, Image, StyleSheet } from 'react-native'
import React, { useMemo } from 'react'
import { Theme, useTheme } from '../../../../styles/theme'
import image from '../../../../assets/icons'

const FilterButton = (props: { goToFilter: () => unknown }) => {
    const theme = useTheme()
    const styles = useMemo(() => createStyles(theme), [theme])

    return (
        <TouchableOpacity onPress={props.goToFilter}>
            <Image style={styles.icon} source={image.iconFilter}></Image>
        </TouchableOpacity>
    )
}

export default FilterButton

const createStyles = ({ colorScheme }: Theme) =>
    StyleSheet.create({
        icon: { height: 34, width: 34, tintColor: colorScheme.accentColor },
    })
