//
//  ARNReaction.tsx
//
//  Created by thaitd96 on 2022-27-04 15:23.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React, { useMemo } from 'react'
import { Image, StyleSheet, Text, View } from 'react-native'
import { Theme, useTheme } from '../../../../styles/theme'
import image from '../../../../assets/icons'
import { ItemAccessory, Reaction } from '@appformula/app-descriptor/src'
import { observer } from 'mobx-react-lite'
import { useBindingHaveItemData } from '../hooks/useBindingHaveItemData'

export const ARNReaction = observer(
    (props: { accessory: ItemAccessory; item: Record<string, unknown> }) => {
        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])
        const icon = image.like

        const count = useBindingHaveItemData(
            (props.accessory as Reaction).count,
            props.item
        )

        return (
            <View style={styles.accessory}>
                <Image source={icon} style={styles.iconAccessory} />
                <Text
                    style={styles.textAccessory}
                    numberOfLines={1}
                    ellipsizeMode="tail"
                >
                    {count}
                </Text>
            </View>
        )
    }
)

const createStyles = ({ colorScheme }: Theme) =>
    StyleSheet.create({
        accessory: {
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: 4,
            height: 32,
        },
        textAccessory: {
            flex: 1,
            color: colorScheme.labelSecondary,
            marginLeft: 4,
            fontSize: 14,
            fontWeight: '500',
        },
        iconAccessory: {
            tintColor: colorScheme.accentColor,
            width: 24,
            height: 24,
        },
    })
