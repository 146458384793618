//
//  BottomSheetComponent.tsx
//
//  Created by thaitd96 on 2022-06-05 17:16.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React, { useCallback, useMemo, useRef } from 'react'
import BottomSheet, {
    BottomSheetModalProvider,
    BottomSheetView,
    useBottomSheetDynamicSnapPoints,
} from '@gorhom/bottom-sheet'
import { Theme, useTheme } from '../../../../styles/theme'
import { Image, Pressable, StyleSheet, Text, View } from 'react-native'
import appConstants from '../../../../constants/const'
import image from '../../../../assets/icons'
import { MapListItem } from './MapListItem'
import { CollectionPageStore } from '@appformula/app-studio-core/src'
import { MapLayout } from '@appformula/app-descriptor/src'
import { observer } from 'mobx-react-lite'
import { MapLayoutStore } from './MapLayoutStore'
import {
    ARNCollectionPageStore,
    IItemDataMap,
} from '../../ARNCollectionPageStore'
import { useWidthHeightMainLayout } from '../hooks/useSpecifyPlatformWindowDimensions'
import Sort from '../component-utils/SortButton'
import FilterButton from '../component-utils/FilterButton'
import { useNavigation } from '@react-navigation/native'
import screenNames from '../../../../navigations/screenNames'
import { SearchMap } from './SearchMap'
import { FlatList } from 'react-native-gesture-handler'
import ItemDetails from './ItemDetails'

const BottomSheetComponent = observer(
    (props: {
        pageStore: CollectionPageStore
        items: IItemDataMap[]
        mapLayoutStore: MapLayoutStore
        arnCollectionPageStore: ARNCollectionPageStore
    }) => {
        const { pageStore, items, arnCollectionPageStore, mapLayoutStore } =
            props
        const theme = useTheme()
        const { height } = useWidthHeightMainLayout()
        const styles = useMemo(
            () => createStyles(theme, height),
            [theme, height]
        )
        const navigation = useNavigation()
        const sheetRef = useRef<BottomSheet>(null)
        const sheetDetailRef = useRef<BottomSheet>(null)
        const ref = useRef({
            tempItemSelected: undefined,
        })
        // 60 cause height of bottom sheet header = 58,
        // padding to search = 4. So it need to between 58 & 62.
        const snapPoints = useMemo(() => [60, '50%'], [])
        const snapPointsDetail = useMemo(() => [60, 'CONTENT_HEIGHT'], [])
        const {
            animatedHandleHeight,
            animatedSnapPoints,
            animatedContentHeight,
            handleContentLayout,
        } = useBottomSheetDynamicSnapPoints(snapPointsDetail)
        const isGrouping = !!(pageStore.activeLayout as MapLayout)
            .groupingColumn

        const renderItem = ({
            item,
            index,
        }: {
            item: IItemDataMap
            index: number
        }) => {
            return (
                <MapListItem
                    item={item}
                    index={index}
                    isGrouping={isGrouping}
                    onPress={onPressItem}
                    pageStore={pageStore}
                    arnCollectionPageStore={arnCollectionPageStore}
                    mapLayoutStore={mapLayoutStore}
                />
            )
        }

        const keyExtractor = useCallback(
            (item: IItemDataMap) => {
                return `${item.index}_${item?.type || ''}_${
                    props.arnCollectionPageStore.queryString
                }`
            },
            [props.arnCollectionPageStore.queryString]
        )

        const onPressItem = (item: Record<string, unknown>) => {
            sheetRef.current?.close()
            ref.current.tempItemSelected = item
        }

        const closeDetail = useCallback(() => {
            sheetDetailRef.current?.close()
            props.mapLayoutStore.clearCurrentItem()
        }, [props.mapLayoutStore])

        const onCloseDetail = useCallback(() => {
            sheetRef.current?.snapToIndex(1)
        }, [])

        const onCloseSheetList = useCallback(() => {
            sheetDetailRef.current?.snapToIndex(1)
            props.mapLayoutStore.setCurrentItem(ref.current.tempItemSelected)
        }, [])

        const goToFilter = useCallback(() => {
            navigation &&
                navigation.navigate(
                    screenNames.COLLECTION_FILTER_SCREEN as never,
                    { pageId: props.pageStore.pageId } as never
                )
        }, [navigation, props.pageStore.pageId])

        const onAdd = () => {
            PubSub.publish(appConstants.OPEN_FORM_PAGE)
        }

        const Header = useMemo(
            () => (
                <View style={styles.header}>
                    <View style={styles.subHeader}>
                        <Text style={styles.title}>{'Store Locations'}</Text>
                        <View style={styles.wrapIcons}>
                            <Sort />
                            <FilterButton goToFilter={goToFilter} />
                            <Pressable onPress={onAdd}>
                                <Image
                                    style={styles.iconFirstRight}
                                    source={image.add}
                                    resizeMode={'contain'}
                                />
                            </Pressable>
                        </View>
                    </View>
                    <SearchMap
                        paddingTop={4}
                        searchCollectionStore={props.arnCollectionPageStore.getSearchStore()}
                        searchBarStore={pageStore.searchBarStore()}
                    />
                </View>
            ),
            [
                goToFilter,
                pageStore,
                props.arnCollectionPageStore,
                styles.header,
                styles.iconFirstRight,
                styles.subHeader,
                styles.title,
                styles.wrapIcons,
            ]
        )

        return (
            <BottomSheetModalProvider>
                <BottomSheet
                    ref={sheetRef}
                    snapPoints={snapPoints}
                    handleIndicatorStyle={styles.handleIndicatorStyle}
                    handleStyle={styles.handleStyle}
                    index={1}
                    onClose={onCloseSheetList}
                    backgroundStyle={styles.backgroundBottomSheet}
                    keyboardBehavior={'interactive'}
                    keyboardBlurBehavior={'restore'}
                >
                    {Header}
                    <FlatList
                        data={items}
                        keyExtractor={keyExtractor}
                        renderItem={renderItem}
                        contentContainerStyle={styles.contentContainer}
                    />
                </BottomSheet>
                <BottomSheet
                    ref={sheetDetailRef}
                    handleIndicatorStyle={styles.handleIndicatorStyle}
                    handleStyle={styles.handleStyle}
                    snapPoints={animatedSnapPoints}
                    handleHeight={animatedHandleHeight}
                    contentHeight={animatedContentHeight}
                    index={-1}
                    onClose={onCloseDetail}
                    backgroundStyle={styles.backgroundBottomSheet}
                >
                    <BottomSheetView onLayout={handleContentLayout}>
                        <ItemDetails
                            closeDetail={closeDetail}
                            mapLayoutStore={props.mapLayoutStore}
                            pageStore={pageStore}
                        />
                    </BottomSheetView>
                </BottomSheet>
            </BottomSheetModalProvider>
        )
    }
)

export default BottomSheetComponent

const createStyles = ({ colorScheme, typography }: Theme, height: number) =>
    StyleSheet.create({
        backgroundBottomSheet: { backgroundColor: colorScheme.backgroundL1 },
        header: { backgroundColor: colorScheme.backgroundL1 },
        wrapIcons: { flexDirection: 'row' },
        otherIcon: {
            tintColor: colorScheme.accentColor,
            height: 34,
            width: 34,
            marginRight: 8,
        },
        iconFirstRight: {
            tintColor: colorScheme.accentColor,
            height: 34,
            width: 34,
        },
        title: {
            fontWeight: '700',
            fontSize: 20,
            color: colorScheme.labelPrimary,
        },
        subHeader: {
            paddingHorizontal: appConstants.PADDING_COMMON,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        handleIndicatorStyle: {
            backgroundColor: colorScheme.separator,
            width: 38,
            height: 4,
            borderRadius: 11,
            marginBottom: 2,
        },
        handleStyle: { paddingTop: 8, paddingBottom: 9 },
        itemContainer: {
            padding: 6,
            margin: 6,
            backgroundColor: colorScheme.backgroundL2,
        },
        contentContainer: {
            backgroundColor: colorScheme.backgroundL1,
        },

        textInput: {
            alignSelf: 'stretch',
            marginHorizontal: 12,
            marginBottom: 12,
            padding: 12,
            borderRadius: 12,
            backgroundColor: 'grey',
            color: 'white',
            textAlign: 'center',
        },
        contentContainer1: {
            flex: 1,
            alignItems: 'center',
        },
    })
