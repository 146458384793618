//
//  Search.tsx
//
//  Created by thaitd96 on 2022-19-04 14:13.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import image from '../../../../assets/icons'
import appConstants from '../../../../constants/const'
import React, { useCallback, useMemo, useRef } from 'react'
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native'
import { Theme, useTheme } from '../../../../styles/theme'
import { useNavigation } from '@react-navigation/native'
import screenNames from '../../../../navigations/screenNames'
import AppTextInput, { IAppTextInput } from '../../../../components/AppTextInput'
import { observer } from 'mobx-react-lite'
import { SearchCollectionStore } from '../SearchCollectionStore'
import { CollectionPageSearchBarStore } from '@appformula/app-studio-core/src'
import PubSub from 'pubsub-js'
import { useBottomSheetInternal } from '@gorhom/bottom-sheet'

export const SearchMap = observer(
    (props: {
        paddingTop?: number
        searchCollectionStore: SearchCollectionStore
        searchBarStore: CollectionPageSearchBarStore
    }) => {
        const textInputRef = useRef<IAppTextInput>()
        const theme = useTheme()
        const navigation = useNavigation()

        const { shouldHandleKeyboardEvents } = useBottomSheetInternal()
        //#endregion
        const { paddingTop = 6, searchCollectionStore, searchBarStore } = props

        const isShowBarCode = searchBarStore.barcodeSearchEnabled

        const styles = useMemo(
            () => createStyles(theme, paddingTop),
            [theme, paddingTop]
        )

        const onChangeText = (text: string) => {
            props.searchCollectionStore.setValueSearch(text)
            setTimeout(() => {
                PubSub.publish(appConstants.SET_DATA_SORT_FILTER)
            }, 0)
        }

        const openBarcodeScanner = () => {
            navigation.navigate(
                screenNames.BARCODE_SCANNER_SCREEN as never,
                {
                    searchCollectionStore: props.searchCollectionStore,
                } as never
            )
        }

        //#region callbacks
        const handleOnFocus = useCallback(
            (args) => {
                shouldHandleKeyboardEvents.value = true
            },
            [shouldHandleKeyboardEvents]
        )
        const handleOnBlur = useCallback(
            (args) => {
                shouldHandleKeyboardEvents.value = false
            },
            [shouldHandleKeyboardEvents]
        )
        //#endregion

        return (
            <View style={styles.viewWrap}>
                <View style={styles.viewChild}>
                    <Image
                        source={image.iconSearch}
                        style={styles.search}
                        resizeMode={'contain'}
                    />
                    <AppTextInput
                        ref={textInputRef}
                        value={searchCollectionStore.valueSearchString}
                        onChangeText={onChangeText}
                        onFocus={handleOnFocus}
                        onBlur={handleOnBlur}
                        
                    />
                    {isShowBarCode && (
                        <TouchableOpacity
                            style={styles.barCodeButton}
                            onPress={openBarcodeScanner}
                        >
                            <Image
                                style={styles.iconBarCode}
                                source={image.iconBarCode}
                                resizeMode={'contain'}
                            />
                        </TouchableOpacity>
                    )}
                </View>
            </View>
        )
    }
)

const createStyles = ({ colorScheme, roundness }: Theme, paddingTop: number) =>
    StyleSheet.create({
        search: { height: 28, width: 28 },
        iconBarCode: {
            tintColor: colorScheme.accentColor,
            height: 34,
            width: 34,
        },
        barCodeButton: {
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
        },
        textInput: { flex: 1, marginHorizontal: 3, height: '100%' },
        viewChild: {
            backgroundColor: colorScheme.backgroundL2,
            height: 44,
            width: '100%',
            borderRadius: roundness,
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'row',
            paddingLeft: 4,
            paddingRight: 7,
        },
        viewWrap: {
            width: '100%',
            paddingHorizontal: appConstants.PADDING_COMMON,
            paddingTop,
            paddingBottom: 12,
            backgroundColor: colorScheme.backgroundL1,
        },
    })
