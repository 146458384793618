import { Nullable } from '@appformula/shared-foundation-x/src'

export enum AppType {
    INTERNAL = 'internal',
    EXTERNAL = 'external',
}

export type DataWrapContextDesktop = {
    logoURL: Nullable<string>
    appName: Nullable<string>
    teamName: Nullable<string>
    appType: Nullable<AppType>
}
