import { action, makeObservable, observable } from 'mobx'

type ListCalendarView = {
    data: Record<string, unknown>[]
    title: unknown
}[]

export class MonthCalendarStore {
    listCalenderView: ListCalendarView = []
    private totalDataLoaded: number = 0

    constructor() {
        makeObservable(this, {
            listCalenderView: observable,
            setListCalendarView: action,
        })
    }

    setListCalendarView(ListCalendarView: ListCalendarView) {
        this.listCalenderView = ListCalendarView
    }

    setTotalDataLoaded(value: number) {
        this.totalDataLoaded = value
    }

    getTotalDataLoaded() {
        return this.totalDataLoaded
    }
}
