//
//  OptionsInput.tsx
//
//  Created by hienbv97 on 2022-18-05 10:03.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { OptionsInputStore } from '@appformula/app-studio-core'
import appConstants from '../../../../constants/const'
import { observer } from 'mobx-react-lite'
import React, { createRef, useEffect, useMemo, useState } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { Theme, useTheme } from '../../../../styles/theme'
import OptionSingleChoiceItem, {
    IOptionSingleChoiceItem,
} from './OptionSingleChoiceItem'
import OptionMultiChoiceItem from './OptionMultiChoiceItem'
import { useBindingCommon } from '../../../collection/components/hooks/useBindingCommon'

export type IOption = {
    id: string
    content: string
}

interface IOptionRef {}

export const OptionsInput = observer(
    (props: {
        optionsInput: OptionsInputStore
        item: Record<string, unknown>
    }) => {
        const { optionsInput, item } = props
        const optionsInputData = optionsInput.object

        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])

        const [listData, setListData] = useState<Array<IOption>>([])
        const [elRefs, setElRefs] = useState({})
        const [keyReset, setKeyReset] = useState<number>(0)
        const title = useBindingCommon(optionsInputData.title, item)
        const isHidden = useBindingCommon(
            optionsInputData.isHidden,
            props.item
        )
        useEffect(() => {
            const listDataWithId =
                optionsInputData?.availableOptions?.map(
                    (item: string, index: number) => ({
                        content: item,
                        id: `${item}_${index}`,
                    })
                ) || []

            setListData(listDataWithId)
        }, [optionsInputData?.availableOptions])

        useEffect(() => {
            const temp = {}
            listData.forEach((item, index) => {
                Object.assign(temp, { [item.id]: createRef() })
            })
            setElRefs({ ...elRefs, ...temp })
            // Do not want to run when update elRefs - prevent exceed call stack
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [listData])

        // Case: switch from allow multiple selection to single or otherwise
        useEffect(() => {
            setKeyReset((prevKey) => prevKey + 1)
        }, [optionsInputData?.allowsMultipleSelection])

        const _changeSelected = (id: string) => {
            const ref = elRefs[
                id as keyof IOptionRef
            ] as React.RefObject<IOptionSingleChoiceItem>
            if (
                ref?.current?.checked &&
                !optionsInputData?.allowsMultipleSelection
            ) {
                Object.keys(elRefs).forEach((key) => {
                    if (key !== id) {
                        const refChild = elRefs[
                            key as keyof IOptionRef
                        ] as React.RefObject<IOptionSingleChoiceItem>
                        refChild?.current?.unCheck()
                    }
                })
            }
        }

        const renderOptionItem = (item: IOption, index: number) => {
            return !optionsInputData?.allowsMultipleSelection ? (
                <OptionSingleChoiceItem
                    ref={elRefs[item.id as keyof IOptionRef]}
                    item={item}
                    key={item.id}
                    changeSelected={_changeSelected}
                />
            ) : (
                <OptionMultiChoiceItem
                    ref={elRefs[item.id as keyof IOptionRef]}
                    item={item}
                    key={item.id}
                />
            )
        }

        return (
            !isHidden && (
                <View key={keyReset} style={styles.viewOptionsInput}>
                    <Text style={styles.title} numberOfLines={2}>{title}</Text>
                    {listData && listData.length > 0
                        ? listData.map((item, index) =>
                              renderOptionItem(item, index)
                          )
                        : null}
                </View>
            )
        )
    }
)
const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        viewOptionsInput: {
            marginVertical: appConstants.PADDING_COMMON / 2,
        },

        title: {
            fontWeight: '500',
            fontSize: 16,
            lineHeight: 19,
            color: colorScheme.labelPrimary,
        },
    })
