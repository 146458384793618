//
//  ARNCountdown.tsx
//
//  Created by thaitd96 on 2022-27-04 15:23.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React, { useEffect, useMemo, useState } from 'react'
import { Image, StyleSheet, Text, View } from 'react-native'
import { Theme, useTheme } from '../../../../styles/theme'
import image from '../../../../assets/icons'
import { Countdown, ItemAccessory } from '@appformula/app-descriptor/src'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { CountdownToTime } from '../../../../utils/timer/CountdownTimer'
import { observer } from 'mobx-react-lite'
import { useBindingHaveItemData } from '../hooks/useBindingHaveItemData'

dayjs.extend(relativeTime)

export const ARNCountdown = observer(
    (props: { accessory: ItemAccessory; item: Record<string, unknown> }) => {
        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])
        const [relativeDateFromNowToTime, setRelativeDateFromNowToTime] =
            useState('')
        const date = useBindingHaveItemData(
            (props.accessory as Countdown)?.date,
            props.item
        ) as Date

        useEffect(() => {
            // Update the count down every 1 second
            const interval = setInterval(async function () {
                const { distance, countDownText } = CountdownToTime(date)
                // If the count down is finished, write some text
                if (distance < 0) {
                    clearInterval(interval)
                    setRelativeDateFromNowToTime('0h 0m 0s')
                } else {
                    setRelativeDateFromNowToTime(countDownText)
                }
            }, 1000)

            return function Clean() {
                clearInterval(interval)
            }
        }, [date, props.accessory])

        return (
            <View style={styles.accessory}>
                <Image source={image.clock} style={styles.iconAccessory} />
                <Text
                    style={styles.textAccessory}
                    numberOfLines={1}
                    ellipsizeMode="tail"
                >
                    {relativeDateFromNowToTime}
                </Text>
            </View>
        )
    }
)

const createStyles = ({ colorScheme }: Theme) =>
    StyleSheet.create({
        accessory: {
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: 4,
            height: 32,
        },
        textAccessory: {
            flex: 1,
            color: colorScheme.labelSecondary,
            marginLeft: 4,
            fontSize: 14,
            fontWeight: '500',
        },
        iconAccessory: {
            tintColor: colorScheme.labelSecondary,
            width: 24,
            height: 24,
        },
    })
