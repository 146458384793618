//
//  FormScreenStore.ts
//
//  Created by thaitd96 on 2022-08-26 09:55.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { DesignPageStore } from '@appformula/app-studio-core/src'
import { action, makeObservable, observable } from 'mobx'
import { Alert } from '../../../../components/alert'
import { IDataSourceCord } from '../../../../components/ScrollViewHighLight'
import { ScrollViewHighLightStore } from '../../../../components/ScrollViewHighLight/ScrollViewHighLightStore'
import { ARNFormPageStore, FormScreenError } from '../../ARNFormPageStore'

export class FormScreenStore {
    private formPageId: string | undefined
    private arnFormPageStore: ARNFormPageStore
    cacheScrollViewHighLightStore: ScrollViewHighLightStore

    dataSourceCords: IDataSourceCord = {}

    constructor(arnFormPageStore: ARNFormPageStore, formPageId: string) {
        this.arnFormPageStore = arnFormPageStore
        this.formPageId = formPageId

        makeObservable(this, {
            dataSourceCords: observable,
            setDataSourceCords: action,
        })
    }

    setDataSourceCords(newCord: IDataSourceCord) {
        this.dataSourceCords = { ...this.dataSourceCords, ...newCord }
    }

    handleErrorSaveData(error: FormScreenError) {
        switch (error.type) {
            case 'VALIDATE':
                this.arnFormPageStore.setFormScreenError(error)
                break
            case 'SEND_TO_STORAGE':
                Alert.alert('Error', (error as FormScreenError).message)
                break
            default:
                break
        }
    }

    getScrollViewHighLightStore(
        designPageStore: DesignPageStore
    ): ScrollViewHighLightStore {
        if (this.cacheScrollViewHighLightStore) {
            return this.cacheScrollViewHighLightStore
        } else {
            const newScrollViewHighLightStore = new ScrollViewHighLightStore(
                this.formPageId,
                designPageStore
            )
            this.cacheScrollViewHighLightStore = newScrollViewHighLightStore
            return newScrollViewHighLightStore
        }
    }
}
