//
//  FilterDate.tsx
//
//  Created by thaitd96 on 2022-07-26 16:39.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React from 'react'
import { Image, StyleSheet, Text, View } from 'react-native'
import { TouchableOpacity } from '@gorhom/bottom-sheet'
import { Theme, useTheme } from '../../../../../styles/theme'
import image from '../../../../../assets/icons'
import appConstants from '../../../../../constants/const'
import { DateInputItem } from './DateInputItem'
import { observer } from 'mobx-react-lite'
import { FilterDateStore } from './FilteDateStore'
import { CollectionFilterStore } from '../CollectionFilterStore'
import ContextMenuCustom from '../../../../../components/context-menu-custom/ContextMenuCustom'
import { subtractDays } from '../../../../../utils/date-time/DateTime'

const EqualCompare = [
    { key: appConstants.DATE_COMPARE_TYPE.BETWEEN, title: 'Between' },
    { key: appConstants.DATE_COMPARE_TYPE.LAST_7_DAYS, title: 'Last 7 days' },
    { key: appConstants.DATE_COMPARE_TYPE.LAST_14_DAYS, title: 'Last 14 days' },
    { key: appConstants.DATE_COMPARE_TYPE.LAST_30_DAYS, title: 'Last 30 days' },
]

export const FilterDate = observer(
    (props: {
        filterDateStore: FilterDateStore
        collectionFilterStore: CollectionFilterStore
    }) => {
        const theme = useTheme()
        const styles = React.useMemo(() => createStyles(theme), [theme])

        const clear = React.useCallback(() => {
            props.collectionFilterStore.removeFilteredColumn(
                props.filterDateStore.columnFilter.key
            )
        }, [
            props.collectionFilterStore,
            props.filterDateStore.columnFilter.key,
        ])

        const ComponentHeader = React.useCallback(() => {
            return (
                <View style={styles.viewComponentHeader}>
                    <Text style={styles.textHeader}>
                        {props.filterDateStore.columnFilter.columnNameRaw}
                    </Text>
                    <TouchableOpacity onPress={clear}>
                        <Image source={image.close} style={styles.imageClose} />
                    </TouchableOpacity>
                </View>
            )
        }, [
            clear,
            props.filterDateStore.columnFilter.columnNameRaw,
            styles.imageClose,
            styles.textHeader,
            styles.viewComponentHeader,
        ])

        const setStartDate = React.useCallback(
            (date: Date) => {
                props.filterDateStore.setStartDate(date)
            },
            [props.filterDateStore]
        )

        const setEndDate = React.useCallback(
            (date: Date) => {
                props.filterDateStore.setEndDate(date)
            },
            [props.filterDateStore]
        )

        const onChangeSelection = React.useCallback(
            (option: number) => {
                props.filterDateStore.setType(option)
                switch (option) {
                    case appConstants.DATE_COMPARE_TYPE.LAST_7_DAYS: {
                        props.filterDateStore.setStartDate(subtractDays(7))
                        props.filterDateStore.setEndDate(new Date())
                        break
                    }
                    case appConstants.DATE_COMPARE_TYPE.LAST_14_DAYS: {
                        props.filterDateStore.setStartDate(subtractDays(14))
                        props.filterDateStore.setEndDate(new Date())
                        break
                    }
                    case appConstants.DATE_COMPARE_TYPE.LAST_30_DAYS: {
                        props.filterDateStore.setStartDate(subtractDays(30))
                        props.filterDateStore.setEndDate(new Date())
                        break
                    }
                    default:
                        break
                }
            },
            [props.filterDateStore]
        )

        return (
            <View style={styles.filterDate}>
                <ComponentHeader />
                <ContextMenuCustom
                    dataActions={EqualCompare}
                    dropdownMenuMode={true}
                    onSelect={onChangeSelection}
                    initialValue={appConstants.DATE_COMPARE_TYPE.BETWEEN}
                    currentValue={props.filterDateStore.type}
                />
                {props.filterDateStore.type ===
                    appConstants.DATE_COMPARE_TYPE.BETWEEN && (
                    <View style={styles.viewWrapDateInput}>
                        <DateInputItem
                            selectedDate={props.filterDateStore.startDate}
                            maximumDate={props.filterDateStore.endDate}
                            setDate={setStartDate}
                        />
                        <Image
                            source={image.arrowRightFilter}
                            style={styles.iconRightArrow}
                        />
                        <DateInputItem
                            selectedDate={props.filterDateStore.endDate}
                            minimumDate={props.filterDateStore.startDate}
                            setDate={setEndDate}
                        />
                    </View>
                )}
            </View>
        )
    }
)

const createStyles = ({ colorScheme }: Theme) => {
    return StyleSheet.create({
        iconRightArrow: {
            height: 24,
            width: 16,
            resizeMode: 'contain',
            tintColor: colorScheme.labelSecondary,
            marginHorizontal: 12,
        },
        viewWrapDateInput: {
            marginTop: 8,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        textHeader: {
            fontSize: 17,
            fontWeight: '600',
            color: colorScheme.labelPrimary,
        },
        viewComponentHeader: {
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingTop: 12,
        },
        imageClose: { height: 30, width: 30, resizeMode: 'contain' },
        filterDate: {
            justifyContent: 'center',
            backgroundColor: colorScheme.backgroundL1,
            paddingHorizontal: appConstants.PADDING_COMMON,
            paddingBottom: 12,
            borderBottomWidth: 1,
            borderBottomColor: colorScheme.separator,
        },
    })
}
