//
//  LeftViewBelow.tsx
//
//  Created by thaitd96 on 2022-12-05 13:57.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React from 'react'
import { observer } from 'mobx-react-lite'
import { WrapContextAppDesktopStore } from '../../WrapContextAppDesktopStore'
import { AppType } from '../../type'
import LeftViewBelowExternal from './LeftViewBelowExternal'
import LeftViewBelowInternal from './LeftViewBelowInternal'

type Props = {
    wrapContextAppDesktopStore: WrapContextAppDesktopStore
}

const LeftViewBelow = observer((props: Props) => {
    const { wrapContextAppDesktopStore } = props
    if (wrapContextAppDesktopStore.appType === AppType.INTERNAL) {
        return (
            <LeftViewBelowInternal
                wrapContextAppDesktopStore={wrapContextAppDesktopStore}
            />
        )
    } else {
        return (
            <LeftViewBelowExternal
                wrapContextAppDesktopStore={wrapContextAppDesktopStore}
            />
        )
    }
})

export default LeftViewBelow
