//
//  Button.tsx
//
//  Created by thaitd96 on 2022-20-04 11:38.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { ButtonStore } from '@appformula/app-studio-core'
import appConstants from '../../../constants/const'
import { observer } from 'mobx-react-lite'
import React, { useMemo } from 'react'
import {
    StyleSheet,
    Text,
    Pressable,
    Image,
    View,
    Platform,
} from 'react-native'
import { Theme, useTheme } from '../../../styles/theme'
import { hexToRGBA } from '../../../utils'
import { COLORS } from '../../../assets/colors'
import { useBindingCommon } from '../../collection/components/hooks/useBindingCommon'
import { actionIcon } from '../../../assets/icons/icon-actions'
import { useWidthHeightMainLayout } from '../../collection/components/hooks/useSpecifyPlatformWindowDimensions'
import cache from '../../../utils/cache'

export const Button = observer(
    (props: { button: ButtonStore; item: Record<string, unknown> }) => {
        const { button, item } = props
        const buttonData = button.object
        const theme = useTheme()
        const color = useBindingCommon(buttonData?.color, item)
        const { width } = useWidthHeightMainLayout()
        const styles = useMemo(
            () => createStyles(theme, color as string, width),
            [theme, color, width]
        )
        const title = useBindingCommon(buttonData?.title, item)
        const isHidden = useBindingCommon(buttonData?.isHidden, item)

        const icon = buttonData.icon

        const onPress = () => {
            console.info('onPress')
        }

        const btnStyle = useMemo(() => {
            switch (buttonData?.style) {
                case 'plain':
                    return {
                        btnView: styles.plainBtn,
                        txtBtn: styles.txtPlain,
                        iconBtn: styles.iconPlain,
                    }
                case 'filled':
                    return {
                        btnView: styles.filledBtn,
                        txtBtn: styles.txtFilled,
                        iconBtn: styles.iconFilled,
                    }
                case 'tinted':
                    return {
                        btnView: styles.tintedBtn,
                        txtBtn: styles.txtTinted,
                        iconBtn: styles.iconTinted,
                    }
                default:
                    return {
                        btnView: styles.plainBtn,
                        txtBtn: styles.txtPlain,
                        iconBtn: styles.iconPlain,
                    }
            }
        }, [buttonData?.style, styles])

        return (
            !isHidden && (
                <Pressable onPress={onPress} style={btnStyle.btnView}>
                    <View style={styles.viewAction}>
                        {icon && (
                            <Image
                                source={actionIcon[icon]}
                                style={btnStyle.iconBtn}
                            />
                        )}

                        <Text style={btnStyle.txtBtn} numberOfLines={1}>
                            {title || ''}
                        </Text>
                    </View>
                </Pressable>
            )
        )
    }
)

const createStyles = (
    { colorScheme, roundness, typography }: Theme,
    color: string,
    width: number
) =>
    StyleSheet.create({
        viewAction: { flexDirection: 'row', alignItems: 'center' },
        iconPlain: {
            width: 34,
            height: 34,
            tintColor: colorScheme.accentColor,
        },
        iconFilled: { width: 34, height: 34 },
        iconTinted: {
            width: 34,
            height: 34,
            tintColor: colorScheme.accentColor,
        },
        plainBtn: {
            marginVertical: appConstants.PADDING_COMMON / 2,
            justifyContent: 'center',
            ...Platform.select({
                web: {
                    width: width - 48,
                    height: 44,
                },
            }),
        },

        filledBtn: {
            marginVertical: appConstants.PADDING_COMMON / 2,
            paddingHorizontal: appConstants.PADDING_COMMON,
            backgroundColor: color || colorScheme.accentColor,
            borderRadius: roundness,
            justifyContent: 'center',
            alignItems: 'center',
            ...Platform.select({
                web: {
                    width:
                        cache.sourceStartUp === 'APP_EDITOR'
                            ? width - 40
                            : width - 48,
                    height: 44,
                },
            }),
        },

        tintedBtn: {
            paddingHorizontal: appConstants.PADDING_COMMON,
            marginVertical: appConstants.PADDING_COMMON / 2,
            backgroundColor: hexToRGBA(
                color || (colorScheme.accentColor as string),
                0.1
            ),
            borderRadius: roundness,
            justifyContent: 'center',
            alignItems: 'center',
            ...Platform.select({
                web: {
                    width:
                        cache.sourceStartUp === 'APP_EDITOR'
                            ? width - 40
                            : width - 48,
                    height: 44,
                },
            }),
        },

        txtPlain: {
            maxWidth: '90%',
            paddingVertical: 9.5,
            color: colorScheme.accentColor,
            fontWeight: '500',
            fontSize: 17,
            lineHeight: 24.75,
            ...Platform.select({
                web: {
                    lineHeight: 35,
                },
            }),
        },

        txtFilled: {
            maxWidth: '90%',
            paddingVertical: 9.5,
            color: COLORS.WHITE,
            fontWeight: '500',
            fontSize: 15,
            lineHeight: 24.75,
            ...Platform.select({
                web: {
                    maxWidth: width - 88,
                    lineHeight: 35,
                },
            }),
        },

        txtTinted: {
            maxWidth: '90%',
            paddingVertical: 9.5,
            color: colorScheme.accentColor,
            fontWeight: '500',
            fontSize: 15,
            lineHeight: 24.75,
            ...Platform.select({
                web: {
                    maxWidth: width - 88,
                    lineHeight: 35,
                },
            }),
        },
    })
