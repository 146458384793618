import {
    Image,
    ImageSourcePropType,
    StyleSheet,
    Text,
    View,
} from 'react-native'
import React, { useCallback, useMemo } from 'react'
import { Theme, useTheme } from '../../styles/theme'
import { observer } from 'mobx-react-lite'

interface IProps {
    source: ImageSourcePropType
    focused: boolean
}
const TabBarIcon = observer((props: IProps) => {
    const { source, focused } = props
    const theme = useTheme()
    const styles = useMemo(() => createStyles(theme, focused), [theme, focused])

    const iconStyle = useCallback(
        (focused: boolean) => {
            return {
                height: 34,
                width: 34,
                tintColor: focused
                    ? theme.colorScheme.accentColor
                    : theme.colorScheme.labelSecondary,
            }
        },
        [theme.colorScheme.accentColor, theme.colorScheme.labelSecondary]
    )

    return (
        <View style={styles.viewSmall}>
            <Image source={source} style={iconStyle(focused)} />
            <Text
                style={[styles.labelSmall]}
                numberOfLines={1}
                ellipsizeMode={'tail'}
            >
                {'More'}
            </Text>
        </View>
    )
})

export default TabBarIcon

const createStyles = ({ colorScheme }: Theme, focused: boolean) => {
    return StyleSheet.create({
        labelSmall: {
            fontSize: 11,
            fontWeight: '500',
            flex: 1,
            color: focused
                ? colorScheme.accentColor
                : colorScheme.labelSecondary,
        },
        viewSmall: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
    })
}
