//
//  MapLayout.tsx
//
//  Created by thaitd96 on 2022-05-05 10:04.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { CollectionPageStore } from '@appformula/app-studio-core'
import { observer } from 'mobx-react-lite'
import { FlatList, StyleSheet, View } from 'react-native'
import { Theme, useTheme } from '../../../../styles/theme'
import BottomSheetComponent from './BottomSheetComponent'
import { MapLayout as MapLayoutDescriptor } from '@appformula/app-descriptor/src'
import ItemGroupMap, { GroupDisplay } from './ItemGroupMap'
import { IReverseExtend, MapLayoutStore } from './MapLayoutStore'
import GoogleMapReact, { ChangeEventValue } from 'google-map-react'
import { ARNCollectionPageStore } from '../../ARNCollectionPageStore'
import IconGroupItem from './IconGroupItem'
import { IReverseGeocodeItem } from '../../../../utils/network/extensions/MapApi'
import appConstants from '../../../../constants/const'

export const MapLayout = observer(
    (props: {
        pageStore: CollectionPageStore
        mapLayoutStore: MapLayoutStore
        arnCollectionPageStore: ARNCollectionPageStore
    }) => {
        const { pageStore, mapLayoutStore, arnCollectionPageStore } = props
        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])
        const isGrouping = (pageStore.activeLayout as MapLayoutDescriptor)
            .groupingColumn
        const groupsDisplay = Object.values(
            (pageStore.activeLayout as MapLayoutDescriptor).groupsDisplay || {}
        )
        const [markers, setMarkers] = useState([])
        const [zoom, setZoom] = useState(9)
        const data = arnCollectionPageStore.dataMap

        useEffect(() => {
            mapLayoutStore.startListeningDataChange()
        }, [mapLayoutStore])

        useEffect(() => {
            const dataMarkers = JSON.parse(
                JSON.stringify(props.mapLayoutStore.listGeocode)
            )

            setMarkers(dataMarkers)
        }, [props.mapLayoutStore.listGeocode])

        const renderItem = useCallback(
            ({ item, index }: { item: GroupDisplay; index: number }) => {
                return (
                    <ItemGroupMap item={item} mapLayoutStore={mapLayoutStore} />
                )
            },
            [mapLayoutStore]
        )

        const ListGroup = useMemo(() => {
            return (
                <FlatList
                    style={styles.group}
                    contentContainerStyle={styles.contentContainerStyle}
                    horizontal
                    showsHorizontalScrollIndicator={false}
                    data={groupsDisplay}
                    keyExtractor={(item, index) => `${index}`}
                    renderItem={renderItem}
                />
            )
        }, [
            groupsDisplay,
            renderItem,
            styles.contentContainerStyle,
            styles.group,
        ])

        const Marker = ({
            text,
            item,
            lat,
            lng,
        }: {
            text?: string
            item: IReverseGeocodeItem & IReverseExtend
            lat: number
            lng: number
        }) => {
            return (
                <View>
                    <IconGroupItem
                        pageStore={pageStore}
                        typeValue={item?.type || ''}
                    />
                </View>
            )
        }

        const onChange = (value: ChangeEventValue) => {
            setZoom(value.zoom)
        }

        return (
            <View style={styles.container}>
                <GoogleMapReact
                    bootstrapURLKeys={{
                        key: appConstants.BOOTSTRAP_URL_KEYS,
                    }}
                    defaultCenter={{
                        lat: appConstants.REGION_DEFAULT.latitude,
                        lng: appConstants.REGION_DEFAULT.longitude,
                    }}
                    center={
                        props.mapLayoutStore.regionFocus
                            ? {
                                  lat: props.mapLayoutStore.regionFocus
                                      ?.latitude,
                                  lng: props.mapLayoutStore.regionFocus
                                      ?.longitude,
                              }
                            : null
                    }
                    defaultZoom={12}
                    // zoom={zoom}
                    yesIWantToUseGoogleMapApiInternals
                    onChange={onChange}
                >
                    {markers.length > 0 &&
                        markers?.map(
                            (
                                item: IReverseGeocodeItem & IReverseExtend,
                                index
                            ) => (
                                <Marker
                                    key={`${index}_${item?.query}`}
                                    lat={item.data.coordinate.latitude}
                                    lng={item.data.coordinate.longitude}
                                    item={item}
                                />
                            )
                        )}
                </GoogleMapReact>
                {isGrouping && ListGroup}
                <BottomSheetComponent {...props} items={data} />
            </View>
        )
    }
)

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        contentContainerStyle: { paddingHorizontal: 8 },
        group: { position: 'absolute', top: 12 },

        container: {
            flex: 1,
        },
        map: {
            ...StyleSheet.absoluteFillObject,
        },
    })
