//
//  WebLink.tsx
//
//  Created by thaitd96 on 2022-20-04 11:49.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { WebLinkStore } from '@appformula/app-studio-core'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Image, Linking, Pressable, StyleSheet, Text, View } from 'react-native'
import image from '../../../../assets/icons'
import { Alert } from '../../../../components/alert'
import FastImage from '../../../../components/fast-image/FastImage'
import appConstants from '../../../../constants/const'
import { Theme, useTheme } from '../../../../styles/theme'
import CustomPageApi from '../../../../utils/network/extensions/CustomPageApi'
import webLinkHelper from '../../../../utils/weblink/helper'
import { useBindingCommon } from '../../../collection/components/hooks/useBindingCommon'
import { useWidthHeightMainLayout } from '../../../collection/components/hooks/useSpecifyPlatformWindowDimensions'

interface IMetadata {
    description: string
    image: string
    title: string
    url: string
}

export const WebLink = observer(
    (props: {
        webLink: WebLinkStore
        item: Record<string, unknown>
        isViewHorizontal?: boolean
    }) => {
        const { webLink, item, isViewHorizontal } = props
        const { width } = useWidthHeightMainLayout()
        const theme = useTheme()
        const styles = useMemo(
            () => createStyles(theme, width, isViewHorizontal),
            [theme, width, isViewHorizontal]
        )
        const isHidden = useBindingCommon(webLink?.isHidden, item)
        const url = useBindingCommon(webLink?.url, item)
        const [data, setData] = useState<IMetadata>(null)

        const getData = useCallback(async () => {
            try {
                if (url) {
                    const data = await webLinkHelper.getInfo(url as string)
                    setData(data)
                } else {
                    setData(null)
                }
            } catch (error) {
                console.info('error----', error)
            }
        }, [url])

        useEffect(() => {
            getData()
        }, [getData])

        const onPress = async () => {
            try {
                const supported = await Linking.canOpenURL(data.url)
                if (supported) {
                    // Opening the link with some app, if the URL scheme is "http" the web link should be opened
                    // by some browser in the mobile
                    await Linking.openURL(data.url)
                } else {
                    Alert.alert(`Don't know how to open this URL: ${url}`)
                }
            } catch (error) {
                Alert.alert(`Don't know how to open this URL: ${url}`)
            }
        }

        const hasOnlyUrl = !data?.title && !data?.description
        const styleURL = hasOnlyUrl ? styles.txtUrlOnly : styles.txtUrl
        const numberLinesUrl = hasOnlyUrl ? 3 : 1
        const numberLinesTitle = !data?.description ? 2 : 1
        return (
            !isHidden && (
                <Pressable onPress={onPress}>
                    <View style={styles.viewWebLink} removeClippedSubviews>
                        {data?.image ? (
                            <FastImage
                                source={
                                    data?.image
                                        ? { uri: data.image }
                                        : image.noImg
                                }
                                // resizeMode={'contain'}
                                style={styles.imgUrl}
                            />
                        ) : (
                            <View style={styles.viewNoImg}>
                                <Image
                                    source={image.noImg}
                                    style={styles.imgNo}
                                />
                            </View>
                        )}
                        <View style={styles.wrapContent}>
                            <Text
                                numberOfLines={numberLinesUrl}
                                style={styleURL}
                            >
                                {data?.url}
                            </Text>
                            {data?.title && (
                                <Text
                                    numberOfLines={numberLinesTitle}
                                    style={styles.txtTitle}
                                >
                                    {data?.title}
                                </Text>
                            )}
                            {data?.description && (
                                <Text
                                    numberOfLines={2}
                                    style={styles.txtDescription}
                                >
                                    {data?.description}
                                </Text>
                            )}
                        </View>
                    </View>
                </Pressable>
            )
        )
    }
)

const createStyles = (
    { colorScheme, roundness, typography }: Theme,
    width: number,
    isViewHorizontal?: boolean
) =>
    StyleSheet.create({
        txtDescription: {
            fontSize: 13,
            fontWeight: '400',
            lineHeight: 21.45,
            color: colorScheme.labelSecondary,
            marginTop: 2,
        },
        txtTitle: {
            fontSize: 15,
            fontWeight: '500',
            lineHeight: 18.15,
            color: colorScheme.labelPrimary,
        },
        txtUrl: {
            fontSize: 12,
            fontWeight: '500',
            lineHeight: 14.52,
            color: colorScheme.accentColor,
            marginBottom: 5,
        },
        txtUrlOnly: {
            fontSize: 15,
            fontWeight: '500',
            lineHeight: 18.15,
            color: colorScheme.labelPrimary,
        },
        wrapContent: { flex: 1, justifyContent: 'center' },
        imgNo: { width: 44, height: 44 },
        viewNoImg: {
            width: 78,
            height: 74,
            borderRadius: 8,
            marginRight: 16,
            backgroundColor: colorScheme.backgroundL2,
            justifyContent: 'center',
            alignItems: 'center',
        },
        imgUrl: { width: 78, height: 74, borderRadius: 8, marginRight: 16 },
        viewWebLink: {
            flexDirection: 'row',
            padding: 14,
            borderRadius: 8,
            borderWidth: 1,
            borderColor: colorScheme.separator,
            marginVertical: appConstants.PADDING_COMMON / 2,
            height: 102,
            width: isViewHorizontal ? width - 100 : null,
        },
    })
