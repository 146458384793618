//
//  FormulaManagerContext.tsx
//
//  Created by hienbv on 2022-09-30 1821.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { Nullable } from '@appformula/shared-foundation-x'
import React from 'react'
import { AppStore } from '../app/AppStore'

const AppAuthenticated: Nullable<AppStore> = undefined

export const AppAuthenticationContext =
    React.createContext<Nullable<AppStore>>(AppAuthenticated)
