import { action, makeAutoObservable, observable } from 'mobx'
import { BehaviorSubject, distinctUntilChanged, Subscription } from 'rxjs'
import { IDataSourceCord } from '.'
import { isEmpty } from 'lodash'
import { DesignPageStore, ResultDragEnd } from '@appformula/app-studio-core/src'
export class ScrollViewHighLightStore {
    yOffset: number
    pageId: string
    dataSourceCords: IDataSourceCord = {}
    yCoordinateRN: number = 0
    resultDragEnd: ResultDragEnd

    private yCoordinateRNSubject: BehaviorSubject<number> =
        new BehaviorSubject<number>(this.yCoordinateRN)

    isDroppedComponent: boolean = true
    private isDroppedComponentSubject: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(this.isDroppedComponent)
    designPageStore: DesignPageStore

    private componentIdsSubject: BehaviorSubject<string[]> =
        new BehaviorSubject<string[]>([])

    constructor(pageId: string, designPageStore: DesignPageStore) {
        this.pageId = pageId
        this.designPageStore = designPageStore
        makeAutoObservable(this, {
            yOffset: observable,
            setYOffset: action,
            dataSourceCords: observable,
            setDataSourceCords: action,
        })
    }

    setYOffset(value: number) {
        this.yOffset = value
    }

    setDataSourceCords(newCords: IDataSourceCord) {
        this.dataSourceCords = newCords
    }

    startListeningDataChange(): Subscription[] {
        const subscribeDragEnd = this.isDroppedComponentSubject.subscribe({
            next: (newValue) => {
                if (isEmpty(this.resultDragEnd) || !newValue) {
                    return
                }

                if (isEmpty(this.dataSourceCords)) {
                    const result: ResultDragEnd = {
                        lexoRank: 'above',
                        targetComponent: '',
                    }
                    this.designPageStore.resultDragEnd = result
                } else {
                    const result: ResultDragEnd = {
                        lexoRank: this.resultDragEnd.lexoRank,
                        targetComponent: this.resultDragEnd.targetComponent,
                    }

                    this.designPageStore.resultDragEnd = result
                }

                setTimeout(() => {
                    this.designPageStore.isDroppedComponent = false
                }, 2000)
            },
        })

        const subscribeComponentIds = this.componentIdsSubject
            .pipe(distinctUntilChanged())
            .subscribe({
                next: (newValue) => {
                    //
                },
            })

        return [subscribeDragEnd, subscribeComponentIds]
    }

    setYCoordinateRN(yCoordinateRN: number) {
        this.yCoordinateRN = yCoordinateRN
        this.yCoordinateRNSubject.next(yCoordinateRN)
    }

    setIsDroppedComponent(isDroppedComponent: boolean) {
        this.isDroppedComponent = isDroppedComponent
        this.isDroppedComponentSubject.next(isDroppedComponent)
    }

    setComponentIds(componentIds: string[]) {
        this.componentIdsSubject.next(componentIds)
    }
}
