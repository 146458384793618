import { View, Text, StyleSheet } from 'react-native'
import React, {
    createRef,
    forwardRef,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react'
import { Theme, useTheme } from '../../../../styles/theme'
import OptionItem, { IDataItem, IOptionItem } from './OptionItem'
import { CollectionSortStore } from './CollectionSortStore'
interface IOptions {
    title?: string
    data?: IDataItem[]
    optionSelected?: string
    type: string
    collectionSortStore: CollectionSortStore
    ref?: any
}

interface IOptionRef {}

export interface IOptionsRef {
    getOptionSeleted?: () => string
}

const Options: React.FC<IOptions> = forwardRef((props: IOptions, ref) => {
    const theme = useTheme()
    const styles = useMemo(() => createStyles(theme), [theme])
    const [elRefs, setElRefs] = useState({})

    const { data, optionSelected, title } = props

    const refs = useRef({
        itemSelected: optionSelected,
    })

    useImperativeHandle(ref, () => ({
        getOptionSeleted: () => {
            return refs.current.itemSelected
        },
    }))

    useEffect(() => {
        const temp = {}
        data.forEach((item, index) => {
            Object.assign(temp, { [item.id]: createRef() })
        })
        setElRefs({ ...elRefs, ...temp })
        // Do not want to run when update elRefs - prevent exceed call stack
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const renderItemOption = (item: IDataItem) => {
        return (
            <OptionItem
                key={item.id}
                ref={elRefs[item.id as keyof IOptionRef]}
                item={item}
                changeSelected={_changeSelected}
                optionSelected={optionSelected}
            />
        )
    }

    const _changeSelected = (id: string) => {
        const ref = elRefs[
            id as keyof IOptionRef
        ] as React.RefObject<IOptionItem>
        if (ref?.current?.checked) {
            refs.current.itemSelected = ref.current.item.key
            Object.keys(elRefs).forEach((key) => {
                if (key !== id) {
                    const refChild = elRefs[
                        key as keyof IOptionRef
                    ] as React.RefObject<IOptionItem>
                    refChild?.current?.unCheck()
                }
            })
        }
    }

    return (
        <View style={styles.container}>
            <Text style={styles.txtTitle}>{title}</Text>
            {data &&
                data.length > 0 &&
                data.map((item) => renderItemOption(item))}
        </View>
    )
})

Options.displayName = 'Options'

export default Options

Options.defaultProps = {
    title: 'Options',
    data: [],
}

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        txtTitle: {
            color: colorScheme.labelPrimary,
            fontSize: 17,
            fontWeight: '600',
            lineHeight: 20.57,
            marginBottom: 21,
        },
        container: {
            padding: 16,
            borderBottomColor: colorScheme.separator,
            borderBottomWidth: 1,
        },
    })
