//
//  ARNPhotoFormStore.ts
//
//  Created by thaitd on 2022-08-12 14:08.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { Nullable } from '@appformula/shared-foundation-x/src'
import { action, makeObservable, observable } from 'mobx'
import { Subject, Subscription } from 'rxjs'
import { FormulaManager } from '../../../../utils/integrate-formula/FormulaManager'
import { ARNFormPageStore } from '../../ARNFormPageStore'
import { BaseARNFormStore } from '../BaseARNFormStore'

export type PhotoUploaded = {
    assetUrl: URL
}

export class ARNPhotoFormStore implements BaseARNFormStore {
    readonly formPageId: string
    readonly componentId: string
    arnFormPageStore: ARNFormPageStore
    listPhotoUploaded: PhotoUploaded[]
    listPhotoUploadedChange: Subject<void>
    formulaManager?: FormulaManager
    width: Nullable<number>

    constructor(
        arnFormPageStore: ARNFormPageStore,
        formPageId: string,
        componentId: string,
        formulaManager?: FormulaManager
    ) {
        this.arnFormPageStore = arnFormPageStore
        this.formPageId = formPageId
        this.componentId = componentId
        this.listPhotoUploaded = []
        this.listPhotoUploadedChange = new Subject()
        this.formulaManager = formulaManager
        makeObservable(this, {
            width: observable,
            setWidth: action,
        })
    }

    startListeningEvents(): Subscription[] {
        const valueChangeSubscription = this.listPhotoUploadedChange.subscribe({
            next: () => {
                this.arnFormPageStore.clearFormScreenError(this.componentId)
            },
        })

        return [valueChangeSubscription]
    }

    async validate(): Promise<void> {
        const componentStore = this.arnFormPageStore.pageStore.componentStore(
            this.componentId
        )

        if (componentStore.required && !this.listPhotoUploaded?.length) {
            return Promise.reject(`${componentStore.titleName} is required!`)
        }
    }

    addPhotoUploaded(photoUpload: PhotoUploaded) {
        this.listPhotoUploaded.push(photoUpload)
        this.listPhotoUploadedChange.next()
    }

    setWidth(width: number) {
        this.width = width
    }
}
