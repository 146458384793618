import { apiClient } from '../index'
import { OPENGRAPHSCRAPER_URL } from '../apis/OpenGraphScraperUrl'
import appConstants from '../../../constants/const'

interface IDataOpenGraphScraper {
    url: string
}

export interface IResponseGetLinkInfo {
    metadata: {
        description: string
        image: string
        title: string
        url: string
    }
    success: boolean
}

export default {
    getWeblinkInfo: async (data: IDataOpenGraphScraper) => {
        return apiClient.get(
            `${appConstants.API.BASE_URL_CLOUDFUNCTION}/${OPENGRAPHSCRAPER_URL.getWeblinkInfo}`,
            {
                data,
                needSetApiPath: false,
                isHandleError: false,
            }
        )
    },
}
