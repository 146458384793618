//
//  MapLayoutStore.tsx
//
//  Created by thaitd96 on 2022-18-05 17:25.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { makeObservable, observable, action } from 'mobx'
import { Nullable } from '@appformula/shared-foundation-x'
import MapApi, {
    IReverseGeocodeItem,
} from '../../../../utils/network/extensions/MapApi'
import { BehaviorSubject, debounceTime, skip } from 'rxjs'
import { Dimensions, Platform } from 'react-native'
import { getGeocodingWithMapKit } from '../../../../utils/location'
import appConstants from '../../../../constants/const'
import _ from 'lodash'
import { Alert } from '../../../../components/alert'

export interface IRegion {
    latitude?: number
    longitude?: number
    latitudeDelta?: number
    longitudeDelta?: number
}

export interface IParamReverse {
    addressString: string
    type: string
}

export interface IReverseExtend {
    type?: string
}

export interface IResponseReverseGeocode {
    query: string
    data: {
        coordinate: {
            latitude: number
            longitude: number
        }
        region: {
            latitude: number
            latitudeDelta: number
            longitude: number
            longitudeDelta: number
        }
    }
}

const { width, height } = Dimensions.get('window')

const ASPECT_RATIO = width / height

const DEFAULT_DELTA_REGION = {
    latitudeDelta: 0.0922,
    longitudeDelta: 0.0922 * ASPECT_RATIO,
}
export class MapLayoutStore {
    isShowMapListItem = true
    currentItem: Nullable<Record<string, unknown>> = undefined
    listGeocode: IResponseReverseGeocode[] = []
    listAddressInit: IParamReverse[] = []
    private listAddressInitBeS = new BehaviorSubject(this.listAddressInit)
    regionFocus: IRegion = null
    activeGroups: string[] = []
    private activeGroupsBeS = new BehaviorSubject<string[]>(this.activeGroups)
    isMapReady: boolean = false

    constructor() {
        makeObservable(this, {
            isShowMapListItem: observable,
            currentItem: observable,
            listGeocode: observable,
            regionFocus: observable,
            activeGroups: observable,
            showMapItemDetail: action,
            clearCurrentItem: action,
            setCurrentItem: action,
            setListAddressInit: action,
            setListGeocode: action,
            resetListAddressInit: action,
            setRegionFocus: action,
            changeIsActive: action,
        })
    }

    startListeningDataChange() {
        this.listAddressInitBeS.subscribe({
            next: (newValue) => {
                if(newValue.length === 10) {
                    this.reverseAndSetGeocodeList(newValue)
                }
            },
        })

        this.activeGroupsBeS.pipe(debounceTime(500), skip(1)).subscribe({
            next: (newValue) => {
                PubSub.publish(appConstants.SET_DATA_SORT_FILTER)
            },
        })
    }

    showMapItemDetail() {
        // Intermediate states will not become visible to observers.
        this.isShowMapListItem = false
    }

    hideMapItemDetail() {
        this.isShowMapListItem = true
    }

    clearCurrentItem() {
        this.currentItem = undefined
        this.regionFocus = undefined
    }


    setCurrentItem(item: Record<string, unknown>) {
        this.currentItem = item
    }

    setListGeocode(result: (IResponseReverseGeocode & IReverseExtend)[]) {
        const list = [...this.listGeocode, ...result]
        this.listGeocode = list
    }

    setListAddressInit(address: IParamReverse) {
        const newList = [...this.listAddressInit, address]
        this.listAddressInit = newList
        this.listAddressInitBeS.next(newList)
    }

    resetListAddressInit() {
        this.listAddressInit = []
    }

    setRegionFocus(coordinate: IRegion) {
        this.regionFocus = coordinate
    }

    changeIsActive(columnValue: string) {
        const i = this.activeGroups.indexOf(columnValue)
        if (i >= 0) {
            this.activeGroups.splice(i, 1)
        } else {
            this.activeGroups.push(columnValue)
        }
        this.activeGroupsBeS.next(this.activeGroups)
    }

    async reverseGeocodeFromApi(
        addresses: string[]
    ): Promise<IResponseReverseGeocode[]> {
        try {
            if (Platform.OS === 'ios') {
                const listPromise = addresses.map((item) =>
                    getGeocodingWithMapKit(item)
                )
                const response = await Promise.all(listPromise)
                const result = response.map((item, index) => {
                    const data: IResponseReverseGeocode = {
                        query: addresses[index],
                        data: {
                            ...item,
                        },
                    }
                    return data
                })
                
                return result
            } else {
                const addressesString = addresses.join(';')
                const response = await MapApi.reverseGeocode(addressesString)
                const result: IResponseReverseGeocode[] = []
                response.data.results?.forEach((item: IReverseGeocodeItem) => {
                    if (!_.isEmpty(item.data)) {
                        const res = {
                            ...item,
                            data: {
                                ...item.data,
                                region: {
                                    latitude: item.data?.coordinate?.latitude,
                                    longitude: item.data?.coordinate?.longitude,
                                    latitudeDelta:
                                        DEFAULT_DELTA_REGION.latitudeDelta,
                                    longitudeDelta:
                                        DEFAULT_DELTA_REGION.longitudeDelta,
                                },
                            },
                        }
                        result.push(res)
                    }
                })
                return result
            }
        } catch (error) {
            console.info('reverseGeocodeFromApi------', error)
            return []
        }
    }

    async reverseAndSetGeocodeList(listAddress: IParamReverse[]) {
        try {
            const listAddressHasReversed = this.listGeocode.map(
                (item) => item.query
            )
            const listAddressString = listAddress.map(
                (item) => item.addressString
            )

            const listNeedReverse: string[] = []
            listAddressString.forEach((item) => {
                const notReverseYet = !listAddressHasReversed.includes(item)
                if (notReverseYet) {
                    listNeedReverse.push(item)
                }
            })

            if (listNeedReverse.length > 0) {
                const response = await this.reverseGeocodeFromApi(
                    listNeedReverse
                )
                const result = response.reduce((acc, curr) => {
                    const newCurr: IResponseReverseGeocode & IReverseExtend = {
                        ...curr,
                    }
                    const index = listAddress.findIndex(
                        (item) => item.addressString === newCurr.query
                    )
                    if (index > -1) {
                        newCurr.type = listAddress[index].type
                    }

                    acc.push(newCurr)
                    return acc
                }, [])
                this.setRegionFocus(result[0].data.region)
                this.setListGeocode(result)
                this.resetListAddressInit()
            }
        } catch (error) {
            console.info('reverseAndSetGeocodeList error', error)
        }
    }

    async reverseAndSetGeocodeItem(address: IParamReverse) {
        try {
            const itemReverse = this.listGeocode.find(
                (item) => item.query === address.addressString
            )

            let region = {}

            if (!itemReverse) {
                const response = await this.reverseGeocodeFromApi([
                    address.addressString,
                ])
                const result = response?.[0]
                
                if(!response?.[0]) {
                    Alert.alert('Warning', 'Can not find this location', [
                        {
                            text: 'OK',
                            onPress: () => {
                                //
                            },
                        },
                    ])
                    return
                }
                this.setListGeocode([{ ...result, type: address.type }])
                region = result?.data?.region || {}
            } else {
                region = itemReverse?.data?.region
            }
            this.setRegionFocus(region)
        } catch (error) {
            console.info('reverseAndSetGeocodeItem error', error)
        }
    }
}
