//
//  WrapContextDesktop.tsx
//
//  Created by thaitd96 on 2022-11-07 16:27.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import React, { Image, StyleSheet, Text, View } from 'react-native'
import { Theme, useTheme } from '../../../../styles/theme'
import { WrapContextAppDesktopStore } from '../WrapContextAppDesktopStore'
import LeftViewBelow from './left-view-below/LeftViewBelow'

type Props = {
    wrapContextAppDesktopStore: WrapContextAppDesktopStore
}

const DesktopWrapperLeftView = observer((props: Props) => {
    const { wrapContextAppDesktopStore } = props
    const theme = useTheme()
    const styles = useMemo(() => createStyles(theme), [theme])
    return (
        <View style={styles.container}>
            <View style={styles.wrapIcon}>
                <Image
                    source={{ uri: wrapContextAppDesktopStore.logoURL }}
                    style={styles.image}
                ></Image>
            </View>
            <Text style={styles.appName}>
                {wrapContextAppDesktopStore.appName}
            </Text>
            <Text style={styles.teamName}>
                {wrapContextAppDesktopStore.teamName}
            </Text>
            <View style={styles.separator} />

            <LeftViewBelow
                wrapContextAppDesktopStore={wrapContextAppDesktopStore}
            />
        </View>
    )
})

export default DesktopWrapperLeftView

const createStyles = ({ colorScheme }: Theme) =>
    StyleSheet.create({
        separator: {
            height: 1,
            width: '100%',
            backgroundColor: colorScheme.separator,
            marginTop: 50,
        },
        teamName: {
            color: colorScheme.labelSecondary,
            fontWeight: '400',
            fontSize: 12,
            marginTop: 12,
        },
        appName: {
            fontWeight: '600',
            fontSize: 26,
            lineHeight: 31.47,
            marginTop: 18,
            color: colorScheme.labelPrimary,
        },
        image: { height: 57, width: 57 },
        container: {
            padding: 40,
            flex: 1,
            backgroundColor: colorScheme.backgroundL1,
        },
        wrapIcon: {
            borderWidth: 1,
            borderColor: colorScheme.separator,
            borderRadius: 22,
            padding: 20,
            alignSelf: 'baseline',
        },
    })
