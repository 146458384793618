//
//  FormOptionsPage.tsx
//
//  Created by thaitd96 on 2022-06-22 17:03.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import {
    FormPageStore,
    OptionsFormInputStore,
} from '@appformula/app-studio-core'
import appConstants from '../../../../../constants/const'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { StyleSheet, View } from 'react-native'
import { Theme, useTheme } from '../../../../../styles/theme'
import MultiChoiceItem from '../MultiChoiceItem'
import SingleChoiceItem from '../SingleChoiceItem'
import { ARNFormPageStore } from '../../../ARNFormPageStore'
import { ScrollView } from 'react-native-gesture-handler'
import { SearchFormPage } from '../../SearchFormPage'
import { Option } from '../OptionFormInputInline'
import AddOptionFormInput from '../AddOptionFormInput'
import { ARNOptionFormInputStore } from '../ARNOptionFormInputStore'
import { useRoute } from '@react-navigation/native'

export type IOption = {
    id: string
    content: string
}

type Params = {
    componentId: string
}

const isMultipleChoice = true

const FormOptionsPage = observer(
    (props: {
        pageStore: FormPageStore
        arnFormPageStore: ARNFormPageStore
    }) => {
        const { pageStore, arnFormPageStore } = props
        const route = useRoute()
        const { componentId } = route.params as Params

        const componentStore = pageStore.componentStore(
            componentId
        ) as OptionsFormInputStore

        const arnOptionFormInputStore =
            arnFormPageStore.childComponentFormStore(
                componentId,
                componentStore.inputType
            ) as ARNOptionFormInputStore

        return (
            <FormOptionsPageContent
                arnOptionFormInputStore={arnOptionFormInputStore}
                isMultipleChoice={isMultipleChoice}
                isInputPage={true}
            />
        )
    }
)

const FormOptionsPageContent = observer(
    (props: {
        arnOptionFormInputStore: ARNOptionFormInputStore
        isMultipleChoice: boolean
        isInputPage?: boolean
    }) => {
        const { arnOptionFormInputStore, isMultipleChoice, isInputPage } = props
        const theme = useTheme()
        const styles = React.useMemo(() => createStyles(theme), [theme])

        const renderOptionItem = (item: Option, index: number) => {
            if (isMultipleChoice) {
                return (
                    <MultiChoiceItem
                        item={item}
                        index={index}
                        key={item.content}
                        isHaveBottomLine={true}
                        arnOptionFormInputStore={arnOptionFormInputStore}
                    />
                )
            } else {
                return (
                    <SingleChoiceItem
                        item={item}
                        index={index}
                        key={item.content}
                        isHaveBottomLine={true}
                        arnOptionFormInputStore={arnOptionFormInputStore}
                    />
                )
            }
        }

        return (
            <View style={styles.wrap}>
                <SearchFormPage inputType="options" />
                <ScrollView
                    style={styles.viewOptionsFormInput}
                    showsVerticalScrollIndicator={false}
                >
                    {arnOptionFormInputStore.options &&
                    arnOptionFormInputStore.options.length > 0 ? (
                        <View style={styles.listOptions}>
                            {arnOptionFormInputStore.options.map(
                                (item, index) => renderOptionItem(item, index)
                            )}
                        </View>
                    ) : null}
                    <AddOptionFormInput
                        arnOptionFormInputStore={arnOptionFormInputStore}
                        isMultipleChoice={isMultipleChoice}
                        isInputPage={isInputPage}
                    />
                </ScrollView>
            </View>
        )
    }
)

export default FormOptionsPage

const createStyles = ({ colorScheme }: Theme) =>
    StyleSheet.create({
        wrap: { flex: 1 },
        listOptions: { marginTop: 11 },
        imageClose: { width: 24, height: 24, resizeMode: 'contain' },
        buttonClose: { position: 'absolute', top: 22, right: 10 },
        viewTitle: { flexDirection: 'row', alignItems: 'center' },
        requireDot: {
            height: 6,
            width: 6,
            backgroundColor: 'red',
            borderRadius: 6,
            marginLeft: 6,
        },
        viewOptionsFormInput: {
            backgroundColor: colorScheme.backgroundL1,
            paddingLeft: appConstants.PADDING_COMMON,
            borderBottomWidth: 1,
            borderBottomColor: colorScheme.separator,
        },
        title: {
            fontSize: 16,
            lineHeight: 19,
            fontWeight: '500',
        },
    })
