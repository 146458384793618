import { View, Text, Image, StyleSheet } from 'react-native'
import React, { useMemo } from 'react'
import { Theme, useTheme } from '../../styles/theme'
import {
    CollectionPageStore,
    CustomPageStore,
    FormPageStore,
} from '@appformula/app-studio-core/src'
import { useBinding } from '../collection/components/hooks/useBinding'
import { COLORS } from '../../assets/colors'
import { pagesIcon } from '../../assets/icons/icon-pages'
import { observer } from 'mobx-react-lite'
import { DEFAULT_FP_ICON, DEFAULT_FP_TITLE } from '../../constants/const'

interface IProps {
    pageStore: CollectionPageStore | CustomPageStore | FormPageStore
    focused: boolean
    isLargeDevice: boolean
}
const TabBarIconStore = observer((props: IProps) => {
    const { pageStore, focused, isLargeDevice } = props
    const theme = useTheme()
    const styles = useMemo(() => createStyles(theme, focused), [theme, focused])

    const badgeNumber = useBinding(pageStore?.badgeNumber)
    const badgeString = badgeNumber > 99 ? `99+` : `${badgeNumber}`
    const rightPostion = badgeNumber > 0 ? badgeString?.length * 4 + 6 : 0

    const label = useBinding(pageStore?.title) || DEFAULT_FP_TITLE

    if (isLargeDevice) {
        return (
            <View style={styles.viewLarge}>
                <Image
                    source={pagesIcon[pageStore?.pageIcon || DEFAULT_FP_ICON]}
                    style={styles.imageLarge}
                />
                <Text
                    style={styles.labelLarge}
                    numberOfLines={1}
                    ellipsizeMode={'tail'}
                >
                    {label}
                </Text>
                {badgeNumber > 0 && (
                    <Text style={styles.txtBadgeLarge}>{badgeString}</Text>
                )}
            </View>
        )
    } else
        return (
            <View style={styles.viewSmall}>
                <View>
                    <Image
                        source={
                            pagesIcon[pageStore?.pageIcon || DEFAULT_FP_ICON]
                        }
                        style={styles.imageSmall}
                    />
                    {badgeNumber > 0 && (
                        <Text
                            style={[styles.txtBadge, { right: -rightPostion }]}
                        >
                            {badgeString}
                        </Text>
                    )}
                </View>
                <Text
                    style={styles.labelSmall}
                    numberOfLines={1}
                    ellipsizeMode={'tail'}
                >
                    {label}
                </Text>
            </View>
        )
})

export default TabBarIconStore

const createStyles = ({ colorScheme }: Theme, focused: boolean) => {
    return StyleSheet.create({
        imageLarge: {
            height: 34,
            width: 34,
            tintColor: focused ? COLORS.WHITE : colorScheme.labelSecondary,
        },
        imageSmall: {
            height: 34,
            width: 34,
            tintColor: focused
                ? colorScheme.accentColor
                : colorScheme.labelSecondary,
        },
        labelLarge: {
            marginHorizontal: 10,
            fontSize: 15,
            fontWeight: '500',
            flex: 1,
            color: focused ? COLORS.WHITE : colorScheme.labelPrimary,
        },
        labelSmall: {
            fontSize: 11,
            fontWeight: '500',
            flex: 1,
            color: focused
                ? colorScheme.accentColor
                : colorScheme.labelSecondary,
        },
        viewLarge: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        viewSmall: {
            flex: 1,
            paddingHorizontal: 4,
            justifyContent: 'center',
            alignItems: 'center',
        },
        txtBadge: {
            color: COLORS.WHITE,
            fontSize: 14,
            alignSelf: 'flex-end',
            lineHeight: 17.75,
            height: 18.75,
            minWidth: 19.75,
            position: 'absolute',
            backgroundColor: COLORS.BADGE,
            textAlign: 'center',
            paddingHorizontal: 4,
            overflow: 'hidden',
            borderRadius: 9.375,
            top: 3,
            right: -6,
        },
        txtBadgeLarge: {
            color: COLORS.WHITE,
            fontSize: 14,
            lineHeight: 17.75,
            height: 18.75,
            minWidth: 19.75,
            backgroundColor: COLORS.BADGE,
            textAlign: 'center',
            paddingHorizontal: 4,
            overflow: 'hidden',
            borderRadius: 9.375,
        },
    })
}
