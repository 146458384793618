import {
    BindableType,
    BindingTypeName,
    BindingValue,
} from '@appformula/app-descriptor/src'

export const TransformConditionBindingToSql = (
    columnName: string,
    value: BindingValue,
    typeName: BindingTypeName
) => {
    if (
        typeName === 'boolean' ||
        typeName === 'date' ||
        typeName === 'string'
    ) {
        return `\`${columnName}\` = '${value}'`
    } else if (typeName === 'number') {
        return `\`${columnName}\` = ${value}`
    } else {
        const listSubCondition: string[] = []
        const values = value as BindableType[]

        values.forEach((value: BindableType) => {
            listSubCondition.push(`\`${columnName}\` LIKE '%${value}%'`)
        })
        return `(${listSubCondition.join(' OR ')})`
    }
}
