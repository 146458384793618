import { Nullable } from '@appformula/shared-foundation-x/src'
import { action, makeObservable, observable } from 'mobx'

export class SearchCollectionStore {
    valueSearchString: Nullable<string>
    private collectionPageIdValue: Nullable<string>

    get collectionPageId(): string {
        return this.collectionPageIdValue ?? ''
    }
    constructor(collectionPageId: string, value?: string) {
        this.collectionPageIdValue = collectionPageId
        this.valueSearchString = value ?? ''
        makeObservable(this, {
            valueSearchString: observable,
            setValueSearch: action,
        })
    }

    setValueSearch(result: string) {
        this.valueSearchString = result
    }
}
