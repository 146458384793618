//
//  AppMetadataContext.tsx
//
//  Created by thaitd96 on 2022-08-29 11:00.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React from 'react'
import buildConfig from '../../buildConfig.json'

type AppMetadata = {
    teamId: string
    appId: string
}

const appMetadata = {
    teamId: buildConfig.teamId,
    appId: buildConfig.appId,
}

export const AppMetadataContext: React.Context<AppMetadata> =
    React.createContext<AppMetadata>(appMetadata)
