//
//  Audio.tsx
//
//  Created by thaitd96 on 2022-20-04 11:38.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { AudioStore } from '@appformula/app-studio-core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Image, Pressable, StyleSheet, Text, View } from 'react-native'
import {
    AVPlaybackStatus,
    Audio as AudioExpo,
    AVPlaybackStatusSuccess,
    AVPlaybackStatusError,
} from 'expo-av'
import image from '../../../assets/icons'
import { observer } from 'mobx-react-lite'
import appConstants from '../../../constants/const'
import { Theme, useTheme } from '../../../styles/theme'
import { millisToMinutesAndSeconds } from '../../../utils'
import { useBindingCommon } from '../../collection/components/hooks/useBindingCommon'
import { useWidthHeightMainLayout } from '../../collection/components/hooks/useSpecifyPlatformWindowDimensions'

export const Audio = observer(
    (props: {
        audio: AudioStore
        item: Record<string, unknown>
        isViewHorizontal?: boolean
    }) => {
        const { audio, item, isViewHorizontal } = props
        const audioData = audio.object || audio
        const { width } = useWidthHeightMainLayout()
        const theme = useTheme()
        const styles = useMemo(
            () => createStyles(theme, width, isViewHorizontal),
            [theme, width, isViewHorizontal]
        )
        const [status, setStatus] = useState<AVPlaybackStatus>()
        const [sound, setSound] = useState<AudioExpo.Sound>()
        const [duration, setDuration] = useState('00:00')
        const audioURL = useBindingCommon(audioData?.audioURL, item) || ''
        const title = useBindingCommon(audioData?.title, item) || ''
        const isHidden = useBindingCommon(audioData?.isHidden, item)

        useEffect(() => {
            const enableAudio = async () => {
                await AudioExpo.setAudioModeAsync({
                    allowsRecordingIOS: false,
                    playsInSilentModeIOS: false,
                    staysActiveInBackground: false,
                    shouldDuckAndroid: false,
                })
            }
            enableAudio()
                .then(() => {
                    //
                })
                .catch((error) => {
                    console.log('enableAudio', error)
                })
        }, [])

        const loadSound = useCallback(async () => {
            if (audioURL) {
                const { sound } = await AudioExpo.Sound.createAsync(
                    {
                        uri: audioURL as string,
                    },
                    {
                        isLooping: true,
                        positionMillis: 10,
                    },
                    onPlaybackStatusUpdate
                )
                setSound(sound)
            }
        }, [audioURL])

        useEffect(() => {
            loadSound()
        }, [audioURL, loadSound])

        useEffect(() => {
            // unload the sound from memory
            // when you are done using the Sound object
            return sound
                ? () => {
                      console.log('Unloading Sound')
                      sound.unloadAsync()
                  }
                : undefined
        }, [sound])

        const onPlaybackStatusUpdate = async (
            statusPlayback: AVPlaybackStatus
        ) => {
            if (!statusPlayback.isLoaded) {
                // Update your UI for the unloaded state
                if ((statusPlayback as AVPlaybackStatusError).error) {
                    console.log(
                        `Encountered a fatal error during playback: ${
                            (statusPlayback as AVPlaybackStatusError).error
                        }`
                    )
                }
            } else {
                const durationMillis =
                    statusPlayback?.durationMillis ||
                    statusPlayback?.playableDurationMillis ||
                    0
                const positionMillis = statusPlayback.positionMillis

                setDuration(
                    millisToMinutesAndSeconds(durationMillis - positionMillis)
                )
            }
            setStatus(statusPlayback)
        }

        const playSound = async () => {
            if (sound) {
                const statusSuccess = status as AVPlaybackStatusSuccess
                statusSuccess?.isPlaying
                    ? sound.pauseAsync()
                    : sound.playAsync()
            }
        }

        return (
            !isHidden && (
                <View style={styles.container}>
                    <View style={styles.viewAudio}>
                        <View style={styles.containerTitle}>
                            <Image
                                source={image.headphone}
                                style={styles.imgHeadphone}
                            />
                            <View style={styles.wrapContentText}>
                                <Text style={styles.txtTitle} numberOfLines={1}>
                                    {title}
                                </Text>
                                <Text style={styles.txtTime}>{duration}</Text>
                            </View>
                        </View>
                        <Pressable
                            style={styles.btnPlayback}
                            onPress={playSound}
                            hitSlop={20}
                        >
                            {(status as AVPlaybackStatusSuccess)?.isPlaying ? (
                                <Image
                                    source={image.pause}
                                    style={styles.imgPause}
                                />
                            ) : (
                                <Image
                                    source={image.playback}
                                    style={styles.imgPlayback}
                                />
                            )}
                        </Pressable>
                    </View>
                </View>
            )
        )
    }
)

const createStyles = (
    { colorScheme, roundness, typography }: Theme,
    width: number,
    isViewHorizontal?: boolean
) =>
    StyleSheet.create({
        wrapContentText: { flex: 1, marginRight: 10 },
        container: {
            backgroundColor: colorScheme.backgroundL2,
            borderRadius: roundness,
            marginVertical: appConstants.PADDING_COMMON / 2,
            width: isViewHorizontal ? width - 100 : null,
        },

        viewAudio: {
            padding: appConstants.PADDING_COMMON,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            flex: 1,
        },

        imgHeadphone: {
            width: 34,
            height: 34,
            marginRight: 10,
        },

        containerTitle: {
            flexDirection: 'row',
            flex: 1,
        },

        imgPlayback: {
            width: 20,
            height: 20,
            margin: 4,
        },

        imgPause: {
            width: 20,
            height: 20,
            margin: 4,
        },

        txtTitle: {
            fontWeight: '500',
            fontSize: 15,
            lineHeight: 18,
            color: colorScheme.labelPrimary,
            marginBottom: 6,
            flex: 1,
        },

        txtTime: {
            fontWeight: '500',
            fontSize: 14,
            lineHeight: 17,
            color: colorScheme.labelSecondary,
        },

        btnPlayback: {
            backgroundColor: colorScheme.accentColor,
            borderRadius: 50,
        },
    })
