import _ from 'lodash'
import { Dimensions, Platform } from 'react-native'
import AddressAutocomplete, {
    AddressDetails,
} from 'react-native-address-autocomplete'
import MapApi, { IReverseGeocodeItem } from '../network/extensions/MapApi'
const { width, height } = Dimensions.get('window')

const ASPECT_RATIO = width / height

const DEFAULT_DELTA_REGION = {
    latitudeDelta: 0.0922,
    longitudeDelta: 0.0922 * ASPECT_RATIO,
}

export interface IResponseReverseGeocode {
    query: string
    data: {
        coordinate: {
            latitude: number
            longitude: number
        }
        region: {
            latitude: number
            latitudeDelta: number
            longitude: number
            longitudeDelta: number
        }
    }
}

export const getGeocodingWithMapKit = async (
    description: string
): Promise<AddressDetails> => {
    return new Promise((resolve, reject) => {
        console.info('description===', description)

        AddressAutocomplete.getAddressDetails(description)
            .then((response: AddressDetails) => {
                resolve(response)
            })
            .catch((error) => {
                console.info('ERROR GET GEOCODING', error)
                reject({})
            })
    })
}

export const reverseGeocodeFromApi = async (
    addresses: string[]
): Promise<IResponseReverseGeocode[]> => {
    try {
        if (Platform.OS === 'ios') {
            const listPromise = addresses.map((item) =>
                getGeocodingWithMapKit(item)
            )

            const response = await Promise.all(listPromise)

            const result = response.map((item, index) => {
                const data: IResponseReverseGeocode = {
                    query: addresses[index],
                    data: {
                        ...item,
                    },
                }
                return data
            })
            return result
        } else {
            const addressesString = addresses.join(';')
            const response = await MapApi.reverseGeocode(addressesString)
            const result: IResponseReverseGeocode[] = []
            response.data.results?.forEach((item: IReverseGeocodeItem) => {
                if (!_.isEmpty(item.data)) {
                    const res = {
                        ...item,
                        data: {
                            ...item.data,
                            region: {
                                latitude: item.data?.coordinate?.latitude,
                                longitude: item.data?.coordinate?.longitude,
                                latitudeDelta:
                                    DEFAULT_DELTA_REGION.latitudeDelta,
                                longitudeDelta:
                                    DEFAULT_DELTA_REGION.longitudeDelta,
                            },
                        },
                    }
                    result.push(res)
                }
            })
            return result
        }
    } catch (error) {
        console.info('reverseGeocodeFromApi------', error)
        return []
    }
}
