//
//  MainPage.tsx
//
//  Created by Peter Vu on 2022-03-28 11:06:11.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React, { useContext, useEffect, useState } from 'react'
import { MainPageStore, MainPageAuthInfo } from './MainPageStore'
import { observer } from 'mobx-react-lite'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import screenNames from '../../navigations/screenNames'
import { AppDescriptorContext } from '../../context/AppDescriptorContext'
import { FormPage } from '../form/FormPage'
import { MainPageDataStoreContext } from '../../context/MainPageDataStoreContext'
import { AppMetadataContext } from '../../context/AppMetadataContext'
import { DetailPage } from '../details/DetailPage'
import { DesignPageContext } from '../../context/DesignPageContext'
import { CollectionPage } from '../collection/CollectionPage'
import SettingPage from '../SettingPage'
import EditProfile from '../EditProfile'
import TabView from './tab-view/TabView'
import { CustomPageRoute } from '../custom/CustomPageRoute'
import { useWidthHeightFull } from '../collection/components/hooks/useSpecifyPlatformWindowDimensions'
import { isLargeDevice } from '../../utils'
import TabViewTablet from './tab-view/TabViewTablet'
import { TestFormula } from '../test-formula/TestFormula'
import cache from '../../utils/cache'
import { AppAuthenticationContext } from '../../context/AppAuthenticationContext'
import { Platform } from 'react-native'
import CDPApi from '../../utils/network/extensions/CDPApi'
import { Subscription } from 'rxjs'
import { AppDatabaseProvider } from '../../context/AppDatabaseContext'
import { WelcomePage } from '../authentication/WelcomePage'

const Stack = createNativeStackNavigator()

export const MainPageAuthContext: React.Context<MainPageAuthInfo> =
    React.createContext<MainPageAuthInfo>(null as unknown as MainPageAuthInfo)

export const MainPage = observer((props: { mainPageStore: MainPageStore }) => {
    const { mainPageStore } = props

    const { width } = useWidthHeightFull()
    const { teamId, appId } = useContext(AppMetadataContext)
    const appAuthenticationStore = useContext(AppAuthenticationContext)
    const [isSyncDone, setSyncDone] = useState(false)

    useEffect(() => {
        CDPApi.userEvent({
            team_id: teamId,
            app_id: appId,
            name: 'application_open',
            user_id: appAuthenticationStore?.authenticatedUser?.uid || '',
            platform: Platform.OS,
        })
    }, [appId, teamId])

    useEffect(() => {
        const handleSync = async () => {
            try {
                await mainPageStore.handleSync(teamId, appId)
                setSyncDone(true)
            } catch (error) {
                console.warn(error)
            }
        }
        handleSync()
    }, [appId, mainPageStore, teamId])

    useEffect(() => {
        let subscriptions: Subscription[] = []

        if (cache.sourceStartUp === 'APP_NORMAL') {
            mainPageStore.handlePushToken(teamId, appId)
            if (Platform.OS !== 'web') {
                subscriptions =
                    mainPageStore.startListeningLocationWebhooksChange()
                mainPageStore.registerLocationWebhooks(teamId, appId)
            }
        }

        return () => {
            subscriptions.forEach((sub) => sub.unsubscribe())
            mainPageStore.webhooksFirestoreUnsubscribe?.()
        }
    }, [appId, mainPageStore, teamId])

    const TabNavigator = () => {
        return (
            <AppDescriptorContext.Consumer>
                {(appDescriptorStore) => (
                    <DesignPageContext.Consumer>
                        {(designPageStore) => {
                            if (isLargeDevice(width)) {
                                return (
                                    <TabViewTablet
                                        appDescriptorStore={appDescriptorStore}
                                        designPageStore={designPageStore}
                                    />
                                )
                            } else {
                                return (
                                    <TabView
                                        appDescriptorStore={appDescriptorStore}
                                        designPageStore={designPageStore}
                                    />
                                )
                            }
                        }}
                    </DesignPageContext.Consumer>
                )}
            </AppDescriptorContext.Consumer>
        )
    }

    return (
        <MainPageDataStoreContext.Provider value={mainPageStore.dataStore}>
            <MainPageAuthContext.Provider value={mainPageStore.authInfo}>
                {isSyncDone && mainPageStore.schema(teamId, appId).database && (
                    <AppDatabaseProvider
                        database={mainPageStore.schema(teamId, appId).database}
                    >
                        <Stack.Navigator>
                            <Stack.Screen
                                name={screenNames.TAB_NAVIGATOR}
                                component={TabNavigator}
                                options={{ headerShown: false }}
                            />
                            <Stack.Screen
                                name={screenNames.FORM_PAGE}
                                component={FormPage}
                                options={{
                                    headerShown: false,
                                    presentation: 'modal',
                                }}
                            />
                            <Stack.Screen
                                name={screenNames.DETAIL_SCREEN}
                                component={DetailPage}
                                options={{
                                    headerShown: false,
                                }}
                            />
                            <Stack.Screen
                                name={screenNames.CUSTOM_SCREEN}
                                component={CustomPageRoute}
                                options={{
                                    headerShown: false,
                                }}
                            />
                            <Stack.Screen
                                name={screenNames.COLLECTION_PAGE}
                                component={CollectionPage}
                                options={{
                                    headerShown: false,
                                }}
                            />
                            <Stack.Screen
                                name={screenNames.SETTING_SCREEN}
                                component={SettingPage}
                            />
                            <Stack.Screen
                                name={screenNames.EDIT_PROFILE_SCREEN}
                                component={EditProfile}
                                options={{
                                    headerShown: false,
                                    presentation: 'modal',
                                }}
                            />
                            <Stack.Screen
                                name={screenNames.TEST_FORMULA}
                                component={TestFormula}
                            />
                            <Stack.Screen
                                name={screenNames.WELCOME_PAGE}
                                component={WelcomePageRoute}
                                options={{
                                    headerShown: false,
                                }}
                            />
                        </Stack.Navigator>
                    </AppDatabaseProvider>
                )}
            </MainPageAuthContext.Provider>
        </MainPageDataStoreContext.Provider>
    )
})

const WelcomePageRoute = () => {
    const appStore = React.useContext(AppAuthenticationContext)
    if (appStore) {
        return <WelcomePage store={appStore.welcomePageStore()} />
    }
    return null
}
