//
//  FirebaseInit.web.ts
//
//  Created by thaitd96 on 2022-08-16 17:15
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { ReactNativeFirebase } from '@react-native-firebase/app'
import firebase from 'firebase/app'

const defaultConfig = {
    apiKey: 'AIzaSyBnsR7rKqAjmHqnIxE-2fFTWyBzIt2kMeY',
    authDomain: 'appformula-dev.firebaseapp.com',
    projectId: 'appformula-dev',
    storageBucket: 'appformula-dev.appspot.com',
    messagingSenderId: '540718492053',
    appId: '1:540718492053:web:c2e84b4f87b2bb6111ae9c',
    measurementId: 'G-E8F4JQ3DR4',
}

// Force return type is ReactNativeFirebase.FirebaseApp to unified type on app-react-native
const initializeApp = async (
    firebaseConfig = defaultConfig
): Promise<ReactNativeFirebase.FirebaseApp> => {
    if (!firebase.apps.length) {
        // @ts-ignore
        return firebase.initializeApp(firebaseConfig)
    } else {
        // @ts-ignore
        return firebase.app() // if already initialized, use that one
    }
}

export default initializeApp
