import { View, Text, Image, StyleSheet, Pressable } from 'react-native'
import React, { useContext, useMemo } from 'react'
import { AppDescriptorContext } from '../../context/AppDescriptorContext'
import { pagesIcon } from '../../assets/icons/icon-pages'
import { useBinding } from '../collection/components/hooks/useBinding'
import { observer } from 'mobx-react-lite'
import {
    CollectionPageStore,
    CustomPageStore,
    DesignPageStore,
    FormPageStore,
    NormalPageSelection,
} from '@appformula/app-studio-core/src'
import { Theme, useTheme } from '../../styles/theme'
import image from '../../assets/icons'
import { COLORS } from '../../assets/colors'
import { useNavigation } from '@react-navigation/native'
import screenNames from '../../navigations/screenNames'
import { DesignPageContext } from '../../context/DesignPageContext'
import { DEFAULT_FP_ICON, DEFAULT_FP_TITLE } from '../../constants/const'

interface IItemTabView {
    pageId: string
}

const ItemTabView = (props: IItemTabView) => {
    const { pageId } = props
    const store = useContext(AppDescriptorContext)
    const designPageStore = useContext(DesignPageContext)
    const pageStore = store.pageStore(pageId)
    return pageStore ? (
        <ItemTabViewContent
            pageStore={pageStore}
            designPageStore={designPageStore}
        />
    ) : null
}

const ItemTabViewContent = observer(
    ({
        pageStore,
        designPageStore,
    }: {
        pageStore: CollectionPageStore | CustomPageStore | FormPageStore
        designPageStore: DesignPageStore
    }) => {
        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])
        const title = useBinding(pageStore?.title) || DEFAULT_FP_TITLE
        const badgeNumber = useBinding(pageStore?.badgeNumber)
        const navigation = useNavigation()

        const changePageSelectionOnStudio = (pageId: string) => {
            if (designPageStore) {
                designPageStore.pageSelection = new NormalPageSelection(pageId)
            }
        }

        const onPress = () => {
            const pageId = pageStore?.pageId

            if (pageStore instanceof CollectionPageStore) {
                navigation.navigate(
                    screenNames.COLLECTION_PAGE as never,
                    {
                        pageId,
                        showBackButton: true,
                        fromMorePage: true,
                    } as never
                )
                changePageSelectionOnStudio(pageId)
            } else if (pageStore instanceof CustomPageStore) {
                navigation.navigate(
                    screenNames.CUSTOM_SCREEN as never,
                    {
                        pageId,
                        showBackButton: true,
                        fromMorePage: true,
                    } as never
                )
                changePageSelectionOnStudio(pageId)
            } else if (pageStore instanceof FormPageStore) {
                navigation.navigate(
                    screenNames.FORM_PAGE as never,
                    {
                        pageId,
                        showBackButton: true,
                        fromMorePage: true,
                    } as never
                )
                changePageSelectionOnStudio(pageId)
            }
        }
        return (
            <Pressable onPress={onPress}>
                <View style={styles.container}>
                    <Image
                        source={
                            pagesIcon[pageStore?.pageIcon || DEFAULT_FP_ICON]
                        }
                        style={styles.ic}
                    />
                    <View style={styles.wrapContentTitle}>
                        <Text style={styles.txtTitle}>{title}</Text>
                        <View style={styles.wrapContentRight}>
                            {badgeNumber > 0 && (
                                <View style={styles.wrapBadge}>
                                    <Text style={styles.txtBadge}>
                                        {badgeNumber}
                                    </Text>
                                </View>
                            )}

                            <Image
                                source={image.arrowRight}
                                style={styles.icArrowRight}
                            />
                        </View>
                    </View>
                </View>
            </Pressable>
        )
    }
)

export default ItemTabView

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        wrapContentTitle: {
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottomColor: colorScheme.separator,
            borderBottomWidth: 1,
            paddingRight: 8,
        },
        icArrowRight: { width: 20, height: 20 },
        wrapContentRight: { flexDirection: 'row', alignItems: 'center' },
        container: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        txtTitle: {
            color: colorScheme.labelPrimary,
            paddingVertical: 19,
            fontSize: 15,
            fontWeight: '500',
            lineHeight: 18.15,
        },

        wrapBadge: {
            backgroundColor: colorScheme.error,
            height: 24,
            minWidth: 23,
            borderRadius: 12,
            justifyContent: 'center',
            marginRight: 8,
        },

        txtBadge: {
            color: COLORS.WHITE,
            fontSize: 13,
            lineHeight: 16,
            paddingHorizontal: 8,
        },
        ic: {
            width: 34,
            height: 34,
            marginHorizontal: 16,
            tintColor: colorScheme.labelSecondary,
        },
    })
