import { apiClient } from '../index'
import { CDP_URL } from '../apis/CDPUrl'

type NameType = 'application_open' | ''

export type IData = {
    name: NameType
    user_id?: string
    team_id?: string
    platform?: string
    app_id?: string
    // properties: string
}

export default {
    userEvent: async (data: IData): Promise<void> => {
        try {
            await apiClient.post(`${CDP_URL.userEvent}`, {
                data,
                isHandleError: false,
            })
        } catch (error) {
            // console.info('userEvent', error)
        }
    },
}
