import { LayoutChangeEvent, View } from 'react-native'
import React, { forwardRef, useImperativeHandle, useState } from 'react'

interface ILabelContainer {
    renderContent: (value: number) => React.ReactElement
    onLayout: (event: LayoutChangeEvent) => void
}

const LabelContainer = forwardRef(
    ({ renderContent, onLayout, ...otherProps }: ILabelContainer, ref) => {
        const [value, changeValue] = useState<number>(0)

        useImperativeHandle(ref, () => ({
            setValue: () => {
                return changeValue(value)
            },
        }))
        return <View {...otherProps}>{renderContent(value)}</View>
    }
)

LabelContainer.displayName = 'LabelContainer'

export default LabelContainer