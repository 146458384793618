import {
    View,
    Text,
    StyleSheet,
    Pressable,
    ActivityIndicator,
    Image,
} from 'react-native'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Theme, useTheme } from '../../../../../styles/theme'
import appConstants from '../../../../../constants/const'
import image from '../../../../../assets/icons'
import file from '../../../../../utils/file/index.web'
import { Alert } from '../../../../../components/alert'
import { FORM_URL } from '../../../../../utils/network/apis/FormUrl'
import IconFile from '../IconFile'
import { ResponseUpload, ResponseUploadData } from '../../../../../utils'

export interface IFileWeb {
    id: string
    name: string
    type: string
    size: string
}

interface IItemFile {
    item: IFileWeb
    onRemove?: (file: IFileWeb) => void
    onUploadFileSuccess: (url: string) => void
}

const ItemFile: React.FC<IItemFile> = (props: IItemFile) => {
    const { item, onRemove, onUploadFileSuccess } = props
    const [failed, setFailed] = useState(false)
    const [loading, setLoading] = useState(true)
    const theme = useTheme()
    const styles = useMemo(() => createStyles(theme), [theme])

    const viewFile = () => {
        //
    }

    const _removeFile = (item: IFileWeb) => () => {
        onRemove && onRemove(item)
    }

    const uploadPhoto = useCallback(() => {
        file.uploadFile({ data: item, url: FORM_URL.uploadFile })
            .then((response) => {
                setFailed(false)
                setLoading(false)

                const url =
                    (
                        JSON.parse(
                            (response as ResponseUpload).data
                        ) as ResponseUploadData
                    ).assetUrl ?? ''

                onUploadFileSuccess(url)
            })
            .catch((err) => {
                setFailed(true)
                setLoading(false)
            })
    }, [item, onUploadFileSuccess])

    useEffect(() => {
        const idQ = `${item.id}`
        if (file.isUploadActive(idQ)) {
            uploadPhoto()
        }
    }, [uploadPhoto, item.id])

    const onRetry = () => {
        Alert.alert(
            'Notification',
            'Are you sure to retry upload these photos ?',
            [
                {
                    text: 'Cancel',
                    onPress: () => {
                        //
                    },
                    style: 'cancel',
                },
                {
                    text: 'OK',
                    onPress: () => confirmRetry(),
                },
            ]
        )
    }

    const confirmRetry = () => {
        setLoading(true)
        uploadPhoto()
    }

    return (
        <Pressable
            key={item.id}
            style={styles.wrapperItem}
            onPress={viewFile}
            hitSlop={10}
        >
            <View style={styles.containerItemTitle}>
                <IconFile type={item.type} />
                <Text
                    style={styles.titleItem}
                    numberOfLines={1}
                    ellipsizeMode="tail"
                >
                    {item.name}
                </Text>
                {failed && (
                    <Pressable onPress={onRetry}>
                        <Text style={styles.txtError}>FAILED</Text>
                    </Pressable>
                )}
            </View>

            {loading ? (
                <ActivityIndicator />
            ) : (
                <Pressable onPress={_removeFile(item)}>
                    <Image source={image.close} style={styles.imgClose} />
                </Pressable>
            )}
        </Pressable>
    )
}

export default ItemFile

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        imgClose: { width: 24, height: 24 },
        titleItem: {
            flex: 1,
            marginLeft: 12,
            fontWeight: '400',
            fontSize: 15,
            lineHeight: 18.15,
            color: colorScheme.labelPrimary,
        },
        containerItemTitle: {
            flexDirection: 'row',
            flex: 1,
            alignItems: 'center',
        },
        wrapperItem: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingHorizontal: appConstants.PADDING_COMMON,
            height: 54,
        },
        imageSize: {},
        txtError: {
            color: colorScheme.error,
        },
    })
