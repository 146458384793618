//
//  OptionsFormInputPage.tsx
//
//  Created by thaitd96 on 2022-06-24 10:26.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { OptionsFormInputStore } from '@appformula/app-studio-core'
import appConstants from '../../../../../constants/const'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { Image, Pressable, StyleSheet, Text, View } from 'react-native'
import { Theme, useTheme } from '../../../../../styles/theme'
import image from '../../../../../assets/icons'
import { useNavigation } from '@react-navigation/native'
import screenNames from '../../../../../navigations/screenNames'
import { ARNOptionFormInputStore } from '../ARNOptionFormInputStore'
import { useBinding } from '../../../../collection/components/hooks/useBinding'

export type IOption = {
    id: string
    content: string
}

export const OptionsFormInputPage = observer(
    (props: {
        optionsFormInput: OptionsFormInputStore
        arnOptionFormInputStore: ARNOptionFormInputStore
    }) => {
        const { optionsFormInput, arnOptionFormInputStore } = props
        const theme = useTheme()
        const styles = React.useMemo(() => createStyles(theme), [theme])
        const navigation = useNavigation()
        const isHidden = useBinding(optionsFormInput?.isHidden)

        const goToPageOptions = () => {
            navigation &&
                navigation.navigate(
                    screenNames.FORM_OPTIONS as never,
                    { componentId: optionsFormInput.componentId } as never
                )
        }

        return isHidden ? null : (
            <Pressable
                style={styles.viewOptionsFormInput}
                onPress={goToPageOptions}
            >
                <View style={styles.viewTitle}>
                    <Text style={styles.title}>
                        {optionsFormInput.titleName}
                    </Text>
                    {optionsFormInput.required && (
                        <View style={styles.requireDot}></View>
                    )}
                </View>
                <View style={styles.viewSelected}>
                    <Text
                        style={styles.selected}
                        numberOfLines={1}
                        ellipsizeMode="tail"
                    >
                        {arnOptionFormInputStore.selectedOptionsString}
                    </Text>
                    <Image
                        source={image.arrowRight}
                        style={styles.image}
                    ></Image>
                </View>
            </Pressable>
        )
    }
)
const createStyles = ({ colorScheme }: Theme) =>
    StyleSheet.create({
        viewSelected: {
            flexDirection: 'row',
            flex: 1,
            alignItems: 'center',
            marginLeft: 12,
        },
        image: {
            height: 20,
            width: 20,
            resizeMode: 'contain',
            tintColor: colorScheme.labelSecondary,
        },
        selected: {
            fontSize: 15,
            color: colorScheme.labelSecondary,
            fontWeight: '500',
            flex: 1,
            textAlign: 'right',
        },
        viewTitle: { flexDirection: 'row', alignItems: 'center' },
        requireDot: {
            height: 6,
            width: 6,
            backgroundColor: 'red',
            borderRadius: 6,
            marginLeft: 6,
        },
        viewOptionsFormInput: {
            paddingLeft: appConstants.PADDING_COMMON,
            paddingRight: 8,
            height: 68,
            borderBottomWidth: 1,
            borderBottomColor: colorScheme.separator,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        title: {
            fontSize: 16,
            lineHeight: 19,
            fontWeight: '500',
            color: colorScheme.labelPrimary,
        },
    })
