//
//  App.tsx
//
//  Created by Peter Vu on 2022-03-28 11:01:31.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//
import 'fastestsmallesttextencoderdecoder'
import './polyfill'
import { LogBox, Platform, StyleSheet, View } from 'react-native'
import { observer } from 'mobx-react-lite'
import { AppStore } from './AppStore'
import auth from '@react-native-firebase/auth'
import { GestureHandlerRootView } from 'react-native-gesture-handler'
import RootStackNavigation from '../navigations/RootStackNavigation'
import appConstants from '../constants/const'
import * as React from 'react'
import { MenuProvider } from 'react-native-popup-menu'
import buildConfig from '../../buildConfig.json'

//https://stackoverflow.com/a/70996361/1992881
import 'react-native-reanimated'
import {
    AppDescriptorStore,
    DesignPageStore,
} from '@appformula/app-studio-core/src'
import { AppDescriptorContext } from '../context/AppDescriptorContext'
import initializeApp from '../utils/firebase/firebase-init/FirebaseInit'
import initializeAppSDK from '../utils/firebase/firebase-init/FirebaseInit.web'
import { Loading } from '../components'
import { BottomSheetModalProvider } from '@gorhom/bottom-sheet'
import { AppMetadataContext } from '../context/AppMetadataContext'
import { DesignPageContext } from '../context/DesignPageContext'
import WrapContextAppGo from './wrap-context/WrapContextAppGo'
import WrapContextApp from './wrap-context/WrapContextApp'
import { Nullable } from '@appformula/shared-foundation-x/src'
import cache from '../utils/cache'
import { DefaultTheme, NavigationContainer } from '@react-navigation/native'
import { useTheme } from '../styles/theme'
import WrapContextDesktop from './wrap-context/wrap-context-desktop/WrapContextDesktop'
import { wrapContextAppDesktopStore } from './wrap-context/wrap-context-desktop/WrapContextAppDesktopStore'
import { ActionSheetProvider } from '@expo/react-native-action-sheet'
export type SourceStartUp = 'APP_FORMULA_GO' | 'APP_EDITOR' | 'APP_NORMAL'

const _App = function App(props: {
    descriptorStore: AppDescriptorStore
    designPageStore: DesignPageStore
    teamId: string
    appId: string
    sourceStartUp: SourceStartUp
    initDescriptor: string
}) {
    React.useEffect(() => {
        if (props.teamId && props.appId) {
            // @ts-ignore: global variable
            global.teamId = props.teamId
            // @ts-ignore: global variable
            global.appId = props.appId
        } else {
            // @ts-ignore: global variable
            global.teamId = buildConfig.teamId
            // @ts-ignore: global variable
            global.appId = buildConfig.appId
        }
    }, [props.appId, props.teamId])

    // Ignore some warning from firebase
    LogBox.ignoreLogs(appConstants.IGNORE_LOGS)
    const [appStore, setAppStore] =
        React.useState<Nullable<AppStore>>(undefined)
    const [isDesktop, setIsDesktop] =
        React.useState<Nullable<boolean>>(undefined)

    const createAppStore = React.useCallback(async () => {
        if (Platform.OS === 'web' || props.sourceStartUp === 'APP_FORMULA_GO') {
            await initializeAppSDK()
        } else {
            await initializeApp()
        }
        const appStoreInit = new AppStore({ auth: auth() })
        setAppStore(appStoreInit)
    }, [])

    React.useEffect(() => {
        createAppStore()
    }, [createAppStore])

    React.useEffect(() => {
        if (props.sourceStartUp) {
            cache.sourceStartUp = props.sourceStartUp
        } else {
            cache.sourceStartUp = 'APP_NORMAL'
        }
    }, [props.sourceStartUp])

    React.useEffect(() => {
        const asyncFunc = async () => {
            appStore && setIsDesktop(await appStore.isDesktop())
        }
        asyncFunc()
    }, [appStore])

    if (!appStore || isDesktop === undefined) return <Loading />

    if (props.sourceStartUp === 'APP_EDITOR') {
        const metaData = { teamId: props.teamId, appId: props.appId }

        return (
            <BottomSheetModalProvider>
                <MenuProvider>
                    <AppMetadataContext.Provider value={metaData}>
                        <AppDescriptorContext.Provider
                            value={props.descriptorStore}
                        >
                            <DesignPageContext.Provider
                                value={props.designPageStore}
                            >
                                <GestureHandlerRootView
                                    style={styles.gestureHandler}
                                >
                                    <ResourcesWaitingView store={appStore} />
                                </GestureHandlerRootView>
                            </DesignPageContext.Provider>
                        </AppDescriptorContext.Provider>
                    </AppMetadataContext.Provider>
                </MenuProvider>
            </BottomSheetModalProvider>
        )
    } else if (props.sourceStartUp === 'APP_FORMULA_GO') {
        const metaData = { teamId: props.teamId, appId: props.appId }
        return (
            <BottomSheetModalProvider>
                <MenuProvider>
                    <AppMetadataContext.Provider value={metaData}>
                        <WrapContextAppGo initDescriptor={props.initDescriptor}>
                            <GestureHandlerRootView
                                style={styles.gestureHandler}
                            >
                                <ResourcesWaitingView store={appStore} />
                            </GestureHandlerRootView>
                        </WrapContextAppGo>
                    </AppMetadataContext.Provider>
                </MenuProvider>
            </BottomSheetModalProvider>
        )
    } else if (props.sourceStartUp === 'APP_NORMAL' && isDesktop) {
        return (
            <BottomSheetModalProvider>
                <MenuProvider>
                    <WrapContextDesktop
                        wrapContextAppDesktopStore={wrapContextAppDesktopStore}
                    >
                        <GestureHandlerRootView style={styles.gestureHandler}>
                            <ResourcesWaitingView store={appStore} />
                        </GestureHandlerRootView>
                    </WrapContextDesktop>
                </MenuProvider>
            </BottomSheetModalProvider>
        )
    } else {
        return (
            <BottomSheetModalProvider>
                <MenuProvider>
                    <WrapContextApp>
                        <GestureHandlerRootView style={styles.gestureHandler}>
                            <ResourcesWaitingView store={appStore} />
                        </GestureHandlerRootView>
                    </WrapContextApp>
                </MenuProvider>
            </BottomSheetModalProvider>
        )
    }
}

const ResourcesWaitingView = observer((props: { store: AppStore }) => {
    const theme = useTheme()
    const navigationTheme = {
        ...DefaultTheme,
        colors: {
            ...DefaultTheme.colors,
            primary: theme.colorScheme.accentColor.toString(),
        },
    }

    React.useEffect(() => {
        props.store?.observeConfig()

        return () => {
            props.store?.unobserveConfig()
        }
    }, [props.store])

    return (
        <NavigationContainer theme={navigationTheme}>
            <ActionSheetProvider>
                <RootStackNavigation store={props.store} />
            </ActionSheetProvider>
        </NavigationContainer>
    )
})

const styles = StyleSheet.create({
    gestureHandler: { flex: 1 },
})

_App.defaultProps = {
    sourceStartUp: 'APP_NORMAL',
}

export const App = _App
export default _App
