//
//  WeekCalendar.tsx
//
//  Created by thaitd96 on 2022-06-14 17:32.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React, { useEffect, useMemo, useRef, useState } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import WeekView from 'react-native-week-view'
import { convertDayOfWeek, hexToRGBA } from '../../../../../utils'
import { Theme, useTheme } from '../../../../../styles/theme'
import { observer } from 'mobx-react-lite'
import dayjs from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'
import { CalendarLayoutStore } from '../CalendarLayoutStore'
import { CollectionPageStore } from '@appformula/app-studio-core/src'
import ItemWeekCalendar from './ItemWeekCalendar'
import { WeekCalendarStore } from './WeekCalendarStore'
import { useWidthHeightMainLayout } from '../../hooks/useSpecifyPlatformWindowDimensions'
import { appFormulaId } from '../../../../../database/type'

dayjs.extend(isoWeek)

interface IEvent {
    color: string
    id: string | number
    description: string
    startDate: Date
    endDate: Date
}

interface IPosition {
    height: number
    width: number
    top: number
    left: number
}

export const WeekCalendar = observer(
    (props: {
        calendarLayoutStore: CalendarLayoutStore
        pageStore: CollectionPageStore
        weekCalendarStore: WeekCalendarStore
    }) => {
        const { pageStore, weekCalendarStore, calendarLayoutStore } = props
        const dataCalendar = weekCalendarStore.listCalenderView
        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])
        const [data, setData] = useState([])
        const weekViewRef = useRef()

        const { width, height } = useWidthHeightMainLayout()
        const [startOfCurrentWeek, setStartOfCurrentWeek] = useState(
            dayjs().startOf('isoWeek')
        )
        const remainingDaysOfMonth = dayjs()
            .endOf('month')
            .diff(dayjs().startOf('isoWeek'), 'days')
        let tempLeft: number | undefined
        if (remainingDaysOfMonth < 6) {
            tempLeft = 54 + ((remainingDaysOfMonth + 1) * (width - 54)) / 7
        }
        const [left, setLeft] = useState(tempLeft)

        useEffect(() => {
            const timeout = setTimeout(() => {
                const newData = dataCalendar
                    .map((item) => item.data)
                    .flat()
                    .map((item) => ({ ...item, id: item?.[appFormulaId] }))
                setData(newData)
            }, 0)

            return () => {
                clearTimeout(timeout)
            }
        }, [dataCalendar])

        // In react-native-week-view, use hoursInDisplay to specify height of time cell.
        // Below is formula which calculate hoursInDisplay for height = 70
        const hoursInDisplay = Math.round((height - 60) / 70)
        const MyDayComponent = ({
            formattedDate,
            date,
        }: {
            formattedDate: string
            date: string
        }) => {
            // This method can be used to set the day of the week, with Sunday as 0 and Saturday as 6.
            const numberWeekDay = dayjs(date).day()
            const textWeekDay = convertDayOfWeek(numberWeekDay)
            return (
                <View style={styles.viewItemHeader}>
                    <Text style={styles.textWeekDay}>{textWeekDay}</Text>
                    <Text style={styles.textMonthDay}>{formattedDate}</Text>
                </View>
            )
        }

        const MyEventComponent = ({
            event,
            position,
        }: {
            event: IEvent
            position: IPosition
        }) => {
            return (
                <ItemWeekCalendar
                    pageStore={pageStore}
                    item={event as unknown as Record<string, unknown>}
                />
            )
        }

        const onSwipeNextOrPrev = (date: string) => {
            setStartOfCurrentWeek(dayjs(date))
            const startDate = dayjs(date).startOf('isoWeek').toDate()
            const endDate = dayjs(date).endOf('isoWeek').toDate()
            const weekPicked = {
                startDate,
                endDate,
            }
            calendarLayoutStore.setWeekPicked(weekPicked)

            const remainingDaysOfMonth = dayjs(date)
                .endOf('month')
                .diff(dayjs(date), 'days')
            if (remainingDaysOfMonth < 6) {
                setLeft(54 + ((remainingDaysOfMonth + 1) * (width - 54)) / 7)
            } else {
                setLeft(null)
            }
        }

        useEffect(() => {
            try {
                weekViewRef?.current &&
                    (weekViewRef?.current as any)?.goToDate(
                        calendarLayoutStore.weekPicked.startDate
                    )
                setStartOfCurrentWeek(
                    dayjs(calendarLayoutStore.weekPicked.startDate)
                )
            } catch (error) {
                //
            }
        }, [calendarLayoutStore.weekPicked])

        return (
            <View style={styles.wrap}>
                <View style={styles.monthInWeekCalendar}>
                    <Text style={styles.textMonth}>
                        {dayjs(startOfCurrentWeek)
                            .format('MMMM Y')
                            .toString()
                            .toUpperCase()}
                    </Text>
                    {!!left && (
                        <View style={[styles.nextMonth, { marginLeft: left }]}>
                            <Text style={styles.textMonth}>
                                {dayjs(startOfCurrentWeek)
                                    .add(1, 'month')
                                    .format('MMMM Y')
                                    .toString()
                                    .toUpperCase()}
                            </Text>
                        </View>
                    )}
                </View>
                <WeekView
                    ref={weekViewRef}
                    headerStyle={styles.headerStyle}
                    selectedDate={dayjs(
                        calendarLayoutStore.weekPicked.startDate
                    ).toDate()}
                    numberOfDays={7}
                    DayHeaderComponent={MyDayComponent}
                    EventComponent={MyEventComponent}
                    formatDateHeader={'D'}
                    formatTimeLabel={'h A'}
                    hourTextStyle={styles.hourTextStyle}
                    events={data}
                    // eventContainerStyle={styles.eventContainerStyle}
                    hoursInDisplay={hoursInDisplay}
                    onSwipeNext={onSwipeNextOrPrev}
                    onSwipePrev={onSwipeNextOrPrev}
                    scrollViewContentStyle={styles.weekViewContent}
                    gridRowStyle={styles.gridRow}
                    gridColumnStyle={styles.gridColumn}
                />
            </View>
        )
    }
)

const createStyles = ({ colorScheme }: Theme) =>
    StyleSheet.create({
        gridRow: { borderColor: colorScheme.separator },
        gridColumn: { borderColor: colorScheme.separator },
        nextMonth: {
            position: 'absolute',
            borderLeftWidth: 2,
            borderColor: colorScheme.backgroundL1,
            height: '100%',
            justifyContent: 'center',
            paddingLeft: 12,
        },
        textMonth: {
            fontSize: 11,
            color: colorScheme.labelSecondary,
            fontWeight: '600',
        },
        wrap: { flex: 1 },
        monthInWeekCalendar: {
            height: 34,
            width: '100%',
            backgroundColor: colorScheme.backgroundL2,
            paddingLeft: 12,
            flexDirection: 'row',
            alignItems: 'center',
        },
        eventContainerStyle: {
            backgroundColor: hexToRGBA(colorScheme.accentColor as string, 0.2),
            paddingLeft: 3,
            paddingVertical: 2,
            borderRadius: 2,
            borderWidth: 2,
            borderColor: colorScheme.backgroundL1,
        },
        myEventComponentStyle: {
            backgroundColor: colorScheme.accentColor,
            width: 3,
            height: '100%',
            borderRadius: 1,
        },
        hourTextStyle: {
            fontSize: 11,
            color: colorScheme.labelSecondary,
            fontWeight: '500',
        },
        headerStyle: {
            backgroundColor: colorScheme.backgroundL1,
            height: 65,
            borderTopWidth: 0,
            borderLeftWidth: 0,
            borderBottomWidth: 1,
            borderBottomColor: colorScheme.separator,
        },
        textMonthDay: {
            fontSize: 15,
            color: colorScheme.labelSecondary,
            marginTop: 8,
        },
        viewItemHeader: {
            justifyContent: 'center',
            alignItems: 'center',
            height: 65,
        },
        textWeekDay: { fontSize: 11, color: colorScheme.labelSecondary },

        weekViewContent: {
            backgroundColor: colorScheme.backgroundL1,
        },
    })
