//
//  ListLayoutBasic.tsx
//
//  Created by thaitd96 on 2022-08-05 14:54.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Image, Keyboard, StyleSheet, Text, View } from 'react-native'
import { Search } from '../Search'
import { observer } from 'mobx-react-lite'
import { CollectionPageStore } from '@appformula/app-studio-core'
import { Loading } from '../../../../components'
import { Theme, useTheme } from '../../../../styles/theme'
import {
    ARNCollectionPageStore,
    QueryState,
} from '../../ARNCollectionPageStore'
import { ListLayoutItem } from './ListLayoutItem'
import { ScrollView } from 'react-native-gesture-handler'
import { SwipeListView } from 'react-native-swipe-list-view'
import { HiddenItemActions } from './hidden-item-actions/HiddenItemActions'
import { ItemAction } from '@appformula/app-descriptor/src'
import iconFilter from '../../../../assets/icons/icon-filter'

export const ListLayoutSection = observer(
    (props: {
        pageStore: CollectionPageStore
        arnCollectionPageStore: ARNCollectionPageStore
    }) => {
        const { pageStore, arnCollectionPageStore } = props
        const data = arnCollectionPageStore.listVirtualDataSection

        // data is ObservableArray not Array and SectionList, Flatlist regards ObservableArray as unsuitable prop
        const dataFormated = data
            .map((item) => ({
                title: item.title,
                data: item.data.slice(),
            }))
            .slice()

        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])
        const [itemActions, setItemActions] = useState<ItemAction[]>([])

        const filterShownActions = useCallback(async () => {
            const res = await props.arnCollectionPageStore.filterShownActions(
                pageStore.itemActions
            )
            setItemActions(res as unknown as ItemAction[])
        }, [pageStore.itemActions, props.arnCollectionPageStore])

        useEffect(() => {
            filterShownActions()
        }, [filterShownActions])

        const keyExtractor = useCallback(
            (item: number) => {
                return `${item}_${props.arnCollectionPageStore.queryString}`
            },
            [props.arnCollectionPageStore.queryString]
        )

        const renderItem =
            (title: string) =>
            // eslint-disable-next-line react/display-name
            ({ item }: { item: number }, rowMap: unknown) => {
                return (
                    <ListLayoutItem
                        key={keyExtractor(item)}
                        item={item}
                        pageStore={pageStore}
                        arnCollectionPageStore={arnCollectionPageStore}
                        valueGrouping={title}
                    />
                )
            }

        const renderHiddenItem =
            (title: string) =>
            // eslint-disable-next-line react/display-name
            ({ item }: { item: number }, rowMap: unknown) => {
                return (
                    <HiddenItemActions
                        itemActions={itemActions}
                        item={item}
                        pageStore={pageStore}
                        arnCollectionPageStore={arnCollectionPageStore}
                        valueGrouping={title}
                    />
                )
            }

        return (
            <View style={styles.viewListLayoutSection}>
                <Search
                    searchBarStore={pageStore.searchBarStore()}
                    searchCollectionStore={arnCollectionPageStore.getSearchStore()}
                />
                {arnCollectionPageStore.isLoading ? (
                    <Loading />
                ) : (
                    <View style={styles.listCard}>
                        {dataFormated.length ? (
                            <ScrollView
                                nestedScrollEnabled={true}
                                showsVerticalScrollIndicator={false}
                            >
                                {dataFormated.map((item) => (
                                    <View key={item.title as string}>
                                        <View style={styles.headerSection}>
                                            <Text style={styles.textSection}>
                                                {item.title}
                                            </Text>
                                        </View>
                                        <SwipeListView
                                            showsVerticalScrollIndicator={false}
                                            disableRightSwipe
                                            disableLeftSwipe={
                                                itemActions?.length === 0
                                            }
                                            data={item.data}
                                            renderItem={renderItem(
                                                item.title as string
                                            )}
                                            keyExtractor={keyExtractor}
                                            renderHiddenItem={renderHiddenItem(
                                                item.title as string
                                            )}
                                            rightOpenValue={
                                                itemActions?.length > 1
                                                    ? -240
                                                    : -120
                                            }
                                            recalculateHiddenLayout={true}
                                            onScroll={() => {
                                                Keyboard.dismiss()
                                            }}
                                        />
                                    </View>
                                ))}
                            </ScrollView>
                        ) : (
                            <View style={styles.doNotHaveData}>
                                {props.arnCollectionPageStore.queryState ===
                                QueryState.Filter ? (
                                    <>
                                        <Image
                                            source={iconFilter.noItemFilter}
                                            style={styles.imgNotFilter}
                                        />
                                        <Text style={styles.txtTitle}>
                                            No Items Matched
                                        </Text>
                                        <Text style={styles.txtContent}>
                                            There is no items that matched your
                                            filter conditions. Try changing the
                                            filters or search terms.
                                        </Text>
                                    </>
                                ) : props.arnCollectionPageStore.queryState ===
                                  QueryState.Search ? (
                                    <>
                                        <Image
                                            source={iconFilter.noItemSearch}
                                            style={styles.imgNotFilter}
                                        />
                                        <Text style={styles.txtTitle}>
                                            No Items Found
                                        </Text>
                                    </>
                                ) : (
                                    <>
                                        <Image
                                            source={iconFilter.noItemNormal}
                                            style={styles.imgNotFilter}
                                            resizeMode={'contain'}
                                        />
                                        <Text style={styles.txtTitle}>
                                            No Items to Show
                                        </Text>
                                    </>
                                )}
                            </View>
                        )}
                    </View>
                )}
            </View>
        )
    }
)

const createStyles = ({ colorScheme }: Theme) =>
    StyleSheet.create({
        textSection: {
            color: colorScheme.labelSecondary,
            fontSize: 14,
            fontWeight: '500',
            lineHeight: 16.94,
            paddingVertical: 13,
        },
        headerSection: {
            backgroundColor: colorScheme.backgroundL2,
            paddingLeft: 16,
        },
        textNoData: { color: colorScheme.labelPrimary },
        title: {
            fontSize: 25,
            fontWeight: '700',
            color: colorScheme.labelPrimary,
        },
        viewListLayoutSection: { flex: 1 },
        viewHeaderRight: {
            flexDirection: 'row',
        },
        iconHeader: {
            height: 34,
            width: 34,
            tintColor: colorScheme.accentColor,
        },
        listCard: {
            flex: 1,
            width: '100%',
        },
        doNotHaveData: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            paddingHorizontal: 40,
        },
        imgNotFilter: {
            width: 38,
            height: 38,
            marginBottom: 18,
            tintColor: colorScheme.labelSecondary,
        },
        txtTitle: {
            fontSize: 20,
            fontWeight: '500',
            lineHeight: 24.2,
            color: colorScheme.labelSecondary,
            marginBottom: 6,
        },
        txtContent: {
            fontSize: 15,
            fontWeight: '400',
            lineHeight: 22.5,
            textAlign: 'center',
            color: colorScheme.labelSecondary,
        },
    })
