import messaging, {
    FirebaseMessagingTypes,
} from '@react-native-firebase/messaging'
import { UserInfo } from '../../app/type'
import { Alert } from '../../components/alert'

class NotificationHelper {
    offNotificationForceGround = false
    codeGroupSupActive: string | null = null
    user: UserInfo | unknown
    onTokenRefreshListener: () => void
    unsubscribe: (() => void) | null = null

    constructor(user = {}) {
        this.user = user
    }

    setStatusNotify(value: boolean) {
        this.offNotificationForceGround = value
    }

    setUser = (user: UserInfo) => {
        this.user = user
        console.info(this.user, 'USER O DAY NE')
    }

    //Get token
    getFcmToken = async () => {
        let token = ''
        try {
            const hasPermission = await this.checkPermission()

            if (hasPermission) {
                token = await messaging().getToken()
            } else {
                await this.requestPermission()
                const isGranted = await this.checkPermission()
                if (isGranted) {
                    token = await messaging().getToken()
                } else {
                    console.info('Authorization status not enabled')
                }
            }
            return Promise.resolve(token)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    getAPNSToken = async () => {
        let token = ''
        try {
            const hasPermission = await this.checkPermission()

            if (hasPermission) {
                token = await messaging().getAPNSToken()
            } else {
                await this.requestPermission()
                const isGranted = await this.checkPermission()
                if (isGranted) {
                    token = await messaging().getAPNSToken()
                } else {
                    console.info('Authorization status not enabled:')
                }
            }
            return Promise.resolve(token)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    //refresh token
    onRefreshFcmToken = () => {
        this.onTokenRefreshListener = messaging().onTokenRefresh((fcmToken) => {
            // Process your token as required
            console.info(fcmToken, 'fcmToken refresh')
        })
    }

    removeRefreshFcmTokenListener = () => {
        this.onTokenRefreshListener()
    }

    checkPermission = async () => {
        const authStatus = await messaging().hasPermission()
        if (
            authStatus === messaging.AuthorizationStatus.AUTHORIZED ||
            authStatus === messaging.AuthorizationStatus.PROVISIONAL
        ) {
            return true
        }
        return false
    }

    requestPermission = async () => {
        try {
            const enable = await this.checkPermission()
            if (!enable) {
                const authStatus = await messaging().requestPermission()
                const enabled =
                    authStatus === messaging.AuthorizationStatus.AUTHORIZED ||
                    authStatus === messaging.AuthorizationStatus.PROVISIONAL

                if (enabled) {
                    console.info('Authorization status:', authStatus)
                } else {
                    console.info('Authorization status enabled:', authStatus)
                }
            }
        } catch (error) {
            console.info('Authorization error', error)
            Alert.alert('Thiết bị này không thể nhận thông báo')
        }
    }

    addListenerNotification = () => {
        try {
            this.unsubscribe = messaging().onMessage((remoteMessage) => {
                console.info(remoteMessage, 'remoteMessage')
                // Push local notification when foreground cause remote message not show notification in this case
                this.displayLocalNotification(remoteMessage)
            })
        } catch (error) {
            console.info(error)
        }

        try {
            // Open app from background
            messaging().onNotificationOpenedApp((remoteMessage) => {
                console.info(
                    'Notification caused app to open from background state:',
                    remoteMessage
                )
            })
        } catch (error) {
            console.info(error)
        }

        // Open app from quit state, do nothing
        void messaging()
            .getInitialNotification()
            .then((remoteMessage) => {
                if (remoteMessage) {
                    console.info(
                        'Notification caused app to open from quit state:',
                        remoteMessage
                    )
                }
            })
    }

    displayLocalNotification = (
        remoteMessage: FirebaseMessagingTypes.RemoteMessage
    ) => {
        if (this.offNotificationForceGround) {
            return
        }
        console.info('remoteMessage', remoteMessage)
        const title = remoteMessage.notification?.title
        const message =
            remoteMessage.notification?.body &&
            remoteMessage.notification.body.length >= 60
                ? `${remoteMessage.notification.body.substring(0, 60)}...`
                : remoteMessage.notification?.body
        const payload = remoteMessage
        // LocalNotify.localNotif({
        //     title,
        //     message,
        //     payload,
        //     groupSummary: true,
        // })
    }

    handleBackgroundMessage = () => {
        console.info('handleBackgroundMessage')
        messaging().setBackgroundMessageHandler((remoteMessage) => {
            return new Promise<void>(() => {
                console.info(
                    'Message handled in the background!',
                    remoteMessage
                )
            })
        })
    }

    subscribeNotificationTopic = (topic: string) => {
        void messaging()
            .subscribeToTopic(topic)
            .then(() => console.info(`Subscribed to topic! ${topic}`))
    }

    removeNotification = () => {
        if (this.unsubscribe) {
            this.unsubscribe()
        }
    }
}

const notificationHelper = new NotificationHelper()

export default notificationHelper
