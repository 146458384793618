//
//  ARNLookupFormStore.ts
//
//  Created by thaitd on 2022-08-12 14:08.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { LookupFormInput } from '@appformula/app-descriptor/src'
import _ from 'lodash'
import { action, makeObservable, observable } from 'mobx'
import { Subject, Subscription } from 'rxjs'
import { FormulaManager } from '../../../../utils/integrate-formula/FormulaManager'
import {
    ARNFormPageStore,
    LookupFormData,
    LookupFormInputExtend,
} from '../../ARNFormPageStore'
import { BaseARNFormStore } from '../BaseARNFormStore'

export class ARNLookupFormStore implements BaseARNFormStore {
    readonly formPageId: string
    readonly componentId: string
    data: LookupFormData
    lookupFormInput: (LookupFormInput & LookupFormInputExtend) | null = null
    arnFormPageStore: ARNFormPageStore
    dataChange: Subject<void>
    formulaManager?: FormulaManager

    constructor(
        arnFormPageStore: ARNFormPageStore,
        formPageId: string,
        componentId: string,
        initData?: LookupFormData,
        formulaManager?: FormulaManager
    ) {
        this.arnFormPageStore = arnFormPageStore
        this.formPageId = formPageId
        this.componentId = componentId
        this.data = initData ?? {}
        this.dataChange = new Subject()
        this.formulaManager = formulaManager

        makeObservable(this, {
            lookupFormInput: observable,
            data: observable,
            setData: action,
            setLookupFormInput: action,
        })
    }

    startListeningEvents(): Subscription[] {
        const dataChangeSubscription = this.dataChange.subscribe({
            next: () => {
                this.arnFormPageStore.clearFormScreenError(this.componentId)
            },
        })

        return [dataChangeSubscription]
    }

    setData(data: LookupFormData) {
        this.data = data
        this.dataChange.next()
    }

    setLookupFormInput(
        lookupFormInput: LookupFormInput & LookupFormInputExtend
    ) {
        this.lookupFormInput = lookupFormInput
    }

    async validate(): Promise<void> {
        const componentStore = this.arnFormPageStore.pageStore?.componentStore(
            this.componentId
        )

        if (componentStore?.required && _.isEmpty(this.data)) {
            return Promise.reject(`${componentStore.titleName} is required!`)
        }
    }
}
