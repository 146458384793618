import { View, Text, StyleSheet } from 'react-native'
import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { BarcodeStore } from '@appformula/app-studio-core/src'
import { useBindingCommon } from '../../collection/components/hooks/useBindingCommon'
import { Theme, useTheme } from '../../../styles/theme'
import BarcodeBuilder from '../../../components/barcode-builder'
import { COLORS } from '../../../assets/colors'
import appConstants from '../../../constants/const'

const BarcodeView = observer(
    (props: { barcodeInput: BarcodeStore; item: Record<string, unknown> }) => {
        const { barcodeInput, item } = props
        const isHidden = useBindingCommon(barcodeInput.isHidden, item)
        const title = useBindingCommon(barcodeInput.title, item)
        const barcodeString = useBindingCommon(barcodeInput.barcode, item)
        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])
        return (
            !isHidden && (
                <View style={styles.container}>
                    <Text style={styles.txtTitle} numberOfLines={2}>
                        {title}
                        kasjdf;lkajsd;flkasjdflkjhasgdflkjhasdfjklashfdhgasdkfjhgaskdjfhgaksdjfhgaskjfhgaskdfjhgasdkj
                    </Text>
                    <View style={styles.warpBarcode}>
                        <BarcodeBuilder
                            value={barcodeString as string}
                            format="CODE128"
                        />
                        <Text style={styles.txtbarcode} numberOfLines={2}>
                            {barcodeString}
                        </Text>
                    </View>
                </View>
            )
        )
    }
)

export default BarcodeView

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        container: {
            backgroundColor: COLORS.WHITE,
            borderColor: colorScheme.separator,
            borderRadius: 10,
            padding: 14,
            paddingBottom: 15,
            borderWidth: 1,
            marginVertical: appConstants.PADDING_COMMON / 2,
        },
        txtbarcode: { fontSize: 20, textAlign: 'center' },
        warpBarcode: { flex: 1, alignItems: 'center', overflow: 'hidden' },
        txtTitle: {
            fontSize: 16,
            fontWeight: '500',
            lineHeight: 26.4,
            alignSelf: 'center',
            marginBottom: 9,
            textAlign: 'center',
        },
    })
