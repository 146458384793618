import React, { useMemo } from 'react'
import { WelcomePageStore } from './WelcomePageStore'
import { observer } from 'mobx-react-lite'
import AppButton from '../../components/AppButton'
import image from '../../assets/icons'
import { Image, SafeAreaView, StyleSheet, Text } from 'react-native'
import { Theme, useTheme } from '../../styles/theme'

const UnauthorizedScreen = observer((props: { store: WelcomePageStore }) => {
    const theme = useTheme()
    const styles = useMemo(() => createStyles(theme), [theme])
    const { store } = props

    const [loading, setLoading] = React.useState<boolean>(false)

    const handleGoBack = React.useCallback(() => {
        setLoading(true)
        store.logout().then(() => {
            setLoading(false)
        })
    }, [store])

    return (
        <SafeAreaView style={styles.container}>
            <Image
                style={styles.icon}
                source={
                    store.appIconURL
                        ? { uri: store.appIconURL }
                        : image.noAppIcon
                }
                resizeMode={'contain'}
            />
            <Text style={styles.txtTitle}>
                You are not authorized to view this app
            </Text>
            <Text style={styles.txtSubTitle}>
                Contact the app owner and ask them to add you as a member of
                this team to access.
            </Text>
            <AppButton
                title={'Sign Out'}
                onPress={handleGoBack}
                loading={loading}
            />
        </SafeAreaView>
    )
})

export default UnauthorizedScreen

const createStyles = ({ colorScheme }: Theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
            backgroundColor: colorScheme.backgroundL1,
            justifyContent: 'center',
            alignItems: 'center',
            // paddingHorizontal: 24,
            marginHorizontal: 24,
        },
        txtTitle: {
            fontWeight: '600',
            fontSize: 30,
            lineHeight: 36.31,
            color: colorScheme.labelPrimary,
            marginBottom: 9,
            marginTop: 24,
            textAlign: 'center',
        },
        txtSubTitle: {
            fontWeight: '400',
            fontSize: 15,
            lineHeight: 22.5,
            color: colorScheme.labelSecondary,
            textAlign: 'center',
            marginBottom: 24,
            paddingHorizontal: 8,
        },
        icon: {
            width: 104,
            height: 104,
            borderRadius: 26,
        },
    })
