import { View, Text, StyleSheet } from 'react-native'
import React, { useMemo } from 'react'
import FastImage from '../../../../components/fast-image/FastImage'
import { Theme, useTheme } from '../../../../styles/theme'
import appConstants from '../../../../constants/const'
import { InlineCollectionStore } from '@appformula/app-studio-core/src'
import { observer } from 'mobx-react-lite'
import { useBindingCommon } from '../../../collection/components/hooks/useBindingCommon'

const CollectionVListItem = observer(
    (props: { item: any; inlineCollection: InlineCollectionStore }) => {
        const theme = useTheme()
        const { inlineCollection } = props
        const styles = useMemo(() => createStyles(theme), [theme])
        const verticalLayoutData = inlineCollection.object.verticalLayout
        const title = useBindingCommon(verticalLayoutData.title)
        const imageUrl = useBindingCommon(verticalLayoutData.imageUrl)
        const subtitle = useBindingCommon(verticalLayoutData.subtitle)

        return (
            <View style={styles.item}>
                <FastImage
                    style={styles.image}
                    source={{
                        uri: imageUrl as string,
                    }}
                    resizeMode={'stretch'}
                />
                <View style={styles.rightItem}>
                    <Text
                        numberOfLines={2}
                        ellipsizeMode="tail"
                        style={styles.title}
                    >
                        {title}
                    </Text>
                    <Text
                        numberOfLines={2}
                        ellipsizeMode="tail"
                        style={styles.subTitle}
                    >
                        {subtitle}
                    </Text>
                </View>
            </View>
        )
    }
)

export default CollectionVListItem

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        subTitle: {
            fontWeight: '500',
            fontSize: 14,
            lineHeight: 21,
            color: colorScheme.labelSecondary,
        },
        title: {
            fontWeight: '500',
            fontSize: 16,
            color: colorScheme.labelPrimary,
        },
        rightItem: {
            flex: 1,
            marginLeft: appConstants.PADDING_COMMON,
            paddingVertical: appConstants.PADDING_COMMON,
            justifyContent: 'space-between',
            borderColor: colorScheme.separator,
            borderBottomWidth: 1,
        },
        item: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        image: { width: 50, height: 50, borderRadius: roundness },
    })
