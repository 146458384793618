//
//  OptionsFormInputInline.tsx
//
//  Created by thaitd96 on 2022-06-22 17:03.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { OptionsFormInputStore } from '@appformula/app-studio-core'
import appConstants from '../../../../constants/const'
import { observer } from 'mobx-react-lite'
import React, { useMemo } from 'react'
import { Pressable, StyleSheet, Text, View } from 'react-native'
import { Theme, useTheme } from '../../../../styles/theme'
import MultiChoiceItem from './MultiChoiceItem'
import SingleChoiceItem from './SingleChoiceItem'
import AddOptionFormInput from './AddOptionFormInput'
import { ARNOptionFormInputStore } from './ARNOptionFormInputStore'
import { useBinding } from '../../../collection/components/hooks/useBinding'

export type Option = {
    content: string
    selected: boolean
}

export const availableOptionsMocked = [
    'Option 1',
    'Option 2',
    'Option 3',
    'Option 4',
    'Option 5',
    'Option 6',
]
const isMultipleChoice = false

export const OptionsFormInputInline = observer(
    (props: {
        optionsFormInput: OptionsFormInputStore
        arnOptionFormInputStore: ARNOptionFormInputStore
    }) => {
        const { optionsFormInput, arnOptionFormInputStore } = props
        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])
        const isHidden = useBinding(optionsFormInput?.isHidden)

        const clear = () => {
            arnOptionFormInputStore.clearSelectedOptionInput()
        }

        const renderOptionItem = (item: Option, index: number) => {
            if (isMultipleChoice) {
                return (
                    <MultiChoiceItem
                        item={item}
                        index={index}
                        key={item.content}
                        arnOptionFormInputStore={arnOptionFormInputStore}
                    />
                )
            } else {
                return (
                    <SingleChoiceItem
                        item={item}
                        index={index}
                        key={item.content}
                        arnOptionFormInputStore={arnOptionFormInputStore}
                    />
                )
            }
        }

        return isHidden ? null : (
            <View style={styles.viewOptionsFormInput}>
                <View style={styles.viewTitle}>
                    <Text style={styles.title}>
                        {optionsFormInput.titleName}
                    </Text>
                    {optionsFormInput.required && (
                        <View style={styles.requireDot}></View>
                    )}
                </View>
                <Pressable style={styles.buttonClose} onPress={clear}>
                    <Text style={styles.clear}>{'Clear'}</Text>
                </Pressable>
                {arnOptionFormInputStore.options &&
                arnOptionFormInputStore.options.length > 0 ? (
                    <View style={styles.listOptions}>
                        {arnOptionFormInputStore.options.map((item, index) =>
                            renderOptionItem(item, index)
                        )}
                    </View>
                ) : null}
                <AddOptionFormInput
                    arnOptionFormInputStore={arnOptionFormInputStore}
                />
            </View>
        )
    }
)
const createStyles = ({ colorScheme }: Theme) =>
    StyleSheet.create({
        clear: {
            color: colorScheme.accentColor,
            fontSize: 16,
            fontWeight: '500',
        },
        listOptions: { marginTop: 11 },
        buttonClose: {
            position: 'absolute',
            top: 9,
            right: 0,
            padding: appConstants.PADDING_COMMON,
            justifyContent: 'center',
            alignItems: 'center',
        },
        viewTitle: { flexDirection: 'row', alignItems: 'center' },
        requireDot: {
            height: 6,
            width: 6,
            backgroundColor: 'red',
            borderRadius: 6,
            marginLeft: 6,
        },
        viewOptionsFormInput: {
            paddingTop: 25,
            paddingHorizontal: appConstants.PADDING_COMMON,
            borderBottomWidth: 1,
            borderBottomColor: colorScheme.separator,
        },
        title: {
            fontSize: 16,
            lineHeight: 19,
            fontWeight: '500',
            color: colorScheme.labelPrimary,
        },
    })
