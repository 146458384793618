//
//  MainPageStore.ts
//
//  Created by Peter Vu on 2022-03-28 11:06:14.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { action, makeObservable, observable } from 'mobx'
import { DocumentData, QuerySnapshot } from '../../database/type'
import { ARNCollectionPageStore } from '../collection/ARNCollectionPageStore'
import { FirebaseFirestoreTypes } from '@react-native-firebase/firestore'
import { Observable, of, Subject, Subscription } from 'rxjs'
import appConstants from '../../constants/const'
import { ARNFormPageStore } from '../form/ARNFormPageStore'
import { ARNCustomDetailPageStore } from '../custom/ARNCustomDetailPageStore'
import { Nullable } from '@appformula/shared-foundation-x/src'
import { EventType } from '../../utils/network/extensions/WebhookLocationTriggerApi'
import { LocationRegion } from 'expo-location'
import * as Location from 'expo-location'
import { GEOFENCING_TASK, Identifier } from '../../utils/geofencing/type'
import notificationHelper from '../../utils/firebase/NotificationHelper'
import NotificationApi from '../../utils/network/extensions/NotificationApi'
import { getDeviceId } from '../../utils/device'
import { Platform } from 'react-native'
import getFireStore from '../../utils/firebase/firestore/getFireStore'
import { Schema } from '../../database/Schema'
import { IDatabaseContext } from '../../context/AppDatabaseContext'
import { FirebaseAuthTypes } from '@react-native-firebase/auth'
import firebase from 'firebase'
import { FormulaManager } from '../../utils/integrate-formula/FormulaManager'

export interface MainPageAuthInfo {
    userId: string
    name: string
}

export type Webhook = {
    id: string
    eventType: EventType
    eventName: string
    eventDescription: string
    url: string
    longitude: number
    latitude: number
    radius: number
}

export class MainPageStore {
    readonly dataStore: MainPageDataStore
    readonly authInfo: MainPageAuthInfo
    oldTableNames: string[] = []
    currentTables: Nullable<FirebaseFirestoreTypes.DocumentData[]>
    refreshSchemaDone: boolean
    get refreshSchemaDoneObservable() {
        return of(this.refreshSchemaDone)
    }
    locationWebhooksSubject: Subject<Webhook[]> = new Subject()
    get locationWebhooksObservable(): Observable<Webhook[]> {
        return this.locationWebhooksSubject.asObservable()
    }
    webhooksFirestoreUnsubscribe: Nullable<() => void>
    private cachedSchemas: Record<string, Schema> = {}

    constructor() {
        this.dataStore = new MainPageDataStore(this)
        this.refreshSchemaDone = false
        this.authInfo = {
            userId: 'first_UID',
            name: 'Peter Vu',
        } // TODO: Inject from outside

        makeObservable(this, {
            dataStore: observable,
            handleSync: action,
        })
    }

    setCurrentTables(
        currentTables: Nullable<FirebaseFirestoreTypes.DocumentData[]>
    ) {
        this.currentTables = currentTables
    }

    handleSync = async (teamId: string, appId: string) => {
        try {
            const schema = this.schema(teamId, appId)
            const currentTables = await schema.refreshSchema()
            console.info('currentTables=======', currentTables)
            this.setCurrentTables(currentTables)

            this.refreshSchemaDone = true
            PubSub.publish(appConstants.REFRESH_SCHEMA_DONE)

            schema.getDataChangeFromLatestSync(currentTables)
        } catch (error) {
            console.warn('Error handleSync', error)
            throw new Error('Error handleSync')
        }
    }

    startListeningLocationWebhooksChange(): Subscription[] {
        const locationWebhooksSubscription =
            this.locationWebhooksObservable.subscribe({
                next: (locationWebhooks) => {
                    this.handleLocationWebhooksChange(locationWebhooks)
                },
            })

        return [locationWebhooksSubscription]
    }

    async handleLocationWebhooksChange(locationWebhooks: Webhook[]) {
        // console.info('handleLocationWebhooksChange', locationWebhooks)
        if (locationWebhooks.length) {
            const granted = await this.locationPermissions()
            if (granted) {
                const locationRegions = this.toLocationRegions(locationWebhooks)

                // console.info('locationRegions', locationRegions)
                Location.startGeofencingAsync(GEOFENCING_TASK, locationRegions)
            }
        }
    }

    async registerLocationWebhooks(
        teamId: string,
        appId: string
    ): Promise<void> {
        try {
            const firestore = await getFireStore()
            this.webhooksFirestoreUnsubscribe = firestore
                .collection(`teams/${teamId}/apps/${appId}/webhooks`)
                .onSnapshot({
                    next: (querySnapshot: QuerySnapshot<DocumentData>) => {
                        this.locationWebhooksSubject.next(
                            querySnapshot.docs
                                .map(
                                    (item) =>
                                        ({
                                            id: item.id,
                                            ...item.data(),
                                        } as Webhook)
                                )
                                .filter(
                                    (item) =>
                                        item.eventType === 'user_entering' ||
                                        item.eventType === 'user_leaving'
                                )
                        )
                    },
                })
        } catch (error) {
            console.info('registerLocationWebhooks', error)
            return
        }
    }

    toLocationRegions(locationWebhooks: Webhook[]): LocationRegion[] {
        return locationWebhooks.map((webhook) => {
            const { id, latitude, longitude, radius } = webhook

            // notifyOnEnter & notifyOnExit not working => need handle by identifier
            const identifier: Identifier = {
                id,
                notifyOnEnter: webhook.eventType === 'user_entering',
                notifyOnExit: webhook.eventType === 'user_leaving',
            }
            return {
                identifier: JSON.stringify(identifier),
                latitude,
                longitude,
                radius,
            }
        })
    }

    async locationPermissions(): Promise<boolean> {
        return (
            (await this.foregroundLocations()) &&
            (await this.backgroundLocations())
        )
    }

    async foregroundLocations(): Promise<boolean> {
        return (
            (await Location.getForegroundPermissionsAsync()).granted ||
            (await Location.requestForegroundPermissionsAsync()).granted
        )
    }

    async backgroundLocations(): Promise<boolean> {
        return (
            (await Location.getBackgroundPermissionsAsync()).granted ||
            (await Location.requestBackgroundPermissionsAsync()).granted
        )
    }

    async getTokenPush(): Promise<string> {
        let token = ''

        if (Platform.OS === 'ios') {
            token = await notificationHelper.getAPNSToken()
        } else if (Platform.OS === 'android') {
            token = await notificationHelper.getFcmToken()
        }

        return token
    }

    handlePushToken = async (teamId: string, appId: string) => {
        try {
            const token = await this.getTokenPush()

            if (Platform.OS !== 'web') {
                notificationHelper.addListenerNotification()
                notificationHelper.handleBackgroundMessage()
            }

            const deviceID = await getDeviceId()

            const data = {
                deviceID,
                deviceType: Platform.OS,
                pushToken: token,
            }

            setTimeout(() => {
                NotificationApi.pushToken(data, {
                    teamId,
                    appId,
                })
            }, 2000)
        } catch (error) {
            console.info('error----', error)
        }
    }

    schema(teamId: string, appId: string): Schema {
        const cachedSchema = this.cachedSchemas[`${teamId}${appId}`]
        if (cachedSchema) {
            return cachedSchema
        } else {
            const newSchema = new Schema(teamId, appId)
            this.cachedSchemas[`${teamId}${appId}`] = newSchema
            return newSchema
        }
    }
}

export class MainPageDataStore {
    mainPageStore: MainPageStore
    private cachedARNCollectionPageStore: Map<string, ARNCollectionPageStore> =
        new Map()
    private cachedARNFormPageStores: Record<string, ARNFormPageStore> = {}

    private cachedARNDetailPageStore: Map<string, ARNCustomDetailPageStore> =
        new Map()

    constructor(mainPageStore: MainPageStore) {
        this.mainPageStore = mainPageStore
    }

    arnCollectionPageStore(
        collectionPageId: string,
        databaseAction: IDatabaseContext,
        currentUser?: FirebaseAuthTypes.User | firebase.User
    ): ARNCollectionPageStore {
        const cachedARNCollectionPageStore =
            this.cachedARNCollectionPageStore.get(collectionPageId)
        if (cachedARNCollectionPageStore) {
            return cachedARNCollectionPageStore
        } else {
            const newChildComponentStore = new ARNCollectionPageStore(
                this,
                collectionPageId,
                databaseAction,
                currentUser
            )
            this.cachedARNCollectionPageStore.set(
                collectionPageId,
                newChildComponentStore
            )
            return newChildComponentStore
        }
    }

    arnFormPageStore(formPageId: string): ARNFormPageStore {
        const cachedARNFormPageStore = this.cachedARNFormPageStores[formPageId]
        if (cachedARNFormPageStore) {
            return cachedARNFormPageStore
        } else {
            const newFormPageStore = new ARNFormPageStore(this, formPageId)
            this.cachedARNFormPageStores[formPageId] = newFormPageStore
            return newFormPageStore
        }
    }

    clearCacheARNFormPageStoreById(formPageId: string) {
        delete this.cachedARNFormPageStores[formPageId]
    }

    arnCustomDetailPageStore(
        detailPageId: string,
        databaseAction: IDatabaseContext,
    ): ARNCustomDetailPageStore {
        const cachedARNDetailPageStore =
            this.cachedARNDetailPageStore.get(detailPageId)
        if (cachedARNDetailPageStore) {
            return cachedARNDetailPageStore
        } else {
            const newFormPageStore = new ARNCustomDetailPageStore(
                detailPageId,
                databaseAction,
            )
            this.cachedARNDetailPageStore.set(detailPageId, newFormPageStore)
            return newFormPageStore
        }
    }
}
