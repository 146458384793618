import React, { forwardRef } from 'react'
import ReactPlayer from 'react-player'

type IPlayer = {
    videoUrl: string
    height: number
    width: number
    playing?: boolean
}

const Player = forwardRef(({ videoUrl, height, width }: IPlayer, ref) => {
    return <ReactPlayer url={videoUrl} controls={true} height={'100%'} width={'100%'}/>
})

Player.displayName = 'Player'

export default Player
