import { TextStyle } from "react-native";

export interface TypographySettings {
    readonly fontFamily: FontFamily;
    readonly textStyles: TextStyles;
}

export enum FontWeight {
    Light,
    Regular,
    Medium,
    SemiBold,
    Bold,
    Heavy,
}

export interface FontFamily {
    getFontName(options: {
        weight: FontWeight;
        italic: boolean;
    }): string | undefined;
    getFontNameOfWeight(weight: FontWeight): string | undefined;
}

export interface TextStyles {
    readonly h1: TextStyle;
    readonly h2: TextStyle;
    readonly body: TextStyle;
}
