import { View, Text, StyleSheet, Pressable, Image } from 'react-native'
import React, { useEffect, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { LocationFormInputStore } from '@appformula/app-studio-core/src'
import { Theme, useTheme } from '../../../../styles/theme'
import appConstants from '../../../../constants/const'
import image from '../../../../assets/icons'
import { useNavigation } from '@react-navigation/native'
import screenNames from '../../../../navigations/screenNames'
import { ARNFormPageStore } from '../../ARNFormPageStore'
import GoogleMapReact from 'google-map-react'
import { ARNLocationFormStore } from './ARNLocationFormStore'
import { useBinding } from '../../../collection/components/hooks/useBinding'

const defaultProps = {
    center: {
        lat: 10.99835602,
        lng: 77.01502627,
    },
    zoom: 15,
}

const LocationFormInput = observer(
    (props: {
        locationFormInput: LocationFormInputStore
        arnLocationFormStore: ARNLocationFormStore
        arnFormPageStore: ARNFormPageStore
    }) => {
        return (
            <LocationFormInputContent
                locationFormInput={props.locationFormInput}
                arnLocationFormStore={props.arnLocationFormStore}
                arnFormPageStore={props.arnFormPageStore}
            />
        )
    }
)

const PinComponent = (props: { lat: number; lng: number }) => {
    const theme = useTheme()
    const styles = useMemo(() => createStyles(theme), [theme])
    return <Image source={image.pin3} style={styles.imgPin3} />
}

const LocationFormInputContent = observer(
    (props: {
        locationFormInput: LocationFormInputStore
        arnLocationFormStore: ARNLocationFormStore
        arnFormPageStore: ARNFormPageStore
    }) => {
        const { locationFormInput, arnLocationFormStore } = props
        const { locationSelected } = arnLocationFormStore
        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])
        const navigation = useNavigation()
        const isHidden = useBinding(locationFormInput.isHidden)

        useEffect(() => {
            const subscriptions = arnLocationFormStore.startListeningEvents()

            return () => {
                subscriptions.forEach((s) => s.unsubscribe())
            }
        }, [arnLocationFormStore])

        const chooseLocation = () => {
            navigation.navigate(
                screenNames.LOCATION_SEARCHING as never,
                { componentId: locationFormInput.componentId } as never
            )
        }

        const onClear = () => {
            arnLocationFormStore.clearLocationSelected()
        }

        return isHidden ? null : (
            <View style={styles.container}>
                <Pressable style={styles.btn} onPress={chooseLocation}>
                    <View style={styles.wrapperHeader}>
                        <View style={styles.wrapperTitle}>
                            <Text style={styles.txtTitle}>
                                {locationFormInput.titleName}
                            </Text>
                            {locationFormInput.required && (
                                <View style={styles.requireDot} />
                            )}
                        </View>
                        {locationSelected?.placeId ? (
                            <Pressable onPress={onClear}>
                                <Image
                                    source={image.close}
                                    style={styles.imgClose}
                                />
                            </Pressable>
                        ) : (
                            <Image
                                source={image.arrowRight}
                                style={styles.imgArrowRight}
                            />
                        )}
                    </View>
                </Pressable>

                {locationSelected && locationSelected?.placeId && (
                    <View style={styles.wrapperResult} removeClippedSubviews>
                        <View style={styles.wrapTitleResult}>
                            <Text style={styles.txtMain}>
                                {locationSelected?.mainText}
                            </Text>
                            <Text style={styles.txtDescription}>
                                {locationSelected?.description}
                            </Text>
                        </View>
                        <View style={styles.viewMap} removeClippedSubviews>
                            <GoogleMapReact
                                bootstrapURLKeys={{
                                    key: appConstants.API_KEY_GOOGLE_MAP,
                                }}
                                center={
                                    locationSelected
                                        ? {
                                              lat: locationSelected?.latitude,
                                              lng: locationSelected?.longitude,
                                          }
                                        : undefined
                                }
                                defaultZoom={defaultProps.zoom}
                                yesIWantToUseGoogleMapApiInternals
                            >
                                <PinComponent
                                    lat={locationSelected?.latitude}
                                    lng={locationSelected?.longitude}
                                />
                            </GoogleMapReact>
                        </View>
                    </View>
                )}
            </View>
        )
    }
)

export default LocationFormInput

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        btn: { paddingVertical: 24 },
        wrapTitleResult: { padding: 16 },
        viewMap: { width: '100%', height: 161 },
        wrapperResult: {
            borderRadius: roundness,
            borderWidth: 1,
            marginHorizontal: appConstants.PADDING_COMMON,
            borderColor: colorScheme.separator,
            overflow: 'hidden',
            marginBottom: 20,
        },
        txtTitle: {
            fontWeight: '500',
            fontSize: 16,
            lineHeight: 19.36,
            color: colorScheme.labelPrimary,
        },
        imgArrowRight: { width: 20, height: 20 },
        wrapperHeader: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingHorizontal: appConstants.PADDING_COMMON,
        },
        container: {
            borderBottomColor: colorScheme.separator,
            borderBottomWidth: 1,
        },
        requireDot: {
            height: 6,
            width: 6,
            borderRadius: 3,
            backgroundColor: 'red',
            marginLeft: 8,
        },
        wrapperTitle: { flexDirection: 'row', alignItems: 'center' },
        imgClose: {
            width: 24,
            height: 24,
        },
        txtMain: {
            fontSize: 15,
            fontWeight: '500',
            lineHeight: 18,
            marginBottom: 4,
            color: colorScheme.labelPrimary,
        },
        txtDescription: {
            fontSize: 13,
            fontWeight: '400',
            lineHeight: 18.2,
            color: colorScheme.labelSecondary,
        },
        imgPin3: { width: 34, height: 34, marginRight: 6 },
    })
