//
//  SingleChoiceItem.tsx
//
//  Created by thaitd96 on 2022-06-23 10:03.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { View, StyleSheet, Text, Pressable, Image } from 'react-native'
import React, { useMemo } from 'react'
import image from '../../../../assets/icons'
import { Theme, useTheme } from '../../../../styles/theme'
import { Option } from './OptionFormInputInline'
import appConstants from '../../../../constants/const'
import { observer } from 'mobx-react-lite'
import { ARNOptionFormInputStore } from './ARNOptionFormInputStore'

const SingleChoiceItem = observer(
    (props: {
        item: Option
        isHaveBottomLine?: boolean
        index: number
        arnOptionFormInputStore: ARNOptionFormInputStore
    }) => {
        const {
            item,
            isHaveBottomLine = false,
            index,
            arnOptionFormInputStore,
        } = props

        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])

        const onPressItem = () => {
            arnOptionFormInputStore.setChangeSingleSelectedOptionInput(index)
        }

        return (
            <Pressable onPress={onPressItem}>
                <View style={styles.viewCheckBox}>
                    <View style={styles.wrapImg}>
                        {item.selected ? (
                            <View>
                                <Image
                                    source={image.radioChecked}
                                    style={styles.radioChecked}
                                    resizeMode={'contain'}
                                ></Image>
                            </View>
                        ) : (
                            <Image
                                style={styles.radioUncheck}
                                source={image.radioUncheck}
                                resizeMode={'contain'}
                            />
                        )}
                    </View>
                    <Text
                        style={styles.title}
                        numberOfLines={1}
                        ellipsizeMode={'tail'}
                    >
                        {item.content}
                    </Text>
                </View>
                {isHaveBottomLine && <View style={styles.line}></View>}
            </Pressable>
        )
    }
)

SingleChoiceItem.displayName = 'SingleChoiceItem'

export default SingleChoiceItem

const createStyles = ({ colorScheme }: Theme) =>
    StyleSheet.create({
        line: {
            height: 1,
            backgroundColor: colorScheme.separator,
            flex: 1,
            marginLeft: 32,
        },
        viewCheckBox: {
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            height: 50,
        },

        title: {
            fontWeight: '400',
            fontSize: 15,
            lineHeight: 18,
            marginRight: appConstants.PADDING_COMMON,
            color: colorScheme.labelPrimary,
        },

        wrapImg: {
            marginRight: 12,
        },

        radioChecked: {
            height: 20,
            width: 20,
            tintColor: colorScheme.accentColor,
        },

        imgCheck: { height: 20, width: 20, position: 'absolute' },

        radioUncheck: { height: 20, width: 20 },
    })
