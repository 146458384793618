import {
    DesignPageStore,
    InspectorItemSelection,
    InspectorPageComponentSelection,
} from '@appformula/app-studio-core/src'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { BehaviorSubject, distinctUntilChanged, Subscription } from 'rxjs'
import { IComponentInfoPath } from './WrappingComponentHighlight'
import { isEqual, isEmpty } from 'lodash'
import appConstants from '../../constants/const'
import { ScrollViewHighLightStore } from '../ScrollViewHighLight/ScrollViewHighLightStore'
import { LexoRankInsertionPosition } from '@appformula/app-studio-core/src/lib/helpers/LexoRankContainer'
const heightHeader: number = 57
export class WrappingComponentHighlightStore {
    isHighLight: boolean = false
    isClosed: boolean = false
    lexoRank: LexoRankInsertionPosition
    targetComponent: string = ''

    scrollViewHighLightStore: ScrollViewHighLightStore
    inspectorItemSelection: Readonly<InspectorItemSelection> | undefined =
        undefined
    private inspectorItemSelectionSubject: BehaviorSubject<
        Readonly<InspectorItemSelection>
    > = new BehaviorSubject<Readonly<InspectorItemSelection>>(
        this.inspectorItemSelection
    )

    yCoordinateRN: number = 0
    private yCoordinateRNSubject: BehaviorSubject<number> =
        new BehaviorSubject<number>(this.yCoordinateRN)

    componentInfoPath: IComponentInfoPath
    designPageStore: DesignPageStore

    constructor(
        scrollViewHighLightStore: ScrollViewHighLightStore,
        componentInfoPath: IComponentInfoPath,
        designPageStore: DesignPageStore
    ) {
        this.scrollViewHighLightStore = scrollViewHighLightStore
        this.componentInfoPath = componentInfoPath
        this.designPageStore = designPageStore

        makeObservable(this, {
            isHighLight: observable,
            isClosed: observable,
            inspectorItemSelection: observable,
            yCoordinateRN: observable,
            setYCoordinateRN: action,
            setInspectorItemSelection: action,
        })
    }

    startListeningDataChange(): Subscription[] {
        const subscribeItemDrag = this.yCoordinateRNSubject
            .pipe(distinctUntilChanged())
            .subscribe({
                next: (newValue) => {
                    if (
                        this.scrollViewHighLightStore.isDroppedComponent ||
                        isEmpty(this.scrollViewHighLightStore.dataSourceCords)
                    ) {
                        return
                    }

                    const coordinateComponent =
                        this.scrollViewHighLightStore.dataSourceCords[
                            this.componentInfoPath.componentId
                        ]

                    const arrayKey = Object.keys(
                        this.scrollViewHighLightStore.dataSourceCords
                    )
                    const currentIndexHover = arrayKey.findIndex(
                        (item) => item === this.componentInfoPath.componentId
                    )

                    if (
                        isEmpty(coordinateComponent) ||
                        !coordinateComponent?.y
                    ) {
                        return
                    }

                    const limitAbove =
                        coordinateComponent.y + coordinateComponent.height / 2
                    const limitBelow =
                        coordinateComponent.y + coordinateComponent.height
                    const yOffset = this.scrollViewHighLightStore?.yOffset || 0
                    const realValue = yOffset + newValue - heightHeader

                    let limitBottom = limitBelow

                    if (currentIndexHover !== arrayKey.length - 1) {
                        const bufferHeight =
                            Object.values(
                                this.scrollViewHighLightStore.dataSourceCords
                            )[currentIndexHover + 1].height / 2
                        limitBottom += bufferHeight
                    }

                    if (
                        realValue < coordinateComponent.y ||
                        realValue > limitBottom
                    ) {
                        runInAction(() => {
                            this.isClosed = false
                        })
                        return
                    }

                    runInAction(() => {
                        this.isClosed = true
                    })

                    if (
                        realValue >= coordinateComponent.y &&
                        realValue <= limitAbove
                    ) {
                        runInAction(() => {
                            this.lexoRank = 'above'
                            this.scrollViewHighLightStore.resultDragEnd = {
                                lexoRank: 'above',
                                targetComponent:
                                    this.componentInfoPath.componentId,
                            }
                        })
                    } else if (
                        realValue >= limitAbove &&
                        realValue <= limitBottom
                    ) {
                        runInAction(() => {
                            this.lexoRank = 'below'
                            this.scrollViewHighLightStore.resultDragEnd = {
                                lexoRank: 'below',
                                targetComponent:
                                    this.componentInfoPath.componentId,
                            }
                        })
                    }
                },
            })

        const subscribeItemSelection =
            this.inspectorItemSelectionSubject.subscribe({
                next: (newValue) => {
                    if (newValue instanceof InspectorPageComponentSelection) {
                        const itemSelection = {
                            pageId: newValue.pageId,
                            componentId: newValue.componentId,
                        }
                        const hasFocus = isEqual(
                            this.componentInfoPath,
                            itemSelection
                        )
                        if (hasFocus) {
                            runInAction(() => {
                                this.isHighLight = true
                                setTimeout(() => {
                                    this.isHighLight = false
                                }, appConstants.TIME_OUT_HIGHLIGHT)
                            })
                        }
                    }
                },
            })

        return [subscribeItemSelection, subscribeItemDrag]
    }

    setInspectorItemSelection(
        inspectorItemSelection: Readonly<InspectorItemSelection>
    ) {
        this.inspectorItemSelection = inspectorItemSelection
        this.inspectorItemSelectionSubject.next(inspectorItemSelection)
    }

    setYCoordinateRN(yCoordinateRN: number) {
        this.yCoordinateRN = yCoordinateRN
        this.yCoordinateRNSubject.next(yCoordinateRN)
    }
}
