//
//  HiddenItemActions.tsx
//
//  Created by thaitd96 on 2022-19-04 11:54.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React, { Fragment, useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { Theme, useTheme } from '../../../../../styles/theme'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { Action, ItemAction } from '@appformula/app-descriptor/src'
import { usePressItemAction } from '../../hooks/usePressItemAction'
import { hexToRGBA } from '../../../../../utils'
import ActionIconImage from '../../../../../components/ActionIconImage'
import { ButtonMore } from './button-more/ButtonMore'
import { useGetItemFromIndex } from '../../hooks/useGetItemFromIndex'
import { CollectionPageStore } from '@appformula/app-studio-core/src'
import { ARNCollectionPageStore } from '../../../ARNCollectionPageStore'

export const HiddenItemActions = observer(
    (props: {
        itemActions: ItemAction[]
        item: number
        pageStore: CollectionPageStore
        arnCollectionPageStore: ARNCollectionPageStore
        valueGrouping?: string
    }) => {
        const { itemActions, item } = props
        const onPressItemAction = usePressItemAction()
        const theme = useTheme()
        const styles = React.useMemo(
            () => createStyles(theme, itemActions.length),
            [itemActions.length, theme]
        )

        const itemData = useGetItemFromIndex(
            props.pageStore,
            item,
            props.arnCollectionPageStore.queryString,
            props.valueGrouping
        )

        const _onPressItemAction = useCallback(
            (action: Action) => () => {
                onPressItemAction(action)
            },
            [onPressItemAction]
        )

        const CommonButton = useCallback(
            (props: { itemAction: ItemAction }) => {
                const { itemAction } = props
                return (
                    <TouchableOpacity
                        style={styles.hiddenItemAction}
                        onPress={_onPressItemAction(itemAction?.onTapAction)}
                    >
                        <ActionIconImage iconName={itemAction.icon} />
                        <Text style={styles.itemActionTitle}>
                            {itemAction.title}
                        </Text>
                    </TouchableOpacity>
                )
            },
            [
                _onPressItemAction,
                styles.hiddenItemAction,
                styles.itemActionTitle,
            ]
        )

        const ItemActions = useCallback(() => {
            if (itemActions.length > 2) {
                return (
                    <Fragment>
                        <CommonButton itemAction={itemActions[0]} />
                        <ButtonMore
                            itemActions={itemActions.slice(1)}
                            itemData={itemData}
                        />
                    </Fragment>
                )
            } else {
                return (
                    <Fragment>
                        {itemActions.map((itemAction) => (
                            <CommonButton
                                key={itemAction.title}
                                itemAction={itemAction}
                            />
                        ))}
                    </Fragment>
                )
            }
        }, [CommonButton, itemData, itemActions])

        return (
            <View style={styles.hiddenView}>
                <ItemActions />
            </View>
        )
    }
)

const createStyles = ({ colorScheme }: Theme, numberOfItemActions: number) =>
    StyleSheet.create({
        itemActionTitle: {
            marginTop: 6,
            color: colorScheme.accentColor,
            fontSize: 14,
            fontWeight: '500',
        },
        hiddenItemAction: {
            borderRightWidth: 2,
            borderRightColor: hexToRGBA(colorScheme.accentColor as string, 0.1),
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: 120,
        },
        hiddenView: {
            height: '100%',
            width: numberOfItemActions >= 2 ? 240 : 120,
            backgroundColor: hexToRGBA(colorScheme.accentColor as string, 0.2),
            alignSelf: 'flex-end',
            alignItems: 'center',
            flexDirection: 'row',
        },
    })
