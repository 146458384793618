//
//  WelcomePageStore.ts
//
//  Created by Peter Vu on 2022-03-28 17:25:35.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { FirebaseAuthTypes } from '@react-native-firebase/auth'
import { action, computed, makeObservable, observable } from 'mobx'
import buildConfig from '../../../buildConfig.json'
import { AuthenticationPage } from '@appformula/app-descriptor'
import { Nullable } from '@appformula/shared-foundation-x'
import { AppStore } from '../../app/AppStore'
import getFireStore from '../../utils/firebase/firestore/getFireStore'
import { FirebaseFirestoreTypes } from '@react-native-firebase/firestore'
import firebase from 'firebase'
import { Platform } from 'react-native'

export type EmailProvider = {
    enabled: boolean
    requireVerification: boolean
}

export type AppleProvider = {
    enabled: boolean
    serviceID: string
    bundleID: string
    teamID: string
    keyID: string
    privateKey: string
}

export type GoogleProvider = {
    enabled: boolean
    webClientID: string
    webClientSecret: string
    iosClientId: string
    iosURLScheme: string
    androidClientID: string
}

export type Anonymous = {
    enabled: boolean
}

export type AuthProviderConfig = {
    email?: EmailProvider
    apple?: AppleProvider
    google?: GoogleProvider
    anonymous?: Anonymous
}

export type AppConfig = {
    type?: AppType
    name?: string
    plan?: string
    size?: string
    logoURL?: string
    authProviderConfig?: AuthProviderConfig
}

const actionCodeSettings = {
    android: {
        packageName: 'co.unstatic.afrn',
        installApp: true,
    },
    iOS: {
        bundleId: 'co.unstatic.appformulago',
    },
    handleCodeInApp: true,
    url: 'https://appformula.dev/',
}

export enum VerificationType {
    NONE = 'NONE',
    CREATE_ACCOUNT = 'CREATE_ACCOUNT',
    RESET_PASSWORD = 'RESET_PASSWORD',
}

export enum AppType {
    INTERNAL = 'internal',
    EXTERNAL = 'external',
}

export class WelcomePageStore {
    firstName = ''
    lastName = ''
    profileImage = ''
    authenticationPageDescriptor: Nullable<AuthenticationPage> = undefined
    private _auth: FirebaseAuthTypes.Module | firebase.auth.Auth
    appName: string = ''
    appIconURL: string = ''
    verificationType: VerificationType = VerificationType.NONE
    oobCode: string = ''
    loadingState: boolean = false
    private _appStore: Nullable<AppStore> = undefined
    appType: Nullable<AppType> = undefined
    unsubscribes: (() => void)[] = []

    constructor(options: {
        auth: FirebaseAuthTypes.Module | firebase.auth.Auth
        appStore: AppStore
    }) {
        this._auth = options.auth
        this._appStore = options.appStore

        makeObservable(this, {
            signIn: action,
            authenticationPageDescriptor: observable,
            oobCode: observable,
            appType: observable,

            observeConfig: action,

            setAuthenticationPageDescriptor: action,
            setAppName: action,
            setAppType: action,

            firstName: observable,
            lastName: observable,
            profileImage: observable,
            setFirstName: action,
            setLastName: action,
            setProfileImage: action,

            appName: observable,
            brandLogoURL: computed,
            welcomeTitle: computed,
            welcomeSubtitle: computed,
            isShowGoogleAuth: computed,
            isShowAppleAuth: computed,
            isShowEmailAuth: computed,
            isShowGuestAuth: computed,
            getCurrentUser: computed,

            sendPasswordResetEmail: action,
            setVerificationType: action,
            reloadUser: action,
            applyActionCode: action,
            verifyPasswordResetCode: action,
            confirmPasswordReset: action,
            updateProfile: action,
            signInAnonymously: action,

            loadingState: observable,
            setLoadingState: action,

            getAuthProviderConfig: computed,
            unsubscribes: observable,
            unobserveConfig: action,

            appIconURL: observable,
            setAppIconURL: action,
        })
    }

    async createAppUser(userId: string) {
        const firestore = await getFireStore()
        // @ts-ignore
        const teamId = global.teamId
        // @ts-ignore
        const appId = global.appId
        const userRef = firestore
            .collection(`teams/${teamId}/apps/${appId}/appUsers`)
            .doc(userId)

        userRef.get().then((docSnapshot) => {
            if (!docSnapshot.exists) {
                userRef.set({})
            }
        })
    }

    async observeConfig(
        authenticationPageDescriptor: Nullable<AuthenticationPage>
    ) {
        if (authenticationPageDescriptor) {
            this.setAuthenticationPageDescriptor(authenticationPageDescriptor)
        }
        try {
            // @ts-ignore
            const teamId = global.teamId
            // @ts-ignore
            const appId = global.appId
            const unsubscribe = (await getFireStore())
                .collection(`teams/${teamId}/apps`)
                .doc(appId)
                .onSnapshot({
                    next: (
                        querySnapshot: FirebaseFirestoreTypes.DocumentSnapshot<FirebaseFirestoreTypes.DocumentData>
                    ) => {
                        const appConfig = querySnapshot?.data() as AppConfig
                        console.info('AppConfig-----', appConfig)
                        if (appConfig?.type) {
                            this.setAppType(appConfig.type)
                        }
                        if (appConfig?.name) {
                            this.setAppName(appConfig.name)
                        }
                        if (appConfig?.logoURL) {
                            this.setAppIconURL(appConfig.logoURL)
                        }
                    },
                })
            this.unsubscribes = [unsubscribe]
        } catch (error) {
            console.warn('Error get AuthProviderConfig:', error)
        }
    }

    unobserveConfig() {
        this.unsubscribes.forEach((unsubscribe) => {
            unsubscribe()
        })
    }

    get brandLogoURL() {
        return this.authenticationPageDescriptor?.brandLogoURL
    }
    get welcomeTitle() {
        return this.authenticationPageDescriptor?.welcomeTitle
    }
    get welcomeSubtitle() {
        return this.authenticationPageDescriptor?.welcomeSubtitle
    }

    get isShowGoogleAuth() {
        return (
            !this.authenticationPageDescriptor?.isHiddenGoogleAuth &&
            this._appStore?.authProviderConfig.google?.enabled
        )
    }
    get isShowAppleAuth() {
        return (
            !this.authenticationPageDescriptor?.isHiddenAppleAuth &&
            this._appStore?.authProviderConfig.apple?.enabled
        )
    }
    get isShowEmailAuth() {
        return (
            !this.authenticationPageDescriptor?.isHiddenEmailAuth &&
            this._appStore?.authProviderConfig.email?.enabled
        )
    }
    get isShowGuestAuth() {
        return (
            !this.authenticationPageDescriptor?.isHiddenGuestAuth &&
            this._appStore?.authProviderConfig.anonymous?.enabled
        )
    }

    get getAuthProviderConfig() {
        return this._appStore?.authProviderConfig
    }

    setAuthenticationPageDescriptor(
        authenticationPageDescriptor: AuthenticationPage
    ) {
        this.authenticationPageDescriptor = authenticationPageDescriptor
    }

    setAppName(appName: string) {
        this.appName = appName
    }

    setAppType(appType: AppType) {
        this.appType = appType
    }

    setAppIconURL(appIconURL: string) {
        this.appIconURL = appIconURL
    }

    setVerificationType(verificationType: VerificationType) {
        this.verificationType = verificationType
    }

    setFirstName(firstName: string) {
        this.firstName = firstName
    }

    setLastName(lastName: string) {
        this.lastName = lastName
    }
    setProfileImage(profileImage: string) {
        this.profileImage = profileImage
    }

    setLoadingState(loadingState: boolean) {
        this.loadingState = loadingState
    }

    async signIn(email: string, password: string) {
        if (!this.loadingState) {
            if (!!email && !!password) {
                this.setLoadingState(true)
                if (
                    this.appType === AppType.EXTERNAL &&
                    buildConfig.tenantId !== ''
                ) {
                    if (Platform.OS === 'web') {
                        this._auth.tenantId = buildConfig.tenantId
                    } else {
                        await (
                            this._auth as FirebaseAuthTypes.Module
                        ).setTenantId(buildConfig.tenantId)
                    }
                }

                await this._auth
                    .signInWithEmailAndPassword(email, password)
                    .then(async (userInfo) => {
                        if (userInfo.user) {
                            await this.createAppUser(userInfo.user.uid)
                        }
                    })
                    .catch((error) => {
                        console.warn(`Sign In error: ${error}`)
                        return Promise.reject(error)
                    })
                    .finally(() => {
                        this.setLoadingState(false)
                    })
            }
        }
    }

    async signUp(email: string, password: string) {
        if (!this.loadingState) {
            if (!!email && !!password) {
                try {
                    this.setLoadingState(true)
                    if (
                        this.appType === AppType.EXTERNAL &&
                        buildConfig.tenantId !== ''
                    ) {
                        if (Platform.OS === 'web') {
                            this._auth.tenantId = buildConfig.tenantId
                        } else {
                            await (
                                this._auth as FirebaseAuthTypes.Module
                            ).setTenantId(buildConfig.tenantId)
                        }
                    }
                    console.info('tenantId', this.appType, this._auth.tenantId)

                    this.setVerificationType(VerificationType.CREATE_ACCOUNT)
                    await this._auth
                        .createUserWithEmailAndPassword(email, password)
                        .then(async (userInfo) => {
                            if (userInfo.user) {
                                await this.createAppUser(userInfo.user.uid)
                            }
                        })
                    if (
                        this._appStore?.authProviderConfig.email
                            ?.requireVerification
                    ) {
                        await this._auth?.currentUser?.sendEmailVerification(
                            actionCodeSettings
                        )
                    }
                    return Promise.resolve()
                } catch (error) {
                    console.warn(`Sign Up error: ${error}`)
                    return Promise.reject(error)
                } finally {
                    this.setLoadingState(false)
                }
                return
            }
            return Promise.reject()
        }
    }

    async sendPasswordResetEmail(email: string) {
        if (!this.loadingState) {
            if (email) {
                this.setLoadingState(true)
                if (
                    this.appType === AppType.EXTERNAL &&
                    buildConfig.tenantId !== ''
                ) {
                    if (Platform.OS === 'web') {
                        this._auth.tenantId = buildConfig.tenantId
                    } else {
                        await (
                            this._auth as FirebaseAuthTypes.Module
                        ).setTenantId(buildConfig.tenantId)
                    }
                }

                this.setVerificationType(VerificationType.RESET_PASSWORD)
                await this._auth
                    .sendPasswordResetEmail(email, actionCodeSettings)
                    .catch((error) => {
                        console.warn(
                            `Send password reset email error: ${error}`
                        )
                        return Promise.reject(error)
                    })
                    .finally(() => {
                        this.setLoadingState(false)
                    })
            }
        }
    }

    async reloadUser() {
        await this._auth.currentUser
            ?.reload()
            .then(() => {
                return Promise.resolve()
            })
            .catch((error) => {
                console.warn(`Reload user error: ${error}`)
                return Promise.reject(error)
            })
    }

    async checkActionCode(oobCode: string) {
        return this._auth.checkActionCode(oobCode)
    }

    async applyActionCode(oobCode: string) {
        await this._auth.applyActionCode(oobCode).catch((error) => {
            console.warn('Verification signup error: ', error)
            return Promise.reject(error)
        })
    }

    async verifyPasswordResetCode(oobCode: string) {
        await this._auth
            .verifyPasswordResetCode(oobCode)
            .then(() => {
                this.oobCode = oobCode
                return Promise.resolve()
            })
            .catch((error) => {
                console.warn('Verification reset password error: ', error)
                return Promise.reject(error)
            })
    }

    async confirmPasswordReset(password: string) {
        if (!this.loadingState) {
            if (password && this.oobCode) {
                this.setLoadingState(true)
                await this._auth
                    .confirmPasswordReset(this.oobCode, password)
                    .catch((error) => {
                        console.warn('Reset password error: ', error)
                        return Promise.reject(error)
                    })
                    .finally(() => {
                        this.setLoadingState(false)
                    })
            }
        }
    }

    async signInAnonymously() {
        if (!this.loadingState) {
            if (
                this.appType === AppType.EXTERNAL &&
                buildConfig.tenantId !== ''
            ) {
                if (Platform.OS === 'web') {
                    this._auth.tenantId = buildConfig.tenantId
                } else {
                    await (this._auth as FirebaseAuthTypes.Module).setTenantId(
                        buildConfig.tenantId
                    )
                }
            }
            await this._auth
                .signInAnonymously()
                .then(async (userInfo) => {
                    console.info('User signed in anonymously')
                    if (userInfo.user) {
                        await this.createAppUser(userInfo.user.uid)
                    }
                })
                .catch((error) => {
                    if (error.code === 'auth/operation-not-allowed') {
                        console.info(
                            'Enable anonymous in your firebase console.'
                        )
                    }
                    console.warn(error)
                })
        }
    }

    async updateProfile(user: FirebaseAuthTypes.UpdateProfile) {
        if (!this.loadingState) {
            this.setLoadingState(true)
            await this._auth.currentUser
                ?.updateProfile(user)
                .catch((error) => {
                    console.warn('Update user profile error: ', error)
                    return Promise.reject(error)
                })
                .finally(() => {
                    this.setLoadingState(false)
                })
        }
    }

    get getCurrentUser() {
        return this._auth.currentUser
    }

    async logout() {
        await this._appStore?.logout()
    }
}
