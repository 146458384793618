//
//  WrapContextAppStore.ts
//
//  Created by Peter Vu on 2022-10-25 10:48.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { AppDescriptor } from '@appformula/app-descriptor/src'
import { AppDescriptorStore } from '@appformula/app-studio-core/src'
import { Nullable } from '@appformula/shared-foundation-x/src'
import { LocalAppDescriptorRepository } from '../../app-studio-core-local'
import getFireStore from '../../utils/firebase/firestore/getFireStore'
import {
    DescriptorData,
    getLatestCompatibleAppDescriptor,
} from '../../utils/network/extensions/Descriptor'
import { getData, storeData } from '../../utils/persistent-storage/AsyncStorage'
import { DocumentData, DocumentSnapshot } from '../../database/type'
import { decode } from 'base64-arraybuffer'

const DESCRIPTOR = 'Descriptor'
const LATEST_TIME = 'LatestTime'

class WrapContextAppStore {
    publishAppUnsubscribe: Nullable<() => void>

    async getDescriptorStore(
        teamId: string,
        appId: string
    ): Promise<AppDescriptorStore | undefined> {
        try {
            const descriptor =
                (await this.getDesFromLocal(teamId, appId)) ||
                (await this.getDesFromServer(appId))

            if (descriptor) {
                const descriptorRepo = new LocalAppDescriptorRepository(
                    descriptor as unknown as AppDescriptor
                )

                return Promise.resolve(new AppDescriptorStore(descriptorRepo))
            } else {
                return Promise.resolve(undefined)
            }
        } catch (error) {
            console.info('error---', error)
            return Promise.reject(undefined)
        }
    }

    async getDesFromServer(appId: string) {
        try {
            const descriptorData: Nullable<DescriptorData> =
                await getLatestCompatibleAppDescriptor(appId)

            if (descriptorData) {
                const descriptor = JSON.parse(
                    new TextDecoder().decode(
                        decode(descriptorData?.data?.descriptorContent ?? '')
                    )
                )
                return Promise.resolve(descriptor)
            } else {
                return Promise.resolve(undefined)
            }
        } catch (error) {
            return Promise.resolve(undefined)
        }
    }

    async getDesFromLocal(teamId: string, appId: string) {
        try {
            const descriptor = await getData(`${DESCRIPTOR}${teamId}${appId}`)
            return Promise.resolve(descriptor)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async getTimeFromLocal(teamId: string, appId: string) {
        try {
            const time = await getData(`${LATEST_TIME}${teamId}${appId}`)
            return time
        } catch (error) {
            return undefined
        }
    }

    async saveDesToLocal(
        teamId: string,
        appId: string,
        descriptor: Record<string, unknown>
    ) {
        storeData(`${DESCRIPTOR}${teamId}${appId}`, descriptor)
    }

    async saveTimeToLocal(teamId: string, appId: string, time: string) {
        storeData(`${LATEST_TIME}${teamId}${appId}`, time)
    }

    async listenPublishApp(teamId: string, appId: string) {
        try {
            const firestore = await getFireStore()
            this.publishAppUnsubscribe = firestore
                .collection(`teams/${teamId}/apps`)
                .doc(appId)
                .onSnapshot({
                    next: async (snapshot) => {
                        this.handleDocChange(teamId, appId, snapshot)
                    },
                })
        } catch (error) {
            console.info(error)
            return
        }
    }

    async handleDocChange(
        teamId: string,
        appId: string,
        snapshot: DocumentSnapshot<DocumentData>
    ) {
        if (
            snapshot.data()?.['descriptorLastUpdatedAt'] !==
            (await this.getTimeFromLocal(teamId, appId))
        ) {
            const descriptor = await this.getDesFromServer(appId)
            if (descriptor) {
                this.saveDesToLocal(teamId, appId, descriptor)
                this.saveTimeToLocal(
                    teamId,
                    appId,
                    snapshot.data()?.['descriptorLastUpdatedAt']
                )
            }
        }
    }
}

export const wrapContextAppStore = new WrapContextAppStore()
