//
//  ItemGroupMap.tsx
//
//  Created by thaitd96 on 2022-16-05 11:48.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React, { useMemo } from 'react'
import image from '../../../../assets/icons'
import { observer } from 'mobx-react-lite'
import { Image, StyleSheet, Text, TouchableOpacity } from 'react-native'
import { Theme, useTheme } from '../../../../styles/theme'
import { pagesIcon } from '../../../../assets/icons/icon-pages'
import { MapLayoutStore } from './MapLayoutStore'

export type GroupDisplay = {
    icon: string
    color: string
    columnValue: string
}

const ItemGroupMap = observer(
    (props: { item: GroupDisplay; mapLayoutStore: MapLayoutStore }) => {
        const theme = useTheme()
        const { item, mapLayoutStore } = props
        const isActive = mapLayoutStore.activeGroups.includes(item.columnValue)
        const styles = useMemo(
            () => createStyles(theme, item, isActive),
            [theme, item, isActive]
        )

        const onPress = () => {
            mapLayoutStore.changeIsActive(item.columnValue)
        }
        return (
            <TouchableOpacity style={styles.itemGroup} onPress={onPress}>
                <Image
                    style={styles.icon}
                    source={pagesIcon[item.icon]}
                    resizeMode={'contain'}
                ></Image>
                <Text style={styles.text}>{props.item.columnValue}</Text>
                {isActive && (
                    <Image
                        style={styles.iconChecked}
                        source={image.check}
                        resizeMode={'contain'}
                    ></Image>
                )}
            </TouchableOpacity>
        )
    }
)

export default ItemGroupMap

const createStyles = (
    { colorScheme }: Theme,
    item: GroupDisplay,
    isActive: boolean
) =>
    StyleSheet.create({
        icon: {
            tintColor: isActive ? item.color : colorScheme.labelSecondary,
            height: 24,
            width: 24,
        },
        iconChecked: {
            tintColor: isActive ? item.color : colorScheme.labelSecondary,
            height: 20,
            width: 20,
        },
        text: {
            fontWeight: '500',
            color: isActive ? item.color : colorScheme.labelSecondary,
            marginLeft: 4,
        },
        itemGroup: {
            flexDirection: 'row',
            backgroundColor: colorScheme.backgroundL1,
            alignItems: 'center',
            marginRight: 10,
            paddingVertical: 6,
            paddingHorizontal: 8,
            borderRadius: 28,
            borderColor: isActive ? item.color : colorScheme.separator,
            borderWidth: 1,
        },
    })
