import { StyleSheet, TextInput } from 'react-native'
import React, { useState } from 'react'
import { WelcomePageStore } from './WelcomePageStore'
import { observer } from 'mobx-react-lite'
import { Theme, useTheme } from '../../styles/theme'
import AppButton from '../../components/AppButton'
import AuthenticationLayout from './AuthenticationLayout'
import appConstants from '../../constants/const'
import ImagePicker from '../../components/image-picker/ImagePicker'
import { AppAuthenticationContext } from '../../context/AppAuthenticationContext'

const CustomizeProfileScreen = observer(
    (props: { store: WelcomePageStore }) => {
        const theme = useTheme()
        const styles = React.useMemo(() => createStyles(theme), [theme])
        const { store } = props

        const appStore = React.useContext(AppAuthenticationContext)

        const [firstName, setFirstName] = useState<string>('')
        const [lastName, setLastName] = useState<string>('')

        const onChangeFirstName = (text: string) => {
            setFirstName(text)
        }

        const onChangeLastName = (text: string) => {
            setLastName(text)
        }

        const handleSaveProfile = React.useCallback(async () => {
            const updatedDisplayName = JSON.stringify({
                firstName: firstName,
                lastName: lastName,
            })

            store
                ?.updateProfile({
                    displayName: updatedDisplayName ?? '',
                    photoURL: ref.current.photoURL ?? '',
                })
                .then(async () => {
                    appStore?.setIsCustomizedProfile(true)
                    await store.reloadUser()
                })
        }, [appStore, firstName, lastName, store])

        const handleDoLater = React.useCallback(async () => {
            appStore?.setIsCustomizedProfile(true)
            await store.reloadUser()
        }, [appStore, store])

        const ref = React.useRef({ photoURL: '' })

        const setAvatarUrl = React.useCallback((msg, data) => {
            ref.current.photoURL = data
        }, [])

        React.useEffect(() => {
            const sub = PubSub.subscribe(
                appConstants.RECEIVE_AVATAR_URL,
                setAvatarUrl
            )

            return function clean() {
                PubSub.unsubscribe(sub)
            }
        }, [setAvatarUrl])

        return (
            <AuthenticationLayout
                title={'Customize your Profile'}
                subTitle={
                    'Help other users recognize you more easier by providing your info.'
                }
                headerRightTitle={'Do this Later'}
                onHeaderRight={handleDoLater}
                isHideLeftHeader={true}
            >
                <ImagePicker
                    initURL={store?.getCurrentUser?.photoURL ?? ''}
                    style={styles.avatarContainer}
                    enable={!store?.loadingState}
                />
                <TextInput
                    style={styles.input}
                    onChangeText={onChangeFirstName}
                    value={firstName}
                    placeholder={'First Name'}
                    autoCapitalize="none"
                    placeholderTextColor={theme.colorScheme.labelSecondary}
                />
                <TextInput
                    style={styles.input}
                    onChangeText={onChangeLastName}
                    value={lastName}
                    placeholder={'Last Name'}
                    autoCapitalize="none"
                    placeholderTextColor={theme.colorScheme.labelSecondary}
                />
                <AppButton
                    title={'Done'}
                    onPress={handleSaveProfile}
                    loading={store?.loadingState}
                />
            </AuthenticationLayout>
        )
    }
)

export default CustomizeProfileScreen

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        input: {
            width: '100%',
            height: 50,
            marginBottom: 12,
            borderWidth: 1,
            padding: 10,
            borderRadius: roundness,
            borderColor: colorScheme.separator,
            color: colorScheme.labelPrimary,
        },
        avatarContainer: {
            marginBottom: 20,
        },
    })
