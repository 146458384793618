//
//  AddPeopleLookupEmail.tsx
//
//  Created by thaitd96 on 2022-07-06 11:30.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React, { useMemo } from 'react'
import { Image, Platform, StyleSheet, View } from 'react-native'
import { Theme, useTheme } from '../../../../styles/theme'
import image from '../../../../assets/icons'
import appConstants from '../../../../constants/const'
import { hexToRGBA } from '../../../../utils'
import { observer } from 'mobx-react-lite'
import { ARNPeopleLookupFormStore } from './ARNPeopleLookupFormStore'
import { People } from './PeopleLookupFormInput'
import { Alert } from '../../../../components/alert'
import TextInputEmailSearch, {
    IInfo,
} from '../../../../components/TextInputEmailSearch'

type AddPeopleLookupEmailProps = {
    arnPeopleLookupFormStore: ARNPeopleLookupFormStore
}

const checkPeopleExisted = (peoples: People[], email: string) => {
    for (let index = 0; index < peoples.length; index++) {
        const element = peoples[index]
        if (element?.email === email) return true
    }
    return false
}

const AddPeopleLookupEmail = observer((props: AddPeopleLookupEmailProps) => {
    const theme = useTheme()
    const styles = useMemo(() => createStyles(theme), [theme])
    const { arnPeopleLookupFormStore } = props

    const onSelectItem = (item: IInfo) => {
        let peoples = arnPeopleLookupFormStore.peoples || []

        // Email empty
        if (!item.emailAddress) return

        // Email existed
        if (checkPeopleExisted(peoples, item.emailAddress)) {
            Alert.alert('Error', 'People existed!')
            return
        }

        const firstName = item?.firstName || ''
        const lastName = item?.lastName || ''
        const name = `${firstName}${firstName ? ' ' : ''}${lastName}`

        peoples = [
            ...peoples,
            {
                name,
                email: item.emailAddress,
                avatarImage: item.profileImageURL,
                selected: false,
            },
        ]
        arnPeopleLookupFormStore.setPeoples(peoples)
    }

    return (
        <View style={styles.item}>
            <View style={styles.viewWrapIcon}>
                <Image source={image.mail} style={styles.mail}></Image>
            </View>
            <View style={styles.right}>
                <TextInputEmailSearch
                    placeholder="Enter other email"
                    placeholderTextColor={hexToRGBA(
                        theme.colorScheme.labelPrimary as string,
                        0.3
                    )}
                    menuOptionsProps={{
                        optionsContainerStyle: styles.popup,
                    }}
                    onSelectItem={onSelectItem}
                />
            </View>
        </View>
    )
})

export default AddPeopleLookupEmail

const createStyles = ({ colorScheme }: Theme) =>
    StyleSheet.create({
        textInput: {
            fontSize: 15,
            flex: 1,
            marginRight: appConstants.PADDING_COMMON,
            height: '100%',
        },
        viewWrapIcon: {
            height: 40,
            width: 40,
            borderRadius: 20,
            backgroundColor: colorScheme.backgroundSidebar,
            justifyContent: 'center',
            alignItems: 'center',
            marginHorizontal: appConstants.PADDING_COMMON,
        },
        right: {
            flex: 1,
            borderBottomColor: colorScheme.separator,
            borderBottomWidth: 1,
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '100%',
            flexDirection: 'row',
        },
        mail: {
            height: 28,
            width: 28,
            resizeMode: 'contain',
        },
        item: {
            height: 61,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
        },
        popup: {
            width: 300,
            backgroundColor: colorScheme.backgroundL2,
            paddingHorizontal: 8,
            borderRadius: 5,
            marginLeft: Platform.OS === 'web' ? 0 : 64,
            marginTop: Platform.OS === 'web' ? 0 : -61 / 2,
        },
    })
