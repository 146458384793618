//
//  FilterStringStore.ts
//
//  Created by thaitd96 on 2022-07-27 15:24.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { action, makeObservable, observable } from 'mobx'
import appConstants from '../../../../../constants/const'
import { ColumnFilter, FilterStringData } from '../CollectionFilterStore'

export class FilterStringStore {
    readonly collectionPageId: string
    readonly columnFilter: ColumnFilter
    type: number
    value: string

    constructor(collectionPageId: string, filterStringData: FilterStringData) {
        this.collectionPageId = collectionPageId
        this.columnFilter = filterStringData.columnFilter
        this.type =
            filterStringData.type ?? appConstants.COMPARE_TYPE.IS_CONTAIN
        this.value = filterStringData.value ?? ''

        makeObservable(this, {
            type: observable,
            value: observable,
            setType: action,
            setValue: action,
            clear: action,
        })
    }

    setType(type: number) {
        this.type = type
    }

    setValue(value: string) {
        this.value = value
    }

    clear() {
        this.type = appConstants.COMPARE_TYPE.IS_EQUAL
        this.value = ''
    }
}
