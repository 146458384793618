import axios, { Canceler, ResponseType, AxiosError } from 'axios'
import { Alert } from 'react-native'
import { generateRandomId } from '../../utils/Utils'
import apis from './apis'

const initHeader = {
    'Content-Type': 'application/json',
    Accept: 'application/octet-stream',
    'Accept-Charset': 'UTF-8',
    'Accept-Encoding': 'gzip',
}
export interface IProgressEvent {
    loaded: number
    total: number
}
interface IParameter {
    headerCustomize?: any
    data?: any
    timeoutShowModal?: number
    isHandleError?: boolean
    manualRequestId?: string
    responseType?: ResponseType
    handleProgressDownload?: (progressEvent: IProgressEvent) => void
    handleProgressUpload?: (progressEvent: IProgressEvent) => void
    needSetApiPath?: boolean
    params?: any
}

interface IParameterPost {
    headerCustomize?: any
    data?: any
    timeoutShowModal?: number
    isHandleError?: boolean
    manualRequestId?: string
    responseType?: ResponseType
    handleProgressDownload?: (progressEvent: IProgressEvent) => void
    handleProgressUpload?: (progressEvent: IProgressEvent) => void
    needSetApiPath?: boolean
    params?: any
}
interface IInstanceCancel {
    [requestId: string]: Canceler | null
}
interface ICancelRequest {
    requestId?: string
    url?: string
    isManual?: boolean
}
interface IError {
    error?: any
    timeoutShowModal: number
}

const TIMEOUT_API = 60000

const getClient = () => {
    const options = {
        timeout: TIMEOUT_API,
        headers: initHeader,
    }
    const client = axios.create(options)

    // client.defaults.withCredentials = true;
    // Add a request interceptor
    client.interceptors.request.use(
        async function (config) {
            if (__DEV__) {
                try {
                    const request = {
                        data: config.data,
                        headers: config.headers['common'],
                        url: config.url,
                        params: config.params,
                    }
                    console.info(
                        '====================REQUEST=====================\n',
                        request
                    )
                    console.info(
                        '================================================'
                    )
                } catch (error) {
                    console.info('Catch error', error)
                }
            }
            return {
                ...config,
            }
        },
        (error) => {
            console.info('Client.interceptors.request Error', error)
            return Promise.reject(error)
        }
    )

    client.interceptors.response.use(
        async function (response) {
            try {
                if (__DEV__) {
                    const res = {
                        data: response.data,
                        requestURL: response.request.responseURL,
                        status: response.request.status,
                    }
                    console.info(
                        '====================RESPONSE====================\n',
                        res
                    )
                    console.info(
                        '================================================'
                    )
                }
            } catch (error) {
                console.info('Client.interceptors.response Error', error)
            }
            return response
        },
        function (error) {
            const err = {
                message: error.message,
                requestURL: error.request.responseURL,
                data: error.response.data
            }
            console.info('====================ERROR====================\n', err)
            console.info('================================================')
        }
    )
    return client
}

class ApiClient {
    CancelToken = axios.CancelToken
    instanceCancel: IInstanceCancel = {}
    client
    constructor() {
        this.client = getClient()
    }

    setHeader(token: string) {
        this.client.defaults.headers.common['Authorization'] = `Bearer ${token}`
    }

    async get(url: string, config = {} as IParameter) {
        const {
            headerCustomize = {},
            data = {},
            timeoutShowModal = 1000,
            isHandleError = true,
            manualRequestId = '',
            responseType,
            handleProgressDownload,
            needSetApiPath = true,
        } = config

        const requestUrl = needSetApiPath ? apis.setApiPath(url) : url
        console.info('requestUrl------', requestUrl);
        
        const requestId = manualRequestId
            ? manualRequestId
            : `${url}${generateRandomId()}`

        return this.client
            .get(requestUrl, {
                params: {
                    ...data,
                },
                ...(headerCustomize && { headers: { ...headerCustomize } }),
                cancelToken: new this.CancelToken((token) => {
                    this.initTokenCancel(requestId, token)
                }),
                ...(responseType && { responseType }),
                onUploadProgress: function (progressEvent: IProgressEvent) {
                    handleProgressDownload &&
                        handleProgressDownload(progressEvent)
                },
            })
            .catch((error: AxiosError) => {
                let messageError = ''
                if (isHandleError) {
                    messageError = handleError({ error, timeoutShowModal })
                    console.info('messageError', requestUrl, messageError)
                }
                return Promise.reject(error)
            })
    }

    async post(url: string, config = {} as IParameterPost) {
        const {
            headerCustomize = {},
            data = {},
            params = {},
            timeoutShowModal = 1000,
            isHandleError = true,
            manualRequestId = '',
            responseType,
            handleProgressUpload,
            needSetApiPath = true,
        } = config

        const requestUrl = needSetApiPath ? apis.setApiPath(url) : url
        const requestId = manualRequestId
            ? manualRequestId
            : `${url}${generateRandomId()}`

        return this.client
            .post(requestUrl, data, {
                params: {
                    ...params,
                },
                ...(headerCustomize && { headers: { ...headerCustomize } }),
                cancelToken: new this.CancelToken((token) => {
                    this.initTokenCancel(requestId, token)
                }),
                ...(responseType && { responseType }),
                onUploadProgress: function (progressEvent: IProgressEvent) {
                    handleProgressUpload && handleProgressUpload(progressEvent)
                },
            })
            .catch((error: AxiosError) => {
                let messageError = ''
                if (isHandleError) {
                    messageError = handleError({ error, timeoutShowModal })
                    console.info('messageError', requestUrl, messageError)
                }
                return Promise.reject(error)
            })
    }

    async put(url: string, config = {} as IParameter) {
        const {
            headerCustomize = {},
            data = {},
            params = {},
            timeoutShowModal = 1000,
            isHandleError = true,
            manualRequestId = '',
            responseType,
            handleProgressUpload,
            needSetApiPath = true,
        } = config
        const requestUrl = needSetApiPath ? apis.setApiPath(url) : url
        const requestId = manualRequestId
            ? manualRequestId
            : `${url}${generateRandomId()}`

        return this.client
            .put(requestUrl, data, {
                params: {
                    ...params,
                },
                ...(headerCustomize && { headers: { ...headerCustomize } }),
                cancelToken: new this.CancelToken((token) => {
                    this.initTokenCancel(requestId, token)
                }),
                ...(responseType && { responseType }),
                onUploadProgress: function (progressEvent: IProgressEvent) {
                    handleProgressUpload && handleProgressUpload(progressEvent)
                },
            })
            .catch((error: AxiosError) => {
                let messageError = ''
                if (isHandleError) {
                    messageError = handleError({ error, timeoutShowModal })
                    console.info('messageError', requestUrl, messageError)
                }
                return Promise.reject(error)
            })
    }

    initTokenCancel(requestId: string, token: Canceler) {
        this.instanceCancel[requestId as keyof IInstanceCancel] = token
    }

    cancelRequest({ requestId, url, isManual }: ICancelRequest) {
        console.info(`cancelRequest from ${url}-${requestId}`)
        if (this.instanceCancel[requestId as keyof IInstanceCancel]) {
            const cancelMessage = isManual ? 'manual' : 'Request timeout'
            this.instanceCancel[requestId as keyof IInstanceCancel]?.(
                cancelMessage
            )
            this.instanceCancel[requestId as keyof IInstanceCancel] = null
        }
    }
}

function getMessageError({ error }: IError) {
    if (error?.message) {
        if (error?.message === 'manual') {
            return ''
        }
        if (error?.message === 'Request timeout') {
            return 'Request timeout'
        }
    }
    const { response: errorObj, message } = error

    const defaultError = {
        message: 'Fail',
    }
    if (errorObj) {
        if (errorObj.data && errorObj.data.message) {
            return errorObj.data.message
        } else {
            return defaultError.message
        }
    } else {
        return 'Can not connect to server'
    }
}

function handleError({ error, timeoutShowModal = 1000 }: IError) {
    const message = getMessageError({ error } as IError)
    if (message) {
        setTimeout(() => {
            Alert.alert('Warning', message, [
                {
                    text: 'OK',
                    onPress: () => {
                        //
                    },
                },
            ])
        }, timeoutShowModal)
    }
    return message
}

export const apiClient = new ApiClient()
