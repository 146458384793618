import { View, Text } from 'react-native'
import React from 'react'
import { observer } from 'mobx-react-lite';
import { InlineCollectionStore } from '@appformula/app-studio-core';
import { IData } from '.';

const GridCollection = observer((props: { inlineCollection: InlineCollectionStore; data: Array<IData> }) => {
  return (
    <View>
      <Text>GridCollection</Text>
    </View>
  )
})

export default GridCollection