//
//  Carousel.tsx
//
//  Created by thaitd96 on 2022-20-04 11:43.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { CarouselStore } from '@appformula/app-studio-core'
import appConstants from '../../../constants/const'
import { observer } from 'mobx-react-lite'
import React, { useMemo, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import FastImage from '../../../components/fast-image/FastImage'
import Carousel from 'react-native-reanimated-carousel'
import { Theme, useTheme } from '../../../styles/theme'
import { COLORS } from '../../../assets/colors'
import { useWidthHeightMainLayout } from '../../collection/components/hooks/useSpecifyPlatformWindowDimensions'
import { useBindingCommon } from '../../collection/components/hooks/useBindingCommon'

export const CarouselView = observer(
    (props: { carousel: CarouselStore; item: Record<string, unknown> }) => {
        const { carousel, item } = props
        const carouselData = carousel.object
        const urls = useBindingCommon(carouselData?.urls, item) as Array<string>
        const isHidden = useBindingCommon(carouselData.isHidden, item)
        const { width } = useWidthHeightMainLayout()
        const [itemActive, setItemActive] = useState<number>(0)

        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme, width), [theme, width])

        const onScrollEnd = (index: number) => {
            setItemActive(index)
        }

        return (
            !isHidden &&
            urls?.length > 0 && (
                <View style={styles.viewCarousel}>
                    <Carousel
                        width={width}
                        height={303}
                        data={urls as unknown[]}
                        loop={false}
                        renderItem={({ item }) => (
                            <FastImage
                                key={`${item}`}
                                style={styles.imageItem}
                                source={{
                                    uri: item as string,
                                }}
                                resizeMode={'stretch'}
                            />
                        )}
                        panGestureHandlerProps={{
                            activeOffsetX: [-10, 10],
                        }}
                        onScrollEnd={onScrollEnd}
                    />
                    <View style={styles.wrapDotItems}>
                        {urls?.map((item: string, index: number) => (
                            <View
                                key={`${item}`}
                                style={
                                    itemActive === index
                                        ? styles.dotItemActive
                                        : styles.dotItemInActive
                                }
                            />
                        ))}
                    </View>
                </View>
            )
        )
    }
)
const createStyles = (
    { colorScheme, roundness, typography }: Theme,
    width: number
) =>
    StyleSheet.create({
        viewCarousel: {
            marginVertical: appConstants.PADDING_COMMON / 2,
        },

        imageItem: {
            width: width - 34,
            height: 303,
            borderRadius: roundness,
            backgroundColor: COLORS.GRAY,
        },

        dotItemInActive: {
            width: 6,
            height: 6,
            borderRadius: 3,
            backgroundColor: COLORS.GRAY,
            marginHorizontal: 4,
        },

        dotItemActive: {
            width: 6,
            height: 6,
            borderRadius: 3,
            backgroundColor: colorScheme.labelPrimary,
            marginHorizontal: 4,
        },

        wrapDotItems: {
            flexDirection: 'row',
            alignSelf: 'center',
            marginTop: 12,
        },
    })
