//
//  OptionFilter.tsx
//
//  Created by thaitd96 on 2022-25-07 18:23.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { observer } from 'mobx-react-lite'
import React, { useCallback, useMemo } from 'react'
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import appConstants from '../../../../../constants/const'
import { Theme, useTheme } from '../../../../../styles/theme'

import { OptionFilterItem } from './FilterOptionItem'
import image from '../../../../../assets/icons'
import { FilterOptionStore } from './FilterOptionStore'
import { CollectionFilterStore } from '../CollectionFilterStore'
import ContextMenuCustom from '../../../../../components/context-menu-custom/ContextMenuCustom'

const CompareOptions = [
    { key: appConstants.OPTIONS_COMPARE_TYPE.EQUALS, title: 'Equals' },
    { key: appConstants.OPTIONS_COMPARE_TYPE.NOT_EQUALS, title: 'Not Equals' },
]

export const OptionFilter = observer(
    (props: {
        filterOptionStore: FilterOptionStore
        collectionFilterStore: CollectionFilterStore
    }) => {
        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])

        const clear = useCallback(() => {
            props.collectionFilterStore.removeFilteredColumn(
                props.filterOptionStore.columnFilter.key
            )
        }, [
            props.collectionFilterStore,
            props.filterOptionStore.columnFilter.key,
        ])

        const ComponentHeader = React.useCallback(() => {
            return (
                <View style={styles.viewComponentHeader}>
                    <Text style={styles.textHeader}>
                        {props.filterOptionStore.columnFilter.columnNameRaw}
                    </Text>
                    <TouchableOpacity onPress={clear}>
                        <Image
                            source={image.close}
                            style={styles.imageClose}
                        ></Image>
                    </TouchableOpacity>
                </View>
            )
        }, [
            clear,
            props.filterOptionStore.columnFilter.columnNameRaw,
            styles.imageClose,
            styles.textHeader,
            styles.viewComponentHeader,
        ])

        const onPressItem = (option: string) => {
            props.filterOptionStore.changeSelectedOption(option)
        }

        const renderOptionItem = (item: string, index: number) => {
            const isSelected =
                props.filterOptionStore.selectedOptions.includes(item)
            return (
                <OptionFilterItem
                    key={item}
                    item={item}
                    isSelected={isSelected}
                    onPressItem={onPressItem}
                />
            )
        }

        const onChangeSelection = React.useCallback(
            (option: number) => {
                props.filterOptionStore.setType(option)
            },
            [props.filterOptionStore]
        )

        return (
            <View style={styles.viewOptionsFormInput}>
                <ComponentHeader />
                <ContextMenuCustom
                    dataActions={CompareOptions}
                    dropdownMenuMode={true}
                    onSelect={onChangeSelection}
                    initialValue={appConstants.OPTIONS_COMPARE_TYPE.EQUALS}
                    currentValue={props.filterOptionStore.type}
                />
                {props.filterOptionStore.availableOptions &&
                    props.filterOptionStore.availableOptions.length > 0 && (
                        <View style={styles.listOptions}>
                            {props.filterOptionStore.availableOptions.map(
                                (item: string | number, index: number) =>
                                    renderOptionItem(item.toString(), index)
                            )}
                        </View>
                    )}
                {/*<AddOptionFilter*/}
                {/*    onEndEditing={onEndEditing}*/}
                {/*    existedOptions={props.filterOptionStore.availableOptions}*/}
                {/*/>*/}
            </View>
        )
    }
)
const createStyles = ({ colorScheme }: Theme) =>
    StyleSheet.create({
        imageClose: { height: 30, width: 30, resizeMode: 'contain' },
        textHeader: {
            fontSize: 17,
            fontWeight: '600',
            color: colorScheme.labelPrimary,
        },
        viewComponentHeader: {
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingTop: 12,
        },
        listOptions: { marginTop: 7 },
        viewOptionsFormInput: {
            paddingTop: 25,
            paddingHorizontal: appConstants.PADDING_COMMON,
            borderBottomWidth: 1,
            borderBottomColor: colorScheme.separator,
        },
    })
