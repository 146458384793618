import React, { useContext, useEffect, useState } from 'react'
import { MainPage } from '../pages/main/MainPage'
import { MainPageStore } from '../pages/main/MainPageStore'
import { observer } from 'mobx-react-lite'
import auth from '@react-native-firebase/auth'
import { WelcomePage } from '../pages/authentication/WelcomePage'
import { AppStore, UserAuthenticationState } from '../app/AppStore'
import { FormulaManager } from '../utils/integrate-formula/FormulaManager'
import { FormulaManagerContext } from '../context/FormulaManagerContext'
import { AppMetadataContext } from '../context/AppMetadataContext'
import { AppAuthenticationContext } from '../context/AppAuthenticationContext'
import cache from '../utils/cache'
import UnauthorizedScreen from '../pages/authentication/UnauthorizedScreen'

const defaultStore = new MainPageStore()

const RootStackNavigation = observer((props: { store: AppStore }) => {
    const [formulaManager, setFormulaManager] = useState<
        FormulaManager | undefined
    >(undefined)
    const { teamId, appId } = useContext(AppMetadataContext)
    const {
        authProviderConfig,
        authenticatedUser,
        isCustomizedProfile,
        isTeamMember,
    } = props.store

    useEffect(() => {
        setFormulaManager(new FormulaManager(auth(), teamId, appId))
    }, [appId, teamId])

    React.useEffect(() => {
        if (authenticatedUser) {
            props.store.listenTeamInfo()
        }

        return () => {
            props.store.teamInfoUnsubscribe?.()
        }
    }, [authenticatedUser, props.store])

    if (
        formulaManager &&
        ((authenticatedUser &&
            isTeamMember !== UserAuthenticationState.IsNotVerify &&
            ((isCustomizedProfile &&
                Object.keys(authProviderConfig).length !== 0 &&
                !authProviderConfig.email?.requireVerification) ||
                (authProviderConfig.email?.requireVerification &&
                    authenticatedUser.emailVerified) ||
                (authProviderConfig.anonymous?.enabled &&
                    authenticatedUser.isAnonymous))) ||
            cache.sourceStartUp === 'APP_FORMULA_GO')
    ) {
        if (isTeamMember === UserAuthenticationState.IsNotTeamMember) {
            return (
                <AppAuthenticationContext.Provider value={props.store}>
                    <UnauthorizedScreen
                        store={props.store.welcomePageStore()}
                    />
                </AppAuthenticationContext.Provider>
            )
        }

        return (
            <FormulaManagerContext.Provider value={formulaManager}>
                <AppAuthenticationContext.Provider value={props.store}>
                    <MainPage mainPageStore={defaultStore} />
                </AppAuthenticationContext.Provider>
            </FormulaManagerContext.Provider>
        )
    } else {
        return (
            <AppAuthenticationContext.Provider value={props.store}>
                <WelcomePage store={props.store.welcomePageStore()} />
            </AppAuthenticationContext.Provider>
        )
    }
})

export default RootStackNavigation
