import { action, computed, makeObservable, observable } from 'mobx'
import { ARNCustomDetailPageStore } from '../../ARNCustomDetailPageStore'
import { ChartStore } from '@appformula/app-studio-core'
import getFireStore from '../../../../utils/firebase/firestore/getFireStore'
import { processColor, ProcessedColorValue } from 'react-native'
import { DocumentData, QuerySnapshot } from '../../../../database/type'

export type AxisChartData = {
    [segment: string]: {
        [horizontal: string]: {
            x: string
            y: number
            count: number
        }
    }
}

export type PieChartData = {
    [category: string]: {
        sum: number
        count: number
    }
}
export type AxisValue = {
    x: number
    y: number
    marker: string
}

export type BarAxisData = {
    values?: AxisValue[]
    config?: {
        drawValues: boolean
        colors: (ProcessedColorValue | null | undefined)[]
    }
    label?: string
    color?: string
}
export type BarAxis = {
    data: BarAxisData[]
    labels?: string[]
}

export type LineAxisData = {
    values?: AxisValue[]
    config?: {
        colors: (ProcessedColorValue | null | undefined)[]
        drawValues: boolean
        drawCircles: boolean
        lineWidth: number
    }
    label?: string
    color?: string
}

export type LineAxis = {
    data: LineAxisData[]
    labels?: string[]
}

export type Pie = {
    data: {
        value: number
        label?: string | undefined
    }[]
    colors: ReturnType<typeof processColor>[]
}

export type AxisDataTypeWeb = {
    name: string
    data: AxisValue[]
    color?: string
}
export type AxisChartLayoutWeb = {
    data: AxisDataTypeWeb[]
    labels: string[]
}

export type PieDataTypeWeb = {
    name: string
    y: number
    color?: string
}
export type PieChartLayoutWeb = {
    data: PieDataTypeWeb[]
    labels?: string[]
}

export class ARNChartStore {
    chartData: Record<string, unknown>[] = []
    pageStore: ARNCustomDetailPageStore
    chartStore: ChartStore

    constructor(pageStore: ARNCustomDetailPageStore, chartStore: ChartStore) {
        this.pageStore = pageStore
        this.chartStore = chartStore

        makeObservable(this, {
            chartData: observable,
            setChartData: action,

            getAndSetChartData: action,

            getBarAxisChart: computed,
            getLineAxisChart: computed,
            getPieChart: computed,

            getAxisChartLayoutWeb: computed,
            getPieChartLayoutWeb: computed,
        })
    }

    setChartStore(chartStore: ChartStore) {
        this.chartStore = chartStore
    }

    setChartData(chartData: Record<string, unknown>[]) {
        this.chartData = chartData
    }

    async getAndSetChartData(chart: ChartStore): Promise<(() => void)[]> {
        const unsubscribes: (() => void)[] = []
        try {
            // const listData = await GetAllSqlDataByTableName(
            //     this.chartStore?.dataSource?.id ?? ''
            // )
            if (chart) {
                this.setChartStore(chart)
            }
            const firestore = await getFireStore()
            if (this.chartStore?.dataSource?.id) {
                // @ts-ignore
                const teamId = global.teamId
                const unsubscribe = firestore
                    .collection(
                        `tableData/${teamId}/${this.chartStore?.dataSource?.id}`
                    )
                    .onSnapshot({
                        next: (querySnapshot: QuerySnapshot<DocumentData>) => {
                            const listData = querySnapshot.docs.map((item) =>
                                item.data()
                            ) as Record<string, unknown>[]
                            this.setChartData(listData)
                        },
                    })
                unsubscribes.push(unsubscribe)
            }
        } catch (error) {
            console.info('Chart error-----', error)
        }

        return unsubscribes
    }

    get getBarAxisChart(): BarAxis {
        if (
            this.chartStore.chartType === 'bar' &&
            this.chartStore.axisChartConfig &&
            this.chartData.length > 0
        ) {
            let target: AxisChartData = {}
            const dataOrder = new Set<string>()
            for (const data of this.chartData) {
                const vertical =
                    data[this.chartStore.axisChartConfig.verticalGroupingColumn]

                const horizontal = data[
                    this.chartStore.axisChartConfig.horizontalGroupingColumn
                ] as string

                const segment = data[
                    this.chartStore.axisChartConfig.segmentColumn
                ] as string

                dataOrder.add(segment)

                if (typeof vertical === 'number') {
                    if (!target[horizontal]) {
                        target = {
                            ...target,
                            [horizontal]: {
                                [segment]: {
                                    x: segment,
                                    y: vertical,
                                    count: 1,
                                },
                            },
                        }
                    } else {
                        if (!target[horizontal]![segment]) {
                            target = {
                                ...target,
                                [horizontal]: {
                                    ...target[horizontal],
                                    [segment]: {
                                        x: segment,
                                        y: vertical,
                                        count: 1,
                                    },
                                },
                            }
                        } else {
                            target = {
                                ...target,
                                [horizontal]: {
                                    ...target[horizontal],
                                    [segment]: {
                                        x: segment,
                                        y:
                                            target[horizontal]![segment]!.y +
                                            vertical,
                                        count:
                                            target[horizontal]![segment]!
                                                .count + 1,
                                    },
                                },
                            }
                        }
                    }
                }
            }

            const sortedDataOrder = [...dataOrder].sort((a, b) =>
                a.toString().localeCompare(b.toString())
            )

            const axisData = Object.entries(target)
                .sort((a, b) => a[0].toString().localeCompare(b[0].toString()))
                .map((item, index) => {
                    const hasValue = Object.values(item[1]).map((dataItem) => {
                        return {
                            x: sortedDataOrder.indexOf(dataItem.x),
                            y:
                                this.chartStore.axisChartConfig
                                    ?.verticalAggerationMode === 'sum'
                                    ? dataItem.y
                                    : Math.round(
                                          (dataItem.y / dataItem.count) * 100
                                      ) / 100,
                            marker: dataItem.x,
                        }
                    })

                    const noValue = sortedDataOrder
                        .filter((item) =>
                            hasValue.every((value) => value.marker !== item)
                        )
                        .map((item) => {
                            return {
                                x: sortedDataOrder.indexOf(item),
                                y: 0,
                                marker: item,
                            }
                        })
                    const color =
                        index < this.chartStore.colors.length
                            ? this.chartStore.colors[index]
                            : this.chartStore.colors[
                                  index % this.chartStore.colors.length
                              ]
                    return {
                        label: item[0],
                        values: [...hasValue, ...noValue].sort((a, b) =>
                            a.marker
                                .toString()
                                .localeCompare(b.marker.toString())
                        ),
                        // .sort((a, b) => a.x.localeCompare(b.x)),
                        config: {
                            drawValues: false,
                            colors: [processColor(color)],
                        },
                        color: color,
                    }
                })
            return { labels: sortedDataOrder, data: axisData }
        }

        return { labels: [], data: [] }
    }

    get getLineAxisChart(): LineAxis {
        if (
            this.chartStore.chartType === 'line' &&
            this.chartStore.axisChartConfig &&
            this.chartData.length > 0
        ) {
            let target: AxisChartData = {}
            const dataOrder = new Set<string>()
            for (const data of this.chartData) {
                const vertical =
                    data[this.chartStore.axisChartConfig.verticalGroupingColumn]

                const horizontal = data[
                    this.chartStore.axisChartConfig.horizontalGroupingColumn
                ] as string

                const segment = data[
                    this.chartStore.axisChartConfig.segmentColumn
                ] as string

                dataOrder.add(segment)

                if (typeof vertical === 'number') {
                    if (!target[horizontal]) {
                        target = {
                            ...target,
                            [horizontal]: {
                                [segment]: {
                                    x: segment,
                                    y: vertical,
                                    count: 1,
                                },
                            },
                        }
                    } else {
                        if (!target[horizontal]![segment]) {
                            target = {
                                ...target,
                                [horizontal]: {
                                    ...target[horizontal],
                                    [segment]: {
                                        x: segment,
                                        y: vertical,
                                        count: 1,
                                    },
                                },
                            }
                        } else {
                            target = {
                                ...target,
                                [horizontal]: {
                                    ...target[horizontal],
                                    [segment]: {
                                        x: segment,
                                        y:
                                            target[horizontal]![segment]!.y +
                                            vertical,
                                        count:
                                            target[horizontal]![segment]!
                                                .count + 1,
                                    },
                                },
                            }
                        }
                    }
                }
            }

            const sortedDataOrder = [...dataOrder].sort((a, b) =>
                a.toString().localeCompare(b.toString())
            )
            const axisData = Object.entries(target)
                .sort((a, b) => a[0].toString().localeCompare(b[0].toString()))
                .map((item, index) => {
                    const hasValue: AxisValue[] = Object.values(item[1]).map(
                        (dataItem) => {
                            return {
                                x: sortedDataOrder.indexOf(dataItem.x) + 0.5,
                                y:
                                    this.chartStore.axisChartConfig
                                        ?.verticalAggerationMode === 'sum'
                                        ? dataItem.y
                                        : Math.round(
                                              (dataItem.y / dataItem.count) *
                                                  100
                                          ) / 100,
                                marker: dataItem.x,
                            }
                        }
                    )

                    const noValue: AxisValue[] = sortedDataOrder
                        .filter((item) =>
                            hasValue.every((value) => value.marker !== item)
                        )
                        .map((item) => {
                            return {
                                x: sortedDataOrder.indexOf(item) + 0.5,
                                y: 0,
                                marker: item,
                            }
                        })
                    const color =
                        index < this.chartStore.colors.length
                            ? this.chartStore.colors[index]
                            : this.chartStore.colors[
                                  index % this.chartStore.colors.length
                              ]
                    return {
                        label: item[0],
                        values: [...hasValue, ...noValue]
                            // .sort((a, b) => a.y - b.y),
                            .sort((a, b) =>
                                a.marker
                                    .toString()
                                    .localeCompare(b.marker.toString())
                            ),

                        config: {
                            colors: [processColor(color)],
                            drawValues: false,
                            drawCircles: false,
                            lineWidth: 3,
                        },
                        color: color,
                    }
                })

            return { labels: sortedDataOrder, data: axisData }
        }

        return { labels: [], data: [] }
    }

    get getPieChart(): Pie {
        if (
            this.chartStore.chartType === 'pie' &&
            this.chartStore.pieChartConfig &&
            this.chartData.length > 0
        ) {
            let target: PieChartData = {}
            for (const data of this.chartData) {
                const dataCategory = data[
                    this.chartStore.pieChartConfig.categoryColumn
                ] as string

                const valueColumn =
                    data[this.chartStore.pieChartConfig.valueColumn]

                if (typeof valueColumn === 'number') {
                    if (!target[dataCategory]) {
                        target = {
                            ...target,
                            [dataCategory]: {
                                sum: valueColumn,
                                count: 1,
                            },
                        }
                    } else {
                        target[dataCategory]!.sum += valueColumn
                        target[dataCategory]!.count += 1
                    }
                }
            }

            const colors: ReturnType<typeof processColor>[] = []

            const pieData = Object.entries(target)
                .map((item, index) => {
                    const color =
                        index < this.chartStore.colors.length
                            ? this.chartStore.colors[index]
                            : this.chartStore.colors[
                                  index % this.chartStore.colors.length
                              ]

                    colors.push(processColor(color))
                    return {
                        label: item[0],
                        value:
                            this.chartStore.pieChartConfig
                                ?.valueAggerationMode === 'sum'
                                ? item[1].sum
                                : item[1].sum / item[1].count,
                    }
                })
                .sort((a, b) => b.value - a.value)

            return { data: pieData, colors: colors }
        }

        return { data: [], colors: [] }
    }

    get getAxisChartLayoutWeb(): AxisChartLayoutWeb {
        if (
            (this.chartStore.chartType === 'bar' ||
                this.chartStore.chartType === 'line') &&
            this.chartStore.axisChartConfig &&
            this.chartData.length > 0
        ) {
            let target: AxisChartData = {}
            const dataOrder = new Set<string>()
            for (const data of this.chartData) {
                const vertical =
                    data[this.chartStore.axisChartConfig.verticalGroupingColumn]

                const horizontal = data[
                    this.chartStore.axisChartConfig.horizontalGroupingColumn
                ] as string

                const segment = data[
                    this.chartStore.axisChartConfig.segmentColumn
                ] as string

                dataOrder.add(segment)

                if (typeof vertical === 'number') {
                    if (!target[horizontal]) {
                        target = {
                            ...target,
                            [horizontal]: {
                                [segment]: {
                                    x: segment,
                                    y: vertical,
                                    count: 1,
                                },
                            },
                        }
                    } else {
                        if (!target[horizontal]![segment]) {
                            target = {
                                ...target,
                                [horizontal]: {
                                    ...target[horizontal],
                                    [segment]: {
                                        x: segment,
                                        y: vertical,
                                        count: 1,
                                    },
                                },
                            }
                        } else {
                            target = {
                                ...target,
                                [horizontal]: {
                                    ...target[horizontal],
                                    [segment]: {
                                        x: segment,
                                        y:
                                            target[horizontal]![segment]!.y +
                                            vertical,
                                        count:
                                            target[horizontal]![segment]!
                                                .count + 1,
                                    },
                                },
                            }
                        }
                    }
                }
            }

            const sortedDataOrder = [...dataOrder].sort((a, b) =>
                a.toString().localeCompare(b.toString())
            )

            const axisData = Object.entries(target)
                .sort((a, b) => a[0].toString().localeCompare(b[0].toString()))
                .map((item, index) => {
                    const hasValue = Object.values(item[1]).map((dataItem) => {
                        return {
                            x: sortedDataOrder.indexOf(dataItem.x),
                            y:
                                this.chartStore.axisChartConfig
                                    ?.verticalAggerationMode === 'sum'
                                    ? dataItem.y
                                    : Math.round(
                                          (dataItem.y / dataItem.count) * 100
                                      ) / 100,
                            marker: dataItem.x,
                        }
                    })

                    const noValue = sortedDataOrder
                        .filter((item) =>
                            hasValue.every((value) => value.marker !== item)
                        )
                        .map((item) => {
                            return {
                                x: sortedDataOrder.indexOf(item),
                                y: 0,
                                marker: item,
                            }
                        })
                    return {
                        name: item[0],
                        data: [...hasValue, ...noValue]
                            // .sort((a, b) => a.y - b.y),
                            .sort((a, b) =>
                                a.marker
                                    .toString()
                                    .localeCompare(b.marker.toString())
                            ),
                        color:
                            index < this.chartStore.colors.length
                                ? this.chartStore.colors[index]
                                : this.chartStore.colors[
                                      index % this.chartStore.colors.length
                                  ],
                    }
                })

            return { labels: sortedDataOrder, data: axisData }
        }

        return { labels: [], data: [] }
    }

    get getPieChartLayoutWeb(): PieChartLayoutWeb {
        if (
            this.chartStore.chartType === 'pie' &&
            this.chartStore.pieChartConfig &&
            this.chartData.length > 0
        ) {
            let target: PieChartData = {}
            for (const data of this.chartData) {
                const dataCategory = data[
                    this.chartStore.pieChartConfig.categoryColumn
                ] as string

                const valueColumn =
                    data[this.chartStore.pieChartConfig.valueColumn]

                if (typeof valueColumn === 'number') {
                    if (!target[dataCategory]) {
                        target = {
                            ...target,
                            [dataCategory]: {
                                sum: valueColumn,
                                count: 1,
                            },
                        }
                    } else {
                        target[dataCategory]!.sum += valueColumn
                        target[dataCategory]!.count += 1
                    }
                }
            }

            const pieData = Object.entries(target)
                .map((item, index) => {
                    return {
                        name: item[0],
                        y:
                            this.chartStore.pieChartConfig
                                ?.valueAggerationMode === 'sum'
                                ? item[1].sum
                                : item[1].sum / item[1].count,
                        color:
                            index < this.chartStore.colors.length
                                ? this.chartStore.colors[index]
                                : this.chartStore.colors[
                                      index % this.chartStore.colors.length
                                  ],
                    }
                })
                .sort((a, b) => b.y - a.y)

            return { data: pieData }
        }

        return { data: [] }
    }
}
