//
//  ListLayout.tsx
//
//  Created by thaitd96 on 2022-19-04 11:54.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React, { useCallback, useMemo } from 'react'
import { CollectionPageStore } from '@appformula/app-studio-core'
import { observer } from 'mobx-react-lite'
import { ListLayoutBasic } from './ListLayoutBasic'
import { useNavigation } from '@react-navigation/native'
import { SafeAreaView, StyleSheet, Text, View } from 'react-native'
import { Theme, useTheme } from '../../../../styles/theme'
import screenNames from '../../../../navigations/screenNames'
import Sort from '../component-utils/SortButton'
import FilterButton from '../component-utils/FilterButton'
import { ARNCollectionPageStore } from '../../ARNCollectionPageStore'
import { ListLayoutSection } from './ListLayoutSection'
import { useBinding } from '../hooks/useBinding'
import appConstants from '../../../../constants/const'
import { useWidthHeightMainLayout } from '../hooks/useSpecifyPlatformWindowDimensions'

export const ListLayout = observer(
    (props: {
        pageStore: CollectionPageStore
        arnCollectionPageStore: ARNCollectionPageStore
    }) => {
        const { pageStore } = props
        const { width } = useWidthHeightMainLayout()
        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme, width), [theme, width])
        const navigation = useNavigation()

        const goToFilter = useCallback(() => {
            navigation &&
                navigation.navigate(
                    screenNames.COLLECTION_FILTER_SCREEN as never,
                    { pageId: props.pageStore.pageId } as never
                )
        }, [navigation, props.pageStore.pageId])

        const title = useBinding(pageStore.title)

        return (
            <SafeAreaView style={styles.containerView}>
                <View style={styles.header}>
                    <View style={styles.viewHeader}>
                        <Text
                            style={styles.title}
                            numberOfLines={1}
                            ellipsizeMode={'tail'}
                        >
                            {title}
                        </Text>
                    </View>
                    <View style={styles.headerRight}>
                        <Sort />
                        <FilterButton goToFilter={goToFilter} />
                    </View>
                </View>

                {props.pageStore.activeLayout.groupingColumn ? (
                    <ListLayoutSection
                        {...props}
                        arnCollectionPageStore={props.arnCollectionPageStore}
                    />
                ) : (
                    <ListLayoutBasic
                        {...props}
                        arnCollectionPageStore={props.arnCollectionPageStore}
                    />
                )}
            </SafeAreaView>
        )
    }
)

const createStyles = ({ colorScheme }: Theme, width: number) =>
    StyleSheet.create({
        containerView: {
            flex: 1,
            backgroundColor: colorScheme.backgroundL1,
        },
        header: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: colorScheme.backgroundL1,
            width: '100%',
            paddingTop: 10,
        },
        headerRight: {
            flexDirection: 'row',
            paddingRight: appConstants.PADDING_COMMON,
        },
        viewHeader: {
            width: width - 100,
            paddingLeft: appConstants.PADDING_COMMON,
        },
        title: {
            fontSize: 25,
            fontWeight: '700',
            color: colorScheme.labelPrimary,
            flex: 1,
            paddingRight: 20,
        },

        iconLeft: { height: 34, width: 34, tintColor: colorScheme.accentColor },
        icon: { height: 34, width: 34, tintColor: colorScheme.accentColor },
    })
