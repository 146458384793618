//
//  LocationStore.ts
//
//  Created by hienbv on 2022-06-30 10:48.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { LocationGeocodedLocation } from 'expo-location'
import { makeObservable, observable, action } from 'mobx'
import { ILocation } from './locationSearchingPage/LocationResults'
import { AddressDetails } from 'react-native-address-autocomplete'
import { BaseARNFormStore } from '../BaseARNFormStore'
import { ARNFormPageStore, LocationData } from '../../ARNFormPageStore'
import { Subject, Subscription } from 'rxjs'
import isEmpty from 'lodash/isEmpty'
import uniqBy from 'lodash/uniqBy'
import { FormulaManager } from '../../../../utils/integrate-formula/FormulaManager'

export type GeocodedLocation = LocationGeocodedLocation | AddressDetails
export class ARNLocationFormStore implements BaseARNFormStore {
    historyLocationsSelected: Array<GeocodedLocation & ILocation> = []
    formPageId: string
    componentId: string
    arnFormPageStore: ARNFormPageStore
    locationSelected: LocationData
    locationSelectedChange: Subject<void>
    formulaManager?: FormulaManager

    constructor(
        arnFormPageStore: ARNFormPageStore,
        formPageId: string,
        componentId: string,
        formulaManager?: FormulaManager,
        initData?: LocationData
    ) {
        this.arnFormPageStore = arnFormPageStore
        this.formPageId = formPageId
        this.componentId = componentId
        this.locationSelected = initData
        this.locationSelectedChange = new Subject()
        this.formulaManager = formulaManager

        makeObservable(this, {
            historyLocationsSelected: observable,
            locationSelected: observable,
            setHistoryLocationsSelected: action,
            setLocationSelected: action,
            clearLocationSelected: action,
        })
    }

    startListeningEvents(): Subscription[] {
        const valueChangeSubscription = this.locationSelectedChange.subscribe({
            next: () => {
                this.arnFormPageStore.clearFormScreenError(this.componentId)
            },
        })

        return [valueChangeSubscription]
    }

    setHistoryLocationsSelected(location: GeocodedLocation & ILocation) {
        const result = uniqBy(
            [location, ...this.historyLocationsSelected],
            'placeId'
        )
        this.historyLocationsSelected = result
    }

    async validate(): Promise<void> {
        const componentStore = this.arnFormPageStore.pageStore.componentStore(
            this.componentId
        )

        if (componentStore.required && isEmpty(this.locationSelected)) {
            return Promise.reject(`${componentStore.titleName} is required!`)
        }
    }

    setLocationSelected(locationSelected: LocationData) {
        this.locationSelected = locationSelected
        this.locationSelectedChange.next()
    }

    clearLocationSelected() {
        this.locationSelected = undefined
    }
}
