//
//  ListLayoutBasic.tsx
//
//  Created by thaitd96 on 2022-19-04 11:54.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React, { useCallback, useEffect, useState } from 'react'
import { Image, Keyboard, StyleSheet, Text, View } from 'react-native'
import { Search } from '../Search'
import { ListLayoutItem } from './ListLayoutItem'
import { observer } from 'mobx-react-lite'
import { CollectionPageStore } from '@appformula/app-studio-core'
import { Loading } from '../../../../components'
import { Theme, useTheme } from '../../../../styles/theme'
import {
    ARNCollectionPageStore,
    QueryState,
} from '../../ARNCollectionPageStore'
import { SwipeListView } from 'react-native-swipe-list-view'
import { HiddenItemActions } from './hidden-item-actions/HiddenItemActions'
import { ItemAction } from '@appformula/app-descriptor/src'
import iconFilter from '../../../../assets/icons/icon-filter'

export const ListLayoutBasic = observer(
    (props: {
        pageStore: CollectionPageStore
        arnCollectionPageStore: ARNCollectionPageStore
    }) => {
        const { pageStore } = props
        const data = props.arnCollectionPageStore.listVirtualDataWithoutSection
        const theme = useTheme()
        const styles = React.useMemo(() => createStyles(theme), [theme])
        const [itemActions, setItemActions] = useState<ItemAction[]>([])

        const filterShownActions = useCallback(async () => {
            const res = await props.arnCollectionPageStore.filterShownActions(
                pageStore.itemActions
            )
            setItemActions(res as unknown as ItemAction[])
        }, [pageStore.itemActions, props.arnCollectionPageStore])

        useEffect(() => {
            filterShownActions()
        }, [filterShownActions])

        const renderItem = ({ item }: { item: number }, rowMap: unknown) => {
            return (
                <ListLayoutItem
                    key={item}
                    item={item}
                    pageStore={pageStore}
                    arnCollectionPageStore={props.arnCollectionPageStore}
                />
            )
        }

        const renderHiddenItem = (
            { item }: { item: number },
            rowMap: unknown
        ) => {
            return (
                <HiddenItemActions
                    itemActions={itemActions}
                    item={item}
                    pageStore={pageStore}
                    arnCollectionPageStore={props.arnCollectionPageStore}
                />
            )
        }

        const keyExtractor = useCallback(
            (item: number) => {
                return `${item}_${props.arnCollectionPageStore.queryString}`
            },
            [props.arnCollectionPageStore.queryString]
        )

        return (
            <View style={styles.viewListLayoutBasic}>
                <Search
                    searchBarStore={pageStore.searchBarStore()}
                    searchCollectionStore={props.arnCollectionPageStore.getSearchStore()}
                />
                {props.arnCollectionPageStore.isLoading ? (
                    <Loading />
                ) : (
                    <View style={styles.listCard}>
                        {data?.length ? (
                            <SwipeListView
                                showsVerticalScrollIndicator={false}
                                disableRightSwipe
                                disableLeftSwipe={itemActions?.length === 0}
                                renderItem={renderItem}
                                keyExtractor={keyExtractor}
                                data={data.slice()}
                                removeClippedSubviews
                                renderHiddenItem={renderHiddenItem}
                                rightOpenValue={
                                    itemActions?.length > 1 ? -240 : -120
                                }
                                recalculateHiddenLayout={true}
                                onScroll={() => {
                                    Keyboard.dismiss()
                                }}
                            />
                        ) : (
                            <View style={styles.doNotHaveData}>
                                {props.arnCollectionPageStore.queryState ===
                                QueryState.Filter ? (
                                    <>
                                        <Image
                                            source={iconFilter.noItemFilter}
                                            style={styles.imgNotFilter}
                                        />
                                        <Text style={styles.txtTitle}>
                                            No Items Matched
                                        </Text>
                                        <Text style={styles.txtContent}>
                                            There is no items that matched your
                                            filter conditions. Try changing the
                                            filters or search terms.
                                        </Text>
                                    </>
                                ) : props.arnCollectionPageStore.queryState ===
                                  QueryState.Search ? (
                                    <>
                                        <Image
                                            source={iconFilter.noItemSearch}
                                            style={styles.imgNotFilter}
                                        />
                                        <Text style={styles.txtTitle}>
                                            No Items Found
                                        </Text>
                                    </>
                                ) : (
                                    <>
                                        <Image
                                            source={iconFilter.noItemNormal}
                                            style={styles.imgNotFilter}
                                            resizeMode={'contain'}
                                        />
                                        <Text style={styles.txtTitle}>
                                            No Items to Show
                                        </Text>
                                    </>
                                )}
                            </View>
                        )}
                    </View>
                )}
            </View>
        )
    }
)

const createStyles = ({ colorScheme }: Theme) =>
    StyleSheet.create({
        textNoData: { color: colorScheme.labelPrimary },
        title: {
            fontSize: 25,
            fontWeight: '700',
            color: colorScheme.labelPrimary,
        },
        viewListLayoutBasic: { flex: 1 },
        viewHeaderRight: {
            flexDirection: 'row',
        },
        iconHeader: {
            height: 34,
            width: 34,
            tintColor: colorScheme.accentColor,
        },
        listCard: {
            flex: 1,
            width: '100%',
        },
        doNotHaveData: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            paddingHorizontal: 40,
        },
        imgNotFilter: {
            width: 38,
            height: 38,
            marginBottom: 18,
            tintColor: colorScheme.labelSecondary,
        },
        txtTitle: {
            fontSize: 20,
            fontWeight: '500',
            lineHeight: 24.2,
            color: colorScheme.labelSecondary,
            marginBottom: 6,
        },
        txtContent: {
            fontSize: 15,
            fontWeight: '400',
            lineHeight: 22.5,
            textAlign: 'center',
            color: colorScheme.labelSecondary,
        },
    })
