//
//  FormulaManagerContext.tsx
//
//  Created by hienbv on 2022-09-19 1821.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { DesignPageStore } from '@appformula/app-studio-core/src'
import React from 'react'

export const DesignPageContext = React.createContext<DesignPageStore>(
    undefined as DesignPageStore
)
