import { View, Text, StyleSheet, Image, Pressable } from 'react-native'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import FastImage from '../../../../components/fast-image/FastImage'
import { Theme, useTheme } from '../../../../styles/theme'
import image from '../../../../assets/icons'
import firebase from 'firebase'
import { AppMetadataContext } from '../../../../context/AppMetadataContext'
import { IInfo } from '../../../../components/TextInputEmailSearch'

interface IPeopleChip {
    item: string
    onRemove?: (item: string) => void
}

const PeopleChip = (props: IPeopleChip) => {
    const { item, onRemove } = props
    const theme = useTheme()
    const styles = useMemo(() => createStyles(theme), [theme])
    const { teamId } = useContext(AppMetadataContext)
    const [info, setInfo] = useState<IInfo>()
    const onRemoveItem = () => {
        onRemove && onRemove(item)
    }

    useEffect(() => {
        firebase
            .firestore()
            .collection('teams')
            .doc(`${teamId}`)
            .collection('members')
            .where('emailAddress', '==', item)
            .get()
            .then((querySnapshot) => {
                querySnapshot?.forEach((i) => {
                    setInfo(i.data() as IInfo)
                })
            })
    }, [item, teamId])

    return (
        <View style={styles.container}>
            <View style={styles.wrapContent}>
                <FastImage
                    source={{
                        uri: info?.profileImageURL || '',
                    }}
                    style={styles.imgAvatar}
                />
                <Text style={styles.txtName}>
                    {info?.firstName || ''} {info?.lastName || ''}
                </Text>
            </View>
            <Pressable onPress={onRemoveItem} hitSlop={5}>
                <Image source={image.close} style={styles.icClose} />
            </Pressable>
        </View>
    )
}

export default PeopleChip

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        icClose: { width: 24, height: 24 },
        imgAvatar: { width: 32, height: 32, borderRadius: 16, marginRight: 12 },
        wrapContent: {
            flexDirection: 'row',
            alignItems: 'center',
            marginVertical: 10,
        },
        container: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        txtName: {
            fontSize: 16,
            fontWeight: '500',
            lineHeight: 19.36,
            color: colorScheme.labelPrimary,
        },
        viewPeopleInput: {},
    })
