import { apiClient } from '../index'
import { GOOGLE_MAP_URL } from '../apis/GoogleMapUrl'
import appConstants from '../../../constants/const'

type TFields =
    | 'address_component'
    | 'adr_address'
    | 'business_status'
    | 'formatted_address'
    | 'geometry'
    | 'utc_offset'
    | 'icon'
    | 'icon_mask_base_uri'
    | 'icon_background_color'
    | 'name'
    | 'photo'
    | 'place_id'
    | 'plus_code'
    | 'type'
    | 'url'
    | 'vicinity'
    | 'formatted_phone_number'
    | 'international_phone_number'
    | 'opening_hours'
    | 'website'
    | 'price_level'
    | 'rating'
    | 'review'
    | 'user_ratings_total'

type TInput = 'textquery' | 'phonenumber'

interface IPlaceAutoComplete {
    input: string
    language?: string
    radius?: number
    location?: string
    strictbounds?: boolean
    types?: string // infomation: https://developers.google.com/maps/documentation/places/web-service/supported_types
}

interface IDetailPlace {
    place_id: string
    field?: TFields
    language?: string
    region?: string
}

interface ISearchPlace {
    input: string
    inputtype?: TInput
    fields?: TFields
    language?: string
    region?: string
}

export default {
    getPrediction: (data: IPlaceAutoComplete) => {
        return apiClient.get(GOOGLE_MAP_URL.getPrediction, {
            data: {
                key: appConstants.API_KEY_GOOGLE_MAP,
                ...data,
            },
            isHandleError: false,
        })
    },

    getDetailPlace: (data: IDetailPlace) => {
        return apiClient.get(GOOGLE_MAP_URL.getDetailPlace, {
            data: {
                key: appConstants.API_KEY_GOOGLE_MAP,
                ...data,
            },
            // isHandleError: false
        })
    },

    getPlaceFromText: ({
        inputtype = 'textquery',
        fields = 'geometry',
        ...data
    }: ISearchPlace) => {
        return apiClient.get(GOOGLE_MAP_URL.getPlaceFromText, {
            data: {
                key: appConstants.API_KEY_GOOGLE_MAP,
                inputtype,
                fields,
                ...data,
            },
            // isHandleError: false
        })
    },
}
