import React, { PureComponent } from 'react'

import {
    Animated,
    Image,
    StyleSheet,
    PanResponder,
    View,
    Easing,
    I18nManager,
    ViewStyle,
    ImageSourcePropType,
    PanResponderInstance,
    GestureResponderEvent,
    PanResponderGestureState,
    LayoutChangeEvent,
} from 'react-native'
import { Rect } from './Rect'
import _ from 'lodash'
import PubSub from 'pubsub-js'
import appConstants from '../../constants/const'

const TRACK_SIZE = 5
const THUMB_SIZE = 23

const DEFAULT_ANIMATION_CONFIGS = {
    spring: {
        friction: 7,
        tension: 100,
    },
    timing: {
        duration: 150,
        easing: Easing.inOut(Easing.ease),
        delay: 0,
    },
}

interface ISize {
    width: number
    height: number
}

interface IValueVisibleStyle {
    opacity?: number
}

type eventKey = 'onSlidingStart' | 'onValueChange' | 'onSlidingComplete'
type IEventProps = {
    [event in eventKey]: (value: number) => void
}

type sizeKey = '_containerSize' | '_trackSize' | '_thumbSize'
type ISizeState = {
    [size in sizeKey]: number
}

export interface ISlider {
    /**
     * Initial value of the slider. The value should be between minimumValue
     * and maximumValue, which default to 0 and 1 respectively.
     * Default value is 0.
     *
     * *This is not a controlled component*, e.g. if you don't update
     * the value, the component won't be reset to its inital value.
     */
    value?: number

    /**
     * If true the user won't be able to move the slider.
     * Default value is false.
     */
    disabled?: boolean

    /**
     * Initial minimum value of the slider. Default value is 0.
     */
    minimumValue?: number

    /**
     * Initial maximum value of the slider. Default value is 1.
     */
    maximumValue?: number

    /**
     * Step value of the slider. The value should be between 0 and
     * (maximumValue - minimumValue). Default value is 0.
     */
    step?: number

    /**
     * The color used for the track to the left of the button. Overrides the
     * default blue gradient image.
     */
    minimumTrackTintColor?: string

    /**
     * The color used for the track to the right of the button. Overrides the
     * default blue gradient image.
     */
    maximumTrackTintColor?: string

    /**
     * The color used for the thumb.
     */
    thumbTintColor?: string

    /**
     * The size of the touch area that allows moving the thumb.
     * The touch area has the same center has the visible thumb.
     * This allows to have a visually small thumb while still allowing the user
     * to move it easily.
     * The default is {width: 40, height: 40}.
     */
    thumbTouchSize?: {
        width: number
        height: number
    }

    /**
     * Callback continuously called while the user is dragging the slider.
     */
    onValueChange?: (value: number) => void

    /**
     * Callback called when the user starts changing the value (e.g. when
     * the slider is pressed).
     */
    onSlidingStart?: (value: number) => void

    /**
     * Callback called when the user finishes changing the value (e.g. when
     * the slider is released).
     */
    onSlidingComplete?: (value: number) => void

    /**
     * The style applied to the slider container.
     */
    styles?: ViewStyle

    /**
     * The style applied to the track.
     */
    trackStyle?: ViewStyle

    /**
     * The style applied to the thumb.
     */
    thumbStyle?: ViewStyle

    /**
     * Sets an image for the thumb.
     */
    thumbImage?: ImageSourcePropType

    /**
     * Set this to true to visually see the thumb touch rect in green.
     */
    debugTouchArea?: boolean

    /**
     * Set to true to animate values with default 'timing' animation type
     */
    animateTransitions?: boolean

    /**
     * Custom Animation type. 'spring' or 'timing'.
     */
    animationType?: 'spring' | 'timing'

    /**
     * Used to configure the animation parameters.  These are the same parameters in the Animated library.
     */
    animationConfig?: any
}

interface IState {
    containerSize: ISize
    trackSize: ISize
    thumbSize: ISize
    allMeasured: boolean
    value: Animated.Value
    _panResponder: PanResponderInstance
}

export default class Slider extends React.Component<ISlider, IState> {
    public static defaultProps = {
        value: 0,
        minimumValue: 0,
        maximumValue: 1,
        step: 0,
        minimumTrackTintColor: '#3f3f3f',
        maximumTrackTintColor: '#b3b3b3',
        thumbTintColor: '#343434',
        thumbTouchSize: { width: 40, height: 40 },
        debugTouchArea: false,
        animationType: 'timing',
    }

    _previousLeft: number
    _containerSize: ISize
    _trackSize: ISize
    _thumbSize: ISize

    constructor(props: ISlider) {
        super(props)
        this.state = {
            containerSize: { width: 0, height: 0 },
            trackSize: { width: 0, height: 0 },
            thumbSize: { width: 0, height: 0 },
            allMeasured: false,
            value: new Animated.Value(props.value),
            _panResponder: null,
        }
    }

    override componentDidMount() {
        this.setState({
            _panResponder: PanResponder.create({
                onStartShouldSetPanResponder:
                    this._handleStartShouldSetPanResponder,
                onMoveShouldSetPanResponder:
                    this._handleMoveShouldSetPanResponder,
                onPanResponderGrant: this._handlePanResponderGrant,
                onPanResponderMove: this._handlePanResponderMove,
                onPanResponderRelease: this._handlePanResponderEnd,
                onPanResponderTerminationRequest:
                    this._handlePanResponderRequestEnd,
                onPanResponderTerminate: this._handlePanResponderEnd,
            }),
        })
    }

    override shouldComponentUpdate(
        nextProps: Readonly<ISlider>,
        nextState: Readonly<IState>,
        nextContext: any
    ): boolean {
        if (
            !_.isEqual(nextProps, this.props) ||
            !_.isEqual(nextState, this.state)
        ) {
            return true
        }
        return false
    }

    override componentDidUpdate(prevProps: ISlider) {
        const oldValue = prevProps.value

        if (this.props.value !== oldValue) {
            if (this.props.animateTransitions) {
                this._setCurrentValueAnimated(this.props.value)
            } else {
                this._setCurrentValue(this.props.value)
            }
        }
    }

    override render() {
        const {
            minimumValue,
            maximumValue,
            minimumTrackTintColor,
            maximumTrackTintColor,
            thumbTintColor,
            thumbImage,
            styles,
            trackStyle,
            thumbStyle,
            debugTouchArea,
            onValueChange,
            thumbTouchSize,
            animationType,
            animateTransitions,
            ...other
        } = this.props
        const { value, containerSize, thumbSize, allMeasured } = this.state
        const mainStyles = { ...defaultStyles, ...styles }
        const thumbLeft = value.interpolate({
            inputRange: [minimumValue, maximumValue],
            outputRange: I18nManager.isRTL
                ? [0, -(containerSize.width - thumbSize.width)]
                : [0, containerSize.width - thumbSize.width],
            // extrapolate: 'clamp',
        })
        const minimumTrackWidth = value.interpolate({
            inputRange: [minimumValue, maximumValue],
            outputRange: [0, containerSize.width - thumbSize.width],
            // extrapolate: 'clamp',
        })
        const valueVisibleStyle: IValueVisibleStyle = {}
        if (!allMeasured) {
            valueVisibleStyle.opacity = 0
        }

        const minimumTrackStyle = {
            position: 'absolute' as const,
            width: Animated.add(minimumTrackWidth, thumbSize.width / 2),
            backgroundColor: minimumTrackTintColor,
            ...valueVisibleStyle,
        }

        const touchOverflowStyle = this._getTouchOverflowStyle()

        return (
            <View
                {...other}
                style={[mainStyles.container, styles]}
                onLayout={this._measureContainer}
            >
                <View
                    style={[
                        { backgroundColor: maximumTrackTintColor },
                        mainStyles.track,
                        trackStyle,
                    ]}
                    renderToHardwareTextureAndroid
                    onLayout={this._measureTrack}
                />
                <Animated.View
                    renderToHardwareTextureAndroid
                    style={[mainStyles.track, trackStyle, minimumTrackStyle]}
                />
                <Animated.View
                    onLayout={this._measureThumb}
                    renderToHardwareTextureAndroid
                    style={[
                        { backgroundColor: thumbTintColor },
                        mainStyles.thumb,
                        thumbStyle,
                        {
                            transform: [
                                { translateX: thumbLeft },
                                { translateY: 0 },
                            ],
                            ...valueVisibleStyle,
                        },
                    ]}
                >
                    {this._renderThumbImage()}
                </Animated.View>
                <View
                    renderToHardwareTextureAndroid
                    style={[defaultStyles.touchArea, touchOverflowStyle]}
                    {...(this.state._panResponder && {
                        ...this.state._panResponder.panHandlers,
                    })}
                >
                    {debugTouchArea === true &&
                        this._renderDebugThumbTouchRect(minimumTrackWidth)}
                </View>
            </View>
        )
    }

    _handleStartShouldSetPanResponder = (
        e: GestureResponderEvent /* gestureState: Object */
    ): boolean => {
        const test = this._thumbHitTest(e)
        if (test) {
            PubSub.publish(appConstants.HANDLE_SCROLL_OFF)
        }
        // Should we become active when the user presses down on the thumb?
        return test
    }

    _handleMoveShouldSetPanResponder(/* e: Object, gestureState: Object */): boolean {
        // Should we become active when the user moves a touch over the thumb?
        return false
    }

    _handlePanResponderGrant = (/* e: Object, gestureState: Object */) => {
        this._previousLeft = this._getThumbLeft(this._getCurrentValue())
        this._fireChangeEvent('onSlidingStart')
    }

    _handlePanResponderMove = (
        e: GestureResponderEvent,
        gestureState: PanResponderGestureState
    ) => {
        if (this.props.disabled) {
            return
        }

        this._setCurrentValue(this._getValue(gestureState))
        this._fireChangeEvent('onValueChange')
    }

    _handlePanResponderRequestEnd(
        e: GestureResponderEvent,
        gestureState: PanResponderGestureState
    ) {
        // Should we allow another component to take over this pan?
        return false
    }

    _handlePanResponderEnd = (
        e: GestureResponderEvent,
        gestureState: PanResponderGestureState
    ) => {
        if (this.props.disabled) {
            return
        }
        PubSub.publish(appConstants.HANDLE_SCROLL_ON)
        this._setCurrentValue(this._getValue(gestureState))
        this._fireChangeEvent('onSlidingComplete')
    }

    _measureContainer = (x: LayoutChangeEvent) => {
        this._handleMeasure('containerSize', x)
    }

    _measureTrack = (x: LayoutChangeEvent) => {
        this._handleMeasure('trackSize', x)
    }

    _measureThumb = (x: LayoutChangeEvent) => {
        this._handleMeasure('thumbSize', x)
    }

    _handleMeasure = (name: string, x: LayoutChangeEvent) => {
        const { width, height } = x.nativeEvent.layout
        const size = { width, height }

        const storeName = `_${name}`
        const currentSize = this[storeName as keyof ISizeState]
        if (
            currentSize &&
            width === currentSize.width &&
            height === currentSize.height
        ) {
            return
        }
        this[storeName as keyof ISizeState] = size

        if (this._containerSize && this._trackSize && this._thumbSize) {
            this.setState({
                containerSize: this._containerSize,
                trackSize: this._trackSize,
                thumbSize: this._thumbSize,
                allMeasured: true,
            })
        }
    }

    _getRatio = (value: number) =>
        (value - this.props.minimumValue) /
        (this.props.maximumValue - this.props.minimumValue)

    _getThumbLeft = (value: number): number => {
        const nonRtlRatio = this._getRatio(value)
        const ratio = I18nManager.isRTL ? 1 - nonRtlRatio : nonRtlRatio
        return (
            ratio *
            (this.state.containerSize.width - this.state.thumbSize.width)
        )
    }

    _getValue = (gestureState: PanResponderGestureState) => {
        const { step, minimumValue, maximumValue } = this.props
        const length =
            this.state.containerSize.width - this.state.thumbSize.width
        const thumbLeft = this._previousLeft + gestureState.dx

        const nonRtlRatio = thumbLeft / length
        const ratio = I18nManager.isRTL ? 1 - nonRtlRatio : nonRtlRatio

        if (step) {
            return Math.max(
                minimumValue,
                Math.min(
                    maximumValue,
                    minimumValue +
                        Math.round(
                            (ratio * (maximumValue - minimumValue)) / step
                        ) *
                            step
                )
            )
        }
        return Math.max(
            minimumValue,
            Math.min(
                maximumValue,
                ratio * (maximumValue - minimumValue) + minimumValue
            )
        )
    }

    _getCurrentValue = (): number => {
        return (this.state.value as any).__getValue()
    }

    _setCurrentValue = (value: number) => {
        this.state.value.setValue(value)
    }

    _setCurrentValueAnimated = (value: number) => {
        const { animationType } = this.props
        const animationConfig = Object.assign(
            {},
            DEFAULT_ANIMATION_CONFIGS[animationType],
            this.props.animationConfig,
            {
                toValue: value,
            }
        )

        Animated[animationType](this.state.value, animationConfig).start()
    }

    _fireChangeEvent = (event: string) => {
        if (this.props[event as keyof IEventProps]) {
            this.props[event as keyof IEventProps](this._getCurrentValue())
        }
    }

    _getTouchOverflowSize = () => {
        const state = this.state
        const props = this.props

        const size: ISize = {
            width: 0,
            height: 0,
        }
        if (state.allMeasured === true) {
            size.width = Math.max(
                0,
                props.thumbTouchSize.width - state.thumbSize.width
            )
            size.height = Math.max(
                0,
                props.thumbTouchSize.height - state.containerSize.height
            )
        }

        return size
    }

    _getTouchOverflowStyle = () => {
        const { width, height } = this._getTouchOverflowSize()

        const touchOverflowStyle = {} as ViewStyle
        if (width !== undefined && height !== undefined) {
            const verticalMargin = -height / 2
            touchOverflowStyle.marginTop = verticalMargin
            touchOverflowStyle.marginBottom = verticalMargin

            const horizontalMargin = -width / 2
            touchOverflowStyle.marginLeft = horizontalMargin
            touchOverflowStyle.marginRight = horizontalMargin
        }

        if (this.props.debugTouchArea === true) {
            touchOverflowStyle.backgroundColor = 'orange'
            touchOverflowStyle.opacity = 0.5
        }

        return touchOverflowStyle
    }

    _thumbHitTest = (e: GestureResponderEvent) => {
        const nativeEvent = e.nativeEvent
        const thumbTouchRect = this._getThumbTouchRect()

        return thumbTouchRect.containsPoint(
            nativeEvent.locationX,
            nativeEvent.locationY
        )
    }

    _getThumbTouchRect = () => {
        const { thumbSize, containerSize } = this.state
        const props = this.props
        const touchOverflowSize = this._getTouchOverflowSize()

        return new Rect(
            touchOverflowSize.width / 2 +
                this._getThumbLeft(this._getCurrentValue()) +
                (thumbSize.width - props.thumbTouchSize.width) / 2,
            touchOverflowSize.height / 2 +
                (containerSize.height - props.thumbTouchSize.height) / 2,
            props.thumbTouchSize.width,
            props.thumbTouchSize.height
        )
    }

    _renderDebugThumbTouchRect = (
        thumbLeft: Animated.AnimatedInterpolation
    ) => {
        const thumbTouchRect = this._getThumbTouchRect()
        const positionStyle = {
            left: thumbLeft,
            top: thumbTouchRect.y,
            width: thumbTouchRect.width,
            height: thumbTouchRect.height,
        }

        return (
            <Animated.View
                style={[defaultStyles.debugThumbTouchArea, positionStyle]}
                pointerEvents="none"
            />
        )
    }

    _renderThumbImage = () => {
        const { thumbImage } = this.props

        if (!thumbImage) return null

        return <Image source={thumbImage} />
    }
}

const defaultStyles = StyleSheet.create({
    container: {
        height: 40,
        justifyContent: 'center',
    },
    track: {
        height: TRACK_SIZE,
        borderRadius: TRACK_SIZE / 2,
    },
    thumb: {
        position: 'absolute',
        width: THUMB_SIZE,
        height: THUMB_SIZE,
        borderRadius: THUMB_SIZE / 2,
    },
    touchArea: {
        position: 'absolute',
        backgroundColor: 'transparent',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    debugThumbTouchArea: {
        position: 'absolute',
        backgroundColor: 'green',
        opacity: 0.5,
    },
})
