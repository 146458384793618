//
//  ObjectRepository.ts
//
//  Created by thaitd96 on 2022-08-10 14:24
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import {
    map,
    Observable,
    tap,
    share,
    startWith,
    merge,
    from,
    filter,
    of,
    catchError,
} from 'rxjs'
import { Nullable } from '@appformula/shared-foundation-x'
import * as localForage from 'localforage'
import { docData } from '@appformula/shared-rx-firebase'

export class AnyObservableObjectRepository<O> {
    get objectSnapshot(): Nullable<O> {
        return this._snapshot
    }

    get object(): Observable<O> {
        return this.objectObservable
    }

    readonly objectId: string

    private objectObservable: Observable<O>
    private _snapshot: Nullable<O>

    constructor(
        objectObservable: Observable<O>,
        object: Nullable<O>,
        objectId: string
    ) {
        this._snapshot = object
        this.objectId = objectId
        const objectCacheName = `__LocalObject_${objectId}`

        let cachedItem: Observable<O>
        if (object) {
            cachedItem = of(object)
        } else {
            cachedItem = from(localForage.getItem(objectCacheName)).pipe(
                map((e) => e as unknown as O),
                startWith(object),
                filter((e) => e !== undefined),
                filter(isDefined),
                catchError((_) => {
                    return of(undefined)
                })
            )
        }

        const firebaseObject = objectObservable.pipe(
            tap({
                next: (newPage) => {
                    this._snapshot = newPage
                    localForage.setItem(objectCacheName, newPage).catch(() => {
                        return
                    })
                },
            })
        )

        this.objectObservable = merge(cachedItem, firebaseObject).pipe(share())
    }
}

export class FirestoreDocObjectRepository<
    O
> extends AnyObservableObjectRepository<O> {
    constructor(
        docRef: firebase.default.firestore.DocumentReference,
        initialObject: Nullable<O>,
        objectId: string
    ) {
        const docObject = docData(docRef).pipe(
            map((data) => data as unknown as O)
        )
        super(docObject, initialObject, objectId)
    }
}

export function isDefined<T>(
    arg: T | null | undefined
): arg is T extends null | undefined ? never : T {
    return arg !== null && arg !== undefined
}
