import { View, StyleSheet, Image } from 'react-native'
import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { CollectionPageStore } from '@appformula/app-studio-core/src'
import { MapLayout as MapLayoutDescriptor } from '@appformula/app-descriptor/src'
import { Theme, useTheme } from '../../../../styles/theme'
import { pagesIcon } from '../../../../assets/icons/icon-pages'
import appConstants from '../../../../constants/const'

const IconGroupItem = observer(
    (props: { pageStore: CollectionPageStore; typeValue: string }) => {
        const { pageStore, typeValue } = props
        const groupsDisplay = Object.values(
            (pageStore.activeLayout as MapLayoutDescriptor).groupsDisplay || {}
        )

        const grInfo = useMemo(() => {
            const result = groupsDisplay.find(
                (i) => i.columnValue == typeValue
            )
            return result
        }, [groupsDisplay, typeValue])

        const colorWrapIcon = grInfo?.color || '#cccccc'

        const theme = useTheme()
        const styles = useMemo(
            () => createStyles(theme, colorWrapIcon),
            [theme, colorWrapIcon]
        )

        return (
            <View style={styles.wrapIconView}>
                {grInfo?.icon && (
                    <Image
                        style={styles.wrapIcon}
                        source={pagesIcon[grInfo?.icon]}
                        resizeMode={'contain'}
                    />
                )}
            </View>
        )
    }
)

export default IconGroupItem

const createStyles = (
    { colorScheme, roundness, typography }: Theme,
    colorWrapIcon: string
) =>
    StyleSheet.create({
        wrapIconView: {
            backgroundColor: colorWrapIcon,
            height: 34,
            width: 34,
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 17,
            marginRight: appConstants.PADDING_COMMON,
        },
        wrapIcon: {
            height: 24,
            width: 24,
        },
    })
