import React from 'react'
import MapViewRN from './MapViewRN'
import { MapStore } from '@appformula/app-studio-core/src'
import { ARNCustomDetailPageStore } from '../../ARNCustomDetailPageStore'
import { ARNMapStore } from './ARNMapStore'
import { useBindingCommon } from '../../../collection/components/hooks/useBindingCommon'
import { Nullable } from '@appformula/shared-foundation-x/src'
import { Binding } from '@appformula/app-descriptor/src'
import { observer } from 'mobx-react-lite'

const Map = observer(
    (props: {
        map: MapStore
        pageStore: ARNCustomDetailPageStore
        item?: Record<string, unknown>
    }) => {
        const { map, pageStore, item } = props
        const isHidden = useBindingCommon(map.isHidden, item)

        return !isHidden ? (
            <MapViewRN
                mapStore={map}
                arnMapStore={
                    pageStore.childComponentDetailStore(map) as ARNMapStore
                }
                address={map.address as unknown as Nullable<Binding<string>>}
            />
        ) : null
    }
)

export default Map
