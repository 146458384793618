//
//  ARNPeopleLookupFormStore.ts
//
//  Created by thaitd on 2022-08-12 14:08.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { action, makeObservable, observable } from 'mobx'
import { Subject, Subscription } from 'rxjs'
import { FormulaManager } from '../../../../utils/integrate-formula/FormulaManager'
import { ARNFormPageStore, PeopleLookupFormData } from '../../ARNFormPageStore'
import { BaseARNFormStore } from '../BaseARNFormStore'
import { People } from './PeopleLookupFormInput'

export class ARNPeopleLookupFormStore implements BaseARNFormStore {
    readonly formPageId: string
    readonly componentId: string
    peoples: People[]
    arnFormPageStore: ARNFormPageStore
    selectedPeopleChange: Subject<void>
    formulaManager?: FormulaManager

    get selectedPeoples(): People[] {
        return this.peoples.filter((item) => item.selected) || []
    }

    constructor(
        arnFormPageStore: ARNFormPageStore,
        formPageId: string,
        componentId: string,
        initData?: PeopleLookupFormData,
        formulaManager?: FormulaManager
    ) {
        this.arnFormPageStore = arnFormPageStore
        this.formPageId = formPageId
        this.componentId = componentId
        this.peoples = initData?.peoples ?? []
        this.selectedPeopleChange = new Subject()
        this.formulaManager = formulaManager

        makeObservable(this, {
            peoples: observable,
            setPeoples: action,
            setChangeSelectedPeopleLookup: action,
        })
    }

    startListeningEvents(): Subscription[] {
        const selectedPeopleChangeSubscription =
            this.selectedPeopleChange.subscribe({
                next: () => {
                    this.arnFormPageStore.clearFormScreenError(this.componentId)
                },
            })

        return [selectedPeopleChangeSubscription]
    }

    setPeoples(peoples: People[]) {
        this.peoples = peoples
    }

    setChangeSelectedPeopleLookup = (index: number) => {
        this.peoples[index].selected = !this.peoples[index].selected
        this.selectedPeopleChange.next()
    }

    async validate(): Promise<void> {
        const componentStore = this.arnFormPageStore.pageStore.componentStore(
            this.componentId
        )

        if (componentStore.required && !this.selectedPeoples?.length) {
            return Promise.reject(`${componentStore.titleName} is required!`)
        }
    }
}
