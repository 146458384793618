//
//  Loading.tsx
//
//  Created by thaitd96 on 2022-22-04 09:30.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React from 'react'
import { ActivityIndicator, StyleSheet, View } from 'react-native'
import { useTheme } from '../styles/theme'

type LoadingProps = {
    color?: string
}

export const Loading: React.FC<LoadingProps> = (props: LoadingProps) => {
    const theme = useTheme()
    return (
        <View style={styles.loading}>
            <ActivityIndicator
                color={props.color ?? theme.colorScheme.accentColor}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    loading: { flex: 1, justifyContent: 'center' },
})
