import { Observable, of } from 'rxjs'
import { OperationConstraint } from './OperationConstraint'

export class NetworkConstraint implements OperationConstraint {
    isSatisfy: Observable<boolean>

    constructor() {
        this.isSatisfy = of(true)
    }
}

export const networkConstraint = new NetworkConstraint()
