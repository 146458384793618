//
//  ButtonMoreActions.web.tsx
//
//  Created by thaitd96 on 2022-09-07 16:50.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React, { useCallback } from 'react'
import { ItemAction } from '@appformula/app-descriptor/src'
import { observer } from 'mobx-react-lite'
import { usePressItemAction } from '../../pages/collection/components/hooks/usePressItemAction'
import { actionIcon } from '../../assets/icons/icon-actions'
import { COLORS } from '../../assets/colors'
import { ImageStyle } from 'react-native'
import ContextMenu from '../context-menu/ContextMenu'

export const ButtonMoreActions = observer(
    (props: {
        itemActions: ItemAction[]
        triggerComponent: JSX.Element
        itemData: Record<string, unknown>
    }) => {
        const { itemActions, triggerComponent, itemData } = props
        const onPressItemAction = usePressItemAction(itemData)

        const onChangeSelection = useCallback(
            (item: Record<string, unknown>) => {
                onPressItemAction((item as unknown as ItemAction).onTapAction)
            },
            [onPressItemAction]
        )

        return (
            <ContextMenu
                triggerComponent={triggerComponent}
                actions={itemActions as unknown as Record<string, unknown>[]}
                getIcon={getIconFromItemAction}
                getText={getText}
                onSelect={onChangeSelection}
                iconStyle={iconStyle}
            />
        )
    }
)

function getIconFromItemAction(item: Record<string, unknown>) {
    return actionIcon[(item as unknown as ItemAction)?.icon]
}

function getText(item: Record<string, unknown>) {
    return (item as unknown as ItemAction)?.title ?? ''
}

const iconStyle: ImageStyle = {
    height: 34,
    width: 34,
    resizeMode: 'contain',
    tintColor: COLORS.BLACK,
}
