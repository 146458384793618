import AsyncStorage from '@react-native-async-storage/async-storage'

export const storeData = async (
    key: string,
    value: number | string | Record<string, unknown> | Record<string, unknown>[]
) => {
    try {
        const jsonValue = JSON.stringify(value)
        await AsyncStorage.setItem(key, jsonValue)
    } catch (e) {
        throw new Error('storeData error')
    }
}

export const getData = async (value: string) => {
    try {
        const jsonValue = await AsyncStorage.getItem(value)
        return jsonValue != null ? JSON.parse(jsonValue) : null
    } catch (e) {
        throw new Error('getData error')
    }
}

export const removeItem = async (key: string) => {
    try {
        await AsyncStorage.removeItem(key)
    } catch (e) {
        throw new Error('removeItem')
    }
}

export const getItemsWithPrefix = async (keyPrefix: string) => {
    try {
        const keys = await AsyncStorage.getAllKeys()
        console.info('keys', keys)
        const results = await AsyncStorage.multiGet(
            keys.filter((key) => key.includes(keyPrefix))
        )

        return results.map((result) => JSON.parse(result[1]))
    } catch (error) {
        throw new Error('getItemWithPrefix')
    }
}

export const removeItemsWithPrefix = async (keyPrefix: string) => {
    try {
        const keys = await AsyncStorage.getAllKeys()
        console.info('keys', keys)
        await AsyncStorage.multiRemove(
            keys.filter((key) => key.includes(keyPrefix))
        )
    } catch (error) {
        throw new Error('getItemWithPrefix')
    }
}
