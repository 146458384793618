//
//  FilterSwitcher.tsx
//
//  Created by thaitd96 on 2022-01-08 10:08.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React from 'react'
import { FilterBoolean } from './filter-boolean/FilterBoolean'
import { FilterDate } from './filter-date/FilterDate'
import { FilterSlider } from './filter-slider/FilterSlider'
import { FilterString } from './filter-string/FilterString'
import { OptionFilter } from './filter-option/FilterOption'
import {
    CollectionFilterStore,
    FilterData,
    FilterOptionData,
    TableSchemaType,
} from './CollectionFilterStore'
import { observer } from 'mobx-react-lite'
import { FilterSliderStore } from './filter-slider/FilterSliderStore'
import { FilterStringStore } from './filter-string/FilterStringStore'
import { FilterBooleanStore } from './filter-boolean/FilterBooleanStore'
import { FilterDateStore } from './filter-date/FilteDateStore'
import { FilterOptionStore } from './filter-option/FilterOptionStore'

export const FilterSwitcher = observer(
    (props: {
        item: FilterData
        collectionFilterStore: CollectionFilterStore
        itemSchema?: TableSchemaType
    }) => {
        if (props.itemSchema?.enum && props.itemSchema?.enum.length > 0) {
            return (
                <OptionFilter
                    collectionFilterStore={props.collectionFilterStore}
                    filterOptionStore={
                        props.collectionFilterStore.childComponentStore({
                            ...props.item,
                            availableOptions: props.itemSchema?.enum,
                        } as FilterOptionData) as FilterOptionStore
                    }
                />
            )
        }
        switch (props.item.columnFilter.dataType) {
            case 'String':
                return (
                    <FilterString
                        collectionFilterStore={props.collectionFilterStore}
                        filterStringStore={
                            props.collectionFilterStore.childComponentStore({
                                ...props.item,
                            }) as FilterStringStore
                        }
                    />
                )
            case 'Number':
                return (
                    <FilterSlider
                        collectionFilterStore={props.collectionFilterStore}
                        filterSliderStore={
                            props.collectionFilterStore.childComponentStore({
                                ...props.item,
                            }) as FilterSliderStore
                        }
                    />
                )
            case 'List':
                return null
            case 'Boolean':
                return (
                    <FilterBoolean
                        collectionFilterStore={props.collectionFilterStore}
                        filterBooleanStore={
                            props.collectionFilterStore.childComponentStore({
                                ...props.item,
                            }) as FilterBooleanStore
                        }
                    />
                )
            case 'DateTime':
                return (
                    <FilterDate
                        collectionFilterStore={props.collectionFilterStore}
                        filterDateStore={
                            props.collectionFilterStore.childComponentStore({
                                ...props.item,
                            }) as FilterDateStore
                        }
                    />
                )
            default:
                return null
        }
    }
)
