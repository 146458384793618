//
//  useBindingHaveItemData.tsx
//
//  Created by thaitd96 on 2022-09-09 15:13.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { Binding, BindingValue } from '@appformula/app-descriptor/src'
import { Nullable } from '@appformula/shared-foundation-x/src'
import { useState, useEffect, useCallback, useContext } from 'react'
import { FormulaManagerContext } from '../../../../context/FormulaManagerContext'
import { FormulaManager } from '../../../../utils/integrate-formula/FormulaManager'
import { TransformBinding } from '../../../../utils/transform-binding/TransformBinding'
type ItemData = {
    [key: string]: unknown
}

export function useBindingHaveItemData(
    binding: Nullable<Binding<BindingValue>>,
    itemData: ItemData
) {
    const formulaManager: Nullable<FormulaManager> = useContext(
        FormulaManagerContext
    )
    const [value, setValue] = useState<unknown>(undefined)

    const getTitle = useCallback(
        async (binding: Binding<BindingValue>, itemData: ItemData) => {
            try {
                setValue(
                    (await TransformBinding(
                        binding,
                        formulaManager,
                        itemData
                    )) as string
                )
            } catch (error) {
                console.info('error----', error)
            }
        },
        [formulaManager]
    )

    useEffect(() => {
        binding && itemData && getTitle(binding, itemData)
    }, [binding, getTitle, itemData])

    return value
}
