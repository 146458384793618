import appConstants from '../../../constants/const'
import { SaveToStorageError } from '../../../pages/form/ARNFormPageStore'
import getFireStore from '../../firebase/firestore/getFireStore'
import {
    allUploadsInfoByForm,
    UploadInfo,
} from '../../form-upload-storage/FormUploadInfo'
import { ConstantConstraint } from '../constraint/ConstantConstraint'
import { OperationConstraint } from '../constraint/OperationConstraint'
import { Operation, OperationRawData } from './Operation'

type FileUploaded = {
    assetUrl: string
}

export type DataSaveItemOperation = {
    dataToSend: Record<string, unknown>
    formOperationId: string
    tableId?: string
}

export class SaveDataToStorageOperation implements Operation {
    id: string
    constraints: Array<OperationConstraint> = [new ConstantConstraint(true)]
    data: DataSaveItemOperation
    tableId: string

    constructor(rawData: OperationRawData) {
        this.id = rawData.operationID
        this.data = rawData.data as unknown as DataSaveItemOperation
    }

    async run(): Promise<void> {
        try {
            const allUploadsInfo = await allUploadsInfoByForm(
                this.data.formOperationId
            )
            this.updateDataToSendWhenUploadDone(allUploadsInfo)
            return this.sendDataToStorage(this.id, this.data.dataToSend)
        } catch (error) {
            throw new Error('sendDataToStorage')
        }
    }

    toRawData(): OperationRawData {
        const saveDataToStorageRawData = {
            operationID: this.id,
            data: this.data,
            type: 'saveDataToStorage',
        } as OperationRawData

        return saveDataToStorageRawData
    }

    async sendDataToStorage(
        id: string,
        dataToSend: Record<string, unknown>
    ): Promise<void> {
        try {
            await (await getFireStore())
                .collection('ProductsSortFilterSaved')
                .doc(id)
                .set(dataToSend)

            Promise.resolve()
        } catch (error) {
            Promise.reject({
                type: appConstants.TYPE_ERROR.SEND_TO_STORAGE,
                message: error,
            } as SaveToStorageError)
        }
    }

    setDataToSend(dataToSend: Record<string, unknown>): void {
        this.data = { ...this.data, dataToSend }
    }

    setTableId(tableId: string) {
        this.data = { ...this.data, tableId }
    }

    private updateDataToSendWhenUploadDone(allUploadsInfo: UploadInfo[]) {
        allUploadsInfo.forEach((uploadInfo) => {
            this.data.dataToSend[uploadInfo.targetColumnName] = [
                ...(this.data.dataToSend[
                    uploadInfo.targetColumnName
                ] as Array<FileUploaded>),
                { assetUrl: uploadInfo.assetUrl },
            ]
        })
    }
}
