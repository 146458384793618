//
//  MapListItem.tsx
//
//  Created by thaitd96 on 2022-09-05 15:16.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React, { useCallback, useEffect, useMemo } from 'react'
import { Image, StyleSheet, Text, View } from 'react-native'
import { Theme, useTheme } from '../../../../styles/theme'
import appConstants from '../../../../constants/const'
import image from '../../../../assets/icons'
import { observer } from 'mobx-react-lite'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { CollectionPageStore } from '@appformula/app-studio-core/src'
import { MapLayout } from '@appformula/app-descriptor/src'
import { Accessory } from '../accessory/Accessory'
import { useBindingHaveItemData } from '../hooks/useBindingHaveItemData'
import { useGetItemFromIndex } from '../hooks/useGetItemFromIndex'
import {
    ARNCollectionPageStore,
    IItemDataMap,
} from '../../ARNCollectionPageStore'
import { MapLayoutStore } from './MapLayoutStore'
import IconGroupItem from './IconGroupItem'

export const MapListItem = observer(
    (props: {
        item: IItemDataMap
        index: number
        isGrouping: boolean
        onPress: (item: Record<string, unknown>) => void
        pageStore: CollectionPageStore
        arnCollectionPageStore: ARNCollectionPageStore
        mapLayoutStore: MapLayoutStore
    }) => {
        const { item, isGrouping, pageStore, mapLayoutStore, index } = props
        const { accessory, address } = pageStore.activeLayout as MapLayout

        const groupingColumn = (pageStore.activeLayout as MapLayout)
            .groupingColumn

        const itemData = useGetItemFromIndex(
            props.pageStore,
            item.index,
            props.arnCollectionPageStore.queryString,
            item?.type || undefined
        )

        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])

        const typeValue = (itemData?.[groupingColumn] || '') as string

        const title = useBindingHaveItemData(
            (props.pageStore.activeLayout as MapLayout).title,
            itemData
        )

        const addressString = useBindingHaveItemData(
            (props.pageStore.activeLayout as MapLayout).address,
            itemData
        )

        useEffect(() => {
            if (index < 10 && addressString) {
                mapLayoutStore.setListAddressInit({
                    addressString: addressString as string,
                    type: typeValue,
                })
            }
        }, [addressString, groupingColumn, index, item, mapLayoutStore, typeValue])

        const Title = useCallback(() => {
            return (
                <Text
                    numberOfLines={2}
                    ellipsizeMode="tail"
                    style={styles.title}
                >
                    {title ?? ''}
                </Text>
            )
        }, [styles.title, title])

        const Address = useCallback(() => {
            return (
                <Text
                    numberOfLines={2}
                    ellipsizeMode="tail"
                    style={styles.subTitle}
                >
                    {addressString ?? ''}
                </Text>
            )
        }, [addressString, styles.subTitle])

        const IconInfo = useMemo(
            () => (
                <Image
                    style={styles.icon}
                    source={image.info}
                    resizeMode={'contain'}
                />
            ),
            [styles.icon]
        )

        const onPress = () => {
            props.onPress && props.onPress(itemData)
            mapLayoutStore.reverseAndSetGeocodeItem({
                addressString: addressString as string,
                type: typeValue,
            })
        }

        return (
            <TouchableOpacity style={styles.item} onPress={onPress}>
                {isGrouping && (
                    <IconGroupItem
                        pageStore={props.pageStore}
                        typeValue={typeValue}
                    />
                )}
                <View style={styles.rightItem}>
                    <Title />
                    {address && <Address />}
                    {!!accessory && (
                        <Accessory
                            accessory={
                                (props.pageStore.activeLayout as MapLayout)
                                    ?.accessory
                            }
                            item={itemData}
                        />
                    )}
                </View>
                {IconInfo}
            </TouchableOpacity>
        )
    }
)

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        subTitle: {
            fontWeight: '500',
            fontSize: 14,
            lineHeight: 21,
            color: colorScheme.labelSecondary,
            marginTop: 4,
            marginBottom: 2,
        },
        title: {
            fontWeight: '500',
            fontSize: 16,
            marginBottom: 2,
            color: colorScheme.labelPrimary,
        },
        rightItem: {
            justifyContent: 'space-between',
            flex: 1,
        },
        item: {
            padding: appConstants.PADDING_COMMON,
            paddingRight: 8,
            flexDirection: 'row',
            alignItems: 'center',
            borderColor: colorScheme.separator,
            borderTopWidth: 1,
            backgroundColor: colorScheme.backgroundL1,
        },
        icon: {
            tintColor: colorScheme.accentColor,
        },
    })
