// import appConstants from '../../../constants/const'
import cache from '../../cache'
// import { CDP_URL } from './CDPUrl'
// import { FORM_URL } from './FormUrl'
// import { GOOGLE_MAP_URL } from './GoogleMapUrl'
// import { OPENGRAPHSCRAPER_URL } from './OpenGraphScraperUrl'

// const GATE_ASSETS = [FORM_URL.uploadFile]

// const GATE_GOOGLE_MAP = [...Object.values(GOOGLE_MAP_URL)]

// const GATE_CLOUDFUNCTION = [OPENGRAPHSCRAPER_URL.getWeblinkInfo]

// const GATE_CDP = [CDP_URL.userEvent]

function getGateConfig(url: string) {
    const baseUrl = `${cache.baseUrl}/api`
    const version = 'v1'
    // if (GATE_ASSETS.includes(url)) {
    //     baseUrl = appConstants.API.BASE_URL_ASSETS
    //     version = 'v1'
    // }
    // if (GATE_GOOGLE_MAP.includes(url)) {
    //     baseUrl = appConstants.API.BASE_URL_GOOGLE_MAP
    //     version = 'v1'
    // }
    // if (GATE_CLOUDFUNCTION.includes(url)) {
    //     baseUrl = appConstants.API.BASE_URL_CLOUDFUNCTION
    //     version = 'v1'
    // }
    // if (GATE_CDP.includes(url)) {
    //     baseUrl = appConstants.API.BASE_URL_CDP
    //     version = 'v1'
    // }

    return {
        baseUrl,
        version,
    }
}

function setApiPath(url: string) {
    const { version, baseUrl } = getGateConfig(url)
    let fullPath = baseUrl
    if (version) {
        fullPath = `${fullPath}/${version}`
    }
    return `${fullPath}/${url}`
}

export default {
    setApiPath,
}
