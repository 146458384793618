//
//  CalendarLayoutStore.ts
//
//  Created by thaitd96 on 2022-06-13 10:30.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import dayjs from 'dayjs'
import _ from 'lodash'
import { makeObservable, observable, action } from 'mobx'
import { BehaviorSubject } from 'rxjs'
import { ListDataCalendarWithSection } from '../../ARNCollectionPageStore'
import { DayCalendarStore } from './day-calendar/DayCalendarStore'
import { MonthCalendarStore } from './month-calendar/MonthCalendarStore'
import { WeekCalendarStore } from './week-calendar/WeekCalendarStore'

export type CalendarMode = 'D' | 'W' | 'M'

export interface IWeekPicked {
    startDate: Date
    endDate: Date
}
export class CalendarLayoutStore {
    cachedWeekCalendarStore: WeekCalendarStore
    cachedDayCalendarStore: DayCalendarStore
    cachedMonthCalendarStore: MonthCalendarStore

    calendarMode: CalendarMode = 'D'
    calendarModeBeS = new BehaviorSubject<CalendarMode>(this.calendarMode)
    activeSections: string[] = []
    activeSectionsBeS = new BehaviorSubject<string[]>(this.activeSections)
    isOpenTimePicker: boolean = false
    monthYear: Date = dayjs(new Date()).startOf('month').toDate()
    monthYearBeS = new BehaviorSubject<Date>(this.monthYear)
    weekPicked: IWeekPicked = {
        startDate: dayjs(new Date()).startOf('isoWeek').toDate(),
        endDate: dayjs(new Date()).endOf('isoWeek').toDate(),
    }
    weekPickedBeS = new BehaviorSubject<IWeekPicked>(this.weekPicked)

    constructor() {
        makeObservable(this, {
            calendarMode: observable,
            activeSections: observable,
            isOpenTimePicker: observable,
            monthYear: observable,
            weekPicked: observable,
            setCalendarMode: action,
            changeIsActive: action,
            openTimePicker: action,
            closeTimePicker: action,
            setMonthYear: action,
            setWeekPicked: action,
            setActiveSections: action,
            setListCalendarView: action
        })
    }

    setCalendarMode(mode: CalendarMode) {
        // Intermediate states will not become visible to observers.
        this.calendarMode = mode
        this.calendarModeBeS.next(mode)
    }

    changeIsActive(columnValue: string) {
        const i = this.activeSections.indexOf(columnValue)
        if (i >= 0) {
            this.activeSections.splice(i, 1)
        } else {
            this.activeSections.push(columnValue)
        }
        this.activeSectionsBeS.next(this.activeSections)
    }

    setActiveSections(listActiveSections: string[]) {
        this.activeSections = listActiveSections
        this.activeSectionsBeS.next(listActiveSections)
    }

    openTimePicker() {
        this.isOpenTimePicker = true
    }

    closeTimePicker() {
        this.isOpenTimePicker = false
    }

    setMonthYear(monthYear: Date) {
        this.monthYear = monthYear
        this.monthYearBeS.next(monthYear)
    }

    setWeekPicked(weekPicked: IWeekPicked) {
        this.weekPicked = weekPicked
        this.weekPickedBeS.next(weekPicked)
    }

    setListCalendarView(result: ListDataCalendarWithSection) {
        if (this.calendarMode === 'D') {
            this.cachedDayCalendarStore.setListCalendarView(result)
        } else if (this.calendarMode === 'W') {
            this.cachedWeekCalendarStore.setListCalendarView(result)
        } else if (this.calendarMode === 'M') {
            this.cachedMonthCalendarStore.setListCalendarView(result)
        }
    }

    setTotalDataLoaded(value: number) {
        if (this.calendarMode === 'D') {
            this.cachedDayCalendarStore.setTotalDataLoaded(value)
        } else if (this.calendarMode === 'W') {
            this.cachedWeekCalendarStore.setTotalDataLoaded(value)
        } else if (this.calendarMode === 'M') {
            this.cachedMonthCalendarStore.setTotalDataLoaded(value)
        }
    }

    dayCalendarStore() {
        if (this.cachedDayCalendarStore) {
            return this.cachedDayCalendarStore
        } else {
            const newDayCalendarStore = new DayCalendarStore()
            this.cachedDayCalendarStore = newDayCalendarStore

            return newDayCalendarStore
        }
    }

    weekCalendarStore() {
        if (this.cachedWeekCalendarStore) {
            return this.cachedWeekCalendarStore
        } else {
            const newWeekCalendarStore = new WeekCalendarStore()
            this.cachedWeekCalendarStore = newWeekCalendarStore

            return newWeekCalendarStore
        }
    }

    monthCalendarStore() {
        if (this.cachedMonthCalendarStore) {
            return this.cachedMonthCalendarStore
        } else {
            const newMonthCalendarStore = new MonthCalendarStore()
            this.cachedMonthCalendarStore = newMonthCalendarStore

            return newMonthCalendarStore
        }
    }
}
