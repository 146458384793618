import {
    TextInput,
    StyleSheet,
    TextInputProps,
    View,
    Text,
    ScrollView,
    Pressable,
} from 'react-native'
import React, { Fragment, useContext, useMemo, useRef, useState } from 'react'
import { Theme, useTheme } from '../../styles/theme'
import {
    Menu,
    MenuOptions,
    MenuOptionsProps,
    MenuProps,
    MenuTrigger,
    renderers,
} from 'react-native-popup-menu'
import { COLORS } from '../../assets/colors'
import firebase from 'firebase'
import { AppMetadataContext } from '../../context/AppMetadataContext'
import FastImage from '../fast-image/FastImage'

const { Popover } = renderers

interface ITextInputEmailSearch {
    menuProps?: MenuProps
    menuOptionsProps?: MenuOptionsProps
    onSelectItem?: (item: IInfo) => void
}

export interface IInfo {
    firstName: string
    lastName: string
    emailAddress: string
    profileImageURL: string
    roles: string
}

const TextInputEmailSearch = (
    props: ITextInputEmailSearch & TextInputProps
) => {
    const { menuProps, menuOptionsProps, onSelectItem } = props
    const theme = useTheme()
    const styles = useMemo(() => createStyles(theme), [theme])
    const menuRef = useRef<Menu | null>(null)
    const txtInputRef = useRef<TextInput>(null)
    const { teamId } = useContext(AppMetadataContext)
    const [listUser, setListUser] = useState<IInfo[]>([])
    const onChangeText = (text: string) => {
        if (!text) {
            onBlur()
            return
        }
        if (menuRef && !menuRef.current?.isOpen()) {
            onOpen()
        }

        firebase
            .firestore()
            .collection('teams')
            .doc(`${teamId}`)
            .collection('members')
            .where('emailAddress', '>=', text.toLowerCase())
            .where('emailAddress', '<=', text.toLowerCase() + '\uf8ff')
            .get()
            .then((querySnapshot) => {
                const listUser: IInfo[] = []
                querySnapshot?.forEach((i) => {
                    listUser.push(i.data() as IInfo)
                })
                setListUser(listUser)
            })
    }

    const renderOptions = () => {
        return (
            <ScrollView>
                {listUser.map((item, index) => renderItem(item))}
            </ScrollView>
        )
    }

    const renderItem = (item: IInfo) => {
        return (
            <Pressable key={item.emailAddress} onPress={onSelect(item)}>
                <View style={styles.wrapContent}>
                    <FastImage
                        source={{
                            uri: item?.profileImageURL || '',
                        }}
                        style={styles.imgAvatar}
                    />
                    <View>
                        <Text style={styles.txtName}>
                            {item?.firstName || ''} {item?.lastName || ''}
                        </Text>
                        <Text style={styles.txtEmail}>
                            {item?.emailAddress || ''}
                        </Text>
                    </View>
                </View>
            </Pressable>
        )
    }

    const onSelect = (item: IInfo) => () => {
        onBlur()
        txtInputRef.current?.clear()
        onSelectItem && onSelectItem(item)
    }

    const onBlur = () => {
        menuRef && menuRef.current?.close()
    }

    const onOpen = () => {
        menuRef && menuRef.current?.open()
    }

    return (
        <Fragment>
            <Menu
                ref={menuRef}
                renderer={Popover}
                rendererProps={{
                    placement: 'bottom',
                    anchorStyle: { backgroundColor: COLORS.TRANSPARENT },
                }}
                {...menuProps}
            >
                <MenuTrigger />
                <MenuOptions
                    optionsContainerStyle={styles.popup}
                    {...menuOptionsProps}
                >
                    {renderOptions()}
                </MenuOptions>
            </Menu>
            <TextInput
                ref={txtInputRef}
                placeholder="Add more"
                style={styles.txtInput}
                placeholderTextColor={theme.colorScheme.labelSecondary}
                onChangeText={onChangeText}
                {...props}
            />
        </Fragment>
    )
}

export default TextInputEmailSearch
const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        popup: {
            width: 300,
            backgroundColor: colorScheme.backgroundL2,
            paddingHorizontal: 8,
            borderRadius: 5,
        },
        txtInput: {
            flex: 1,
            fontSize: 15,
            fontWeight: '500',
            color: colorScheme.labelPrimary,
            height: 52,
        },
        imgAvatar: { width: 32, height: 32, borderRadius: 16, marginRight: 8 },
        wrapContent: {
            flexDirection: 'row',
            alignItems: 'center',
            marginVertical: 10,
        },
        txtName: {
            fontSize: 16,
            fontWeight: '500',
            lineHeight: 19.36,
            color: colorScheme.labelPrimary,
        },
        txtEmail: {
            fontSize: 13,
            fontWeight: '400',
            lineHeight: 21.45,
            color: colorScheme.labelSecondary,
        },
    })
