//
//  FilterBooleanStore.ts
//
//  Created by thaitd96 on 2022-07-28 10:40.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { Nullable } from '@appformula/shared-foundation-x/src'
import { action, makeObservable, observable } from 'mobx'
import { ColumnFilter, FilterBooleanData } from '../CollectionFilterStore'

export class FilterBooleanStore {
    readonly collectionPageId: string
    readonly columnFilter: ColumnFilter
    value: Nullable<boolean>

    constructor(
        collectionPageId: string,
        filterBooleanData: FilterBooleanData
    ) {
        this.collectionPageId = collectionPageId
        this.columnFilter = filterBooleanData.columnFilter
        this.value = filterBooleanData.value

        makeObservable(this, {
            value: observable,
            setValue: action,
        })
    }

    setValue(value: boolean) {
        this.value = value
    }
}
