import { makeAutoObservable, runInAction } from 'mobx';
import * as Font from 'expo-font';
import { InterFontFamily } from '../assets/fonts/InterFontFamily';

export class AppResourcesStore {
    isResourcesReady: boolean;

    constructor() {
        this.isResourcesReady = false;
        makeAutoObservable(this);
    }

    async loadResources(): Promise<void> {
        return Font.loadAsync(InterFontFamily.availableFonts()).then(() => {
            runInAction(() => {
                this.isResourcesReady = true;
            });
        });
    }
}
