//
//  Chart.ts
//
//  Created by thaitd96 on 2022-08-10 14:24
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { Chart, Filter } from '@appformula/app-descriptor'
import { ChartRepository } from '@appformula/app-studio-core'
import { LocalCollectionComponentRepository } from './CollectionComponent'

export class LocalChartRepository
    extends LocalCollectionComponentRepository<Chart>
    implements ChartRepository
{
    insertPreFilter(
        preFilterId: string,
        filter: Filter,
        priority: string
    ): Promise<void> {
        throw new Error('Method not implemented.')
    }
    insertColorId(colorId: string, color: string, rank: string): Promise<void> {
        throw new Error('Method not implemented.')
    }
    removeColorId(colorId: string): Promise<void> {
        throw new Error('Method not implemented.')
    }
}
