//
//  NumberScaleInput.tsx
//
//  Created by thaitd96 on 2022-20-04 11:52.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { NumberScaleInputStore } from '@appformula/app-studio-core'
import { observer } from 'mobx-react-lite'
import React, { createRef, useEffect, useMemo, useState } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import appConstants from '../../../../constants/const'
import { Theme, useTheme } from '../../../../styles/theme'
import { useBindingCommon } from '../../../collection/components/hooks/useBindingCommon'
import ItemScale, { IItemScale } from './ItemScale'

export type IScale = {
    id: string
}

interface IScaleRef {}

export const NumberScaleInput = observer(
    (props: {
        numberScaleInput: NumberScaleInputStore
        item: Record<string, unknown>
    }) => {
        const { numberScaleInput } = props
        const numberScaleInputData = numberScaleInput.object

        const title = useBindingCommon(numberScaleInputData.title, props.item)
        const initialValue = useBindingCommon(
            numberScaleInputData.initialValue,
            props.item
        )

        console.info('initialValue----', initialValue)

        const isHidden = useBindingCommon(
            numberScaleInputData.isHidden,
            props.item
        )
        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])

        const [listScale, setListScale] = useState<Array<IScale>>([])
        const [elRefs, setElRefs] = useState({})

        useEffect(() => {
            const scalesInit = Array(5)
                .fill(null)
                .map((item, index) => ({ id: `${index}` }))

            setListScale(scalesInit)
        }, [numberScaleInputData])

        const handleItemPress = (id: string, index: number) => {
            Object.keys(elRefs).forEach((key, indexT) => {
                const ref = elRefs[
                    key as keyof IScaleRef
                ] as React.RefObject<IItemScale>
                if (indexT > index) {
                    ref?.current?.unCheck()
                } else {
                    ref?.current?.check()
                }
            })
        }

        useEffect(() => {
            const temp = {}
            listScale.forEach((item, index) => {
                Object.assign(temp, { [item.id]: createRef() })
            })
            setElRefs({ ...elRefs, ...temp })
            // Do not want to run when update elRefs - prevent exceed call stack
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [listScale])

        const renderItemScale = (item: IScale, index: number) => {
            const isActive = index + 1 <= initialValue
            return (
                <ItemScale
                    ref={elRefs[item.id as keyof IScaleRef]}
                    key={item.id}
                    item={item}
                    scaleIcon={numberScaleInputData?.scaleIcon || 'star'}
                    handleItemPress={handleItemPress}
                    index={index}
                    isActiveDefault={isActive}
                />
            )
        }

        return (
            !isHidden && (
                <View style={styles.viewNumberScaleInput}>
                    <Text style={styles.titleScale} numberOfLines={1}>
                        {title}
                    </Text>
                    <View style={styles.wrapScale}>
                        {listScale.map((item, index) =>
                            renderItemScale(item, index)
                        )}
                    </View>
                </View>
            )
        )
    }
)

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        viewNumberScaleInput: {
            marginVertical: appConstants.PADDING_COMMON / 2,
        },

        titleScale: {
            fontWeight: '500',
            fontSize: 16,
            lineHeight: 19,
            marginBottom: 12,
            color: colorScheme.labelPrimary,
        },

        wrapScale: {
            flex: 1,
            borderRadius: roundness,
            borderWidth: 1,
            borderColor: colorScheme.separator,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
        },
    })
