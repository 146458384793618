//
//  WrapContextDesktop.tsx
//
//  Created by thaitd96 on 2022-11-07 16:27.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React, {
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { AppMetadataContext } from '../../../context/AppMetadataContext'
import buildConfig from '../../../../buildConfig.json'
import * as Linking from 'expo-linking'
import { apiClient } from '../../../utils/network'
import { StyleSheet, Text, View } from 'react-native'
import { Loading } from '../../../components'
import { Theme, useTheme } from '../../../styles/theme'
import { AppDescriptorStore } from '@appformula/app-studio-core/src'
import { AppDescriptorContext } from '../../../context/AppDescriptorContext'
import appConstants from '../../../constants/const'
import { DeviceOnDeskTop } from '../../../pages/collection/components/hooks/useSpecifyPlatformWindowDimensions'
import { Nullable } from '@appformula/shared-foundation-x/src'
import { WrapContextAppDesktopStore } from './WrapContextAppDesktopStore'
import { observer } from 'mobx-react-lite'
import DesktopWrapperLeftView from './components/DesktopWrapperLeftView'
import { wrapContextAppStore } from '../WrapContextAppStore'
import NotPublishScreen from '../NotPublishScreen'
interface IProps {
    wrapContextAppDesktopStore: WrapContextAppDesktopStore
    children: ReactNode
}

interface IMetadata {
    teamId: string
    appId: string
}

type StatusType = 'LOADING' | 'SUCCESS' | 'ERROR' | 'NOT_PUBLISH'

const WrapContextDesktop = observer((props: IProps) => {
    const { wrapContextAppDesktopStore } = props
    const [metaData, setMetaData] = useState<IMetadata>()
    const [descriptor, setDescriptor] =
        useState<Nullable<AppDescriptorStore>>(undefined)
    const [status, setStatus] = useState<StatusType>('LOADING')
    const theme = useTheme()
    const styles = useMemo(() => createStyles(theme), [theme])

    // FOR DEVELOPER
    const getInfoAppForDev = useCallback(() => {
        setMetaData({
            teamId: buildConfig.teamId,
            appId: buildConfig.appId,
        })
    }, [])

    const getInfoApp = async (api: string) => {
        try {
            const response = await apiClient.get(api, {
                needSetApiPath: false,
            })
            if (response.data.success) {
                const result = response?.data?.data
                // @ts-ignore: global variable
                global.teamId = result.teamId
                // @ts-ignore: global variable
                global.appId = result.appId
                setMetaData(result)
            } else {
                setStatus('ERROR')
            }
        } catch (error) {
            setStatus('ERROR')
        }
    }

    useEffect(() => {
        // FOR DEVELOPER
        if (__DEV__) {
            getInfoAppForDev()
            return
        }
        const redirectUrl = Linking.createURL(appConstants.SUFFIX_LINK_REDIRECT)
        getInfoApp(redirectUrl)
    }, [])

    const getDescriptorStore = useCallback(async () => {
        try {
            const descriptorStore =
                await wrapContextAppStore.getDescriptorStore(
                    metaData.teamId,
                    metaData.appId
                )

            if (descriptorStore) {
                setDescriptor(descriptorStore)
                setStatus('SUCCESS')
            } else {
                setStatus('NOT_PUBLISH')
            }
        } catch (error) {
            setStatus('ERROR')
        }
    }, [metaData])

    useEffect(() => {
        if (metaData) {
            wrapContextAppDesktopStore.listenAppInfo(
                metaData.teamId,
                metaData.appId
            )
            wrapContextAppDesktopStore.listenTeamInfo(metaData.teamId)
        }
        return () => {
            wrapContextAppDesktopStore.appInfoUnsubscribe?.()
            wrapContextAppDesktopStore.teamInfoUnsubscribe?.()
        }
    }, [metaData, wrapContextAppDesktopStore])

    useEffect(() => {
        if (metaData) {
            getDescriptorStore()
            wrapContextAppStore.listenPublishApp(
                metaData.teamId,
                metaData.appId
            )
        }

        return () => {
            wrapContextAppStore.publishAppUnsubscribe?.()
        }
    }, [getDescriptorStore, metaData])

    if (status === 'LOADING') {
        return <Loading />
    } else if (status === 'SUCCESS') {
        return (
            <AppMetadataContext.Provider value={metaData}>
                <AppDescriptorContext.Provider value={descriptor}>
                    <View style={styles.containerStandard}>
                        <DesktopWrapperLeftView
                            wrapContextAppDesktopStore={
                                props.wrapContextAppDesktopStore
                            }
                        />
                        <View style={styles.viewRight}>
                            <View style={styles.wrapPreview}>
                                <View style={styles.preview}>
                                    {props.children}
                                </View>
                            </View>
                        </View>
                    </View>
                </AppDescriptorContext.Provider>
            </AppMetadataContext.Provider>
        )
    } else if (status === 'NOT_PUBLISH') {
        return (
            <View style={styles.containerStandard}>
                <DesktopWrapperLeftView
                    wrapContextAppDesktopStore={
                        props.wrapContextAppDesktopStore
                    }
                />
                <View style={styles.viewRight}>
                    <View style={styles.wrapPreview}>
                        <View style={styles.preview}>
                            <NotPublishScreen />
                        </View>
                    </View>
                </View>
            </View>
        )
    } else {
        return (
            <View style={styles.container}>
                <Text style={styles.contentError}>
                    Something went wrong. Please try again.
                </Text>
            </View>
        )
    }
})

export default WrapContextDesktop

const createStyles = ({ colorScheme, roundness }: Theme) =>
    StyleSheet.create({
        preview: {
            width: DeviceOnDeskTop.width,
            height: DeviceOnDeskTop.height,
        },
        wrapPreview: {
            borderColor: colorScheme.black,
            borderWidth: 12,
            borderRadius: 48,
            paddingHorizontal: 7,
            paddingVertical: 20,
            backgroundColor: colorScheme.backgroundL1,
            overflow: 'hidden',
        },
        viewRight: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#D4DAE3',
        },
        containerStandard: { flex: 1, flexDirection: 'row' },
        container: {
            flex: 1,
            backgroundColor: colorScheme.backgroundL1,
            justifyContent: 'center',
            alignItems: 'center',
        },

        contentError: {
            color: colorScheme.labelPrimary,
        },
    })
