import { View } from 'react-native'
import React from 'react'
import { observer } from 'mobx-react-lite'
import { ChartStore } from '@appformula/app-studio-core/src'
import Layout from './Layout'
import { useBindingHaveItemData } from '../../../collection/components/hooks/useBindingHaveItemData'
import { ARNChartStore } from './ARNChartStore'
import { useBinding } from '../../../collection/components/hooks/useBinding'
import { ARNCustomDetailPageStore } from '../../ARNCustomDetailPageStore'

export type IData = {
    id: string
}

export const BAR_SIZE: number = 21
export const DEFAULT_BAR: number = 5

const Chart = observer(
    (props: {
        chart: ChartStore
        pageStore: ARNCustomDetailPageStore
        item?: Record<string, unknown>
    }) => {
        const { chart, pageStore, item } = props

        const isHidden = item
            ? useBindingHaveItemData(chart.isHidden, item)
            : useBinding(chart.isHidden)

        return !isHidden ? (
            <View>
                <Layout
                    chartStore={chart}
                    arnChartStore={
                        pageStore.childComponentDetailStore(
                            chart
                        ) as ARNChartStore
                    }
                />
            </View>
        ) : (
            <View />
        )
    }
)

export default Chart
