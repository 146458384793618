//
//  CustomPage.tsx
//
//  Created by Peter Vu on 2022-03-28 11:06:31.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React, { useEffect, useMemo, useState } from 'react'
import { CustomPageStore, DesignPageStore } from '@appformula/app-studio-core'
import { observer } from 'mobx-react-lite'
import { Platform, StyleSheet } from 'react-native'
import { Component } from './components/Component'
import appConstants from '../../constants/const'
import PubSub from 'pubsub-js'
import { Theme, useTheme } from '../../styles/theme'
import CustomPageHeader from './CustomPageHeader'
import { SafeAreaView } from 'react-native-safe-area-context'
import ScrollViewHighLight from '../../components/ScrollViewHighLight'
import { DesignPageContext } from '../../context/DesignPageContext'
import { ARNCustomDetailPageStore } from './ARNCustomDetailPageStore'
import WrappingComponentHighlight from '../../components/WrappingComponentHighlight/WrappingComponentHighlight'

const CustomPageContent = observer(
    (props: {
        pageStore: CustomPageStore
        designPageStore: DesignPageStore
        arnCustomPageStore: ARNCustomDetailPageStore
    }) => {
        const { pageStore, designPageStore, arnCustomPageStore } = props

        const [scrollable, setScrollable] = useState(true)
        const [isDelay, setIsDelay] = useState(true)

        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])

        useEffect(() => {
            setTimeout(() => {
                setIsDelay(false)
            }, 100)
        }, [])

        useEffect(() => {
            if (Platform.OS === 'ios') {
                const scrollOn = PubSub.subscribe(
                    appConstants.HANDLE_SCROLL_ON,
                    onScrollableOn
                )
                const scrollOff = PubSub.subscribe(
                    appConstants.HANDLE_SCROLL_OFF,
                    onScrollableOff
                )
                return function clean() {
                    PubSub.unsubscribe(scrollOn)
                    PubSub.unsubscribe(scrollOff)
                }
            }
            return function clean() {
                //
            }
        }, [])

        const onScrollableOn = () => {
            setScrollable(true)
        }

        const onScrollableOff = () => {
            setScrollable(false)
        }

        const renderItems = (item: string, index: number) => {
            const componentStore = pageStore.componentStore(item)
            const componentInfoPath = {
                pageId: pageStore.pageId,
                componentId: item,
            }

            return (
                <WrappingComponentHighlight
                    wrapChildrenComp={{
                        paddingHorizontal: 0,
                    }}
                    componentInfoPath={componentInfoPath}
                    designPageStore={designPageStore}
                    scrollViewHighLightStore={arnCustomPageStore.getScrollViewHighLightStore(
                        designPageStore
                    )}
                >
                    <Component
                        key={index}
                        componentStore={componentStore}
                        arnPageStore={arnCustomPageStore}
                    />
                </WrappingComponentHighlight>
            )
        }

        return (
            <SafeAreaView style={styles.container} edges={['top']}>
                <CustomPageHeader
                    pageStore={pageStore}
                    arnCustomPageStore={arnCustomPageStore}
                    item={arnCustomPageStore.data}
                />
                {!isDelay && (
                    <ScrollViewHighLight
                        ScrollViewProps={{
                            style: styles.viewCustom,
                            extraScrollHeight: 20,
                            removeClippedSubviews: true,
                            scrollEnabled: scrollable,
                            contentContainerStyle: styles.contentScroll,
                        }}
                        pageId={pageStore.pageId}
                        designPageStore={designPageStore}
                        scrollViewHighLightStore={arnCustomPageStore.getScrollViewHighLightStore(
                            designPageStore
                        )}
                        data={pageStore.componentIds}
                        renderItems={renderItems}
                    />
                )}
            </SafeAreaView>
        )
    }
)

export const CustomPage = observer(
    (props: {
        pageStore: CustomPageStore
        arnCustomDetailPageStore: ARNCustomDetailPageStore
    }) => {
        return (
            <DesignPageContext.Consumer>
                {(designPageStore) => {
                    return (
                        <CustomPageContent
                            pageStore={props.pageStore}
                            designPageStore={designPageStore}
                            arnCustomPageStore={props.arnCustomDetailPageStore}
                        />
                    )
                }}
            </DesignPageContext.Consumer>
        )
    }
)

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
            backgroundColor: colorScheme.backgroundL1,
        },
        contentScroll: {
            paddingHorizontal: appConstants.PADDING_COMMON,
            paddingTop: 16,
            paddingBottom: 8,
        },
        viewCustom: {
            flex: 1,
            backgroundColor: colorScheme.backgroundL1,
        },
    })
