//
//  Accessory.tsx
//
//  Created by thaitd96 on 2022-27-04 10:23.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React from 'react'
import { ItemAccessory } from '@appformula/app-descriptor/src'
import { ARNScale } from './ARNScale'
import { ARNCountdown } from './ARNCountdown'
import { ARNCustomLabel } from './ARNCustomLabel'
import { ARNReaction } from './ARNReaction'
import { ARNRelativeDate } from './ARNRelativeDate'
import { observer } from 'mobx-react-lite'

export const Accessory = observer(
    (props: { accessory: ItemAccessory; item: Record<string, unknown> }) => {
        switch (props.accessory.accessoryType) {
            case 'scale':
                return (
                    <ARNScale accessory={props.accessory} item={props.item} />
                )
            case 'reaction':
                return (
                    <ARNReaction
                        accessory={props.accessory}
                        item={props.item}
                    />
                )
            case 'countdown':
                return (
                    <ARNCountdown
                        accessory={props.accessory}
                        item={props.item}
                    />
                )
            case 'relativeDate':
                return (
                    <ARNRelativeDate
                        accessory={props.accessory}
                        item={props.item}
                    />
                )
            case 'customLabel':
                return (
                    <ARNCustomLabel
                        accessory={props.accessory}
                        item={props.item}
                    />
                )
            default:
                return null
        }
    }
)
