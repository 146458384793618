//
//  AddConditionButton.tsx
//
//  Created by thaitd96 on 2022-27-07 10:35.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React from 'react'
import { StyleSheet, Text } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { Theme, useTheme } from '../../../../styles/theme'

export const AddConditionButton = () => {
    const theme = useTheme()
    const styles = React.useMemo(() => createStyles(theme), [theme])

    return (
        <TouchableOpacity style={styles.addCondition}>
            <Text style={styles.AddCondition}>{'Add Condition'}</Text>
        </TouchableOpacity>
    )
}
const createStyles = ({ colorScheme }: Theme) => {
    return StyleSheet.create({
        AddCondition: {
            fontSize: 17,
            fontWeight: '500',
            lineHeight: 21,
            color: colorScheme.accentColor,
        },
        addCondition: {},
    })
}
