const image = {
    appStore: require('./appStore.png'),
    googlePlay: require('./googlePlay.png'),
    qrCode: require('./qrCode.png'),
    iconFilter: require('./iconFilter.png'),
    iconSort: require('./iconSort.png'),
    iconSearch: require('./iconSearch.png'),
    iconBarCode: require('./iconBarCode.png'),
    phone: require('./phone.png'),
    more: require('./more.png'),
    clock: require('./clock.png'),
    calendar: require('./calendar.png'),
    like: require('./like.png'),
    add: require('./add.png'),
    info: require('./info.png'),
    globe: require('./globe.png'),
    getDirection: require('./getDirection.png'),
    phoneMap: require('./phoneMap.png'),
    pin1: require('./pin1.png'),
    pin2: require('./pin2.png'),
    inventory: require('./inventory.png'),
    store: require('./store.png'),
    check: require('./check.png'),
    close: require('./close.png'),
    thumbnail:
        'https://i.picsum.photos/id/730/200/200.jpg?hmac=wK_2ZX79XZRP1wVJ-dW_r-OkOjiz1kK8eHIyTw2Lr6s',
    rightArrow: require('./rightArrow.png'),
    checkBox: require('./Checkbox.png'),
    unCheckBox: require('./UnCheckbox.png'),
    checkV1: require('./IconCheck.png'),
    fileViewer: require('./fileViewer.png'),
    star: require('./Star.png'),
    thumb: require('./Thumb.png'),
    headphone: require('./headphone.png'),
    playback: require('./Playback.png'),
    pause: require('./pause.png'),
    starInactive: require('./starInactive.png'),
    searchTI: require('./SearchTI.png'),
    google: require('./google.png'),
    apple: require('./apple.png'),
    back: require('./back.png'),
    dropDown: require('./dropDown.png'),
    upload: require('./Upload.png'),
    radioChecked: require('./radioChecked.png'),
    radioUncheck: require('./radioUncheck.png'),
    arrowRight: require('./arrowRight.png'),
    locationArrow: require('./location-arrow.png'),
    locationPin: require('./LocationPin.png'),
    pin3: require('./pin3.png'),
    checked: require('./checked.png'),
    textFile: require('./textFile.png'),
    excelFile: require('./excelFile.png'),
    audioFile: require('./audioFile.png'),
    musicFile: require('./musicFile.png'),
    zipFile: require('./zipFile.png'),
    rarFile: require('./rarFile.png'),
    pdfFile: require('./pdfFile.png'),
    pttFile: require('./pttFile.png'),
    wordFile: require('./wordFile.png'),
    imageFile: require('./imageFile.png'),
    signature: require('./iconSignature.png'),
    mail: require('./mail.png'),
    turnArrow: require('./turnArrow.png'),
    dropdownFill: require('./dropdownFill.png'),
    arrowRightFilter: require('./arrowRightFilter.png'),
    flashOff: require('./flashOff.png'),
    flashOn: require('./flashOn.png'),
    calendarPick: require('./calendar-pick.png'),
    moreIcon: require('./moreIcon.png'),
    logoutIcon: require('./logoutIc.png'),
    appNameImg: require('./appName.png'),
    avatar: require('./avatar.png'),
    noImg: require('./NoImg.png'),
    infoNotPublish: require('./infoNotPublish.png'),
    tick: require('./tick.png'),
    noAvatar: require('./noAvatar.png'),
    noAppIcon: require('./noAppIcon.png'),
    noFilterCondition: require('./noFilterCondition.png'),
    logoDarkMode: require('./logoStarionDarkMode.png'),
    logoLightMode: require('./logoStarionLightMode.png'),
    noItemNormal: require('./icon-filter/noItemNormal.png'),
    noItemFilter: require('./icon-filter/noItemFilter.png'),
    noItemSearch: require('./icon-filter/noItemSearch.png'),
}

export default image
