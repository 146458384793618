//
//  ARNCustomLabel.tsx
//
//  Created by thaitd96 on 2022-27-04 15:23.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React, { useMemo } from 'react'
import {
    Image,
    ImageSourcePropType,
    StyleSheet,
    Text,
    View,
} from 'react-native'
import { Theme, useTheme } from '../../../../styles/theme'
import image from '../../../../assets/icons'
import { CustomLabel, ItemAccessory } from '@appformula/app-descriptor/src'
import { observer } from 'mobx-react-lite'
import { useBindingHaveItemData } from '../hooks/useBindingHaveItemData'

const getIconFromIconName = (iconName: string): ImageSourcePropType | null => {
    switch (iconName) {
        case 'phone':
            return image.phone
        default:
            return null
    }
}

export const ARNCustomLabel = observer(
    (props: { accessory: ItemAccessory; item: Record<string, unknown> }) => {
        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])

        const title = useBindingHaveItemData(
            (props.accessory as CustomLabel).title,
            props.item
        )

        return (
            <View style={styles.accessory}>
                <Image
                    source={getIconFromIconName(
                        (props.accessory as CustomLabel).icon
                    )}
                    style={styles.iconAccessory}
                />
                <Text
                    style={styles.textAccessory}
                    numberOfLines={1}
                    ellipsizeMode="tail"
                >
                    {title}
                </Text>
            </View>
        )
    }
)

const createStyles = ({ colorScheme }: Theme) =>
    StyleSheet.create({
        accessory: {
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: 4,
            height: 32,
        },
        textAccessory: {
            flex: 1,
            color: colorScheme.labelSecondary,
            marginLeft: 4,
            fontSize: 14,
            fontWeight: '500',
        },
        iconAccessory: {
            tintColor: colorScheme.labelSecondary,
            width: 24,
            height: 24,
        },
    })
