import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { Theme, useTheme } from '../../../../../styles/theme'
import { StyleSheet, Text, View } from 'react-native'
import { ARNChartStore, PieDataTypeWeb } from '../ARNChartStore'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import appConstants from '../../../../../constants/const'

const commonOption: Highcharts.Options = {
    chart: {
        type: 'pie',
        height:
            appConstants.WIDTH_SCREEN < 286 ? appConstants.WIDTH_SCREEN : 286,
        backgroundColor: 'transparent',
    },
    title: {
        text: '',
    },
    credits: {
        enabled: false,
    },
    tooltip: {
        enabled: false,
    },
}

const PieChart = observer((props: { arnChartStore: ARNChartStore }) => {
    const { arnChartStore } = props

    const theme = useTheme()
    const styles = useMemo(() => createStyles(theme), [theme])

    const renderLegends = () => {
        return (
            <View style={styles.wrapLegends}>
                {arnChartStore.getPieChartLayoutWeb?.data?.map(
                    (item: PieDataTypeWeb, index: number) =>
                        renderItemLegend(item, `${index}_${item.name}`)
                )}
            </View>
        )
    }

    const renderItemLegend = (item: PieDataTypeWeb, key: string) => {
        return (
            <View style={styles.containerLegendItem} key={key}>
                <View
                    style={[styles.tagLegend, { backgroundColor: item.color }]}
                />
                <Text style={styles.txtLegend}>{item.name}</Text>
            </View>
        )
    }

    return (
        <View style={styles.container}>
            <HighchartsReact
                highcharts={Highcharts}
                options={{
                    ...commonOption,
                    plotOptions: {
                        series: {
                            enableMouseTracking: false,
                            states: {
                                hover: {
                                    enabled: false,
                                },
                            },
                        },
                        pie: {
                            borderWidth: 1,
                            dataLabels: {
                                enabled: false,
                            },
                            innerSize:
                                arnChartStore.chartStore.pieChartConfig
                                    ?.displayMode === 'donut'
                                    ? '60%'
                                    : 0,
                        },
                    },
                    legend: {
                        enabled: false,
                        align: 'center',
                        verticalAlign: 'bottom',
                        layout: 'horizontal',
                        itemStyle: {
                            color: theme.colorScheme.labelPrimary,
                            cursor: 'auto',
                            fontSize: '12px',
                            fontWeight: '400',
                            textOverflow: 'ellipsis',
                        },
                        symbolHeight: 7,
                        symbolWidth: 7,
                        symbolRadius: 6,
                        symbolPadding: 6,
                        itemHoverStyle: {
                            color: theme.colorScheme.labelPrimary,
                        },
                    },
                    animation: {
                        defer: 0,
                        duration: 1000,
                    },
                    colors: arnChartStore.chartStore.colors,
                    series: {
                        data: arnChartStore.getPieChartLayoutWeb.data,
                    },
                }}
            />
            {renderLegends()}
        </View>
    )
})

export default PieChart

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        container: {
            marginVertical: appConstants.PADDING_COMMON / 2,
        },
        wrapLegends: {
            flexDirection: 'row',
            alignSelf: 'center',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
        },
        containerLegendItem: {
            flexDirection: 'row',
            alignItems: 'center',
            marginHorizontal: 19 / 2,
        },
        tagLegend: { width: 7, height: 7, borderRadius: 3.5, marginRight: 6 },
        txtLegend: {
            fontWeight: '400',
            fontSize: 12,
            lineHeight: 14.52,
            color: colorScheme.labelPrimary,
        },
    })
