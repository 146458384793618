//
//  LeftViewBelowExternal.tsx
//
//  Created by thaitd96 on 2022-12-05 13:57.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import React, { Image, StyleSheet, Text, View } from 'react-native'
import { Theme, useTheme } from '../../../../../styles/theme'
import image from '../../../../../assets/icons/index'
import { WrapContextAppDesktopStore } from '../../WrapContextAppDesktopStore'

type Props = {
    wrapContextAppDesktopStore: WrapContextAppDesktopStore
}

const LeftViewBelowExternal = observer((props: Props) => {
    const theme = useTheme()
    const styles = useMemo(() => createStyles(theme), [theme])
    return (
        <View style={styles.viewBelow}>
            <Text style={styles.download}>
                {'Open Space Mission Guide on your Phone'}
            </Text>
            <Text style={styles.descriptorDownload}>
                {
                    'This app will work best on mobile devices. Open your phone\n& Scan the QR Code below to open the app on your phone\nbrowser.'
                }
            </Text>
            <Image source={image.qrCode} style={styles.qrCode}></Image>
        </View>
    )
})

export default LeftViewBelowExternal

const createStyles = ({ colorScheme }: Theme) =>
    StyleSheet.create({
        qrCode: { height: 220, width: 220, marginTop: 14 },
        descriptorDownload: {
            marginTop: 8,
            fontWeight: '400',
            fontSize: 14,
            lineHeight: 24,
            color: colorScheme.labelSecondary,
        },
        download: {
            color: colorScheme.labelPrimary,
            fontWeight: '600',
            fontSize: 18,
            lineHeight: 21.78,
        },
        viewBelow: {
            marginTop: 50,
            backgroundColor: colorScheme.backgroundL2,
            borderRadius: 12,
            paddingVertical: 24,
            paddingHorizontal: 32,
        },
    })
