//
//  AppleSignInButton.tsx
//
//  Created by thaitd96 on 2022-05-30 17:05.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React, { useContext, useMemo } from 'react'
import { StyleSheet, Text, TouchableOpacityProps } from 'react-native'
import { Theme, useTheme } from '../../../styles/theme'
import { TouchableOpacity } from 'react-native'
import FastImage from '../../../components/fast-image/FastImage'
import image from '../../../assets/icons'
import firebase from 'firebase'
import buildConfig from '../../../../buildConfig.json'
import { AppType } from '../WelcomePageStore'
import cache from '../../../utils/cache'
import { Alert } from '../../../components/alert'
import { WelcomePageDataStoreContext } from '../context/WelcomePageDataStoreContext'

type ButtonProps = {
    loading?: boolean
    appType?: AppType
}

const AppleSignInButton = (props: ButtonProps & TouchableOpacityProps) => {
    const theme = useTheme()
    const styles = React.useMemo(() => createStyles(theme), [theme])
    const store = useContext(WelcomePageDataStoreContext)

    const onAppleButtonPress = React.useCallback(async () => {
        if (cache.sourceStartUp !== 'APP_EDITOR') {
            try {
                const provider = new firebase.auth.OAuthProvider('apple.com')

                provider.addScope('email')
                provider.addScope('name')

                if (
                    props.appType === AppType.EXTERNAL &&
                    buildConfig.tenantId !== ''
                ) {
                    firebase.auth().tenantId = buildConfig.tenantId
                }
                await firebase
                    .auth()
                    .signInWithPopup(provider)
                    .then(async (userInfo) => {
                        if (userInfo.user) {
                            await store.createAppUser(userInfo.user.uid)
                        }
                    })

                console.info('Signed in with Apple!')
            } catch (error) {
                console.info(`Signed in with Apple error ${error}!`)
            }
        } else {
            Alert.alert(
                'Notification',
                `You can't use this feature in Web Editor!`
            )
        }
    }, [props.appType])

    return useMemo(
        () => (
            <TouchableOpacity
                style={styles.appleButton}
                onPress={onAppleButtonPress}
                disabled={props.loading}
            >
                <FastImage
                    style={styles.appleImg}
                    source={image.apple}
                    resizeMode={'contain'}
                />
                <Text style={styles.txtAppleSignIn}>Continue with Apple</Text>
            </TouchableOpacity>
        ),
        [styles, props.loading, onAppleButtonPress]
    )
}

export default AppleSignInButton

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        appleButton: {
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            borderWidth: 1,
            borderRadius: roundness,
            borderColor: colorScheme.separator,
            width: '100%',
            height: 50,
            marginTop: 12,
        },

        txtAppleSignIn: {
            fontWeight: '500',
            fontSize: 16,
            lineHeight: 19.36,
            color: colorScheme.labelPrimary,
        },

        appleImg: {
            width: 18,
            height: 18,
            marginRight: 10,
        },
    })
