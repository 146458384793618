import {
    View,
    Text,
    StyleSheet,
    FlatList,
    Image,
    Pressable,
} from 'react-native'
import React, { useMemo } from 'react'
import { useNavigation } from '@react-navigation/native'
import { Theme, useTheme } from '../../styles/theme'
import { SafeAreaView } from 'react-native-safe-area-context'
import ItemTabView from './ItemTabView'
import { pagesIcon } from '../../assets/icons/icon-pages'
import image from '../../assets/icons'
import screenNames from '../../navigations/screenNames'
import { AppDescriptorContext } from '../../context/AppDescriptorContext'
import { observer } from 'mobx-react-lite'
import appConstants from '../../constants/const'
import {
    AppDescriptorStore,
    CollectionPageStore,
    CustomPageStore,
    FormPageStore,
} from '@appformula/app-studio-core/src'

interface IParams {
    listTabMore: string[]
}

const MorePage = () => {
    return (
        <AppDescriptorContext.Consumer>
            {(appDescriptorStore) => (
                <MorePageContent appDescriptorStore={appDescriptorStore} />
            )}
        </AppDescriptorContext.Consumer>
    )
}

const MorePageContent = observer(
    ({ appDescriptorStore }: { appDescriptorStore: AppDescriptorStore }) => {
        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])
        const navigation = useNavigation()

        const list = [...appDescriptorStore.frontPageIds].map((item) => item)

        const listFilter = list?.filter(
            (pageId) =>
                appDescriptorStore.pageStore(pageId) instanceof
                    CollectionPageStore ||
                appDescriptorStore.pageStore(pageId) instanceof
                    CustomPageStore ||
                appDescriptorStore.pageStore(pageId) instanceof FormPageStore
        )

        const listTabMore = listFilter.slice(appConstants.NUMBER_TAB_VIEW)

        const renderItem = ({
            item,
            index,
        }: {
            item: string
            index: number
        }) => {
            return <ItemTabView pageId={item} />
        }

        const keyExtractor = (item: string, index: number) => {
            return item
        }

        const onPressSetting = () => {
            navigation.navigate(screenNames.SETTING_SCREEN as never)
        }

        const renderFooterComponent = () => {
            return (
                <Pressable onPress={onPressSetting}>
                    <View style={styles.containerSettingTab}>
                        <Image source={pagesIcon['home']} style={styles.ic} />
                        <View style={styles.wrapContentTab}>
                            <Text style={styles.txtTitle}>Settings</Text>
                            <Image
                                source={image.arrowRight}
                                style={styles.icArrowRight}
                            />
                        </View>
                    </View>
                </Pressable>
            )
        }

        return (
            <SafeAreaView style={styles.container}>
                <View style={styles.header}>
                    <Text style={styles.txtHeader}>More</Text>
                </View>
                <FlatList
                    data={listTabMore}
                    renderItem={renderItem}
                    keyExtractor={keyExtractor}
                    ListFooterComponent={renderFooterComponent}
                />
            </SafeAreaView>
        )
    }
)

export default MorePage

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        containerSettingTab: { flexDirection: 'row', alignItems: 'center' },
        wrapContentTab: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottomColor: colorScheme.separator,
            borderBottomWidth: 1,
            paddingRight: 8,
            flex: 1,
        },
        container: {
            flex: 1,
            backgroundColor: colorScheme.backgroundL1,
        },
        header: {
            backgroundColor: colorScheme.backgroundL1,
            borderColor: colorScheme.separator,
            borderBottomWidth: 1,
        },
        txtHeader: {
            fontSize: 25,
            fontWeight: '700',
            lineHeight: 30.26,
            color: colorScheme.labelPrimary,
            paddingVertical: 15,
            paddingLeft: 16,
        },
        icArrowRight: { width: 20, height: 20 },
        ic: {
            width: 34,
            height: 34,
            marginHorizontal: 16,
            tintColor: colorScheme.labelSecondary,
        },
        txtTitle: {
            color: colorScheme.labelPrimary,
            paddingVertical: 19,
            fontSize: 15,
            fontWeight: '500',
            lineHeight: 18.15,
        },
    })
