import React from 'react'
import { observer } from 'mobx-react-lite'
import { InlineCollectionStore } from '@appformula/app-studio-core'
import CollectionVListItem from './CollectionVListItem'
import { IData } from '.'
import { View } from 'react-native'

const VerticalCollection = observer((props: { inlineCollection: InlineCollectionStore; data: Array<IData> }) => {
    const { inlineCollection, data } = props
    const renderItem = (item: IData, index: number) => {
        return <CollectionVListItem key={`${item.id}`} item={item} inlineCollection={inlineCollection} />
    }

    return data && data.length > 0 ? <View>{data.map((item, index) => renderItem(item, index))}</View> : null
})

export default VerticalCollection
