//
//  BooleanFormInput.tsx
//
//  Created by thaitd96 on 2022-24-06 11:51.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { BooleanFormInputStore } from '@appformula/app-studio-core'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useMemo } from 'react'
import {
    StyleSheet,
    Text,
    View,
    Switch,
    Platform,
    ColorValue,
} from 'react-native'
import { Theme, useTheme } from '../../../../styles/theme'
import { hexToRGBA } from '../../../../utils'
import appConstants from '../../../../constants/const'
import { ARNFormPageStore } from '../../ARNFormPageStore'
import { COLORS } from '../../../../assets/colors'
import { ARNBooleanFormStore } from './ARNBooleanFormStore'
import { useBinding } from '../../../collection/components/hooks/useBinding'

export const BooleanFormInput = observer(
    (props: {
        booleanFormInput: BooleanFormInputStore
        arnBooleanFormStore: ARNBooleanFormStore
        arnFormPageStore: ARNFormPageStore
    }) => {
        const { booleanFormInput, arnBooleanFormStore } = props
        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])
        const isHidden = useBinding(booleanFormInput.isHidden)
        const defaultValue = useBinding(booleanFormInput.defaultValue)

        const isAndroid = Platform.OS === 'android'
        let thumbColor = COLORS.WHITE as ColorValue
        if (isAndroid) {
            thumbColor = arnBooleanFormStore.value
                ? theme.colorScheme.accentColor
                : COLORS.WHITE
        }

        const handleCheck = () => {
            arnBooleanFormStore.setValue(!arnBooleanFormStore.value)
        }

        useEffect(() => {
            arnBooleanFormStore.setValue(defaultValue as boolean)
        }, [arnBooleanFormStore, defaultValue])

        return isHidden ? null : (
            <View style={styles.viewBooleanSwitch}>
                <Text style={styles.title}>{booleanFormInput.titleName}</Text>
                <Switch
                    trackColor={{
                        false: COLORS.BG_THUMB,
                        true: hexToRGBA(
                            theme.colorScheme.accentColor as string,
                            0.1
                        ),
                    }}
                    ios_backgroundColor={
                        arnBooleanFormStore.value
                            ? theme.colorScheme.accentColor
                            : COLORS.BG_THUMB
                    }
                    thumbColor={thumbColor as string}
                    onValueChange={handleCheck}
                    value={arnBooleanFormStore.value}
                />
            </View>
        )
    }
)
const createStyles = ({ colorScheme }: Theme) =>
    StyleSheet.create({
        viewBooleanSwitch: {
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingVertical: 25,
            borderBottomWidth: 1,
            alignItems: 'center',
            borderBottomColor: colorScheme.separator,
            paddingHorizontal: appConstants.PADDING_COMMON,
        },
        title: {
            fontSize: 16,
            lineHeight: 19,
            fontWeight: '500',
            color: colorScheme.labelPrimary,
        },
    })
