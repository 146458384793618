import { FirebaseFirestoreTypes } from '@react-native-firebase/firestore'
import {
    ArrayType,
    BooleanType,
    ContextVariable,
    DateTimeType,
    FormulaExecutor,
    NumberType,
    ObjectType,
    StringType,
    Table,
} from '@Unstatic-co/appformula-dsl'
import { randomVariableName } from './Alias'
import { FirebaseAuthTypes } from '@react-native-firebase/auth'
import { arnFormulaExecutorDelegate } from './ARNFormulaExecutorDelegate'
import { typeCheck } from '../Utils'
import { Nullable } from '@appformula/shared-foundation-x/src'
import { Schema } from '../../database/Schema'

function GetFormulaType(type: string) {
    switch (type) {
        case 'String':
            return new StringType()
        case 'Boolean':
            return new BooleanType()
        case 'Number':
            return new NumberType()
        case 'DateTime':
            return new DateTimeType()
        default:
            return undefined
    }
}

export class FormulaManager extends Schema {
    private executor: FormulaExecutor = new FormulaExecutor()
    private tables: Table[]

    constructor(auth: FirebaseAuthTypes.Module, teamId: string, appId: string) {
        super(teamId, appId)
        this.tables = []
        auth?.currentUser && this.setUpWith(auth.currentUser)
        auth.onAuthStateChanged((newUser) => {
            if (newUser) {
                this.setUpWith(newUser)
            } else {
                this.cleanupFormulaRegistration()
            }
        })
    }

    async execute(
        formula: string,
        supportingParameters?: unknown | null
    ): Promise<unknown> {
        return this.executor.execute(formula, supportingParameters)
    }

    async registContextVariableWithTableId(
        contextVariableName: string,
        tableId: string
    ) {
        const tableSchema = await this.getSchemaByTableId(tableId)

        const contextVariable = FormulaManager.contextVariableFromDocument(
            tableSchema,
            contextVariableName
        )

        this.executor.register(
            this.tables,
            contextVariable ? [contextVariable] : undefined
        )
    }

    async registContextVariable(
        contextVariableName: string,
        schemaValue: unknown
    ) {
        const contextVariable = FormulaManager.contextVariableFromValue(
            schemaValue,
            contextVariableName
        )

        this.executor.register(
            this.tables,
            contextVariable ? [contextVariable] : undefined
        )
    }

    private cleanupFormulaRegistration() {
        this.executor.register([], [])
    }

    private async setUpWith(user: FirebaseAuthTypes.User) {
        const tableDocs = await this.tablesDocument(user)
        this.tables = tableDocs
            .map((e) => FormulaManager.tableFromDocument(e))
            .filter((table) => !!table) as Table[]

        this.executor.register(this.tables, [])
        this.executor.delegate = arnFormulaExecutorDelegate
    }

    async registTable(tableId: string) {
        const tableSchema = await this.getSchemaByTableId(tableId)
        if (tableSchema) {
            const tableDoc = FormulaManager.tableFromDocument(tableSchema)
            if (tableDoc) {
                this.executor.register([tableDoc], [])
            } else {
                console.warn(`Failed to get tableDoc from table ${tableId}`)
            }
        } else {
            console.warn(
                `Failed to get table schema from Firestore with tableId ${tableId}`
            )
        }
    }

    private async tablesDocument(
        user: FirebaseAuthTypes.User
    ): Promise<FirebaseFirestoreTypes.DocumentData[]> {
        const currentTables = await this.getCurrentSchema()
        return currentTables
    }

    private static tableFromDocument(
        doc: FirebaseFirestoreTypes.DocumentData
    ): Table | undefined {
        const objectColumns = Object.fromEntries(
            Object.entries(doc?.['columns'] ?? {})
                .filter(
                    (e) =>
                        GetFormulaType(e[1] as string) !== undefined
                )
                .map((entry) => {
                    const parsedColType = GetFormulaType(entry[1] as string)
                    return [entry[0], parsedColType]
                })
        )

        const tableType = new ObjectType(
            `Base type of ${doc['name']}`,
            objectColumns
        )

        const dataType = new ArrayType(doc['name'], tableType)
        return {
            name: doc['name'],
            alias: randomVariableName(),
            dataType: dataType,
        }
    }

    private static contextVariableFromDocument(
        doc: Nullable<FirebaseFirestoreTypes.DocumentData>,
        contextVariableName: string
    ): ContextVariable | undefined {
        const objectColumn = Object.fromEntries(
            Object.entries(doc?.['columns'] ?? {})
                .filter(
                    (e) =>
                        GetFormulaType(e[1] as unknown as string) !== undefined
                )
                .map((entry) => {
                    const parsedColType = GetFormulaType(
                        entry[1] as unknown as string
                    )
                    return [entry[0], parsedColType]
                })
        )

        const objectType = new ObjectType(contextVariableName, objectColumn)

        const contextVariable: ContextVariable = {
            name: contextVariableName,
            dataType: objectType,
        }

        return contextVariable
    }

    private static contextVariableFromValue(
        object: unknown,
        contextVariableName: string
    ): ContextVariable | undefined {
        const formulaType = GetFormulaType(
            typeCheck(object) as unknown as string
        )

        if (!formulaType) {
            return undefined
        }

        const contextVariable: ContextVariable = {
            name: contextVariableName,
            dataType: formulaType,
        }

        return contextVariable
    }
}
