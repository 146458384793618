import { StyleSheet, Image, ImageSourcePropType } from 'react-native'
import React, { useMemo } from 'react'
import { Theme, useTheme } from '../../../../styles/theme'
import image from '../../../../assets/icons'

export type IconFileType =
    | 'jpg'
    | 'jpeg'
    | 'png'
    | 'gif'
    | 'tiff'
    | 'psd'
    | 'eps'
    | 'ai'
    | 'mp4'
    | 'mov'
    | 'avi'
    | 'flv'
    | 'wmv'
    | 'mkv'
    | 'xlsx'
    | 'xls'
    | 'csv'
    | 'pdf'
    | 'docx'
    | 'doc'
    | 'rar'
    | 'm4a'
    | 'flac'
    | 'mp3'
    | 'wav'
    | 'wma'
    | 'aac'
    | 'pptx'
    | 'ppt'
    | 'zip'
    | 'gzip'
    | '7zip'
    | 'txt'
    | 'rtf'
    | 'md'

const typeImage = ['jpg', 'jpeg', 'png', 'gif', 'tiff', 'psd', 'eps', 'ai']

const typeVideo = ['mp4', 'mov', 'avi', 'flv', 'wmv', 'mkv']

const typeExcel = ['xlsx', 'xls', 'csv']

const typePDF = ['pdf']

const typeDoc = ['docx', 'doc']

const typeRar = ['rar']

const typeAudio = ['m4a', 'flac', 'mp3', 'wav', 'wma', 'aac']

const typePPT = ['pptx', 'ppt']

const typeZip = ['zip', 'gzip', '7zip']

const typeTxt = ['txt', 'rtf', 'md']

const IconFile = (props: { type: IconFileType }) => {
    const { type } = props
    const theme = useTheme()
    const styles = useMemo(() => createStyles(theme), [theme])

    const getFileIcon = () => {
        if (typeDoc.includes(type)) {
            return (
                <Image
                    style={styles.imageSize}
                    source={image.wordFile as ImageSourcePropType}
                />
            )
        }
        if (typeExcel.includes(type)) {
            return (
                <Image
                    style={styles.imageSize}
                    source={image.excelFile as ImageSourcePropType}
                />
            )
        }
        if (typeTxt.includes(type)) {
            return (
                <Image
                    style={styles.imageSize}
                    source={image.textFile as ImageSourcePropType}
                />
            )
        }
        if (typePDF.includes(type)) {
            return (
                <Image
                    style={styles.imageSize}
                    source={image.pdfFile as ImageSourcePropType}
                />
            )
        }
        if (typeZip.includes(type)) {
            return (
                <Image
                    style={styles.imageSize}
                    source={image.zipFile as ImageSourcePropType}
                />
            )
        }
        if (typeRar.includes(type)) {
            return (
                <Image
                    style={styles.imageSize}
                    source={image.rarFile as ImageSourcePropType}
                />
            )
        }
        if (typeImage.includes(type)) {
            return (
                <Image
                    style={styles.imageSize}
                    source={image.imageFile as ImageSourcePropType}
                />
            )
        }
        if (typePPT.includes(type)) {
            return (
                <Image
                    style={styles.imageSize}
                    source={image.pttFile as ImageSourcePropType}
                />
            )
        }
        if (typeVideo.includes(type)) {
            return (
                <Image
                    style={styles.imageSize}
                    source={image.audioFile as ImageSourcePropType}
                />
            )
        }
        if (typeAudio.includes(type)) {
            return (
                <Image
                    style={styles.imageSize}
                    source={image.musicFile as ImageSourcePropType}
                />
            )
        } else {
            return (
                <Image
                    style={styles.imageSize}
                    source={image.imageFile as ImageSourcePropType}
                />
            )
        }
    }
    return getFileIcon()
}

export default IconFile

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        imageSize: { width: 34, height: 34 },
    })
