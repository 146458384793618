import { View, Text, Image, StyleSheet, Pressable } from 'react-native'
import React, { useMemo } from 'react'
import { ILookup } from '..'
import image from '../../../../../../assets/icons'
import appConstants from '../../../../../../constants/const'
import { Theme, useTheme } from '../../../../../../styles/theme'

const ResultItem = (props: {
    item: ILookup
    removeItem: (item: ILookup) => void
}) => {
    const { item, removeItem } = props
    const theme = useTheme()
    const styles = useMemo(() => createStyles(theme), [theme])

    const onRemove = () => {
        removeItem && removeItem(item)
    }

    return (
        <View style={styles.container}>
            <View style={styles.wrapContent}>
                <Image source={image.turnArrow} style={styles.imgTurnArr} />
                <Text style={styles.txtContent}>{item?.content || ''}</Text>
            </View>
            <Pressable onPress={onRemove}>
                <Image source={image.close} style={styles.imgClose} />
            </Pressable>
        </View>
    )
}

export default ResultItem

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        txtContent: {
            fontSize: 15,
            fontWeight: '400',
            lineHeight: 18.15,
            color: colorScheme.labelPrimary,
        },
        imgClose: { width: 24, height: 24 },
        imgTurnArr: { width: 24, height: 24, marginRight: 10 },
        wrapContent: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingVertical: 18,
        },
        container: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingHorizontal: appConstants.PADDING_COMMON,
        },
    })
