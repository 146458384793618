import { Observable, of } from 'rxjs'
import { OperationConstraint } from './OperationConstraint'

export class ConstantConstraint implements OperationConstraint {
    isSatisfy: Observable<boolean>

    constructor(value: boolean) {
        this.isSatisfy = of(value)
    }
}
