import React, { useMemo } from 'react'
import { VerificationType, WelcomePageStore } from './WelcomePageStore'
import { observer } from 'mobx-react-lite'
import { useNavigation } from '@react-navigation/native'
import AppButton from '../../components/AppButton'
import screenNames from '../../navigations/screenNames'
import image from '../../assets/icons'
import { Image, SafeAreaView, ScrollView, StyleSheet, Text } from 'react-native'
import { Theme, useTheme } from '../../styles/theme'

const VerifySuccessScreen = observer((props: { store: WelcomePageStore }) => {
    const theme = useTheme()
    const styles = useMemo(() => createStyles(theme), [theme])
    const { store } = props

    const navigation = useNavigation()

    const handleGoNext = React.useCallback(async () => {
        if (store.verificationType === VerificationType.CREATE_ACCOUNT) {
            navigation.navigate(screenNames.CUSTOMIZE_PROFILE as never)
        } else if (store.verificationType === VerificationType.RESET_PASSWORD) {
            navigation.navigate(screenNames.SIGN_IN as never)
        }
    }, [navigation, store?.verificationType])

    return (
        <SafeAreaView style={styles.container}>
            <ScrollView contentContainerStyle={styles.containerScroll}>
                <Image
                    style={styles.icon}
                    source={image.tick}
                    resizeMode={'center'}
                />
                <Text style={styles.txtTitle}>
                    {store.verificationType === VerificationType.CREATE_ACCOUNT
                        ? 'Your Email is Now verified'
                        : store.verificationType ===
                          VerificationType.RESET_PASSWORD
                        ? 'Your Password has been Reset'
                        : ''}
                </Text>
                <Text style={styles.txtSubTitle}>
                    {store.verificationType === VerificationType.CREATE_ACCOUNT
                        ? `Thank you, you now can access the ${store.appName} now.`
                        : store.verificationType ===
                          VerificationType.RESET_PASSWORD
                        ? `Login with your ${store.appName} employee account app to continue with ${store.appName}.`
                        : ''}
                </Text>
                <AppButton
                    title={
                        store.verificationType ===
                        VerificationType.RESET_PASSWORD
                            ? 'Go to Login Page'
                            : 'Continue'
                    }
                    onPress={handleGoNext}
                />
            </ScrollView>
        </SafeAreaView>
    )
})

export default VerifySuccessScreen

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        backImg: {
            width: 28,
            height: 28,
            tintColor: colorScheme.accentColor,
            marginLeft: 8,
        },
        containerHeaderLeft: { flexDirection: 'row', alignItems: 'center' },
        txtHeaderLeft: {
            color: colorScheme.accentColor,
            fontWeight: '500',
            fontSize: 17,
            lineHeight: 21,
        },
        container: {
            flex: 1,
            backgroundColor: colorScheme.backgroundL1,
            paddingTop: 20,
        },
        containerScroll: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            paddingHorizontal: 24,
        },
        txtTitle: {
            fontWeight: '700',
            fontSize: 30,
            lineHeight: 36.31,
            color: colorScheme.labelPrimary,
            marginBottom: 9,
            textAlign: 'center',
        },
        txtSubTitle: {
            fontWeight: '400',
            fontSize: 15,
            lineHeight: 22.5,
            color: colorScheme.labelSecondary,
            textAlign: 'center',
            marginBottom: 20,
        },
        input: {
            width: '100%',
            height: 50,
            marginBottom: 12,
            borderWidth: 1,
            padding: 10,
            borderRadius: roundness,
            borderColor: colorScheme.separator,
        },
        icon: {
            width: 34,
            height: 24,
            tintColor: colorScheme.accentColor,
            marginBottom: 26,
        },
    })
