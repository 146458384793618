import { View, Text, StyleSheet, Pressable, Image } from 'react-native'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Theme, useTheme } from '../../../../../styles/theme'
import appConstants from '../../../../../constants/const'
import {
    convertResultSuccessToUrl,
    generateRandomId,
    hexToRGBA,
} from '../../../../../utils'
import { observer } from 'mobx-react-lite'
import { FilesFormInputStore } from '@appformula/app-studio-core/src'
import image from '../../../../../assets/icons'
import ItemFile, { IFileWeb } from '../item-file/ItemFile.web'
import { FORM_URL } from '../../../../../utils/network/apis/FormUrl'
import { useDropzone } from 'react-dropzone'
import file from '../../../../../utils/file/index.web'
import { Alert } from '../../../../../components/alert'
import { ARNFileFormStore } from '../ARNFileFormStore'
import allsettled, { PromiseResolution } from 'promise.allsettled'
import { useBinding } from '../../../../collection/components/hooks/useBinding'

const Files = observer(
    (props: {
        filesFormInput: FilesFormInputStore
        arnFileFormStore: ARNFileFormStore
    }) => {
        const { filesFormInput, arnFileFormStore } = props
        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])

        const [listFileSelected, setlistFileSelected] = useState<IFileWeb[]>([])
        const { open, acceptedFiles } = useDropzone({})
        const isHidden = useBinding(filesFormInput.isHidden)

        useEffect(() => {
            const subscriptions = arnFileFormStore.startListeningEvents()

            return () => {
                subscriptions.forEach((s) => s.unsubscribe())
            }
        }, [arnFileFormStore])

        const onUploadFileSuccess = useCallback(
            (url: string) => {
                arnFileFormStore.addFileUploaded({
                    assetUrl: new URL(url),
                })
            },
            [arnFileFormStore]
        )

        useEffect(() => {
            if (acceptedFiles && acceptedFiles.length > 0) {
                const newAcceptedFiles = acceptedFiles.map(
                    (item) =>
                        ({
                            id: generateRandomId(),
                            name: item.name,
                            type: item.type,
                            size: item.size,
                        } as unknown as IFileWeb)
                )
                setlistFileSelected((prevList) => [
                    ...prevList,
                    ...newAcceptedFiles,
                ])
                const filesUpload = newAcceptedFiles.map(async (item) => {
                    return file.uploadFile({
                        data: item,
                        url: FORM_URL.uploadFile,
                        options: { isHandleError: false },
                    })
                })

                allsettled(filesUpload).then((results) => {
                    results
                        .filter((result) => result.status === 'fulfilled')
                        .forEach((resultSuccess) => {
                            const url = convertResultSuccessToUrl(
                                resultSuccess as PromiseResolution<
                                    Record<string, unknown>
                                >
                            )

                            onUploadFileSuccess(url)
                        })
                })
            }
        }, [acceptedFiles, arnFileFormStore, onUploadFileSuccess])

        const pickFile = () => {
            open()
        }

        const _removeFile = (file: IFileWeb) => {
            Alert.alert('Notification', 'Are you sure to delete this files ?', [
                {
                    text: 'Cancel',
                    onPress: () => {
                        //
                    },
                    style: 'cancel',
                },
                {
                    text: 'OK',
                    onPress: () => comfirmRemoveFile(file),
                },
            ])
        }

        const comfirmRemoveFile = (file: IFileWeb) => {
            // firebaseStorageHelper
            //     .delete({ fileName: file.fileName, id: file.id })
            //     .then((response) => {
            //         setlistFileSelected((prevList) => {
            //             const newList = [...prevList]
            //             const indexFile = newList.findIndex((i) => i.id === file.id)
            //             newList.splice(indexFile, 1)
            //             return newList
            //         })
            //     })
            //     .catch((error) => {
            //         console.log('error', error)
            //     })
        }

        const _renderItem = (item: IFileWeb, index: number) => {
            return (
                <ItemFile
                    // ref={elRefs[index]}
                    key={`${item.id}`}
                    item={item}
                    onRemove={_removeFile}
                    onUploadFileSuccess={onUploadFileSuccess}
                />
            )
        }

        return isHidden ? null : (
            <View style={styles.container}>
                <View style={styles.wrapperHeader}>
                    <View style={styles.wrapperTitle}>
                        <Text style={styles.txtTitle}>
                            {filesFormInput.titleName}
                        </Text>
                        {filesFormInput.required && (
                            <View style={styles.requireDot} />
                        )}
                    </View>
                    <Pressable style={styles.btnUpload} onPress={pickFile}>
                        <Image source={image.upload} style={styles.imgUpload} />
                    </Pressable>
                </View>

                {listFileSelected && listFileSelected.length > 0
                    ? listFileSelected.map((item, index) =>
                          _renderItem(item, index)
                      )
                    : null}
            </View>
        )
    }
)

export default Files

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        txtTitle: {
            fontWeight: '500',
            fontSize: 16,
            lineHeight: 19.36,
            color: colorScheme.labelPrimary,
        },
        imgClose: { width: 24, height: 24 },
        imgUpload: {
            width: 28,
            height: 28,
            margin: 5,
            tintColor: colorScheme.accentColor,
        },
        wrapperHeader: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingHorizontal: appConstants.PADDING_COMMON,
        },
        btnUpload: {
            backgroundColor: hexToRGBA(colorScheme.accentColor as string, 0.1),
            borderRadius: roundness,
        },
        titleItem: {
            marginLeft: 12,
            fontWeight: '400',
            fontSize: 15,
            lineHeight: 18.15,
            color: colorScheme.labelPrimary,
        },
        containerItemTitle: { flexDirection: 'row' },
        wrapperItem: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingHorizontal: appConstants.PADDING_COMMON,
            height: 54,
        },
        container: {
            paddingBottom: 11,
            borderBottomColor: colorScheme.separator,
            borderBottomWidth: 1,
            marginTop: 15,
        },
        requireDot: {
            height: 6,
            width: 6,
            borderRadius: 3,
            backgroundColor: 'red',
            marginLeft: 8,
        },
        wrapperTitle: { flexDirection: 'row', alignItems: 'center' },
    })
