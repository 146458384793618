import {
    View,
    Text,
    Pressable,
    Image,
    StyleSheet,
    ActivityIndicator,
} from 'react-native'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Theme, useTheme } from '../../../../../styles/theme'
import file from '../../../../../utils/file/index.web'
import { FORM_URL } from '../../../../../utils/network/apis/FormUrl'
import { Alert } from '../../../../../components/alert'
import { ResponseUpload, ResponseUploadData } from '../../../../../utils'

export interface IPhotoWeb {
    id: string
    name: string
    type: string
    size: string
    preview: string
}

interface IItemPhotoWeb {
    item: IPhotoWeb
    onRemove?: (file: IPhotoWeb) => void
    onUploadFileSuccess: (url: string) => void
}

const ItemPhoto: React.FC<IItemPhotoWeb> = (props: IItemPhotoWeb) => {
    const { item, onUploadFileSuccess } = props
    const [failed, setFailed] = useState(false)
    const [loading, setLoading] = useState(true)
    const theme = useTheme()
    const styles = useMemo(() => createStyles(theme), [theme])

    const uploadPhoto = useCallback(() => {
        file.uploadFile({ data: item, url: FORM_URL.uploadFile })
            .then((response) => {
                setFailed(false)
                setLoading(false)

                const url =
                    (
                        JSON.parse(
                            (response as ResponseUpload).data
                        ) as ResponseUploadData
                    ).assetUrl ?? ''

                onUploadFileSuccess(url)
            })
            .catch((err) => {
                setFailed(true)
                setLoading(false)
            })
    }, [item, onUploadFileSuccess])

    useEffect(() => {
        const idQ = `${item.id}`
        if (file.isUploadActive(idQ)) {
            uploadPhoto()
        }
    }, [uploadPhoto, item.id])

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => {
            URL.revokeObjectURL(item.preview)
        }
    }, [])

    const viewFile = () => {
        //
    }

    const onRetry = () => {
        Alert.alert(
            'Notification',
            'Are you sure to retry upload these photos?',
            [
                {
                    text: 'Cancel',
                    onPress: () => {
                        //
                    },
                    style: 'cancel',
                },
                {
                    text: 'OK',
                    onPress: () => uploadPhoto(),
                },
            ]
        )
    }

    const onLoad = () => {
        //
    }

    const onError = () => {
        URL.revokeObjectURL(item.preview)
    }

    return (
        <Pressable onPress={viewFile}>
            <Image
                source={{ uri: item.preview }}
                style={styles.imgItem}
                resizeMode={'stretch'}
                onLoad={onLoad}
                onError={onError}
            />
            {loading && (
                <View style={styles.viewLoading}>
                    <ActivityIndicator />
                </View>
            )}
            {failed && (
                <Pressable style={styles.viewLoading} onPress={onRetry}>
                    <Text>FAILED</Text>
                </Pressable>
            )}
        </Pressable>
    )
}

export default ItemPhoto

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        imgItem: { width: 62.82, height: 62, borderRadius: 8, margin: 4 },
        viewLoading: {
            width: 62.82,
            height: 62,
            borderRadius: roundness,
            margin: 4,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            position: 'absolute',
            alignItems: 'center',
            justifyContent: 'center',
        },
    })
