//
//  ARNFormPageStore.ts
//
//  Created by thaitd96 on 2022-06-23 18:14.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import {
    FormPageStore,
    PeopleLookupFormInputStore,
} from '@appformula/app-studio-core/src'
import { makeObservable, observable, action } from 'mobx'
import { People } from './components/people-lookup/PeopleLookupFormInput'
import { FormInputType } from '@appformula/app-descriptor/src'
import { ILookup } from './components/Lookup/lookupOptionsPage'
import { Option } from './components/option-form-input/OptionFormInputInline'
import { ARNDateTimeFormStore } from './components/date-time/ARNDateTimeFormStore'
import { ARNLocationFormStore } from './components/Location/ARNLocationFormStore'
import { ARNLookupFormStore } from './components/Lookup/ARNLookupFormStore'
import { ARNMultiLineTextFormStore } from './components/multiline-text/ARNMultiLineTextFormStore'
import { ARNOptionFormInputStore } from './components/option-form-input/ARNOptionFormInputStore'
import { ARNPeopleLookupFormStore } from './components/people-lookup/ARNPeopleLookupFormStore'
import { ARNSignatureDrawingFormStore } from './components/signature-drawing/ARNSignatureDrawingFormStore'
import { ARNSingleLineTextFormStore } from './components/single-line-text/ARNSingleLineTextFormStore'
import { ARNBarCodescannerFormStore } from './components/barcode-scanner/ARNBarCodescannerFormStore'
import { ARNBooleanFormStore } from './components/boolean/ARNBooleanFormStore'
import { Nullable } from '@appformula/shared-foundation-x/src'
import { ARNNumberFormStore } from './components/number/ARNNumberFormStore'
import appConstants from '../../constants/const'
import { FormScreenStore } from './components/form-screen/FormScreenStore'
import { ARNFileFormStore } from './components/files/ARNFileFormStore'
import { ARNPhotoFormStore } from './components/photos/ARNPhotoFormStore'
import {
    DataSaveItemOperation,
    SaveDataToStorageOperation,
} from '../../utils/queue/operation/SaveDataToStorageOperation'
import { CompoundedOperation } from '../../utils/queue/operation/CompoundedOperation'
import { nanoid } from 'nanoid'
import { commonOperationQueue } from '../../utils/queue/operation-queue/CommonOperationQueue'
import { MainPageDataStore } from '../main/MainPageStore'
import { saveItemDataTempToSql } from '../../utils/database/SetSqlData'
import { onSyncSuccess } from '../../utils/sync/onSyncSuccess'
import { getSchemaByTableId } from '../../utils/sync/RefreshSchema'
import { FormulaManager } from '../../utils/integrate-formula/FormulaManager'

export type BarcodeScannerFormData = {
    value: string
}

export type MultiLineFormData = {
    value: string
}

export type SingleLineFormData = {
    value: string
}

export type NumberFormData = {
    value: number
}

export type DateTimeFormData = {
    value: Date
}

export type BooleanFormData = {
    value: boolean
}

export type SignatureDrawingFormData = {
    uploadedURL: URL
}

export type PeopleLookupFormData = {
    peoples: People[]
}

export type ValueData = {
    value: string
}

export type LocationData = {
    description: string
    latitude: number
    longitude: number
    mainText: string
    placeId: string
}

export type LookupFormData = {
    [id: string]: ILookup
}

export type OptionFormInputData = {
    options: Option[]
}

export type LookupFormInputExtend = {
    allowsMultipleSelection: boolean
}

export type ChildComponentFormData =
    | BarcodeScannerFormData
    | LocationData
    | LookupFormData
    | PeopleLookupFormData
    | DateTimeFormData
    | MultiLineFormData
    | SingleLineFormData
    | BooleanFormData
    | SignatureDrawingFormData
    | OptionFormInputData
    | NumberFormData

export type ComponentDatas = Record<string, ChildComponentFormData>

export interface BaseFormError {
    type: 'VALIDATE' | 'SEND_TO_STORAGE'
    message: string
}

export interface FormValidateError extends BaseFormError {
    field: string
    componentId: string
}

export interface SaveToStorageError extends BaseFormError {}

export type FormScreenError = FormValidateError | SaveToStorageError

export type ChildComponentFormStore =
    | ARNBarCodescannerFormStore
    | ARNBooleanFormStore
    | ARNDateTimeFormStore
    | ARNFileFormStore
    | ARNLocationFormStore
    | ARNLookupFormStore
    | ARNMultiLineTextFormStore
    | ARNOptionFormInputStore
    | ARNPeopleLookupFormStore
    | ARNPhotoFormStore
    | ARNSignatureDrawingFormStore
    | ARNSingleLineTextFormStore
    | ARNNumberFormStore

export class ARNFormPageStore {
    mainPageDataStore: MainPageDataStore
    pageStore: Nullable<FormPageStore> = undefined
    cachedFormScreenStore: FormScreenStore | undefined
    peopleLookupFormInput: PeopleLookupFormInputStore | undefined = undefined
    componentDatas: ComponentDatas = {}
    formScreenError: FormScreenError | undefined = undefined
    private formPageIdValue: string | undefined = undefined
    private cachedChildComponentFormStores: Record<
        string,
        ChildComponentFormStore
    > = {}
    totalSignComponents: number

    get formPageId(): string {
        return this.formPageIdValue
    }
    formOperationId: string = nanoid()
    formOperation = new CompoundedOperation(this.formOperationId, [], 'serial')
    uploadFileOperation = new CompoundedOperation(nanoid(), [], 'concurrent')
    dataSaveItemOperation: DataSaveItemOperation = {
        dataToSend: {},
        formOperationId: this.formOperationId,
    }
    saveDataToStorageOperationId: string = nanoid()
    saveDataToStorageOperation = new SaveDataToStorageOperation({
        operationID: this.saveDataToStorageOperationId,
        data: this.dataSaveItemOperation,
        type: 'saveDataToStorage',
    })

    constructor(mainPageDataStore: MainPageDataStore, formPageId: string) {
        this.formPageIdValue = formPageId
        this.mainPageDataStore = mainPageDataStore
        makeObservable(this, {
            componentDatas: observable,
            formScreenError: observable,
            setFormScreenError: action,
            reset: action,
        })
    }

    setTotalSignComponents(totalSignComponents: number) {
        this.totalSignComponents = totalSignComponents
    }

    reduceTotalSignComponents() {
        this.totalSignComponents -= 1
    }

    sendAllDataToQueue(teamId: string) {
        const dataToSend: Record<string, unknown> = {}
        Object.entries(this.cachedChildComponentFormStores).forEach(
            ([compId, compStore]) => {
                const targetColumnName =
                    this.pageStore.componentStore(compId).targetColumnName
                if (targetColumnName) {
                    if (compStore instanceof ARNBarCodescannerFormStore) {
                        dataToSend[targetColumnName] = compStore.value ?? ''
                    } else if (compStore instanceof ARNBooleanFormStore) {
                        dataToSend[targetColumnName] = compStore.value ?? null
                    } else if (compStore instanceof ARNDateTimeFormStore) {
                        dataToSend[targetColumnName] = compStore.value ?? ''
                    } else if (compStore instanceof ARNFileFormStore) {
                        dataToSend[targetColumnName] =
                            compStore.listFileUploaded ?? []
                    } else if (compStore instanceof ARNLocationFormStore) {
                        dataToSend[targetColumnName] =
                            compStore.locationSelected ?? []
                    } else if (compStore instanceof ARNLookupFormStore) {
                        dataToSend[targetColumnName] = compStore.data ?? {}
                    } else if (compStore instanceof ARNMultiLineTextFormStore) {
                        dataToSend[targetColumnName] = compStore.value ?? ''
                    } else if (
                        compStore instanceof ARNSingleLineTextFormStore
                    ) {
                        dataToSend[targetColumnName] = compStore.value ?? ''
                    } else if (compStore instanceof ARNNumberFormStore) {
                        dataToSend[targetColumnName] =
                            compStore.valueNumber ?? 0
                    } else if (compStore instanceof ARNOptionFormInputStore) {
                        dataToSend[targetColumnName] =
                            compStore.selectedOptionsString ?? ''
                    } else if (compStore instanceof ARNPeopleLookupFormStore) {
                        dataToSend[targetColumnName] =
                            compStore.selectedPeoples ?? []
                    } else if (compStore instanceof ARNPhotoFormStore) {
                        dataToSend[targetColumnName] =
                            compStore.listPhotoUploaded ?? []
                    } else if (
                        compStore instanceof ARNSignatureDrawingFormStore
                    ) {
                        dataToSend[targetColumnName] =
                            compStore.uploadedURL ?? ''
                    }
                }
            }
        )

        this.saveItemDataToSql(dataToSend, teamId)

        this.saveDataToStorageOperation.setDataToSend(dataToSend)

        this.formOperation.addOperations([
            this.uploadFileOperation,
            this.saveDataToStorageOperation,
        ])

        commonOperationQueue.addOperation(this.formOperation)
        this.mainPageDataStore.clearCacheARNFormPageStoreById(
            this.formPageIdValue
        )
    }

    async saveItemDataToSql(
        dataToSend: Record<string, unknown>,
        teamId: string
    ) {
        const tableSchema = await getSchemaByTableId(
            teamId,
            this.pageStore.tableId
        )

        saveItemDataTempToSql(
            this.saveDataToStorageOperationId,
            dataToSend,
            tableSchema,
            onSyncSuccess
        )
    }

    setPageStore(pageStore: FormPageStore) {
        console.info('run setPageStore Form')
        this.pageStore = pageStore
    }

    async validateForm(): Promise<void> {
        // For...of can break, while for...each can not.
        for (const entry of Object.entries(
            this.cachedChildComponentFormStores
        )) {
            try {
                await entry[1].validate()
            } catch (error) {
                return Promise.reject({
                    type: appConstants.TYPE_ERROR.VALIDATE,
                    message: error,
                    componentId: entry[0],
                    field: this.pageStore.componentStore(entry[0]).titleName,
                } as FormValidateError)
            }
        }

        return Promise.resolve()
    }

    clearFormScreenError(componentId: string) {
        if (
            (this.formScreenError as FormValidateError)?.componentId ===
            componentId
        ) {
            this.setFormScreenError(undefined)
        }
    }

    setFormScreenError(formScreenError: FormScreenError) {
        this.formScreenError = formScreenError
    }

    reset() {
        this.peopleLookupFormInput = undefined
        this.componentDatas = {}
        this.formScreenError = undefined
    }

    childComponentFormStore(
        componentId: string,
        componentType: FormInputType,
        initData?: ChildComponentFormData,
        formulaManager?: FormulaManager
    ): Nullable<ChildComponentFormStore> {
        const cachedChildComponentFormStores =
            this.cachedChildComponentFormStores[componentId]
        if (cachedChildComponentFormStores) {
            return cachedChildComponentFormStores
        } else {
            const newChildComponentFormStore = this.makeChildComponentFormStore(
                this.formPageId,
                componentId,
                componentType,
                initData,
                formulaManager
            )
            this.cachedChildComponentFormStores[componentId] =
                newChildComponentFormStore
            return newChildComponentFormStore
        }
    }

    private makeChildComponentFormStore(
        formPageId: string,
        componentId: string,
        componentType: FormInputType,
        initData?: ChildComponentFormData,
        formulaManager?: FormulaManager
    ): ChildComponentFormStore {
        switch (componentType) {
            case 'barcodeScanner':
                return new ARNBarCodescannerFormStore(
                    this,
                    formPageId,
                    componentId,
                    initData as BarcodeScannerFormData,
                    formulaManager
                )
            case 'boolean':
                return new ARNBooleanFormStore(
                    this,
                    formPageId,
                    componentId,
                    initData as BooleanFormData,
                    formulaManager
                )
            case 'dateTime':
                return new ARNDateTimeFormStore(
                    this,
                    formPageId,
                    componentId,
                    initData as DateTimeFormData,
                    formulaManager
                )
            case 'files':
                return new ARNFileFormStore(
                    this,
                    formPageId,
                    componentId,
                    formulaManager
                )
            case 'location':
                return new ARNLocationFormStore(
                    this,
                    formPageId,
                    componentId,
                    formulaManager
                )
            case 'lookup':
                return new ARNLookupFormStore(
                    this,
                    formPageId,
                    componentId,
                    initData as LookupFormData,
                    formulaManager
                )
            case 'multiLineText':
                return new ARNMultiLineTextFormStore(
                    this,
                    formPageId,
                    componentId,
                    initData as MultiLineFormData,
                    formulaManager
                )
            case 'singleLineText':
                return new ARNSingleLineTextFormStore(
                    this,
                    formPageId,
                    componentId,
                    initData as SingleLineFormData,
                    formulaManager
                )
            case 'options':
                return new ARNOptionFormInputStore(
                    this,
                    formPageId,
                    componentId,
                    initData as OptionFormInputData,
                    formulaManager
                )
            case 'peopleLookup':
                return new ARNPeopleLookupFormStore(
                    this,
                    formPageId,
                    componentId,
                    initData as PeopleLookupFormData,
                    formulaManager
                )
            case 'photos':
                return new ARNPhotoFormStore(
                    this,
                    formPageId,
                    componentId,
                    formulaManager
                )
            case 'signatureDrawing':
                return new ARNSignatureDrawingFormStore(
                    this,
                    formPageId,
                    componentId,
                    formulaManager
                )
            case 'number':
                return new ARNNumberFormStore(
                    this,
                    formPageId,
                    componentId,
                    initData as NumberFormData,
                    formulaManager
                )
        }
    }

    formScreenStore(formPageId: string): FormScreenStore {
        if (this.cachedFormScreenStore) {
            return this.cachedFormScreenStore
        } else {
            this.cachedFormScreenStore = new FormScreenStore(this, formPageId)
            return this.cachedFormScreenStore
        }
    }
}
