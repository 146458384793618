import { AxiosError, AxiosResponse } from 'axios'
import { nanoid } from 'nanoid'
import { apiClient, IProgressEvent } from '../../utils/network'
import * as Linking from 'expo-linking'

interface IUploadQ {
    [path: string]: any
}

interface IUploadPromise {
    data: any
    url: string
    callBackUpload?: (progressEvent: IProgressEvent) => void
    options?: IOptionUpload
}

interface IUploadProfilePromise {
    data: any
    url: string
    callBackUpload?: (progressEvent: IProgressEvent) => void
    options?: IOptionUpload
    email: string
}

interface IUploadBlobPromise {
    data: Blob
    url: string
    callBackUpload?: (progressEvent: IProgressEvent) => void
    options?: IOptionUpload
}

interface IOptionUpload {
    isHandleError?: boolean
}

class FileHandler {
    uploadQueue: IUploadQ

    constructor() {
        this.uploadQueue = {}
    }

    isUploadActive(path: string) {
        if (!path) {
            return false
        }
        return !!this.uploadQueue[path as keyof IUploadQ]
    }

    uploadFile({ data, url, callBackUpload, options }: IUploadPromise) {
        return new Promise((resolve, reject) => {
            if (!data) {
                reject()
            }

            const formData = new FormData()
            const file = new File([JSON.stringify(data)], data.name, {
                type: data.type,
            })

            formData.append('file', file)

            if (this.isUploadActive(data.id)) {
                // console.log('InQ download', this.uploadQueue[url as keyof IUploadQ])
                // this.uploadQueue[url] = this.uploadQueue[url];
            } else {
                // @ts-ignore: global variable
                const teamId = global.teamId
                // @ts-ignore: global variable
                const fullPathUrl = `assets/${teamId}/${url}`
                this.uploadQueue[data.id as keyof IUploadQ] = apiClient.put(
                    fullPathUrl,
                    {
                        handleProgressUpload: callBackUpload,
                        data,
                        headerCustomize: {
                            'Content-Type': 'multipart/form-data',
                        },
                        ...(options && { ...options }),
                    }
                )
            }

            this.uploadQueue[data.id as keyof IUploadQ]?.then(
                (response: AxiosResponse) => {
                    if (response?.data?.status >= 200 && response?.data?.status < 400) {
                        resolve(response)
                    } else {
                        reject(response?.data.status)
                    }
                    delete this.uploadQueue[data.id as keyof IUploadQ]
                }
            )

            this.uploadQueue[data.id as keyof IUploadQ]?.catch(
                (error: AxiosError) => {
                    delete this.uploadQueue[data.id as keyof IUploadQ]
                    reject(error)
                }
            )
        })
    }

    uploadProfile({
        data,
        url,
        callBackUpload,
        options,
        email,
    }: IUploadProfilePromise) {
        return new Promise((resolve, reject) => {
            if (!data) {
                reject()
            }

            const formData = new FormData()
            const file = new File([JSON.stringify(data)], data.name, {
                type: data.type,
            })

            formData.append('file', file)
            formData.append('email', email)

            if (this.isUploadActive(data.id)) {
                // console.log('InQ download', this.uploadQueue[url as keyof IUploadQ])
                // this.uploadQueue[url] = this.uploadQueue[url];
            } else {
                const fullPathUrl = `profile/${url}`
                this.uploadQueue[data.id as keyof IUploadQ] = apiClient.put(
                    fullPathUrl,
                    {
                        handleProgressUpload: callBackUpload,
                        data: formData,
                        headerCustomize: {
                            'Content-Type': 'multipart/form-data',
                        },
                        ...(options && { ...options }),
                    }
                )
            }

            this.uploadQueue[data.id as keyof IUploadQ]?.then(
                (response: AxiosResponse) => {
                    if (response?.status >= 200 && response?.status < 400) {
                        resolve(response)
                    } else {
                        reject(response?.status)
                    }
                    delete this.uploadQueue[data.id as keyof IUploadQ]
                }
            )

            this.uploadQueue[data.id as keyof IUploadQ]?.catch(
                (error: AxiosError) => {
                    delete this.uploadQueue[data.id as keyof IUploadQ]
                    reject(error)
                }
            )
        })
    }

    uploadFileBlob({ data, url, callBackUpload, options }: IUploadBlobPromise) {
        return new Promise((resolve, reject) => {
            if (!data) {
                reject()
            }

            const itemId = nanoid()
            const fileName = nanoid()
            const formData = new FormData()
            const file = new File([data], fileName, {
                type: data.type,
            })

            formData.append('file', file)

            if (this.isUploadActive(itemId)) {
                // console.log('InQ download', this.uploadQueue[url as keyof IUploadQ])
                // this.uploadQueue[url] = this.uploadQueue[url];
            } else {
                // @ts-ignore: global variable
                const teamId = global.teamId
                this.uploadQueue[itemId as keyof IUploadQ] = apiClient.post(
                    `${teamId}/${url}`,
                    {
                        handleProgressUpload: callBackUpload,
                        data,
                        headerCustomize: {
                            'Content-Type': 'multipart/form-data',
                        },
                        ...(options && { ...options }),
                    }
                )
            }

            this.uploadQueue[itemId as keyof IUploadQ]?.then(
                (response: AxiosResponse) => {
                    if (response.status >= 200 && response.status < 400) {
                        resolve(response)
                    } else {
                        reject(response.status)
                    }
                    delete this.uploadQueue[itemId as keyof IUploadQ]
                }
            )

            this.uploadQueue[itemId as keyof IUploadQ]?.catch(
                (error: AxiosError) => {
                    delete this.uploadQueue[itemId as keyof IUploadQ]
                    reject(error)
                }
            )
        })
    }
}

export default new FileHandler()
