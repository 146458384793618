import { observer } from 'mobx-react-lite'
import { WelcomePageStore } from './WelcomePageStore'
import { Theme, useTheme } from '../../styles/theme'
import React, { useMemo } from 'react'
import { useNavigation } from '@react-navigation/native'
import {
    Image,
    Keyboard,
    Platform,
    SafeAreaView,
    ScrollView,
    StyleSheet,
    Text,
    TextInput,
    TouchableOpacity,
} from 'react-native'
import AppButton from '../../components/AppButton'
import screenNames from '../../navigations/screenNames'
import image from '../../assets/icons'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

const SetupPasswordScreen = observer((props: { store: WelcomePageStore }) => {
    const theme = useTheme()
    const styles = useMemo(() => createStyles(theme), [theme])
    const navigation = useNavigation()

    const { store } = props

    const onBack = React.useCallback(() => {
        navigation.navigate(screenNames.SIGN_IN as never)
    }, [navigation])

    const [password, setPassword] = React.useState<string>('')
    const [confirmPassword, setConfirmPassword] = React.useState<string>('')

    const handleSetPassword = React.useCallback((value: string) => {
        setPassword(value)
    }, [])

    const handleSetConfirmPassword = React.useCallback((value: string) => {
        setConfirmPassword(value)
    }, [])

    const onSaveNewPassword = React.useCallback(async () => {
        if (password === confirmPassword) {
            await store.confirmPasswordReset(password.trim()).then(() => {
                navigation.navigate(screenNames.VERIFICATION_SUCCESS as never)
            })
        }
    }, [confirmPassword, navigation, password, store])

    return (
        <SafeAreaView style={styles.container}>
            <ScrollView
                contentContainerStyle={styles.containerKeyboardScroll}
                onScroll={() => {
                    Platform.OS !== 'android' && Keyboard.dismiss()
                }}
            >
                <KeyboardAwareScrollView
                    contentContainerStyle={styles.containerKeyboardScroll}
                >
                    <TouchableOpacity
                        style={styles.containerHeaderLeft}
                        onPress={onBack}
                    >
                        <Image
                            style={styles.backImg}
                            source={image.back}
                            resizeMode={'contain'}
                        />
                        <Text style={styles.txtHeaderLeft}>Login</Text>
                    </TouchableOpacity>
                    <ScrollView contentContainerStyle={styles.containerScroll}>
                        <Text style={styles.txtTitle}>Setup new Password</Text>
                        <Text style={styles.txtSubTitle}>
                            {`Login with your ${store.appName} employee account app to continue with ${store.appName}.`}
                        </Text>
                        <TextInput
                            style={styles.input}
                            onChangeText={handleSetPassword}
                            value={password}
                            placeholder="New password"
                            secureTextEntry={true}
                            placeholderTextColor={
                                theme.colorScheme.labelSecondary
                            }
                        />
                        <TextInput
                            style={[
                                styles.input,
                                confirmPassword.length > 0 &&
                                    confirmPassword !== password &&
                                    styles.wrongConfirm,
                            ]}
                            onChangeText={handleSetConfirmPassword}
                            value={confirmPassword}
                            placeholder="Re-enter new password"
                            secureTextEntry={true}
                            placeholderTextColor={
                                theme.colorScheme.labelSecondary
                            }
                        />
                        <AppButton
                            title="Save"
                            onPress={onSaveNewPassword}
                            loading={store.loadingState}
                        />
                    </ScrollView>
                </KeyboardAwareScrollView>
            </ScrollView>
        </SafeAreaView>
    )
})

export default SetupPasswordScreen

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        backImg: {
            width: 28,
            height: 28,
            tintColor: colorScheme.accentColor,
            marginLeft: 8,
        },
        containerHeaderLeft: { flexDirection: 'row', alignItems: 'center' },
        txtHeaderLeft: {
            color: colorScheme.accentColor,
            fontWeight: '500',
            fontSize: 17,
            lineHeight: 21,
        },
        container: {
            flex: 1,
            backgroundColor: colorScheme.backgroundL1,
            paddingTop: 20,
        },
        containerKeyboardScroll: {
            flex: 1,
            justifyContent: 'space-between',
        },
        containerScroll: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            paddingHorizontal: 24,
        },
        txtTitle: {
            fontWeight: '700',
            fontSize: 30,
            lineHeight: 36.31,
            color: colorScheme.labelPrimary,
            marginBottom: 9,
        },
        txtSubTitle: {
            fontWeight: '400',
            fontSize: 15,
            lineHeight: 22.5,
            color: colorScheme.labelSecondary,
            textAlign: 'center',
            marginBottom: 20,
        },
        input: {
            width: '100%',
            height: 50,
            marginBottom: 12,
            borderWidth: 1,
            padding: 10,
            borderRadius: roundness,
            borderColor: colorScheme.separator,
            color: colorScheme.labelPrimary,
        },
        wrongConfirm: {
            borderColor: '#EB1260',
        },
    })
