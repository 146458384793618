import { FirebaseFirestoreTypes } from '@react-native-firebase/firestore'
import { SQLError, SQLTransaction } from 'expo-sqlite'
import { GetDatabase } from '.'
import { DataType } from '../../pages/collection/components/filter/CollectionFilterStore'
import { ConvertDateToISOString } from '../date-time/DateTime'

const PREFIX_ITEM_TEMP = 'PREFIX_ITEM_TEMP'

type DocumentData = FirebaseFirestoreTypes.DocumentData

export function saveItemDataTempToSql(
    itemId: string,
    itemData: Record<string, unknown>,
    currentTable: DocumentData,
    onSyncSuccess: (tableName: string) => void
) {
    const tableName = currentTable['name']
    const columnsObject = currentTable['columns'] || {}

    const columnsString = `(${Object.keys(columnsObject)
        .map((name) => `\`${name}\``)
        .join(', ')})`

    const placeHolder = Object.keys(columnsObject)
        .map(() => '?')
        .join(', ')

    GetDatabase().transaction(
        (tx: SQLTransaction) => {
            const tempItem: Record<string, unknown> = {
                itemData,
                key: `${PREFIX_ITEM_TEMP}${itemId}`,
            }
            const values = Object.keys(columnsObject).map((column) => {
                if ((columnsObject[column] as DataType) === 'DateTime') {
                    return ConvertDateToISOString(tempItem[column] as string)
                } else {
                    return tempItem[column]
                }
            }) as (string | number)[]
            // console.info('tableName---', tableName)
            // console.info('columnsString---', columnsString)
            // console.info('placeHolder---', placeHolder)

            tx.executeSql(
                `INSERT INTO \`${tableName}\` ${columnsString} VALUES (${placeHolder})`,
                values
            )
        },
        (e: SQLError) => {
            console.warn('Error syncData:', e)
        },
        () => {
            // console.info('syncData Success', tableName)
            onSyncSuccess(tableName)
        }
    )
}
