/* eslint-disable no-inline-styles/no-inline-styles */
// Disable because can not use stylesheet for div tag style line: 44 and 46
// //
// //  DateTimeField.tsx
// //
// //  Created by thaitd96 on 2022-20-04 11:52.
// //  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
// //

import React, { ReactNode, useEffect, useMemo, useRef } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { Theme, useTheme } from '../../../../styles/theme'
import { DateTimeInputStore } from '@appformula/app-studio-core'
import { observer } from 'mobx-react-lite'
import { hexToRGBA } from '../../../../utils'
import appConstants from '../../../../constants/const'
import DatePicker, { CalendarContainer } from 'react-datepicker'
// import 'react-datepicker/dist/react-datepicker.css'
import dayjs from 'dayjs'
import { useBindingCommon } from '../../../collection/components/hooks/useBindingCommon'

export const DateTimeField = observer(
    (props: {
        dateTimeInput: DateTimeInputStore
        item: Record<string, unknown>
    }) => {
        const { dateTimeInput, item } = props
        const dateTimeInputData = dateTimeInput.object

        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])

        const [date, setDate] = React.useState<Date | undefined>(undefined)
        const datePickerRef = useRef(null)
        const title = useBindingCommon(dateTimeInputData.title, item)
        const initialValue = useBindingCommon(
            dateTimeInputData.initialValue,
            item
        )
        const isHidden = useBindingCommon(
            dateTimeInputData.isHidden,
            props.item
        )
        useEffect(() => {
            const dateTime = initialValue
                ? dayjs(initialValue as unknown as string)?.toDate()
                : dayjs().toDate()

            setDate(dateTime)
        }, [dateTimeInputData.initialValue, initialValue])

        const onChange = (date: Date) => setDate(date)

        const MyContainer = (props: {
            children: ReactNode[]
            className: string
        }) => {
            return (
                <div style={{ width: 330 }}>
                    <CalendarContainer className={props.className}>
                        <div style={{ position: 'relative' }}>
                            {props.children}
                        </div>
                    </CalendarContainer>
                </div>
            )
        }

        return (
            !isHidden && (
                <View style={styles.wrapDateTimeTrigger}>
                    <Text style={styles.dateTimeTitle} numberOfLines={2}>
                        {title}
                    </Text>
                    <View style={styles.viewDateTime}>
                        <DatePicker
                            ref={datePickerRef}
                            selected={date}
                            onChange={onChange}
                            showTimeSelect
                            customInput={
                                <View style={styles.dateTimeTrigger}>
                                    <Text
                                        style={styles.dateTimeContent}
                                        numberOfLines={1}
                                    >
                                        {dayjs(date).format(
                                            'MMM D, YYYY h:mm A'
                                        )}
                                    </Text>
                                </View>
                            }
                            shouldCloseOnSelect
                            calendarContainer={MyContainer}
                        />
                    </View>
                </View>
            )
        )
    }
)

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        viewDateTime: { flex: 1.25 },
        wrapDateTimeTrigger: {
            zIndex: 10000,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginVertical: appConstants.PADDING_COMMON / 2,
        },
        dateTimeTrigger: {
            borderRadius: roundness,
            backgroundColor: hexToRGBA(colorScheme.accentColor as string, 0.1),
            justifyContent: 'center',
            alignItems: 'center',
            padding: 10,
        },
        dateTimeContent: {
            color: colorScheme.accentColor,
            fontWeight: '500',
            fontSize: 15,
            lineHeight: 18,
        },
        dateTimeTitle: {
            flex: 1,
            fontWeight: '500',
            fontSize: 16,
            lineHeight: 19,
            marginRight: 8,
            color: colorScheme.labelPrimary,
        },
        dateTimeCustomContainer: { width: 330 },
        dateTimeChild: { position: 'relative' },
    })
