import * as React from 'react'
import { StyleSheet, View } from 'react-native'
import { Database, RawRecord } from '@nozbe/watermelondb'
import { Q } from '@nozbe/watermelondb'
import _ from 'lodash'
import Model from '@nozbe/watermelondb/Model'
export interface IDatabaseContext {
    getAll: (tableName: string) => Promise<RawRecord[]>
    getAllWithLimit: (tableName: string, limit: number) => Promise<RawRecord[]>
    getItemByKey: (tableName: string, key: string) => Promise<RawRecord>
    getAllWithQueryString: (
        tableName: string,
        query: Q.Clause[]
    ) => Promise<RawRecord[]>
    getAllVirtualSqlDataWithQueryString: (
        tableName: string,
        query: Q.Clause[]
    ) => Promise<number[]>
    getItemByIndexWithoutQueryString: (
        tableName: string,
        index: number
    ) => Promise<RawRecord>
    getItemByIndex: (
        tableName: string,
        query: Q.Clause[],
        index: number
    ) => Promise<RawRecord>
    getItemByIndexOfSection: (
        tableName: string,
        query: Q.Clause[],
        index: number,
        groupingColumn: string,
        groupingColumnValue: any
    ) => Promise<RawRecord>
    getAllVirtualListSectionDataWithQueryString: (
        tableName: string,
        query: Q.Clause[],
        groupingColumn: string
    ) => Promise<{ data: number[]; title: unknown }[]>
    getMaxMinValueOfTable: (
        tableName: string,
        columnName: string
    ) => Promise<Record<'Max' | 'Min', number>>
}

const AppDatabaseContext = React.createContext<IDatabaseContext>(
    {} as IDatabaseContext
)

interface IProps {
    database: Database
    children?: React.ReactElement
}

const AppDatabaseProvider = (props: IProps) => {
    const database = props.database
    const getAll = React.useCallback(
        async (tableName: string): Promise<RawRecord[]> => {
            try {
                const response = await database.get(tableName).query().fetch()
                const result = response.map((item: Model) => item._raw)
                return Promise.resolve(result)
            } catch (error) {
                return Promise.reject(error)
            }
        },
        [database]
    )

    const getAllWithLimit = React.useCallback(
        async (tableName: string, limit: number): Promise<RawRecord[]> => {
            try {
                const response = await database
                    .get(tableName)
                    .query(Q.take(limit))
                    .fetch()
                const result = response.map((item: Model) => item._raw)
                return Promise.resolve(result)
            } catch (error) {
                return Promise.reject(error)
            }
        },
        [database]
    )

    const getItemByKey = React.useCallback(
        async (tableName: string, key: string): Promise<RawRecord> => {
            try {
                const response = await database.get(tableName).find(key)
                const result = response._raw
                // console.info('result___________', result)
                return Promise.resolve(result)
            } catch (error) {
                return Promise.reject(error)
            }
        },
        [database]
    )

    const getAllVirtualSqlDataWithQueryString = React.useCallback(
        async (tableName: string, query: Q.Clause[]): Promise<number[]> => {
            try {
                const response = await database
                    .get(tableName)
                    .query(...query)
                    .fetchCount()
                const result = [...Array(response).keys()]
                // console.info('result___________', result)
                return Promise.resolve(result)
            } catch (error) {
                return Promise.reject(error)
            }
        },
        [database]
    )

    const getAllWithQueryString = React.useCallback(
        async (tableName: string, query: Q.Clause[]): Promise<RawRecord[]> => {
            try {
                const response = await database.get(tableName).query(...query)
                const result = response.map((item: Model) => item._raw)
                // console.info('result___________', result)
                return Promise.resolve(result)
            } catch (error) {
                return Promise.reject(error)
            }
        },
        [database]
    )

    const getItemByIndexWithoutQueryString = React.useCallback(
        async (tableName: string, index: number): Promise<RawRecord> => {
            try {
                const response = await database
                    .get(tableName)
                    .query(Q.skip(index), Q.take(1))
                const result = response[0]._raw
                // console.info('result___________', result)
                return Promise.resolve(result)
            } catch (error) {
                return Promise.reject(error)
            }
        },
        [database]
    )

    const getItemByIndex = React.useCallback(
        async (
            tableName: string,
            query: Q.Clause[],
            index: number
        ): Promise<RawRecord> => {
            try {
                const response = await database.get(tableName).query(
                    ...query,

                    Q.skip(index),
                    Q.take(1)
                )
                const result = response[0]._raw
                // console.info('result___________', result)
                return Promise.resolve(result)
            } catch (error) {
                return Promise.reject(error)
            }
        },
        [database]
    )

    const getItemByIndexOfSection = React.useCallback(
        async (
            tableName: string,
            query: Q.Clause[],
            index: number,
            groupingColumn: string,
            groupingColumnValue: any
        ): Promise<RawRecord> => {
            try {
                const value =
                    typeof groupingColumnValue === 'number'
                        ? +groupingColumnValue
                        : groupingColumnValue
                const response = await database
                    .get(tableName)
                    .query(
                        ...query,
                        Q.where(groupingColumn, Q.eq(value)),
                        Q.skip(index),
                        Q.take(1)
                    )
                const result = response[0]._raw
                // console.info('result___________', result)
                return Promise.resolve(result)
            } catch (error) {
                return Promise.reject(error)
            }
        },
        [database]
    )

    const getAllVirtualListSectionDataWithQueryString = React.useCallback(
        async (
            tableName: string,
            query: Q.Clause[],
            groupingColumn: string
        ): Promise<{ data: number[]; title: unknown }[]> => {
            try {
                const response = await database.get(tableName).query(...query)
                const list = response.map((item: Model) => item._raw)
                const result = _.chain(list)
                    .groupBy(groupingColumn)
                    .map((value, key) => {
                        return {
                            title:
                                (value as Record<string, unknown>[])?.[0]?.[
                                    groupingColumn
                                ] ?? '',
                            data: [...Array(value.length).keys()],
                        }
                    })
                    .value()
                // console.info('result___________', result)
                return Promise.resolve(result)
            } catch (error) {
                return Promise.reject(error)
            }
        },
        [database]
    )

    const getMaxMinValueOfTable = React.useCallback(
        async (
            tableName: string,
            columnName: string
        ): Promise<Record<'Max' | 'Min', number>> => {
            console.info('getMaxMinValueOfTable', tableName, columnName)
            try {
                const responseMin = await database
                    .get(tableName)
                    .query(Q.sortBy(columnName, Q.asc), Q.skip(0), Q.take(1))

                const responseMax = await database
                    .get(tableName)
                    .query(Q.sortBy(columnName, Q.desc), Q.skip(0), Q.take(1))
                // @ts-ignore
                const resultMin = responseMin[0]._raw
                // @ts-ignore
                const resultMax = responseMax[0]._raw

                // @ts-ignore
                const result = {
                    // @ts-ignore
                    Min: resultMin[columnName],
                    // @ts-ignore
                    Max: resultMax[columnName],
                }
                return Promise.resolve(result)
            } catch (error) {
                return Promise.reject(error)
            }
        },
        [database]
    )

    const commonContext = React.useMemo<IDatabaseContext>(() => {
        return {
            getAll,
            getItemByKey,
            getAllWithQueryString,
            getAllVirtualSqlDataWithQueryString,
            getItemByIndexWithoutQueryString,
            getItemByIndex,
            getItemByIndexOfSection,
            getAllVirtualListSectionDataWithQueryString,
            getMaxMinValueOfTable,
            getAllWithLimit,
        }
    }, [
        getAll,
        getItemByKey,
        getAllWithQueryString,
        getAllVirtualSqlDataWithQueryString,
        getItemByIndexWithoutQueryString,
        getItemByIndex,
        getItemByIndexOfSection,
        getAllVirtualListSectionDataWithQueryString,
        getMaxMinValueOfTable,
        getAllWithLimit,
    ])

    return (
        <View style={styles.container}>
            <AppDatabaseContext.Provider value={commonContext} {...props}>
                {props.children}
            </AppDatabaseContext.Provider>
        </View>
    )
}

const useDatabaseAction = (): IDatabaseContext => {
    const context = React.useContext<IDatabaseContext>(AppDatabaseContext)
    return context
}

export { AppDatabaseProvider, AppDatabaseContext, useDatabaseAction }

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
})
