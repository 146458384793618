//
//  FormScreen.tsx
//
//  Created by thaitd96 on 2022-18-04 17:20.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { observer } from 'mobx-react-lite'
import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { StyleSheet } from 'react-native'
import { DesignPageStore, FormPageStore } from '@appformula/app-studio-core'
import { ARNFormPageStore, FormScreenError } from '../../ARNFormPageStore'
import { Theme, useTheme } from '../../../../styles/theme'
import { ComponentSwitcher } from '../../ComponentSwitcher'
import FormHeader from '../form-header/FormHeader'
import { SafeAreaView } from 'react-native-safe-area-context'
import { FormScreenStore } from './FormScreenStore'
import { useNavigation } from '@react-navigation/native'
import ScrollViewHighLight from '../../../../components/ScrollViewHighLight'
import { DesignPageContext } from '../../../../context/DesignPageContext'
import { AppMetadataContext } from '../../../../context/AppMetadataContext'
import appConstants from '../../../../constants/const'
import WrappingComponentHighlight from '../../../../components/WrappingComponentHighlight/WrappingComponentHighlight'

const FormScreenContent = observer(
    (props: {
        pageStore: FormPageStore
        arnFormPageStore: ARNFormPageStore
        formScreenStore: FormScreenStore
        designPageStore: DesignPageStore
    }) => {
        const {
            pageStore,
            arnFormPageStore,
            formScreenStore,
            designPageStore,
        } = props
        const navigation = useNavigation()
        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])
        const { teamId } = useContext(AppMetadataContext)

        useEffect(() => {
            const totalSignComponents = pageStore
                ? pageStore.componentIds
                      .map((id) => pageStore.componentStore(id))
                      .filter(
                          (store) => store?.inputType === 'signatureDrawing'
                      ).length
                : 0

            arnFormPageStore.setTotalSignComponents(totalSignComponents)
        }, [arnFormPageStore, pageStore])

        useEffect(() => {
            arnFormPageStore.setPageStore(pageStore)
            arnFormPageStore.saveDataToStorageOperation.setTableId(
                pageStore.tableId
            )
        }, [arnFormPageStore, pageStore, pageStore.object])

        React.useEffect(() => {
            return function cleanup() {
                arnFormPageStore.reset()
            }
        }, [arnFormPageStore])

        const sendDataToStore = useCallback(() => {
            arnFormPageStore.sendAllDataToQueue(teamId)
            navigation.goBack()
        }, [arnFormPageStore, navigation, teamId])

        const handleValidateAndSendData = useCallback(async () => {
            if (arnFormPageStore.totalSignComponents > 1) {
                arnFormPageStore.reduceTotalSignComponents()
            } else {
                try {
                    await arnFormPageStore.validateForm()
                    sendDataToStore()
                } catch (error) {
                    formScreenStore.handleErrorSaveData(
                        error as FormScreenError
                    )
                }
            }
        }, [arnFormPageStore, formScreenStore, sendDataToStore])

        useEffect(() => {
            const pubSubSaveSignatureDone = PubSub.subscribe(
                appConstants.TOPIC_SAVE_SIGN_DONE,
                handleValidateAndSendData
            )

            return () => {
                PubSub.unsubscribe(pubSubSaveSignatureDone)
            }
        }, [
            arnFormPageStore,
            formScreenStore,
            handleValidateAndSendData,
            sendDataToStore,
        ])

        const onSaveData = useCallback(async () => {
            if (arnFormPageStore.totalSignComponents === 0) {
                handleValidateAndSendData()
            } else {
                PubSub.publish(appConstants.TOPIC_SIGN)
            }
        }, [arnFormPageStore.totalSignComponents, handleValidateAndSendData])

        const renderItems = (item: string, index: number) => {
            const componentStore = pageStore.componentStore(item)
            const componentInfoPath = {
                pageId: pageStore.pageId,
                componentId: item,
            }

            return (
                <WrappingComponentHighlight
                    wrapChildrenComp={{
                        paddingHorizontal: 0,
                    }}
                    componentInfoPath={componentInfoPath}
                    designPageStore={designPageStore}
                    scrollViewHighLightStore={formScreenStore.getScrollViewHighLightStore(
                        designPageStore
                    )}
                >
                    <ComponentSwitcher
                        key={index}
                        componentStore={componentStore}
                        arnFormPageStore={arnFormPageStore}
                    />
                </WrappingComponentHighlight>
            )
        }

        return (
            <SafeAreaView style={styles.container} edges={['bottom']}>
                <FormHeader
                    arnFormPageStore={arnFormPageStore}
                    pageStore={pageStore}
                    onSaveData={onSaveData}
                />
                <ScrollViewHighLight
                    pageId={pageStore.pageId}
                    designPageStore={designPageStore}
                    scrollViewHighLightStore={formScreenStore.getScrollViewHighLightStore(
                        designPageStore
                    )}
                    data={pageStore.componentIds}
                    renderItems={renderItems}
                />
            </SafeAreaView>
        )
    }
)

const FormScreen = observer(
    (props: {
        pageStore: FormPageStore
        arnFormPageStore: ARNFormPageStore
        formScreenStore: FormScreenStore
    }) => {
        return (
            <DesignPageContext.Consumer>
                {(designPageStore) => (
                    <FormScreenContent
                        pageStore={props.pageStore}
                        arnFormPageStore={props.arnFormPageStore}
                        formScreenStore={props.formScreenStore}
                        designPageStore={designPageStore}
                    />
                )}
            </DesignPageContext.Consumer>
        )
    }
)

export default FormScreen

const createStyles = ({ colorScheme }: Theme) =>
    StyleSheet.create({
        container: { flex: 1, backgroundColor: colorScheme.backgroundL1 },
        viewCustom: { flex: 1, backgroundColor: colorScheme.backgroundL1 },
    })
