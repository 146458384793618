import {
    Binding,
    BindingValue,
    ConstantBinding,
    ContextualMappingBinding,
    FormulaBinding,
    KeyPathBinding,
} from '@appformula/app-descriptor/src'
import { FormulaManager } from '../integrate-formula/FormulaManager'

type TypeResult = BindingValue | null

export const TransformBinding = (
    binding: Binding<BindingValue>,
    formulaManager?: FormulaManager,
    itemData?: Record<string, unknown>
): Promise<TypeResult> => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
        try {
            let result = null
            if (binding?.source === 'constant') {
                result = (binding as ConstantBinding<BindingValue>)
                    ?.constantValue
            } else if (binding?.source === 'formula') {
                const formulaString = (binding as FormulaBinding)?.formulaString
                result = await formulaManager?.execute(formulaString, {
                    dataRow: itemData,
                })
            } else if (binding?.source === 'contexualMapping') {
                result =
                    itemData?.[
                        (binding as ContextualMappingBinding).mappingPropName
                    ]
            } else if (binding?.source === 'keyPath') {
                const keyPath = (binding as KeyPathBinding).keyPath
                let resultTemp: Record<string, unknown> | unknown = itemData
                keyPath.forEach((key: string) => {
                    resultTemp = (resultTemp as Record<string, unknown>)?.[key]
                })
                result = resultTemp
            }

            resolve(result as TypeResult)
        } catch (error) {
            // console.info('error----', error, itemData)
            reject(error)
        }
    })
}
