import { View, Text, Image, Pressable } from 'react-native'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { TextInput } from 'react-native-gesture-handler'
import image from '../../../../../assets/icons'
import { useTheme } from '../../../../../styles/theme'
import GoogleMapReact from 'google-map-react'
import LocationResults, { ILocation } from './LocationResults'
import { useNavigation, useRoute } from '@react-navigation/native'
import * as Location from 'expo-location'
import { debounce } from 'throttle-debounce'
import GoogleMap from '../../../../../utils/network/extensions/GoogleMap'
import appConstants from '../../../../../constants/const'
import { ARNLocationFormStore, GeocodedLocation } from '../ARNLocationFormStore'
import { observer } from 'mobx-react-lite'
import Animated, { SlideInDown, SlideOutDown } from 'react-native-reanimated'
import { ARNFormPageStore, LocationData } from '../../../ARNFormPageStore'
import { createStyles } from './styles'
import { getCurrentLocation } from '../../../../../utils'
import {
    FormPageStore,
    LocationFormInputStore,
} from '@appformula/app-studio-core/src'

type Params = {
    componentId: string
}

const defaultProps = {
    center: {
        lat: 10.99835602,
        lng: 77.01502627,
    },
    zoom: 15,
}

const LocationSearchingPage = observer(
    (props: {
        pageStore: FormPageStore
        arnFormPageStore: ARNFormPageStore
    }) => {
        const { arnFormPageStore, pageStore } = props
        const route = useRoute()
        const { componentId } = route.params as Params

        const locationFormInputStore = pageStore.componentStore(
            componentId
        ) as LocationFormInputStore

        const arnLocationFormStore = arnFormPageStore.childComponentFormStore(
            componentId,
            locationFormInputStore.inputType
        ) as ARNLocationFormStore

        return (
            <LocationSearchingContent
                historyLocationsSelected={
                    arnLocationFormStore.historyLocationsSelected
                }
                arnLocationFormStore={arnLocationFormStore}
                arnFormPageStore={arnFormPageStore}
            />
        )
    }
)

const PinComponent = (props: { lat: number; lng: number }) => {
    const theme = useTheme()
    const styles = useMemo(() => createStyles(theme), [theme])
    return <Image source={image.pin3} style={styles.imgPin3} />
}

const LocationSearchingContent = observer(
    (props: {
        historyLocationsSelected: Array<GeocodedLocation & ILocation>
        arnLocationFormStore: ARNLocationFormStore
        arnFormPageStore: ARNFormPageStore
    }) => {
        const {
            historyLocationsSelected,
            arnFormPageStore,
            arnLocationFormStore,
        } = props
        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])
        const [showLocationResults, setShowLocationResults] = useState(false)
        const navigation = useNavigation()
        const [locationsSearched, setLocationsSearched] = useState([])
        const [valueSearch, setValueSearch] = useState('')
        const route = useRoute()
        const { componentId } = route.params as Params

        const data = arnFormPageStore.componentDatas[
            componentId
        ] as LocationData

        const initLocations = useMemo(() => {
            return [
                { placeId: appConstants.PLACE_ID_CURRENT },
                ...historyLocationsSelected,
            ]
        }, [historyLocationsSelected])

        const textInputRef = useRef<TextInput>()

        useEffect(() => {
            Location.setGoogleApiKey(appConstants.API_KEY_GOOGLE_MAP)
        }, [])

        const onSubmitSearch = useCallback(async () => {
            try {
                const response = await GoogleMap.getPrediction({
                    input: valueSearch,
                })
                const predictions = response.data.predictions.map(
                    (item: any) => ({
                        description: item?.description || '',
                        placeId: item?.place_id || '',
                        mainText: item?.structured_formatting?.main_text || '',
                    })
                )

                setLocationsSearched(predictions)
            } catch (error) {
                console.log('error', error)
            }
        }, [valueSearch])

        const handleSearch = useCallback(() => {
            if (valueSearch) {
                onSubmitSearch()
            } else {
                setLocationsSearched(initLocations)
            }
        }, [initLocations, onSubmitSearch, valueSearch])

        useEffect(() => {
            debounce(1000, handleSearch)()
        }, [handleSearch, onSubmitSearch, valueSearch])

        const onFocus = () => {
            setShowLocationResults(true)
        }

        const confirmLocation = () => {
            navigation.goBack()
        }

        const onSelect = async (location: ILocation) => {
            try {
                setShowLocationResults(false)
                textInputRef.current && textInputRef.current.blur()
                let result

                if (location.placeId !== appConstants.PLACE_ID_CURRENT) {
                    const response = await GoogleMap.getDetailPlace({
                        place_id: location.placeId,
                    })
                    result = {
                        ...location,
                        mainText: response.data.result?.name || '',
                        description:
                            response.data.result?.formatted_address || '',
                        longitude:
                            response.data.result?.geometry?.location?.lng || '',
                        latitude:
                            response.data.result?.geometry?.location?.lat || '',
                    }
                    arnLocationFormStore.setHistoryLocationsSelected(result)
                } else {
                    const response = await getCurrentLocation()
                    result = {
                        placeId: appConstants.PLACE_ID_CURRENT,
                        description: response.description,
                        mainText: response.mainText,
                        longitude: response.longitude,
                        latitude: response.latitude,
                    }
                }

                const locationSelected = {
                    longitude: result.longitude,
                    latitude: result.latitude,
                    placeId: result.placeId,
                    description: result.description,
                    mainText: result.mainText,
                }
                arnLocationFormStore.setLocationSelected(locationSelected)
            } catch (error) {
                console.log('error', error)
            }
        }

        const onChangeText = (text: string) => {
            setValueSearch(text)
        }

        const onClear = () => {
            arnLocationFormStore.clearLocationSelected()
        }

        return (
            <View style={styles.container}>
                <View style={styles.header}>
                    <View style={styles.wrapSeach}>
                        <Image
                            style={styles.imgSearch}
                            source={image.iconSearch}
                        />
                        <TextInput
                            ref={textInputRef}
                            value={valueSearch}
                            style={styles.inputSeach}
                            placeholder={'Location'}
                            placeholderTextColor={
                                theme.colorScheme.labelSecondary
                            }
                            onFocus={onFocus}
                            onChangeText={onChangeText}
                        />
                    </View>
                    <Pressable onPress={confirmLocation} hitSlop={10}>
                        <Text style={styles.txtDone}>Done</Text>
                    </Pressable>
                </View>
                <View style={styles.wrapMapLocation}>
                    <GoogleMapReact
                        bootstrapURLKeys={{
                            key: appConstants.API_KEY_GOOGLE_MAP,
                        }}
                        defaultCenter={defaultProps.center}
                        center={
                            data
                                ? { lat: data.latitude, lng: data.longitude }
                                : null
                        }
                        defaultZoom={defaultProps.zoom}
                        yesIWantToUseGoogleMapApiInternals
                    >
                        {data && (
                            <PinComponent
                                lat={data.latitude}
                                lng={data.longitude}
                            />
                        )}
                    </GoogleMapReact>
                    {data && (
                        <Animated.View
                            entering={SlideInDown.delay(500)}
                            exiting={SlideOutDown.delay(500)}
                            style={styles.viewAddressSelected}
                        >
                            <View style={styles.wrapTitleAddrSelected}>
                                <Image
                                    source={image.pin3}
                                    style={styles.imgPin3}
                                />
                                <View style={styles.wrapTxtTitleAddr}>
                                    <Text style={styles.txtMain}>
                                        {data.mainText}
                                    </Text>
                                    <Text style={styles.txtDescription}>
                                        {data.description}
                                    </Text>
                                </View>
                            </View>
                            <Pressable hitSlop={10} onPress={onClear}>
                                <Image
                                    source={image.close}
                                    style={styles.imgClose}
                                />
                            </Pressable>
                        </Animated.View>
                    )}
                    {showLocationResults && (
                        <LocationResults
                            onSelect={onSelect}
                            data={locationsSearched}
                            selectedLocation={data}
                        />
                    )}
                    <View />
                </View>
            </View>
        )
    }
)

export default LocationSearchingPage
