import { TouchableOpacity, Image, StyleSheet } from 'react-native'
import React, { useMemo } from 'react'
import { Theme, useTheme } from '../../../../styles/theme'
import image from '../../../../assets/icons'
import { useNavigation } from '@react-navigation/native'
import screenNames from '../../../../navigations/screenNames'

const SortButton = () => {
    const theme = useTheme()
    const styles = useMemo(() => createStyles(theme), [theme])
    const navigation = useNavigation()

    const openSort = () => {
        navigation.navigate(screenNames.SORT_COLLECTION as never)
    }

    return (
        <TouchableOpacity onPress={openSort}>
            <Image style={styles.icon} source={image.iconSort}></Image>
        </TouchableOpacity>
    )
}

export default SortButton

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        icon: { height: 34, width: 34, tintColor: colorScheme.accentColor },
    })
