//
//  form/components/SearchFormPage.tsx
//
//  Created by thaitd96 on 2022-06-24 10:35.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { useNavigation } from '@react-navigation/native'
import image from '../../../assets/icons'
import appConstants from '../../../constants/const'
import React, { useCallback, useMemo } from 'react'
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { Theme, useTheme } from '../../../styles/theme'
import { FormInputType } from '@appformula/app-descriptor/src'
import AppTextInput from '../../../components/AppTextInput'

export const SearchFormPage = (props: {
    inputType: FormInputType
    placeholder?: string
}) => {
    const { placeholder } = props
    const theme = useTheme()
    const styles = useMemo(() => createStyles(theme), [theme])
    const navigation = useNavigation()
    const done = useCallback(() => {
        navigation.goBack()
    }, [navigation])

    return (
        <View style={styles.viewWrap}>
            <Image
                source={image.iconSearch}
                style={styles.search}
                resizeMode={'contain'}
            ></Image>
            <AppTextInput
                style={styles.textInput}
                placeholder={placeholder || 'Options'}
            />
            <TouchableOpacity style={styles.touchDone}>
                <Text style={styles.textDone} onPress={done}>
                    {'Done'}
                </Text>
            </TouchableOpacity>
        </View>
    )
}

const createStyles = ({ colorScheme }: Theme) =>
    StyleSheet.create({
        textDone: {
            fontSize: 17,
            color: colorScheme.accentColor,
            fontWeight: '500',
        },
        search: { height: 24, width: 24 },
        touchDone: {
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            paddingLeft: 5,
        },
        textInput: {
            flex: 1,
            marginLeft: 4,
            fontWeight: '500',
            fontSize: 16,
            color: colorScheme.labelPrimary,
            paddingVertical: 18,
        },
        viewWrap: {
            width: '100%',
            backgroundColor: colorScheme.backgroundL1,
            height: 60,
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'row',
            paddingLeft: 12,
            paddingRight: appConstants.PADDING_COMMON,
            borderBottomColor: colorScheme.separator,
            borderBottomWidth: 1,
        },
    })
