//
//  CalendarLayout.tsx
//
//  Created by thaitd96 on 2022-19-04 11:38.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React, { useCallback, useEffect, useMemo } from 'react'
import { CollectionPageStore } from '@appformula/app-studio-core'
import { observer } from 'mobx-react-lite'
import { StyleSheet, Text, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { Theme, useTheme } from '../../../../styles/theme'
import { Search } from '../Search'
import { CalendarHeader } from './calendar-header/CalendarHeader'
import { CalendarLayoutStore } from './CalendarLayoutStore'
import { CalendarLayout as CalendarLayoutDescriptor } from '@appformula/app-descriptor/src'
import { SectionHeaders } from './SectionHeaders'
import { DayCalendar } from './day-calendar/DayCalendar'
import { WeekCalendar } from './week-calendar/WeekCalendar'
import { MonthCalendar } from './month-calendar/MonthCalendar'
import { GroupDisplay } from './ItemGroupCalendar'
import { ARNCollectionPageStore } from '../../ARNCollectionPageStore'
import { TabView } from 'react-native-tab-view'
import FilterButton from '../component-utils/FilterButton'
import screenNames from '../../../../navigations/screenNames'
import { useBinding } from '../hooks/useBinding'
import { useWidthHeightMainLayout } from '../hooks/useSpecifyPlatformWindowDimensions'

export type ItemData = {
    color: string
}

export type SectionData = {
    title: string
    data: ItemData[]
}

export const CalendarLayout = observer(
    (props: {
        pageStore: CollectionPageStore
        calendarLayoutStore: CalendarLayoutStore
        arnCollectionPageStore: ARNCollectionPageStore
    }) => {
        const layout = useWidthHeightMainLayout()
        const { calendarLayoutStore, pageStore } = props
        const [index, setIndex] = React.useState(0)
        const [routes] = React.useState([
            { key: 'D', title: 'D' },
            { key: 'W', title: 'W' },
            { key: 'M', title: 'M' },
        ])

        const navigation = useNavigation()
        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])
        const activeLayout = props.pageStore
            .activeLayout as CalendarLayoutDescriptor
        const groupsDisplay = Object.values(
            activeLayout.groupsDisplay || {}
        ) as GroupDisplay[]
        const title = useBinding(pageStore.title)

        const goToFilter = useCallback(() => {
            navigation &&
                navigation.navigate(
                    screenNames.COLLECTION_FILTER_SCREEN as never,
                    { pageId: props.pageStore.pageId } as never
                )
        }, [navigation, props.pageStore.pageId])

        useEffect(() => {
            navigation.setOptions({
                headerLeft: () => (
                    <Text
                        style={styles.title}
                        numberOfLines={1}
                        ellipsizeMode={'tail'}
                    >
                        {title}
                    </Text>
                ),
                headerRight: () => (
                    <View>
                        <FilterButton goToFilter={goToFilter} />
                    </View>
                ),
                title: '',
            })
        }, [
            goToFilter,
            navigation,
            styles.icon,
            styles.title,
            theme.colorScheme.accentColor,
            title,
        ])

        useEffect(() => {
            props.arnCollectionPageStore.startListeningDataChange()
        }, [props.arnCollectionPageStore])

        useEffect(() => {
            if (props.calendarLayoutStore.calendarMode === 'D') {
                setIndex(0)
            } else if (props.calendarLayoutStore.calendarMode === 'W') {
                setIndex(1)
            } else if (props.calendarLayoutStore.calendarMode === 'M') {
                setIndex(2)
            }
        }, [props.calendarLayoutStore.calendarMode])

        const CustomTabBar = useCallback(() => {
            return (
                <View>
                    <CalendarHeader calendarLayoutStore={calendarLayoutStore} />
                    <SectionHeaders
                        calendarLayoutStore={calendarLayoutStore}
                        groupsDisplay={groupsDisplay}
                    />
                </View>
            )
        }, [])

        const renderScene = ({
            route,
        }: {
            route: { key: string; title: string }
        }) => {
            switch (route.key) {
                case 'D':
                    return (
                        <DayCalendar
                            dayCalendarStore={calendarLayoutStore.dayCalendarStore()}
                            calendarLayoutStore={calendarLayoutStore}
                            pageStore={pageStore}
                        />
                    )
                case 'W':
                    return (
                        <WeekCalendar
                            weekCalendarStore={calendarLayoutStore.weekCalendarStore()}
                            calendarLayoutStore={calendarLayoutStore}
                            pageStore={pageStore}
                        />
                    )
                case 'M':
                    return (
                        <MonthCalendar
                            monthCalendarStore={calendarLayoutStore.monthCalendarStore()}
                            calendarLayoutStore={calendarLayoutStore}
                            pageStore={pageStore}
                        />
                    )
                default:
                    return null
            }
        }

        const renderLazyPlaceholder = () => {
            return (
                <View>
                    <Text>Loading</Text>
                </View>
            )
        }

        return (
            <View style={styles.view}>
                <Search
                    searchCollectionStore={props.arnCollectionPageStore.getSearchStore()}
                    searchBarStore={props.pageStore.searchBarStore()}
                />
                <TabView
                    lazy
                    navigationState={{ index, routes }}
                    renderScene={renderScene}
                    onIndexChange={setIndex}
                    initialLayout={{ width: layout.width }}
                    renderTabBar={() => {
                        return <CustomTabBar />
                    }}
                    swipeEnabled={false}
                    renderLazyPlaceholder={renderLazyPlaceholder}
                />
            </View>
        )
    }
)

const createStyles = ({ colorScheme }: Theme) =>
    StyleSheet.create({
        title: {
            fontSize: 25,
            fontWeight: '700',
            color: colorScheme.labelPrimary,
        },
        view: { flex: 1 },
        icon: { height: 34, width: 34, tintColor: colorScheme.accentColor },
    })
