//
//  Map.tsx
//
//  Created by hienbv97 on 2022-20-04 11:49.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { TextInputStore } from '@appformula/app-studio-core/src'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useMemo, useState } from 'react'
import { StyleSheet, TextInput as TextInputRN, View } from 'react-native'
import { Theme, useTheme } from '../../../styles/theme'
import appConstants from '../../../constants/const'
import { useBindingCommon } from '../../collection/components/hooks/useBindingCommon'

export const TextInput = observer(
    (props: { textInput: TextInputStore; item: Record<string, unknown> }) => {
        const { textInput, item } = props
        const textInputData = textInput.object
        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])
        const [value, setValue] = useState<string>('')
        const initialText = useBindingCommon(textInputData?.initialText, item)
        const isHidden = useBindingCommon(textInputData.isHidden, props.item)
        const placeholder = useBindingCommon(textInputData?.placeholder, item)
        useEffect(() => {
            setValue((initialText || '') as string)
        }, [initialText])

        const onChangeText = (text: string) => {
            setValue(text)
        }

        return (
            !isHidden && (
                <View style={styles.viewTextInput}>
                    <TextInputRN
                        placeholder={(placeholder || '') as string}
                        placeholderTextColor={theme.colorScheme.labelSecondary}
                        style={styles.txtInput}
                        onChangeText={onChangeText}
                        value={value}
                    />
                </View>
            )
        )
    }
)

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        viewTextInput: {
            backgroundColor: colorScheme.backgroundL2,
            flexDirection: 'row',
            marginVertical: appConstants.PADDING_COMMON / 2,
            borderRadius: roundness,
        },

        imgSearch: {
            height: 34,
            width: 34,
            marginLeft: 8,
            marginVertical: 8,
            marginRight: 5,
        },

        txtInput: {
            fontWeight: '500',
            fontSize: 15,
            flex: 1,
            color: colorScheme.labelPrimary,
            height: 50,
            paddingHorizontal: 15
        },
    })
