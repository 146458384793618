//
//  Files.ts
//
//  Created by thaitd96 on 2022-08-10 14:24
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { FilesFormInput } from '@appformula/app-descriptor'
import { FilesFormInputRepository } from '@appformula/app-studio-core'
import { LocalFormInputRepository } from '../FormInputRepository'

export class LocalFilesFormInputRepository
    extends LocalFormInputRepository<FilesFormInput>
    implements FilesFormInputRepository
{
    addExcludeFileType(
        fileType: 'audio' | 'video' | 'docs' | 'images'
    ): Promise<void> {
        throw new Error('Method not implemented.')
    }
    removeExcludeFileType(
        fileType: 'audio' | 'video' | 'docs' | 'images'
    ): Promise<void> {
        throw new Error('Method not implemented.')
    }
}
