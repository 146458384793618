//
//  onSyncSuccess.ts
//
//  Created by thaitd96 on 2022-06-29 11:00.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import appConstants from '../../constants/const'

export const onSyncSuccess = (tableName: string) => {
    PubSub.publish(appConstants.SYNC_DATA_DONE, { tableName })
}
