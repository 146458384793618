import { CollectionPageStore } from '@appformula/app-studio-core/src'
import { Q } from '@nozbe/watermelondb'
import { useState, useEffect, useCallback } from 'react'
import { useDatabaseAction } from '../../../../context/AppDatabaseContext'

export function useGetItemFromIndex(
    pageStore: CollectionPageStore,
    item: number,
    queryString?: Q.Clause[],
    valueGrouping?: unknown
) {
    const [itemData, setItemData] = useState<Record<string, unknown>>({})
    const { getItemByIndexOfSection, getItemByIndex } = useDatabaseAction()

    const getItemData = useCallback(async () => {
        try {
            if (pageStore.activeLayout.groupingColumn) {
                setItemData(
                    await getItemByIndexOfSection(
                        pageStore?.dataSource?.id ?? '',
                        queryString,
                        item,
                        pageStore.activeLayout.groupingColumn,
                        valueGrouping
                    )
                )
            } else {
                setItemData(
                    await getItemByIndex(
                        pageStore?.dataSource?.id ?? '',
                        queryString,
                        item
                    )
                )
            }
        } catch (error) {
            console.info(error)
        }
    }, [
        getItemByIndex,
        getItemByIndexOfSection,
        item,
        pageStore.activeLayout.groupingColumn,
        pageStore?.dataSource?.id,
        queryString,
        valueGrouping,
    ])

    useEffect(() => {
        getItemData()
        return () => {
            setItemData({})
        }
    }, [getItemData])

    return itemData
}
