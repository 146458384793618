//
//  ItemGroupCalendar.tsx
//
//  Created by thaitd96 on 2022-06-13 10:59.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React, { useCallback, useMemo } from 'react'
import image from '../../../../assets/icons'
import { observer } from 'mobx-react-lite'
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { Theme, useTheme } from '../../../../styles/theme'
import _ from 'lodash'
import { hexToRGBA } from '../../../../utils'
import { CalendarLayoutStore } from './CalendarLayoutStore'

export type GroupDisplay = {
    color: string
    columnValue: string
}

const ItemGroupCalendar = observer(
    (props: {
        item: GroupDisplay
        activeSections: string[]
        calendarLayoutStore: CalendarLayoutStore
    }) => {
        const theme = useTheme()
        const { item } = props
        const isActive = _.includes(
            props.calendarLayoutStore.activeSections,
            item.columnValue
        )

        const styles = useMemo(
            () => createStyles(theme, item, isActive),
            [theme, item, isActive]
        )

        const changeIsActive = useCallback(() => {
            props.calendarLayoutStore.changeIsActive(item.columnValue)
        }, [item.columnValue, props.calendarLayoutStore])

        return (
            <TouchableOpacity style={styles.itemGroup} onPress={changeIsActive}>
                <View style={styles.dot}></View>
                <Text style={styles.text}>{props.item.columnValue}</Text>
                {isActive && (
                    <Image
                        style={styles.iconChecked}
                        source={image.check}
                        resizeMode={'contain'}
                    ></Image>
                )}
            </TouchableOpacity>
        )
    }
)

export default ItemGroupCalendar

const createStyles = (
    { colorScheme }: Theme,
    item: GroupDisplay,
    isActive: boolean
) =>
    StyleSheet.create({
        dot: {
            height: 8,
            width: 8,
            borderRadius: 4,
            backgroundColor: isActive ? item.color : colorScheme.labelSecondary,
        },
        iconChecked: {
            tintColor: isActive ? item.color : colorScheme.labelSecondary,
            height: 20,
            width: 20,
            marginLeft: 4,
        },
        text: {
            fontWeight: '500',
            color: isActive ? item.color : colorScheme.labelSecondary,
            marginLeft: 8,
        },
        itemGroup: {
            flexDirection: 'row',
            backgroundColor: isActive
                ? hexToRGBA(item.color, 0.1)
                : colorScheme.backgroundL1,
            alignItems: 'center',
            marginRight: 10,
            paddingVertical: 6,
            paddingLeft: 10,
            paddingRight: 8,
            borderRadius: 28,
            borderWidth: 2,
            borderColor: isActive ? item.color : colorScheme.separator,
            height: 35,
        },
    })
