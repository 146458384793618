import { convertResponseUploadToUrl } from '../..'
import file, { IDataUpload } from '../../file'
import {
    saveFormUploadInfo,
    UploadInfo,
} from '../../form-upload-storage/FormUploadInfo'
import { FORM_URL } from '../../network/apis/FormUrl'
import { NetworkConstraint } from '../constraint/NetworkConstraint'
import { OperationConstraint } from '../constraint/OperationConstraint'
import { Operation, OperationRawData } from './Operation'

export type OtherData = {
    formOperationId: string
    targetColumnName: string
}

export type DataUploadOperation = {
    dataUpload: IDataUpload
    otherData: OtherData
}

export class UploadOperation implements Operation {
    id: string
    constraints: Array<OperationConstraint> = [new NetworkConstraint()]
    data: DataUploadOperation

    constructor(rawData: OperationRawData) {
        this.id = rawData.operationID
        this.data = rawData.data as unknown as DataUploadOperation
    }

    async run(): Promise<void> {
        try {
            const resultSuccess = await file.uploadWithResize({
                data: this.data.dataUpload,
                url: FORM_URL.uploadFile,
            })
            const url = convertResponseUploadToUrl(
                resultSuccess as Record<string, unknown>
            )
            const uploadInfo: UploadInfo = {
                targetColumnName: this.data.otherData.targetColumnName,
                assetUrl: url,
            }

            await saveFormUploadInfo(
                this.data.otherData.formOperationId,
                this.id,
                uploadInfo
            )
            console.info('Real success')
            Promise.resolve('Real success')
        } catch (error) {
            if (error === 400) {
                this.fileTypeNotSupported()
            } else {
                this.serverError()
            }
        }
    }

    toRawData(): OperationRawData {
        const uploadRawData = {
            operationID: this.id,
            data: this.data as unknown as Record<string, unknown>,
            type: 'uploadFile',
        } as OperationRawData

        return uploadRawData
    }

    private fileTypeNotSupported(): Promise<void> {
        return Promise.resolve()
    }

    private serverError(): Promise<void> {
        throw new Error('serverError')
    }
}
