//
//  SectionDetails.tsx
//
//  Created by thaitd96 on 2022-08-02 16:08.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React, { useEffect } from 'react'
import { FlatList, Image, StyleSheet, Text, View } from 'react-native'
import { Theme, useTheme } from '../../../../styles/theme'
import { Loading } from '../../../../components'
import { CardItemLargeOverlay } from '../card-item/CardItemLargeOverlay'
import { CardItemBlock } from '../card-item/CardItemBlock'
import { observer } from 'mobx-react-lite'
import { CollectionPageStore } from '@appformula/app-studio-core'
import { CardLayout } from '@appformula/app-descriptor'
import {
    ARNCollectionPageStore,
    QueryState,
} from '../../ARNCollectionPageStore'
import { useNavigation } from '@react-navigation/native'
import iconFilter from '../../../../assets/icons/icon-filter'

export const SectionDetails = observer(
    (props: {
        pageStore: CollectionPageStore
        arnCollectionPageStore?: ARNCollectionPageStore
    }) => {
        const theme = useTheme()
        const styles = React.useMemo(() => createStyles(theme), [theme])
        const { imageSize, image, contentPosition } = props.pageStore
            ?.activeLayout as CardLayout
        const navigation = useNavigation()

        const title = props.arnCollectionPageStore.currentCardSection
        const data = props.arnCollectionPageStore.listVirtualDataSection.find(
            (item) => item.title === title
        ).data

        useEffect(() => {
            navigation.setOptions({
                title: title,
            })
        }, [navigation, title])

        const renderItem = ({ item }: { item: number }) => {
            // Switch case to detect item type in here
            if (image && contentPosition === 'overlay') {
                return (
                    <CardItemLargeOverlay
                        item={item}
                        pageStore={props.pageStore}
                        arnCollectionPageStore={props.arnCollectionPageStore}
                        valueGrouping={title}
                    />
                )
            } else {
                return (
                    <CardItemBlock
                        item={item}
                        imageSize={imageSize}
                        image={image}
                        pageStore={props.pageStore}
                        arnCollectionPageStore={props.arnCollectionPageStore}
                        valueGrouping={title}
                    />
                )
            }
        }

        const keyExtractor = (item: number) => `${item}`

        return (
            <View style={styles.viewSectionDetails}>
                {props.arnCollectionPageStore?.isLoading ? (
                    <Loading />
                ) : (
                    <View style={styles.listCard}>
                        {data.length ? (
                            <FlatList
                                key={imageSize}
                                renderItem={renderItem}
                                // numColumns 2 work not perfect on data length 3, 5, 7, ...
                                // https://stackoverflow.com/questions/55369091/mobx-console-warning
                                data={data.slice()}
                                keyExtractor={keyExtractor}
                                numColumns={imageSize === 'large' ? 1 : 2}
                            />
                        ) : (
                            <View style={styles.doNotHaveData}>
                                {props.arnCollectionPageStore.queryState ===
                                QueryState.Filter ? (
                                    <>
                                        <Image
                                            source={iconFilter.noItemFilter}
                                            style={styles.imgNotFilter}
                                        />
                                        <Text style={styles.txtTitle}>
                                            No Items Matched
                                        </Text>
                                        <Text style={styles.txtContent}>
                                            There is no items that matched your
                                            filter conditions. Try changing the
                                            filters or search terms.
                                        </Text>
                                    </>
                                ) : props.arnCollectionPageStore.queryState ===
                                  QueryState.Search ? (
                                    <>
                                        <Image
                                            source={iconFilter.noItemSearch}
                                            style={styles.imgNotFilter}
                                        />
                                        <Text style={styles.txtTitle}>
                                            No Items Found
                                        </Text>
                                    </>
                                ) : (
                                    <>
                                        <Image
                                            source={iconFilter.noItemNormal}
                                            style={styles.imgNotFilter}
                                            resizeMode={'contain'}
                                        />
                                        <Text style={styles.txtTitle}>
                                            No Items to Show
                                        </Text>
                                    </>
                                )}
                            </View>
                        )}
                    </View>
                )}
            </View>
        )
    }
)

const createStyles = ({ colorScheme }: Theme) =>
    StyleSheet.create({
        textNoData: { color: colorScheme.labelPrimary },
        doNotHaveData: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            paddingHorizontal: 40,
        },
        listCard: {
            flex: 1,
            width: '100%',
            borderColor: colorScheme.separator,
            borderTopWidth: 1,
            paddingTop: 6,
        },
        viewSectionDetails: {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: colorScheme.groupedBackground,
        },
        imgNotFilter: {
            width: 38,
            height: 38,
            marginBottom: 18,
            tintColor: colorScheme.labelSecondary,
        },
        txtTitle: {
            fontSize: 20,
            fontWeight: '500',
            lineHeight: 24.2,
            color: colorScheme.labelSecondary,
            marginBottom: 6,
        },
        txtContent: {
            fontSize: 15,
            fontWeight: '400',
            lineHeight: 22.5,
            textAlign: 'center',
            color: colorScheme.labelSecondary,
        },
    })
