//
//  ProgressBar.tsx
//
//  Created by hienbv on 2022-20-04 11:56.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { ProgressBarStore } from '@appformula/app-studio-core'
import appConstants from '../../../constants/const'
import { observer } from 'mobx-react-lite'
import React, { useMemo } from 'react'
import { StyleSheet, View } from 'react-native'
import * as Progress from 'react-native-progress'
import { Theme, useTheme } from '../../../styles/theme'
import { hexToRGBA } from '../../../utils'
import { useBindingCommon } from '../../collection/components/hooks/useBindingCommon'

export const ProgressBar = observer(
    (props: {
        progressBar: ProgressBarStore
        item: Record<string, unknown>
    }) => {
        const { progressBar, item } = props
        const progressBarData = progressBar.object
        const progress =
            (useBindingCommon(
                progressBarData?.progress,
                item
            ) as number) || 0
        const isHidden = useBindingCommon(
            progressBarData.isHidden,
            props.item
        )
        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])

        return (
            !isHidden && (
                <View style={styles.viewProgressBar}>
                    <Progress.Bar
                        progress={progress / 100}
                        width={null}
                        height={9}
                        color={theme.colorScheme.accentColor as string}
                        borderWidth={0}
                        unfilledColor={hexToRGBA(
                            theme.colorScheme.accentColor as string,
                            0.1
                        )}
                        borderRadius={theme.roundness}
                    />
                </View>
            )
        )
    }
)

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        viewProgressBar: {
            marginVertical: appConstants.PADDING_COMMON,
        },
    })
