import { CompoundedData, CompoundedOperation } from '../CompoundedOperation'
import { Operation, OperationRawData } from '../Operation'
import { SaveDataToStorageOperation } from '../SaveDataToStorageOperation'
import { UploadOperation } from '../UploadOperation'
import { OperationBuilder } from './OperationBuilder'

export class CompoundedOperationBuilder implements OperationBuilder {
    operationFromRawData(rawData: OperationRawData): CompoundedOperation {
        const operations = this.childOperationsFromRawData(rawData)
        return new CompoundedOperation(
            rawData.operationID,
            operations,
            (rawData.data as CompoundedData).mode
        )
    }

    private childOperationsFromRawData(rawData: OperationRawData): Operation[] {
        const operations: Operation[] = []
        const listChildRawData = Object.values(
            ((rawData as unknown as OperationRawData).data as CompoundedData)
                .data
        ) as OperationRawData[]

        listChildRawData.forEach((childRawData): void => {
            switch (childRawData.type) {
                case 'compounded':
                    operations.push(this.operationFromRawData(childRawData))
                    break
                case 'saveDataToStorage':
                    operations.push(
                        new SaveDataToStorageOperation(childRawData)
                    )
                    break
                case 'uploadFile':
                    operations.push(new UploadOperation(childRawData))
                    break
                default:
                    break
            }
        })

        return operations
    }
}
