//
//  FormPageRepository.ts
//
//  Created by thaitd96 on 2022-08-10 14:24
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import {
    BarcodeScannerFormInput,
    BooleanFormInput,
    DateTimeFormInput,
    FilesFormInput,
    FormInput,
    FormInputType,
    FormPage,
    LocationFormInput,
    LookupFormInput,
    MultiLineTextFormInput,
    NumberFormInput,
    OptionsFormInput,
    PeopleLookupFormInput,
    PhotosFormInput,
    SignatureDrawingFormInput,
    SingleLineTextFormInput,
} from '@appformula/app-descriptor'
import {
    FormInputRepository,
    FormPageRepository,
} from '@appformula/app-studio-core'
import { of } from 'rxjs'
import { LocalBasePageRepository } from '../BaseRepository'
import { LocalFormInputRepository } from './FormInputRepository'
import { LocalBarcodeScannerFormInputRepository } from './inputs/BarcodeScanner'
import { LocalFilesFormInputRepository } from './inputs/Files'
import { LocalPhotosFormInputRepository } from './inputs/Photos'

export class LocalFormPageRepository
    extends LocalBasePageRepository<FormPage>
    implements FormPageRepository
{
    componentRepository(
        componentId: string,
        componentType: FormInputType
    ): FormInputRepository {
        const component = this.objectSnapshot?.components?.[componentId]

        switch (componentType) {
            case 'options':
                return new LocalFormInputRepository<OptionsFormInput>(
                    of(component as unknown as OptionsFormInput),
                    component as unknown as OptionsFormInput,
                    componentId
                )
            case 'number':
                return new LocalFormInputRepository<NumberFormInput>(
                    of(component as unknown as NumberFormInput),
                    component as unknown as NumberFormInput,
                    componentId
                )
            case 'boolean':
                return new LocalFormInputRepository<BooleanFormInput>(
                    of(component as unknown as BooleanFormInput),
                    component as unknown as BooleanFormInput,
                    componentId
                )
            case 'dateTime':
                return new LocalFormInputRepository<DateTimeFormInput>(
                    of(component as unknown as DateTimeFormInput),
                    component as unknown as DateTimeFormInput,
                    componentId
                )
            case 'location':
                return new LocalFormInputRepository<LocationFormInput>(
                    of(component as unknown as LocationFormInput),
                    component as unknown as LocationFormInput,
                    componentId
                )
            case 'lookup':
                return new LocalFormInputRepository<LookupFormInput>(
                    of(component as unknown as LookupFormInput),
                    component as unknown as LookupFormInput,
                    componentId
                )
            case 'multiLineText':
                return new LocalFormInputRepository<MultiLineTextFormInput>(
                    of(component as unknown as MultiLineTextFormInput),
                    component as unknown as MultiLineTextFormInput,
                    componentId
                )
            case 'singleLineText':
                return new LocalFormInputRepository<SingleLineTextFormInput>(
                    of(component as unknown as SingleLineTextFormInput),
                    component as unknown as SingleLineTextFormInput,
                    componentId
                )
            case 'peopleLookup':
                return new LocalFormInputRepository<PeopleLookupFormInput>(
                    of(component as unknown as PeopleLookupFormInput),
                    component as unknown as PeopleLookupFormInput,
                    componentId
                )
            case 'barcodeScanner':
                return new LocalBarcodeScannerFormInputRepository(
                    of(component as unknown as BarcodeScannerFormInput),
                    component as unknown as BarcodeScannerFormInput,
                    componentId
                )
            case 'files':
                return new LocalFilesFormInputRepository(
                    of(component as unknown as FilesFormInput),
                    component as unknown as FilesFormInput,
                    componentId
                )
            case 'photos':
                return new LocalPhotosFormInputRepository(
                    of(component as unknown as PhotosFormInput),
                    component as unknown as PhotosFormInput,
                    componentId
                )
            case 'signatureDrawing':
                return new LocalFormInputRepository<SignatureDrawingFormInput>(
                    of(component as unknown as SignatureDrawingFormInput),
                    component as unknown as SignatureDrawingFormInput,
                    componentId
                )
            default:
                throw Error('Unsupported form input type')
        }
    }
    insertComponent(
        componentId: string,
        component: FormInput,
        position: string
    ): Promise<void> {
        throw new Error('Method not implemented.')
    }
    setComponentPosition(componentId: string, position: string): Promise<void> {
        throw new Error('Method not implemented.')
    }
    removeComponent(componentId: string): Promise<void> {
        throw new Error('Method not implemented.')
    }
}
