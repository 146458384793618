import {
    Text,
    StyleSheet,
    Pressable,
    TextInput,
    SafeAreaView,
    Image,
} from 'react-native'
import React, { useMemo } from 'react'
import { WelcomePageStore } from './WelcomePageStore'
import { Theme, useTheme } from '../../styles/theme'
import image from '../../assets/icons'
import { useNavigation } from '@react-navigation/native'
import { ScrollView } from 'react-native-gesture-handler'
import AppButton from '../../components/AppButton'
import { observer } from 'mobx-react-lite'
import cache from '../../utils/cache'
import { Alert } from '../../components/alert'
import screenNames from '../../navigations/screenNames'
import { AppAuthenticationContext } from '../../context/AppAuthenticationContext'

const SignUpScreen = observer((props: { store: WelcomePageStore }) => {
    const theme = useTheme()
    const styles = useMemo(() => createStyles(theme), [theme])
    const navigation = useNavigation()
    const appStore = React.useContext(AppAuthenticationContext)

    const { store } = props

    const onBack = React.useCallback(() => {
        navigation.goBack()
    }, [navigation])

    const [email, setEmail] = React.useState<string>('')
    const [password, setPassword] = React.useState<string>('')
    const [confirmPassword, setConfirmPassword] = React.useState<string>('')

    const handleSetEmail = React.useCallback((value: string) => {
        setEmail(value)
    }, [])

    const handleSetPassword = React.useCallback((value: string) => {
        setPassword(value)
    }, [])

    const handleSetConfirmPassword = React.useCallback((value: string) => {
        setConfirmPassword(value)
    }, [])

    const signUp = React.useCallback(async () => {
        if (cache.sourceStartUp === 'APP_NORMAL') {
            if (
                email.trim() &&
                password.trim() &&
                password === confirmPassword
            ) {
                appStore?.setIsCustomizedProfile(false)
                await store.signUp(email.trim(), password.trim()).then(() => {
                    if (
                        store.getAuthProviderConfig?.email?.requireVerification
                    ) {
                        navigation.navigate(screenNames.VERIFICATION as never)
                    } else {
                        navigation.navigate(
                            screenNames.CUSTOMIZE_PROFILE as never
                        )
                    }
                })
            }
        } else {
            Alert.alert(
                'Notification',
                `You can't use this feature in Web Editor!`
            )
        }
    }, [appStore, confirmPassword, email, navigation, password, store])

    return (
        <SafeAreaView style={styles.container}>
            <Pressable style={styles.containerHeaderLeft} onPress={onBack}>
                <Image
                    style={styles.backImg}
                    source={image.back}
                    resizeMode={'contain'}
                />
                <Text style={styles.txtHeaderLeft}>Login</Text>
            </Pressable>
            <ScrollView contentContainerStyle={styles.containerScroll}>
                <Text style={styles.txtTitle}>Sign Up</Text>
                <Text style={styles.txtSubTitle}>
                    {`Login with your ${store.appName} employee account app to continue with ${store.appName}.`}
                </Text>
                <TextInput
                    style={styles.input}
                    onChangeText={handleSetEmail}
                    value={email}
                    placeholder={'name@work-email.com'}
                    autoCapitalize="none"
                    placeholderTextColor={theme.colorScheme.labelSecondary}
                />
                <TextInput
                    style={styles.input}
                    onChangeText={handleSetPassword}
                    value={password}
                    placeholder="Password"
                    secureTextEntry={true}
                    placeholderTextColor={theme.colorScheme.labelSecondary}
                />
                <TextInput
                    style={[
                        styles.input,
                        confirmPassword.length > 0 &&
                            confirmPassword !== password &&
                            styles.wrongConfirm,
                    ]}
                    onChangeText={handleSetConfirmPassword}
                    value={confirmPassword}
                    placeholder="Confirm password"
                    secureTextEntry={true}
                    placeholderTextColor={theme.colorScheme.labelSecondary}
                />
                <AppButton
                    title="Sign Up"
                    onPress={signUp}
                    loading={store.loadingState}
                />
                <Text style={styles.txtPrivacy}>
                    {`By tapping Sign Up button, you’ve agree with ${store.appName} app `}
                    <Text style={styles.txtDetailPrivacy}>Privacy Policy</Text>{' '}
                    & <Text style={styles.txtDetailPrivacy}>Terms of Use</Text>
                </Text>
            </ScrollView>
        </SafeAreaView>
    )
})

export default SignUpScreen

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        backImg: {
            width: 28,
            height: 28,
            tintColor: colorScheme.accentColor,
            marginLeft: 8,
        },
        containerHeaderLeft: { flexDirection: 'row', alignItems: 'center' },
        txtHeaderLeft: {
            color: colorScheme.accentColor,
            fontWeight: '500',
            fontSize: 17,
            lineHeight: 21,
        },
        container: {
            flex: 1,
            backgroundColor: colorScheme.backgroundL1,
            paddingTop: 20,
        },
        containerScroll: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            paddingHorizontal: 24,
        },
        txtTitle: {
            fontWeight: '700',
            fontSize: 30,
            lineHeight: 36.31,
            color: colorScheme.labelPrimary,
            marginBottom: 9,
        },
        txtSubTitle: {
            fontWeight: '400',
            fontSize: 15,
            lineHeight: 22.5,
            color: colorScheme.labelSecondary,
            textAlign: 'center',
            marginBottom: 20,
        },
        input: {
            width: '100%',
            height: 50,
            marginBottom: 12,
            borderWidth: 1,
            padding: 10,
            borderRadius: roundness,
            borderColor: colorScheme.separator,
            color: colorScheme.labelPrimary,
        },
        wrongConfirm: {
            borderColor: '#EB1260',
        },
        txtPrivacy: {
            textAlign: 'center',
            color: colorScheme.labelSecondary,
            marginTop: 12,
            fontSize: 13,
            lineHeight: 19.5,
            fontWeight: '400',
        },
        txtDetailPrivacy: {
            color: colorScheme.accentColor,
            fontSize: 13,
            lineHeight: 19.5,
            fontWeight: '400',
            textDecorationLine: 'underline',
        },
    })
