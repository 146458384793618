import { FontResource } from 'expo-font'
import { FontFamily, FontWeight } from '../../styles/typography'

interface FontDescriptor {
    resource: FontResource
    name: string
    italic: boolean
}

export class InterFontFamily implements FontFamily {
    getFontNameOfWeight(weight: FontWeight): string | undefined {
        return this.getFontName({ weight: weight, italic: false })
    }

    getFontName(options: { weight: FontWeight; italic: boolean }): string | undefined {
        return InterFontFamily.getFontDescriptor(options).name
    }

    private static getFontDescriptor(options: { weight: FontWeight; italic: boolean }): FontDescriptor {
        switch (options.weight) {
            default:
                return null
        }
    }

    static availableFonts(): Record<string, FontResource> {
        const availableFontWeights: FontWeight[] = [
            FontWeight.Regular,
            FontWeight.Medium,
            FontWeight.SemiBold,
            FontWeight.Bold,
        ]
        const fontMap: Record<string, FontResource> = {}
        availableFontWeights.forEach((fontWeight) => {
            const normalDescriptor = this.getFontDescriptor({ weight: fontWeight, italic: false })
            const italicDescriptor = this.getFontDescriptor({ weight: fontWeight, italic: true })
            fontMap[normalDescriptor.name] = normalDescriptor.resource
            fontMap[italicDescriptor.name] = italicDescriptor.resource
        })
        return fontMap
    }
}
