import { action, makeObservable, observable } from 'mobx'
import {
    CollectionFilterStore,
    FilterData,
    TableSchemaType,
} from './components/filter/CollectionFilterStore'
import { CollectionSortStore } from './components/SortScreen/CollectionSortStore'
import appConstants from '../../constants/const'
import { Alert } from '../../components/alert'
import { Nullable } from '@appformula/shared-foundation-x/src'
import { CollectionPageStore } from '@appformula/app-studio-core/src'
import { SearchCollectionStore } from './components/SearchCollectionStore'
import { MainPageDataStore } from '../main/MainPageStore'
import {
    BehaviorSubject,
    debounceTime,
    merge,
    Observable,
    skip,
    Subject,
    Subscription,
} from 'rxjs'
import {
    CalendarLayoutStore,
    CalendarMode,
} from './components/calendar-layout/CalendarLayoutStore'
import dayjs from 'dayjs'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import _ from 'lodash'
import { asyncFilter } from '../../utils'
import { FormulaManager } from '../../utils/integrate-formula/FormulaManager'
import {
    Binding,
    CalendarLayout as CalendarLayoutDescriptor,
    ContextualMappingBinding,
    ItemAction,
    ListLayout,
    Table,
} from '@appformula/app-descriptor/src'
import { TransformBinding } from '../../utils/transform-binding/TransformBinding'
import { MapLayoutStore } from './components/map-layout/MapLayoutStore'
import { DocumentData, DocumentSnapshot } from '../../database/type'
import getFireStore from '../../utils/firebase/firestore/getFireStore'
import { IDatabaseContext } from '../../context/AppDatabaseContext'
import { TransformConditionToSqlWDB } from '../../database/helper/TransformConditionToSqlWDB'
import { Q } from '@nozbe/watermelondb'
import {
    getData,
    removeItem,
    storeData,
} from '../../utils/persistent-storage/AsyncStorage'
import { TransformGroupsPreFilter } from '../../utils/database/TransformGroupsPreFilter'
import { FirebaseAuthTypes } from '@react-native-firebase/auth'
import firebase from 'firebase'

dayjs.extend(weekOfYear)

export type ListVirtualDataSection = { data: number[]; title: unknown }[]

export type ListDataCalendarWithSection = {
    data: Record<string, unknown>[]
    title: unknown
}[]

export type CalendarSection = {
    [key in CalendarMode]?: ListDataCalendarWithSection
}

export type ListDataCalendar = Record<string, unknown>[]

export type ListDataCalendarGroup = {
    title: string
    data: Record<string, unknown>[]
}[]

export interface IItemDataMap {
    index: number
    type?: unknown
}

export type SortData = Record<
    'collectionPageIdValue' | 'columnName' | 'order',
    string
>

export type PageDataStored = {
    filter?: Record<string, FilterData>
    sort?: SortData
}

export const ignoredColumns = ['key', '__AppFormulaID']

export enum QueryState {
    Normal = 'Normal',
    Filter = 'Filter',
    Search = 'Search',
}

export class ARNCollectionPageStore {
    readonly collectionPageId: string
    listVirtualDataWithoutSection: number[] = []
    listVirtualDataSection: ListVirtualDataSection = []

    listCalendar: ListDataCalendar = []
    listCalendarSection: CalendarSection = {}

    dataMap: IItemDataMap[] = []
    private listCalendarBeS = new BehaviorSubject(this.listCalendar)
    private listCalendarSectionBeS = new BehaviorSubject(
        this.listCalendarSection
    )

    private cachedCollectionFilterStore: CollectionFilterStore
    private cachedCollectionSortStore: CollectionSortStore
    private cachedCollectionCalendarStore: CalendarLayoutStore
    private cachedCollectionMapStore: MapLayoutStore

    pageDataStored: PageDataStored = {}

    isLoading = false
    pageStore: Nullable<CollectionPageStore> = undefined
    searchStore: Nullable<SearchCollectionStore> = undefined
    mainPageDataStore: MainPageDataStore
    currentOffset: number = 0
    totalRows: number = 0
    currentCardSection: string | number
    queryString: Q.Clause[] = []
    searchEvent: Observable<void>
    getAndSetDataEvent: Observable<void>
    pageStoreChange: Subject<void>
    getDataCalendarMonthEvent: Subject<void>
    collectionScreenDidMount: Subject<void>
    formulaManager: FormulaManager
    intervalLoadData: ReturnType<typeof setInterval>
    databaseAction: IDatabaseContext
    tableDataType: Nullable<Record<string, TableSchemaType>> = undefined
    queryState: QueryState = QueryState.Normal
    currentUser?: FirebaseAuthTypes.User | firebase.User

    setQueryString(queryString: Q.Clause[]) {
        this.queryString = queryString
    }

    setCurrentCardSection(currentCardSection: string | number) {
        this.currentCardSection = currentCardSection
    }

    constructor(
        mainPageDataStore: MainPageDataStore,
        collectionPageId: string,
        databaseAction: IDatabaseContext,
        currentUser?: FirebaseAuthTypes.User | firebase.User
    ) {
        this.collectionPageId = collectionPageId
        this.mainPageDataStore = mainPageDataStore
        this.currentCardSection = ''
        this.getAndSetDataEvent = new Observable()
        this.pageStoreChange = new Subject()
        this.getDataCalendarMonthEvent = new Subject()
        this.collectionScreenDidMount = new Subject()
        this.databaseAction = databaseAction
        this.currentUser = currentUser

        makeObservable(this, {
            listVirtualDataWithoutSection: observable,
            listVirtualDataSection: observable,

            listCalendar: observable,
            listCalendarSection: observable,
            dataMap: observable,

            isLoading: observable,
            pageStore: observable,
            searchStore: observable,
            currentOffset: observable,
            totalRows: observable,
            currentCardSection: observable,
            setListVirtualDataWithoutSection: action,
            setListVirtualDataSection: action,
            getAndSetData: action,
            showLoading: action,
            hideLoading: action,
            setPageStore: action,
            getSearchStore: action,
            setCurrentOffset: action,
            clearCollectionSortStore: action,
            setCurrentCardSection: action,
            setListCalendar: action,
            setListCalendarSection: action,
            setDataMap: action,
            getDataCalendarWithFilter: action,
            resetListCalendarSection: action,

            tableDataType: observable,
            setTableDataType: action,
            getTableDataType: action,
            columnData: action,

            pageDataStored: observable,
            setPageDataStored: action,
            getPageDataStored: action,
            getPreFilterQuery: action,
            getPreSortQuery: action,

            queryState: observable,
            setQueryState: action,
        })

        const pubsubSearchSignal = new Observable<void>((subscriber) => {
            const token = PubSub.subscribe(
                appConstants.SET_DATA_SORT_FILTER,
                () => {
                    console.info('pubsubSearchSignal')
                    subscriber.next()
                }
            )
            return () => {
                PubSub.unsubscribe(token)
            }
        })

        const debouncePubsubSearchSignal = pubsubSearchSignal.pipe(
            debounceTime(300)
        )

        const pubsubRefreshDoneSignal = new Observable<void>((subscriber) => {
            const token = PubSub.subscribe(
                appConstants.REFRESH_SCHEMA_DONE,
                () => {
                    // console.info('pubsubRefreshDoneSignal')
                    subscriber.next()
                }
            )
            return () => {
                PubSub.unsubscribe(token)
            }
        })

        const pubsubSyncDataDoneSignal = new Observable<void>((subscriber) => {
            const token = PubSub.subscribe(
                appConstants.SYNC_DATA_DONE,
                (_, data) => {
                    if (
                        data.tableName === this.pageStore?.dataSource?.id ??
                        ''
                    ) {
                        // console.info('pubsubSyncDataDoneSignal', data)
                        subscriber.next()
                    }
                }
            )
            return () => {
                PubSub.unsubscribe(token)
            }
        })

        this.getAndSetDataEvent = merge(
            debouncePubsubSearchSignal,
            pubsubRefreshDoneSignal,
            pubsubSyncDataDoneSignal,
            this.pageStoreChange,
            this.collectionScreenDidMount
        )
    }

    startListeningDataChange(): Subscription[] {
        const mergeSubCalendar = merge(
            this.cachedCollectionCalendarStore.monthYearBeS,
            this.cachedCollectionCalendarStore.weekPickedBeS
        )
            .pipe(debounceTime(300), skip(1))
            .subscribe(() => {
                // Push to Event Queue to prevent drop Fps while animating
                setTimeout(() => {
                    this.getDataCalendarWithFilter()
                }, 0)
            })

        this.listCalendarSectionBeS.subscribe(() => {
            // Push to Event Queue to prevent drop Fps while animating
            setTimeout(() => {
                this.getDataCalendarWithFilter()
            }, 0)
        })

        this.cachedCollectionCalendarStore.calendarModeBeS
            .pipe(skip(1))
            .subscribe({
                next: (newValue) => {
                    setTimeout(() => {
                        if (this.listCalendarSection?.[newValue]) {
                            this.getDataCalendarWithFilter()
                        } else {
                            this.getDataCalendarLayout(this.formulaManager)
                        }
                    }, 0)
                },
            })

        this.cachedCollectionCalendarStore.activeSectionsBeS
            .pipe(debounceTime(300), skip(1))
            .subscribe({
                next: (newValue) => {
                    console.info('CHANGE ACTIVE SECTION')

                    this.getAndSetCalendarRaw(this.formulaManager)
                },
            })

        return [mergeSubCalendar]
    }

    setTableDataType(tableDataType: Record<string, TableSchemaType>) {
        this.tableDataType = tableDataType
    }

    async getTableDataType() {
        const unsubscribes: (() => void)[] = []
        try {
            if (this.pageStore?.dataSource?.id) {
                const unsubscribe = (await getFireStore())
                    // @ts-ignore
                    .collection(`teams/${global.teamId}/tables`)
                    .doc(this.pageStore?.dataSource?.id)
                    .onSnapshot({
                        next: (querySnapshot) => {
                            const tableData = querySnapshot.data() as Table
                            const fieldType = tableData?.schema?.fieldType
                            if (fieldType) {
                                this.setTableDataType(fieldType)
                            } else {
                                console.warn(
                                    `Trying to access fieldType of table schema but havent found. This may lead to an error. Data: ${JSON.stringify(
                                        tableData
                                    )}`
                                )
                            }
                        },
                    })

                unsubscribes.push(unsubscribe)
            }
        } catch (error) {
            console.warn('getTableDataType error---', error)
        }

        return unsubscribes
    }

    columnData(dataType: string) {
        const item = Object.entries(this.tableDataType ?? {}).find(
            (itemType) => itemType[0] === dataType
        )
        if (item?.length === 2) {
            return item[1]
        }

        return undefined
    }

    setQueryState(queryState: QueryState) {
        this.queryState = queryState
    }

    showLoading() {
        // Intermediate states will not become visible to observers.
        this.isLoading = true
    }

    hideLoading() {
        this.isLoading = false
    }

    setPageStore(pageStore: CollectionPageStore) {
        this.pageStore = pageStore
    }

    clearCollectionFilterStore = () => {
        this.cachedCollectionFilterStore.clearChildComponentStores()
    }

    clearCollectionSortStore = () => {
        this.cachedCollectionSortStore = null
    }

    setListVirtualDataWithoutSection(listVirtualDataWithoutSection: number[]) {
        this.listVirtualDataWithoutSection = listVirtualDataWithoutSection
    }

    setListVirtualDataSection(listVirtualDataSection: ListVirtualDataSection) {
        this.listVirtualDataSection = listVirtualDataSection
    }

    setCurrentOffset(offset: number) {
        this.currentOffset = offset
    }

    setListCalendar = (listCalendar: ListDataCalendar) => {
        this.listCalendar = listCalendar
        this.listCalendarBeS.next(listCalendar)
    }

    setListCalendarSection = (
        listCalendarSection: ListDataCalendarWithSection,
        mode: CalendarMode
    ) => {
        this.listCalendarSection[mode] = listCalendarSection
        this.listCalendarSectionBeS.next(this.listCalendarSection)
    }

    resetListCalendarSection = () => {
        this.listCalendarSection = {}
        this.listCalendarSectionBeS.next({})
    }

    setDataMap = (data: IItemDataMap[]) => {
        this.dataMap = data
    }

    async getPreFilterQuery(
        formulaManager?: FormulaManager
    ): Promise<Nullable<Q.Clause>> {
        try {
            return TransformGroupsPreFilter(
                this.pageStore?.preFilter,
                formulaManager,
                this.currentUser as unknown as Record<string, unknown>
            )
        } catch (error) {
            console.warn('getPreFilterQuery error-----', error)
            return undefined
        }
    }

    getPreSortQuery() {
        const preSort = this.pageStore?.sortColumns ?? []

        const preSortQuery: Q.Clause[] = []
        preSort.forEach((sort) => {
            switch (sort.order) {
                case 'ascending': {
                    preSortQuery.push(Q.sortBy(sort.columnName, Q.asc))
                    break
                }
                case 'descending': {
                    preSortQuery.push(Q.sortBy(sort.columnName, Q.desc))
                    break
                }
            }
        })

        console.info({ preSortQuery })

        return preSortQuery
    }

    async getQueryFilter() {
        const oldPageDataStored = await getData(this.collectionPageId)
        if (this.cachedCollectionFilterStore) {
            const conditions: Q.Clause[] = []
            this.cachedCollectionFilterStore
                .allChildComponentStores()
                .forEach((store) => {
                    const condition = TransformConditionToSqlWDB(store)
                    condition && conditions.push(condition)
                })
            return conditions
        } else if (oldPageDataStored?.filter) {
            const listFilterData: FilterData[] = Object.values(
                oldPageDataStored.filter ?? {}
            )

            const oldCollectionFilterStore = new CollectionFilterStore(
                this.collectionPageId,
                this.pageStore?.dataSource?.id ?? '',
                listFilterData
            )

            const conditions: Q.Clause[] = []

            oldCollectionFilterStore.filterData.forEach((data) => {
                const newChildComponentStore =
                    oldCollectionFilterStore.childComponentStore(data)
                if (newChildComponentStore) {
                    const condition = TransformConditionToSqlWDB(
                        newChildComponentStore
                    )
                    condition && conditions.push(condition)
                }
            })
            return conditions
        } else {
            return []
        }
    }

    getQuerySearch(): Q.Clause[] {
        const querySearch: Q.Where[] = []
        // SEARCH
        const searchBarStoreFromFS = this.pageStore?.searchBarStore()
        const valueSearch = this.searchStore?.valueSearchString?.trim()

        const columnsSearch = [...(searchBarStoreFromFS?.matchingColumns || [])]

        if (searchBarStoreFromFS?.barcodeSearchColumn) {
            columnsSearch.push(searchBarStoreFromFS.barcodeSearchColumn)
        }
        if (valueSearch && columnsSearch.length > 0) {
            columnsSearch.forEach((item) => {
                querySearch.push(
                    Q.where(
                        `${item}`,
                        Q.like(`%${Q.sanitizeLikeString(valueSearch)}%`)
                    )
                )
            })
            return [Q.or(...querySearch)]
        }
        return []
    }

    async getQueryOrder() {
        const queryOrder: Q.Clause[] = []
        const oldPageDataStored = await getData(this.collectionPageId)
        if (this.cachedCollectionSortStore) {
            const sortColumn = this.cachedCollectionSortStore.columnName
            const orderStore = this.cachedCollectionSortStore.order

            if (sortColumn && orderStore) {
                const query =
                    orderStore.toUpperCase() === appConstants.ORDER.ASCENDING
                        ? Q.sortBy(sortColumn, Q.asc)
                        : Q.sortBy(sortColumn, Q.desc)
                queryOrder.push(query)
            }
        } else if (oldPageDataStored?.sort) {
            const sortColumn = oldPageDataStored?.sort.columnName
            const orderStore = oldPageDataStored?.sort.order

            if (sortColumn && orderStore) {
                const query =
                    orderStore.toUpperCase() === appConstants.ORDER.ASCENDING
                        ? Q.sortBy(sortColumn, Q.asc)
                        : Q.sortBy(sortColumn, Q.desc)
                queryOrder.push(query)
            }
        } else {
            const sortColumn: string = (
                (this.pageStore?.activeLayout as ListLayout)
                    ?.itemTitle as ContextualMappingBinding
            )?.mappingPropName

            const orderStore: string = appConstants.ORDER.ASCENDING

            if (sortColumn && orderStore) {
                const query =
                    orderStore.toUpperCase() === appConstants.ORDER.ASCENDING
                        ? Q.sortBy(sortColumn, Q.asc)
                        : Q.sortBy(sortColumn, Q.desc)
                queryOrder.push(query)
            }
        }

        return queryOrder
    }

    async saveQuerySortFilter() {
        const sort = this.cachedCollectionSortStore ?? this.pageDataStored.sort

        const data: PageDataStored = {}

        data.sort = sort as unknown as SortData

        data.filter =
            this.cachedCollectionFilterStore?.allChildComponentStores()
                ? Object.fromEntries(
                      this.cachedCollectionFilterStore?.allChildComponentStores()
                  )
                : this.pageDataStored.filter
        console.info(data)
        await storeData(this.collectionPageId, data)
    }

    async removeQuerySortFilter() {
        const sort = this.pageDataStored.sort
        if (sort && Object.keys(sort).length !== 0) {
            await storeData(this.collectionPageId, sort)
        } else {
            await removeItem(this.collectionPageId)
        }
    }

    async removeQuerySort() {
        const filter = this.pageDataStored.filter
        if (filter && Object.keys(filter).length !== 0) {
            await storeData(this.collectionPageId, filter)
        } else {
            await removeItem(this.collectionPageId)
        }
    }

    setPageDataStored(pageDataStored: PageDataStored) {
        this.pageDataStored = pageDataStored
    }

    async getPageDataStored() {
        if (this.collectionPageId) {
            getData(this.collectionPageId).then((storedData) => {
                if (storedData) {
                    this.setPageDataStored(storedData)
                }
            })
        }
    }

    async getQuerySortFilter() {
        // FILTER
        const queryFilter = await this.getQueryFilter()

        // SEARCH
        const querySearch = this.getQuerySearch()

        //SORT
        const queryOrder = await this.getQueryOrder()

        if (queryFilter.length > 0) {
            this.setQueryState(QueryState.Filter)
        } else if (querySearch.length > 0) {
            this.setQueryState(QueryState.Search)
        }

        const queryString = await this.mapSearchFilterAndSort(
            querySearch,
            queryFilter,
            queryOrder
        )
        this.setQueryString(queryString)
        return queryString
    }

    async mapSearchFilterAndSort(
        querySearch: Q.Clause[],
        queryFilter: Q.Clause[],
        queryOrder: Q.Clause[]
    ) {
        const preFilter = await this.getPreFilterQuery(this.formulaManager)
        const preSort = this.getPreSortQuery()
        if (preFilter) {
            return [
                preFilter,
                ...preSort,
                ...querySearch,
                ...queryFilter,
                ...queryOrder,
            ]
        }
        return [...preSort, ...querySearch, ...queryFilter, ...queryOrder]
    }

    async getDataCardListLayout() {
        const groupingColumn = this.pageStore?.activeLayout.groupingColumn
        const queryStringSortFilter = await this.getQuerySortFilter()
        if (groupingColumn) {
            try {
                const listVirtualDataSection =
                    await this.databaseAction.getAllVirtualListSectionDataWithQueryString(
                        this.pageStore?.dataSource?.id ?? '',
                        queryStringSortFilter,
                        groupingColumn
                    )
                this.setListVirtualDataSection(listVirtualDataSection)
            } catch (error) {
                console.warn(error)
            }
        } else {
            try {
                const result =
                    await this.databaseAction.getAllVirtualSqlDataWithQueryString(
                        this.pageStore?.dataSource?.id ?? '',
                        queryStringSortFilter
                    )
                this.setListVirtualDataWithoutSection(result)
            } catch (error) {
                console.warn(error)
            }
        }
    }

    async getStartEndTime(
        formulaManager: FormulaManager,
        item: Record<string, unknown>
    ): Promise<any> {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            try {
                const startDateBinding = (
                    this.pageStore?.activeLayout as CalendarLayoutDescriptor
                ).startDate
                const endDateBinding = (
                    this.pageStore?.activeLayout as CalendarLayoutDescriptor
                ).endDate

                Promise.all([
                    TransformBinding(startDateBinding, formulaManager, item),
                    TransformBinding(endDateBinding, formulaManager, item),
                ]).then(([startDate, endDate]) => {
                    const startDayJs = dayjs(startDate as string)
                    const endDayJs = dayjs(endDate as string)
                    const result = {
                        ...item,
                        startDate: startDayJs.toDate(),
                        endDate: endDayJs.toDate(),
                        dateGroup: startDayJs.format('YYYY/MM/DD'),
                        startWeek: startDayJs.startOf('isoWeek').toString(),
                        month: startDayJs.startOf('month'),
                    }
                    resolve(result)
                })
            } catch (error) {
                reject(error)
            }
        })
    }

    async getDataCalendarLayout(formulaManager: FormulaManager) {
        try {
            await formulaManager.registContextVariableWithTableId(
                appConstants.CURRENT_ROW,
                this.pageStore?.dataSource?.id ?? ''
            )

            const windowsSize = 1000
            const totalSize = this.listCalendar.length
            let totalLoaded = this.getTotalDataLoadedByMode()
            const breath = (): Promise<void> =>
                new Promise((resolve) => {
                    setTimeout(resolve, 100)
                })

            do {
                const listPromise = this.listCalendar
                    .slice(totalLoaded, totalLoaded + windowsSize)
                    .map(async (item, index) =>
                        this.getStartEndTime(formulaManager, item)
                    )
                totalLoaded += windowsSize
                this.setTotalDataLoadedByMode(totalLoaded)

                Promise.all(listPromise)
                    .then((data) => {
                        this.groupAndGetDataCalendarByMode(data)
                    })
                    .catch((error) => {
                        console.info('error----', error)
                    })
                await breath()
            } while (totalLoaded < totalSize)

            // const _runInterval = () => {
            //     if (totalLoaded >= totalSize) {
            //         clearInterval(this.intervalLoadData)
            //         return
            //     }

            //     const listPromise = this.listCalendar
            //         .slice(totalLoaded, totalLoaded + windowsSize)
            //         .map(async (item, index) =>
            //             this.getStartEndTime(formulaManager, item)
            //         )

            //     totalLoaded += windowsSize
            //     this.setTotalDataLoadedByMode(totalLoaded)

            //     Promise.all(listPromise)
            //         .then((data) => {
            //             this.groupAndGetDataCalendarByMode(data)
            //         })
            //         .catch((error) => {
            //             console.info('error----', error)
            //         })
            // }

            // this.intervalLoadData = setInterval(_runInterval, 100)
        } catch (error) {
            console.info('getDataCalendarLayout error----', error)
        }
    }

    async getAndSetCalendarRaw(formulaManager: FormulaManager) {
        try {
            const queryStringSortFilter = await this.getQuerySortFilter()
            const queryFilterActiveSection = await this.getQueryActiveSection()
            const queryString = [
                ...queryStringSortFilter,
                ...queryFilterActiveSection,
            ]

            const dataCalendarSQL =
                await this.databaseAction.getAllWithQueryString(
                    this.pageStore?.dataSource?.id ?? '',
                    queryString
                )

            this.setListCalendar(dataCalendarSQL)
            this.setTotalDataLoadedByMode(0)
            this.resetListCalendarSection()
            this.getDataCalendarLayout(formulaManager)
        } catch (error) {
            console.info('error', error)
        }
    }

    async getQueryActiveSection() {
        const queryString: Q.Clause[] = []

        const groupingColumn = this.pageStore?.activeLayout?.groupingColumn

        if (!groupingColumn) {
            return Promise.resolve([])
        }

        const tableData: DocumentSnapshot<DocumentData> = (await (
            await getFireStore()
        )
            // @ts-ignore: global variable
            .collection(`teams/${global.teamId}/tables`)
            .doc(`${this.pageStore?.dataSource?.id}`)
            .get()) as unknown as DocumentSnapshot<DocumentData>

        const table = tableData.data() as Table

        const typeGroupColumn =
            table?.schema?.fieldType?.[groupingColumn]?.type || 'Number'

        const activeSections =
            this.cachedCollectionCalendarStore?.activeSections.map((item) => {
                if (typeGroupColumn !== 'Number') {
                    return `'${item}'`
                }
                return item
            })

        if (activeSections?.length > 0) {
            queryString.push(Q.where(groupingColumn, Q.oneOf(activeSections)))
        }
        return Promise.resolve(queryString)
    }

    async getQueryActiveGroup() {
        const queryString: Q.Clause[] = []

        const groupingColumn = this.pageStore?.activeLayout?.groupingColumn

        if (!groupingColumn) {
            return Promise.resolve([])
        }

        const tableData: DocumentSnapshot<DocumentData> = (await (
            await getFireStore()
        )
            // @ts-ignore: global variable
            .collection(`teams/${global.teamId}/tables`)
            .doc(`${this.pageStore?.dataSource?.id}`)
            .get()) as unknown as DocumentSnapshot<DocumentData>

        const table = tableData.data() as Table

        const typeGroupColumn =
            table?.schema?.fieldType?.[groupingColumn]?.type || 'Number'

        const activeSections = this.cachedCollectionMapStore?.activeGroups.map(
            (item) => {
                if (typeGroupColumn !== 'Number') {
                    return `'${item}'`
                }
                return item
            }
        )

        if (activeSections?.length > 0) {
            queryString.push(Q.where(groupingColumn, Q.oneOf(activeSections)))
        }
        return Promise.resolve(queryString)
    }

    getTotalDataLoadedByMode() {
        let totalLoaded = 0
        const currentMode = this.cachedCollectionCalendarStore?.calendarMode
        if (currentMode === 'D') {
            totalLoaded =
                this.cachedCollectionCalendarStore?.cachedDayCalendarStore?.getTotalDataLoaded()
        } else if (currentMode === 'W') {
            totalLoaded =
                this.cachedCollectionCalendarStore?.cachedWeekCalendarStore?.getTotalDataLoaded()
        } else if (currentMode === 'M') {
            totalLoaded =
                this.cachedCollectionCalendarStore?.cachedMonthCalendarStore?.getTotalDataLoaded()
        }

        return totalLoaded
    }

    setTotalDataLoadedByMode(value: number) {
        const currentMode = this.cachedCollectionCalendarStore?.calendarMode
        if (currentMode === 'D') {
            this.cachedCollectionCalendarStore?.cachedDayCalendarStore?.setTotalDataLoaded(
                value
            )
        } else if (currentMode === 'W') {
            this.cachedCollectionCalendarStore?.cachedWeekCalendarStore?.setTotalDataLoaded(
                value
            )
        } else if (currentMode === 'M') {
            this.cachedCollectionCalendarStore?.cachedMonthCalendarStore?.setTotalDataLoaded(
                value
            )
        }
    }

    convertAndSortDataCalendarByMode(
        data: ListDataCalendarGroup,
        mode: CalendarMode
    ) {
        const newList = [
            ...(this.listCalendarSection?.[mode] || []),
            ...data,
        ].reduce((prevValue, currValue) => {
            const key = currValue['title']
            let found = false
            for (let i = 0; i < prevValue.length; i++) {
                if (prevValue[i]['title'] === key) {
                    found = true
                    const newData = [
                        ...(prevValue[i]?.['data'] || []),
                        ...currValue['data'],
                    ]
                    prevValue[i]['data'] = _.orderBy(
                        newData,
                        ['startDate'],
                        'desc'
                    )
                }
            }

            if (!found) {
                prevValue.push(currValue)
            }
            return prevValue
        }, [])

        return newList
    }

    groupAndGetDataCalendarByMode(list: ListDataCalendar) {
        const data = _.orderBy(list, ['startDate'], 'desc')
        if (this.cachedCollectionCalendarStore.calendarMode === 'D') {
            const dataConverted = _.chain(data)
                .groupBy('dateGroup')
                .map((value, key) => {
                    return { title: key, data: value }
                })
                .value()
            const newList = this.convertAndSortDataCalendarByMode(
                dataConverted,
                'D'
            )
            const result = _.orderBy(newList, ['title'], 'desc')
            this.setListCalendarSection(result, 'D')
        } else if (this.cachedCollectionCalendarStore.calendarMode === 'W') {
            const dataConverted = _.chain(data)
                .groupBy('startWeek')
                .map((value, key) => {
                    return { title: key, data: value }
                })
                .value()
            const newList = this.convertAndSortDataCalendarByMode(
                dataConverted,
                'W'
            )
            const result = _.orderBy(newList, ['title'], 'desc')
            this.setListCalendarSection(result, 'W')
        } else if (this.cachedCollectionCalendarStore.calendarMode === 'M') {
            const dataConverted = _.chain(data)
                .groupBy('month')
                .map((value, key) => {
                    return { title: key, data: value }
                })
                .value()
            const newList = this.convertAndSortDataCalendarByMode(
                dataConverted,
                'M'
            )
            const result = _.orderBy(newList, ['title'], 'desc')
            this.setListCalendarSection(result, 'M')
        }
    }

    getDataCalendarWithFilter() {
        try {
            let data: ListDataCalendarWithSection = []
            if (this.cachedCollectionCalendarStore.calendarMode === 'D') {
                const monthSelected =
                    this.cachedCollectionCalendarStore.monthYear

                data =
                    this.listCalendarSection?.D?.filter((item) =>
                        dayjs(monthSelected).isSame(
                            item.title as string,
                            'month'
                        )
                    ) || []
            } else if (
                this.cachedCollectionCalendarStore.calendarMode === 'W'
            ) {
                const weekPicked = this.cachedCollectionCalendarStore.weekPicked
                data =
                    this.listCalendarSection?.W?.filter((item) =>
                        dayjs(weekPicked.startDate).isSame(
                            item.title as string,
                            'week'
                        )
                    ) || []
            } else if (
                this.cachedCollectionCalendarStore.calendarMode === 'M'
            ) {
                const monthSelected =
                    this.cachedCollectionCalendarStore.monthYear

                data =
                    this.listCalendarSection?.M?.filter((item) =>
                        dayjs(monthSelected).isSame(
                            item.title as string,
                            'month'
                        )
                    ) || []
            }
            this.cachedCollectionCalendarStore.setListCalendarView(data)
        } catch (error) {
            console.info('error------', error)
        }
    }

    async getDataMapLayout() {
        try {
            const groupingColumn = this.pageStore?.activeLayout.groupingColumn
            const queryStringSortFilter = await this.getQuerySortFilter()
            const queryFilterActiveSection = await this.getQueryActiveGroup()

            const queryString = [
                ...queryStringSortFilter,
                ...queryFilterActiveSection,
            ]

            let result: IItemDataMap[] = []
            if (groupingColumn) {
                const listVirtualDataSection =
                    await this.databaseAction.getAllVirtualListSectionDataWithQueryString(
                        this.pageStore?.dataSource?.id ?? '',
                        queryString,
                        groupingColumn
                    )

                const listCoverted = listVirtualDataSection.map((item) => {
                    const itemData = item.data.map((i) => ({
                        index: i,
                        type: item.title,
                    }))
                    return itemData
                })

                result = listCoverted.flat()
            } else {
                const listVirtualDataWithoutSection =
                    await this.databaseAction.getAllVirtualSqlDataWithQueryString(
                        this.pageStore?.dataSource?.id ?? '',
                        queryString
                    )
                result = listVirtualDataWithoutSection.map((item) => ({
                    index: item,
                }))
            }

            this.setDataMap(result)
        } catch (error) {
            console.warn(error)
        }
    }

    setFormulaManager(formulaManager: FormulaManager) {
        this.formulaManager = formulaManager
    }

    async getAndSetData(formulaManager?: FormulaManager) {
        try {
            !this.formulaManager &&
                formulaManager &&
                this.setFormulaManager(formulaManager)
            if (this.pageStore?.activeLayoutType === 'list') {
                await this.getDataCardListLayout()
            } else if (this.pageStore?.activeLayoutType === 'calendar') {
                await this.getAndSetCalendarRaw(this.formulaManager)
            } else if (this.pageStore?.activeLayoutType === 'card') {
                await this.getDataCardListLayout()
            } else if (this.pageStore?.activeLayoutType === 'map') {
                await this.getDataMapLayout()
            }
        } catch (error) {
            Alert.alert('Error', 'Get data error!')
            console.info('error', error)
        }
    }

    collectionFilterStore(): CollectionFilterStore {
        if (this.cachedCollectionFilterStore) {
            return this.cachedCollectionFilterStore
        } else {
            let listFilterData: FilterData[] = []
            if (this.pageDataStored?.filter) {
                listFilterData = Object.values(
                    this.pageDataStored?.filter ?? {}
                )
            }

            const newCollectionFilterStore = new CollectionFilterStore(
                this.collectionPageId,
                this.pageStore?.dataSource?.id ?? '',
                listFilterData
            )
            this.cachedCollectionFilterStore = newCollectionFilterStore
            return newCollectionFilterStore
        }
    }

    collectionSortStore(): CollectionSortStore {
        if (this.cachedCollectionSortStore) {
            return this.cachedCollectionSortStore
        } else {
            const newCollectionSortStore = new CollectionSortStore(
                this.collectionPageId
            )
            this.cachedCollectionSortStore = newCollectionSortStore
            return newCollectionSortStore
        }
    }

    getSearchStore(): SearchCollectionStore {
        if (this.searchStore) {
            return this.searchStore
        } else {
            const newCollectionSortStore = new SearchCollectionStore(
                this.collectionPageId
            )
            this.searchStore = newCollectionSortStore
            return newCollectionSortStore
        }
    }

    collectionCalendarStore(): CalendarLayoutStore {
        if (this.cachedCollectionCalendarStore) {
            return this.cachedCollectionCalendarStore
        } else {
            const newCollectionCalendarStore = new CalendarLayoutStore()
            this.cachedCollectionCalendarStore = newCollectionCalendarStore

            return newCollectionCalendarStore
        }
    }

    collectionMapStore(): MapLayoutStore {
        if (this.cachedCollectionMapStore) {
            return this.cachedCollectionMapStore
        } else {
            const newCollectionMapStore = new MapLayoutStore()
            this.cachedCollectionMapStore = newCollectionMapStore

            return newCollectionMapStore
        }
    }

    async filterShownActions(itemActions: ItemAction[]): Promise<ItemAction[]> {
        const res = await asyncFilter(
            itemActions as unknown as Record<string, unknown>[],
            async (itemAction) =>
                !(await TransformBinding(
                    itemAction['isHidden'] as Binding<boolean>
                ))
        )

        return res as unknown as ItemAction[]
    }
}
