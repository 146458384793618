//
//  FilterSlider.tsx
//
//  Created by thaitd96 on 2022-25-07 17:10.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React, { useCallback } from 'react'
import {
    Animated,
    Image,
    Pressable,
    StyleSheet,
    Text,
    TextInput,
    View,
} from 'react-native'
import { Theme, useTheme } from '../../../../../styles/theme'
import Slider from '../../../../../components/SliderMulti'
import { PanGestureHandler } from 'react-native-gesture-handler'
import image from '../../../../../assets/icons'
import { COLORS } from '../../../../../assets/colors'
import appConstants from '../../../../../constants/const'
import { observer } from 'mobx-react-lite'
import { FilterSliderStore } from './FilterSliderStore'
import { CollectionFilterStore } from '../CollectionFilterStore'
import ContextMenuCustom from '../../../../../components/context-menu-custom/ContextMenuCustom'
import { useDatabaseAction } from '../../../../../context/AppDatabaseContext'

const CompareOption = [
    { key: appConstants.NUMBER_COMPARE_TYPE.BETWEEN, title: 'Between' },
    {
        key: appConstants.NUMBER_COMPARE_TYPE.GREATER_THAN,
        title: 'Greater than',
    },
    { key: appConstants.NUMBER_COMPARE_TYPE.LOWER_THEN, title: 'Lower than' },
    { key: appConstants.NUMBER_COMPARE_TYPE.EQUALS, title: 'Equals' },
]

export const FilterSlider = observer(
    (props: {
        filterSliderStore: FilterSliderStore
        collectionFilterStore: CollectionFilterStore
    }) => {
        const theme = useTheme()
        const styles = React.useMemo(() => createStyles(theme), [theme])

        const databaseAction = useDatabaseAction()

        const clear = React.useCallback(() => {
            props.collectionFilterStore.removeFilteredColumn(
                props.filterSliderStore.columnFilter.key
            )
        }, [
            props.collectionFilterStore,
            props.filterSliderStore.columnFilter.key,
        ])

        const onValueChanged = React.useCallback(
            (lowValue?: number, highValue?: number, fromUser?: boolean) => {
                if (lowValue) {
                    props.filterSliderStore.setLow(Math.floor(lowValue))
                }
                if (highValue) {
                    props.filterSliderStore.setHigh(Math.floor(highValue))
                }
            },
            [props.filterSliderStore]
        )

        const renderThumbLow = useCallback(() => {
            return (
                <View>
                    <View style={styles.thumb} />
                    <Text style={styles.labelThumb} numberOfLines={1}>
                        {props.filterSliderStore.low}
                    </Text>
                </View>
            )
        }, [props.filterSliderStore.low, styles.labelThumb, styles.thumb])

        const renderThumbHigh = useCallback(() => {
            return (
                <View>
                    <View style={styles.thumb} />
                    <Text style={styles.labelThumb}>
                        {props.filterSliderStore.high}
                    </Text>
                </View>
            )
        }, [props.filterSliderStore.high, styles.labelThumb, styles.thumb])

        const renderRailSelected = () => <View style={styles.railSelected} />

        const renderRail = () => <View style={styles.rail} />

        const onChangeSelection = React.useCallback(
            (option: number) => {
                props.filterSliderStore.setType(option)
            },
            [props.filterSliderStore]
        )

        const onChangeText = React.useCallback(
            (text: string) => {
                if (/^\d+$/.test(text) || text === '') {
                    props.filterSliderStore.setValue(text)
                }
            },
            [props.filterSliderStore]
        )

        React.useEffect(() => {
            props.filterSliderStore.getMaxMinOfTable(
                databaseAction,
                props.collectionFilterStore.dataSourceId,
                props.filterSliderStore.columnFilter.columnName
            )
        }, [
            databaseAction,
            props.collectionFilterStore.dataSourceId,
            props.filterSliderStore,
        ])

        return (
            <PanGestureHandler>
                <Animated.View style={styles.container}>
                    <View style={styles.header}>
                        <Text style={styles.title}>
                            {props.filterSliderStore.columnFilter.columnNameRaw}
                        </Text>
                        <Pressable onPress={clear}>
                            <Image
                                source={image.close}
                                style={styles.imgClose}
                            />
                        </Pressable>
                    </View>
                    <View style={styles.optionContainer}>
                        <ContextMenuCustom
                            dataActions={CompareOption}
                            dropdownMenuMode={true}
                            onSelect={onChangeSelection}
                            initialValue={
                                appConstants.NUMBER_COMPARE_TYPE.BETWEEN
                            }
                            currentValue={props.filterSliderStore.type}
                        />
                        {props.filterSliderStore.type !==
                            appConstants.NUMBER_COMPARE_TYPE.BETWEEN && (
                            <>
                                <View style={styles.spaceView} />
                                <TextInput
                                    style={styles.dataTextInput}
                                    onChangeText={onChangeText}
                                    value={props.filterSliderStore.value}
                                    placeholder={'Data'}
                                    placeholderTextColor={
                                        theme.colorScheme.labelSecondary
                                    }
                                    keyboardType={'numeric'}
                                    selectTextOnFocus={true}
                                />
                            </>
                        )}
                    </View>
                    {props.filterSliderStore.type ===
                        appConstants.NUMBER_COMPARE_TYPE.BETWEEN &&
                        props.filterSliderStore.min &&
                        props.filterSliderStore.max && (
                            <Slider
                                min={props.filterSliderStore.min}
                                max={props.filterSliderStore.max}
                                step={0.0001}
                                floatingLabel
                                renderThumbLow={renderThumbLow}
                                renderThumbHigh={renderThumbHigh}
                                renderRail={renderRail}
                                renderRailSelected={renderRailSelected}
                                onValueChanged={onValueChanged}
                                low={
                                    props.filterSliderStore.low ??
                                    props.filterSliderStore.min
                                }
                                high={
                                    props.filterSliderStore.high ??
                                    props.filterSliderStore.max
                                }
                                style={styles.sliderContainer}
                            />
                        )}
                </Animated.View>
            </PanGestureHandler>
        )
    }
)

const createStyles = ({ colorScheme, roundness }: Theme) => {
    return StyleSheet.create({
        labelThumb: {
            position: 'absolute',
            top: 28,
            color: colorScheme.labelSecondary,
            fontSize: 13,
            fontWeight: '500',
            lineHeight: 15.73,
            alignSelf: 'center',
            textAlign: 'center',
            width: 100,
        },

        container: {
            flex: 1,
            paddingHorizontal: appConstants.PADDING_COMMON,
            borderBottomWidth: 1,
            borderBottomColor: colorScheme.separator,
            paddingBottom: 12,
            paddingTop: 12,
        },

        thumb: {
            width: 20,
            height: 20,
            borderRadius: 10,
            borderWidth: 3,
            borderColor: colorScheme.accentColor,
            backgroundColor: COLORS.WHITE,
        },
        rail: {
            flex: 1,
            height: 6,
            borderRadius: 2,
            backgroundColor: colorScheme.separator,
        },
        railSelected: {
            height: 6,
            backgroundColor: colorScheme.accentColor,
            borderRadius: 2,
        },
        title: {
            color: colorScheme.labelPrimary,
            fontSize: 17,
            lineHeight: 20.57,
            fontWeight: '600',
        },
        header: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        imgClose: {
            height: 30,
            width: 30,
        },
        sliderContainer: {
            paddingVertical: 20,
        },
        touchSelector: {
            // alignSelf: 'baseline',
            flexDirection: 'row',
            paddingLeft: 12,
            paddingRight: 8,
            backgroundColor: colorScheme.backgroundL2,
            borderRadius: roundness,
            height: 48,
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        txtSelector: {
            color: colorScheme.accentColor,
            fontWeight: '500',
            fontSize: 15,
            lineHeight: 18.15,
        },
        iconDropDown: {
            height: 24,
            width: 24,
            resizeMode: 'contain',
            tintColor: colorScheme.accentColor,
        },
        optionContainer: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 8,
        },
        modalContainer: {
            flex: 1,
        },
        overlayStyle: { backgroundColor: 'rgba(0,0,0,0.3)' },
        cancelStyle: { backgroundColor: colorScheme.backgroundL1 },
        optionContainerStyle: { backgroundColor: colorScheme.backgroundL1 },
        dataTextInput: {
            height: 48,
            flex: 1,
            backgroundColor: colorScheme.backgroundL2,
            borderRadius: roundness,
            fontSize: 15,
            fontWeight: '500',
            paddingHorizontal: 12,
            color: colorScheme.labelPrimary,
        },
        spaceView: {
            width: 8,
        },
    })
}
