//
//  CardItemLargeOverlay.tsx
//
//  Created by thaitd96 on 2022-26-04 18:00.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import {
    CollectionPageStore,
    DefaultPageSelection,
} from '@appformula/app-studio-core/src'
import appConstants from '../../../../constants/const'
import React, { useCallback, useEffect, useState } from 'react'
import { StyleSheet, ImageBackground, Pressable } from 'react-native'
import { Theme, useTheme } from '../../../../styles/theme'
import { CardItemContent } from './CardItemContent'
import { useGetItemFromIndex } from '../hooks/useGetItemFromIndex'
import { observer } from 'mobx-react-lite'
import { ARNCollectionPageStore } from '../../ARNCollectionPageStore'
import { ButtonMore } from './ButtonMore'
import { CardLayout, ItemAction } from '@appformula/app-descriptor/src'
import { LinearGradient } from 'expo-linear-gradient'
import { useNavigation } from '@react-navigation/native'
import screenNames from '../../../../navigations/screenNames'
import { useBindingHaveItemData } from '../hooks/useBindingHaveItemData'
import { useWidthHeightMainLayout } from '../hooks/useSpecifyPlatformWindowDimensions'
import { DesignPageContext } from '../../../../context/DesignPageContext'

export const CardItemLargeOverlay = observer(
    (props: {
        item: number
        pageStore: CollectionPageStore
        arnCollectionPageStore: ARNCollectionPageStore
        valueGrouping?: unknown
    }) => {
        const theme = useTheme()
        const { width } = useWidthHeightMainLayout()
        const styles = React.useMemo(
            () => createStyles(theme, width),
            [theme, width]
        )
        const { item, pageStore } = props

        const [itemActions, setItemActions] = useState<ItemAction[]>([])

        const filterShownActions = useCallback(async () => {
            const res = await props.arnCollectionPageStore.filterShownActions(
                pageStore.itemActions
            )
            setItemActions(res as unknown as ItemAction[])
        }, [pageStore.itemActions, props.arnCollectionPageStore])

        useEffect(() => {
            filterShownActions()
        }, [filterShownActions])

        const navigation = useNavigation()
        const itemData = useGetItemFromIndex(
            props.pageStore,
            item,
            props.arnCollectionPageStore.queryString,
            props.valueGrouping
        )

        const imageBinding = (props.pageStore.activeLayout as CardLayout).image
        const image = useBindingHaveItemData(imageBinding, itemData)

        const designPageStore = React.useContext(DesignPageContext)
        const onPressItem = useCallback(() => {
            const tableId = props.pageStore.dataSource?.id
            if (tableId) {
                if (designPageStore) {
                    designPageStore.pageSelection = new DefaultPageSelection(
                        tableId,
                        'details'
                    )
                }
                navigation.navigate(
                    screenNames.DETAIL_SCREEN as never,
                    {
                        itemKey: itemData?.['key'] ?? '',
                        pageId: '',
                        tableId,
                    } as never
                )
            }
        }, [
            designPageStore,
            itemData,
            navigation,
            props.pageStore.dataSource?.id,
        ])

        return (
            <Pressable style={styles.item} onPress={onPressItem}>
                <ImageBackground
                    style={styles.image}
                    source={{
                        uri: (image as string) ?? '',
                    }}
                    resizeMode={'stretch'}
                >
                    <LinearGradient
                        colors={['transparent', 'rgba(0,0,0,0.9)']}
                        style={styles.linearGradient}
                    />
                    {itemActions?.length > 0 && (
                        <ButtonMore
                            itemActions={itemActions}
                            itemData={itemData}
                        />
                    )}
                    <CardItemContent
                        item={itemData}
                        pageStore={pageStore}
                        imageSize={'large'}
                    />
                </ImageBackground>
            </Pressable>
        )
    }
)

const createStyles = ({ colorScheme, roundness }: Theme, width: number) =>
    StyleSheet.create({
        linearGradient: {
            position: 'absolute',
            bottom: 0,
            height: '50%',
            width: '100%',
        },
        item: {
            borderColor: colorScheme.separator,
            borderWidth: 1,
            borderRadius: roundness,
            overflow: 'hidden',
            marginHorizontal: appConstants.PADDING_COMMON,
            marginTop: appConstants.PADDING_COMMON,
            width: width - 2 * appConstants.PADDING_COMMON,
        },
        image: { width: '100%', aspectRatio: 4 / 3 },
    })
