//
//  GetSqlData.tsx
//
//  Created by thaitd96 on 2022-07-21 10:40.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.

import {
    DatabaseMetadata,
    DataSource,
    FormulaExecutorDelegate,
} from '@Unstatic-co/appformula-dsl'
import appConstants from '../../constants/const'
import cache from '../cache'
import Model from '@nozbe/watermelondb/Model'
import { RawRecord } from '@nozbe/watermelondb'

type SupportingParameters = {
    dataRow?: Record<string, unknown>
    value?: any
}

export class ARNFormulaExecutorDelegate implements FormulaExecutorDelegate {
    // MARK: - FormulaExecutorDelegate
    resolveContextVariable = async (
        contextVariableName: string,
        supportingParameters: SupportingParameters
    ): Promise<unknown> => {
        try {
            if (contextVariableName === appConstants.CURRENT_ROW) {
                return supportingParameters?.dataRow ?? {}
            }

            if (contextVariableName === appConstants.CURRENT_VALUE) {
                return supportingParameters?.value ?? {}
            }
            return {}
        } catch (error) {
            return {}
        }
    }

    resolveTable = async (tableName: string): Promise<RawRecord[]> => {
        try {
            const data = await cache.database.get(tableName).query().fetch()
            return data.map((item: Model) => item._raw)
        } catch (error) {
            return []
        }
    }

    resolveDatabaseMetadata(
        tableNames: string[]
    ): Map<string, DatabaseMetadata> {
        const map = new Map<string, DatabaseMetadata>()
        // @ts-ignore
        const teamId = global.teamId
        const firebaseConfig = {
            apiKey: 'AIzaSyBnsR7rKqAjmHqnIxE-2fFTWyBzIt2kMeY',
            authDomain: 'appformula-dev.firebaseapp.com',
            projectId: 'appformula-dev',
            storageBucket: 'appformula-dev.appspot.com',
            messagingSenderId: '540718492053',
            appId: '1:540718492053:android:af7f3d384ca3b9c411ae9c',
            measurementId: 'G-E8F4JQ3DR4',
            databaseURL: 'https://appformula-dev.firebaseio.com',
        }
        tableNames.forEach((tableName) => {
            map.set(tableName, {
                dataSource: DataSource.firestore,
                firebaseConfig,
                rootPath: `tableData/${teamId}`,
            })
        })
        return map
    }
}

export const arnFormulaExecutorDelegate = new ARNFormulaExecutorDelegate()
