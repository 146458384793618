import {
    TextInput,
    StyleSheet,
    Pressable,
    Image,
    Text,
    SafeAreaView,
    ScrollView,
} from 'react-native'
import React, { useMemo } from 'react'
import { WelcomePageStore } from './WelcomePageStore'
import { observer } from 'mobx-react-lite'
import { Theme, useTheme } from '../../styles/theme'
import { useNavigation } from '@react-navigation/native'
import AppButton from '../../components/AppButton'
import screenNames from '../../navigations/screenNames'
import image from '../../assets/icons'

const ResetPasswordScreen = observer((props: { store: WelcomePageStore }) => {
    const theme = useTheme()
    const styles = useMemo(() => createStyles(theme), [theme])

    const { store } = props

    const navigation = useNavigation()

    const onBack = React.useCallback(() => {
        navigation.goBack()
    }, [navigation])

    const [email, setEmail] = React.useState<string>('')

    const handleSetEmail = React.useCallback((value: string) => {
        setEmail(value)
    }, [])

    const handleSendMail = React.useCallback(async () => {
        if (email.trim()) {
            await store.sendPasswordResetEmail(email.trim()).then(() => {
                navigation.navigate(screenNames.VERIFICATION as never)
            })
        }
    }, [email, navigation, store])

    return (
        <SafeAreaView style={styles.container}>
            <Pressable style={styles.containerHeaderLeft} onPress={onBack}>
                <Image
                    style={styles.backImg}
                    source={image.back}
                    resizeMode={'contain'}
                />
                <Text style={styles.txtHeaderLeft}>Login</Text>
            </Pressable>
            <ScrollView contentContainerStyle={styles.containerScroll}>
                <Text style={styles.txtTitle}>Reset Password</Text>
                <Text style={styles.txtSubTitle}>
                    {`Login with your ${store.appName} employee account app to continue with ${store.appName}.`}
                </Text>
                <TextInput
                    style={styles.input}
                    onChangeText={handleSetEmail}
                    value={email}
                    placeholder="name@work-email.com"
                    autoCapitalize="none"
                    placeholderTextColor={theme.colorScheme.labelSecondary}
                />
                <AppButton
                    title="Send"
                    onPress={handleSendMail}
                    loading={store.loadingState}
                />
            </ScrollView>
        </SafeAreaView>
    )
})

export default ResetPasswordScreen

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        backImg: {
            width: 28,
            height: 28,
            tintColor: colorScheme.accentColor,
            marginLeft: 8,
        },
        containerHeaderLeft: { flexDirection: 'row', alignItems: 'center' },
        txtHeaderLeft: {
            color: colorScheme.accentColor,
            fontWeight: '500',
            fontSize: 17,
            lineHeight: 21,
        },
        container: {
            flex: 1,
            backgroundColor: colorScheme.backgroundL1,
            paddingTop: 20,
        },
        containerScroll: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            paddingHorizontal: 24,
        },
        txtTitle: {
            fontWeight: '700',
            fontSize: 30,
            lineHeight: 36.31,
            color: colorScheme.labelPrimary,
            marginBottom: 9,
        },
        txtSubTitle: {
            fontWeight: '400',
            fontSize: 15,
            lineHeight: 22.5,
            color: colorScheme.labelSecondary,
            textAlign: 'center',
            marginBottom: 20,
        },
        input: {
            width: '100%',
            height: 50,
            marginBottom: 12,
            borderWidth: 1,
            padding: 10,
            borderRadius: roundness,
            borderColor: colorScheme.separator,
            color: colorScheme.labelPrimary,
        },
    })
