//
//  FormInputRepository.ts
//
//  Created by thaitd96 on 2022-08-10 14:24
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { BaseFormInput } from '@appformula/app-descriptor'
import { BaseFormInputRepository } from '@appformula/app-studio-core'
import { Observable } from 'rxjs'
import { AnyObservableObjectRepository } from '../../ObjectRepository'

export class LocalFormInputRepository<I extends BaseFormInput>
    extends AnyObservableObjectRepository<I>
    implements BaseFormInputRepository<I>
{
    get inputId(): string {
        return this.objectId
    }
    get inputSnapshot(): I {
        return this.objectSnapshot
    }
    get input(): Observable<I> {
        return this.object
    }
    set<K extends keyof I>(key: K, value: I[K]): Promise<void> {
        throw new Error('Method not implemented.')
    }
}
