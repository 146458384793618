//
//  AppDescriptorContext.tsx
//
//  Created by thaitd96 on 2022-05-11 12:11:31.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import firebase from 'firebase'
import localDescriptor from '../assets/localDescriptor.json'
import { AppDescriptor } from '@appformula/app-descriptor'
import { AppDescriptorStore } from '@appformula/app-studio-core'
import React from 'react'
import { LocalAppDescriptorRepository } from '../app-studio-core-local'
import { FirebaseAppDescriptorRepository } from '@appformula/app-studio-core-firebase'
import buildConfig from '../../buildConfig.json'
import appConstants from '../constants/const'

// Special case, not use global initializeApp cause can not use await here
// -----------------------------------------------------------------------
const firebaseConfig = {
    apiKey: 'AIzaSyBnsR7rKqAjmHqnIxE-2fFTWyBzIt2kMeY',
    authDomain: 'appformula-dev.firebaseapp.com',
    projectId: 'appformula-dev',
    storageBucket: 'appformula-dev.appspot.com',
    messagingSenderId: '540718492053',
    appId: '1:540718492053:web:c2e84b4f87b2bb6111ae9c',
    measurementId: 'G-E8F4JQ3DR4',
}

// Initialize Firebase
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig)
} else {
    firebase.app() // if already initialized, use that one
}
// -----------------------------------------------------------------------

let appDescriptorRepository:
    | LocalAppDescriptorRepository
    | FirebaseAppDescriptorRepository

if (buildConfig.descriptorType === appConstants.DESCRIPTOR_TYPE.LOCAL) {
    appDescriptorRepository = new LocalAppDescriptorRepository(
        localDescriptor as unknown as AppDescriptor
    )
} else if (
    buildConfig.descriptorType ===
    appConstants.DESCRIPTOR_TYPE.REALTIME_DATABASE
) {
    appDescriptorRepository = new FirebaseAppDescriptorRepository(
        firebase
            .database()
            .ref(`/app-descriptors/${buildConfig.teamId}/${buildConfig.appId}`),
        localDescriptor as unknown as AppDescriptor,
        buildConfig.appId
    )
} else {
    // Default
    appDescriptorRepository = new FirebaseAppDescriptorRepository(
        firebase
            .database()
            .ref(`/app-descriptors/${buildConfig.teamId}/${buildConfig.appId}`),
        localDescriptor as unknown as AppDescriptor,
        buildConfig.appId
    )
}

const appDescriptorStore = new AppDescriptorStore(appDescriptorRepository)

export const AppDescriptorContext =
    React.createContext<AppDescriptorStore>(appDescriptorStore)
