import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import {
    AppDescriptorStore,
    CollectionPageStore,
    CustomPageStore,
    DefaultAuthenticationPageSelection,
    DefaultPageSelection,
    DesignPageStore,
    DetailsPageStore,
    FormPageStore,
    NormalPageSelection,
} from '@appformula/app-studio-core/src'
import { useNavigation } from '@react-navigation/native'
import screenNames from '../../../navigations/screenNames'
import { useTheme } from '../../../styles/theme'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { View } from 'react-native'
import { CollectionPage } from '../../collection/CollectionPage'
import appConstants from '../../../constants/const'
import TabBarIconStore from './../TabBarIconStore'
import TabBarIcon from './../TabBarIcon'
import image from '../../../assets/icons'
import MorePage from '../../more'
import { CustomPageRoute } from '../../custom/CustomPageRoute'

export type TabParamList = {
    CollectionRoute: {
        pageId: string
        fromMorePage?: boolean
        showBackButton?: boolean
    }
    CustomPageRoute: {
        pageId: string
        fromMorePage?: boolean
        showBackButton?: boolean
    }
    BlankFormPage: {
        pageId: string
        fromMorePage?: boolean
        showBackButton?: boolean
    }
    More: { fromMorePage?: boolean; showBackButton?: boolean }
}

const TabView = observer(
    (props: {
        appDescriptorStore: AppDescriptorStore
        designPageStore: DesignPageStore
    }) => {
        const { appDescriptorStore, designPageStore } = props
        const list = [...appDescriptorStore.frontPageIds].map((item) => item)

        const theme = useTheme()
        const navigation = useNavigation()

        const Tab = createBottomTabNavigator<TabParamList>()

        const BlankFormPage = () => <View />

        useEffect(() => {
            if (!designPageStore) {
                return
            }

            if (designPageStore.pageSelection instanceof NormalPageSelection) {
                const pageId = designPageStore.pageSelection.pageId
                const store = appDescriptorStore.pageStore(pageId)

                if (store instanceof CollectionPageStore) {
                    navigation.navigate(store.pageId as never)
                } else if (store instanceof CustomPageStore) {
                    navigation.navigate(
                        screenNames.CUSTOM_SCREEN as never,
                        { pageId } as never
                    )
                } else if (store instanceof FormPageStore) {
                    navigation.navigate(
                        screenNames.FORM_PAGE as never,
                        { pageId } as never
                    )
                } else if (store instanceof DetailsPageStore) {
                    navigation.navigate(
                        screenNames.DETAIL_SCREEN as never,
                        { pageId } as never
                    )
                }
            } else if (
                designPageStore.pageSelection instanceof
                DefaultAuthenticationPageSelection
            ) {
                const defaultAuthenticationPageType =
                    designPageStore.pageSelection.defaultAuthenticationPageType

                if (defaultAuthenticationPageType === 'welcome') {
                    navigation.navigate(screenNames.WELCOME_PAGE as never)
                }
            } else if (
                designPageStore.pageSelection instanceof DefaultPageSelection
            ) {
                const defaultPageType =
                    designPageStore.pageSelection.defaultPageType
                const dataSourceId = designPageStore.pageSelection.dataSourceId
                if (defaultPageType === 'details') {
                    navigation.navigate(
                        screenNames.DETAIL_SCREEN as never,
                        { pageId: '', tableId: dataSourceId } as never
                    )
                } else if (defaultPageType === 'form') {
                    navigation.navigate(
                        screenNames.FORM_PAGE as never,
                        { pageId: '', tableId: dataSourceId } as never
                    )
                }
            }
        }, [
            appDescriptorStore,
            designPageStore?.pageSelection,
            navigation,
            designPageStore,
        ])

        const changePageSelectionOnStudio = (pageId: string) => {
            if (designPageStore) {
                designPageStore.pageSelection = new NormalPageSelection(pageId)
            }
        }

        const listFilter = list?.filter(
            (pageId) =>
                appDescriptorStore.pageStore(pageId) instanceof
                    CollectionPageStore ||
                appDescriptorStore.pageStore(pageId) instanceof
                    CustomPageStore ||
                appDescriptorStore.pageStore(pageId) instanceof FormPageStore
        )

        if (listFilter?.length) {
            const listTabVisible = listFilter.slice(
                0,
                appConstants.NUMBER_TAB_VIEW
            )
            return (
                <Tab.Navigator
                    screenOptions={{
                        tabBarHideOnKeyboard: true,
                        headerStyle: {
                            backgroundColor: theme.colorScheme.backgroundL1,
                        },
                        headerTintColor: theme.colorScheme
                            .labelPrimary as string,
                        tabBarStyle: {
                            backgroundColor: theme.colorScheme.backgroundL1,
                            borderTopColor: theme.colorScheme.separator,
                        },
                    }}
                >
                    {listTabVisible.map((pageId, index) => {
                        const pageStore = appDescriptorStore.pageStore(pageId)
                        if (pageStore instanceof CollectionPageStore) {
                            return (
                                <Tab.Screen
                                    key={index}
                                    name={pageId as keyof TabParamList}
                                    initialParams={{ pageId }}
                                    component={CollectionPage}
                                    options={{
                                        headerShown: false,
                                        tabBarShowLabel: false,
                                        tabBarIcon: ({ focused }) => {
                                            return (
                                                <TabBarIconStore
                                                    focused={focused}
                                                    pageStore={pageStore}
                                                    isLargeDevice={false}
                                                />
                                            )
                                        },
                                    }}
                                    listeners={({ navigation }) => ({
                                        tabPress: (e) => {
                                            changePageSelectionOnStudio(pageId)
                                        },
                                    })}
                                />
                            )
                        } else if (pageStore instanceof CustomPageStore) {
                            return (
                                <Tab.Screen
                                    key={index}
                                    name={pageId as keyof TabParamList}
                                    component={CustomPageRoute}
                                    initialParams={{ pageId }}
                                    options={{
                                        headerShown: false,
                                        tabBarShowLabel: false,
                                        tabBarIcon: ({ focused }) => {
                                            return (
                                                <TabBarIconStore
                                                    focused={focused}
                                                    pageStore={pageStore}
                                                    isLargeDevice={false}
                                                />
                                            )
                                        },
                                    }}
                                    listeners={({ navigation }) => ({
                                        tabPress: (e) => {
                                            changePageSelectionOnStudio(pageId)
                                        },
                                    })}
                                />
                            )
                        } else if (pageStore instanceof FormPageStore) {
                            return (
                                <Tab.Screen
                                    key={index}
                                    name={pageId as keyof TabParamList}
                                    component={BlankFormPage}
                                    listeners={({ navigation }) => ({
                                        tabPress: (e) => {
                                            e.preventDefault()
                                            navigation.navigate(
                                                screenNames.FORM_PAGE,
                                                { pageId }
                                            )
                                            changePageSelectionOnStudio(pageId)
                                        },
                                    })}
                                    options={{
                                        tabBarShowLabel: false,
                                        tabBarIcon: ({ focused }) => {
                                            return (
                                                <TabBarIconStore
                                                    focused={focused}
                                                    pageStore={pageStore}
                                                    isLargeDevice={false}
                                                />
                                            )
                                        },
                                    }}
                                />
                            )
                        }
                        return null
                    })}
                    <Tab.Screen
                        key={'MORE'}
                        name={'More'}
                        component={MorePage}
                        options={{
                            tabBarIcon: ({ focused }) => {
                                return (
                                    <TabBarIcon
                                        focused={focused}
                                        source={image.moreIcon}
                                    />
                                )
                            },
                            tabBarShowLabel: false,
                            headerShown: false,
                        }}
                    />
                </Tab.Navigator>
            )
        } else {
            return null
        }
    }
)

export default TabView
