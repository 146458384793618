import { SortOrder } from '@appformula/app-descriptor/src'
import { action, makeObservable, observable } from 'mobx'

export class CollectionSortStore {
    columnName: string | null = null
    order: SortOrder
    private collectionPageIdValue: string

    get collectionPageId(): string {
        return this.collectionPageIdValue
    }
    constructor(collectionPageId: string, sortBy?: string, order?: SortOrder) {
        this.collectionPageIdValue = collectionPageId
        this.columnName = sortBy
        this.order = order
        makeObservable(this, {
            columnName: observable,
            order: observable,
            setColumnName: action,
            setOrder: action,
            clear: action,
        })
    }

    setColumnName(result: string) {
        this.columnName = result
    }

    setOrder(order: SortOrder) {
        this.order = order
    }

    clear() {
        this.columnName = null
        this.order = null
    }
}
