//
//  ItemDayCalendar.tsx
//
//  Created by thaitd96 on 2022-06-13 15:56.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import {
    ColorValue,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
} from 'react-native'
import { Theme, useTheme } from '../../../../../styles/theme'
import { CollectionPageStore } from '@appformula/app-studio-core'
import { CalendarLayout as CalendarLayoutDescriptor } from '@appformula/app-descriptor/src'
import { GroupDisplay } from '../ItemGroupCalendar'
import { ConvertDateFormat } from '../../../../../utils/date-time/DateTime'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import dayjs from 'dayjs'
import { useBindingHaveItemData } from '../../hooks/useBindingHaveItemData'
dayjs.extend(localizedFormat)

const ItemDayCalendar = observer(
    (props: {
        item: Record<string, unknown>
        activeSections: string[]
        pageStore: CollectionPageStore
        valueGrouping?: string
    }) => {
        const theme = useTheme()
        const { item } = props

        const activeLayout = props.pageStore
            .activeLayout as CalendarLayoutDescriptor
        const groupsDisplay = Object.values(
            activeLayout.groupsDisplay || {}
        ) as GroupDisplay[]
        const groupingColumn = activeLayout.groupingColumn
        const colorLine = groupsDisplay?.find(
            (e) => e.columnValue === item?.[groupingColumn] || ''
        )?.color

        const title = useBindingHaveItemData(activeLayout.title, item)

        const styles = useMemo(
            () => createStyles(theme, colorLine),
            [theme, colorLine]
        )

        return (
            <TouchableOpacity style={styles.itemGroup}>
                <View style={styles.wrapVerticalLine}>
                    <View style={styles.verticalLine}></View>
                </View>
                <View style={styles.content}>
                    <Text style={styles.text}>{title || ''}</Text>
                    <Text style={styles.time}>
                        {`${ConvertDateFormat(
                            item?.['startDate'] as string,
                            'LT'
                        )} - ${ConvertDateFormat(
                            item?.['endDate'] as string,
                            'LT'
                        )}`}
                    </Text>
                </View>
            </TouchableOpacity>
        )
    }
)

export default React.memo(ItemDayCalendar)

const createStyles = ({ colorScheme }: Theme, colorLine: ColorValue) =>
    StyleSheet.create({
        wrapVerticalLine: {
            height: '100%',
            paddingVertical: 14,
            paddingLeft: 12,
            paddingRight: 16,
        },
        content: {
            paddingVertical: 14,
            flex: 1,
            paddingRight: 12,
            borderBottomWidth: 1,
            borderBottomColor: colorScheme.separator,
        },
        verticalLine: {
            flex: 1,
            width: 4,
            borderRadius: 18,
            paddingTop: 14,
            backgroundColor: colorLine,
        },
        iconChecked: {
            height: 20,
            width: 20,
            marginLeft: 4,
            tintColor: colorLine,
        },
        text: {
            fontSize: 15,
            fontWeight: '500',
            color: colorScheme.labelPrimary,
        },
        time: {
            fontSize: 13,
            fontWeight: '500',
            color: colorScheme.labelSecondary,
            marginTop: 4,
        },
        itemGroup: {
            width: '100%',
            flexDirection: 'row',
            alignItems: 'center',
        },
    })
