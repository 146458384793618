//
//  ChooseColumnToAddCondition.web.tsx
//
//  Created by thaitd96 on 2022-09-30 14:01.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React, { useRef } from 'react'
import { observer } from 'mobx-react-lite'
import {
    CollectionFilterStore,
    Columns,
    DataType,
} from '../CollectionFilterStore'
import { AddConditionButton } from '../AddConditionButton'
import { randomVariableName } from '../../../../../utils/integrate-formula/Alias'
import {
    Menu,
    MenuOptions,
    MenuTrigger,
    renderers,
} from 'react-native-popup-menu'
import { iconDataTypes } from '../../../../../assets/icons/icon-data-types'
import { appFormulaId } from '../../../../../database/type'
import ContextMenuOptions from '../../../../../components/context-menu/ContextMenuOptions.web'
const { Popover } = renderers

export const ChooseColumnToAddCondition = observer(
    (props: {
        collectionFilterStore: CollectionFilterStore
        columns: Columns
    }) => {
        const ref = useRef(undefined)
        const onChangeSelection = (item: Record<string, unknown>) => {
            props.collectionFilterStore.addFilteredColumn({
                key: randomVariableName(),
                columnName: item['columnName'] as string,
                dataType: item['dataType'] as DataType,
                columnNameRaw: item['columnNameRaw'] as string,
            })
            ref?.current?.close()
        }

        return (
            <Menu
                ref={ref}
                renderer={Popover}
                rendererProps={{
                    placement: 'top',
                }}
            >
                <MenuTrigger>
                    <AddConditionButton />
                </MenuTrigger>
                <MenuOptions
                    renderOptionsContainer={() => (
                        <ContextMenuOptions
                            actions={Object.entries(props.columns)
                                .filter((item) => item[0] !== appFormulaId)
                                .map((item) => ({
                                    columnName: item[0],
                                    dataType: item[1],
                                }))}
                            getIcon={getIconFromType}
                            getText={getText}
                            onSelect={onChangeSelection}
                        />
                    )}
                />
            </Menu>
        )
    }
)

const getIconFromType = (item: Record<string, unknown>): unknown => {
    switch (item['dataType']) {
        case 'String':
            return iconDataTypes['string']
        case 'Boolean':
            return iconDataTypes['boolean']
        case 'DateTime':
            return iconDataTypes['date']
        case 'List':
            return iconDataTypes['list']
        case 'Number':
            return iconDataTypes['number']
        default:
            return ''
    }
}

const getText = (item: Record<string, unknown>): string => {
    return item['columnName'] as string
}
