const screenNames = {
    SECTION_DETAIL: 'SectionDetails',
    TAB_NAVIGATOR: 'TabNavigator',
    COLLECTION_PAGE: 'CollectionPage',
    WELCOME_PAGE: 'WelcomePage',
    SIGN_IN: 'SignIn',
    SIGN_UP: 'SignUp',
    RESET_PASSWORD: 'ResetPassword',
    VERIFICATION: 'Verification',
    VERIFICATION_SUCCESS: 'VerifySuccess',
    CUSTOMIZE_PROFILE: 'CustomizeProfile',
    UNAUTHORIZED_SCREEN: 'UnauthorizedScreen',
    SETUP_PASSWORD: 'SetupPassword',
    FORM_PAGE: 'FormPage',
    FORM_SCREEN: 'FormScreen',
    FORM_OPTIONS: 'FormOptions',
    BARCODE_SCANNER: 'BarcodeScanner',
    PEOPLE_LOOKUP: 'PeopleLookup',
    LOCATION_SEARCHING: 'LocationSearching',
    LOOKUP_OPTIONS: 'LookupOptions',
    SORT_COLLECTION: 'SortCollection',
    COLLECTION_SCREEN: 'CollectionScreen',
    BARCODE_SCANNER_SCREEN: 'BarcodeScannerScreen',
    COLLECTION_FILTER_SCREEN: 'CollectionFilterScreen',
    DETAIL_SCREEN: 'DetailScreen',
    CUSTOM_SCREEN: 'CustomScreen',
    MORE_SCREEN: 'MoreScreen',
    SETTING_SCREEN: 'SettingScreen',
    EDIT_PROFILE_SCREEN: 'EditProfileScreen',
    TEST_FORMULA: 'TestFormula',
}

export default screenNames
