import { View, StyleSheet, Text, Pressable, Image } from 'react-native'
import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useMemo,
} from 'react'
import FastImage from '../../../../components/fast-image/FastImage'
import image from '../../../../assets/icons'
import { Theme, useTheme } from '../../../../styles/theme'
import { IOption } from '.'

export interface IOptionSingleChoiceItem {
    checked: boolean
    unCheck: () => void
}

const OptionSingleChoiceItem = forwardRef(
    (props: { item: IOption; changeSelected: (id: string) => void }, ref) => {
        const { item, changeSelected } = props
        const [checked, setChecked] = React.useState(false)

        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])

        useImperativeHandle(ref, () => ({
            unCheck: () => {
                return unCheck()
            },
            checked,
        }))

        const handleCheck = () => {
            setChecked(!checked)
        }

        const unCheck = () => {
            setChecked(false)
        }

        useEffect(() => {
            if (checked) {
                changeSelected && changeSelected(item.id)
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [checked])

        return (
            <Pressable onPress={handleCheck}>
                <View style={styles.viewBooleanFieldCheckBox}>
                    <View style={styles.wrapImg}>
                        {checked ? (
                            <View>
                                <Image
                                    style={styles.imgCheckbox}
                                    source={image.radioChecked}
                                    resizeMode={'contain'}
                                />
                            </View>
                        ) : (
                            <Image
                                style={styles.imgUnCheckbox}
                                source={image.radioUncheck}
                                resizeMode={'contain'}
                            />
                        )}
                    </View>
                    <Text style={styles.title}>{item.content}</Text>
                </View>
            </Pressable>
        )
    }
)

OptionSingleChoiceItem.displayName = 'OptionSingleChoiceItem'

export default OptionSingleChoiceItem

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        viewBooleanFieldCheckBox: {
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            paddingVertical: 12,
        },

        title: {
            fontWeight: '400',
            fontSize: 15,
            lineHeight: 18,
            color: colorScheme.labelPrimary,
        },

        wrapImg: {
            marginRight: 12,
        },

        imgCheckbox: {
            height: 20,
            width: 20,
            tintColor: colorScheme.accentColor,
        },

        imgCheck: { height: 20, width: 20, position: 'absolute' },

        imgUnCheckbox: { height: 20, width: 20 },
    })
