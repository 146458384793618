export interface IAppPreferenceContext {
    themeName: string;
    changeTheme: (name: string) => void;
    languageCode: string;
    changeLanguageCode: (languageCode: string) => void;
}

export interface ResponseNotification {
    countUnreadNotification: number;
    notifications: Array<Notification>;
}

export interface Notification {
    content: string;
    id: string;
    notificationType: string;
    read: boolean;
    sendDate: string;
    title: string;
}

export interface ButtonDialog {
    label: string;
    color?: string;
    onPress?: () => unknown;
}

export interface UserInfo {
    id: string;
    username: string;
    fullName: string;
    phoneNumber: string;
    email: string;
    gender: string;
}

export interface AppResponse {
    success: boolean;
    code: number;
    message?: string;
}

export enum DesktopOS {
    windows = 'Windows',
    macOs = 'Mac OS'
}
