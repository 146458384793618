import { Nullable } from '@appformula/shared-foundation-x'
import { Q } from '@nozbe/watermelondb'
import { BooleanExtCondition } from 'packages/app-descriptor/src/lib/prefilter/booleanExt/BooleanExtCondition'
import { CategoryExtCondition } from 'packages/app-descriptor/src/lib/prefilter/categoryExt/CategoryExtCondition'
import { OptionValues } from 'packages/app-descriptor/src/lib/prefilter/common/OptionValues'
import { ContextVariable } from 'packages/app-descriptor/src/lib/prefilter/common/ContextVariable'
import {
    DateTimeComparableValue,
    DateTimeExtCondition,
    DateTimeRangeValue,
} from 'packages/app-descriptor/src/lib/prefilter/datetimeExt/DateTimeExtCondition'
import {
    NumberExtCondition,
    RangeValue,
} from 'packages/app-descriptor/src/lib/prefilter/numberExt/NumberExtCondition'
import { StringExtCondition } from 'packages/app-descriptor/src/lib/prefilter/stringExt/StringExtCondition'
import {
    addDaysWithUnit,
    ConvertDateToDateQuery,
    getFirstLastDayOfUnit,
    subtractDaysWithUnit,
} from '../date-time/DateTime'
import {
    Binding,
    BindingValue,
    ColumnBinding,
    ColumnBindingClause,
    ContextualMappingBinding,
    DataSourceFilter,
    Filter,
    FormulaBinding,
    FormulaFilterBinding,
} from '@appformula/app-descriptor'
import { FormulaManager } from '../integrate-formula/FormulaManager'
import { TransformBinding } from '../transform-binding/TransformBinding'

export type TransformPreFilterType = {
    clause: Nullable<Q.Clause>
    type: ColumnBindingClause
}

export const TransformPreFilterNumber = async (
    columnRef: string,
    condition: NumberExtCondition
): Promise<Nullable<Q.Clause>> => {
    switch (condition.method) {
        case 'less_than': {
            return Q.where(columnRef, Q.lt(condition.conditionValue as number))
        }
        case 'greater_than': {
            return Q.where(columnRef, Q.gt(condition.conditionValue as number))
        }
        case 'at_least': {
            return Q.where(columnRef, Q.gte(condition.conditionValue as number))
        }
        case 'at_most': {
            return Q.where(columnRef, Q.lte(condition.conditionValue as number))
        }
        case 'between': {
            const conditionValue = condition.conditionValue as RangeValue
            return Q.where(
                columnRef,
                Q.between(conditionValue.min, conditionValue.max)
            )
        }
        case 'equals': {
            return Q.where(columnRef, Q.eq(condition.conditionValue as number))
        }
        case 'not_equals': {
            return Q.where(
                columnRef,
                Q.notEq(condition.conditionValue as number)
            )
        }
    }
}
export const TransformPreFilterString = async (
    columnRef: string,
    condition: StringExtCondition,
    formulaManager?: FormulaManager,
    itemData?: Record<string, unknown>
): Promise<Nullable<Q.Clause>> => {
    let conditionValue: string = ''
    let selectedOptions: string[] = []

    switch (condition.conditionValueType) {
        case 'ContextVariable': {
            const contextVariableValue =
                condition.conditionValue as ContextVariable

            if (
                contextVariableValue.contextVariableId &&
                contextVariableValue.contextVariablePropsId
            ) {
                const formulaBinding: ContextualMappingBinding = {
                    source: 'contexualMapping',
                    valueTypeName: 'string',
                    objectType: contextVariableValue.contextVariableId,
                    mappingPropName:
                        contextVariableValue.contextVariablePropsId,
                }

                const value = await TransformBinding(
                    formulaBinding,
                    formulaManager,
                    itemData
                )

                if (value) {
                    conditionValue = value.toString()
                }
            }
            break
        }
        case 'OptionValues': {
            selectedOptions = Object.values(
                (condition.conditionValue as OptionValues) ?? {}
            )
                .filter((item) => item.selected)
                .map((item) => item.value.toString())
            break
        }
        case 'rawString':
        default:
            conditionValue = condition.conditionValue?.toString() ?? ''
            break
    }

    switch (condition.method) {
        case 'contains':
            return Q.where(
                columnRef,
                Q.like(`%${Q.sanitizeLikeString(conditionValue)}%`)
            )
        case 'does_not_contain':
            return Q.where(
                columnRef,
                Q.notLike(`%${Q.sanitizeLikeString(conditionValue)}%`)
            )
        case 'equals':
            return Q.where(columnRef, Q.eq(conditionValue))
        case 'not_equals':
            return Q.where(columnRef, Q.notEq(conditionValue))
        case 'starts_with':
            return Q.where(
                columnRef,
                Q.like(`${Q.sanitizeLikeString(conditionValue)}%`)
            )
        case 'not_starts_with':
            return Q.where(
                columnRef,
                Q.notLike(`${Q.sanitizeLikeString(conditionValue)}%`)
            )
        case 'ends_with':
            return Q.where(
                columnRef,
                Q.like(`%${Q.sanitizeLikeString(conditionValue)}`)
            )
        case 'not_ends_with':
            return Q.where(
                columnRef,
                Q.notLike(`%${Q.sanitizeLikeString(conditionValue)}`)
            )
        case 'is_empty':
            return Q.or(
                Q.where(columnRef, Q.eq('')),
                Q.where(columnRef, Q.eq(null))
            )
        case 'is_not_empty':
            return Q.and(
                Q.where(columnRef, Q.notEq('')),
                Q.where(columnRef, Q.notEq(null))
            )
        case 'contains_any_of': {
            if (selectedOptions.length > 0) {
                return Q.where(columnRef, Q.oneOf(selectedOptions))
            }
            return undefined
        }
        case 'not_contains_any_of': {
            if (selectedOptions.length > 0) {
                return Q.where(columnRef, Q.notIn(selectedOptions))
            }
            return undefined
        }
    }
}
export const TransformPreFilterBoolean = async (
    columnRef: string,
    condition: BooleanExtCondition
): Promise<Nullable<Q.Clause>> => {
    return Q.where(columnRef, Q.eq(condition.method))
}
export const TransformPreFilterDateTime = async (
    columnRef: string,
    condition: DateTimeExtCondition
): Promise<Nullable<Q.Clause>> => {
    switch (condition.method) {
        case 'between': {
            if (condition.conditionValueType === 'DateTimeRangeValue') {
                const conditionValue =
                    condition.conditionValue as DateTimeRangeValue

                if (conditionValue.prefixRange === 'last') {
                    const startDate = subtractDaysWithUnit(
                        conditionValue.value,
                        conditionValue.unit
                    )
                    return Q.where(
                        columnRef,
                        Q.gte(ConvertDateToDateQuery(startDate))
                    )
                } else if (conditionValue.prefixRange === 'next') {
                    const endDate = addDaysWithUnit(
                        conditionValue.value,
                        conditionValue.unit
                    )
                    return Q.where(
                        columnRef,
                        Q.lte(ConvertDateToDateQuery(endDate))
                    )
                }
            }
            return undefined
        }
        case 'compare': {
            if (condition.conditionValueType === 'DateTimeComparableValue') {
                const conditionValue =
                    condition.conditionValue as DateTimeComparableValue
                const firstLastDay = getFirstLastDayOfUnit(
                    conditionValue.comparableValue,
                    conditionValue.comparableUnit
                )
                if (conditionValue.prefixComparable === 'same') {
                    return Q.and(
                        Q.where(
                            columnRef,
                            Q.gte(ConvertDateToDateQuery(firstLastDay.First))
                        ),
                        Q.where(
                            columnRef,
                            Q.lte(ConvertDateToDateQuery(firstLastDay.Last))
                        )
                    )
                } else if (conditionValue.prefixComparable === 'different') {
                    return Q.or(
                        Q.where(
                            columnRef,
                            Q.lt(ConvertDateToDateQuery(firstLastDay.First))
                        ),
                        Q.where(
                            columnRef,
                            Q.gt(ConvertDateToDateQuery(firstLastDay.Last))
                        )
                    )
                }
            }
            return undefined
        }
    }
}
export const TransformPreFilterCategory = async (
    columnRef: string,
    condition: CategoryExtCondition
): Promise<Nullable<Q.Clause>> => {
    switch (condition.method) {
        case 'is_one_of': {
            const conditionValue = condition?.values as OptionValues
            const selectedOptions = Object.values(conditionValue ?? {})
                .filter((item) => item.selected)
                .map((item) => item.value)

            if (selectedOptions.length > 0) {
                return Q.where(columnRef, Q.oneOf(selectedOptions))
            }
            return undefined
        }
        case 'is_not_one_of': {
            const conditionValue = condition?.values as OptionValues
            const selectedOptions = Object.values(conditionValue ?? {})
                .filter((item) => item.selected)
                .map((item) => item.value)

            if (selectedOptions.length > 0) {
                return Q.where(columnRef, Q.notIn(selectedOptions))
            }
            return undefined
        }
    }
}

export const TransformWithDataType = async (
    bindingItem: ColumnBinding,
    formulaManager?: FormulaManager,
    itemData?: Record<string, unknown>
): Promise<Nullable<Q.Clause>> => {
    const dataType = bindingItem.dataType
    const columnRef = bindingItem.columnRef
    const condition = bindingItem.condition

    switch (dataType) {
        case 'Number': {
            return TransformPreFilterNumber(
                columnRef,
                condition as NumberExtCondition
            )
        }
        case 'String': {
            return TransformPreFilterString(
                columnRef,
                condition as StringExtCondition,
                formulaManager,
                itemData
            )
        }
        case 'Boolean': {
            return TransformPreFilterBoolean(
                columnRef,
                condition as BooleanExtCondition
            )
        }
        case 'DateTime': {
            return TransformPreFilterDateTime(
                columnRef,
                condition as DateTimeExtCondition
            )
        }
        case 'List': {
            return TransformPreFilterCategory(
                columnRef,
                condition as CategoryExtCondition
            )
        }
    }
}

export const TransformPreFilter = async (
    itemFilter: Filter,
    formulaManager?: FormulaManager,
    itemData?: Record<string, unknown>
): Promise<Nullable<Q.Clause>> => {
    const filterBinding = Object.values(itemFilter?.binding ?? {}).sort(
        (lhs: ColumnBinding, rhs: ColumnBinding) => {
            const lhsRank = lhs.priority ?? ''
            const rhsRank = rhs.priority ?? ''
            return lhsRank.localeCompare(rhsRank)
        }
    ) as ColumnBinding[]

    let groupingCondition: Nullable<Q.Clause>

    for (const bindingItem of filterBinding) {
        const bindingIndex: number = filterBinding.indexOf(bindingItem)
        const bindingClause = await TransformWithDataType(
            bindingItem,
            formulaManager,
            itemData
        )
        if (bindingClause) {
            if (bindingIndex === 0 || !groupingCondition) {
                groupingCondition = bindingClause
            } else {
                if (bindingItem.filterClause === 'or') {
                    groupingCondition = Q.or(
                        groupingCondition as Q.Where,
                        bindingClause as Q.Where
                    )
                } else {
                    groupingCondition = Q.and(
                        groupingCondition as Q.Where,
                        bindingClause as Q.Where
                    )
                }
            }
        }
    }

    return groupingCondition
}

export const TransformGroupsColumnPreFilter = async (
    preFilterData: Nullable<Record<string, Filter>>,
    formulaManager?: FormulaManager,
    itemData?: Record<string, unknown>
): Promise<Nullable<Q.Clause>> => {
    const preFilter: Filter[] = Object.values(preFilterData ?? {}).sort(
        (lhs: Filter, rhs: Filter) => {
            const lhsRank = lhs.priority ?? ''
            const rhsRank = rhs.priority ?? ''
            return lhsRank.localeCompare(rhsRank)
        }
    )
    let preFilterQuery: Nullable<Q.Clause>
    if (preFilter?.length > 0) {
        for (const itemFilter of preFilter) {
            const index: number = preFilter.indexOf(itemFilter)
            const groupingCondition: Nullable<Q.Clause> =
                await TransformPreFilter(itemFilter, formulaManager, itemData)

            if (groupingCondition) {
                if (index === 0 || !preFilterQuery) {
                    preFilterQuery = groupingCondition
                } else {
                    if (itemFilter.condition === 'or') {
                        preFilterQuery = Q.or(
                            preFilterQuery as Q.Where,
                            groupingCondition as Q.Where
                        )
                    } else {
                        preFilterQuery = Q.and(
                            preFilterQuery as Q.Where,
                            groupingCondition as Q.Where
                        )
                    }
                }
            }
        }
    }

    console.info({ preFilterQuery })
    return preFilterQuery
}

export const TransformGroupsFormulaPreFilter = async (
    preFilterData: Nullable<Record<string, FormulaFilterBinding>>,
    formulaManager?: FormulaManager,
    itemData?: Record<string, unknown>
) => {
    const preFilter: FormulaFilterBinding[] = Object.values(
        preFilterData ?? {}
    ).sort((lhs: FormulaFilterBinding, rhs: FormulaFilterBinding) => {
        const lhsRank = lhs.priority ?? ''
        const rhsRank = rhs.priority ?? ''
        return lhsRank.localeCompare(rhsRank)
    })

    return preFilter
}

export const TransformGroupsPreFilter = async (
    preFilterData: Nullable<DataSourceFilter>,
    formulaManager?: FormulaManager,
    itemData?: Record<string, unknown>
): Promise<Nullable<Q.Clause>> => {
    if (preFilterData?.type === 'custom') {
        return TransformGroupsColumnPreFilter(
            preFilterData?.columnFilter,
            formulaManager,
            itemData
        )
    } else if (preFilterData?.type === 'formula') {
        // return TransformGroupsFormulaPreFilter(
        //     preFilterData?.formulaFilter,
        //     formulaManager,
        //     itemData
        // )
    }

    return undefined
}

export const TransformTableBinding = async (
    formulaManager: FormulaManager,
    binding?: Nullable<Binding<BindingValue>>,
    query?: Nullable<Q.Clause[]>
) => {
    try {
        let result
        if (binding?.source === 'formula') {
            const formulaString = (
                binding as FormulaBinding
            )?.formulaString.toString()
            // result = await formulaManager?.execute(formulaString, query)
            result = await formulaManager?.execute(formulaString)
        }

        return result
    } catch (e) {
        console.error('TransformTableBinding error---', e)
    }
}
