import { ColumnType } from '@nozbe/watermelondb'
import { DataType } from './type'

export function convertFirestoreToWMDBDataType(
    firestoreDataType: DataType
): ColumnType {
    switch (firestoreDataType) {
        case 'String':
            return 'string'
        case 'Boolean':
            return 'boolean'
        case 'DateTime':
            return 'string'
        case 'List':
            return 'string'
        case 'Number':
            return 'number'
        default:
            return 'string'
    }
}
