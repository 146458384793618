import firebase from 'firebase'
import RNfirestore, {
    FirebaseFirestoreTypes,
} from '@react-native-firebase/firestore'
import { Platform } from 'react-native'
import initializeApp from '../firebase-init/FirebaseInit'
import initializeAppSDK from '../firebase-init/FirebaseInit.web'
import cache from '../../cache'

// Force return type is ReactNativeFirebase.Module to unified type in app-react-native
const getFireStore = async (): Promise<FirebaseFirestoreTypes.Module> => {
    if (Platform.OS === 'web') {
        await initializeAppSDK()
        const firestore = firebase.firestore()
        try {
            await firestore.enablePersistence({ synchronizeTabs: true })
        } catch (error) {
            //
        }
        return firestore as unknown as FirebaseFirestoreTypes.Module
    } else if (cache.sourceStartUp === 'APP_FORMULA_GO') {
        await initializeAppSDK()
        const firestore = firebase.firestore()
        return firestore as unknown as FirebaseFirestoreTypes.Module
    } else {
        await initializeApp()
        const firestore = RNfirestore()
        try {
            await firestore.settings({
                persistence: true,
                cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
            })
        } catch (error) {
            //
        }
        return firestore
    }
}

export default getFireStore
