import { action, makeObservable, observable } from 'mobx'
import {
    ChartStore,
    ComponentStore,
    DesignPageStore,
    DetailsPageStore,
    MapStore,
} from '@appformula/app-studio-core'
import { Nullable } from '@appformula/shared-foundation-x'
import { ARNChartStore } from './components/Chart/ARNChartStore'
import { IDatabaseContext } from '../../context/AppDatabaseContext'
import { ARNMapStore } from './components/Map/ARNMapStore'
import { IDataSourceCord } from '../../components/ScrollViewHighLight'
import { ScrollViewHighLightStore } from '../../components/ScrollViewHighLight/ScrollViewHighLightStore'
import { Observable } from 'rxjs'

export type RNAudioType = {
    title: string
    audioURL: string
}

export type RNBarCodeType = {
    title: string
    barcode: string
}

export type RNBooleanInputType = {
    title: string
    initialValue: boolean
}

export type RNButtonType = {
    title: string
    color: string
}

export type RNDateRangeInputType = {
    title: string
    initialStart: Date
    initialEnd: Date
}

export type RNDateTimeInputType = {
    title: string
    initialValue: Date
}

export type RNFilePreviewType = {
    fileUrl: string
}

export type RNImageType = {
    url: string
}

export type RNMapType = {
    address: string
    calloutTitle?: string
    calloutSubtitle?: string
}

export type RNMarkdownType = {
    text: string
}

export type RNNumberScaleInputType = {
    title: string
    initialValue: number
}

export type RNNumberSliderInputType = {
    title: string
    maxValue: number
    minValue: number
    initialValue: number
}

export type RNOptionsInputType = {
    title: string
}

export type RNPeopleInputType = {
    title: string
}

export type RNProgressBarType = {
    progress: number
}

export type RNTextType = {
    text: string
}

export type RNTextInputType = {
    placeholder: string
    initialText: string
}

export type RNVideoType = {
    videoURL: string
    title: string
    thumbnailURL: string
}

export type RNWebLinkType = {
    url: string
}

export type RNChartType = {
    key: string
}

export type ChildComponentType =
    | RNAudioType
    | RNBarCodeType
    | RNBooleanInputType
    | RNButtonType
    | RNDateRangeInputType
    | RNDateTimeInputType
    | RNFilePreviewType
    | RNImageType
    | RNMapType
    | RNMarkdownType
    | RNNumberScaleInputType
    | RNNumberSliderInputType
    | RNOptionsInputType
    | RNPeopleInputType
    | RNProgressBarType
    | RNTextType
    | RNTextInputType
    | RNVideoType
    | RNWebLinkType
    | RNChartType

class ChildComponent {
    data: Record<string, unknown> = {}

    constructor() {
        makeObservable(this, {
            data: observable,
            setData: action,
        })
    }

    setData(data: ChildComponentType | { [x: string]: unknown }) {
        this.data = data
    }
}
export type ComponentData = Record<string, ChildComponentType>

export type ChildComponentStore = ARNChartStore | ChildComponent

interface ICachedMapStore {
    [key: string]: ARNMapStore
}

export class ARNCustomDetailPageStore {
    detailPageId: string
    data: Record<string, unknown> = {}

    componentData: ComponentData = {}
    pageStore: Nullable<DetailsPageStore> = undefined
    databaseAction: IDatabaseContext

    dataSourceCords: IDataSourceCord = {}

    private cachedMapStore: ICachedMapStore = {}
    cacheScrollViewHighLightStore: ScrollViewHighLightStore

    constructor(detailPageId: string, databaseAction: IDatabaseContext) {
        this.detailPageId = detailPageId
        this.databaseAction = databaseAction
        makeObservable(this, {
            data: observable,
            dataSourceCords: observable,

            setData: action,
            setDataSourceCords: action,

            componentData: observable,
        })
    }

    setDataSourceCords(newCord: IDataSourceCord) {
        this.dataSourceCords = { ...this.dataSourceCords, ...newCord }
    }

    setPageStore(pageStore: DetailsPageStore) {
        this.pageStore = pageStore
    }

    setData(data: ChildComponentType) {
        this.data = data
    }

    async getAndSetData(tableId: string, itemKey: string) {
        try {
            const result = await this.databaseAction.getItemByKey(
                tableId,
                itemKey
            )
            this.setData(result as unknown as ChildComponentType)
        } catch (error) {
            console.info('error----', error)
        }
    }

    childComponentStore(componentId: string): ChildComponent {
        // Implement this
        return new ChildComponent()
    }

    childComponentDetailStore(componentStore: ComponentStore) {
        switch (componentStore.componentType) {
            case 'chart':
                return new ARNChartStore(this, componentStore as ChartStore)

            case 'map':
                return this.getArnMapStore(componentStore)

            default:
                return new ChildComponent()
        }
    }

    getArnMapStore(componentStore: ComponentStore): ARNMapStore {
        if (this.cachedMapStore?.[componentStore.componentId]) {
            return this.cachedMapStore[componentStore.componentId]
        } else {
            const newMapStore = new ARNMapStore(
                this,
                componentStore as MapStore,
                componentStore.componentId
            )
            this.cachedMapStore[componentStore.componentId] = newMapStore
            return newMapStore
        }
    }

    getScrollViewHighLightStore(designPageStore: DesignPageStore): ScrollViewHighLightStore {
        if (this.cacheScrollViewHighLightStore) {
            return this.cacheScrollViewHighLightStore
        } else {
            const newScrollViewHighLightStore = new ScrollViewHighLightStore(
                this.detailPageId,
                designPageStore,
            )
            this.cacheScrollViewHighLightStore = newScrollViewHighLightStore
            return newScrollViewHighLightStore
        }
    }
}
