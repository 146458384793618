import {
    LayoutChangeEvent,
    NativeScrollEvent,
    NativeSyntheticEvent,
    StyleSheet,
    View,
} from 'react-native'
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
} from 'react'
import {
    KeyboardAwareScrollView,
    KeyboardAwareScrollViewProps,
} from 'react-native-keyboard-aware-scroll-view'
import appConstants from '../../constants/const'
import { Theme, useTheme } from '../../styles/theme'
import _, { isEmpty } from 'lodash'
import { DesignPageContext } from '../../context/DesignPageContext'
import {
    DesignPageStore,
    InspectorPageComponentSelection,
} from '@appformula/app-studio-core/src'
import { observer } from 'mobx-react-lite'
import { ScrollViewHighLightStore } from './ScrollViewHighLightStore'

export interface IDataSourceCord {
    [key: string]: {
        y: number
        height: number
    }
}

interface Props {
    ScrollViewProps?: KeyboardAwareScrollViewProps
    pageId: string
    designPageStore: DesignPageStore
    scrollViewHighLightStore: ScrollViewHighLightStore
    data: string[]
    renderItems: (item: string, index: number) => React.ReactElement
}

const ScrollViewHighLight = observer((props: Props) => {
    const { ScrollViewProps, scrollViewHighLightStore, data, renderItems } =
        props
    const scrollViewRef = useRef<KeyboardAwareScrollView>()
    const theme = useTheme()
    const styles = useMemo(() => createStyles(theme), [theme])
    const designPageStore = useContext(DesignPageContext)
    const tempDataCoord = useRef<IDataSourceCord>()

    console.info('data===>>', data, renderItems)

    const scrollToHighLight = useCallback((componentId) => {
        if (!_.isEmpty(scrollViewHighLightStore.dataSourceCords)) {
            scrollViewRef &&
                scrollViewRef.current.scrollToPosition(
                    0,
                    scrollViewHighLightStore.dataSourceCords[componentId].y,
                    true
                )
        }
    }, [])

    useEffect(() => {
        const subscriptions =
            scrollViewHighLightStore.startListeningDataChange()

        return function clean() {
            subscriptions.forEach((sub) => sub.unsubscribe())
        }
    }, [scrollViewHighLightStore])

    useEffect(() => {
        if (!designPageStore) {
            return
        }
        if (
            designPageStore.inspectorItemSelection instanceof
            InspectorPageComponentSelection
        ) {
            const componentId =
                designPageStore.inspectorItemSelection.componentId

            scrollToHighLight(componentId)
        }
    }, [
        designPageStore,
        scrollToHighLight,
        designPageStore?.inspectorItemSelection,
    ])

    useEffect(() => {
        scrollViewHighLightStore.setIsDroppedComponent(
            props?.designPageStore?.isDroppedComponent
        )
    }, [props?.designPageStore?.isDroppedComponent])

    const onScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
        const { y } = event.nativeEvent.contentOffset
        scrollViewHighLightStore.setYOffset(y)
    }

    const onLayout = useCallback(
        (componentId: string, index: number) => (event: LayoutChangeEvent) => {
            const { layout } = event.nativeEvent
            const newDataSourceCords: IDataSourceCord = {}
            newDataSourceCords[componentId] = {
                y: layout.y,
                height: layout.height,
            }
            tempDataCoord.current = {
                ...tempDataCoord.current,
                ...newDataSourceCords,
            }
            if (index === data.length - 1) {
                scrollViewHighLightStore.setDataSourceCords(
                    tempDataCoord.current
                )
                tempDataCoord.current = null
            }
        },
        [data.length, scrollViewHighLightStore]
    )

    const Items = useCallback(
        ({ item, index }: { item: string; index: number }) => {
            return (
                <View onLayout={onLayout(item, index)}>
                    {renderItems && renderItems(item, index)}
                </View>
            )
        },
        [onLayout, renderItems]
    )

    return (
        <KeyboardAwareScrollView
            ref={scrollViewRef}
            style={styles.viewCustom}
            extraScrollHeight={200}
            removeClippedSubviews
            onScroll={onScroll}
            {...ScrollViewProps}
        >
            {!isEmpty(scrollViewHighLightStore) &&
                !isEmpty(data) &&
                data.map((item, index) => (
                    <Items key={`${index}`} item={item} index={index} />
                ))}
        </KeyboardAwareScrollView>
    )
})

export default ScrollViewHighLight

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
            backgroundColor: colorScheme.backgroundL1,
        },
        contentScroll: {
            paddingHorizontal: appConstants.PADDING_COMMON,
            paddingTop: 16,
            paddingBottom: 8,
        },
        viewCustom: {
            flex: 1,
            backgroundColor: colorScheme.backgroundL1,
        },
    })
