import { apiClient } from '../index'
import appConstants from '../../../constants/const'
import packageJson from '../../../../package.json'
import { Nullable } from '@appformula/shared-foundation-x/src'

export type DescriptorData = {
    data: {
        descriptorId: string
        appId: string
        descriptorContent: string
        bundleVersion: string
        createdAt: string
    }
}

export const getLatestCompatibleAppDescriptor = async (
    appId: string
): Promise<Nullable<DescriptorData>> => {
    const data = {
        bundleVersion: packageJson.version,
        appId,
    }
    try {
        const response = await apiClient.get(
            `${appConstants.API.BASE_URL_APP_DESCRIPTOR}/api/v1/descriptor/getLatestCompatibleAppDescriptor`,
            {
                data,
                isHandleError: false,
                needSetApiPath: false,
            }
        )
        return response?.data as DescriptorData
    } catch (error) {
        throw new Error('getLatestCompatibleAppDescriptor')
    }
}
