import React from 'react'
import { CustomPageStore } from '@appformula/app-studio-core/src'
import { BottomTabScreenProps } from '@react-navigation/bottom-tabs'
import { observer } from 'mobx-react-lite'
import { useContext } from 'react'
import { AppDescriptorContext } from '../../context/AppDescriptorContext'
import { CustomPage } from './CustomPage'
import { TabParamList } from '../main/tab-view/TabView'
import { ARNCustomDetailPageStore } from './ARNCustomDetailPageStore'
import { MainPageDataStoreContext } from '../../context/MainPageDataStoreContext'
import { useDatabaseAction } from '../../context/AppDatabaseContext'
type Props = BottomTabScreenProps<TabParamList>

interface IParams {
    pageId: 'string'
}

export const CustomPageRoute = ({ route }: Props) => {
    const store = useContext(AppDescriptorContext)
    const mainPageDataStore = React.useContext(MainPageDataStoreContext)
    const { pageId } = route.params as IParams
    const pageStore = store.pageStore(pageId)
    const databaseAction = useDatabaseAction()
    
    return (
        <CustomPageContent
            pageStore={pageStore as CustomPageStore}
            arnCustomDetailPageStore={
                mainPageDataStore.arnCustomDetailPageStore(
                    pageId,
                    databaseAction,
                ) as ARNCustomDetailPageStore
            }
        />
    )
}

const CustomPageContent = observer(
    (props: {
        pageStore: CustomPageStore
        arnCustomDetailPageStore: ARNCustomDetailPageStore
    }) => {
        return (
            <CustomPage
                pageStore={props.pageStore}
                arnCustomDetailPageStore={props.arnCustomDetailPageStore}
            />
        )
    }
)
