//
//  BarcodeScanner.tsx
//
//  Created by thaitd on 2022-06-27 17:53.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React, { useEffect } from 'react'
import { BarcodeScannerFormInputStore } from '@appformula/app-studio-core/src'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { Image, Pressable, StyleSheet, Text, View } from 'react-native'
import { Theme, useTheme } from '../../../../styles/theme'
import appConstants from '../../../../constants/const'
import image from '../../../../assets/icons'
import { hexToRGBA } from '../../../../utils'
import { useNavigation } from '@react-navigation/native'
import screenNames from '../../../../navigations/screenNames'
import { ARNFormPageStore } from '../../ARNFormPageStore'
import { ARNBarCodescannerFormStore } from './ARNBarCodescannerFormStore'
import { useBinding } from '../../../collection/components/hooks/useBinding'

export const BarcodeScannerFormInput = observer(
    (props: {
        barcodeScannerFormInput: BarcodeScannerFormInputStore
        arnBarCodescannerFormStore: ARNBarCodescannerFormStore
        arnFormPageStore: ARNFormPageStore
    }) => {
        const { barcodeScannerFormInput, arnBarCodescannerFormStore } = props
        const isHaveData = !!arnBarCodescannerFormStore.value
        const theme = useTheme()
        const styles = useMemo(
            () => createStyles(theme, isHaveData),
            [theme, isHaveData]
        )
        const isHidden = useBinding(
            barcodeScannerFormInput.isHidden
        )

        const navigation = useNavigation()

        useEffect(() => {
            const subscriptions =
                arnBarCodescannerFormStore.startListeningEvents()

            return () => {
                subscriptions.forEach((s) => s.unsubscribe())
            }
        }, [arnBarCodescannerFormStore])

        const onPressIcon = () => {
            navigation.navigate(
                screenNames.BARCODE_SCANNER as never,
                { componentId: barcodeScannerFormInput.componentId } as never
            )
        }

        return isHidden ? null : (
            <View style={styles.wrap}>
                <View>
                    <View style={styles.title}>
                        <Text style={styles.textTitle}>
                            {barcodeScannerFormInput.titleName}
                        </Text>
                        {barcodeScannerFormInput.required && (
                            <View style={styles.requireDot}></View>
                        )}
                    </View>
                    {isHaveData && (
                        <Text style={styles.textData}>
                            {arnBarCodescannerFormStore.value}
                        </Text>
                    )}
                </View>
                <Pressable style={styles.barCodeButton} onPress={onPressIcon}>
                    <Image
                        style={styles.iconBarCode}
                        source={image.iconBarCode}
                        resizeMode={'contain'}
                    ></Image>
                </Pressable>
            </View>
        )
    }
)

const createStyles = ({ colorScheme, roundness }: Theme, isHaveData: boolean) =>
    StyleSheet.create({
        wrap: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderBottomWidth: 1,
            borderBottomColor: colorScheme.separator,
            paddingVertical: 15,
            paddingHorizontal: appConstants.PADDING_COMMON,
        },
        textData: { marginTop: 8, color: colorScheme.labelPrimary },
        iconBarCode: {
            tintColor: colorScheme.accentColor,
            height: 34,
            width: 34,
        },
        barCodeButton: {
            backgroundColor: hexToRGBA(colorScheme.accentColor as string, 0.1),
            padding: 2,
            height: 38,
            width: 38,
            borderRadius: roundness,
        },
        textTitle: isHaveData
            ? {
                  color: colorScheme.labelPrimary,
                  fontWeight: '500',
                  fontSize: 12,
              }
            : {
                  color: colorScheme.labelSecondary,
                  fontWeight: '500',
                  fontSize: 15,
              },
        title: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        requireDot: {
            height: 6,
            width: 6,
            backgroundColor: 'red',
            borderRadius: 6,
            marginLeft: 6,
        },
    })
