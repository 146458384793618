import { View, Text, Image, Pressable, StyleSheet } from 'react-native'
import React, { createRef, useEffect, useMemo, useState } from 'react'
import image from '../../../../../assets/icons'
import { Theme, useTheme } from '../../../../../styles/theme'
import { observer } from 'mobx-react-lite'
import { useNavigation, useRoute } from '@react-navigation/native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import MultiChoiceItem, { IMultiChoiceItem } from './MultiChoiceItem'
import {
    ARNFormPageStore,
    LookupFormData,
    LookupFormInputExtend,
} from '../../../ARNFormPageStore'
import SingleChoiceItem, { ISingleChoiceItem } from './SingleChoiceItem'
import { LookupFormInput } from '@appformula/app-descriptor/src'
import AppTextInput from '../../../../../components/AppTextInput'
import {
    FormPageStore,
    LookupFormInputStore,
} from '@appformula/app-studio-core/src'
import { ARNLookupFormStore } from '../ARNLookupFormStore'

export type ILookup = {
    id: string
    content: string
}

interface ILookupRef {}

type Params = {
    componentId: string
}

const availableLookupsMocked = ['Option 1', 'Option 2', 'Option 3', 'Option 4']

const LookupOptionsPage = observer(
    (props: {
        arnFormPageStore: ARNFormPageStore
        pageStore: FormPageStore
    }) => {
        const { arnFormPageStore, pageStore } = props
        const route = useRoute()
        const { componentId } = route.params as Params

        const peopleLookupFormInput = pageStore.componentStore(
            componentId
        ) as LookupFormInputStore

        const arnLookupFormStore = arnFormPageStore.childComponentFormStore(
            componentId,
            peopleLookupFormInput.inputType
        ) as ARNLookupFormStore

        return (
            <LookupOptionsPageContent
                arnLookupFormStore={arnLookupFormStore}
                listOptionAvailble={availableLookupsMocked}
                lookupFormInput={arnLookupFormStore.lookupFormInput}
            />
        )
    }
)

const LookupOptionsPageContent = observer(
    (props: {
        arnLookupFormStore: ARNLookupFormStore
        listOptionAvailble: string[]
        lookupFormInput: LookupFormInput & LookupFormInputExtend
    }) => {
        const { listOptionAvailble, lookupFormInput, arnLookupFormStore } =
            props
        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])
        const [valueSearch, setValueSearch] = useState<string>('')
        const [data, setData] = useState<ILookup[]>([])
        const navigation = useNavigation()
        const [elRefs, setElRefs] = useState({})
        const allowsMultipleSelection = lookupFormInput?.allowsMultipleSelection
        const dataStore = arnLookupFormStore.data || {}

        useEffect(() => {
            const listDataWithId =
                listOptionAvailble.map((item: string, index: number) => ({
                    content: item,
                    id: `${item}_${index}`,
                })) || []

            setData(listDataWithId)
        }, [listOptionAvailble])

        useEffect(() => {
            const temp = {}
            data.forEach((item, index) => {
                Object.assign(temp, { [item.id]: createRef() })
            })
            setElRefs({ ...elRefs, ...temp })
            // Do not want to run when update elRefs - prevent exceed call stack
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [data])

        const onChangeText = (text: string) => {
            setValueSearch(text)
        }

        const confirmLookup = () => {
            const itemsSelected: LookupFormData = {}
            Object.keys(elRefs).forEach((id) => {
                let elRef
                if (allowsMultipleSelection) {
                    elRef = elRefs[
                        id as keyof ILookupRef
                    ] as React.RefObject<ISingleChoiceItem>
                } else {
                    elRef = elRefs[
                        id as keyof ILookupRef
                    ] as React.RefObject<IMultiChoiceItem>
                }

                if (elRef.current.checked) {
                    itemsSelected[id] = elRef.current.item
                }
            })

            arnLookupFormStore.setData(itemsSelected)
            navigation.goBack()
        }

        const _changeSelectedSingleChoice = (id: string) => {
            const elRef = elRefs[
                id as keyof ILookupRef
            ] as React.RefObject<ISingleChoiceItem>
            if (elRef?.current?.checked) {
                Object.keys(elRefs).forEach((key) => {
                    if (key !== id) {
                        const ref = elRefs[
                            key as keyof ILookupRef
                        ] as React.RefObject<ISingleChoiceItem>
                        ref?.current?.unCheck()
                    }
                })
            }
        }

        const renderItem = (item: ILookup) => {
            const isActive = Object.keys(dataStore).includes(item.id)
            if (allowsMultipleSelection) {
                return (
                    <MultiChoiceItem
                        ref={elRefs[item.id as keyof ILookupRef]}
                        isActive={isActive}
                        key={item.id}
                        item={item}
                    />
                )
            } else {
                return (
                    <SingleChoiceItem
                        ref={elRefs[item.id as keyof ILookupRef]}
                        isActive={isActive}
                        changeSelected={_changeSelectedSingleChoice}
                        key={item.id}
                        item={item}
                    />
                )
            }
        }

        return (
            <View style={styles.container}>
                <View style={styles.header}>
                    <View style={styles.wrapSeach}>
                        <Image
                            style={styles.imgSearch}
                            source={image.iconSearch}
                        />
                        <AppTextInput
                            value={valueSearch}
                            style={styles.inputSeach}
                            placeholder={'Lookup'}
                            onChangeText={onChangeText}
                        />
                    </View>
                    <Pressable onPress={confirmLookup}>
                        <Text style={styles.txtDone}>Done</Text>
                    </Pressable>
                </View>
                <KeyboardAwareScrollView
                    keyboardShouldPersistTaps={'always'}
                    keyboardDismissMode={'on-drag'}
                    enableResetScrollToCoords={false}
                    contentContainerStyle={styles.containerScroll}
                >
                    {data && data.map((item) => renderItem(item))}
                </KeyboardAwareScrollView>
            </View>
        )
    }
)

export default LookupOptionsPage

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        containerScroll: { paddingLeft: 16 },
        inputSeach: {
            flex: 1,
            fontWeight: '500',
            fontSize: 16,
            color: colorScheme.labelPrimary,
            paddingVertical: 18,
        },
        wrapSeach: { flex: 1, flexDirection: 'row', alignItems: 'center' },
        header: {
            flexDirection: 'row',
            paddingHorizontal: 12,
            alignItems: 'center',
            borderBottomColor: colorScheme.separator,
            borderBottomWidth: 1,
        },
        container: { flex: 1, backgroundColor: colorScheme.backgroundL1 },
        txtDone: {
            color: colorScheme.accentColor,
            fontWeight: '500',
            fontSize: 17,
        },
        imgSearch: {
            height: 24,
            width: 24,
            marginRight: 4,
        },
    })
