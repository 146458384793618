//
//  SignatureDrawingFormInput.web.tsx
//
//  Created by thaitd96 on 2022-06-30 14:20.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { SignatureDrawingFormInputStore } from '@appformula/app-studio-core'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import React, { Image, StyleSheet, Text, View } from 'react-native'
import { Theme, useTheme } from '../../../../styles/theme'
import appConstants from '../../../../constants/const'
import image from '../../../../assets/icons'
import SignatureCanvas from 'react-signature-canvas'
import { useBinding } from '../../../collection/components/hooks/useBinding'
import { ARNSignatureDrawingFormStore } from './ARNSignatureDrawingFormStore'
import { ARNFormPageStore } from '../../ARNFormPageStore'

export const SignatureDrawingFormInput = observer(
    (props: {
        arnSignatureDrawingFormStore: ARNSignatureDrawingFormStore
        signatureDrawingFormInput: SignatureDrawingFormInputStore
        arnFormPageStore: ARNFormPageStore
    }) => {
        const {
            signatureDrawingFormInput,
            arnFormPageStore,
            arnSignatureDrawingFormStore,
        } = props
        return (
            <SignatureDrawingFormInputContent
                signatureDrawingFormInput={signatureDrawingFormInput}
                arnFormPageStore={arnFormPageStore}
                arnSignatureDrawingFormStore={arnSignatureDrawingFormStore}
            />
        )
    }
)

export const SignatureDrawingFormInputContent = observer(
    (props: {
        arnSignatureDrawingFormStore: ARNSignatureDrawingFormStore
        signatureDrawingFormInput: SignatureDrawingFormInputStore
        arnFormPageStore: ARNFormPageStore
    }) => {
        const { signatureDrawingFormInput, arnSignatureDrawingFormStore } =
            props
        const { componentId } = signatureDrawingFormInput
        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])
        const signRef = useRef(null)
        const isHidden = useBinding(signatureDrawingFormInput?.isHidden)

        useEffect(() => {
            const subscriptions =
                arnSignatureDrawingFormStore.startListeningDataChange()
            const saveToken = arnSignatureDrawingFormStore.saveEvent.subscribe({
                next: () => {
                    if (arnSignatureDrawingFormStore.isDragged) {
                        const base64string = signRef?.current?.toDataURL()
                        arnSignatureDrawingFormStore.onSignatureSave(
                            base64string
                        )
                    } else {
                        PubSub.publish(appConstants.TOPIC_SAVE_SIGN_DONE)
                    }
                },
            })

            const clearCancel =
                arnSignatureDrawingFormStore.clearEvent.subscribe({
                    next: () => {
                        signRef?.current?.clear()
                    },
                })

            return () => {
                subscriptions.forEach((e) => e.unsubscribe())
                clearCancel.unsubscribe()
                saveToken.unsubscribe()
            }
        }, [componentId, arnSignatureDrawingFormStore])

        const onClear = useCallback(() => {
            arnSignatureDrawingFormStore.clearEvent.next()
        }, [arnSignatureDrawingFormStore.clearEvent])

        const onTouchStart = useCallback(() => {
            arnSignatureDrawingFormStore.touchStartEvent.next()
        }, [arnSignatureDrawingFormStore.touchStartEvent])

        return isHidden ? null : (
            <View style={styles.viewSignature}>
                <View style={styles.viewHeader}>
                    <View style={styles.viewTitle}>
                        <Text style={styles.title}>
                            {signatureDrawingFormInput.titleName}
                        </Text>
                        {signatureDrawingFormInput.required && (
                            <View style={styles.requireDot}></View>
                        )}
                    </View>
                    <Text style={styles.clear} onPress={onClear}>
                        {'Clear'}
                    </Text>
                </View>
                <View style={styles.signArea}>
                    <SignatureCanvas
                        ref={signRef}
                        canvasProps={{ height: 800 }}
                        onBegin={onTouchStart}
                        onEnd={() => {
                            arnSignatureDrawingFormStore.dragEvent.next()
                        }}
                    />
                    {arnSignatureDrawingFormStore.isPlaceholderVisibleValue && (
                        <Text style={styles.placeHolder}>
                            {'Draw Signature here'}
                        </Text>
                    )}
                    <Image
                        source={image.signature}
                        style={styles.iconSign}
                    ></Image>
                </View>
            </View>
        )
    }
)
const createStyles = ({ colorScheme, roundness }: Theme) =>
    StyleSheet.create({
        viewHeader: { flexDirection: 'row', justifyContent: 'space-between' },
        signature: {
            flex: 1,
        },
        iconSign: {
            height: 34,
            width: 34,
            resizeMode: 'contain',
            position: 'absolute',
            top: 8,
            right: 8,
        },
        placeHolder: {
            fontWeight: '400',
            fontSize: 15,
            color: colorScheme.labelSecondary,
            alignSelf: 'center',
            position: 'absolute',
            top: '45%',
        },
        signArea: {
            height: 200,
            borderRadius: roundness,
            borderColor: colorScheme.separator,
            borderWidth: 1,
            marginVertical: 20,
            overflow: 'hidden',
        },
        title: {
            fontWeight: '500',
            fontSize: 16,
            lineHeight: 19,
            color: colorScheme.labelPrimary,
        },
        clear: {
            fontWeight: '500',
            fontSize: 16,
            color: colorScheme.accentColor,
        },
        viewTitle: {
            flexDirection: 'row',
            flex: 1,
            alignItems: 'center',
            marginRight: 30,
        },
        requireDot: {
            height: 6,
            width: 6,
            backgroundColor: 'red',
            borderRadius: 6,
            marginLeft: 6,
        },
        viewSignature: {
            paddingTop: 25,
            borderBottomWidth: 1,
            borderBottomColor: colorScheme.separator,
            paddingHorizontal: appConstants.PADDING_COMMON,
        },
    })
