//
//  TabViewTablet.tsx
//
//  Created by thaitd96 on 2022-10-03 09:59
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import {
    AppDescriptorStore,
    CollectionPageStore,
    CustomPageStore,
    DefaultAuthenticationPageSelection,
    DefaultPageSelection,
    DesignPageStore,
    DetailsPageStore,
    FormPageStore,
    NormalPageSelection,
} from '@appformula/app-studio-core/src'
import { createDrawerNavigator } from '@react-navigation/drawer'
import { useNavigation } from '@react-navigation/native'
import screenNames from '../../../navigations/screenNames'
import { useTheme } from '../../../styles/theme'
import { View } from 'react-native'
import { CollectionPage } from '../../collection/CollectionPage'
import TabBarIconStore from '../TabBarIconStore'
import { CustomPageRoute } from '../../custom/CustomPageRoute'
import { TabParamList } from './TabView'

const TAB_WIDTH = 343

const TabViewTablet = observer(
    (props: {
        appDescriptorStore: AppDescriptorStore
        designPageStore: DesignPageStore
    }) => {
        const { appDescriptorStore, designPageStore } = props
        const list = [...appDescriptorStore.frontPageIds].map((item) => item)

        const theme = useTheme()
        const navigation = useNavigation()

        const Drawer = createDrawerNavigator()

        const BlankFormPage = () => <View />

        useEffect(() => {
            if (!designPageStore) {
                return
            }

            if (designPageStore.pageSelection instanceof NormalPageSelection) {
                const pageId = designPageStore.pageSelection.pageId
                const store = appDescriptorStore.pageStore(pageId)

                if (store instanceof CollectionPageStore) {
                    navigation.navigate(store.pageId as never)
                } else if (store instanceof CustomPageStore) {
                    navigation.navigate(
                        screenNames.CUSTOM_SCREEN as never,
                        { pageId } as never
                    )
                } else if (store instanceof FormPageStore) {
                    navigation.navigate(
                        screenNames.FORM_PAGE as never,
                        { pageId } as never
                    )
                } else if (store instanceof DetailsPageStore) {
                    navigation.navigate(
                        screenNames.DETAIL_SCREEN as never,
                        { pageId } as never
                    )
                }
            } else if (
                designPageStore.pageSelection instanceof
                DefaultAuthenticationPageSelection
            ) {
                const defaultAuthenticationPageType =
                    designPageStore.pageSelection.defaultAuthenticationPageType

                if (defaultAuthenticationPageType === 'welcome') {
                    navigation.navigate(screenNames.WELCOME_PAGE as never)
                }
            } else if (
                designPageStore.pageSelection instanceof DefaultPageSelection
            ) {
                const defaultPageType =
                    designPageStore.pageSelection.defaultPageType
                const dataSourceId = designPageStore.pageSelection.dataSourceId
                if (defaultPageType === 'details') {
                    navigation.navigate(
                        screenNames.DETAIL_SCREEN as never,
                        { pageId: '', tableId: dataSourceId } as never
                    )
                } else if (defaultPageType === 'form') {
                    navigation.navigate(
                        screenNames.FORM_PAGE as never,
                        { pageId: '', tableId: dataSourceId } as never
                    )
                }
            }
        }, [
            appDescriptorStore,
            designPageStore?.pageSelection,
            navigation,
            designPageStore,
        ])

        const changePageSelectionOnStudio = (pageId: string) => {
            if (designPageStore) {
                designPageStore.pageSelection = new NormalPageSelection(pageId)
            }
        }

        if (
            list?.filter(
                (pageId) =>
                    appDescriptorStore.pageStore(pageId) instanceof
                        CollectionPageStore ||
                    appDescriptorStore.pageStore(pageId) instanceof
                        CustomPageStore ||
                    appDescriptorStore.pageStore(pageId) instanceof
                        FormPageStore
            )?.length
        ) {
            return (
                <Drawer.Navigator
                    screenOptions={{
                        drawerType: 'permanent',
                        headerStyle: {
                            backgroundColor: theme.colorScheme.backgroundL1,
                        },
                        headerTintColor: theme.colorScheme
                            .labelPrimary as string,
                        drawerStyle: {
                            backgroundColor: theme.colorScheme.backgroundL1,
                            borderTopColor: theme.colorScheme.separator,
                            width: TAB_WIDTH,
                        },
                    }}
                >
                    {list.map((pageId, index) => {
                        const pageStore = appDescriptorStore.pageStore(pageId)
                        if (pageStore instanceof CollectionPageStore) {
                            return (
                                <Drawer.Screen
                                    key={index}
                                    name={pageId as keyof TabParamList}
                                    initialParams={{ pageId }}
                                    component={CollectionPage}
                                    options={{
                                        headerShown: false,
                                        drawerActiveBackgroundColor: theme
                                            .colorScheme.accentColor as string,
                                        drawerIcon: ({ focused }) => {
                                            return (
                                                <TabBarIconStore
                                                    focused={focused}
                                                    pageStore={pageStore}
                                                    isLargeDevice={true}
                                                />
                                            )
                                        },
                                        title: '',
                                    }}
                                    listeners={({ navigation }) => ({
                                        drawerItemPress: (e) => {
                                            changePageSelectionOnStudio(pageId)
                                        },
                                    })}
                                />
                            )
                        } else if (pageStore instanceof CustomPageStore) {
                            return (
                                <Drawer.Screen
                                    key={index}
                                    name={pageId as keyof TabParamList}
                                    component={CustomPageRoute}
                                    initialParams={{ pageId }}
                                    options={{
                                        headerShown: false,
                                        drawerActiveBackgroundColor: theme
                                            .colorScheme.accentColor as string,
                                        drawerIcon: ({ focused }) => {
                                            return (
                                                <TabBarIconStore
                                                    focused={focused}
                                                    pageStore={pageStore}
                                                    isLargeDevice={true}
                                                />
                                            )
                                        },
                                        title: '',
                                    }}
                                    listeners={({ navigation }) => ({
                                        drawerItemPress: (e) => {
                                            changePageSelectionOnStudio(pageId)
                                        },
                                    })}
                                />
                            )
                        } else if (pageStore instanceof FormPageStore) {
                            return (
                                <Drawer.Screen
                                    key={index}
                                    name={pageId as keyof TabParamList}
                                    component={BlankFormPage}
                                    listeners={({ navigation }) => ({
                                        drawerItemPress: (e) => {
                                            e.preventDefault()
                                            navigation.navigate(
                                                screenNames.FORM_PAGE,
                                                { pageId }
                                            )
                                            changePageSelectionOnStudio(pageId)
                                        },
                                    })}
                                    options={{
                                        drawerActiveBackgroundColor: theme
                                            .colorScheme.accentColor as string,
                                        drawerIcon: ({ focused }) => {
                                            return (
                                                <TabBarIconStore
                                                    focused={focused}
                                                    pageStore={pageStore}
                                                    isLargeDevice={true}
                                                />
                                            )
                                        },
                                        title: '',
                                    }}
                                />
                            )
                        }
                        return null
                    })}
                </Drawer.Navigator>
            )
        } else {
            return null
        }
    }
)

export default TabViewTablet
