import { apiClient } from '../index'
import { MAP_URL } from '../apis/MapUrl'

export interface IReverseGeocodeItem {
    query: string
    data: IGeocodeDetail
}

export interface IGeocodeDetail {
    name: string
    formattedAddressLines: string[]
    coordinate: {
        latitude: number
        longitude: number
    }
    country: string
    countryCode: string
}

export default {
    reverseGeocode: async (addresses: string) => {
        // @ts-ignore: global variable
        const teamId = global.teamId
        const url = `geocoding/${teamId}/${MAP_URL.reverseGeocode}`
        return apiClient.get(url, {
            data: {
                addresses,
            },
            isHandleError: false,
        })
    },
}
