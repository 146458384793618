//
//  ActionIconImage.ts
//
//  Created by Peter Vu on 2022-08-31 16:40:14.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { StyleSheet, Image, ImageStyle } from 'react-native'
import React, { useMemo } from 'react'
import { Theme, useTheme } from '../styles/theme'
import { ActionIcon } from '@appformula/app-descriptor/src/lib/shared-types/ActionIcon'
import { actionIcon } from '../assets/icons/icon-actions/index'

const ActionIconImage = (props: {
    iconName: ActionIcon
    style?: ImageStyle
}) => {
    const theme = useTheme()
    const styles = useMemo(() => createStyles(theme), [theme])
    const { iconName, style } = props

    return (
        <Image
            source={actionIcon[iconName]}
            style={[styles.actionIcon, { ...style }]}
        />
    )
}

export default ActionIconImage

const createStyles = ({ colorScheme }: Theme) =>
    StyleSheet.create({
        actionIcon: {
            height: 30,
            width: 30,
            tintColor: colorScheme.accentColor,
            resizeMode: 'contain',
        },
    })
