import {
    View,
    Image as ImageRN,
    StyleSheet,
    TouchableOpacityProps,
    TouchableOpacity,
} from 'react-native'
import React, { useCallback, useEffect, useState } from 'react'
import { Theme, useTheme } from '../../styles/theme'
import image from '../../assets/icons'
import FastImage from '../../components/fast-image/FastImage'
import { Loading } from '../Loading'
import { useDropzone } from 'react-dropzone'
import {
    generateRandomId,
    ResponseUpload,
    ResponseUploadData,
} from '../../utils'
import { FORM_URL } from '../../utils/network/apis/FormUrl'
import file from '../../utils/file/index.web'
import appConstants from '../../constants/const'

type ImagePickerProps = {
    initURL: string
    enable?: boolean
}

const ImagePicker: React.FC<ImagePickerProps & TouchableOpacityProps> = (
    props: ImagePickerProps & TouchableOpacityProps
) => {
    const theme = useTheme()
    const styles = React.useMemo(() => createStyles(theme), [theme])

    const { initURL, enable, style } = props

    const [isLoading, setLoading] = useState<boolean>(false)

    const [avatarURL, setAvatarURL] = React.useState<string>(initURL ?? '')

    const { open, acceptedFiles } = useDropzone({
        accept: {
            'image/*': [],
        },
        maxFiles: 1,
    })

    const handleUpload = useCallback(async () => {
        try {
            if (acceptedFiles && acceptedFiles.length > 0) {
                const newAcceptedFiles = acceptedFiles.map((item) => ({
                    id: generateRandomId(),
                    name: item.name,
                    type: item.type,
                    size: item.size,
                    preview: URL.createObjectURL(item),
                }))

                setLoading(true)

                const resultSuccess = await file.uploadFile({
                    data: newAcceptedFiles[0],
                    url: `${FORM_URL.uploadFile}`,
                })
                const url =
                    (
                        JSON.parse(
                            (resultSuccess as ResponseUpload).data
                        ) as ResponseUploadData
                    ).assetUrl ?? ''

                PubSub.publish(appConstants.RECEIVE_AVATAR_URL, url)
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            console.info('error----', error)
        }
    }, [acceptedFiles])

    useEffect(() => {
        handleUpload()
    }, [handleUpload])

    React.useEffect(() => {
        setAvatarURL(initURL)
    }, [initURL])

    const renderImage = () => {
        return !avatarURL ? (
            <View style={styles.wrapNoAva}>
                <ImageRN source={image.avatar} style={styles.imgNoAva} />
            </View>
        ) : (
            <FastImage source={{ uri: avatarURL }} style={styles.imgAva} />
        )
    }

    return (
        <TouchableOpacity
            onPress={open}
            disabled={!enable}
            style={StyleSheet.flatten(style)}
        >
            {isLoading ? (
                <View style={styles.wrapLoading}>
                    <Loading />
                </View>
            ) : (
                renderImage()
            )}
        </TouchableOpacity>
    )
}

export default ImagePicker

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        imgNoAva: {
            width: 53.93,
            height: 53.93,
            tintColor: colorScheme.separator,
        },

        imgAva: {
            height: 101,
            width: 101,
            borderRadius: 101 / 2,
            marginTop: 24,
            alignSelf: 'center',
        },

        wrapNoAva: {
            height: 101,
            width: 101,
            borderRadius: 101 / 2,
            borderColor: colorScheme.separator,
            backgroundColor: colorScheme.backgroundL2,
            borderStyle: 'dashed',
            borderWidth: 3,
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf: 'center',
            marginTop: 24,
        },

        wrapLoading: {
            height: 101,
            width: 101,
            alignItems: 'center',
            alignSelf: 'center',
            marginTop: 24,
            justifyContent: 'center',
            borderRadius: 101 / 2,
            backgroundColor: colorScheme.backgroundL2,
        },
    })
