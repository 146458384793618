import { View, Text, Image, StyleSheet, Pressable } from 'react-native'
import React, { useMemo } from 'react'
import Animated, { FadeOut, FadeIn } from 'react-native-reanimated'
import image from '../../../../../assets/icons'
import { Theme, useTheme } from '../../../../../styles/theme'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import appConstants from '../../../../../constants/const'
import { SafeAreaView } from 'react-native-safe-area-context'

interface ILocationResults {
    onSelect: (location: ILocation) => void
    data: Array<ILocation>
    selectedLocation?: {
        latitude: number
        longitude: number
    } & ILocation
}

export interface ILocation {
    placeId: string
    description: string
    mainText?: string
}

const LocationResults: React.FC<ILocationResults> = (props: ILocationResults) => {
    const theme = useTheme()
    const styles = useMemo(() => createStyles(theme), [theme])

    const onSelectLocation = (item: ILocation) => () => {
        props.onSelect && props.onSelect(item)
    }

    const renderItemLocation = (item: ILocation, index: number) => {
        const isSelected = props?.selectedLocation?.placeId === item?.placeId

        if (item.placeId === appConstants.PLACE_ID_CURRENT) {
            return (
                <Pressable style={styles.containerHeader} key={item.placeId} onPress={onSelectLocation(item)}>
                    <View style={styles.wrapLocationArrow}>
                        <Image source={image.locationArrow} style={styles.imgLocationArrow} />
                    </View>
                    <View style={styles.wrapAddressResult}>
                        <Text style={styles.txtAddressResult}>Current Location</Text>
                        {isSelected && <Image source={image.checked} style={styles.imgChecked} />}
                    </View>
                </Pressable>
            )
        } else {
            return (
                <Pressable style={styles.containerHeader} key={item.placeId} onPress={onSelectLocation(item)}>
                    <Image source={image.locationPin} style={styles.imgLocationPin} />
                    <View style={styles.wrapAddressResult}>
                        <View style={styles.wrapTitleLocation}>
                            <Text numberOfLines={1} style={styles.txtAddressResult}>
                                {item.mainText}
                            </Text>
                            <Text style={styles.txtDetailAddressResult}>{item.description}</Text>
                        </View>
                        {isSelected && <Image source={image.checked} style={styles.imgChecked} />}
                    </View>
                </Pressable>
            )
        }
    }

    return (
        <Animated.View entering={FadeIn} exiting={FadeOut} style={styles.container}>
            <SafeAreaView style={styles.containerSafeView} edges={['bottom']}>
                <KeyboardAwareScrollView
                    contentContainerStyle={styles.containerScrollView}
                    keyboardShouldPersistTaps={'always'}
                    keyboardDismissMode={'on-drag'}
                    enableResetScrollToCoords={false}
                >
                    {!!props.data &&
                        props.data.length > 0 &&
                        props.data.map((item, index) => renderItemLocation(item, index))}
                </KeyboardAwareScrollView>
            </SafeAreaView>
        </Animated.View>
    )
}

export default LocationResults
const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        wrapTitleLocation: { flex: 1 },
        imgChecked: { width: 24, height: 24, tintColor: colorScheme.accentColor, marginHorizontal: 12 },
        wrapAddressResult: {
            flex: 1,
            paddingVertical: 18,
            borderBottomColor: colorScheme.separator,
            borderBottomWidth: 1,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        imgLocationArrow: { width: 20, height: 20 },
        containerHeader: { flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' },
        contentFlatList: { paddingLeft: 16 },
        container: { height: '100%', width: '100%', backgroundColor: colorScheme.backgroundL1, position: 'absolute' },
        containerSafeView: { flex: 1, backgroundColor: colorScheme.backgroundL1 },
        containerScrollView: {
            paddingLeft: appConstants.PADDING_COMMON,
        },
        txtAddressResult: {
            fontWeight: '500',
            fontSize: 15,
            lineHeight: 22.5,
            color: colorScheme.labelPrimary,
        },
        wrapLocationArrow: {
            width: 32,
            height: 32,
            borderRadius: 16,
            backgroundColor: colorScheme.accentColor,
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: 12,
        },
        txtDetailAddressResult: {
            fontWeight: '400',
            fontSize: 13,
            lineHeight: 19.5,
            color: colorScheme.labelSecondary,
        },
        imgLocationPin: {
            width: 32,
            height: 32,
            marginRight: 12,
        },
    })
