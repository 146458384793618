import React, { useEffect } from 'react'
import { AppDescriptorContext } from '../../context/AppDescriptorContext'
import { MainPageDataStoreContext } from '../../context/MainPageDataStoreContext'
import { DetailsPageStore } from '@appformula/app-studio-core/src'
import { ARNCustomDetailPageStore } from '../custom/ARNCustomDetailPageStore'
import { observer } from 'mobx-react-lite'
import Details from './Details'
import { useRoute } from '@react-navigation/native'
import { useDatabaseAction } from '../../context/AppDatabaseContext'
interface IParams {
    itemKey: string
    pageId: string
    tableId: string
}

export const DetailPage = () => {
    const store = React.useContext(AppDescriptorContext)
    const mainPageDataStore = React.useContext(MainPageDataStoreContext)
    const route = useRoute()
    const { pageId, itemKey, tableId } = route.params as IParams
    const pageStore =
        (store.pageStore(pageId) as DetailsPageStore) ??
        (tableId &&
            (store.defaultPageStore(tableId, 'details') as DetailsPageStore))
    const databaseAction = useDatabaseAction()
    return pageStore ? (
        <DetailPageContent
            pageStore={pageStore}
            arnDetailPageStore={
                mainPageDataStore.arnCustomDetailPageStore(
                    pageId,
                    databaseAction,
                ) as ARNCustomDetailPageStore
            }
            itemKey={itemKey}
        />
    ) : null
}

export const DetailPageContent = observer(
    (props: {
        pageStore: DetailsPageStore
        arnDetailPageStore: ARNCustomDetailPageStore
        itemKey: string
    }) => {
        const { pageStore, arnDetailPageStore, itemKey } = props
        const tableId = pageStore?.tableId || ''
        useEffect(() => {
            if (tableId) {
                arnDetailPageStore.getAndSetData(tableId, itemKey)
            }
        }, [arnDetailPageStore, tableId, itemKey])
        return (
            <Details
                pageStore={pageStore}
                arnDetailPageStore={arnDetailPageStore}
            />
        )
    }
)
