import { StyleSheet, ScrollView } from 'react-native'
import React from 'react'
import { observer } from 'mobx-react-lite'
import { InlineCollectionStore } from '@appformula/app-studio-core'
import CollectionHListItem from './CollectionHListItem'
import { IData } from '.'

const HorizontalCollection = observer((props: { inlineCollection: InlineCollectionStore; data: Array<IData> }) => {
    const { inlineCollection, data } = props
    const renderItem = (item: IData, index: number) => {
        return <CollectionHListItem key={`${item.id}`} item={item} inlineCollection={inlineCollection}/>
    }

    return (
        data &&
        data.length > 0 ? (
            <ScrollView horizontal style={styles.container} showsHorizontalScrollIndicator={false}>
                {data.map((item, index) => renderItem(item, index))}
            </ScrollView>
        ) : null
    )
})

export default HorizontalCollection

const styles = StyleSheet.create({
    container: {},
})
