import React from 'react'
import { observer } from 'mobx-react-lite'
import BarChart from './BarChart/BarChart'
import PieChart from './PieChart/PieChart'
import LineChart from './LineChart/LineChart'
import { ARNChartStore } from './ARNChartStore'
import { ChartStore } from '@appformula/app-studio-core'

const Layout = observer(
    (props: { chartStore: ChartStore; arnChartStore: ARNChartStore }) => {
        const { chartStore, arnChartStore } = props

        React.useEffect(() => {
            let unsubscribes: (() => void)[] = []

            const handleGetData = async () => {
                unsubscribes = await arnChartStore.getAndSetChartData(
                    chartStore
                )
            }

            handleGetData()

            return () => {
                unsubscribes.forEach((unsubscribe) => {
                    unsubscribe()
                })
            }
        }, [arnChartStore, chartStore, chartStore.object])

        switch (arnChartStore.chartStore?.chartType) {
            case 'pie':
                return <PieChart {...props} />
            case 'bar':
                return <BarChart {...props} />
            case 'line':
                return <LineChart {...props} />
            default:
                return null
        }
    }
)

export default Layout
