import { Platform } from 'react-native'
import * as SQLite from 'expo-sqlite'
import { openDatabase } from 'expo-sqlite'

let singletonDbInstance: SQLite.Database

export const GetDatabase = function (): SQLite.Database {
    if (singletonDbInstance) {
        return singletonDbInstance
    } else {
        if (Platform.OS === 'web') {
            const db = openDatabase('db', '1.0', 'this is a client side database', 2 * 1024 * 1024)
            // To check whether the database is created or not.
            if (!db) {
                throw Error('Unable to open database')
            } else {
                singletonDbInstance = db
                return singletonDbInstance
            }
        } else {
            singletonDbInstance = SQLite.openDatabase('db')
            return singletonDbInstance
        }
    }
}
