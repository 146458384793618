import { action, makeObservable, observable } from 'mobx'
import { MapStore } from '@appformula/app-studio-core'
import { Nullable } from '@appformula/shared-foundation-x/src'
import { Region } from 'react-native-maps'
import { MarkerProps } from './MarkerItem'
import { ARNCustomDetailPageStore } from '../../ARNCustomDetailPageStore'
import { Binding } from '@appformula/app-descriptor/src'
import { FormulaManager } from 'apps/app-react-native/src/utils/integrate-formula/FormulaManager'
import { TransformBinding } from '../../../../utils/transform-binding/TransformBinding'
import { reverseGeocodeFromApi } from '../../../../utils/location'

export type RNAudioType = {
    title: string
    audioURL: string
}

export type RNBarCodeType = {
    title: string
    barcode: string
}

export type RNBooleanInputType = {
    title: string
    initialValue: boolean
}

export type RNButtonType = {
    title: string
    color: string
}

export type RNDateRangeInputType = {
    title: string
    initialStart: Date
    initialEnd: Date
}

export type RNDateTimeInputType = {
    title: string
    initialValue: Date
}

export type RNFilePreviewType = {
    fileUrl: string
}

export type RNImageType = {
    url: string
}

export type RNMapType = {
    address: string
    calloutTitle?: string
    calloutSubtitle?: string
}

export type RNMarkdownType = {
    text: string
}

export type RNNumberScaleInputType = {
    title: string
    initialValue: number
}

export type RNNumberSliderInputType = {
    title: string
    maxValue: number
    minValue: number
    initialValue: number
}

export type RNOptionsInputType = {
    title: string
}

export type RNPeopleInputType = {
    title: string
}

export type RNProgressBarType = {
    progress: number
}

export type RNTextType = {
    text: string
}

export type RNTextInputType = {
    placeholder: string
    initialText: string
}

export type RNVideoType = {
    videoURL: string
    title: string
    thumbnailURL: string
}

export type RNWebLinkType = {
    url: string
}

export type ChildComponentType =
    | RNAudioType
    | RNBarCodeType
    | RNBooleanInputType
    | RNButtonType
    | RNDateRangeInputType
    | RNDateTimeInputType
    | RNFilePreviewType
    | RNImageType
    | RNMapType
    | RNMarkdownType
    | RNNumberScaleInputType
    | RNNumberSliderInputType
    | RNOptionsInputType
    | RNPeopleInputType
    | RNProgressBarType
    | RNTextType
    | RNTextInputType
    | RNVideoType
    | RNWebLinkType

class ChildComponent {
    data: ChildComponentType

    setData(data: ChildComponentType) {
        this.data = data
    }
}

export const defaultLatitude: number = 21.0365159
export const defaultLongitude: number = 105.813689

export const defaultLatitudeDelta: number = 0.05
export const defaultLongitudeDelta: number = 0.05

export class ARNMapStore {
    mapStore: Nullable<MapStore> = undefined
    listMarkerData: MarkerProps[] = []
    mapRegion: Nullable<Region> = null
    pageStore: ARNCustomDetailPageStore
    componentId: string
    constructor(
        pageStore: ARNCustomDetailPageStore,
        mapStore: MapStore,
        componentId: string
    ) {
        this.pageStore = pageStore
        this.mapStore = mapStore
        this.componentId = componentId
        makeObservable(this, {
            mapStore: observable,
            setMapStore: action,

            mapRegion: observable,
            setMapRegion: action,

            listMarkerData: observable,
            setListMarkerData: action,

            getAndSetMapData: action,
        })
    }

    setMapStore(mapStore: MapStore) {
        this.mapStore = mapStore
    }

    setListMarkerData(listMarkerData: MarkerProps[]) {
        this.listMarkerData = listMarkerData
    }

    setMapRegion(mapRegion: Region) {
        this.mapRegion = mapRegion
    }

    async getAndSetMapData(
        formulaManager: FormulaManager,
        address: Nullable<Binding<string>>
    ) {
        try {
            await formulaManager.registTable(
                this.mapStore?.dataSource?.id ?? ''
            )

            const resultAddress = await TransformBinding(
                address,
                formulaManager
            )
            let listAddress: string[] = []
            if (Array.isArray(resultAddress)) {
                listAddress = [...(resultAddress as string[])]
            } else {
                listAddress = [resultAddress.toString()]
            }

            const response = await reverseGeocodeFromApi(listAddress)

            const listPin: MarkerProps[] = []

            response.forEach((item, index) => {
                listPin.push({
                    latitude: item?.data.coordinate.latitude,
                    longitude: item?.data.coordinate.longitude,
                    // calloutTitle: pin.calloutTitle as string,
                    // calloutSubtitle: pin.calloutSubtitle as string,
                    address: item?.query as string,
                })
            })

            const region = {
                latitude: response[0]?.data.coordinate.latitude,
                longitude: response[0]?.data.coordinate.longitude,
                latitudeDelta: defaultLatitudeDelta,
                longitudeDelta: defaultLongitudeDelta,
            }

            this.setMapRegion(region)
            this.setListMarkerData(listPin)
        } catch (error) {
            console.info('error-----', error)
        }
    }

    childComponentStore(componentId: string): ChildComponent {
        // Implement this
        return new ChildComponent()
    }
}
