import {
    Text,
    StyleSheet,
    TouchableOpacity,
    TouchableOpacityProps,
} from 'react-native'
import React, { useMemo } from 'react'
import { Theme, useTheme } from '../styles/theme'
import { COLORS } from '../assets/colors'
import { Loading } from './Loading'

type IAppBtn = {
    title?: string
    loading?: boolean
}

const AppButton: React.FC<IAppBtn & TouchableOpacityProps> = (
    props: IAppBtn & TouchableOpacityProps
) => {
    const theme = useTheme()
    const styles = useMemo(() => createStyles(theme), [theme])

    const { loading, title } = props

    return (
        <TouchableOpacity
            style={[styles.container, loading && styles.loadingContainer]}
            {...props}
            disabled={loading}
        >
            {loading ? (
                <Loading color={'#FFFFFF'} />
            ) : (
                <Text style={styles.txtTitle}>{title || ''}</Text>
            )}
        </TouchableOpacity>
    )
}

export default AppButton

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        container: {
            backgroundColor: colorScheme.accentColor,
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            height: 50,
            borderRadius: roundness,
            flexDirection: 'row',
        },

        loadingContainer: {
            backgroundColor: colorScheme.labelSecondary,
        },

        txtTitle: {
            color: COLORS.WHITE,
            fontSize: 17,
            lineHeight: 20.57,
            fontWeight: '600',
        },
    })
