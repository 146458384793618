//
//  WrapContextAppDesktopStore.ts
//
//  Created by Peter Vu on 2022-11-07 15:10.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { Nullable } from '@appformula/shared-foundation-x/src'
import { computed, makeObservable, observable } from 'mobx'
import { Linking } from 'react-native'
import { Alert } from '../../../components/alert'
import getFireStore from '../../../utils/firebase/firestore/getFireStore'
import { AppType, DataWrapContextDesktop } from './type'

export class WrapContextAppDesktopStore {
    appInfoUnsubscribe: Nullable<() => void>
    teamInfoUnsubscribe: Nullable<() => void>
    object: DataWrapContextDesktop

    get logoURL(): string {
        return this.object.logoURL ?? ''
    }

    get appName(): string {
        return this.object.appName ?? ''
    }

    get appType(): AppType {
        return this.object.appType ?? AppType.EXTERNAL
    }

    get teamName(): string {
        return this.object.teamName ?? ''
    }

    constructor() {
        this.object = {
            logoURL: '',
            appName: '',
            appType: AppType.EXTERNAL,
            teamName: '',
        }
        makeObservable(this, {
            object: observable,
            appName: computed,
            appType: computed,
            teamName: computed,
            logoURL: computed,
        })
    }

    set<K extends keyof DataWrapContextDesktop>(
        key: K,
        value: DataWrapContextDesktop[K]
    ) {
        this.object[key] = value
    }

    async listenAppInfo(teamId: string, appId: string) {
        try {
            const firestore = await getFireStore()
            if (this.appInfoUnsubscribe) {
                this.appInfoUnsubscribe()
            }
            this.appInfoUnsubscribe = firestore
                .collection(`teams/${teamId}/apps`)
                .doc(appId)
                .onSnapshot({
                    next: async (snapshot) => {
                        this.set('logoURL', snapshot.data()?.['logoURL'])
                        this.set('appName', snapshot.data()?.['name'])
                        this.set('appType', snapshot.data()?.['type'])
                    },
                })
        } catch (error) {
            console.info(error)
            return
        }
    }

    async listenTeamInfo(teamId: string) {
        try {
            const firestore = await getFireStore()
            if (this.teamInfoUnsubscribe) {
                this.teamInfoUnsubscribe()
            }
            this.teamInfoUnsubscribe = firestore
                .collection(`teams`)
                .doc(teamId)
                .onSnapshot({
                    next: async (snapshot) => {
                        this.set('teamName', snapshot.data()?.['name'])
                    },
                })
        } catch (error) {
            console.info(error)
            return
        }
    }

    async openLink(url: string) {
        const supported = await Linking.canOpenURL(url)
        if (supported) {
            await Linking.openURL(url)
        } else {
            Alert.alert(`Don't know how to open this URL: ${url}`)
        }
    }
}

export const wrapContextAppDesktopStore = new WrapContextAppDesktopStore()
