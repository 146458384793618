export const CountdownToTime = (
    countDownDate: Date
): { distance: number; countDownText: string } => {
    // Get today's date and time
    const now = new Date().getTime()

    // Find the distance between now and the count down date
    const distance = new Date(countDownDate).getTime() - now

    // Time calculations for days, hours, minutes and seconds
    const days = Math.floor(distance / (1000 * 60 * 60 * 24))
    const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    )
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
    const seconds = Math.floor((distance % (1000 * 60)) / 1000)

    const countDownText =
        days > 0
            ? days + 'd ' + hours + 'h ' + minutes + 'm ' + seconds + 's '
            : hours + 'h ' + minutes + 'm ' + seconds + 's '

    return {
        distance,
        countDownText,
    }
}
