//
//  ListLayoutItem.tsx
//
//  Created by thaitd96 on 2022-06-05 17:32.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React, { useCallback, useMemo } from 'react'
import { Pressable, StyleSheet, Text, View } from 'react-native'
import { Theme, useTheme } from '../../../../styles/theme'
import appConstants from '../../../../constants/const'
import FastImage from '../../../../components/fast-image/FastImage'
import { Accessory } from '../accessory/Accessory'
import {
    CollectionPageStore,
    DefaultPageSelection,
} from '@appformula/app-studio-core/src'
import { ListLayout } from '@appformula/app-descriptor/src'
import { useGetItemFromIndex } from '../hooks/useGetItemFromIndex'
import { ARNCollectionPageStore } from '../../ARNCollectionPageStore'
import { observer } from 'mobx-react-lite'
import { useBindingHaveItemData } from '../hooks/useBindingHaveItemData'
import { useNavigation } from '@react-navigation/native'
import screenNames from '../../../../navigations/screenNames'
import { DesignPageContext } from '../../../../context/DesignPageContext'
import { appFormulaId } from '../../../../database/type'

export const ListLayoutItem = observer(
    (props: {
        item: number
        pageStore: CollectionPageStore
        arnCollectionPageStore: ARNCollectionPageStore
        valueGrouping?: string
    }) => {
        const theme = useTheme()
        const { item, pageStore } = props
        const styles = useMemo(() => createStyles(theme), [theme])
        const { accessory } = pageStore.activeLayout as ListLayout
        const navigation = useNavigation()

        const itemData = useGetItemFromIndex(
            props.pageStore,
            item,
            props.arnCollectionPageStore.queryString,
            props.valueGrouping
        )

        const itemTitle = useBindingHaveItemData(
            (pageStore.activeLayout as ListLayout).itemTitle,
            itemData
        )

        const itemSubtitle = useBindingHaveItemData(
            (pageStore.activeLayout as ListLayout).itemSubtitle,
            itemData
        )

        const image = useBindingHaveItemData(
            (pageStore.activeLayout as ListLayout).image,
            itemData
        )

        const designPageStore = React.useContext(DesignPageContext)
        const onPressItem = useCallback(() => {
            try {
                const tableId = props.pageStore.dataSource?.id
                if (tableId) {
                    if (designPageStore && tableId) {
                        designPageStore.pageSelection =
                            new DefaultPageSelection(tableId, 'details')
                    }
                    navigation.navigate(
                        screenNames.DETAIL_SCREEN as never,
                        {
                            itemKey: itemData?.['key'] ?? '',
                            pageId: '',
                            tableId,
                        } as never
                    )
                }
            } catch (error) {
                console.info(error)
            }
        }, [
            designPageStore,
            itemData,
            navigation,
            props.pageStore.dataSource?.id,
        ])

        const Image = useCallback(
            ({ isShow }: { isShow: boolean }) => {
                return isShow ? (
                    <FastImage
                        style={styles.image}
                        source={{
                            uri: (image ?? '') as string,
                        }}
                        resizeMode={'stretch'}
                    />
                ) : null
            },
            [image, styles.image]
        )

        const Title = useCallback(() => {
            return (
                <Text
                    numberOfLines={2}
                    ellipsizeMode="tail"
                    style={styles.title}
                >
                    {(itemTitle as string) ?? ''}
                </Text>
            )
        }, [itemTitle, styles.title])

        const SubTitle = useCallback(
            ({ isShow }: { isShow: boolean }) => {
                return isShow ? (
                    <Text
                        numberOfLines={2}
                        ellipsizeMode="tail"
                        style={styles.subTitle}
                    >
                        {(itemSubtitle as string) ?? ''}
                    </Text>
                ) : null
            },
            [itemSubtitle, styles.subTitle]
        )

        return (
            <Pressable
                key={itemData?.[appFormulaId] as string}
                style={styles.item}
                onPress={onPressItem}
            >
                <Image isShow={!!image} />
                <View style={styles.rightItem}>
                    <Title />
                    <SubTitle isShow={!!itemSubtitle} />
                    {!!accessory && (
                        <Accessory accessory={accessory} item={itemData} />
                    )}
                </View>
            </Pressable>
        )
    }
)

const createStyles = ({ colorScheme, roundness }: Theme) =>
    StyleSheet.create({
        accessory: { color: colorScheme.labelSecondary, marginTop: 6 },
        subTitle: {
            fontWeight: '500',
            fontSize: 14,
            lineHeight: 21,
            color: colorScheme.labelSecondary,
            marginTop: 6,
        },
        title: {
            fontWeight: '500',
            fontSize: 16,
            color: colorScheme.labelPrimary,
            lineHeight: 19.36,
        },
        rightItem: {
            paddingLeft: appConstants.PADDING_COMMON,
            justifyContent: 'space-between',
            flex: 1,
        },
        item: {
            width: '100%',
            padding: appConstants.PADDING_COMMON,
            flexDirection: 'row',
            alignItems: 'center',
            borderColor: colorScheme.separator,
            borderTopWidth: 1,
            backgroundColor: colorScheme.backgroundL1,
        },
        image: { width: 52, height: 52, borderRadius: roundness },
    })
