//
//  FilterBoolean.tsx
//
//  Created by thaitd96 on 2022-26-07 11:56.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { observer } from 'mobx-react-lite'
import React, { useCallback, useMemo } from 'react'
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import image from '../../../../../assets/icons'
import appConstants from '../../../../../constants/const'
import { Theme, useTheme } from '../../../../../styles/theme'
import { CollectionFilterStore } from '../CollectionFilterStore'
import FilterBooleanItem from './FilterBooleanItem'
import { FilterBooleanStore } from './FilterBooleanStore'

const BooleanCompare = [
    { key: 1, value: true, label: 'Yes' },
    { key: 2, value: false, label: 'No' },
]

export type ItemFilterBoolean = {
    key: number
    value: boolean
    label: string
}

export const FilterBoolean = observer(
    (props: {
        filterBooleanStore: FilterBooleanStore
        collectionFilterStore: CollectionFilterStore
    }) => {
        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])

        const onChangeSelected = useCallback(
            (item) => {
                props.filterBooleanStore.setValue(item.value)
            },
            [props.filterBooleanStore]
        )

        const clear = useCallback(() => {
            props.collectionFilterStore.removeFilteredColumn(
                props.filterBooleanStore.columnFilter.key
            )
        }, [
            props.collectionFilterStore,
            props.filterBooleanStore.columnFilter.key,
        ])

        const ComponentHeader = useCallback(() => {
            return (
                <View style={styles.viewComponentHeader}>
                    <Text style={styles.textHeader}>
                        {props.filterBooleanStore.columnFilter.columnNameRaw}
                    </Text>
                    <TouchableOpacity onPress={clear}>
                        <Image
                            source={image.close}
                            style={styles.imageClose}
                        ></Image>
                    </TouchableOpacity>
                </View>
            )
        }, [
            clear,
            props.filterBooleanStore.columnFilter.columnNameRaw,
            styles.imageClose,
            styles.textHeader,
            styles.viewComponentHeader,
        ])

        const RadioGroup = useCallback(() => {
            return (
                <View>
                    {BooleanCompare.map((item) => (
                        <FilterBooleanItem
                            key={item.key}
                            onChangeSelected={onChangeSelected}
                            item={item}
                            isSelected={
                                item.value === props.filterBooleanStore.value
                            }
                        />
                    ))}
                </View>
            )
        }, [onChangeSelected, props.filterBooleanStore.value])

        return (
            <View style={styles.filterBoolean}>
                <ComponentHeader />
                <RadioGroup />
            </View>
        )
    }
)

const createStyles = ({ colorScheme }: Theme) => {
    return StyleSheet.create({
        textHeader: {
            fontSize: 17,
            fontWeight: '600',
            color: colorScheme.labelPrimary,
        },
        viewComponentHeader: {
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingTop: 12,
        },
        imageClose: { height: 30, width: 30, resizeMode: 'contain' },
        filterBoolean: {
            justifyContent: 'center',
            backgroundColor: colorScheme.backgroundL1,
            paddingHorizontal: appConstants.PADDING_COMMON,
            paddingBottom: 12,
            borderBottomWidth: 1,
            borderBottomColor: colorScheme.separator,
        },
    })
}
