import { View, Text, StyleSheet, Pressable, Image } from 'react-native'
import React, { forwardRef, useEffect, useImperativeHandle, useMemo } from 'react'
import { ILookup } from '.'
import { Theme, useTheme } from '../../../../../styles/theme'
import image from '../../../../../assets/icons'

export interface IMultiChoiceItem {
    checked: boolean
    unCheck: () => void
    item: ILookup
}

const MultiChoiceItem = forwardRef(
    (
        props: {
            item: ILookup
            changeSelected?: (id: string) => void
            isActive: boolean
        },
        ref
    ) => {
        const { item, changeSelected, isActive } = props
        const [checked, setChecked] = React.useState(isActive)

        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])

        useImperativeHandle(ref, () => ({
            unCheck: () => {
                return unCheck()
            },
            checked,
            item,
        }))

        const handleCheck = () => {
            setChecked(!checked)
        }

        const unCheck = () => {
            setChecked(false)
        }

        useEffect(() => {
            if (checked) {
                changeSelected && changeSelected(item.id)
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [checked])

        return (
            <Pressable onPress={handleCheck}>
                <View style={styles.viewBooleanFieldCheckBox}>
                    <View style={styles.wrapImg}>
                        {checked ? (
                            <View>
                                <Image style={styles.imgCheckbox} source={image.checkBox} resizeMode={'stretch'} />
                                <Image style={styles.imgCheck} source={image.checkV1} resizeMode={'stretch'} />
                            </View>
                        ) : (
                            <Image style={styles.imgUnCheckbox} source={image.unCheckBox} resizeMode={'stretch'} />
                        )}
                    </View>
                    <View style={styles.wrapContent}>
                        <Text style={styles.title}>{item.content}</Text>
                    </View>
                </View>
            </Pressable>
        )
    }
)

MultiChoiceItem.displayName = 'MultiChoiceItem'

export default MultiChoiceItem

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        wrapContent: { flex: 1, paddingVertical: 19, borderBottomWidth: 1, borderBottomColor: colorScheme.separator },
        viewBooleanFieldCheckBox: {
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
        },

        title: {
            fontWeight: '400',
            fontSize: 15,
            lineHeight: 18,
            color: colorScheme.labelPrimary
        },

        wrapImg: {
            marginRight: 12,
        },

        imgCheckbox: { height: 20, width: 20, tintColor: colorScheme.accentColor },

        imgCheck: { height: 20, width: 20, position: 'absolute' },

        imgUnCheckbox: { height: 20, width: 20 },
    })
