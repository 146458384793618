import {
    Text,
    StyleSheet,
    Image,
    ImageSourcePropType,
    TouchableOpacity,
    TouchableOpacityProps,
} from 'react-native'
import React, { useMemo } from 'react'
import { Theme, useTheme } from '../../../styles/theme'

type IAppBtn = {
    title?: string
    loading?: boolean
    icon?: ImageSourcePropType
    onPress?: () => void
}

const ClientAppButton: React.FC<IAppBtn & TouchableOpacityProps> = (
    props: IAppBtn & TouchableOpacityProps
) => {
    const theme = useTheme()
    const styles = useMemo(() => createStyles(theme), [theme])

    const { title, icon, onPress, ...restProps } = props

    return (
        <TouchableOpacity
            style={styles.container}
            onPress={onPress}
            {...restProps}
        >
            <Image
                source={icon ?? require('../../../../assets/icon.png')}
                style={styles.iconAccessory}
            />
            <Text style={styles.txtTitle}>{title || ''}</Text>
        </TouchableOpacity>
    )
}

export default ClientAppButton

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        container: {
            // backgroundColor: colorScheme.accentColor,
            // width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            // height: 50,
            // borderRadius: roundness,
            marginHorizontal: 12,
        },

        txtTitle: {
            textAlign: 'center',
            color: colorScheme.labelSecondary,
            marginTop: 8,
            fontSize: 12,
            lineHeight: 14.52,
            fontWeight: '400',
        },
        iconAccessory: {
            // tintColor: colorScheme.labelSecondary,
            width: 67,
            height: 67,
        },
    })
