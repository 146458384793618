import { View, Text, StyleSheet, Pressable, Image } from 'react-native'
import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useMemo,
} from 'react'
import { Theme, useTheme } from '../../../../styles/theme'
import image from '../../../../assets/icons'

export interface IDataItem {
    id: string
    key: string
    text: string
}

export interface IOptionItem {
    checked: boolean
    unCheck: () => void
    item: IDataItem
}

const OptionItem = forwardRef(
    (
        props: {
            item: IDataItem
            changeSelected?: (id: string) => void
            optionSelected?: string
        },
        ref
    ) => {
        const { item, changeSelected, optionSelected } = props
        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])
        const [checked, setChecked] = React.useState(
            optionSelected === item.key
        )

        useImperativeHandle(ref, () => ({
            unCheck: () => {
                return unCheck()
            },
            item,
            checked,
        }))

        const handleCheck = () => {
            setChecked(!checked)
        }

        const unCheck = () => {
            setChecked(false)
        }

        useEffect(() => {
            if (checked) {
                changeSelected && changeSelected(item.id)
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [checked])

        return (
            <Pressable key={item.id} onPress={handleCheck}>
                <View style={styles.viewBooleanFieldCheckBox}>
                    <View style={styles.wrapImg}>
                        {checked ? (
                            <View>
                                <Image
                                    style={styles.imgCheckbox}
                                    source={image.radioChecked}
                                    resizeMode={'contain'}
                                />
                            </View>
                        ) : (
                            <Image
                                style={styles.imgUnCheckbox}
                                source={image.radioUncheck}
                                resizeMode={'contain'}
                            />
                        )}
                    </View>
                    <Text style={styles.txtContent}>{item.text}</Text>
                </View>
            </Pressable>
        )
    }
)

OptionItem.displayName = 'OptionItem'

export default OptionItem

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        txtContent: {
            color: colorScheme.labelPrimary,
            fontSize: 15,
            fontWeight: '500',
            lineHeight: 18.15,
        },

        imgCheckbox: {
            height: 20,
            width: 20,
            tintColor: colorScheme.accentColor,
        },

        imgUnCheckbox: { height: 20, width: 20 },

        viewBooleanFieldCheckBox: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingVertical: 12,
        },

        wrapImg: {
            marginRight: 12,
        },
    })
