import { Dimensions } from 'react-native'

const appConstants = {
    API: {
        // BASE_URL_ASSETS: 'https://whitelable.onappformula.dev',
        // BASE_URL_GOOGLE_MAP: 'https://maps.googleapis.com/maps',
        // BASE_URL: 'https://api.appformula.dev',
        BASE_URL_CLOUDFUNCTION:
            'https://us-central1-appformula-dev.cloudfunctions.net',
        // WEBHOOK_LOCATION_TRIGGER_URL:
        //     'https://webhook-dev.peter6869.workers.dev',
        BASE_URL_APP_DESCRIPTOR: 'https://whitelable.onappformula.dev',
        // BASE_URL_MAP: 'https://geocoding-dev.peter6869.workers.dev',
        // BASE_URL_CDP: 'https://cdp.appformula.dev',
        SUF_DOMAIN: '.onappformula.dev'
    },
    WARNING: 'WARNING',
    PADDING_COMMON: 16,
    MAX_CONTENT_HEIGHT: 144,
    // 16 * 2 (padding top/bottom) +
    // 18 * 4 (line height * max number of height) +
    // 4 * 2 (padding between title & subtitle, subtitle & accessory) +
    // 32 (accessory height)
    MAX_CONTENT_HEIGHT_WITHOUT_ACCESSORY: 108,
    // 16 * 2 (padding top/bottom) +
    // 18 * 4 (line height * max number of height) +
    // 4 (padding between title & subtitle)
    MAX_CONTENT_HEIGHT_WITHOUT_SUBTITLE: 104,
    // 16 * 2 (padding top/bottom) +
    // 18 * 2 (line height * max number of height) +
    // 4 (padding between title & accessory) +
    // 32 (accessory height)
    MAX_CONTENT_HEIGHT_ONLY_TITLE: 68,
    // 16 * 2 (padding top/bottom) +
    // 18 * 2 (line height * max number of height)
    DEFAULT_TYPE: 1,
    // SIZE_SCREEN
    WIDTH_SCREEN: Dimensions.get('window').width,
    HEIGHT_SCREEN: Dimensions.get('window').height,
    // Use iPhone12 as base size which is 375 x 667
    BASE_WIDTH: 390,
    BASE_HEIGHT: 884,
    IGNORE_LOGS: [
        'AsyncStorage has been extracted from react-native core and will be removed in a future release',
        'Setting a timer for a long period of time',
        'Warning: componentWillMount has been renamed',
        'Warning: componentWillReceiveProps has been renamed',
    ],
    Z_INDEX_DATE_PICKER: 1,
    Z_INDEX_OVER_DATE_PICKER: 2,
    API_KEY_GOOGLE_MAP: 'AIzaSyDAEfRVMQo7WckYEOwgKQ95NZjIb15Qx1I',
    BOOTSTRAP_URL_KEYS: 'AIzaSyCOMyzp0azA3gDL1Nk31OiiGUvCFyMKU7k',
    PLACE_ID_CURRENT: 'CURRENT',
    TOPIC_SIGN: 'TOPIC_SIGN',
    TOPIC_SAVE_SIGN_DONE: 'TOPIC_SAVE_SIGN_DONE',
    REFRESH_SCHEMA_DONE: 'REFRESH_SCHEMA_DONE',
    SYNC_DATA_DONE: 'SYNC_DATA_DONE',
    HANDLE_SCROLL_ON: 'HANDLE_SCROLL_ON',
    HANDLE_SCROLL_OFF: 'HANDLE_SCROLL_OFF',
    RECEIVE_AVATAR_URL: 'RECEIVE_AVATAR_URL',
    OPEN_FORM_PAGE: 'OPEN_FORM_PAGE',
    COMPARE_TYPE: {
        IS_CONTAIN: 0,
        IS_NOT_CONTAIN: 1,
        IS_EQUAL: 2,
        IS_NOT_EQUAL: 3,
        IS_START_WITH: 4,
        IS_END_WITH: 5,
        IS_EMPTY: 6,
        IS_NOT_EMPTY: 7,
    },
    NUMBER_COMPARE_TYPE: {
        BETWEEN: 0,
        GREATER_THAN: 1,
        LOWER_THEN: 2,
        EQUALS: 3,
    },
    DATE_COMPARE_TYPE: {
        BETWEEN: 0,
        LAST_7_DAYS: 1,
        LAST_14_DAYS: 2,
        LAST_30_DAYS: 3,
    },
    OPTIONS_COMPARE_TYPE: {
        EQUALS: 0,
        NOT_EQUALS: 1,
    },
    templateColumns: {
        date: 'DateTime',
        price: 'Number',
        isHave: 'Boolean',
        type: 'List',
        itemSubtitle: 'String',
        itemTitle: 'String',
        key: 'String',
    },
    SORT: {
        SORT_BY: 'SORT_BY',
        ORDER: 'ORDER',
    },
    ORDER: {
        ASCENDING: 'ASCENDING',
        DESCENDING: 'DESCENDING',
    },
    SET_DATA_SORT_FILTER: 'SET_DATA_SORT_FILTER',
    LIMIT_PAGINATION: 20,
    DESCRIPTOR_TYPE: {
        LOCAL: 'LOCAL',
        REALTIME_DATABASE: 'REALTIME_DATABASE',
    },
    CALENDAR_MODE: {
        DAY: 'D',
        WEEK: 'W',
        MONTH: 'M',
    },
    TYPE_ERROR: {
        VALIDATE: 'VALIDATE',
        SEND_TO_STORAGE: 'SEND_TO_STORAGE',
    },
    TIME_OUT_HIGHLIGHT: 1000,
    SUFFIX_LINK_REDIRECT: '__hostnameInfo',
    NUMBER_TAB_VIEW: 4,
    MORE_SCREEN_NAME: 'More',
    CURRENT_VALUE: 'CurrentValue',
    CURRENT_ROW: 'CurrentRow',
    SOURCE_START_UP: 'SOURCE_START_UP',
    REGION_DEFAULT: {
        latitude: 21.0253125,
        longitude: 105.8466135,
    },
}

export default appConstants

export const DEFAULT_FP_ICON = 'home'
export const DEFAULT_FP_TITLE = 'Untitle'
