//
//  ARNDateTimeFormStore.ts
//
//  Created by thaitd on 2022-08-12 14:08.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { DateTimeFormInputStore } from '@appformula/app-studio-core/src'
import { Nullable } from '@appformula/shared-foundation-x/src'
import { action, makeObservable, observable } from 'mobx'
import { Subject, Subscription } from 'rxjs'
import appConstants from '../../../../constants/const'
import { FormulaManager } from '../../../../utils/integrate-formula/FormulaManager'
import { TransformBinding } from '../../../../utils/transform-binding/TransformBinding'
import { ARNFormPageStore, DateTimeFormData } from '../../ARNFormPageStore'
import { BaseARNFormStore } from '../BaseARNFormStore'

export class ARNDateTimeFormStore implements BaseARNFormStore {
    readonly formPageId: string
    readonly componentId: string
    value: Nullable<Date>
    arnFormPageStore: ARNFormPageStore
    valueChange: Subject<void>
    formulaManager?: FormulaManager

    constructor(
        arnFormPageStore: ARNFormPageStore,
        formPageId: string,
        componentId: string,
        initData?: DateTimeFormData,
        formulaManager?: FormulaManager
    ) {
        this.arnFormPageStore = arnFormPageStore
        this.formPageId = formPageId
        this.componentId = componentId
        this.value = initData?.value ?? undefined
        this.valueChange = new Subject()
        this.formulaManager = formulaManager

        makeObservable(this, {
            value: observable,
            setValue: action,
        })
    }

    startListeningEvents(): Subscription[] {
        const valueChangeSubscription = this.valueChange.subscribe({
            next: () => {
                this.arnFormPageStore.clearFormScreenError(this.componentId)
            },
        })

        return [valueChangeSubscription]
    }

    setValue(value: Date) {
        this.value = value
        this.valueChange.next()
    }

    async validate(): Promise<void> {
        try {
            const componentStore =
                this.arnFormPageStore.pageStore?.componentStore(
                    this.componentId
                ) as DateTimeFormInputStore

            if (componentStore.required && !this.value) {
                return Promise.reject(
                    `${componentStore.titleName} is required!`
                )
            }

            if (
                this.formulaManager &&
                componentStore?.formulaValidator?.formula
            ) {
                await this.formulaManager.registContextVariable(
                    appConstants.CURRENT_VALUE,
                    this.value
                )

                const isValid = await this.formulaManager.execute(
                    componentStore.formulaValidator.formula,
                    {
                        value: this.value,
                    }
                )

                if (!isValid) {
                    const errorMessage = await TransformBinding(
                        componentStore.formulaValidator.errorMessage
                    )
                    return Promise.reject(errorMessage)
                }
            }
        } catch (error) {
            return Promise.reject('Error Validate')
        }
    }
}
