//
//  FilterSliderStore.ts
//
//  Created by thaitd96 on 2022-07-28 10:24.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { action, makeObservable, observable } from 'mobx'
import { ColumnFilter, FilterSliderData } from '../CollectionFilterStore'
import appConstants from '../../../../../constants/const'
import { Nullable } from '@appformula/shared-foundation-x'
import { IDatabaseContext } from '../../../../../context/AppDatabaseContext'

export class FilterSliderStore {
    readonly collectionPageId: string
    readonly columnFilter: ColumnFilter
    type: number
    low: Nullable<number> = undefined
    high: Nullable<number> = undefined
    value: string

    max: Nullable<number> = undefined
    min: Nullable<number> = undefined

    constructor(collectionPageId: string, filterSliderData: FilterSliderData) {
        this.collectionPageId = collectionPageId
        this.columnFilter = filterSliderData.columnFilter
        this.type =
            filterSliderData.type ?? appConstants.NUMBER_COMPARE_TYPE.BETWEEN
        this.low = filterSliderData.low
        this.high = filterSliderData.high
        this.value = filterSliderData.value ?? ''

        makeObservable(this, {
            type: observable,
            low: observable,
            high: observable,
            setLow: action,
            setHigh: action,
            value: observable,
            setType: action,
            setValue: action,
            max: observable,
            min: observable,
            setMax: action,
            setMin: action,
            getMaxMinOfTable: action,
        })
    }

    setType(type: number) {
        this.type = type
    }

    setValue(value: string) {
        this.value = value
    }

    setLow(low: number) {
        this.low = low
    }

    setHigh(high: number) {
        this.high = high
    }

    setMax(max: number) {
        this.max = max
    }

    setMin(min: number) {
        this.min = min
    }

    async getMaxMinOfTable(
        databaseAction: IDatabaseContext,
        tableName: string,
        columnName: string
    ) {
        try {
            await databaseAction
                .getMaxMinValueOfTable(tableName, columnName)
                .then((result: Record<'Max' | 'Min', number>) => {
                    console.info({ result })
                    if (result) {
                        this.setMin(result.Min)
                        this.setMax(result.Max)
                    }
                })
        } catch (error) {
            console.info('Get max min slider error: ', error)
        }
    }
}
