import React from 'react';
import { Animated, TextProps } from 'react-native';
const AppAnimatedText: React.FC<TextProps> = (props) => {
    return (
        <Animated.Text {...props} allowFontScaling={false}>
            {props.children}
        </Animated.Text>
    );
};

export default AppAnimatedText;
