//
//  SectionHeaders.tsx
//
//  Created by thaitd96 on 2022-06-13 14:00.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React, { useEffect, useMemo } from 'react'
import appConstants from '../../../../constants/const'
import { observer } from 'mobx-react-lite'
import { FlatList, StyleSheet, View } from 'react-native'
import { Theme, useTheme } from '../../../../styles/theme'
import { CalendarLayoutStore } from './CalendarLayoutStore'
import ItemGroupCalendar, { GroupDisplay } from './ItemGroupCalendar'

export const SectionHeaders = observer(
    (props: {
        calendarLayoutStore: CalendarLayoutStore
        groupsDisplay: GroupDisplay[]
    }) => {
        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])

        // useEffect(() => {
        //     const activeSections = (
        //         Object.values(props.groupsDisplay || {}) as GroupDisplay[]
        //     ).map((item) => item.columnValue)
        //     props.calendarLayoutStore.setActiveSections(activeSections)
        // }, [props.calendarLayoutStore, props.groupsDisplay])

        const renderItem = ({
            item,
            index,
        }: {
            item: GroupDisplay
            index: number
        }) => {
            return (
                <ItemGroupCalendar
                    item={item}
                    activeSections={props.calendarLayoutStore.activeSections}
                    calendarLayoutStore={props.calendarLayoutStore}
                />
            )
        }

        const keyExtractor = (item: GroupDisplay, index: number) => `${index}`

        return (
            <View style={styles.calendarHeader}>
                <FlatList
                    contentContainerStyle={styles.flatList}
                    horizontal
                    showsHorizontalScrollIndicator={false}
                    data={props.groupsDisplay}
                    keyExtractor={keyExtractor}
                    renderItem={renderItem}
                />
            </View>
        )
    }
)

const createStyles = ({ colorScheme }: Theme) =>
    StyleSheet.create({
        flatList: {
            paddingLeft: appConstants.PADDING_COMMON,
            alignItems: 'center',
        },
        calendarHeader: {
            width: '100%',
            height: 59,
            borderTopWidth: 1,
            borderBottomWidth: 1,
            borderColor: colorScheme.separator,
            backgroundColor: colorScheme.backgroundL1,
        },
    })
