import { AlertButton, AlertStatic } from 'react-native'

class WebAlert implements Pick<AlertStatic, 'alert'> {
    public alert(
        title: string,
        message?: string,
        buttons?: AlertButton[]
    ): void {
        if (buttons === undefined || buttons.length === 0) {
            window.alert(message)
            return
        }

        const result = window.confirm(message)

        if (result === true) {
            const confirm = buttons.find(({ style }) => style !== 'cancel')
            confirm?.onPress?.()
            return
        }

        const cancel = buttons.find(({ style }) => style === 'cancel')
        cancel?.onPress?.()
    }
}

export const Alert = new WebAlert()
