import { View, Text, Pressable, Image, StyleSheet } from 'react-native'
import React, { useEffect, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { LookupFormInputStore } from '@appformula/app-studio-core/src'
import image from '../../../../assets/icons'
import { Theme, useTheme } from '../../../../styles/theme'
import appConstants from '../../../../constants/const'
import { useNavigation } from '@react-navigation/native'
import screenNames from '../../../../navigations/screenNames'
import LookupsResult from './lookupOptionsPage/LookupsResult'
import { ARNLookupFormStore } from './ARNLookupFormStore'
import { useBinding } from '../../../collection/components/hooks/useBinding'

const LookupFormInput = observer(
    (props: {
        lookupFormInput: LookupFormInputStore
        arnLookupFormStore: ARNLookupFormStore
    }) => {
        const { lookupFormInput } = props
        const allowsMultipleSelection = lookupFormInput.allowsMultipleSelection
        return (
            <LookupFormInputContent
                lookupFormInput={props.lookupFormInput}
                arnLookupFormStore={props.arnLookupFormStore}
                allowsMultipleSelection={allowsMultipleSelection}
            />
        )
    }
)

const LookupFormInputContent = observer(
    (props: {
        lookupFormInput: LookupFormInputStore
        arnLookupFormStore: ARNLookupFormStore
        allowsMultipleSelection: boolean
    }) => {
        const { lookupFormInput, allowsMultipleSelection, arnLookupFormStore } =
            props
        const lookupFormInputData = lookupFormInput.object

        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])
        const navigation = useNavigation()

        const lookupsSelected = arnLookupFormStore.data || {}

        const isHidden = useBinding(lookupFormInput.isHidden)

        useEffect(() => {
            const subscriptions = arnLookupFormStore.startListeningEvents()

            return () => {
                subscriptions.forEach((s) => s.unsubscribe())
            }
        }, [arnLookupFormStore])

        useEffect(() => {
            arnLookupFormStore.setLookupFormInput({
                ...lookupFormInputData,
                allowsMultipleSelection,
            })
        }, [allowsMultipleSelection, arnLookupFormStore, lookupFormInputData])

        useEffect(() => {
            arnLookupFormStore.setData(null)
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [allowsMultipleSelection, lookupFormInput.componentId])

        const pickLookup = () => {
            navigation.navigate(
                screenNames.LOOKUP_OPTIONS as never,
                { componentId: lookupFormInput.componentId } as never
            )
        }

        const renderListSelected = () => {
            return <LookupsResult arnLookupFormStore={arnLookupFormStore} />
        }

        const hasSelectedMulti =
            Object.keys(lookupsSelected).length > 0 && allowsMultipleSelection
        const hasSelectedSingle =
            Object.keys(lookupsSelected).length > 0 && !allowsMultipleSelection

        return isHidden ? null : (
            <View style={styles.container}>
                <Pressable style={styles.btn} onPress={pickLookup}>
                    <View style={styles.wrapperHeader}>
                        <View style={styles.wrapperTitle}>
                            <Text style={styles.txtTitle}>
                                {lookupFormInput.titleName}
                            </Text>
                            {lookupFormInputData.required && (
                                <View style={styles.requireDot} />
                            )}
                        </View>
                        {Object.keys(lookupsSelected).length === 0 && (
                            <Image
                                source={image.arrowRight}
                                style={styles.imgArrowRight}
                            />
                        )}
                    </View>
                </Pressable>
                {hasSelectedMulti && (
                    <View>
                        {renderListSelected()}
                        <Pressable style={styles.btnAdd} onPress={pickLookup}>
                            <Image source={image.add} style={styles.imgAdd} />
                            <Text style={styles.txtAdd}>Add more...</Text>
                        </Pressable>
                    </View>
                )}
                {hasSelectedSingle && renderListSelected()}
            </View>
        )
    }
)

export default LookupFormInput

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        txtAdd: {
            color: colorScheme.accentColor,
            fontSize: 15,
            fontWeight: '500',
            lineHeight: 18.15,
        },
        imgAdd: {
            width: 24,
            height: 24,
            tintColor: colorScheme.accentColor,
            marginRight: 10,
        },
        btnAdd: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingHorizontal: appConstants.PADDING_COMMON,
            paddingVertical: 15,
        },
        btn: { flex: 1, paddingVertical: 24 },
        txtTitle: {
            fontWeight: '500',
            fontSize: 16,
            lineHeight: 19.36,
            color: colorScheme.labelPrimary,
        },
        imgArrowRight: { width: 20, height: 20 },
        wrapperHeader: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingHorizontal: appConstants.PADDING_COMMON,
        },
        container: {
            borderBottomColor: colorScheme.separator,
            borderBottomWidth: 1,
        },
        requireDot: {
            height: 6,
            width: 6,
            borderRadius: 3,
            backgroundColor: 'red',
            marginLeft: 8,
        },
        wrapperTitle: { flexDirection: 'row', alignItems: 'center' },
    })
