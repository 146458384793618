import {
    Image,
    Linking,
    NativeModules,
    Platform,
    Pressable,
    SafeAreaView,
    StyleSheet,
    Text,
    View,
} from 'react-native'
import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { Theme, useTheme } from '../../styles/theme'
import { useNavigation } from '@react-navigation/native'
import screenNames from '../../navigations/screenNames'
import image from '../../assets/icons'
import { ScrollView } from 'react-native-gesture-handler'
import ClientAppButton from './components/ClientAppButton'
import { iconApps } from '../../assets/icons/icon-apps'
import { AppType, VerificationType, WelcomePageStore } from './WelcomePageStore'
import dynamicLinks, {
    FirebaseDynamicLinksTypes,
} from '@react-native-firebase/dynamic-links'
import buildConfig from '../../../buildConfig.json'
import { Nullable } from 'packages/shared/foundation-x/src/lib/Nullable'
import * as LinkingExpo from 'expo-linking'

const { Email } = NativeModules

type AppPrefix = 'AppleMail' | 'Gmail' | 'Outlook'
type AppURL = {
    appURL: string
    webURL?: string
}

const appPrefix: Record<AppPrefix, AppURL> = {
    AppleMail: {
        appURL: 'message://',
    },
    Gmail: {
        appURL: 'googlegmail://',
        webURL: 'https://mail.google.com/mail',
    },
    Outlook: {
        appURL: 'ms-outlook://',
        webURL: 'https://outlook.live.com/mail',
    },
}

enum ActionCodeOperation {
    PASSWORD_RESET = 'PASSWORD_RESET',
    VERIFY_EMAIL = 'VERIFY_EMAIL',
    RECOVER_EMAIL = 'RECOVER_EMAIL',
    EMAIL_SIGNIN = 'EMAIL_SIGNIN',
    ERROR = 'ERROR',
}

type FirebaseLinkParams = {
    apiKey: string
    continueUrl: string
    lang: string
    mode: string
    oobCode: string
    tenantId: string
}

const VerificationScreen = observer((props: { store: WelcomePageStore }) => {
    const theme = useTheme()
    const styles = useMemo(() => createStyles(theme), [theme])
    const navigation = useNavigation()

    const { store } = props

    const onBack = React.useCallback(() => {
        navigation.navigate(screenNames.SIGN_IN as never)
    }, [navigation])

    const handleOpenApp = React.useCallback((app: AppURL) => {
        if (Platform.OS === 'android') {
            if (!Email) {
                console.warn('Email module is not found!')
            } else {
                Email.open(app.appURL)
            }
            return
        }

        const url: Nullable<string> =
            Platform.OS === 'web' ? app.webURL : app.appURL

        if (url) {
            Linking.canOpenURL(url).then(async (isSupported: boolean) => {
                if (isSupported) {
                    await Linking.openURL(url)
                } else {
                    console.warn('Cannot open', url)
                }
            })
        }
    }, [])

    const handleOpenGmail = React.useCallback(() => {
        handleOpenApp(appPrefix.Gmail)
    }, [handleOpenApp])
    const handleOpenAppleMail = React.useCallback(() => {
        handleOpenApp(appPrefix.AppleMail)
    }, [handleOpenApp])

    const handleOpenOutlook = React.useCallback(() => {
        handleOpenApp(appPrefix.Outlook)
    }, [handleOpenApp])

    // React.useEffect(() => {
    //     const emailLink =
    //         'https://appformula.page.link?link=https://appformula-dev.firebaseapp.com/__/auth/action?apiKey%3DAIzaSyBnsR7rKqAjmHqnIxE-2fFTWyBzIt2kMeY%26mode%3DverifyEmail%26oobCode%3Ds7fIu_AEe0Od_I0yWfroglJC4HhqYCmAjAgjkObgvfUAAAGEwatGzg%26continueUrl%3Dhttps://appformula.dev/%26lang%3Den%26tenantId%3DVEFLBjsQXypNOIlDMXNk-q6x33&apn=co.unstatic.afrn&amv&ibi=co.unstatic.appformulago&ifl=https://appformula-dev.firebaseapp.com/__/auth/action?apiKey%3DAIzaSyBnsR7rKqAjmHqnIxE-2fFTWyBzIt2kMeY%26mode%3DverifyEmail%26oobCode%3Ds7fIu_AEe0Od_I0yWfroglJC4HhqYCmAjAgjkObgvfUAAAGEwatGzg%26continueUrl%3Dhttps://appformula.dev/%26lang%3Den%26tenantId%3DVEFLBjsQXypNOIlDMXNk-q6x33'
    //     const link = LinkingExpo.parse(emailLink).queryParams?.[
    //         'link'
    //     ] as string
    //
    //     console.info('test-link', link)
    //     if (link) {
    //         const queryParams = LinkingExpo.parse(link)
    //             .queryParams as FirebaseLinkParams
    //         // console.info({ queryParams })
    //         // console.info(
    //         //     'queryParams',
    //         //     queryParams.oobCode,
    //         //     queryParams.tenantId
    //         // )
    //     }
    // }, [])

    React.useEffect(() => {
        const handleDynamicLink = (
            link: FirebaseDynamicLinksTypes.DynamicLink
        ) => {
            // const url = new URL(link.url)
            // const tenantId = url.searchParams.get('tenantId')
            // const oobCode = url.searchParams.get('oobCode')

            const queryParams = LinkingExpo.parse(link.url)
                .queryParams as FirebaseLinkParams
            console.info({ queryParams })
            console.info(
                'queryParams',
                queryParams.oobCode,
                queryParams.tenantId
            )

            const tenantId = queryParams.tenantId
            const oobCode = queryParams.oobCode

            if (
                oobCode &&
                (store.appType === AppType.INTERNAL ||
                    (store.appType === AppType.EXTERNAL &&
                        tenantId === buildConfig.tenantId))
            ) {
                store.checkActionCode(oobCode).then((actionCodeInfo) => {
                    if (
                        store.verificationType ===
                            VerificationType.CREATE_ACCOUNT &&
                        actionCodeInfo.operation ===
                            ActionCodeOperation.VERIFY_EMAIL
                    ) {
                        store.applyActionCode(oobCode).then(() => {
                            navigation.navigate(
                                screenNames.VERIFICATION_SUCCESS as never
                            )
                        })
                    } else if (
                        store.verificationType ===
                            VerificationType.RESET_PASSWORD &&
                        actionCodeInfo.operation ===
                            ActionCodeOperation.PASSWORD_RESET
                    ) {
                        store.verifyPasswordResetCode(oobCode).then(() => {
                            navigation.navigate(
                                screenNames.SETUP_PASSWORD as never
                            )
                        })
                    }
                })
            }
        }
        const unsubscribe =
            Platform.OS !== 'web'
                ? dynamicLinks().onLink(handleDynamicLink)
                : () => {
                      //
                  }

        return () => unsubscribe()
    }, [navigation, store])

    return (
        <SafeAreaView style={styles.container}>
            <Pressable style={styles.containerHeaderLeft} onPress={onBack}>
                <Image
                    style={styles.backImg}
                    source={image.back}
                    resizeMode={'contain'}
                />
                <Text style={styles.txtHeaderLeft}>Login</Text>
            </Pressable>
            <ScrollView contentContainerStyle={styles.containerScroll}>
                {store.verificationType === VerificationType.CREATE_ACCOUNT ? (
                    <>
                        <Text style={styles.txtTitle}>Verification Needed</Text>
                        <Text style={styles.txtSubTitle}>
                            You’ll need to verify that you’re owner of the email{' '}
                            <Text style={styles.emailSubTitle}>
                                {store.getCurrentUser?.email}
                            </Text>
                            . Please open the email we sent to continue.
                        </Text>
                    </>
                ) : (
                    store.verificationType ===
                        VerificationType.RESET_PASSWORD && (
                        <>
                            <Text style={styles.txtTitle}>
                                Check your Email
                            </Text>
                            <Text style={styles.txtSubTitle}>
                                We’ve sent a link with an instruction of how to
                                reset your password. Please follow the link in
                                the email.
                            </Text>
                        </>
                    )
                )}
                <Text style={styles.txtSubTitle}>
                    Quick open a mail client app
                </Text>
                <View style={styles.clientAppContainer}>
                    <ClientAppButton
                        title={'Gmail'}
                        icon={iconApps.iconGmail}
                        onPress={handleOpenGmail}
                    />
                    <ClientAppButton
                        title={'Outlook'}
                        icon={iconApps.iconOutlook}
                        onPress={handleOpenOutlook}
                    />
                    {Platform.OS === 'ios' && (
                        <ClientAppButton
                            title={'Mail'}
                            icon={iconApps.iconMail}
                            onPress={handleOpenAppleMail}
                        />
                    )}
                </View>
            </ScrollView>
        </SafeAreaView>
    )
})

export default VerificationScreen

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        backImg: {
            width: 28,
            height: 28,
            tintColor: colorScheme.accentColor,
            marginLeft: 8,
        },
        containerHeaderLeft: { flexDirection: 'row', alignItems: 'center' },
        txtHeaderLeft: {
            color: colorScheme.accentColor,
            fontWeight: '500',
            fontSize: 17,
            lineHeight: 21,
        },
        container: {
            flex: 1,
            backgroundColor: colorScheme.backgroundL1,
            paddingTop: 20,
        },
        containerScroll: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            paddingHorizontal: 24,
        },
        txtTitle: {
            fontWeight: '700',
            fontSize: 30,
            lineHeight: 36.31,
            color: colorScheme.labelPrimary,
            marginBottom: 9,
        },
        txtSubTitle: {
            fontWeight: '400',
            fontSize: 15,
            lineHeight: 22.5,
            color: colorScheme.labelSecondary,
            textAlign: 'center',
            marginBottom: 20,
        },
        emailSubTitle: {
            color: colorScheme.accentColor,
            fontSize: 15,
            lineHeight: 22.5,
            fontWeight: '400',
        },
        input: {
            width: '100%',
            height: 50,
            marginBottom: 12,
            borderWidth: 1,
            padding: 10,
            borderRadius: roundness,
            borderColor: colorScheme.separator,
        },
        wrongConfirm: {
            borderColor: '#EB1260',
        },
        txtPrivacy: {
            textAlign: 'center',
            color: colorScheme.labelSecondary,
            marginTop: 12,
            fontSize: 13,
            lineHeight: 19.5,
            fontWeight: '400',
        },
        txtDetailPrivacy: {
            color: colorScheme.accentColor,
            fontSize: 13,
            lineHeight: 19.5,
            fontWeight: '400',
        },
        clientAppContainer: {
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
        },
    })
