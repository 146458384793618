//
//  ARNFileFormStore.ts
//
//  Created by thaitd on 2022-08-12 14:08.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { makeObservable } from 'mobx'
import { Subject, Subscription } from 'rxjs'
import { FormulaManager } from '../../../../utils/integrate-formula/FormulaManager'
import { ARNFormPageStore } from '../../ARNFormPageStore'
import { BaseARNFormStore } from '../BaseARNFormStore'

export type FileUploaded = {
    assetUrl: URL
}

export class ARNFileFormStore implements BaseARNFormStore {
    readonly formPageId: string
    readonly componentId: string
    arnFormPageStore: ARNFormPageStore
    listFileUploaded: FileUploaded[]
    listFileUploadedChange: Subject<void>
    formulaManager?: FormulaManager

    constructor(
        arnFormPageStore: ARNFormPageStore,
        formPageId: string,
        componentId: string,
        formulaManager?: FormulaManager
    ) {
        this.arnFormPageStore = arnFormPageStore
        this.formPageId = formPageId
        this.componentId = componentId
        this.listFileUploaded = []
        this.listFileUploadedChange = new Subject()
        this.formulaManager = formulaManager

        makeObservable(this, {})
    }

    startListeningEvents(): Subscription[] {
        const valueChangeSubscription = this.listFileUploadedChange.subscribe({
            next: () => {
                this.arnFormPageStore.clearFormScreenError(this.componentId)
            },
        })

        return [valueChangeSubscription]
    }

    async validate(): Promise<void> {
        const componentStore = this.arnFormPageStore.pageStore?.componentStore(
            this.componentId
        )

        if (componentStore?.required && !this.listFileUploaded?.length) {
            return Promise.reject(`${componentStore.titleName} is required!`)
        }
    }

    addFileUploaded(fileUpload: FileUploaded) {
        this.listFileUploaded.push(fileUpload)
        this.listFileUploadedChange.next()
    }
}
