//
//  Divider.tsx
//
//  Created by thaitd96 on 2022-20-04 11:52.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import appConstants from '../../../constants/const'
import React, { useMemo } from 'react'
import { StyleSheet, View } from 'react-native'
import { Divider as DividerDescriptor } from '../../../../../../packages/app-descriptor/src'
import { Theme, useTheme } from '../../../styles/theme'
import { observer } from 'mobx-react-lite'

export const Divider = observer((props: { divider: DividerDescriptor }) => {
    const { divider } = props
    const theme = useTheme()
    const styles = useMemo(() => createStyles(theme), [theme])

    return <View style={styles.viewDivider} />
})

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        viewDivider: {
            height: 1,
            width: '100%',
            marginVertical: appConstants.PADDING_COMMON / 2,
            backgroundColor: colorScheme.separator,
        },
    })
