import React from 'react'
import ReactPlayer from 'react-player'

type IPlayer = {
    videoUrl: string
    height: number
    width: number
}

const Player = ({ videoUrl, height, width }: IPlayer) => {
    return <ReactPlayer url={videoUrl} controls={true} height={'100%'} width={'100%'}/>
}

export default Player
