//
//  NumberSliderInput.tsx
//
//  Created by thaitd96 on 2022-20-04 11:54.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { NumberSliderInputStore } from '@appformula/app-studio-core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Platform, StyleSheet, Text, View } from 'react-native'
import { Theme, useTheme } from '../../../styles/theme'
import { observer } from 'mobx-react-lite'
import { hexToRGBA } from '../../../utils'
import appConstants from '../../../constants/const'
import Slider from '../../../components/Silder/Slider'
import { COLORS } from '../../../assets/colors'
import { useBindingCommon } from '../../collection/components/hooks/useBindingCommon'

export const NumberSliderInput = observer(
    (props: {
        numberSliderInput: NumberSliderInputStore
        item: Record<string, unknown>
    }) => {
        const { numberSliderInput, item } = props
        const numberSliderInputData = numberSliderInput.object

        const [valueDisplay, setValueDisplay] = useState<number>(0)
        const [minValue, setMinValue] = useState<number>(
            numberSliderInputData?.minValue ?? 0
        )
        const [initValue, setInitValue] = useState<number>(0)
        const [maxValue, setMaxValue] = useState<number>(0)
        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])
        const accentColor = theme.colorScheme.accentColor.toString()
        const title = useBindingCommon(numberSliderInputData.title, item)

        const initialValue = useBindingCommon(
            numberSliderInputData.initialValue,
            item
        ) as number

        const isHidden = useBindingCommon(
            numberSliderInputData.isHidden,
            props.item
        )

        useEffect(() => {
            let min: number = numberSliderInputData?.minValue ?? 0
            const max: number = numberSliderInputData?.maxValue ?? 0
            let value: number = initialValue as number
            if (initialValue > max) {
                value = max
            }

            if (initialValue < min) {
                value = min
            }

            if (min > max) {
                min = max
            }

            setMinValue(min)
            setMaxValue(max)
            setValueDisplay(value)
            setInitValue(value)
        }, [initialValue, numberSliderInputData])

        const onValueChange = useCallback((value: number) => {
            setValueDisplay(Math.floor(value))
        }, [])

        return (
            !isHidden && (
                <View style={styles.viewNumberSliderInput}>
                    <View style={styles.title}>
                        <Text style={styles.titleContent} numberOfLines={1}>
                            {title || ''}
                        </Text>
                        <Text style={styles.value}>{valueDisplay}</Text>
                    </View>
                    <Slider
                        styles={styles.sliderEl}
                        minimumValue={minValue}
                        maximumValue={maxValue}
                        minimumTrackTintColor={accentColor}
                        maximumTrackTintColor={hexToRGBA(
                            theme.colorScheme.accentColor as string,
                            0.1
                        )}
                        onValueChange={onValueChange}
                        thumbTintColor={accentColor}
                        thumbStyle={{
                            borderColor: COLORS.WHITE,
                            borderWidth: 2,
                        }}
                        value={initValue}
                    />
                </View>
            )
        )
    }
)

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        viewNumberSliderInput: {
            flex: 1,
            marginVertical: appConstants.PADDING_COMMON / 2,
            ...Platform.select({
                web: {
                    marginBottom: appConstants.PADDING_COMMON + 20,
                },
            }),
        },

        sliderEl: {
            height: 40,
            marginHorizontal: 0,
            paddingHorizontal: 0,
        },

        title: {
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: Platform.OS === 'web' ? 18 : 0,
        },

        titleContent: {
            flex: 1,
            fontWeight: '500',
            fontSize: 16,
            lineHeight: 19,
            color: colorScheme.labelPrimary,
        },

        value: {
            fontWeight: '500',
            fontSize: 15,
            lineHeight: 18.15,
            color: colorScheme.labelSecondary,
        },
    })
