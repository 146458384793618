// //
// //  Component.tsx
// //
// //  Created by thaitd96 on 2022-20-04 10:12.
// //  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
// //

import React from 'react'
import { TextComponent } from './TextComponent'
import { Audio } from './Audio'
import { Button } from './Button'
import { CarouselView } from './CarouselView'

import { FilePreview } from './FilePreview'

import { Repeater } from './Repeater'

import { WebLink } from './WebLink'
import { DateRangeInput } from './DateRangeInput'

import { NumberSliderInput } from './NumberSliderInput'
import { PeopleInput } from './PeopleInput'
import { ProgressBar } from './ProgressBar'
import { BooleanField } from './BooleanField'
import {
    AudioStore,
    BarcodeStore,
    BooleanInputStore,
    ButtonStore,
    CarouselStore,
    ChartStore,
    ComponentStore,
    DateRangeInputStore,
    DateTimeInputStore,
    DividerStore,
    FilePreviewStore,
    InlineCollectionStore,
    MapStore,
    MarkdownStore,
    NumberScaleInputStore,
    NumberSliderInputStore,
    OptionsInputStore,
    PeopleInputStore,
    ProgressBarStore,
    RepeaterStore,
    TextComponentStore,
    TextInputStore,
    VideoStore,
    WebLinkStore,
} from '@appformula/app-studio-core'
import { Nullable } from '@appformula/shared-foundation-x'
import { observer } from 'mobx-react-lite'
import InlineCollection from './InlineCollection'
import { Image } from './Image'
import { ImageStore } from '@appformula/app-studio-core/src/lib/pages/custom/components/Image'
import { OptionsInput } from './OptionInput'
import { DateTimeField } from './DatetimeField'
import Chart from './Chart'
import { Markdown } from './MarkDown'
import Video from './Video'
import { NumberScaleInput } from './NumberScaleInput'
import { TextInput } from './TextInput'
import { Divider } from './Divider'
import { ARNCustomDetailPageStore } from '../ARNCustomDetailPageStore'
import BarcodeView from './BarcodeView'
import Map from './Map'

export const Component = observer(
    (props: {
        componentStore: Nullable<ComponentStore>
        item?: Record<string, unknown>
        arnPageStore: ARNCustomDetailPageStore
    }) => {
        const { componentStore, item, arnPageStore } = props

        switch (componentStore?.componentType) {
            case 'audio':
                return (
                    <Audio audio={componentStore as AudioStore} item={item} />
                )
            case 'button':
                return (
                    <Button
                        button={componentStore as ButtonStore}
                        item={item}
                    />
                )
            case 'carousel':
                return (
                    <CarouselView
                        carousel={componentStore as CarouselStore}
                        item={item}
                    />
                )
            case 'chart':
                return (
                    <Chart
                        chart={componentStore as ChartStore}
                        item={item}
                        pageStore={arnPageStore}
                    />
                )
            case 'filePreview':
                return (
                    <FilePreview
                        filePreview={componentStore as FilePreviewStore}
                        item={item}
                    />
                )
            case 'image':
                return (
                    <Image image={componentStore as ImageStore} item={item} />
                )
            case 'inlineCollection':
                return (
                    <InlineCollection
                        inlineCollection={
                            componentStore as InlineCollectionStore
                        }
                        item={item}
                    />
                )
            case 'map':
                return (
                    <Map
                        map={componentStore as MapStore}
                        item={item}
                        pageStore={arnPageStore}
                    />
                )

            case 'markdown':
                return (
                    <Markdown
                        markdown={componentStore as MarkdownStore}
                        item={item}
                    />
                )
            case 'repeater':
                return (
                    <Repeater
                        repeater={componentStore as unknown as RepeaterStore}
                        item={item}
                    />
                )
            case 'text':
                return (
                    <TextComponent
                        textComponent={componentStore as TextComponentStore}
                        item={item}
                    />
                )
            case 'video':
                return (
                    <Video video={componentStore as VideoStore} item={item} />
                )
            case 'webLink':
                return (
                    <WebLink
                        webLink={componentStore as WebLinkStore}
                        item={item}
                    />
                )
            case 'dateRangeInput':
                return (
                    <DateRangeInput
                        dateRangeInput={componentStore as DateRangeInputStore}
                        item={item}
                    />
                )
            case 'divider':
                return <Divider divider={componentStore as DividerStore} />
            case 'numberScaleInput':
                return (
                    <NumberScaleInput
                        numberScaleInput={
                            componentStore as NumberScaleInputStore
                        }
                        item={item}
                    />
                )
            case 'numberSliderInput':
                return (
                    <NumberSliderInput
                        numberSliderInput={
                            componentStore as NumberSliderInputStore
                        }
                        item={item}
                    />
                )
            case 'optionsInput':
                return (
                    <OptionsInput
                        optionsInput={componentStore as OptionsInputStore}
                        item={item}
                    />
                )
            case 'peopleInput':
                return (
                    <PeopleInput
                        peopleInput={componentStore as PeopleInputStore}
                        item={item}
                    />
                )
            case 'progressBar':
                return (
                    <ProgressBar
                        progressBar={componentStore as ProgressBarStore}
                        item={item}
                    />
                )
            case 'booleanInput':
                return (
                    <BooleanField
                        booleanInput={componentStore as BooleanInputStore}
                        item={item}
                    />
                )
            case 'dateTimeInput':
                return (
                    <DateTimeField
                        dateTimeInput={componentStore as DateTimeInputStore}
                        item={item}
                    />
                )
            case 'textInput':
                return (
                    <TextInput
                        textInput={componentStore as TextInputStore}
                        item={item}
                    />
                )
            case 'barcode':
                return (
                    <BarcodeView
                        barcodeInput={componentStore as BarcodeStore}
                        item={item}
                    />
                )
            default:
                return null
        }
    }
)
