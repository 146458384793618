//
//  DayCalendar.tsx
//
//  Created by thaitd96 on 2022-06-13 15:36.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import {
    DefaultSectionT,
    SectionList,
    StyleSheet,
    Text,
    View,
} from 'react-native'
import { Theme, useTheme } from '../../../../../styles/theme'
import { CalendarLayoutStore } from '../CalendarLayoutStore'
import ItemDayCalendar from './ItemDayCalendar'
import { hexToRGBA } from '../../../../../utils'
import { ConvertDateFormat } from '../../../../../utils/date-time/DateTime'
import dayjs from 'dayjs'
import { CollectionPageStore } from '@appformula/app-studio-core'
import { DayCalendarStore } from './DayCalendarStore'

export type typeDataFormarted = 'section' | 'content'

export const DayCalendar = observer(
    (props: {
        calendarLayoutStore: CalendarLayoutStore
        pageStore: CollectionPageStore
        dayCalendarStore: DayCalendarStore
    }) => {
        const data = props.dayCalendarStore.listCalenderView
        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])
        const sectionListRef =
            useRef<SectionList<unknown, DefaultSectionT>>(null)
        // data is ObservableArray not Array and SectionList, Flatlist regards ObservableArray as unsuitable prop
        const dataFormated = useMemo(() => {
            const list = data
                .map((item) => ({
                    title: item.title,
                    data: item.data.slice(),
                }))
                .slice()
            return list
        }, [data])

        useEffect(() => {
            if (dataFormated.length > 0) {
                // sectionListRef.current &&
                //     sectionListRef?.current?.scrollToLocation({
                //         animated: true,
                //         sectionIndex: 0,
                //         itemIndex: 0,
                //     })
            }
        }, [dataFormated])

        const renderItem = useCallback(
            ({ item }) => {
                return (
                    <ItemDayCalendar
                        key={`${item.key}`}
                        item={item}
                        activeSections={
                            props.calendarLayoutStore.activeSections
                        }
                        pageStore={props.pageStore}
                    />
                )
            },
            [props.calendarLayoutStore.activeSections, props.pageStore]
        )

        const keyExtractor = useCallback((item, index) => {
            return `${item.key}`
        }, [])

        const renderHeaderItem = ({
            section,
        }: {
            section: {
                title: unknown
                data: Record<string, unknown>[]
            }
        }) => {
            const isToday = dayjs(section.title as string).isSame(
                dayjs(),
                'day'
            )
            return section.data.length > 0 ? (
                <View
                    style={[
                        styles.sectionHeader,
                        isToday && styles.sectionHeaderToday,
                    ]}
                >
                    <Text
                        style={[
                            styles.textSection,
                            isToday && styles.textSectionToday,
                        ]}
                    >
                        {ConvertDateFormat(
                            section.title as string,
                            'DD dddd'
                        ).toUpperCase()}
                    </Text>
                    {isToday && (
                        <Text
                            style={[
                                styles.textSection,
                                isToday && styles.textSectionToday,
                            ]}
                        >
                            {'TODAY'}
                        </Text>
                    )}
                </View>
            ) : null
        }

        return (
            <View style={styles.container}>
                <SectionList
                    ref={sectionListRef as unknown as React.LegacyRef<any>}
                    sections={dataFormated}
                    renderItem={renderItem}
                    renderSectionHeader={renderHeaderItem}
                    keyExtractor={keyExtractor}
                />
            </View>
        )
    }
)

const createStyles = ({ colorScheme }: Theme) =>
    StyleSheet.create({
        textSection: {
            color: colorScheme.labelPrimary,
            fontSize: 11,
            fontWeight: '600',
            lineHeight: 13.31,
        },

        textSectionToday: {
            color: colorScheme.accentColor,
        },

        sectionHeader: {
            height: 34,
            width: '100%',
            alignItems: 'center',
            backgroundColor: colorScheme.backgroundL2,
            paddingHorizontal: 12,
            flexDirection: 'row',
            justifyContent: 'space-between',
        },

        sectionHeaderToday: {
            backgroundColor: hexToRGBA(
                colorScheme.sectionHeader as string,
                0.1
            ),
        },

        container: {
            flex: 1,
            backgroundColor: colorScheme.backgroundL1,
        },
    })
