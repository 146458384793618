import { Nullable } from '@appformula/shared-foundation-x/src'
import { Q } from '@nozbe/watermelondb'
import appConstants from '../../constants/const'
import { ChildComponentStore } from '../../pages/collection/components/filter/CollectionFilterStore'
import { FilterBooleanStore } from '../../pages/collection/components/filter/filter-boolean/FilterBooleanStore'
import { FilterDateStore } from '../../pages/collection/components/filter/filter-date/FilteDateStore'
import { FilterOptionStore } from '../../pages/collection/components/filter/filter-option/FilterOptionStore'
import { FilterSliderStore } from '../../pages/collection/components/filter/filter-slider/FilterSliderStore'
import { FilterStringStore } from '../../pages/collection/components/filter/filter-string/FilterStringStore'
import { ConvertDateToDateQuery } from '../../utils/date-time/DateTime'
import { toJS } from 'mobx'

export const TransformConditionToSqlWDB = (
    store: ChildComponentStore
): Nullable<Q.Clause> => {
    if (store instanceof FilterOptionStore) {
        const filterOptionStore = store as FilterOptionStore
        const selectedOptions = toJS(filterOptionStore.selectedOptions)
        if (selectedOptions.length > 0) {
            if (
                filterOptionStore.type ===
                appConstants.OPTIONS_COMPARE_TYPE.EQUALS
            ) {
                return Q.where(
                    `${store.columnFilter.columnName}`,
                    Q.oneOf(selectedOptions)
                )
            } else if (
                filterOptionStore.type ===
                appConstants.OPTIONS_COMPARE_TYPE.NOT_EQUALS
            ) {
                return Q.where(
                    `${store.columnFilter.columnName}`,
                    Q.notIn(selectedOptions)
                )
            }
        } else {
            return
        }
    }
    switch (store.columnFilter.dataType) {
        case 'String': {
            const filterStringStore = store as FilterStringStore
            if (
                filterStringStore.value &&
                filterStringStore.type === appConstants.COMPARE_TYPE.IS_EQUAL
            ) {
                return Q.where(
                    `${store.columnFilter.columnName}`,
                    Q.eq(filterStringStore.value)
                )
            } else if (
                filterStringStore.value &&
                filterStringStore.type ===
                    appConstants.COMPARE_TYPE.IS_NOT_EQUAL
            ) {
                return Q.where(
                    `${store.columnFilter.columnName}`,
                    Q.notEq(filterStringStore.value)
                )
            } else if (
                filterStringStore.value &&
                filterStringStore.type === appConstants.COMPARE_TYPE.IS_CONTAIN
            ) {
                return Q.where(
                    `${store.columnFilter.columnName}`,
                    Q.like(`%${Q.sanitizeLikeString(filterStringStore.value)}%`)
                )
            } else if (
                filterStringStore.value &&
                filterStringStore.type ===
                    appConstants.COMPARE_TYPE.IS_NOT_CONTAIN
            ) {
                return Q.where(
                    `${store.columnFilter.columnName}`,
                    Q.notLike(
                        `%${Q.sanitizeLikeString(filterStringStore.value)}%`
                    )
                )
            } else if (
                filterStringStore.value &&
                filterStringStore.type ===
                    appConstants.COMPARE_TYPE.IS_START_WITH
            ) {
                return Q.where(
                    `${store.columnFilter.columnName}`,
                    Q.like(`${Q.sanitizeLikeString(filterStringStore.value)}%`)
                )
            } else if (
                filterStringStore.value &&
                filterStringStore.type === appConstants.COMPARE_TYPE.IS_END_WITH
            ) {
                return Q.where(
                    `${store.columnFilter.columnName}`,
                    Q.like(`%${Q.sanitizeLikeString(filterStringStore.value)}`)
                )
            } else if (
                filterStringStore.value &&
                filterStringStore.type === appConstants.COMPARE_TYPE.IS_EMPTY
            ) {
                return Q.or(
                    Q.where(`${store.columnFilter.columnName}`, Q.eq('')),
                    Q.where(`${store.columnFilter.columnName}`, Q.eq(null))
                )
            } else if (
                filterStringStore.value &&
                filterStringStore.type ===
                    appConstants.COMPARE_TYPE.IS_NOT_EMPTY
            ) {
                return Q.and(
                    Q.where(`${store.columnFilter.columnName}`, Q.notEq('')),
                    Q.where(`${store.columnFilter.columnName}`, Q.notEq(null))
                )
            } else {
                return
            }
        }
        case 'Number': {
            const filterSliderStore = store as FilterSliderStore
            if (
                filterSliderStore.type ===
                    appConstants.NUMBER_COMPARE_TYPE.BETWEEN &&
                filterSliderStore.low &&
                filterSliderStore.high
            ) {
                return Q.and(
                    Q.where(
                        `${filterSliderStore.columnFilter.columnName}`,
                        Q.gte(+filterSliderStore.low)
                    ),
                    Q.where(
                        `${filterSliderStore.columnFilter.columnName}`,
                        Q.lte(+filterSliderStore.high)
                    )
                )
            } else if (
                filterSliderStore.type ===
                    appConstants.NUMBER_COMPARE_TYPE.GREATER_THAN &&
                filterSliderStore.value
            ) {
                return Q.where(
                    `${filterSliderStore.columnFilter.columnName}`,
                    Q.gt(+filterSliderStore.value)
                )
            } else if (
                filterSliderStore.type ===
                    appConstants.NUMBER_COMPARE_TYPE.LOWER_THEN &&
                filterSliderStore.value
            ) {
                return Q.where(
                    `${filterSliderStore.columnFilter.columnName}`,
                    Q.lt(+filterSliderStore.value)
                )
            } else if (
                filterSliderStore.type ===
                    appConstants.NUMBER_COMPARE_TYPE.EQUALS &&
                filterSliderStore.value
            ) {
                return Q.where(
                    `${filterSliderStore.columnFilter.columnName}`,
                    Q.eq(+filterSliderStore.value)
                )
            }
            return
        }
        case 'Boolean': {
            const filterBooleanStore = store as FilterBooleanStore
            if (typeof filterBooleanStore.value === 'boolean') {
                return Q.where(
                    `${filterBooleanStore.columnFilter.columnName}`,
                    Q.eq(filterBooleanStore.value)
                )
            }
            return
        }
        case 'List': {
            const filterOptionStore = store as FilterOptionStore
            if (filterOptionStore.selectedOptions.length > 0) {
                if (
                    filterOptionStore.type ===
                    appConstants.OPTIONS_COMPARE_TYPE.EQUALS
                ) {
                    return Q.where(
                        `${store.columnFilter.columnName}`,
                        Q.oneOf(filterOptionStore.selectedOptions)
                    )
                } else if (
                    filterOptionStore.type ===
                    appConstants.OPTIONS_COMPARE_TYPE.NOT_EQUALS
                ) {
                    return Q.where(
                        `${store.columnFilter.columnName}`,
                        Q.notIn(filterOptionStore.selectedOptions)
                    )
                }
            }
            return
        }
        case 'DateTime': {
            const filterDateStore = store as FilterDateStore
            const { columnName } = filterDateStore.columnFilter
            const { startDate, endDate } = filterDateStore
            if (startDate && endDate) {
                return Q.and(
                    Q.where(
                        `${columnName}`,
                        Q.gte(ConvertDateToDateQuery(startDate))
                    ),
                    Q.where(
                        `${columnName}`,
                        Q.lte(ConvertDateToDateQuery(endDate))
                    )
                )
            } else if (startDate) {
                return Q.where(
                    `${columnName}`,
                    Q.gte(ConvertDateToDateQuery(startDate))
                )
            } else if (endDate) {
                return Q.where(
                    `${columnName}`,
                    Q.lte(ConvertDateToDateQuery(endDate))
                )
            } else {
                return
            }
        }
        default:
            return
    }
}
