import React from 'react'
import { observer } from 'mobx-react-lite'
import { InlineCollectionStore } from '@appformula/app-studio-core';
import HorizontalCollection from './HorizontalCollection';
import VerticalCollection from './VerticalCollection';
import GridCollection from './GridCollection';
import { IData } from '.';

const Layout = observer((props: { inlineCollection: InlineCollectionStore; data: Array<IData> }) => {
    switch (props?.inlineCollection?.object?.activeLayoutType) {
        case 'horizontal':
            return <HorizontalCollection {...props} />
        case 'vertical':
            return <VerticalCollection {...props} />
        case 'grid':
            return <GridCollection {...props} />
        default:
            return null
    }
})

export default Layout
