//
//  Image.tsx
//
//  Created by thaitd96 on 2022-20-04 11:46.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { ImageStore } from '@appformula/app-studio-core/src/lib/pages/custom/components/Image'
import appConstants from '../../../constants/const'
import { observer } from 'mobx-react-lite'
import React, { useMemo } from 'react'
import { StyleSheet, View } from 'react-native'
import FastImage from '../../../components/fast-image/FastImage'
import { Theme, useTheme } from '../../../styles/theme'
import { useBindingCommon } from '../../collection/components/hooks/useBindingCommon'

export const Image = observer(
    (props: { image: ImageStore; item: Record<string, unknown> }) => {
        const { image, item } = props
        const imageData = image.object
        const url = useBindingCommon(imageData.url, item)
        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])
        const isHidden = useBindingCommon(imageData.isHidden, item)

        return (
            !isHidden && (
                <View style={styles.viewImage}>
                    <FastImage
                        style={styles.imageItem}
                        source={{
                            uri: url as unknown as string,
                        }}
                        resizeMode={'contain'}
                    />
                </View>
            )
        )
    }
)

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        viewImage: {
            marginVertical: appConstants.PADDING_COMMON / 2,
        },

        imageItem: {
            // width: appConstants.WIDTH_SCREEN - 32,
            flex: 1,
            height: 284,
            borderRadius: roundness,
        },
    })
