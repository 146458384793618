import { View, StyleSheet, Text } from 'react-native'
import React, { useMemo } from 'react'
import FastImage from '../../../../components/fast-image/FastImage'
import { Theme, useTheme } from '../../../../styles/theme'
import appConstants from '../../../../constants/const'
import { InlineCollectionStore } from '@appformula/app-studio-core/src'
import { observer } from 'mobx-react-lite'
import { useBinding } from '../../../collection/components/hooks/useBinding'
import { useBindingCommon } from '../../../collection/components/hooks/useBindingCommon'

const CollectionHListItem = observer(
    (props: { item: any; inlineCollection: InlineCollectionStore }) => {
        const theme = useTheme()
        const { item, inlineCollection } = props
        const horizontalLayoutData = inlineCollection.object.horizontalLayout
        const imageAspectRatio =
            horizontalLayoutData.imageAspectRatio === '1-1' ? 1 / 1 : 16 / 9
        const widthImage = useMemo(() => {
            return horizontalLayoutData.imageAspectRatio === '1-1' ? 161.5 : 306
        }, [horizontalLayoutData.imageAspectRatio])
        const styles = useMemo(
            () => createStyles(theme, imageAspectRatio, widthImage),
            [imageAspectRatio, theme, widthImage]
        )

        const title = useBindingCommon(horizontalLayoutData.title)
        const imageUrl = useBindingCommon(
            horizontalLayoutData.imageUrl
        )
        const subtitle = useBindingCommon(
            horizontalLayoutData.subtitle
        )

        return (
            <View style={styles.container}>
                <FastImage
                    style={styles.image}
                    source={{
                        uri: imageUrl as string,
                    }}
                    resizeMode={'stretch'}
                />
                <View style={styles.detail}>
                    {item.avatar && (
                        <FastImage
                            style={styles.avatarImg}
                            source={{
                                uri: item.avatar,
                            }}
                            resizeMode={'stretch'}
                        />
                    )}
                    <View style={styles.rightItem}>
                        <Text
                            numberOfLines={3}
                            ellipsizeMode="tail"
                            style={styles.title}
                        >
                            {title}
                        </Text>
                        <Text
                            numberOfLines={1}
                            ellipsizeMode="tail"
                            style={styles.subTitle}
                        >
                            {subtitle}
                        </Text>
                    </View>
                </View>
            </View>
        )
    }
)

export default CollectionHListItem

const createStyles = (
    { colorScheme, roundness, typography }: Theme,
    imageAspectRatio: number,
    widthImage: number
) =>
    StyleSheet.create({
        container: {
            marginRight: appConstants.PADDING_COMMON,
            width: widthImage,
        },
        subTitle: {
            fontWeight: '500',
            fontSize: 13,
            lineHeight: 21,
            color: colorScheme.labelSecondary,
        },
        title: {
            flex: 1,
            fontWeight: '500',
            fontSize: 15,
            color: colorScheme.labelPrimary,
        },
        rightItem: {
            flexShrink: 1,
            paddingLeft: appConstants.PADDING_COMMON,
        },
        detail: {
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: 12,
        },
        image: {
            width: '100%',
            borderRadius: roundness,
            aspectRatio: imageAspectRatio,
        },
        avatarImg: { width: 36, height: 36, borderRadius: 18 },
    })
