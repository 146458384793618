//
//  ARNNumberFormStore.ts
//
//  Created by thaitd on 2022-08-15 10:16.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { NumberFormInputStore } from '@appformula/app-studio-core/src'
import { action, makeObservable, observable } from 'mobx'
import { Subject, Subscription } from 'rxjs'
import appConstants from '../../../../constants/const'
import { FormulaManager } from '../../../../utils/integrate-formula/FormulaManager'
import { TransformBinding } from '../../../../utils/transform-binding/TransformBinding'
import { ARNFormPageStore, NumberFormData } from '../../ARNFormPageStore'
import { BaseARNFormStore } from '../BaseARNFormStore'

export class ARNNumberFormStore implements BaseARNFormStore {
    readonly formPageId: string
    readonly componentId: string
    valueString: string
    arnFormPageStore: ARNFormPageStore
    valueStringChange: Subject<void>
    formulaManager?: FormulaManager

    get valueNumber() {
        return parseFloat(this.valueString)
    }

    constructor(
        arnFormPageStore: ARNFormPageStore,
        formPageId: string,
        componentId: string,
        initData?: NumberFormData,
        formulaManager?: FormulaManager
    ) {
        this.arnFormPageStore = arnFormPageStore
        this.formPageId = formPageId
        this.componentId = componentId
        this.valueString = initData?.value?.toString() ?? null
        this.valueStringChange = new Subject()
        this.formulaManager = formulaManager

        makeObservable(this, {
            valueString: observable,
            setValue: action,
        })
    }

    setValue(value: string) {
        this.valueString = value
        this.valueStringChange.next()
    }

    startListeningEvents(): Subscription[] {
        const valueStringChangeSubscription = this.valueStringChange.subscribe({
            next: () => {
                this.arnFormPageStore.clearFormScreenError(this.componentId)
            },
        })

        return [valueStringChangeSubscription]
    }

    async validate(): Promise<void> {
        try {
            const componentStore =
                this.arnFormPageStore.pageStore.componentStore(
                    this.componentId
                ) as NumberFormInputStore

            if (componentStore.required && !this.valueString?.trim()) {
                return Promise.reject(
                    `${componentStore.titleName} is required!`
                )
            } else if (this.valueString?.trim() && isNaN(this.valueNumber)) {
                return Promise.reject(
                    `${componentStore.titleName} is not a number!`
                )
            }

            if (
                this.formulaManager &&
                componentStore?.formulaValidator?.formula
            ) {
                await this.formulaManager.registContextVariable(
                    appConstants.CURRENT_VALUE,
                    this.valueString
                )

                const isValid = await this.formulaManager.execute(
                    componentStore.formulaValidator.formula,
                    {
                        value: this.valueString,
                    }
                )

                if (!isValid) {
                    const errorMessage = await TransformBinding(
                        componentStore.formulaValidator.errorMessage
                    )
                    return Promise.reject(errorMessage)
                }
            }
        } catch (error) {
            return Promise.reject('Error Validate')
        }
    }
}
