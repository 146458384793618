//
//  AddOptionFormInput.tsx
//
//  Created by thaitd96 on 2022-07-12 11:34.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React, { useMemo, useState } from 'react'
import { Image, StyleSheet, TextInput, View } from 'react-native'
import { Theme, useTheme } from '../../../../styles/theme'
import image from '../../../../assets/icons'
import appConstants from '../../../../constants/const'
import { hexToRGBA } from '../../../../utils'
import { observer } from 'mobx-react-lite'
import { Alert } from '../../../../components/alert'
import { Option } from './OptionFormInputInline'
import { ARNOptionFormInputStore } from './ARNOptionFormInputStore'

type AddOptionFormInputProps = {
    arnOptionFormInputStore: ARNOptionFormInputStore
    isMultipleChoice?: boolean
    isInputPage?: boolean
}

const checkOptionExisted = (options: Option[], optionContent: string) => {
    for (let index = 0; index < options.length; index++) {
        const element = options[index]
        if (element.content === optionContent) return true
    }
    return false
}

const AddOptionFormInput = observer((props: AddOptionFormInputProps) => {
    const {
        arnOptionFormInputStore,
        isMultipleChoice = false,
        isInputPage = false,
    } = props
    const theme = useTheme()
    const styles = React.useMemo(() => createStyles(theme), [theme])

    const [optionContent, setOptionContent] = useState('')

    const onChangeText = (text: string) => {
        setOptionContent(text)
    }

    const onEndEditing = () => {
        let options = arnOptionFormInputStore.options || []

        // Email empty
        if (!optionContent) return

        // Email existed
        if (checkOptionExisted(options, optionContent)) {
            Alert.alert('Error', 'Option existed!')
            return
        }

        options = [...options, { content: optionContent, selected: false }]
        arnOptionFormInputStore.setOptions(options)
        setOptionContent('')
    }

    return (
        <View style={styles.item}>
            <Image
                style={styles.radioUncheck}
                source={
                    isMultipleChoice ? image.unCheckBox : image.radioUncheck
                }
                resizeMode={'contain'}
            />
            <View style={styles.right}>
                <TextInput
                    placeholder="Other"
                    placeholderTextColor={hexToRGBA(
                        theme.colorScheme.labelPrimary as string,
                        0.3
                    )}
                    style={[
                        styles.textInput,
                        isInputPage && styles.separatorTextInput,
                    ]}
                    onEndEditing={onEndEditing}
                    onChangeText={onChangeText}
                    value={optionContent}
                ></TextInput>
            </View>
        </View>
    )
})

export default AddOptionFormInput

const createStyles = ({ colorScheme }: Theme) =>
    StyleSheet.create({
        radioUncheck: { height: 20, width: 20 },
        textInput: {
            fontSize: 15,
            flex: 1,
            marginRight: appConstants.PADDING_COMMON,
            height: '100%',
            color: colorScheme.labelPrimary,
        },
        right: {
            flex: 1,

            justifyContent: 'space-between',
            alignItems: 'center',
            height: '100%',
            flexDirection: 'row',
            paddingLeft: appConstants.PADDING_COMMON,
        },
        item: {
            height: 50,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
        },
        separatorTextInput: {
            borderBottomColor: colorScheme.separator,
            borderBottomWidth: 1,
        },
    })
