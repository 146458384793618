import {
    getItemsWithPrefix,
    removeItemsWithPrefix,
    storeData,
} from '../persistent-storage/AsyncStorage'

export const PREFIX_UPLOAD_INFO = 'PREFIX_UPLOAD_INFO'
export type UploadInfo = {
    targetColumnName: string
    assetUrl: string
}

export const saveFormUploadInfo = async (
    formOperationId: string,
    uploadFileOperationId: string,
    uploadInfo: UploadInfo
) => {
    return storeData(
        `${PREFIX_UPLOAD_INFO}${formOperationId}${uploadFileOperationId}`,
        uploadInfo
    )
}

export const allUploadsInfoByForm = async (
    formOperationId: string
): Promise<UploadInfo[]> => {
    return getItemsWithPrefix(`${PREFIX_UPLOAD_INFO}${formOperationId}`)
}

export const removeUploadsInfoByForm = async (
    formOperationId: string
): Promise<void> => {
    return removeItemsWithPrefix(`${PREFIX_UPLOAD_INFO}${formOperationId}`)
}
