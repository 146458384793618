/* eslint-disable no-inline-styles/no-inline-styles */
// Disable because can not use stylesheet for div tag style line: 44 and 46
// //
// //  DateTimeFormInput.tsx
// //
// //  Created by thaitd96 on 2022-06-27 10:48.
// //  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
// //

import React, {
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useRef,
} from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { Theme, useTheme } from '../../../../styles/theme'
import { DateTimeFormInputStore } from '@appformula/app-studio-core'
import { observer } from 'mobx-react-lite'
import { hexToRGBA } from '../../../../utils'
import appConstants from '../../../../constants/const'
import DatePicker, { CalendarContainer } from 'react-datepicker'
import { DateTimeFormInputType } from '@appformula/app-descriptor/src'
// import 'react-datepicker/dist/react-datepicker.css'
import dayjs from 'dayjs'
import { ARNFormPageStore } from '../../ARNFormPageStore'
import { ARNDateTimeFormStore } from './ARNDateTimeFormStore'
import { useBinding } from '../../../collection/components/hooks/useBinding'
import { useWidthHeightMainLayout } from '../../../collection/components/hooks/useSpecifyPlatformWindowDimensions'

export const DateTimeFormInput = observer(
    (props: {
        dateTimeFormInput: DateTimeFormInputStore
        arnDateTimeFormStore: ARNDateTimeFormStore
        arnFormPageStore: ARNFormPageStore
    }) => {
        const { dateTimeFormInput, arnDateTimeFormStore } = props
        const [zIndex, setZIndex] = React.useState<number>(
            appConstants.Z_INDEX_DATE_PICKER
        )
        const datePickerRef = useRef(null)
        const { width } = useWidthHeightMainLayout()

        const theme = useTheme()
        const styles = useMemo(
            () => createStyles(theme, zIndex),
            [theme, zIndex]
        )
        const isHidden = useBinding(dateTimeFormInput.isHidden)

        useEffect(() => {
            const subscriptions = arnDateTimeFormStore.startListeningEvents()

            return () => {
                subscriptions.forEach((s) => s.unsubscribe())
            }
        }, [arnDateTimeFormStore])

        const onChange = (date: Date) => {
            arnDateTimeFormStore.setValue(date)
        }

        const MyContainer = (props: {
            children: ReactNode[]
            className: string
        }) => {
            return (
                <div
                    style={{
                        width: width,
                        justifyContent: 'center',
                        display: 'flex',
                    }}
                >
                    <CalendarContainer className={props.className}>
                        <div style={{ position: 'relative' }}>
                            {props.children}
                        </div>
                    </CalendarContainer>
                </div>
            )
        }

        const getTimeContent = useCallback(() => {
            if (!arnDateTimeFormStore.value) return 'Select'
            if (dateTimeFormInput.dateInputType === 'dateTime') {
                return dayjs(arnDateTimeFormStore.value).format(
                    'MMMM D, YYYY h:mm A'
                )
            } else if (dateTimeFormInput.dateInputType === 'dateOnly') {
                return dayjs(arnDateTimeFormStore.value).format('MMMM D, YYYY')
            } else {
                // Time only
                return dayjs(arnDateTimeFormStore.value).format('h:mm A')
            }
        }, [arnDateTimeFormStore.value, dateTimeFormInput.dateInputType])

        const showTimeSelect = useCallback(
            (dateInputType: DateTimeFormInputType): boolean => {
                if (
                    dateInputType === 'dateTime' ||
                    dateInputType === 'timeOnly'
                ) {
                    return true
                }
                return false
            },
            []
        )

        const showTimeSelectOnly = useCallback(
            (dateInputType: DateTimeFormInputType): boolean => {
                if (dateInputType === 'timeOnly') {
                    return true
                }
                return false
            },
            []
        )

        const onCalendarOpen = () => {
            setZIndex(appConstants.Z_INDEX_OVER_DATE_PICKER)
        }

        const onCalendarClose = () => {
            setZIndex(appConstants.Z_INDEX_DATE_PICKER)
        }

        return isHidden ? null : (
            <View style={styles.wrapDateTimeTrigger}>
                <View style={styles.viewTitle}>
                    <Text style={styles.dateTimeTitle} numberOfLines={2}>
                        {dateTimeFormInput.titleName}
                    </Text>
                    {dateTimeFormInput.required && (
                        <View style={styles.requireDot}></View>
                    )}
                </View>
                <View style={styles.viewDateTime}>
                    <DatePicker
                        ref={datePickerRef}
                        selected={arnDateTimeFormStore.value}
                        onChange={onChange}
                        showTimeSelect={showTimeSelect(
                            dateTimeFormInput.dateInputType
                        )}
                        showTimeSelectOnly={showTimeSelectOnly(
                            dateTimeFormInput.dateInputType
                        )}
                        onCalendarOpen={onCalendarOpen}
                        onCalendarClose={onCalendarClose}
                        customInput={
                            <View style={styles.dateTimeTrigger}>
                                <Text
                                    style={styles.dateTimeContent}
                                    numberOfLines={1}
                                >
                                    {getTimeContent()}
                                </Text>
                            </View>
                        }
                        shouldCloseOnSelect
                        calendarContainer={MyContainer}
                    />
                </View>
            </View>
        )
    }
)

const createStyles = ({ colorScheme, roundness }: Theme, zIndex: number) =>
    StyleSheet.create({
        viewTitle: {
            flexDirection: 'row',
            flex: 1,
            alignItems: 'center',
            marginRight: 30,
            justifyContent: 'flex-start',
        },
        requireDot: {
            height: 6,
            width: 6,
            backgroundColor: 'red',
            borderRadius: 6,
            marginLeft: 6,
        },
        viewDateTime: { flex: 1.25 },
        wrapDateTimeTrigger: {
            zIndex: zIndex,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingVertical: 15,
            paddingHorizontal: appConstants.PADDING_COMMON,
            borderBottomColor: colorScheme.separator,
            borderBottomWidth: 1,
        },
        dateTimeTrigger: {
            borderRadius: roundness,
            backgroundColor: hexToRGBA(colorScheme.accentColor as string, 0.1),
            justifyContent: 'center',
            alignItems: 'center',
            padding: 10,
        },
        dateTimeContent: {
            color: colorScheme.accentColor,
            fontWeight: '500',
            fontSize: 15,
            lineHeight: 18,
        },
        dateTimeTitle: {
            fontWeight: '500',
            fontSize: 16,
            lineHeight: 19,
            color: colorScheme.labelPrimary,
        },
        dateTimeCustomContainer: { width: 330 },
        dateTimeChild: { position: 'relative' },
    })
