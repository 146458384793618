//
//  BooleanField.tsx
//
//  Created by thaitd96 on 2022-20-04 11:51.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { BooleanInputStore } from '@appformula/app-studio-core'
import image from '../../../assets/icons'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useMemo } from 'react'
import {
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
    Switch,
    Platform,
    Image,
    ColorValue,
} from 'react-native'
import { Theme, useTheme } from '../../../styles/theme'
import { hexToRGBA } from '../../../utils'
import { COLORS } from '../../../assets/colors'
import { useBindingCommon } from '../../collection/components/hooks/useBindingCommon'

export const BooleanField = observer(
    (props: {
        booleanInput: BooleanInputStore
        item: Record<string, unknown>
    }) => {
        const { booleanInput, item } = props
        const booleanInputData = booleanInput.object
        const typeBooleanInput = booleanInputData?.style || 'checkbox'
        const title = useBindingCommon(booleanInputData.title, item)
        const initialValue = useBindingCommon(
            booleanInputData.initialValue,
            item
        )
        const isHidden = useBindingCommon(booleanInputData.isHidden, props.item)
        const [checked, setChecked] = React.useState(initialValue)
        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])
        const isAndroid = Platform.OS === 'android'
        let thumbColor = COLORS.WHITE as ColorValue
        if (isAndroid) {
            thumbColor = checked ? theme.colorScheme.accentColor : COLORS.WHITE
        }

        const handleCheck = () => {
            setChecked(!checked)
        }

        useEffect(() => {
            setChecked(initialValue)
        }, [initialValue])

        if (isHidden) {
            return null
        }

        switch (typeBooleanInput) {
            case 'checkbox':
                return (
                    <TouchableOpacity onPress={handleCheck}>
                        <View style={styles.viewBooleanFieldCheckBox}>
                            <View style={styles.wrapImg}>
                                {checked ? (
                                    <View>
                                        <Image
                                            style={styles.imgCheckbox}
                                            source={image.checkBox}
                                            resizeMode={'stretch'}
                                        />
                                        <Image
                                            style={styles.imgCheck}
                                            source={image.checkV1}
                                            resizeMode={'stretch'}
                                        />
                                    </View>
                                ) : (
                                    <Image
                                        style={styles.imgUnCheckbox}
                                        source={image.unCheckBox}
                                        resizeMode={'stretch'}
                                    />
                                )}
                            </View>
                            <Text style={styles.title}>{title || ''}</Text>
                        </View>
                    </TouchableOpacity>
                )
            case 'switch':
                return (
                    <View style={styles.viewBooleanFieldSwitch}>
                        <Text style={styles.title} numberOfLines={1}>
                            {title}
                        </Text>
                        <Switch
                            trackColor={{
                                false: COLORS.BG_THUMB,
                                true: hexToRGBA(
                                    theme.colorScheme.accentColor as string,
                                    0.1
                                ),
                            }}
                            thumbColor={thumbColor as string}
                            ios_backgroundColor={
                                checked
                                    ? theme.colorScheme.accentColor
                                    : (COLORS.BG_THUMB as string)
                            }
                            onValueChange={handleCheck}
                            value={checked as boolean}
                        />
                    </View>
                )
            default:
                return null
        }
    }
)
const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        viewBooleanFieldCheckBox: {
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            marginVertical: 8,
        },

        viewBooleanFieldSwitch: {
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginVertical: 8,
        },

        title: {
            flex: 1,
            fontWeight: '500',
            fontSize: 16,
            lineHeight: 19,
            color: colorScheme.labelPrimary,
            alignSelf: 'center'
        },

        wrapImg: {
            marginRight: 12,
        },

        imgCheckbox: {
            height: 20,
            width: 20,
            tintColor: colorScheme.accentColor,
        },

        imgCheck: { height: 20, width: 20, position: 'absolute' },

        imgUnCheckbox: { height: 20, width: 20 },
    })
