import {
    View,
    Text,
    Pressable,
    Image as ImageRN,
    StyleSheet,
} from 'react-native'
import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react'
import { useNavigation } from '@react-navigation/native'
import image from '../../assets/icons'
import { Theme, useTheme } from '../../styles/theme'
import { COLORS } from '../../assets/colors'
import { AppAuthenticationContext } from '../../context/AppAuthenticationContext'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import AppTextInput from '../../components/AppTextInput'
import AvatarPicker from './AvatarPicker'
import { observer } from 'mobx-react-lite'
import { AppStore } from '../../app/AppStore'
import { Alert } from '../../components/alert'
import appConstants from '../../constants/const'

const EditProfile = () => {
    const appAuthenticationStore = useContext(AppAuthenticationContext)
    if (appAuthenticationStore) {
        return (
            <EditProfileScreen
                appAuthenticationStore={appAuthenticationStore}
            />
        )
    }
    return null
}

const EditProfileScreen = observer(
    ({ appAuthenticationStore }: { appAuthenticationStore: AppStore }) => {
        const navigation = useNavigation()
        const theme = useTheme()
        const styles = React.useMemo(() => createStyles(theme), [theme])
        const userInfo = appAuthenticationStore?.authenticatedUser || null
        const [firstName, setFirstName] = useState<string>('')
        const [lastName, setLastName] = useState<string>('')

        const ref = useRef({
            photoURL: userInfo?.photoURL || '',
        })

        const setAvatarUrl = useCallback((msg, data) => {
            ref.current.photoURL = data
        }, [])

        useEffect(() => {
            const sub = PubSub.subscribe(
                appConstants.RECEIVE_AVATAR_URL,
                setAvatarUrl
            )

            return function clean() {
                PubSub.unsubscribe(sub)
            }
        }, [setAvatarUrl])

        useEffect(() => {
            try {
                const currentDisplayName = JSON.parse(
                    userInfo?.displayName || ''
                )
                const first = currentDisplayName?.firstName || ''
                const last = currentDisplayName?.lastName || ''
                setFirstName(first)
                setLastName(last)
            } catch (error) {
                const currentDisplayName = userInfo?.displayName || ''
                const first = currentDisplayName.split(' ')?.[0] || ''
                const last = currentDisplayName.substring(first.length + 1)
                setFirstName(first)
                setLastName(last)
            }
        }, [userInfo?.displayName])

        const onClose = useCallback(() => {
            navigation.goBack()
        }, [navigation])

        const onSave = async () => {
            try {
                const oldDisplayName = userInfo?.displayName || ''
                const updatedDisplayName = JSON.stringify({
                    firstName,
                    lastName,
                })

                appAuthenticationStore?.update({
                    displayName: updatedDisplayName
                        ? updatedDisplayName
                        : oldDisplayName,
                    photoURL: ref.current.photoURL,
                })

                navigation.goBack()
            } catch (error) {
                Alert.alert('Error', 'Something went wrong!!!', [
                    {
                        text: 'OK',
                        onPress: () => {
                            //
                        },
                    },
                ])
            }
        }

        const onChangeFirstName = (text: string) => {
            setFirstName(text)
        }

        const onChangeLastName = (text: string) => {
            setLastName(text)
        }

        return (
            <View style={styles.container}>
                <View style={styles.header}>
                    <View style={styles.headerLeft}>
                        <Pressable onPress={onClose} hitSlop={10}>
                            <ImageRN
                                source={image.close}
                                style={styles.imgClose}
                            />
                        </Pressable>
                        <Text style={styles.txtTitle}>{'Your Profile'}</Text>
                    </View>
                    <View style={styles.headerRight}>
                        <Pressable onPress={onSave} style={styles.btnSave}>
                            <Text style={styles.txtSave}>{'Save'}</Text>
                        </Pressable>
                    </View>
                </View>
                <KeyboardAwareScrollView style={styles.containerBody}>
                    <View>
                        <AvatarPicker
                            appAuthenticationStore={appAuthenticationStore}
                        />
                        <View style={styles.wrapInfo}>
                            {!!userInfo?.displayName && (
                                <Text style={styles.txtName} numberOfLines={1}>
                                    {`${firstName} ${lastName}`}
                                </Text>
                            )}
                            {!!userInfo?.email && (
                                <Text style={styles.txtEmail}>
                                    {userInfo?.email || ''}
                                </Text>
                            )}
                        </View>
                    </View>
                    <View style={styles.wrapInput}>
                        <AppTextInput
                            value={firstName}
                            style={styles.txtInput}
                            placeholder={'First name'}
                            onChangeText={onChangeFirstName}
                        />
                        <View style={styles.lineSeparator} />
                        <AppTextInput
                            value={lastName}
                            style={styles.txtInput}
                            placeholder={'Last name'}
                            onChangeText={onChangeLastName}
                        />
                    </View>
                </KeyboardAwareScrollView>
            </View>
        )
    }
)

export default EditProfile

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        txtInput: {
            height: 56,
            paddingLeft: 16,
            color: colorScheme.labelPrimary,
        },
        lineSeparator: {
            width: '100%',
            height: 1,
            backgroundColor: colorScheme.separator,
        },
        wrapInput: {
            borderRadius: 5,
            borderWidth: 1,
            borderColor: colorScheme.separator,
            marginHorizontal: 16,
            marginTop: 24,
        },
        imgAva: {
            width: 53.93,
            height: 53.93,
            tintColor: colorScheme.separator,
        },
        container: { flex: 1, backgroundColor: colorScheme.backgroundL1 },
        containerBody: {
            flex: 1,
        },
        wrapAva: {
            height: 101,
            width: 101,
            borderRadius: 101 / 2,
            borderColor: colorScheme.separator,
            backgroundColor: colorScheme.backgroundL2,
            borderStyle: 'dashed',
            borderWidth: 3,
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf: 'center',
            marginTop: 24,
        },
        header: {
            padding: 10,
            flexDirection: 'row',
            justifyContent: 'space-between',
            backgroundColor: colorScheme.backgroundL1,
            alignItems: 'center',
            borderBottomColor: colorScheme.separator,
            borderBottomWidth: 1,
        },
        imgClose: {
            height: 28,
            width: 28,
        },
        headerLeft: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        headerRight: {},
        btnSave: {
            backgroundColor: colorScheme.accentColor,
            borderRadius: roundness,
        },
        txtSave: {
            color: COLORS.WHITE,
            paddingVertical: 7,
            paddingHorizontal: 16.5,
            fontSize: 14,
            lineHeight: 16.5,
            fontWeight: '500',
        },
        txtTitle: {
            fontWeight: '600',
            fontSize: 18,
            lineHeight: 29.7,
            color: colorScheme.labelPrimary,
        },
        txtEmail: {
            fontSize: 15,
            fontWeight: '400',
            lineHeight: 18.15,
            color: colorScheme.labelSecondary,
        },
        txtName: {
            fontSize: 17,
            fontWeight: '600',
            lineHeight: 20.57,
            color: colorScheme.labelPrimary,
            maxWidth: '90%',
            alignSelf: 'center',
            marginBottom: 8,
        },
        wrapInfo: { alignItems: 'center', marginTop: 16 },
    })
