export const COLORS = {
    ACCENT_PRIMARY: '#B3282D',
    SEPARATOR: '#EBEBEB',
    LABEL_SECONDARY: '#808080',
    LABEL_PRIMARY: '#0B0B0B',
    SIDEBAR_BG: '#F1F1F1',
    GROUPED_BG: '#F2F2F2',
    LEVEL2_BG: '#F6F6F6',
    ERROR: '#D92323',
    TEXT_AVATAR: '#FFFFFF',
    WHITE: '#FFFFFF',
    BG_THUMB: '#DEDEDE',
    BLACK: '#000000',
    GRAY: '#CCCCCC',
    BG_MENU: 'rgba(237, 237, 237, 0.9)',
    TRANSPARENT: 'transparent',
    HIGHLIGHT_COMPONENT: 'rgba(255,99,71,0.3)',
    BADGE: '#ff3a30',
    BG_MENU_FILTER: 'rgba(237, 237, 237, 0.8)',
    SEPARATOR_MENU_FILTER: 'rgba(60, 60, 67, 0.36)',
}
