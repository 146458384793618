//
//  TableRepository.ts
//
//  Created by thaitd96 on 2022-08-10 14:24
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { FieldTableType, Table } from '@appformula/app-descriptor'
import { TableRepository } from '@appformula/app-studio-core'
import { Observable } from 'rxjs'
import { FirestoreDocObjectRepository } from '../ObjectRepository'

export class LocalTableRepository
    extends FirestoreDocObjectRepository<Table>
    implements TableRepository
{
    get tableSnapshot(): Table {
        return this.objectSnapshot
    }
    get table(): Observable<Table> {
        return this.object
    }
    get tableId(): string {
        return this.objectId
    }
    set<K extends keyof Table>(key: K, value: Table[K]): Promise<void> {
        throw new Error('Method not implemented.')
    }
    setColumnType(columnName: string, newType: FieldTableType): Promise<void> {
        throw new Error('Method not implemented.')
    }
}
