//
//  FilterString.tsx
//
//  Created by thaitd96 on 2022-25-07 17:10.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React from 'react'
import { Image, StyleSheet, Text, TextInput, View } from 'react-native'
import appConstants from '../../../../../constants/const'
import { Theme, useTheme } from '../../../../../styles/theme'
import { TouchableOpacity } from '@gorhom/bottom-sheet'
import image from '../../../../../assets/icons'
import { observer } from 'mobx-react-lite'
import { FilterStringStore } from './FilterStringStore'
import { CollectionFilterStore } from '../CollectionFilterStore'
import ContextMenuCustom from '../../../../../components/context-menu-custom/ContextMenuCustom'

const EqualCompare = [
    { key: appConstants.COMPARE_TYPE.IS_CONTAIN, title: 'Contains' },
    { key: appConstants.COMPARE_TYPE.IS_NOT_CONTAIN, title: 'Not Contains' },
    { key: appConstants.COMPARE_TYPE.IS_EQUAL, title: 'Equals' },
    { key: appConstants.COMPARE_TYPE.IS_NOT_EQUAL, title: 'Not Equals' },
    { key: appConstants.COMPARE_TYPE.IS_START_WITH, title: 'Start Withs' },
    { key: appConstants.COMPARE_TYPE.IS_END_WITH, title: 'End Withs' },
    { key: appConstants.COMPARE_TYPE.IS_EMPTY, title: 'Is Empty' },
    { key: appConstants.COMPARE_TYPE.IS_NOT_EMPTY, title: 'Is Not Empty' },
]

export const FilterString = observer(
    (props: {
        filterStringStore: FilterStringStore
        collectionFilterStore: CollectionFilterStore
    }) => {
        const theme = useTheme()
        const styles = React.useMemo(() => createStyles(theme), [theme])

        const clear = React.useCallback(() => {
            props.collectionFilterStore.removeFilteredColumn(
                props.filterStringStore.columnFilter.key
            )
        }, [
            props.collectionFilterStore,
            props.filterStringStore.columnFilter.key,
        ])

        const ComponentHeader = React.useCallback(() => {
            return (
                <View style={styles.viewComponentHeader}>
                    <Text style={styles.textHeader}>
                        {props.filterStringStore.columnFilter.columnNameRaw}
                    </Text>
                    <TouchableOpacity onPress={clear}>
                        <Image source={image.close} style={styles.imageClose} />
                    </TouchableOpacity>
                </View>
            )
        }, [
            clear,
            props.filterStringStore.columnFilter.columnNameRaw,
            styles.imageClose,
            styles.textHeader,
            styles.viewComponentHeader,
        ])

        const onChangeText = React.useCallback(
            (text: string) => {
                props.filterStringStore.setValue(text)
            },
            [props.filterStringStore]
        )

        const onChangeSelection = React.useCallback(
            (option: number) => {
                props.filterStringStore.setType(option)
            },
            [props.filterStringStore]
        )

        return (
            <View style={styles.filterString}>
                <ComponentHeader />
                <ContextMenuCustom
                    dataActions={EqualCompare}
                    dropdownMenuMode={true}
                    onSelect={onChangeSelection}
                    initialValue={appConstants.COMPARE_TYPE.IS_CONTAIN}
                    currentValue={props.filterStringStore.type}
                />
                {props.filterStringStore.type !==
                    appConstants.COMPARE_TYPE.IS_EMPTY &&
                    props.filterStringStore.type !==
                        appConstants.COMPARE_TYPE.IS_NOT_EMPTY && (
                        <TextInput
                            style={styles.dataTextInput}
                            onChangeText={onChangeText}
                            value={props.filterStringStore.value}
                            placeholder={'Data'}
                            placeholderTextColor={
                                theme.colorScheme.labelSecondary
                            }
                        />
                    )}
            </View>
        )
    }
)

const createStyles = ({ colorScheme, roundness }: Theme) => {
    return StyleSheet.create({
        overlayStyle: { backgroundColor: 'rgba(0,0,0,0.3)' },
        cancelStyle: { backgroundColor: colorScheme.backgroundL1 },
        optionContainerStyle: { backgroundColor: colorScheme.backgroundL1 },
        iconDropDown: {
            height: 24,
            width: 24,
            resizeMode: 'contain',
            tintColor: colorScheme.accentColor,
        },
        dataTextInput: {
            height: 48,
            backgroundColor: colorScheme.backgroundL2,
            borderRadius: roundness,
            marginTop: 8,
            fontSize: 15,
            fontWeight: '500',
            paddingHorizontal: 12,
            width: '100%',
            color: colorScheme.labelPrimary,
        },
        textHeader: {
            fontSize: 17,
            fontWeight: '600',
            color: colorScheme.labelPrimary,
        },
        viewComponentHeader: {
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingTop: 12,
        },
        imageClose: { height: 30, width: 30, resizeMode: 'contain' },
        touchSelector: {
            alignSelf: 'baseline',
            flexDirection: 'row',
            paddingLeft: 12,
            paddingRight: 8,
            marginTop: 8,
            backgroundColor: colorScheme.backgroundL2,
            borderRadius: roundness,
            height: 40,
            alignItems: 'center',
        },
        filterString: {
            justifyContent: 'center',
            backgroundColor: colorScheme.backgroundL1,
            paddingHorizontal: appConstants.PADDING_COMMON,
            paddingBottom: 12,
            borderBottomWidth: 1,
            borderBottomColor: colorScheme.separator,
        },

        txtSelector: {
            color: colorScheme.accentColor,
            fontWeight: '500',
            fontSize: 15,
            lineHeight: 18.15,
        },
    })
}
