//
//  Markdown.tsx
//
//  Created by thaitd96 on 2022-20-04 11:41.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { MarkdownStore } from '@appformula/app-studio-core'
import { observer } from 'mobx-react-lite'
import React, { useMemo } from 'react'
import { StyleSheet, View } from 'react-native'
import { default as MarkdownRd } from 'react-native-markdown-display'
import { Theme, useTheme } from '../../../../styles/theme'
import { useBindingCommon } from '../../../collection/components/hooks/useBindingCommon'
import Rules from './Rules'

export const Markdown = observer(
    (props: { markdown: MarkdownStore; item: Record<string, unknown> }) => {
        const { markdown, item } = props
        const markdownData = markdown.object
        const text = useBindingCommon(markdownData?.text, item)
        const isHidden = useBindingCommon(markdownData.isHidden, item)

        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])
        return (
            !isHidden && (
                <View style={styles.viewMarkdown}>
                    <MarkdownRd style={styles} rules={Rules}>
                        {text || ''}
                    </MarkdownRd>
                </View>
            )
        )
    }
)

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        viewMarkdown: {},

        customizeBlockQ: {
            paddingHorizontal: 16,
            paddingVertical: 10,
            marginVertical: 8,
            backgroundColor: colorScheme.backgroundL1,
            borderLeftWidth: 5,
            borderLeftColor: colorScheme.accentColor,
        },

        txtBlockQ: {
            fontWeight: '400',
            fontSize: 15,
            color: colorScheme.labelPrimary,
        },

        codeBlock: {
            backgroundColor: colorScheme.backgroundL2,
            borderRadius: roundness,
        },

        txtCodeBlock: {
            padding: 16,
            fontWeight: '400',
            fontSize: 15,
            color: colorScheme.labelPrimary,
        },

        heading1: {
            fontWeight: '700',
            fontSize: 28,
            lineHeight: 33.89,
            color: colorScheme.labelPrimary,
        },
        heading2: {
            fontWeight: '700',
            fontSize: 26,
            lineHeight: 31.47,
            color: colorScheme.labelPrimary,
        },
        heading3: {
            fontWeight: '600',
            fontSize: 20,
            lineHeight: 24.2,
            color: colorScheme.labelPrimary,
        },
        heading4: {
            fontWeight: '600',
            fontSize: 18,
            lineHeight: 21.78,
            color: colorScheme.labelPrimary,
        },
        heading5: {
            fontWeight: '600',
            fontSize: 16,
            lineHeight: 21.6,
            color: colorScheme.labelPrimary,
        },
        heading6: {
            fontWeight: '500',
            fontSize: 14,
            lineHeight: 16.94,
            color: colorScheme.labelPrimary,
        },

        text: {
            color: colorScheme.labelPrimary,
        },

        strong: {
            fontWeight: 'bold',
            color: colorScheme.labelPrimary,
        },
        em: {
            fontStyle: 'italic',
            color: colorScheme.labelPrimary,
        },
        s: {
            textDecorationLine: 'line-through',
            color: colorScheme.labelPrimary,
        },
        ordered_list_icon: {
            marginLeft: 10,
            marginRight: 10,
            color: colorScheme.labelPrimary,
        },
    })
