//
//  Layout.tsx
//
//  Created by thaitd96 on 2022-19-04 11:38.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React from 'react'
import { CollectionPageStore } from '@appformula/app-studio-core'
import { observer } from 'mobx-react-lite'
import { CalendarLayout } from '../calendar-layout/CalendarLayout'
import { CardLayout } from '../card-layout/CardLayout'
import { ListLayout } from '../list-layout/ListLayout'
import { MapLayout } from '../map-layout/MapLayout'
import { ARNCollectionPageStore } from '../../ARNCollectionPageStore'

export const Layout = observer(
    (props: {
        pageStore: CollectionPageStore
        arnCollectionPageStore: ARNCollectionPageStore
    }) => {
        switch (props.pageStore.activeLayoutType) {
            case 'list':
                return (
                    <ListLayout
                        pageStore={props.pageStore}
                        arnCollectionPageStore={props.arnCollectionPageStore}
                    />
                )
            case 'card':
                return (
                    <CardLayout
                        pageStore={props.pageStore}
                        arnCollectionPageStore={props.arnCollectionPageStore}
                    />
                )
            case 'calendar':
                return (
                    <CalendarLayout
                        {...props}
                        calendarLayoutStore={props.arnCollectionPageStore.collectionCalendarStore()}
                        arnCollectionPageStore={props.arnCollectionPageStore}
                    />
                )
            case 'map':
                return (
                    <MapLayout
                        {...props}
                        mapLayoutStore={props.arnCollectionPageStore.collectionMapStore()}
                        arnCollectionPageStore={props.arnCollectionPageStore}
                    />
                )
            default:
                return null
        }
    }
)
