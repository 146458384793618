//
//  CardItemContent.tsx
//
//  Created by thaitd96 on 2022-27-04 15:44.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React, { useCallback, useMemo } from 'react'
import appConstants from '../../../../constants/const'
import { StyleSheet, View, Text } from 'react-native'
import FastImage from '../../../../components/fast-image/FastImage'
import { Theme, useTheme } from '../../../../styles/theme'
import { Accessory } from '../accessory/Accessory'
import { CollectionPageStore } from '@appformula/app-studio-core/src'
import { CardLayout } from '@appformula/app-descriptor/src'
import { COLORS } from '../../../../assets/colors'
import { observer } from 'mobx-react-lite'
import { useBindingHaveItemData } from '../hooks/useBindingHaveItemData'

export type CardItemContentProps = {
    item: Record<string, unknown>
    pageStore: CollectionPageStore
    imageSize?: string
}

export const CardItemContent = observer((props: CardItemContentProps) => {
    const theme = useTheme()
    const { item, imageSize } = props
    const {
        accessory,
        image,
        contentPosition,
        thumbnail,
        itemSubtitle,
        itemTitle,
    } = props.pageStore.activeLayout as CardLayout
    const isStyleOverlay: boolean = !!image && contentPosition === 'overlay'
    const styles = useMemo(
        () => createStyles(theme, isStyleOverlay),
        [theme, isStyleOverlay]
    )
    const subTitleNumberOfLines = imageSize === 'large' ? 2 : 4
    const thumbnailState = useBindingHaveItemData(thumbnail, item)
    const title = useBindingHaveItemData(itemTitle, item)
    const subTitle = useBindingHaveItemData(itemSubtitle, item)

    const Thubnail = useCallback(() => {
        return (
            <FastImage
                source={{
                    uri: (thumbnailState ?? '') as string,
                }}
                style={styles.thumbnail}
                resizeMode={'stretch'}
            ></FastImage>
        )
    }, [styles.thumbnail, thumbnailState])

    const Title = useCallback(() => {
        return (
            <Text numberOfLines={2} ellipsizeMode="tail" style={styles.title}>
                {title ?? ''}
            </Text>
        )
    }, [styles.title, title])

    const SubTitle = useCallback(() => {
        return (
            <Text
                numberOfLines={subTitleNumberOfLines}
                ellipsizeMode="tail"
                style={styles.subTitle}
            >
                {subTitle ?? ''}
            </Text>
        )
    }, [styles.subTitle, subTitle, subTitleNumberOfLines])

    return (
        <View style={styles.content}>
            {!!thumbnail && <Thubnail />}
            <View style={styles.right}>
                <Title />
                {!!itemSubtitle && <SubTitle />}
                {!!accessory && <Accessory accessory={accessory} item={item} />}
            </View>
        </View>
    )
})

const createStyles = ({ colorScheme }: Theme, isStyleOverlay: boolean) =>
    StyleSheet.create({
        thumbnail: { height: 40, width: 40, borderRadius: 20, marginRight: 12 },
        content: {
            flexDirection: 'row',
            padding: appConstants.PADDING_COMMON,
            alignItems: 'center',
            ...(isStyleOverlay && {
                position: 'absolute',
                bottom: 0,
            }),
        },
        subTitle: {
            marginTop: 4,
            fontWeight: '500',
            fontSize: 13,
            lineHeight: 18,
            ...(isStyleOverlay
                ? { color: COLORS.WHITE, opacity: 0.7 }
                : { color: colorScheme.labelSecondary }),
        },
        title: {
            fontWeight: '500',
            fontSize: 15,
            lineHeight: 18,
            color: isStyleOverlay ? COLORS.WHITE : colorScheme.labelPrimary,
        },
        right: {
            flex: 1,
        },
    })
