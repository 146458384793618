import { View, Text, StyleSheet, Pressable, Image } from 'react-native'
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { Theme, useTheme } from '../../../../../styles/theme'
import appConstants from '../../../../../constants/const'
import {
    convertResultSuccessToUrl,
    generateRandomId,
    hexToRGBA,
} from '../../../../../utils'
import image from '../../../../../assets/icons'
import { observer } from 'mobx-react-lite'
import { PhotosFormInputStore } from '@appformula/app-studio-core/src'
import ItemPhoto, { IPhotoWeb } from '../ItemPhoto/index.web'
import file from '../../../../../utils/file/index.web'
import { FORM_URL } from '../../../../../utils/network/apis/FormUrl'
import { useDropzone } from 'react-dropzone'
import { ARNPhotoFormStore } from '../ARNPhotoFormStore'
import allsettled, { PromiseResolution } from 'promise.allsettled'
import { useBinding } from '../../../../collection/components/hooks/useBinding'
import { AppMetadataContext } from '../../../../../context/AppMetadataContext'

const Photos = observer(
    (props: {
        photosFormInput: PhotosFormInputStore
        arnPhotoFormStore: ARNPhotoFormStore
    }) => {
        const { photosFormInput, arnPhotoFormStore } = props
        const photosFormInputData = photosFormInput.object
        const isHidden = useBinding(photosFormInput?.isHidden)

        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])
        const [listPhotoSelected, setListPhotoSelected] = useState([])
        const { teamId } = useContext(AppMetadataContext)

        useEffect(() => {
            const subscriptions = arnPhotoFormStore.startListeningEvents()

            return () => {
                subscriptions.forEach((s) => s.unsubscribe())
            }
        }, [arnPhotoFormStore])

        const onUploadFileSuccess = useCallback(
            (url: string) => {
                arnPhotoFormStore.addPhotoUploaded({
                    assetUrl: new URL(url),
                })
            },
            [arnPhotoFormStore]
        )

        const { open, acceptedFiles } = useDropzone({
            accept: {
                'image/*': [],
            },
        })

        useEffect(() => {
            if (acceptedFiles && acceptedFiles.length > 0) {
                const newAcceptedFiles = acceptedFiles.map((item) => ({
                    id: generateRandomId(),
                    name: item.name,
                    type: item.type,
                    size: item.size,
                    preview: URL.createObjectURL(item),
                }))

                setListPhotoSelected((prevList) => [
                    ...prevList,
                    ...newAcceptedFiles,
                ])
                const filesUpload = newAcceptedFiles.map(async (item) => {
                    return file.uploadFile({
                        data: item,
                        url: `${FORM_URL.uploadFile}`,
                        options: { isHandleError: false },
                    })
                })

                allsettled(filesUpload).then((results) => {
                    results
                        .filter((result) => result.status === 'fulfilled')
                        .forEach((resultSuccess) => {
                            const url = convertResultSuccessToUrl(
                                resultSuccess as PromiseResolution<
                                    Record<string, unknown>
                                >
                            )

                            onUploadFileSuccess(url)
                        })
                })
            }
        }, [acceptedFiles, onUploadFileSuccess, teamId])

        const _removeFile = (file: IPhotoWeb) => {
            //
        }

        const _renderItem = (item: IPhotoWeb, index: number) => {
            return (
                <ItemPhoto
                    key={`${item.id}`}
                    item={item}
                    onRemove={_removeFile}
                    onUploadFileSuccess={onUploadFileSuccess}
                />
            )
        }

        const handleOptionUpload = () => {
            open()
        }

        return isHidden ? null : (
            <View style={styles.container}>
                <View style={styles.wrapperHeader}>
                    <View style={styles.wrapperTitle}>
                        <Text style={styles.txtTitle}>
                            {photosFormInputData.titleName}
                        </Text>
                        {photosFormInputData.required && (
                            <View style={styles.requireDot} />
                        )}
                    </View>
                    <Pressable
                        style={styles.btnUpload}
                        onPress={handleOptionUpload}
                    >
                        <Image source={image.upload} style={styles.imgUpload} />
                    </Pressable>
                </View>
                {listPhotoSelected && listPhotoSelected.length > 0 ? (
                    <View style={styles.wrapperPhotos}>
                        {listPhotoSelected.map((item, index) =>
                            _renderItem(item, index)
                        )}
                    </View>
                ) : null}
            </View>
        )
    }
)

export default Photos

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        wrapperPhotos: {
            flexWrap: 'wrap',
            flexDirection: 'row',
            marginHorizontal: 12,
            marginTop: 10,
        },
        txtTitle: {
            fontWeight: '500',
            fontSize: 16,
            lineHeight: 19.36,
            color: colorScheme.labelPrimary,
        },
        imgClose: { width: 24, height: 24 },
        imgUpload: {
            width: 28,
            height: 28,
            margin: 5,
            tintColor: colorScheme.accentColor,
        },
        wrapperHeader: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingHorizontal: appConstants.PADDING_COMMON,
        },
        btnUpload: {
            backgroundColor: hexToRGBA(colorScheme.accentColor as string, 0.1),
            borderRadius: roundness,
        },
        containerItemTitle: { flexDirection: 'row' },
        wrapperItem: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingHorizontal: appConstants.PADDING_COMMON,
            height: 54,
        },
        container: {
            paddingBottom: 11,
            borderBottomColor: colorScheme.separator,
            borderBottomWidth: 1,
            marginTop: 15,
        },
        requireDot: {
            height: 6,
            width: 6,
            borderRadius: 3,
            backgroundColor: 'red',
            marginLeft: 8,
        },
        wrapperTitle: { flexDirection: 'row', alignItems: 'center' },
        contentContainer: {
            flex: 1,
            alignItems: 'center',
        },
    })
