//
//  ARNOptionFormInputStore.ts
//
//  Created by thaitd on 2022-08-12 14:08.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { OptionsFormInputStore } from '@appformula/app-studio-core/src'
import { action, makeObservable, observable } from 'mobx'
import { Subject, Subscription } from 'rxjs'
import appConstants from '../../../../constants/const'
import { FormulaManager } from '../../../../utils/integrate-formula/FormulaManager'
import { TransformBinding } from '../../../../utils/transform-binding/TransformBinding'
import { ARNFormPageStore, OptionFormInputData } from '../../ARNFormPageStore'
import { BaseARNFormStore } from '../BaseARNFormStore'
import { availableOptionsMocked, Option } from './OptionFormInputInline'

export class ARNOptionFormInputStore implements BaseARNFormStore {
    readonly formPageId: string
    readonly componentId: string
    options: Option[]
    arnFormPageStore: ARNFormPageStore
    selectedOptionChange: Subject<void>
    formulaManager?: FormulaManager

    get selectedOptions(): Option[] {
        return this.options.filter((item) => item.selected) || []
    }

    get selectedOptionsString(): string {
        return this.selectedOptions.map((item) => item.content).join(', ')
    }

    constructor(
        arnFormPageStore: ARNFormPageStore,
        formPageId: string,
        componentId: string,
        initData?: OptionFormInputData,
        formulaManager?: FormulaManager
    ) {
        this.arnFormPageStore = arnFormPageStore
        this.formPageId = formPageId
        this.componentId = componentId
        this.options = initData?.options ?? []
        this.selectedOptionChange = new Subject()
        this.formulaManager = formulaManager

        makeObservable(this, {
            options: observable,
            setOptions: action,
            setChangeSingleSelectedOptionInput: action,
            setChangeMultipleSelectedOptionInput: action,
            clearSelectedOptionInput: action,
        })
    }

    startListeningEvents(): Subscription[] {
        const selectedOptionChangeSubscription =
            this.selectedOptionChange.subscribe({
                next: () => {
                    this.arnFormPageStore.clearFormScreenError(this.componentId)
                },
            })

        return [selectedOptionChangeSubscription]
    }

    setOptions(options: Option[]) {
        this.options = options
    }

    computeOptions(
        allowsMultipleSelection: boolean,
        defaultValue: string[]
    ): Option[] {
        const options: Option[] = []
        try {
            if (allowsMultipleSelection) {
                availableOptionsMocked.forEach((stringOption: string) => {
                    if (
                        (defaultValue as unknown as string[])?.includes(
                            stringOption
                        )
                    ) {
                        options.push({
                            content: stringOption,
                            selected: true,
                        })
                    } else {
                        options.push({
                            content: stringOption,
                            selected: false,
                        })
                    }
                })
            } else {
                availableOptionsMocked.forEach((stringOption: string) => {
                    options.push({ content: stringOption, selected: false })
                })
            }
            return options
        } catch (error) {
            console.info('error', error)
            return options
        }
    }

    setChangeSingleSelectedOptionInput = (index: number) => {
        const optionsTemp: Option[] = []
        this.options.forEach((item, i) => {
            if (i === index) {
                optionsTemp.push({ ...item, selected: true })
            } else {
                optionsTemp.push({ ...item, selected: false })
            }
        })
        this.options = optionsTemp
        this.selectedOptionChange.next()
    }

    setChangeMultipleSelectedOptionInput = (index: number) => {
        this.options[index].selected = !this.options[index].selected
        this.selectedOptionChange.next()
    }

    clearSelectedOptionInput = () => {
        const optionsTemp: Option[] = []
        this.options.forEach((item, i) => {
            optionsTemp.push({ ...item, selected: false })
        })

        this.options = optionsTemp
    }

    async validate(): Promise<void> {
        try {
            const componentStore =
                this.arnFormPageStore.pageStore.componentStore(
                    this.componentId
                ) as OptionsFormInputStore

            if (componentStore.required && !this.selectedOptions?.length) {
                return Promise.reject(
                    `${componentStore.titleName} is required!`
                )
            }

            if (
                this.formulaManager &&
                componentStore?.formulaValidator?.formula
            ) {
                await this.formulaManager.registContextVariable(
                    appConstants.CURRENT_VALUE,
                    this.selectedOptions
                )

                const isValid = await this.formulaManager.execute(
                    componentStore.formulaValidator.formula,
                    {
                        value: this.selectedOptions,
                    }
                )

                if (!isValid) {
                    const errorMessage = await TransformBinding(
                        componentStore.formulaValidator.errorMessage
                    )
                    return Promise.reject(errorMessage)
                }
            }
        } catch (error) {
            return Promise.reject('Error Validate')
        }
    }
}
