import { View, ViewStyle, StyleSheet } from 'react-native'
import React, { ReactNode, useEffect } from 'react'
import { DesignPageStore } from '@appformula/app-studio-core/src'
import { observer } from 'mobx-react-lite'
import { WrappingComponentHighlightStore } from './WrappingComponentHighlight+Store'
import { COLORS } from '../../assets/colors'
import Animated, {
    FadeIn,
    FadeOut,
    useAnimatedStyle,
    withTiming,
} from 'react-native-reanimated'
import { ScrollViewHighLightStore } from '../ScrollViewHighLight/ScrollViewHighLightStore'
import cache from '../../utils/cache'

interface Props {
    children: ReactNode
    wrapChildrenComp?: ViewStyle
    componentInfoPath?: IComponentInfoPath
    designPageStore: DesignPageStore
    scrollViewHighLightStore: ScrollViewHighLightStore
}

export interface IComponentInfoPath {
    pageId: string
    componentId: string
}

export interface IPageValuePath {
    componentId: string | number
}

const WrappingComponentHighlightContent = observer(
    (props: {
        children: ReactNode
        wrapChildrenComp?: ViewStyle
        wrappingComponentHighlightStore: WrappingComponentHighlightStore
        designPageStore: DesignPageStore
        componentInfoPath?: IComponentInfoPath
        scrollViewHighLightStore: ScrollViewHighLightStore
    }) => {
        const {
            wrapChildrenComp,
            wrappingComponentHighlightStore,
            children,
            scrollViewHighLightStore,
            componentInfoPath,
        } = props

        const isAddNewCompAbove =
            wrappingComponentHighlightStore.isClosed &&
            wrappingComponentHighlightStore.lexoRank === 'above'

        const isAddNewCompBelow =
            wrappingComponentHighlightStore.isClosed &&
            wrappingComponentHighlightStore.lexoRank === 'below'

        useEffect(() => {
            const subscriptions =
                props.wrappingComponentHighlightStore.startListeningDataChange()

            return function clean() {
                subscriptions.forEach((sub) => sub.unsubscribe())
            }
        }, [props?.wrappingComponentHighlightStore])

        useEffect(() => {
            props.wrappingComponentHighlightStore.setInspectorItemSelection(
                props.designPageStore?.inspectorItemSelection
            )
        }, [
            props.designPageStore?.inspectorItemSelection,
            props?.wrappingComponentHighlightStore,
        ])

        useEffect(() => {
            props.wrappingComponentHighlightStore.setYCoordinateRN(
                props.designPageStore?.yCoordinateRN
            )
        }, [
            props.designPageStore?.yCoordinateRN,
            props?.wrappingComponentHighlightStore,
        ])

        const styleAbove = useAnimatedStyle(() => {
            return {
                backgroundColor: withTiming(
                    isAddNewCompAbove ? 'red' : 'transparent',
                    { duration: 200 }
                ),
            }
        })

        const styleBelow = useAnimatedStyle(() => {
            return {
                backgroundColor: withTiming(
                    isAddNewCompBelow ? 'red' : 'transparent',
                    { duration: 200 }
                ),
            }
        })

        return (
            <View style={[styles.container]}>
                {Object.keys(scrollViewHighLightStore.dataSourceCords)[0] ===
                    componentInfoPath.componentId && (
                    <Animated.View
                        style={[
                            {
                                height: 4,
                                width: '100%',
                                borderRadius: 50,
                            },
                            styleAbove,
                        ]}
                    />
                )}
                <View style={wrapChildrenComp}>{children}</View>
                <Animated.View
                    style={[
                        {
                            height: 4,
                            width: '100%',
                            borderRadius: 50,
                        },
                        styleBelow,
                    ]}
                />

                {wrappingComponentHighlightStore.isHighLight && (
                    <Animated.View
                        entering={FadeIn.delay(1000)}
                        exiting={FadeOut}
                        style={styles.hightlightLayout}
                    />
                )}
            </View>
        )
    }
)
const styles = StyleSheet.create({
    container: {},
    hightlightLayout: {
        width: '100%',
        height: '100%',
        backgroundColor: COLORS.HIGHLIGHT_COMPONENT,
        position: 'absolute',
    },
})

const WrappingComponentHighlight = observer((props: Props) => {
    if (!props.designPageStore && cache.sourceStartUp === 'APP_EDITOR') {
        return null
    }

    const wrappingComponentHighlightStore = new WrappingComponentHighlightStore(
        props.scrollViewHighLightStore,
        props.componentInfoPath,
        props.designPageStore
    )
    return (
        <WrappingComponentHighlightContent
            wrappingComponentHighlightStore={wrappingComponentHighlightStore}
            {...props}
        />
    )
})

export default WrappingComponentHighlight
