//
//  CalendarHeader.tsx
//
//  Created by thaitd96 on 2022-06-07 14:11.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React, { useCallback, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import {
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
} from 'react-native'
import { Theme, useTheme } from '../../../../../styles/theme'
import appConstants from '../../../../../constants/const'
import { hexToRGBA } from '../../../../../utils'
import { CalendarLayoutStore, CalendarMode } from '../CalendarLayoutStore'
import { COLORS } from '../../../../../assets/colors'
import WeekTrigger from './WeekTrigger'
import MonthTrigger from './MonthTrigger'

export const CalendarHeader = observer(
    (props: { calendarLayoutStore: CalendarLayoutStore }) => {
        const { calendarLayoutStore } = props
        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])

        const changeMode = (mode: CalendarMode) => () => {
            calendarLayoutStore.setCalendarMode(mode)
        }

        const onValueChange = useCallback(
            (newDate: Date) => {
                calendarLayoutStore.closeTimePicker()
                if (newDate) {
                    calendarLayoutStore.setMonthYear(newDate)
                }
            },
            [calendarLayoutStore]
        )

        const CustomInput = useCallback(() => {
            return calendarLayoutStore.calendarMode === 'W' ? (
                <WeekTrigger calendarLayoutStore={calendarLayoutStore} />
            ) : (
                <MonthTrigger calendarLayoutStore={calendarLayoutStore} />
            )
        }, [])

        return (
            <View style={styles.calendarHeader}>
                <CustomInput />
                <View style={styles.mode}>
                    <TouchableOpacity
                        style={[
                            styles.buttonMode,
                            {
                                backgroundColor:
                                    calendarLayoutStore.calendarMode === 'D'
                                        ? hexToRGBA(
                                              theme.colorScheme
                                                  .accentColor as string,
                                              0.1
                                          )
                                        : theme.colorScheme.backgroundL1,
                            },
                        ]}
                        onPress={changeMode('D')}
                    >
                        <Text
                            style={[
                                styles.textMode,
                                calendarLayoutStore.calendarMode === 'D'
                                    ? styles.textActiveMode
                                    : styles.textInactiveMode,
                            ]}
                        >
                            {'D'}
                        </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                        style={[
                            styles.buttonMode,
                            {
                                backgroundColor:
                                    calendarLayoutStore.calendarMode === 'W'
                                        ? hexToRGBA(
                                              theme.colorScheme
                                                  .accentColor as string,
                                              0.1
                                          )
                                        : theme.colorScheme.backgroundL1,
                            },
                        ]}
                        onPress={changeMode('W')}
                    >
                        <Text
                            style={[
                                styles.textMode,
                                calendarLayoutStore.calendarMode === 'W'
                                    ? styles.textActiveMode
                                    : styles.textInactiveMode,
                            ]}
                        >
                            {'W'}
                        </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                        style={[
                            styles.buttonMode,
                            {
                                backgroundColor:
                                    calendarLayoutStore.calendarMode === 'M'
                                        ? hexToRGBA(
                                              theme.colorScheme
                                                  .accentColor as string,
                                              0.1
                                          )
                                        : theme.colorScheme.backgroundL1,
                            },
                        ]}
                        onPress={changeMode('M')}
                    >
                        <Text
                            style={[
                                styles.textMode,
                                calendarLayoutStore.calendarMode === 'M'
                                    ? styles.textActiveMode
                                    : styles.textInactiveMode,
                            ]}
                        >
                            {'M'}
                        </Text>
                    </TouchableOpacity>
                </View>
            </View>
        )
    }
)

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        textActiveMode: {
            fontWeight: '600',
            color: colorScheme.accentColor,
        },
        textInactiveMode: {
            fontWeight: '500',
            color: colorScheme.labelSecondary,
        },
        imgCalendarPick: { marginRight: 14.36, width: 16.54, height: 15.28 },
        wrapOptionOther: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        itemMenu: {
            borderBottomWidth: 1,
            borderBottomColor: 'rgba(60, 60, 67, 0.36)',
            backgroundColor: COLORS.BG_MENU,
        },
        itemMenuOther: {
            backgroundColor: COLORS.BG_MENU,
        },
        itemTextMenu: {
            paddingLeft: 16,
            paddingVertical: 11,
            fontSize: 17,
            fontWeight: '400',
            lineHeight: 22,
        },
        mode: { flexDirection: 'row' },
        textMode: { fontSize: 15 },
        buttonMode: {
            width: 46,
            height: 34,
            borderRadius: 5,
            justifyContent: 'center',
            alignItems: 'center',
        },
        icon: {
            height: 24,
            width: 24,
            resizeMode: 'contain',
            marginLeft: 4,
            tintColor: colorScheme.accentColor,
        },
        weekMonthPicker: {
            flexDirection: 'row',
            paddingRight: 30,
            paddingVertical: 10,
        },
        monthWeek: {
            fontSize: 19,
            fontWeight: '600',
            color: colorScheme.labelPrimary,
        },
        year: {
            fontSize: 19,
            fontWeight: '500',
            color: colorScheme.labelSecondary,
        },
        calendarHeader: {
            width: '100%',
            height: 55,
            // position: 'absolute',
            borderTopWidth: 1,
            borderTopColor: colorScheme.separator,
            backgroundColor: colorScheme.backgroundL1,
            flexDirection: 'row',
            alignItems: 'center',
            paddingLeft: appConstants.PADDING_COMMON,
            paddingRight: 6,
            justifyContent: 'space-between',
            overflow: 'visible',
            zIndex: 1,
        },
    })
