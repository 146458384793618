//
//  GetSqlData.tsx
//
//  Created by thaitd96 on 2022-07-21 10:40.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.

import { SQLTransaction } from 'expo-sqlite'
import { Platform } from 'react-native'
import { GetDatabase } from '.'

export function GetAllSqlDataByTableName(
    tableName: string
): Promise<Record<string, unknown>[]> {
    return new Promise((resolve, reject) => {
        const sqlQuery = `SELECT * FROM \`${tableName}\``
        GetDatabase().transaction((tx: SQLTransaction) => {
            tx.executeSql(
                sqlQuery,
                [],
                (_, resultSet) => {
                    if (Platform.OS === 'web') {
                        resolve([
                            ...(resultSet.rows as unknown as Set<
                                Record<string, unknown>
                            >),
                        ])
                    } else {
                        resolve(resultSet.rows._array)
                    }
                },
                (err) => {
                    console.info('err----', err)

                    reject(false)
                    // SQLStatementErrorCallback require return boolean value
                    return false
                }
            )
        })
    })
}

export function GetItemByIndex(
    tableName: string,
    index: number,
    queryString?: string
): Promise<Record<string, unknown>> {
    return new Promise((resolve, reject) => {
        const sqlQuery = `SELECT * FROM \`${tableName}\` ${
            queryString ?? ''
        } limit 1 offset ${index}`
        // console.info('sqlQuery', sqlQuery)
        GetDatabase().transaction((tx: SQLTransaction) => {
            tx.executeSql(
                sqlQuery,
                [],
                (_, resultSet) => {
                    if (Platform.OS === 'web') {
                        resolve(
                            [
                                ...(resultSet.rows as unknown as Set<
                                    Record<string, unknown>
                                >),
                            ]?.[0] ?? {}
                        )
                    } else {
                        resolve(resultSet.rows._array[0])
                    }
                },
                (err) => {
                    reject(false)
                    // SQLStatementErrorCallback require return boolean value
                    return false
                }
            )
        })
    })
}

export function GetItemByKey(
    tableName: string,
    key: string
): Promise<Record<string, unknown>> {
    return new Promise((resolve, reject) => {
        const sqlQuery = `SELECT * FROM \`${tableName}\` WHERE key == '${key}'`
        // console.info('sqlQuery', sqlQuery)
        GetDatabase().transaction((tx: SQLTransaction) => {
            tx.executeSql(
                sqlQuery,
                [],
                (_, resultSet) => {
                    if (Platform.OS === 'web') {
                        resolve(
                            [
                                ...(resultSet.rows as unknown as Set<
                                    Record<string, unknown>
                                >),
                            ]?.[0] ?? {}
                        )
                    } else {
                        resolve(resultSet.rows._array[0])
                    }
                },
                (err) => {
                    reject(false)
                    // SQLStatementErrorCallback require return boolean value
                    return false
                }
            )
        })
    })
}

export function GetItemByIndexWithoutQueryString(
    tableName: string,
    index: number
): Promise<Record<string, unknown>> {
    return new Promise((resolve, reject) => {
        const sqlQuery = `SELECT * FROM \`${tableName}\` limit 1 offset ${index}`
        GetDatabase().transaction((tx: SQLTransaction) => {
            tx.executeSql(
                sqlQuery,
                [],
                (_, resultSet) => {
                    if (Platform.OS === 'web') {
                        resolve(
                            [
                                ...(resultSet.rows as unknown as Set<
                                    Record<string, unknown>
                                >),
                            ]?.[0] ?? {}
                        )
                    } else {
                        resolve(resultSet.rows._array[0])
                    }
                },
                (err) => {
                    reject(false)
                    // SQLStatementErrorCallback require return boolean value
                    return false
                }
            )
        })
    })
}

export function GetItemByIndexOfSection(
    tableName: string,
    groupingColumn: string,
    groupingColumnValue: unknown,
    index: number,
    queryString: string
): Promise<Record<string, unknown>> {
    return new Promise((resolve, reject) => {
        const { queryFilter, querySort } = getAddedQuery(queryString)
        const queryByGrouping =
            typeof groupingColumnValue === 'number'
                ? `\`${groupingColumn}\` == ${groupingColumnValue}`
                : `\`${groupingColumn}\` == '${groupingColumnValue}'`
        const sqlQuery = `SELECT * FROM \`${tableName}\` where ${queryFilter} ${queryByGrouping} ${querySort} limit ${1} offset ${index}`
        GetDatabase().transaction((tx: SQLTransaction) => {
            tx.executeSql(
                sqlQuery,
                [],
                (_, resultSet) => {
                    if (Platform.OS === 'web') {
                        resolve(
                            [
                                ...(resultSet.rows as unknown as Set<
                                    Record<string, unknown>
                                >),
                            ]?.[0] ?? {}
                        )
                    } else {
                        resolve(resultSet.rows._array[0])
                    }
                },
                (err) => {
                    console.info('err', err, sqlQuery)
                    reject(false)
                    // SQLStatementErrorCallback require return boolean value
                    return false
                }
            )
        })
    })
}

export function GetAllVirtualSqlDataWithQueryString(
    tableName: string,
    queryString: string
): Promise<Array<number>> {
    return new Promise((resolve, reject) => {
        const sqlQuery = `SELECT count() FROM \`${tableName}\` ${queryString}`

        GetDatabase().transaction((tx: SQLTransaction) => {
            tx.executeSql(
                sqlQuery,
                [],
                (_, resultSet) => {
                    if (Platform.OS === 'web') {
                        resolve(
                            Array.from(
                                Array(
                                    [
                                        ...(resultSet.rows as unknown as Set<
                                            Record<string, unknown>
                                        >),
                                    ]?.[0]?.['count()']
                                ).keys()
                            )
                        )
                    } else {
                        resolve(
                            Array.from(
                                Array(
                                    resultSet.rows._array[0]['count()']
                                ).keys()
                            )
                        )
                    }
                },
                (err) => {
                    reject(err)
                    // SQLStatementErrorCallback require return boolean value
                    return false
                }
            )
        })
    })
}

export function GetAllSqlDataWithQueryString(
    tableName: string,
    queryString: string
): Promise<Record<string, unknown>[]> {
    return new Promise((resolve, reject) => {
        const sqlQuery = `SELECT * FROM \`${tableName}\` ${queryString}`

        GetDatabase().transaction((tx: SQLTransaction) => {
            tx.executeSql(
                sqlQuery,
                [],
                (_, resultSet) => {
                    if (Platform.OS === 'web') {
                        resolve([
                            ...(resultSet.rows as unknown as Set<
                                Record<string, unknown>
                            >),
                        ])
                    } else {
                        resolve(resultSet.rows._array)
                    }
                },
                (err) => {
                    reject(err)
                    // SQLStatementErrorCallback require return boolean value
                    return false
                }
            )
        })
    })
}

export const getAddedQuery = (queryStringSortFilter: string) => {
    if (!queryStringSortFilter) {
        return { queryFilter: '', querySort: '' }
    } else {
        const [queryFilter, querySort] = queryStringSortFilter
            .toLowerCase()
            .replace('where', '')
            .split('order by')
        return {
            queryFilter: queryFilter ? `${queryFilter} and` : '',
            querySort: querySort ? `order by ${querySort}` : '',
        }
    }
}

export function GetAllVirtualListSectionDataWithQueryString(
    tableName: string,
    groupingColumn: string,
    queryStringSortFilter: string
): Promise<{ title: string; data: number[] }[]> {
    // console.info('run GetAllVirtualListSectionDataWithQueryString')
    const { queryFilter, querySort } = getAddedQuery(queryStringSortFilter)
    return new Promise((resolve, reject) => {
        const sqlQuery = `select \`${groupingColumn}\`, count()
            from \`${tableName}\` row1
            where ${queryFilter} key in
                (
                    select  key
                    from    \`${tableName}\` row2
                    where   \`${groupingColumn}\` = row2.\`${groupingColumn}\`
                )
            group by \`${groupingColumn}\`
            ${querySort}`
        GetDatabase().transaction((tx: SQLTransaction) => {
            tx.executeSql(
                sqlQuery,
                [],
                (_, resultSet) => {
                    if (Platform.OS === 'web') {
                        resolve(
                            [
                                ...(resultSet.rows as unknown as Set<
                                    Record<string, unknown>
                                >),
                            ].map((item) => ({
                                title: item[groupingColumn] as string,
                                data: Array.from(Array(item['count()']).keys()),
                            }))
                        )
                    } else {
                        resolve(
                            resultSet.rows._array.map((item) => ({
                                title: item[groupingColumn],
                                data: Array.from(Array(item['count()']).keys()),
                            }))
                        )
                    }
                },
                (err) => {
                    console.warn('resultSet', sqlQuery)
                    reject(false)
                    // SQLStatementErrorCallback require return boolean value
                    return false
                }
            )
        })
    })
}

export function GetMaxMinValueOfTable(
    tableName: string,
    columnName: string
): Promise<Record<string, number>> {
    return new Promise((resolve, reject) => {
        const sqlQuery = `select MIN(\`${columnName}\`) as Min, MAX(\`${columnName}\`) as Max from \`${tableName}\``

        GetDatabase().transaction((tx: SQLTransaction) => {
            tx.executeSql(
                sqlQuery,
                [],
                (_, resultSet) => {
                    if (Platform.OS === 'web') {
                        const rows = resultSet.rows as unknown as Record<
                            string,
                            number
                            >[]
                        if (rows[0]) {
                            resolve(rows[0])
                        }
                    } else {
                        resolve(resultSet.rows._array[0])
                    }
                },
                (err) => {
                    reject(false)
                    // SQLStatementErrorCallback require return boolean value
                    return false
                }
            )
        })
    })
}

export function GetItemsByQueryString(
    tableName: string,
    queryString: string
): Promise<Record<string, unknown>[]> {
    return new Promise((resolve, reject) => {
        const sqlQuery = `SELECT * FROM \`${tableName}\` WHERE ${queryString}`
        // console.info('sqlQuery', sqlQuery)
        GetDatabase().transaction((tx: SQLTransaction) => {
            tx.executeSql(
                sqlQuery,
                [],
                (_, resultSet) => {
                    if (Platform.OS === 'web') {
                        resolve([
                            ...(resultSet.rows as unknown as Set<
                                Record<string, unknown>
                            >),
                        ])
                    } else {
                        resolve(resultSet.rows._array)
                    }
                },
                (err) => {
                    reject(false)
                    // SQLStatementErrorCallback require return boolean value
                    return false
                }
            )
        })
    })
}

export function GetAllVirtualListMapWithQueryString(
    tableName: string,
    groupingColumn: string,
    queryStringSortFilter: string
): Promise<{ index: number; type: string }[]> {
    // console.info('run GetAllVirtualListSectionDataWithQueryString')
    const { queryFilter, querySort } = getAddedQuery(queryStringSortFilter)
    return new Promise((resolve, reject) => {
        const sqlQuery = `select \`${groupingColumn}\`
            from \`${tableName}\` row1
            where ${queryFilter} key in
                (
                    select  key
                    from    \`${tableName}\` row2
                    where   \`${groupingColumn}\` = row2.\`${groupingColumn}\`
                )
            ${querySort}`
        GetDatabase().transaction((tx: SQLTransaction) => {
            tx.executeSql(
                sqlQuery,
                [],
                (_, resultSet) => {
                    if (Platform.OS === 'web') {
                        resolve(
                            [
                                ...(resultSet.rows as unknown as Set<
                                    Record<string, unknown>
                                >),
                            ].map((item, index) => ({
                                type: item[
                                    groupingColumn
                                ]?.toString() as string,
                                index,
                            }))
                        )
                    } else {
                        resolve(
                            resultSet.rows._array.map((item, index) => ({
                                type: item[
                                    groupingColumn
                                ]?.toString() as string,
                                index,
                            }))
                        )
                    }
                },
                (err) => {
                    console.warn('resultSet', sqlQuery)
                    reject(false)
                    // SQLStatementErrorCallback require return boolean value
                    return false
                }
            )
        })
    })
}
