import { StyleSheet, Pressable, Image } from 'react-native'
import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useMemo,
    useState,
} from 'react'
import { IScale } from './index'
import image from '../../../../assets/icons'
import { ScaleIcon } from '@appformula/app-descriptor/src'
import { Theme, useTheme } from '../../../../styles/theme'
import { numberScaleIcon } from '../../../../assets/icons/icon-number-scale'

export interface IItemScale {
    isActiveDefault: boolean
    unCheck: () => void
    check: () => void
}

const ItemScale = forwardRef(
    (
        props: {
            item: IScale
            scaleIcon: ScaleIcon
            handleItemPress: (id: string, index: number) => void
            index: number
            isActiveDefault?: boolean
        },
        ref
    ) => {
        const { item, scaleIcon, handleItemPress, index, isActiveDefault } =
            props
        const theme = useTheme()
        const [isActive, setIsActive] = useState<boolean>(false)
        const styles = useMemo(
            () => createStyles(theme, isActive),
            [theme, isActive]
        )

        useImperativeHandle(ref, () => ({
            unCheck: () => {
                return unCheck()
            },
            check: () => {
                return check()
            },
            isActive,
        }))

        useEffect(() => {
            setIsActive(isActiveDefault)
        }, [isActiveDefault])

        const onPress = () => {
            handleItemPress && handleItemPress(item.id, index)
        }

        const unCheck = () => {
            setIsActive(false)
        }

        const check = () => {
            setIsActive(true)
        }

        return (
            <Pressable onPress={onPress}>
                <Image
                    style={styles.image}
                    source={numberScaleIcon[scaleIcon]}
                    resizeMode={'contain'}
                />
            </Pressable>
        )
    }
)

ItemScale.displayName = 'ItemScale'
export default React.memo(ItemScale)

const createStyles = (
    { colorScheme, roundness, typography }: Theme,
    isActive: boolean
) =>
    StyleSheet.create({
        image: {
            height: 24,
            width: 24,
            marginVertical: 14,
            marginHorizontal: 8,
            tintColor: isActive
                ? colorScheme.starRating
                : colorScheme.starInactive,
        },
    })
