//
//  OptionFilterItem.tsx
//
//  Created by thaitd96 on 2022-25-07 18:23.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { View, StyleSheet, Text, Image, TouchableOpacity } from 'react-native'
import React from 'react'
import { observer } from 'mobx-react-lite'
import image from '../../../../../assets/icons'
import { Theme, useTheme } from '../../../../../styles/theme'
import appConstants from '../../../../../constants/const'

export const OptionFilterItem = observer(
    (props: {
        item: string
        isSelected: boolean
        onPressItem: (item: string) => void
    }) => {
        const { item } = props

        const theme = useTheme()
        const styles = React.useMemo(() => createStyles(theme), [theme])

        const onPressItem = () => {
            props.onPressItem(item)
        }

        return (
            <TouchableOpacity onPress={onPressItem} style={styles.viewCheckbox}>
                <View style={styles.wrapImg}>
                    {props.isSelected ? (
                        <View>
                            <Image
                                style={styles.imgCheckbox}
                                source={image.checkBox}
                                resizeMode={'stretch'}
                            />
                            <Image
                                style={styles.imgCheck}
                                source={image.checkV1}
                                resizeMode={'stretch'}
                            />
                        </View>
                    ) : (
                        <Image
                            style={styles.imgUnCheckbox}
                            source={image.unCheckBox}
                            resizeMode={'stretch'}
                        />
                    )}
                </View>
                <Text
                    style={styles.title}
                    numberOfLines={1}
                    ellipsizeMode={'tail'}
                >
                    {item}
                </Text>
            </TouchableOpacity>
        )
    }
)

const createStyles = ({ colorScheme }: Theme) =>
    StyleSheet.create({
        viewCheckbox: {
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            paddingVertical: 13,
        },
        title: {
            fontWeight: '400',
            fontSize: 15,
            lineHeight: 18,
            marginRight: appConstants.PADDING_COMMON,
            color: colorScheme.labelPrimary,
        },
        wrapImg: {
            marginRight: 12,
        },
        imgCheckbox: {
            height: 20,
            width: 20,
            tintColor: colorScheme.accentColor,
        },
        imgCheck: { height: 20, width: 20, position: 'absolute' },
        imgUnCheckbox: { height: 20, width: 20 },
    })
