import { Nullable } from '@appformula/shared-foundation-x/src'
import { useCallback, useContext, useState } from 'react'
import React, { Button, StyleSheet, TextInput, View } from 'react-native'
import { FormulaManagerContext } from '../../context/FormulaManagerContext'
import { FormulaManager } from '../../utils/integrate-formula/FormulaManager'
import { TransformBinding } from '../../utils/transform-binding/TransformBinding'

export const TestFormula = () => {
    const [text, setText] = useState('')
    const formulaManager: Nullable<FormulaManager> = useContext(
        FormulaManagerContext
    )

    const onChangeText = useCallback((text) => {
        setText(text)
    }, [])

    const onTest = useCallback(async () => {
        const result = await TransformBinding(
            {
                source: 'formula',
                formulaString: text ?? '',
                valueTypeName: 'string',
            },
            formulaManager
        )
        console.info('Execute =>', result)
    }, [formulaManager, text])

    return (
        <View style={styles.viewWrap}>
            <TextInput
                placeholder="Type"
                style={styles.textInput}
                onChangeText={onChangeText}
                value={text}
                onSubmitEditing={onTest}
            ></TextInput>
            <View style={styles.viewButton}>
                <Button onPress={onTest} title={'Run formula'} />
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    viewButton: { marginHorizontal: 20 },
    textInput: {
        height: 50,
        borderWidth: 1,
        borderRadius: 4,
        margin: 20,
        paddingHorizontal: 16,
    },
    viewWrap: { flex: 1 },
})
