//
//  AvatarByName.tsx
//
//  Created by thaitd96 on 2022-07-06 11:30.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React, { useMemo } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { COLORS } from '../../assets/colors'
import { useTheme } from '../../styles/theme'
import { getStanForName } from '../../utils'

type AvatarByNameProps = {
    name: string
    size: number
    backgroundColor?: string
    textColor?: string
    nonChangeName?: boolean
}

const AvatarByName = (props: AvatarByNameProps) => {
    const { name, size, backgroundColor, textColor, nonChangeName } = props
    const theme = useTheme()
    const randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16)
    const bgColor = backgroundColor || randomColor
    const color = textColor || COLORS.TEXT_AVATAR
    const styles = useMemo(
        () => createStyles(size, bgColor, color),
        [size, bgColor, color]
    )
    const text = nonChangeName ? name : getStanForName(name).toUpperCase()

    return (
        <View style={styles.view}>
            <Text style={styles.text}>{text}</Text>
        </View>
    )
}

export default AvatarByName

const createStyles = (size: number, bgColor: string, color: string) =>
    StyleSheet.create({
        text: { fontSize: 16, fontWeight: '600', lineHeight: 16, color },
        view: {
            height: size,
            width: size,
            borderRadius: size / 2,
            backgroundColor: bgColor,
            resizeMode: 'contain',
            justifyContent: 'center',
            alignItems: 'center',
        },
    })
