import {
    Text,
    StyleSheet,
    TouchableOpacity,
    ScrollView,
    View,
} from 'react-native'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Theme, useTheme } from '../../../../styles/theme'
import Options, { IOptionsRef } from './Options'
import { useNavigation } from '@react-navigation/native'
import { observer } from 'mobx-react-lite'
import { CollectionSortStore } from './CollectionSortStore'
import appConstants from '../../../../constants/const'
import {
    ARNCollectionPageStore,
    ignoredColumns,
} from '../../ARNCollectionPageStore'
import { Alert } from '../../../../components/alert'
import { SortOrder } from '@appformula/app-descriptor/src'
import { ColumnNames } from '../../../../database/type'

export const OPTIONS_ORDER_DATA = [
    {
        id: appConstants.ORDER.ASCENDING,
        key: appConstants.ORDER.ASCENDING,
        text: 'Ascending',
    },
    {
        id: appConstants.ORDER.DESCENDING,
        key: appConstants.ORDER.DESCENDING,
        text: 'Descending',
    },
]

const SortCollectionScreen = observer(
    (props: {
        collectionSortStore: CollectionSortStore
        arnCollectionPageStore: ARNCollectionPageStore
    }) => {
        const { collectionSortStore, arnCollectionPageStore } = props
        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])
        const navigation = useNavigation()
        const [listSortData, setListSortData] = useState([])

        const optionsSortByRef = useRef<IOptionsRef>()
        const orderRef = useRef<IOptionsRef>()

        useEffect(() => {
            const columns =
                props.arnCollectionPageStore.mainPageDataStore.mainPageStore.currentTables?.find(
                    (item) =>
                        item['name'] ===
                            props.arnCollectionPageStore.pageStore?.dataSource
                                ?.id ?? ''
                )?.['columnNames'] as ColumnNames

            const data = Object.entries(columns)
                .filter((item) => !ignoredColumns.includes(item[1].name))
                .map((item) => ({
                    id: item[0],
                    key: item[0],
                    text: item[1].name,
                }))

            setListSortData(data)
        }, [
            props.arnCollectionPageStore.mainPageDataStore.mainPageStore
                .currentTables,
            props.arnCollectionPageStore.pageStore?.dataSource?.id,
        ])

        const onApply = useCallback(() => {
            const sortBySelected =
                optionsSortByRef.current.getOptionSeleted() as string
            const orderSelected = orderRef.current.getOptionSeleted()

            if (!sortBySelected) {
                Alert.alert('Warning', 'Please choose kind of criteria', [
                    {
                        text: 'OK',
                        onPress: () => {
                            //
                        },
                    },
                ])

                return
            }

            if (!orderSelected) {
                Alert.alert('Warning', 'Please choose kind of arrangement', [
                    {
                        text: 'OK',
                        onPress: () => {
                            //
                        },
                    },
                ])

                return
            }

            collectionSortStore.setColumnName(sortBySelected)
            collectionSortStore.setOrder(orderSelected as SortOrder)

            setTimeout(async () => {
                await props.arnCollectionPageStore.saveQuerySortFilter()
                await arnCollectionPageStore.getAndSetData()
                navigation.goBack()
            }, 0)
        }, [
            collectionSortStore,
            props.arnCollectionPageStore,
            arnCollectionPageStore,
            navigation,
        ])

        const onClear = useCallback(async () => {
            arnCollectionPageStore.clearCollectionSortStore()
            await props.arnCollectionPageStore.removeQuerySort()
            await arnCollectionPageStore.getAndSetData()
            navigation.goBack()
        }, [arnCollectionPageStore, navigation, props.arnCollectionPageStore])

        return (
            <View style={styles.containerView}>
                <View style={styles.header}>
                    <TouchableOpacity onPress={onClear}>
                        <Text
                            style={styles.textButtonHeader}
                            numberOfLines={1}
                            ellipsizeMode={'tail'}
                        >
                            {'Clear'}
                        </Text>
                    </TouchableOpacity>
                    <Text style={styles.headerTitleStyle}>Sort</Text>
                    <TouchableOpacity onPress={onApply}>
                        <Text style={styles.textButtonHeader}>{'Apply'}</Text>
                    </TouchableOpacity>
                </View>
                <ScrollView style={styles.container}>
                    <Options
                        ref={optionsSortByRef}
                        data={listSortData}
                        title={'Sort by'}
                        type={appConstants.SORT.SORT_BY}
                        collectionSortStore={collectionSortStore}
                        optionSelected={collectionSortStore.columnName}
                    />
                    <Options
                        ref={orderRef}
                        data={OPTIONS_ORDER_DATA}
                        title={'Order'}
                        type={appConstants.SORT.ORDER}
                        collectionSortStore={collectionSortStore}
                        optionSelected={collectionSortStore.order}
                    />
                </ScrollView>
            </View>
        )
    }
)

export default SortCollectionScreen

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        containerView: {
            flex: 1,
            backgroundColor: colorScheme.backgroundL1,
        },
        header: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingVertical: 14,
            borderBottomColor: colorScheme.separator,
            borderBottomWidth: 1,
            paddingHorizontal: 10,
            backgroundColor: colorScheme.backgroundL1,
            width: '100%',
        },
        container: { flex: 1, backgroundColor: colorScheme.backgroundL1 },
        txtClear: {
            color: colorScheme.accentColor,
            fontSize: 17,
            lineHeight: 20.57,
            fontWeight: '600',
        },
        txtApply: {
            color: colorScheme.accentColor,
            fontSize: 17,
            lineHeight: 20.57,
            fontWeight: '600',
        },
        txtTitle: {
            color: colorScheme.labelPrimary,
            fontSize: 17,
            lineHeight: 20.57,
            fontWeight: '600',
        },
        headerTitleStyle: {
            color: colorScheme.labelPrimary,
            fontSize: 17,
            fontWeight: '600',
            backgroundColor: colorScheme.backgroundL1,
        },
        textButtonHeader: {
            color: colorScheme.accentColor,
            fontWeight: '500',
            fontSize: 17,
        },
    })
