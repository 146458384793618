import { Binding, BindingValue } from '@appformula/app-descriptor/src'
import { Nullable } from '@appformula/shared-foundation-x/src'
import { useBinding } from './useBinding'
import { useBindingHaveItemData } from './useBindingHaveItemData'

type ItemData = {
    [key: string]: unknown
}

export function useBindingCommon(
    binding: Nullable<Binding<BindingValue>>,
    itemData?: ItemData
) {
    const resultWithItemData = useBindingHaveItemData(binding, itemData)
    const result = useBinding(binding)
    if (itemData) {
        return resultWithItemData
    } else {
        return result
    }
}
