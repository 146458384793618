/* eslint-disable no-inline-styles/no-inline-styles */
// Disable because can not use stylesheet for div tag style line: 44 and 46
//
//  DateInputItem.web.tsx
//
//  Created by thaitd96 on 2022-26-07 17:41.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React, { ReactNode, useMemo, useRef } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { observer } from 'mobx-react-lite'
import DatePicker, { CalendarContainer } from 'react-datepicker'
// import 'react-datepicker/dist/react-datepicker.css'
import dayjs from 'dayjs'
import { Theme, useTheme } from '../../../../../styles/theme'
import appConstants from '../../../../../constants/const'

export const DateInputItem = observer(
    (props: {
        selectedDate?: Date
        minimumDate?: Date
        maximumDate?: Date
        setDate: (date: Date) => void
    }) => {
        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])
        const datePickerRef = useRef(null)

        const onChange = (date: Date) => {
            props.setDate(date)
        }

        const MyContainer = (props: {
            children: ReactNode[]
            className: string
        }) => {
            return (
                <div style={{ width: 330 }}>
                    <CalendarContainer className={props.className}>
                        <div style={{ position: 'relative' }}>
                            {props.children}
                        </div>
                    </CalendarContainer>
                </div>
            )
        }

        return (
            <View style={styles.wrapDateTimeTrigger}>
                <View style={styles.viewDateTime}>
                    <DatePicker
                        ref={datePickerRef}
                        selected={props.selectedDate}
                        onChange={onChange}
                        customInput={
                            <View style={styles.dateTimeTrigger}>
                                <Text
                                    style={styles.dateTimeContent}
                                    numberOfLines={1}
                                    ellipsizeMode={'tail'}
                                >
                                    {props.selectedDate
                                        ? dayjs(props.selectedDate).format(
                                              'MMM D, YYYY'
                                          )
                                        : 'Select date'}
                                </Text>
                            </View>
                        }
                        shouldCloseOnSelect
                        calendarContainer={MyContainer}
                        minDate={props.minimumDate}
                        maxDate={props.maximumDate}
                    />
                </View>
            </View>
        )
    }
)

const createStyles = ({ colorScheme, roundness }: Theme) =>
    StyleSheet.create({
        viewDateTime: {
            flex: 1,
            paddingHorizontal: appConstants.PADDING_COMMON,
            backgroundColor: colorScheme.backgroundL2,
            borderRadius: roundness,
        },
        wrapDateTimeTrigger: {
            zIndex: 10000,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: appConstants.PADDING_COMMON / 2,
        },
        dateTimeTrigger: {
            height: 48,
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
        },
        dateTimeContent: {
            color: colorScheme.accentColor,
            fontWeight: '500',
            fontSize: 15,
            lineHeight: 18,
        },
    })
