import {
    View,
    Text,
    Pressable,
    StyleSheet,
    Image,
    ScrollView,
    Linking,
    useColorScheme,
} from 'react-native'
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { useNavigation } from '@react-navigation/native'
import { Theme, useTheme } from '../../styles/theme'
import image from '../../assets/icons'
import { AppAuthenticationContext } from '../../context/AppAuthenticationContext'
import { Alert } from '../../components/alert'
import screenNames from '../../navigations/screenNames'
import { AppStore } from '../../app/AppStore'
import { observer } from 'mobx-react-lite'
import cache from '../../utils/cache'

const LIST_INFO_SETTING = [
    {
        id: '1',
        title: 'Contact',
        webLink:
            'https://reactnative.dev/docs/linking#open-links-and-deep-links-universal-links',
    },
    {
        id: '2',
        title: 'Website',
        webLink:
            'https://reactnative.dev/docs/linking#open-links-and-deep-links-universal-links',
    },
    {
        id: '3',
        title: 'Privacy Policy',
        webLink: 'slack://open?team=123456',
    },
    {
        id: '4',
        title: 'Term of Use',
        webLink:
            'https://reactnative.dev/docs/linking#open-links-and-deep-links-universal-links',
    },
]
interface IDataInfo {
    id: string
    title: string
    webLink: string
}

const SettingPage = () => {
    const appAuthenticationStore = useContext(AppAuthenticationContext)
    if (appAuthenticationStore) {
        return (
            <SettingPageScreen
                appAuthenticationStore={appAuthenticationStore}
            />
        )
    }
    return null
}

const SettingPageScreen = observer(
    ({ appAuthenticationStore }: { appAuthenticationStore: AppStore }) => {
        const theme = useTheme()
        const colorSchemeName = useColorScheme()
        const styles = useMemo(() => createStyles(theme), [theme])
        const navigation = useNavigation()
        const onCancel = useCallback(() => {
            navigation.goBack()
        }, [navigation])
        const userInfo = appAuthenticationStore?.authenticatedUser
        // Can not observer user change on WEB
        const changeProfile = appAuthenticationStore?.changeProfile
        const [displayName, setDisplayName] = useState<string>('')

        useEffect(() => {
            try {
                const currentDisplayName = JSON.parse(
                    userInfo?.displayName || ''
                )
                const firstName = currentDisplayName?.firstName || ''
                const lastName = currentDisplayName?.lastName || ''
                setDisplayName(`${firstName} ${lastName}`)
            } catch (error) {
                setDisplayName(userInfo?.displayName ?? '')
            }
        }, [userInfo?.displayName])

        useEffect(() => {
            navigation.setOptions({
                headerLeft: () => (
                    <Pressable onPress={onCancel} hitSlop={10}>
                        <View style={styles.wrapContentHeader}>
                            <Image source={image.back} style={styles.icBack} />
                            <Text style={styles.textButtonHeader}>
                                {'More'}
                            </Text>
                        </View>
                    </Pressable>
                ),
                title: 'Settings',
                headerTitleStyle: styles.headerTitleStyle,
                headerStyle: {
                    backgroundColor: theme.colorScheme.backgroundL1 as string,
                },
                headerShadowVisible: false,
            })
        }, [
            navigation,
            styles.headerTitleStyle,
            styles.textButtonHeader,
            onCancel,
            theme.colorScheme.backgroundL1,
            styles.icBack,
            styles.wrapContentHeader,
        ])

        const renderItemInfo = (item: IDataInfo, index: number) => {
            return (
                <Pressable key={item.id} onPress={openURLButton(item?.webLink)}>
                    <View
                        style={[
                            styles.wrapItemInfo,
                            index === LIST_INFO_SETTING.length - 1 &&
                                styles.wrapLastItemInfo,
                        ]}
                        key={item.id}
                    >
                        <Text style={styles.txtInfo}>{item?.title}</Text>
                        <Image
                            source={image.arrowRight}
                            style={styles.icArrowRight}
                        />
                    </View>
                </Pressable>
            )
        }

        const openURLButton = (url: string) => async () => {
            try {
                const supported = await Linking.canOpenURL(url)

                if (supported) {
                    // Opening the link with some app, if the URL scheme is "http" the web link should be opened
                    // by some browser in the mobile
                    await Linking.openURL(url)
                } else {
                    Alert.alert(`Don't know how to open this URL: ${url}`)
                }
            } catch (error) {
                Alert.alert(`Don't know how to open this URL: ${url}`)
            }
        }

        const onLogout = () => {
            if (cache.sourceStartUp !== 'APP_EDITOR') {
                appAuthenticationStore.logout()
            } else {
                Alert.alert(
                    'Notification',
                    `You can't use this feature in Web Editor!`
                )
            }
        }

        const onEdit = () => {
            navigation.navigate(screenNames.EDIT_PROFILE_SCREEN as never)
        }

        return (
            <ScrollView
                style={styles.container}
                contentContainerStyle={styles.contentScrollView}
            >
                <View style={styles.containerProfileTag}>
                    <View style={styles.wrapContentProfile}>
                        {userInfo?.photoURL ? (
                            <Image
                                source={{
                                    uri: userInfo?.photoURL || '',
                                }}
                                style={styles.imgAvatar}
                            />
                        ) : (
                            <View style={[styles.imgAvatar, styles.noImgAva]} />
                        )}
                        <View style={styles.wrapInfo}>
                            {!!userInfo?.displayName && (
                                <Text
                                    style={styles.txtName}
                                    numberOfLines={userInfo?.email ? 1 : 2}
                                >
                                    {displayName}
                                </Text>
                            )}
                            {!!userInfo?.email && (
                                <Text
                                    style={styles.txtEmail}
                                    numberOfLines={
                                        userInfo?.displayName ? 1 : 2
                                    }
                                >
                                    {userInfo?.email || ''}
                                </Text>
                            )}
                        </View>
                    </View>
                    <Pressable hitSlop={10} onPress={onEdit}>
                        <Text style={styles.txtEdit}>Edit</Text>
                    </Pressable>
                </View>

                <View style={styles.wrapListSetingInfo}>
                    {LIST_INFO_SETTING.map((item, index) =>
                        renderItemInfo(item, index)
                    )}
                </View>

                {cache.sourceStartUp !== 'APP_FORMULA_GO' && (
                    <Pressable onPress={onLogout}>
                        <View style={styles.wrapBtnLogout}>
                            <Image
                                source={image.logoutIcon}
                                style={styles.imgLogout}
                            />
                            <Text style={styles.txtLogout}>Sign out</Text>
                        </View>
                    </Pressable>
                )}

                <View style={styles.wrapFooter}>
                    <Text style={styles.txtMadeWith}>Made with</Text>
                    <Image
                        source={
                            colorSchemeName === 'dark'
                                ? image.logoDarkMode
                                : image.logoLightMode
                        }
                        style={styles.imgAppName}
                    />
                </View>
            </ScrollView>
        )
    }
)

export default SettingPage

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        wrapInfo: {
            marginVertical: 1,
            width: 166,
        },
        noImgAva: { backgroundColor: colorScheme.accentColor },
        imgAppName: { width: 80, height: 21.51 },
        wrapFooter: {
            marginTop: 23,
            flexDirection: 'row',
            alignSelf: 'center',
            alignItems: 'center',
        },
        txtAppName: {
            color: colorScheme.labelSecondary,
            fontSize: 13,
            lineHeight: 16,
            fontWeight: '500',
        },
        txtMadeWith: {
            color: colorScheme.labelSecondary,
            fontSize: 13,
            // lineHeight: 15.73,
            fontWeight: '500',
            marginRight: 6,
        },
        txtLogout: {
            paddingVertical: 18.5,
            color: colorScheme.error,
            fontSize: 15,
            fontWeight: '500',
            lineHeight: 18.15,
        },
        imgLogout: { width: 34, height: 34, marginRight: 8, marginLeft: 16 },
        wrapBtnLogout: {
            flexDirection: 'row',
            alignItems: 'center',
            borderWidth: 1,
            borderRadius: 5,
            borderColor: colorScheme.separator,
        },
        wrapItemInfo: {
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderBottomColor: colorScheme.separator,
            borderBottomWidth: 1,
        },
        wrapLastItemInfo: {
            borderBottomWidth: 0,
        },
        txtInfo: {
            paddingVertical: 19,
            fontSize: 15,
            fontWeight: '500',
            lineHeight: 18,
            color: colorScheme.labelPrimary,
        },
        wrapListSetingInfo: {
            borderRadius: 5,
            paddingLeft: 16,
            borderWidth: 1,
            marginVertical: 16,
            borderColor: colorScheme.separator,
        },
        txtEmail: {
            fontSize: 14,
            fontWeight: '400',
            lineHeight: 18,
            color: colorScheme.labelSecondary,
        },
        txtName: {
            // flex: 1,
            fontSize: 15,
            fontWeight: '600',
            lineHeight: 18,
            color: colorScheme.labelPrimary,
            marginBottom: 6,
        },
        imgAvatar: {
            width: 47,
            height: 47,
            borderRadius: 47 / 2,
            marginRight: 12,
        },
        wrapContentProfile: { flexDirection: 'row', flex: 1 },
        txtEdit: {
            color: colorScheme.accentColor,
            fontSize: 15,
            lineHeight: 18,
            fontWeight: '500',
        },
        contentScrollView: { paddingHorizontal: 16 },
        containerProfileTag: {
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between',
            backgroundColor: colorScheme.backgroundL2,
            borderRadius: 8,
            padding: 16,
            alignItems: 'center',
            marginVertical: 16,
            borderWidth: 1,
            borderColor: colorScheme.separator,
        },
        container: {
            flex: 1,
            backgroundColor: colorScheme.backgroundL1,
        },
        headerTitleStyle: {
            color: colorScheme.labelPrimary,
            fontSize: 17,
            fontWeight: '600',
        },
        textButtonHeader: {
            color: colorScheme.accentColor,
            fontWeight: '500',
            fontSize: 17,
        },
        icBack: {
            width: 28,
            height: 28,
            tintColor: colorScheme.accentColor,
            marginLeft: -8,
        },
        wrapContentHeader: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        icArrowRight: { width: 20, height: 20, marginRight: 16 },
    })
