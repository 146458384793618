//
//  WelcomePage.tsx
//
//  Created by Peter Vu on 2022-03-28 11:07:03.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React, { useContext } from 'react'
import { WelcomePageStore } from './WelcomePageStore'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import screenNames from '../../navigations/screenNames'
import SignInScreen from './SignInScreen'
import SignUpScreen from './SignUpScreen'
import ResetPasswordScreen from './ResetPasswordScreen'
import VerificationScreen from './VerificationScreen'
import { observer } from 'mobx-react-lite'
import SetupPasswordScreen from './SetupPasswordScreen'
import VerifySuccessScreen from './VerifySuccessScreen'
import CustomizeProfileScreen from './CustomizeProfileScreen'
import UnauthorizedScreen from './UnauthorizedScreen'
import { WelcomePageDataStoreContext } from './context/WelcomePageDataStoreContext'

const Stack = createNativeStackNavigator()

export function WelcomePage(props: { store: WelcomePageStore }) {
    return (
        <WelcomePageDataStoreContext.Provider value={props.store}>
            <Stack.Navigator>
                <Stack.Screen
                    name={screenNames.SIGN_IN}
                    component={SignInScreenRoute}
                    options={{ headerShown: false }}
                />
                <Stack.Screen
                    name={screenNames.SIGN_UP}
                    component={SignUpScreenRoute}
                    options={{
                        headerShown: false,
                    }}
                />
                <Stack.Screen
                    name={screenNames.RESET_PASSWORD}
                    component={ResetPasswordScreenRoute}
                    options={{ headerShown: false }}
                />
                <Stack.Screen
                    name={screenNames.VERIFICATION}
                    component={VerificationScreenRoute}
                    options={{ headerShown: false }}
                />
                <Stack.Screen
                    name={screenNames.SETUP_PASSWORD}
                    component={SetupPasswordScreenRoute}
                    options={{ headerShown: false }}
                />
                <Stack.Screen
                    name={screenNames.VERIFICATION_SUCCESS}
                    component={VerifySuccessScreenRoute}
                    options={{ headerShown: false }}
                />
                <Stack.Screen
                    name={screenNames.CUSTOMIZE_PROFILE}
                    component={CustomizeProfileScreenRoute}
                    options={{ headerShown: false }}
                />
                <Stack.Screen
                    name={screenNames.UNAUTHORIZED_SCREEN}
                    component={UnauthorizedScreenRoute}
                    options={{ headerShown: false }}
                />
            </Stack.Navigator>
        </WelcomePageDataStoreContext.Provider>
    )
}

const SignInScreenRoute = () => {
    const store = useContext(WelcomePageDataStoreContext)
    return <SignInScreenContent pageStore={store as WelcomePageStore} />
}

const SignInScreenContent = observer(
    (props: { pageStore: WelcomePageStore }) => {
        return <SignInScreen store={props.pageStore} />
    }
)

const SignUpScreenRoute = () => {
    const store = useContext(WelcomePageDataStoreContext)
    return <SignUpScreenContent pageStore={store as WelcomePageStore} />
}

const SignUpScreenContent = observer(
    (props: { pageStore: WelcomePageStore }) => {
        return <SignUpScreen store={props.pageStore} />
    }
)

const ResetPasswordScreenRoute = () => {
    const store = useContext(WelcomePageDataStoreContext)
    return <ResetPasswordScreenContent pageStore={store as WelcomePageStore} />
}

const ResetPasswordScreenContent = observer(
    (props: { pageStore: WelcomePageStore }) => {
        return <ResetPasswordScreen store={props.pageStore} />
    }
)

const VerificationScreenRoute = () => {
    const store = useContext(WelcomePageDataStoreContext)
    return <VerificationScreen store={store as WelcomePageStore} />
}

const SetupPasswordScreenRoute = () => {
    const store = useContext(WelcomePageDataStoreContext)
    return <SetupPasswordScreen store={store as WelcomePageStore} />
}

const VerifySuccessScreenRoute = () => {
    const store = useContext(WelcomePageDataStoreContext)
    return <VerifySuccessScreen store={store as WelcomePageStore} />
}

export const CustomizeProfileScreenRoute = () => {
    const store = useContext(WelcomePageDataStoreContext)
    return <CustomizeProfileScreen store={store as WelcomePageStore} />
}

const UnauthorizedScreenRoute = () => {
    const store = useContext(WelcomePageDataStoreContext)
    return <UnauthorizedScreen store={store as WelcomePageStore} />
}
