//
//  BarcodeScannerPage.tsx
//
//  Created by thaitd on 2022-06-28 14:25.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useMemo, useState } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { Theme, useTheme } from '../../../../styles/theme'
import appConstants from '../../../../constants/const'
import { BarCodeScanner } from 'expo-barcode-scanner'
import { useNavigation, useRoute } from '@react-navigation/native'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { ARNFormPageStore } from '../../ARNFormPageStore'
import {
    BarcodeScannerFormInputStore,
    FormPageStore,
} from '@appformula/app-studio-core/src'
import { ARNBarCodescannerFormStore } from './ARNBarCodescannerFormStore'

type Params = {
    componentId: string
}

export const BarcodeScannerPage = observer(
    (props: {
        pageStore: FormPageStore
        arnFormPageStore: ARNFormPageStore
    }) => {
        const { pageStore, arnFormPageStore } = props
        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])
        const navigation = useNavigation()
        const route = useRoute()
        const [hasPermission, setHasPermission] = useState<boolean | null>(null)
        const { componentId } = route.params as Params
        const componentStore = pageStore.componentStore(
            componentId
        ) as BarcodeScannerFormInputStore
        const arnBarCodescannerFormStore =
            arnFormPageStore.childComponentFormStore(
                componentId,
                componentStore.inputType
            ) as ARNBarCodescannerFormStore

        const cancel = React.useCallback(() => {
            navigation.goBack()
        }, [navigation])

        useEffect(() => {
            handleRequestPermission()
        }, [])

        const handleRequestPermission = async () => {
            const { status } = await BarCodeScanner.requestPermissionsAsync()
            setHasPermission(status === 'granted')
        }

        const handleBarCodeScanned = ({ data }: { data: string }) => {
            arnBarCodescannerFormStore.setValue(data)
            navigation.goBack()
        }

        if (hasPermission === null) {
            return (
                <View style={styles.viewNote}>
                    <Text>Requesting for camera permission</Text>
                </View>
            )
        }

        if (hasPermission === false) {
            return (
                <View style={styles.viewNote}>
                    <Text>No access to camera</Text>
                </View>
            )
        }

        return (
            <View style={styles.container}>
                <View style={styles.header}>
                    <TouchableOpacity
                        style={styles.touchCancel}
                        onPress={cancel}
                    >
                        <Text style={styles.textCancel}>{'Cancel'}</Text>
                    </TouchableOpacity>
                </View>
                <BarCodeScanner
                    onBarCodeScanned={handleBarCodeScanned}
                    style={styles.barcodeScanner}
                />
            </View>
        )
    }
)

const createStyles = ({ colorScheme }: Theme) =>
    StyleSheet.create({
        viewNote: { flex: 1, margin: 20, alignItems: 'center' },
        barcodeScanner: { flex: 1 },
        container: {
            flex: 1,
        },
        header: {
            height: 60,
            width: '100%',
            backgroundColor: colorScheme.backgroundL1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        textCancel: {
            fontSize: 17,
            color: colorScheme.accentColor,
            fontWeight: '500',
        },
        touchCancel: {
            alignItems: 'center',
            justifyContent: 'center',
            paddingHorizontal: appConstants.PADDING_COMMON,
            height: '100%',
        },
    })
