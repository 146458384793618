import React, {
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { AppMetadataContext } from '../../context/AppMetadataContext'
import buildConfig from '../../../buildConfig.json'
import * as Linking from 'expo-linking'
import { apiClient } from '../../utils/network'
import { StyleSheet, Text, View } from 'react-native'
import { Loading } from '../../components'
import { Theme, useTheme } from '../../styles/theme'
import { AppDescriptorStore } from '@appformula/app-studio-core/src'
import { AppDescriptorContext } from '../../context/AppDescriptorContext'
import appConstants from '../../constants/const'
import { wrapContextAppStore } from './WrapContextAppStore'
import NotPublishScreen from './NotPublishScreen'
interface IProps {
    children: ReactNode
}

interface IMetadata {
    teamId: string
    appId: string
}

type StatusType = 'LOADING' | 'SUCCESS' | 'ERROR' | 'NOT_PUBLISH'

const WrapContextApp = (props: IProps) => {
    const [metaData, setMetaData] = useState<IMetadata>()
    const [descriptor, setDescriptor] = useState<AppDescriptorStore>(undefined)
    const [status, setStatus] = useState<StatusType>('LOADING')
    const theme = useTheme()
    const styles = useMemo(() => createStyles(theme), [theme])

    // FOR DEVELOPER
    const getInfoAppForDev = useCallback(() => {
        setMetaData({
            teamId: buildConfig.teamId,
            appId: buildConfig.appId,
        })
    }, [])

    const getInfoApp = async (api: string) => {
        try {
            const response = await apiClient.get(api, {
                needSetApiPath: false,
            })
            if (response.data.success) {
                const result = response?.data?.data
                // @ts-ignore: global variable
                global.teamId = result.teamId
                // @ts-ignore: global variable
                global.appId = result.appId
                setMetaData(result)
            } else {
                setStatus('ERROR')
            }
        } catch (error) {
            setStatus('ERROR')
        }
    }

    useEffect(() => {
        // FOR DEVELOPER
        if (__DEV__) {
            getInfoAppForDev()
            return
        }
        const redirectUrl = Linking.createURL(appConstants.SUFFIX_LINK_REDIRECT)
        getInfoApp(redirectUrl)
    }, [])

    const getDescriptorStore = useCallback(async () => {
        try {
            if (metaData) {
                const descriptorStore =
                    await wrapContextAppStore.getDescriptorStore(
                        metaData.teamId,
                        metaData.appId
                    )

                if (descriptorStore) {
                    setDescriptor(descriptorStore)
                    setStatus('SUCCESS')
                } else {
                    setStatus('NOT_PUBLISH')
                }
            }
        } catch (error) {
            setStatus('ERROR')
        }
    }, [metaData])

    useEffect(() => {
        if (metaData) {
            getDescriptorStore()
            wrapContextAppStore.listenPublishApp(
                metaData.teamId,
                metaData.appId
            )
        }

        return () => {
            wrapContextAppStore.publishAppUnsubscribe?.()
        }
    }, [getDescriptorStore, metaData])

    if (status === 'LOADING') {
        return <Loading />
    } else if (status === 'SUCCESS') {
        return (
            <AppMetadataContext.Provider value={metaData}>
                <AppDescriptorContext.Provider value={descriptor}>
                    {props.children}
                </AppDescriptorContext.Provider>
            </AppMetadataContext.Provider>
        )
    } else if (status === 'NOT_PUBLISH') {
        return <NotPublishScreen />
    } else {
        return (
            <View style={styles.container}>
                <Text style={styles.contentError}>
                    Something went wrong. Please try again.
                </Text>
            </View>
        )
    }
}

export default WrapContextApp

const createStyles = ({ colorScheme, roundness }: Theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
            backgroundColor: colorScheme.backgroundL1,
            justifyContent: 'center',
            alignItems: 'center',
        },

        contentError: {
            color: colorScheme.labelPrimary,
        },
    })
