import {
    View,
    Text,
    Image,
    NativeSyntheticEvent,
    StyleSheet,
    Platform,
    Pressable,
} from 'react-native'
import React, {
    Fragment,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react'
import dayjs from 'dayjs'
import image from '../../../../../assets/icons'
import { ConvertDateFormat } from '../../../../../utils/date-time/DateTime'
import { Theme, useTheme } from '../../../../../styles/theme'
import { observer } from 'mobx-react-lite'
import { CalendarLayoutStore, IWeekPicked } from '../CalendarLayoutStore'
import ReactDatePicker from 'react-datepicker'
import ContextMenuView from './ContextMenuView'
import ContextMenuViewWeb from './ContextMenuView.web'
import { MenuOption } from 'react-native-popup-menu'
import { COLORS } from '../../../../../assets/colors'
const NumberOfMonthView = 3

interface IActionMenuItem {
    title: string
    key: string
}

const WeekTrigger = observer(
    (props: { calendarLayoutStore: CalendarLayoutStore }) => {
        const [listActionMenu, setListActionMenu] = useState<IActionMenuItem[]>(
            []
        )
        const { calendarLayoutStore } = props
        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])

        useEffect(() => {
            const newListActionMenu = []
            for (let i = 0; i < NumberOfMonthView; i++) {
                const prevWeekStart = dayjs(
                    props.calendarLayoutStore.weekPicked.startDate
                )
                    .subtract(i, 'week')
                    .toString()

                const prevWeekEnd = dayjs(
                    props.calendarLayoutStore.weekPicked.endDate
                )
                    .subtract(i, 'week')
                    .toString()

                const objMonth = {
                    key: `${i}`,
                    title: `W${dayjs(
                        prevWeekStart
                    ).isoWeek()} (${ConvertDateFormat(
                        prevWeekStart,
                        'D MMM'
                    )} - ${ConvertDateFormat(prevWeekEnd, 'D MMM')})`,
                }

                newListActionMenu.push(objMonth)
            }

            setListActionMenu(newListActionMenu)
        }, [
            props.calendarLayoutStore.weekPicked.endDate,
            props.calendarLayoutStore.weekPicked.startDate,
        ])

        const onPressItem = (event: NativeSyntheticEvent<any>) => {
            if (event.nativeEvent.index === listActionMenu.length) {
                // Click the last option Others
                openTimePicker()
            } else {
                const weekPicked = getDateFromWeekText(event.nativeEvent.name)
                onSelectWeekShortcut(weekPicked)
            }
        }

        const onPressItemMenuWeb = (item: IActionMenuItem) => () => {
            const weekPicked = getDateFromWeekText(item.title)
            onSelectWeekShortcut(weekPicked)
        }

        const getDateFromWeekText = (weekText: string) => {
            const matches = weekText.match(/\(([^)]+)\)/)[1].split(' - ')
            const year = dayjs(calendarLayoutStore.weekPicked.startDate)
                .subtract(1, 'week')
                .year()
            const startDate = dayjs(`${matches[0]} ${year}`).toDate()
            const endDate = dayjs(`${matches[1]} ${year}`).toDate()

            return {
                startDate,
                endDate,
            }
        }

        const openTimePicker = () => {
            calendarLayoutStore.openTimePicker()
        }

        const onSelectWeekShortcut = (weekPicked: IWeekPicked) => {
            calendarLayoutStore.setWeekPicked(weekPicked)
        }

        const CustomInput = useCallback(() => {
            return (
                <View style={styles.weekMonthPicker}>
                    <Text
                        style={styles.monthWeek}
                        ellipsizeMode={'tail'}
                        numberOfLines={1}
                    >
                        W
                        {`${dayjs(
                            calendarLayoutStore.weekPicked.startDate
                        ).isoWeek()}`}
                        <Text style={styles.year}>{` ${ConvertDateFormat(
                            calendarLayoutStore.weekPicked.startDate,
                            'YYYY'
                        )}`}</Text>
                    </Text>
                    <Image source={image.dropDown} style={styles.icon} />
                </View>
            )
        }, [
            calendarLayoutStore.weekPicked.startDate,
            styles.icon,
            styles.monthWeek,
            styles.weekMonthPicker,
            styles.year,
        ])

        const onValueChange = useCallback(
            (newDate: Date) => {
                calendarLayoutStore.closeTimePicker()
                if (newDate) {
                    calendarLayoutStore.setMonthYear(newDate)
                }
            },
            [calendarLayoutStore]
        )

        const ContextMenuNative = () => {
            return (
                <ContextMenuView
                    actions={[
                        ...listActionMenu,
                        {
                            title: 'Other...',
                            systemIcon: 'calendar',
                        },
                    ]}
                    onPress={onPressItem}
                    dropdownMenuMode
                >
                    <CustomInput />
                </ContextMenuView>
            )
        }

        const ContextMenuWeb = () => {
            return calendarLayoutStore.isOpenTimePicker ? (
                <ReactDatePicker
                    selected={calendarLayoutStore.monthYear}
                    onChange={onValueChange}
                    showMonthYearPicker
                    open={calendarLayoutStore.isOpenTimePicker}
                    customInput={<CustomInput />}
                />
            ) : (
                <ContextMenuViewWeb
                    MenuTriggerCustomize={<CustomInput />}
                    MenuOptionsCustomize={
                        <Fragment>
                            {listActionMenu.map((item) => (
                                <Pressable
                                    key={item.key}
                                    style={styles.itemMenu}
                                    onPress={onPressItemMenuWeb(item)}
                                >
                                    <Text style={styles.itemTextMenu}>
                                        {item.title}
                                    </Text>
                                </Pressable>
                            ))}
                            <MenuOption
                                customStyles={{
                                    optionWrapper: styles.itemMenuOther,
                                }}
                                onSelect={openTimePicker}
                            >
                                <View style={styles.wrapOptionOther}>
                                    <Text style={styles.itemTextMenu}>
                                        Other...
                                    </Text>
                                    <Image
                                        style={styles.imgCalendarPick}
                                        source={image.calendarPick}
                                    />
                                </View>
                            </MenuOption>
                        </Fragment>
                    }
                    menuOptionsProps={{
                        customStyles: {
                            optionsContainer: styles.listMenu,
                        },
                    }}
                    menuTriggerProps={{
                        customStyles: {
                            TriggerTouchableComponent: Pressable,
                        },
                    }}
                />
            )
        }

        return Platform.OS === 'web' ? (
            <ContextMenuWeb />
        ) : (
            <ContextMenuNative />
        )
    }
)

export default WeekTrigger

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        year: {
            fontSize: 19,
            fontWeight: '500',
            color: colorScheme.labelSecondary,
        },
        weekMonthPicker: {
            flexDirection: 'row',
            paddingRight: 30,
            paddingVertical: 10,
        },
        monthWeek: {
            fontSize: 19,
            fontWeight: '600',
            color: colorScheme.labelPrimary,
        },
        icon: {
            height: 24,
            width: 24,
            resizeMode: 'contain',
            marginLeft: 4,
            tintColor: colorScheme.accentColor,
        },

        itemMenu: {
            borderBottomWidth: 1,
            borderBottomColor: 'rgba(60, 60, 67, 0.36)',
            backgroundColor: COLORS.BG_MENU,
        },
        itemMenuOther: {
            backgroundColor: COLORS.BG_MENU,
        },
        itemTextMenu: {
            paddingLeft: 16,
            paddingVertical: 11,
            fontSize: 17,
            fontWeight: '400',
            lineHeight: 22,
        },
        imgCalendarPick: { marginRight: 14.36, width: 16.54, height: 15.28 },
        wrapOptionOther: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        listMenu: {
            width: 254,
            borderRadius: 8,
            overflow: 'hidden',
            backgroundColor: 'rgba(255,255,255,0.8)',
        },
    })
