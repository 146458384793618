/* eslint-disable no-inline-styles/no-inline-styles */
// Disable because can not use stylesheet for div tag style line: 54 and 56
//
//  DateRangeInput.tsx
//
//  Created by thaitd96 on 2022-20-04 11:51.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import image from '../../../../assets/icons'
import React, { ReactNode, useEffect, useMemo } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import FastImage from '../../../../components/fast-image/FastImage'
import { Theme, useTheme } from '../../../../styles/theme'
import { DateRangeInputStore } from '@appformula/app-studio-core'
import { observer } from 'mobx-react-lite'
import appConstants from '../../../../constants/const'
import DatePicker, { CalendarContainer } from 'react-datepicker'
// import "react-datepicker/dist/react-datepicker.css";
import dayjs from 'dayjs'
import { useBindingCommon } from '../../../collection/components/hooks/useBindingCommon'

export const DateRangeInput = observer(
    (props: { dateRangeInput: DateRangeInputStore }) => {
        const { dateRangeInput } = props
        const dateRangeInputData = dateRangeInput.object
        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])
        const [startDate, setStartDate] = React.useState<Date | undefined>(
            undefined
        )
        const [endDate, setEndDate] = React.useState<Date | undefined>(
            undefined
        )
        const title = useBindingCommon(dateRangeInputData.title)
        const initialStart = useBindingCommon(
            dateRangeInputData.initialStart
        )
        const initialEnd = useBindingCommon(
            dateRangeInputData.initialEnd
        )

        useEffect(() => {
            const startDatePicked = initialStart
                ? dayjs(initialStart as unknown as string)?.toDate()
                : dayjs().toDate()
            const endDatePicked = initialEnd
                ? dayjs(initialEnd as unknown as string)?.toDate()
                : dayjs().toDate()
            setStartDate(startDatePicked)
            setEndDate(endDatePicked)
        }, [initialEnd, initialStart])

        const onChangeStartDate = (date: Date) => {
            setStartDate(date)
        }

        const onChangeEndDate = (date: Date) => {
            setEndDate(date)
        }

        const MyContainer = (props: {
            children: ReactNode[]
            className: string
        }) => {
            return (
                <div style={{ width: 330 }}>
                    <CalendarContainer className={props.className}>
                        <div style={{ position: 'relative' }}>
                            {props.children}
                        </div>
                    </CalendarContainer>
                </div>
            )
        }

        return (
            <View style={styles.viewDateRangeInput}>
                <Text style={styles.dateTimeTitle} numberOfLines={2}>{title}</Text>
                <View style={styles.wrapDateTimeTrigger}>
                    <View style={styles.viewDate}>
                        <DatePicker
                            selected={startDate}
                            onChange={onChangeStartDate}
                            showTimeSelect
                            customInput={
                                <View style={styles.dateTrigger}>
                                    <Text
                                        style={styles.dateTimeContent}
                                        numberOfLines={1}
                                    >
                                        {dayjs(startDate).format('MMM D, YYYY')}
                                    </Text>
                                </View>
                            }
                            maxDate={endDate}
                            calendarContainer={MyContainer}
                        />
                    </View>
                    <FastImage
                        source={image.rightArrow}
                        style={styles.imgArr}
                        resizeMode={'contain'}
                    />
                    <View style={styles.viewDate}>
                        <DatePicker
                            selected={endDate}
                            onChange={onChangeEndDate}
                            showTimeSelect
                            customInput={
                                <View style={styles.dateTrigger}>
                                    <Text
                                        style={styles.dateTimeContent}
                                        numberOfLines={1}
                                    >
                                        {dayjs(endDate).format('MMM D, YYYY')}
                                    </Text>
                                </View>
                            }
                            minDate={startDate}
                            calendarContainer={MyContainer}
                        />
                    </View>
                </View>
            </View>
        )
    }
)

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        viewDate: {
            flex: 1,
        },
        viewDateRangeInput: {
            marginVertical: appConstants.PADDING_COMMON / 2,
            zIndex: 100000,
        },
        wrapDateTimeTrigger: {
            width: '100%',
            flexDirection: 'row',
            alignItems: 'center',
        },
        dateTrigger: {
            flex: 1,
            borderRadius: roundness,
            backgroundColor: colorScheme.backgroundL2,
            height: 44,
            justifyContent: 'center',
            alignItems: 'center',
        },
        dateTimeContent: {
            color: colorScheme.accentColor,
            fontWeight: '500',
            fontSize: 15,
            lineHeight: 18.15,
        },
        dateTimeTitle: {
            fontWeight: '500',
            fontSize: 16,
            lineHeight: 19,
            marginBottom: 12,
            color: colorScheme.labelPrimary,
        },
        imgArr: {
            width: 18,
            height: 22,
            marginHorizontal: 7.5,
            tintColor: colorScheme.accentColor,
        },
        dateTimeCustomContainer: { width: 330 },
        dateTimeChild: { position: 'relative' },
    })
