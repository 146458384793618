import { StyleSheet, Image, ImageSourcePropType } from 'react-native'
import React, { useMemo } from 'react'
import { Theme, useTheme } from '../../../../styles/theme'
import image from '../../../../assets/icons'

interface IIconFile {
    type: string
}

const IconFile = ({ type }: IIconFile) => {
    const theme = useTheme()
    const styles = useMemo(() => createStyles(theme), [theme])

    const getFileIcon = () => {
        if (
            type === 'application/msword' ||
            type ===
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ) {
            return (
                <Image
                    style={styles.imageSize}
                    source={image.wordFile as ImageSourcePropType}
                />
            )
        }
        if (
            type === 'application/vnd.ms-excel' ||
            type ===
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
            type === 'text/csv'
        ) {
            return (
                <Image
                    style={styles.imageSize}
                    source={image.excelFile as ImageSourcePropType}
                />
            )
        }
        if (
            type === 'text/plain' ||
            type === 'application/rtf' ||
            type === 'text/markdown'
        ) {
            return (
                <Image
                    style={styles.imageSize}
                    source={image.textFile as ImageSourcePropType}
                />
            )
        }
        if (type === 'application/pdf') {
            return (
                <Image
                    style={styles.imageSize}
                    source={image.pdfFile as ImageSourcePropType}
                />
            )
        }
        if (
            type === 'application/zip' ||
            type === 'application/x-7z-compressed' ||
            type === 'application/gzip'
        ) {
            return (
                <Image
                    style={styles.imageSize}
                    source={image.zipFile as ImageSourcePropType}
                />
            )
        }
        if (type === 'application/vnd.rar') {
            return (
                <Image
                    style={styles.imageSize}
                    source={image.rarFile as ImageSourcePropType}
                />
            )
        }
        if (
            type === 'image/png' ||
            type === 'image/jpeg' ||
            type === 'image/gif' ||
            type === 'image/tiff' ||
            type === 'image/vnd.adobe.photoshop' ||
            type === 'application/eps' ||
            type === 'application/x-eps' ||
            type === 'image/eps' ||
            type === 'image/x-eps' ||
            type === 'application/postscript'
        ) {
            return (
                <Image
                    style={styles.imageSize}
                    source={image.imageFile as ImageSourcePropType}
                />
            )
        }
        if (
            type === 'application/vnd.ms-powerpoint' ||
            type ===
                'pplication/vnd.openxmlformats-officedocument.presentationml.presentation'
        ) {
            return (
                <Image
                    style={styles.imageSize}
                    source={image.pttFile as ImageSourcePropType}
                />
            )
        }
        if (
            type === 'video/mp4' ||
            type === 'video/x-msvideo' ||
            type === 'video/x-matroska' ||
            type === 'video/x-flv' ||
            type === 'video/x-ms-wmv'
        ) {
            return (
                <Image
                    style={styles.imageSize}
                    source={image.audioFile as ImageSourcePropType}
                />
            )
        }
        if (
            type === 'audio/mpeg' ||
            type === 'audio/wav' ||
            type === 'audio/aac' ||
            type === 'audio/x-ms-wma' ||
            type === 'audio/x-flac' ||
            type === 'audio/m4a'
        ) {
            return (
                <Image
                    style={styles.imageSize}
                    source={image.musicFile as ImageSourcePropType}
                />
            )
        } else {
            return (
                <Image
                    style={styles.imageSize}
                    source={image.imageFile as ImageSourcePropType}
                />
            )
        }
    }
    return getFileIcon()
}

export default IconFile

const createStyles = ({ colorScheme, roundness, typography }: Theme) =>
    StyleSheet.create({
        imageSize: { width: 34, height: 34 },
    })
