//
//  ARNScale.tsx
//
//  Created by thaitd96 on 2022-27-04 10:52.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React, { useMemo } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { Theme, useTheme } from '../../../../styles/theme'
import { AirbnbRating } from 'react-native-ratings'
import { ItemAccessory, Scale } from '@appformula/app-descriptor/src'
import { observer } from 'mobx-react-lite'
import { useBindingHaveItemData } from '../hooks/useBindingHaveItemData'

export const ARNScale = observer(
    (props: { accessory: ItemAccessory; item: Record<string, unknown> }) => {
        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])
        const value = useBindingHaveItemData(
            (props.accessory as Scale).value,
            props.item
        )

        return (
            <View style={styles.accessory}>
                <AirbnbRating
                    count={5}
                    showRating={false}
                    defaultRating={Math.round(value as number)}
                    selectedColor={theme.colorScheme.starRating as string}
                    size={14}
                    isDisabled={true}
                />
                <Text
                    style={styles.textAccessory}
                    numberOfLines={1}
                    ellipsizeMode="tail"
                >
                    {value}
                </Text>
            </View>
        )
    }
)

const createStyles = ({ colorScheme, typography }: Theme) =>
    StyleSheet.create({
        accessory: {
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: 4,
            height: 32,
        },
        textAccessory: {
            flex: 1,
            color: colorScheme.labelSecondary,
            marginLeft: 8,
            fontSize: 14,
            fontWeight: '500',
        },
    })
