//
//  FormPage.tsx
//
//  Created by thaitd96 on 2022-14-07 17:02.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//
import React, { useCallback } from 'react'
import { AppDescriptorContext } from '../../context/AppDescriptorContext'
import { FormPageStore } from '@appformula/app-studio-core/src'
import FormScreen from './components/form-screen/FormScreen'
import screenNames from '../../navigations/screenNames'
import { ARNFormPageStore } from './ARNFormPageStore'
import FormOptionsPage from './components/option-form-input/option-form-input-page/FormOptionsPage'
import { BarcodeScannerPage } from './components/barcode-scanner/BarcodeScannerPage'
import PeopleLookupPage from './components/people-lookup/PeopleLookupPage'
import LocationSearchingPage from './components/Location/locationSearchingPage'
import LookupOptionsPage from './components/Lookup/lookupOptionsPage'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { BottomTabScreenProps } from '@react-navigation/bottom-tabs'
import { observer } from 'mobx-react-lite'
import { MainPageDataStoreContext } from '../../context/MainPageDataStoreContext'
import { StyleSheet, Text, View } from 'react-native'

type TabParamList = {
    BlankFormPage: {
        pageId: string
        tableId: string
    }
}

type Props = BottomTabScreenProps<TabParamList, 'BlankFormPage'>

const Stack = createNativeStackNavigator()

export const FormPage = ({ route }: Props) => {
    const store = React.useContext(AppDescriptorContext)
    const mainPageDataStore = React.useContext(MainPageDataStoreContext)
    const { pageId, tableId } = route.params

    const pageStore =
        (store.pageStore(pageId) as FormPageStore) ??
        (tableId && (store.defaultPageStore(tableId, 'form') as FormPageStore))

    return (
        <FormPageContent
            pageStore={pageStore}
            arnFormPageStore={
                mainPageDataStore.arnFormPageStore(
                    route.params.pageId
                ) as ARNFormPageStore
            }
        />
    )
}

export const FormPageContent = observer(
    (props: {
        pageStore: FormPageStore
        arnFormPageStore: ARNFormPageStore
    }) => {
        const { pageStore, arnFormPageStore } = props

        if (!pageStore) {
            return (
                <View style={styles.emptyView}>
                    <Text>{'PageStore not exist'}</Text>
                </View>
            )
        }

        const FormOptionsScreen = React.useCallback(() => {
            return (
                <FormOptionsPage
                    pageStore={pageStore}
                    arnFormPageStore={arnFormPageStore}
                />
            )
        }, [arnFormPageStore, pageStore])

        const FormPageScreen = useCallback(() => {
            return (
                <FormScreen
                    pageStore={pageStore as FormPageStore}
                    arnFormPageStore={arnFormPageStore}
                    formScreenStore={arnFormPageStore.formScreenStore(
                        pageStore?.pageId ?? ''
                    )}
                />
            )
        }, [arnFormPageStore, pageStore])

        const BarcodeScannerScreen = useCallback(() => {
            return (
                <BarcodeScannerPage
                    pageStore={pageStore}
                    arnFormPageStore={arnFormPageStore}
                />
            )
        }, [pageStore, arnFormPageStore])

        const PeopleLookupScreen = useCallback(() => {
            return (
                <PeopleLookupPage
                    pageStore={pageStore}
                    arnFormPageStore={arnFormPageStore}
                />
            )
        }, [arnFormPageStore, pageStore])

        const LocationSearchingScreen = useCallback(() => {
            return (
                <LocationSearchingPage
                    pageStore={pageStore}
                    arnFormPageStore={arnFormPageStore}
                />
            )
        }, [arnFormPageStore, pageStore])

        const LookupOptionsScreen = useCallback(() => {
            return (
                <LookupOptionsPage
                    arnFormPageStore={arnFormPageStore}
                    pageStore={pageStore}
                />
            )
        }, [arnFormPageStore, pageStore])

        return (
            <Stack.Navigator>
                <Stack.Screen
                    name={screenNames.FORM_SCREEN}
                    component={FormPageScreen}
                    options={{ headerShown: false }}
                />
                <Stack.Screen
                    name={screenNames.FORM_OPTIONS}
                    component={FormOptionsScreen}
                    options={{ headerShown: false }}
                />
                <Stack.Screen
                    name={screenNames.BARCODE_SCANNER}
                    component={BarcodeScannerScreen}
                    options={{ headerShown: false }}
                />
                <Stack.Screen
                    name={screenNames.PEOPLE_LOOKUP}
                    component={PeopleLookupScreen}
                    options={{ headerShown: false }}
                />
                <Stack.Screen
                    name={screenNames.LOCATION_SEARCHING}
                    component={LocationSearchingScreen}
                    options={{ headerShown: false }}
                />
                <Stack.Screen
                    name={screenNames.LOOKUP_OPTIONS}
                    component={LookupOptionsScreen}
                    options={{ headerShown: false }}
                />
            </Stack.Navigator>
        )
    }
)

const styles = StyleSheet.create({
    emptyView: { flex: 1, justifyContent: 'center', alignItems: 'center' },
})
