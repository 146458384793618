//
//  Filter.tsx
//
//  Created by thaitd96 on 2022-25-07 17:10.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { useNavigation } from '@react-navigation/native'
import React, { useCallback, useMemo } from 'react'
import { StyleSheet, Text, View, Image } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { SafeAreaView } from 'react-native-safe-area-context'
import { Theme, useTheme } from '../../../../styles/theme'
import { CollectionFilterStore } from './CollectionFilterStore'
import { observer } from 'mobx-react-lite'
import { ChooseColumnToAddCondition } from './modal-choose-column/ChooseColumnToAddCondition'
import { ARNCollectionPageStore } from '../../ARNCollectionPageStore'
import { FilterSwitcher } from './FilterSwitcher'
import image from '../../../../assets/icons'
import appConstants from '../../../../constants/const'
import { ColumnNames } from '../../../../database/type'

export const Filter = observer(
    (props: {
        collectionFilterStore: CollectionFilterStore
        arnCollectionPageStore: ARNCollectionPageStore
    }) => {
        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])
        const navigation = useNavigation()

        const cancel = React.useCallback(() => {
            navigation.goBack()
        }, [navigation])

        const apply = React.useCallback(async () => {
            await props.arnCollectionPageStore.saveQuerySortFilter()
            await props.arnCollectionPageStore.getAndSetData()
            navigation.goBack()
        }, [navigation, props.arnCollectionPageStore])

        const clear = useCallback(async () => {
            props.arnCollectionPageStore.clearCollectionFilterStore()
            await props.arnCollectionPageStore.removeQuerySortFilter()
            await props.arnCollectionPageStore.getAndSetData()
            navigation.goBack()
        }, [navigation, props.arnCollectionPageStore])

        return (
            <SafeAreaView style={styles.filter} edges={['bottom']}>
                <View style={styles.header}>
                    <TouchableOpacity onPress={cancel}>
                        <Text
                            style={styles.textButtonHeader}
                            numberOfLines={1}
                            ellipsizeMode={'tail'}
                        >
                            Cancel
                        </Text>
                    </TouchableOpacity>
                    <Text style={styles.txtFilter}>Filter</Text>
                    <TouchableOpacity onPress={apply}>
                        <Text style={styles.textButtonHeader}>{'Apply'}</Text>
                    </TouchableOpacity>
                </View>
                <View style={styles.body}>
                    {props.collectionFilterStore.filterData?.length > 0 ? (
                        <KeyboardAwareScrollView
                            style={styles.viewCustom}
                            extraScrollHeight={50}
                            removeClippedSubviews
                        >
                            {props.collectionFilterStore.filterData.map(
                                (item) => (
                                    <FilterSwitcher
                                        key={item.columnFilter.key}
                                        item={item}
                                        collectionFilterStore={
                                            props.collectionFilterStore
                                        }
                                        itemSchema={props.arnCollectionPageStore.columnData(
                                            item.columnFilter.columnName
                                        )}
                                    />
                                )
                            )}
                        </KeyboardAwareScrollView>
                    ) : (
                        <View style={styles.container}>
                            <Image
                                source={image.noFilterCondition}
                                style={styles.imgNotFilter}
                            />
                            <Text style={styles.txtTitle}>
                                No Filter Conditions
                            </Text>
                            <Text style={styles.txtContent}>
                                To filter your list data, add at least one
                                conditions.
                            </Text>
                        </View>
                    )}

                    <View style={styles.bottomContainer}>
                        <ChooseColumnToAddCondition
                            collectionFilterStore={props.collectionFilterStore}
                            columns={
                                props.arnCollectionPageStore.mainPageDataStore.mainPageStore.currentTables?.find(
                                    (item) =>
                                        item['name'] ===
                                            props.arnCollectionPageStore
                                                .pageStore?.dataSource?.id ?? ''
                                )?.['columnNames'] as ColumnNames
                            }
                        />
                        <TouchableOpacity onPress={clear}>
                            <Text
                                style={styles.textButtonHeader}
                                numberOfLines={1}
                                ellipsizeMode={'tail'}
                            >
                                Clear
                            </Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </SafeAreaView>
        )
    }
)

const createStyles = ({ colorScheme, roundness }: Theme) => {
    return StyleSheet.create({
        viewCustom: {
            flex: 1,
            backgroundColor: colorScheme.backgroundL1,
            marginBottom: 20,
        },
        headerTitleStyle: {
            color: colorScheme.labelPrimary,
            fontSize: 17,
            fontWeight: '600',
        },
        textButtonHeader: {
            color: colorScheme.accentColor,
            fontWeight: '500',
            fontSize: 17,
        },
        body: {
            flex: 1,
            width: '100%',
        },
        filter: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: colorScheme.backgroundL1,
        },

        imgNotFilter: {
            width: 36,
            height: 36,
            marginBottom: 16,
            tintColor: colorScheme.labelSecondary,
        },
        txtContent: {
            fontSize: 15,
            fontWeight: '400',
            lineHeight: 22.5,
            textAlign: 'center',
            color: colorScheme.labelSecondary,
        },
        txtTitle: {
            fontSize: 22,
            fontWeight: '500',
            lineHeight: 29.7,
            color: colorScheme.labelSecondary,
            marginBottom: 8,
        },
        container: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            paddingHorizontal: 32,
            backgroundColor: colorScheme.backgroundL1,
        },
        header: {
            paddingHorizontal: 10,
            paddingVertical: 14,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: colorScheme.backgroundL1,
            borderBottomColor: colorScheme.separator,
            borderBottomWidth: 1,
            width: '100%',
        },
        imgClose: {
            height: 28,
            width: 28,
        },
        headerLeft: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        headerRight: {},
        btnSave: {
            borderRadius: roundness,
        },
        txtSave: {
            color: colorScheme.accentColor,
            paddingVertical: 7,
            paddingHorizontal: 16.5,
            fontSize: 14,
            lineHeight: 16.5,
            fontWeight: '500',
        },
        txtTitleHeader: {
            fontWeight: '600',
            fontSize: 18,
            lineHeight: 29.7,
            color: colorScheme.labelPrimary,
            marginLeft: 8,
        },
        txtFilter: {
            color: colorScheme.labelPrimary,
            fontSize: 17,
            fontWeight: '600',
            lineHeight: 20.57,
            textAlign: 'center',
        },
        bottomContainer: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingHorizontal: appConstants.PADDING_COMMON,
            borderTopWidth: 1,
            borderTopColor: colorScheme.separator,
            paddingVertical: 14,
        },
    })
}
