import { OperationConstraint } from '../constraint/OperationConstraint'
import { OperationRawData, CompoundExecutionMode, Operation } from './Operation'

export type CompoundedData = {
    mode: CompoundExecutionMode
    data: Record<string, unknown>
}

export class CompoundedOperation implements Operation {
    id: string
    operations: Array<Operation>
    constraints: OperationConstraint[]
    mode: CompoundExecutionMode = 'concurrent'

    constructor(
        id: string,
        operations: Operation[],
        mode: CompoundExecutionMode
    ) {
        this.id = id
        this.mode = mode
        this.operations = operations
        this.constraints = this.combineAllChildConstraints(operations)
    }

    toRawData(): OperationRawData {
        const data: Record<string, unknown> = {}
        this.operations.forEach((operation) => {
            data[operation.id] = operation.toRawData()
        })

        const compoundedData: CompoundedData = {
            mode: this.mode,
            data,
        }

        const compoundedRawData: OperationRawData = {
            operationID: this.id,
            data: compoundedData,
            type: 'compounded',
        }

        return compoundedRawData
    }

    async run(): Promise<void> {
        if (this.mode === 'concurrent') {
            return Promise.all(this.operations.map((e) => e.run())).then()
        } else {
            const asyncFunction = async () => {
                for (const operation of this.operations) {
                    await operation.run()
                }
            }
            return asyncFunction()
        }
    }

    combineAllChildConstraints(operations: Operation[]): OperationConstraint[] {
        return operations.reduce(
            (constraints, operation) =>
                constraints.concat(operation.constraints),
            []
        )
    }

    addOperations(operations: Operation[]) {
        this.operations = this.operations.concat(operations)
        this.constraints = this.combineAllChildConstraints(this.operations)
    }
}
