//
//  useBinding.tsx
//
//  Created by thaitd96 on 2022-09-09 15:13.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { Binding, BindingValue } from '@appformula/app-descriptor/src'
import { useState, useEffect, useCallback, useContext } from 'react'
import { FormulaManagerContext } from '../../../../context/FormulaManagerContext'
import { FormulaManager } from '../../../../utils/integrate-formula/FormulaManager'
import { TransformBinding } from '../../../../utils/transform-binding/TransformBinding'

export function useBinding(binding: Binding<BindingValue> | undefined) {
    const formulaManager: FormulaManager | undefined = useContext(
        FormulaManagerContext
    )
    const [value, setValue] = useState<BindingValue | undefined>(undefined)

    const getTitle = useCallback(
        async (binding: Binding<BindingValue>) => {
            try {
                setValue(
                    (await TransformBinding(binding, formulaManager)) as string
                )
            } catch (error) {
                console.info('useBinding error', error)
            }
        },
        [formulaManager]
    )

    useEffect(() => {
        binding && getTitle(binding)
    }, [binding, getTitle])

    return value
}
