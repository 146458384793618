//
//  FormulaManagerContext.tsx
//
//  Created by thaitd96 on 2022-07-19 1821.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import React from 'react'
import { FormulaManager } from '../utils/integrate-formula/FormulaManager'

export const FormulaManagerContext = React.createContext<
    FormulaManager | undefined
>(undefined)
