//
//  LocalAppDescriptorRepository.ts
//
//  Created by thaitd96 on 2022-08-10 14:24
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import {
    AppDescriptorRepository,
    DefaultPageType,
    PageRepository,
} from '@appformula/app-studio-core'
import {
    AppDescriptor,
    AuthenticationPage,
    CollectionPage,
    CustomPage,
    FormPage,
    Page,
    PageType,
} from '@appformula/app-descriptor'
import { Observable, of } from 'rxjs'
import {
    LocalCustomPageRepository,
    LocalFormPageRepository,
    LocalCollectionPageRepository,
} from './pages'

export class LocalAppDescriptorRepository implements AppDescriptorRepository {
    private object: AppDescriptor

    get appDescriptorSnapshot(): AppDescriptor {
        return this.object
    }

    get appDescriptor(): Observable<AppDescriptor> {
        return of(this.object)
    }

    constructor(object: AppDescriptor) {
        this.object = object
    }
    defaultPageRepositoryOf(
        tableId: string,
        pageType: DefaultPageType
    ): PageRepository {
        switch (pageType) {
            case 'details': {
                const objectDetail = this.object?.defaultPages?.[tableId]
                    ?.detailsPage as unknown as CustomPage
                return new LocalCustomPageRepository(
                    of(objectDetail),
                    objectDetail,
                    'detailsPage'
                )
            }

            case 'form': {
                const objectForm = this.object?.defaultPages?.[tableId]
                    ?.formPage as unknown as FormPage

                console.info({ objectForm })
                return new LocalFormPageRepository(
                    of(objectForm),
                    objectForm,
                    'formPage'
                )
            }
        }
    }
    setAuthenticationPageProps<K extends keyof AuthenticationPage>(
        key: K,
        value: AuthenticationPage[K]
    ): Promise<void> {
        throw new Error('Method not implemented.')
    }

    pageRepositoryOf(pageId: string, pageType: PageType): PageRepository {
        switch (pageType) {
            case 'collection': {
                const object = this.object?.pages?.[
                    pageId
                ] as unknown as CollectionPage
                return new LocalCollectionPageRepository(
                    of(object),
                    object,
                    pageId
                )
            }
            case 'custom': {
                const object = this.object?.pages?.[
                    pageId
                ] as unknown as CustomPage
                return new LocalCustomPageRepository(of(object), object, pageId)
            }
            case 'form': {
                const object = this.object?.pages?.[
                    pageId
                ] as unknown as FormPage
                return new LocalFormPageRepository(of(object), object, pageId)
            }
            case 'details': {
                const object = this.object?.pages?.[
                    pageId
                ] as unknown as CustomPage
                return new LocalCustomPageRepository(of(object), object, pageId)
            }
        }
    }

    set<K extends keyof AppDescriptor>(
        key: K,
        value: AppDescriptor[K]
    ): Promise<void> {
        throw new Error('Method not implemented.')
    }

    insertPage(
        pageId: string,
        page: Page,
        rank: string,
        isFrontPage: boolean
    ): Promise<void> {
        throw new Error('Method not implemented.')
    }

    removePageId(pageId: string): Promise<void> {
        throw new Error('Method not implemented.')
    }

    setPageRank(pageId: string, rank: string): Promise<void> {
        throw new Error('Method not implemented.')
    }

    removePageFromFrontPages(pageId: string): Promise<void> {
        throw new Error('Method not implemented.')
    }

    addPageToFrontPages(pageId: string): Promise<void> {
        throw new Error('Method not implemented.')
    }
}
