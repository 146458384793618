//
//  ItemDetails.tsx
//
//  Created by thaitd96 on 2022-17-05 16:37.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { observer } from 'mobx-react-lite'
import React, { useMemo } from 'react'
import { Image, StyleSheet, Text, View, ViewStyle } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { Theme, useTheme } from '../../../../styles/theme'
import { MapLayoutStore } from './MapLayoutStore'
import image from '../../../../assets/icons'
import { hexToRGBA } from '../../../../utils'
import appConstants from '../../../../constants/const'
import { useBindingHaveItemData } from '../hooks/useBindingHaveItemData'
import { CollectionPageStore } from '@appformula/app-studio-core/src'
import { MapLayout } from '@appformula/app-descriptor/src'
import { COLORS } from '../../../../assets/colors'

const ItemDetails = observer(
    (props: {
        closeDetail: () => void
        mapLayoutStore: MapLayoutStore
        pageStore: CollectionPageStore
    }) => {
        const theme = useTheme()
        const styles = useMemo(() => createStyles(theme), [theme])
        const { currentItem } = props.mapLayoutStore

        const title = useBindingHaveItemData(
            (props.pageStore.activeLayout as MapLayout).title,
            currentItem
        )

        const addressString = useBindingHaveItemData(
            (props.pageStore.activeLayout as MapLayout).address,
            currentItem
        )

        return (
            <View style={styles.view}>
                <View style={styles.header}>
                    <View style={styles.textHeader}>
                        <Text style={styles.title} numberOfLines={2}>
                            {title || ''}
                        </Text>
                        <Text style={styles.address} numberOfLines={2}>
                            {addressString || ''}
                        </Text>
                    </View>
                    <TouchableOpacity
                        onPress={props.closeDetail}
                        style={{ marginLeft: appConstants.PADDING_COMMON }}
                    >
                        <Image
                            source={image.close}
                            style={styles.close}
                            resizeMode={'contain'}
                        ></Image>
                    </TouchableOpacity>
                </View>
                <View style={styles.wrapButtons}>
                    <TouchableOpacity style={styles.button}>
                        <Image
                            style={styles.imageButton}
                            source={image.getDirection}
                            resizeMode={'contain'}
                        ></Image>
                        <Text style={styles.textButton}>{'Get Direction'}</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.buttonOpacity}>
                        <Image
                            style={styles.imageButtonOpacity}
                            source={image.phoneMap}
                            resizeMode={'contain'}
                        ></Image>
                        <Text style={styles.textButtonOpacity}>
                            {'Call: 713-523-8533'}
                        </Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.buttonOpacity}>
                        <Image
                            style={styles.imageButtonOpacity}
                            source={image.globe}
                            resizeMode={'contain'}
                        ></Image>
                        <Text style={styles.textButtonOpacity}>
                            {'Open Websites'}
                        </Text>
                    </TouchableOpacity>
                </View>
            </View>
        )
    }
)

export default ItemDetails

const styleButton = {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    marginTop: 8,
}

const imageButtonBase = {
    marginVertical: 5,
    marginRight: 2,
    height: 34,
    width: 34,
}

const createStyles = ({ colorScheme, typography }: Theme) =>
    StyleSheet.create({
        close: { height: 32, width: 32 },
        wrapButtons: { padding: appConstants.PADDING_COMMON, paddingTop: 20 },
        imageButton: { ...imageButtonBase },
        imageButtonOpacity: {
            ...imageButtonBase,
            tintColor: colorScheme.accentColor,
        },
        textButton: {
            color: COLORS.WHITE,
            fontSize: 15,
            fontWeight: '500',
        },
        textButtonOpacity: {
            color: colorScheme.accentColor,
            fontSize: 15,
            fontWeight: '500',
        },
        textHeader: { flex: 1 },
        header: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingLeft: appConstants.PADDING_COMMON,
            paddingRight: 8,
        },
        address: {
            fontSize: 16,
            color: colorScheme.labelSecondary,
            marginTop: 4,
        },
        title: {
            fontSize: 20,
            fontWeight: '600',
            lineHeight: 24,
            color: colorScheme.labelPrimary,
        },
        button: {
            ...styleButton,
            backgroundColor: colorScheme.accentColor,
        } as ViewStyle,
        buttonOpacity: {
            ...styleButton,
            backgroundColor: hexToRGBA(colorScheme.accentColor as string, 0.1),
        } as ViewStyle,
        view: { paddingTop: 3, backgroundColor: colorScheme.backgroundL1 },
    })
