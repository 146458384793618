import React, { ReactNode } from 'react'
import { Text, View } from 'react-native'

const Rules = {
    blockquote: (node: any, children: ReactNode[], parent: ReactNode, styles: any) => {
        return (
            <View key={node.key} style={styles.customizeBlockQ}>
                <Text style={styles.txtBlockQ}>{node?.children?.[0]?.children[0]?.children?.[0]?.content || ''}</Text>
            </View>
        )
    },
    fence: (node: any, children: ReactNode[], parent: ReactNode, styles: any) => {
        return (
            <View key={node.key} style={styles.codeBlock}>
                <Text style={styles.txtCodeBlock}>{node.content}</Text>
            </View>
        )
    },
}

export default Rules
